<template>
    <div>
        <!-- Modal link message -->
        <modal name="modal-link-message" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">Rattacher à un message</h2>
                    <button @click="$modal.hide('modal-link-message')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <input-rule v-model="linkMessageForm.selectedMessage" :options="{values: allMessages, key: 'id', label: 'nameAndLabel'}" type="select" label="A quel message souhaitez-vous rattacher le message ?" :label-inline="false" id="link-message-id" />
                <button @click="linkToMessage" class="mt-3 bg-green-100 text-green-800 border border-green-200 rounded px-4 py-2 text-xs">
                    Valider <i class="fas fa-check ml-1"></i>
                </button>
            </div>
        </modal>

        <!-- Modal link reponse -->
        <modal name="modal-link-reponse" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">Rattacher à une réponse</h2>
                    <button @click="$modal.hide('modal-link-message')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <input-rule v-model="linkMessageForm.selectedResponse" :options="{values: allResponses, key: 'id', label: 'nameAndLabel'}" type="select" label="A quelle réponse souhaitez-vous rattacher le message ?" :label-inline="false" id="link-response-id" />
                <button @click="linkToReponse" class="mt-3 bg-green-100 text-green-800 border border-green-200 rounded px-4 py-2 text-xs">
                    Valider <i class="fas fa-check ml-1"></i>
                </button>
            </div>
        </modal>

        <!-- Loader -->
        <div v-if="loading" class="flex justify-center p-4">
            <spinner :size="10" />
        </div>

        <!-- Page content -->
        <div v-if="!loading && msg">
            <!-- Header -->
            <div class="flex justify-end">
                <button @click="$modal.hide('modal-edit-node')">
                    <i class="fas fa-times text-red-500 text-lg"></i>
                </button>
            </div>

            <!-- Error message -->
            <div v-if="$parent.$parent.nodes[Object.keys($parent.$parent.nodes)[0]].id != node.id && Object.keys(node.portsIn).length <= 0" class="mt-1 mb-3 bg-red-100 text-red-800 text-xs font-light p-4 rounded">
                <i class="fas fa-exclamation-triangle mr-1"></i> Ce message n'est rattaché à aucun autre
            </div>
            <div v-if="Object.keys(node.portsOut).length <= 0" class="mt-1 mb-3 bg-red-100 text-red-800 text-xs font-light p-4 rounded">
                <i class="fas fa-exclamation-triangle mr-1"></i> Ce message n'a pas de sortie
            </div>

            <!-- Form -->
            <h2 style="color: #6a7d8b" class="text-sm font-semibold border-b pb-3 mb-3"><i class="fa-solid fa-comment mr-1"></i> Message séléctionné</h2>
            <template v-if="displayForm">
                <input-rule
                v-model="form.typeMessage"
                :options="{values: [
                    {key: 'message', label: 'Message texte'},
                    {key: 'file', label: 'Fichier'},
                    {key: 'link', label: 'Lien'},
                    {key: 'youtube', label: 'Vidéo Youtube'},
                ], key: 'key', label: 'label'}"
                type="select"
                label="Type de message"
                class="mb-4"
                :label-inline="false"
                />

                <!-- Message text -->
                <template v-if="form.typeMessage == 'message'">
                    <input-rule class="mb-4" label="Message affiché" type="textarea" :label-inline="false" v-model="form.message" id="new-node-label" />
                </template>
                <!-- Fichier -->
                <template v-if="form.typeMessage == 'file'">
                    <input-rule class="mb-4" label="Fichier" type="file" :label-inline="false" v-model="form.message" id="new-node-label" />
                </template>
                <!-- Fichier -->
                <template v-if="form.typeMessage == 'link'">
                    <input-rule class="mb-4" label="Lien" type="text" :label-inline="false" v-model="form.message" id="new-node-label" />
                </template>
                <!-- Fichier -->
                <template v-if="form.typeMessage == 'youtube'">
                    <input-rule class="mb-4" label="Lien de la vidéo Youtube" type="text" :label-inline="false" v-model="form.message" id="new-node-label" />
                </template>
                <template v-if="loadingUpdate">
                    <spinner :size="10" />
                </template>
                <template v-else>
                    <button @click="updateMsg" class="bg-green-100 text-green-800 border border-green-200 rounded px-4 py-2 text-xs">
                        Enregistrer <i class="fas fa-save ml-1"></i>
                    </button>
                </template>
            </template>
            <template v-if="!displayForm">
                <div class="font-bold text-sm flex items-center justify-between border rounded p-4" :class="confirmDelete ? 'bg-red-100' : 'bg-slate-100'">
                    <div>
                        {{ getMsgLabel(msg) }}
                    </div>
                    <div class="flex gap-2">
                        <template v-if="!confirmDelete">
                            <button @click="displayForm = true" class="py-1 px-2 rounded bg-slate-300 hover:bg-slate-400 duration-200"><i class="fas fa-pen"></i></button>
                            <button @click="confirmDelete = true" class="py-1 px-2 rounded bg-red-300 hover:bg-red-400 duration-200">
                                <i v-if="!confirmDelete" class="fas fa-trash"></i>
                                <i v-if="confirmDelete" class="fas fa-check text-red-800"></i>
                            </button>
                        </template>
                        <template v-if="confirmDelete">
                            <button @click="confirmDelete = false" class="py-1 px-3 rounded bg-slate-300 hover:bg-slate-400 duration-200">
                                Annuler
                            </button>
                            <button @click="removeMsg()" class="py-1 px-2 rounded bg-red-300 hover:bg-red-400 duration-200">
                                Confirmer
                            </button>
                        </template>
                    </div>
                </div>
            </template>

            <!-- Outputs buttons -->
            <template v-if="msg.message">
                <h2 style="color: #6a7d8b" class="text-sm font-semibold border-b pb-3 mt-6"><i class="fa-solid fa-diagram-next mr-1"></i> Messages suivants</h2>
                <div v-if="outputs.length <= 0 && msg.resolu == 0">
                    <div @click="addNewMessage()" class="border-b p-2.5 hover:bg-slate-100 duration-200 cursor-pointer text-sm">
                        <i class="fa fa-plus inline-block rounded-full bg-orange-500 text-white mr-1 text-xs pl-[5px] pr-[4px] p-[2px]"></i>
                        Nouveau message
                    </div>
                    <div @click="$modal.show('modal-link-message')" class="border-b p-2.5 hover:bg-slate-100 duration-200 cursor-pointer text-sm">
                        <i class="fa fa-link inline-block rounded-full bg-blue-500 text-white mr-1 text-xs pl-[3px] pr-[3px] p-[2px]"></i>
                        Rattacher à un message
                    </div>
                    <div @click="$modal.show('modal-link-reponse')" class="border-b p-2.5 hover:bg-slate-100 duration-200 cursor-pointer text-sm">
                        <i class="fa fa-link inline-block rounded-full bg-blue-500 text-white mr-1 text-xs pl-[3px] pr-[3px] p-[2px]"></i>
                        Rattacher à une réponse
                    </div>
                    <div @click="addNewResponse()" class="border-b p-2.5 hover:bg-slate-100 duration-200 cursor-pointer text-sm">
                        <i class="fa fa-comment inline-block rounded-full bg-purple-500 text-white mr-1 text-xs pl-[4px] pr-[4px] p-[2px]"></i>
                        Ajouter une réponse
                    </div>
                    <div @click="addYesNo" class="border-b p-2.5 hover:bg-slate-100 duration-200 cursor-pointer text-sm">
                        <i class="fa fa-microchip inline-block rounded-full bg-green-500 text-white mr-1 text-xs pl-[4px] pr-[4px] p-[2px]"></i>
                        Ajouter deux réponses : Oui / Non
                    </div>
                    <div @click="addSolutionHelped" class="border-b p-2.5 hover:bg-slate-100 duration-200 cursor-pointer text-sm">
                        <i class="fa fa-microchip inline-block rounded-full bg-green-500 text-white mr-1 text-xs pl-[4px] pr-[4px] p-[2px]"></i>
                        Cette solution vous a-t-elle aidé ?
                    </div>
                    <div @click="addEndProcess(1)" class="border-b p-2.5 hover:bg-slate-100 duration-200 cursor-pointer text-sm">
                        <i class="fa fa-check inline-block rounded-full bg-slate-500 text-white mr-1 text-xs pl-[4px] pr-[4px] p-[2px]"></i>
                        Fin de formulaire - Solution trouvée
                    </div>
                    <div @click="addEndProcess(2)" class="border-b p-2.5 hover:bg-slate-100 duration-200 cursor-pointer text-sm">
                        <i class="fa fa-times inline-block rounded-full bg-slate-500 text-white mr-1 text-xs pl-[6.5px] pr-[6.5px] p-[2px]"></i>
                        Fin de formulaire - Solution non trouvée
                    </div>
                </div>

                <!-- Outputs end form -->
                <div v-if="msg.resolu != 0">
                    <div v-if="msg.resolu == 1" class="text-white bg-green-800 p-4 rounded flex items-center justify-between">
                        <div class="flex items-center gap-3">
                            <i class="fas fa-check mr-1 text-xl"></i>
                            <div>
                                <div class="text-sm font-base">Fin de formulaire</div>
                                <div class="font-thin text-light text-sm">Solution trouvée</div>
                            </div>
                        </div>
                        <button class="text-sm font-light hover:bg-green-700 duration-200 cursor-pointer py-1 px-2 rounded" @click="removeEndProcess">Annuler</button>
                    </div>
                    <div v-if="msg.resolu == 2" class="text-white bg-red-800 p-4 rounded flex items-center justify-between">
                        <div class="flex items-center gap-3">
                            <i class="fas fa-times mr-1 text-xl"></i>
                            <div>
                                <div class="text-sm font-base">Fin de formulaire</div>
                                <div class="font-thin text-light text-sm">Solution non trouvée</div>
                            </div>
                        </div>
                        <button class="text-sm font-light hover:bg-red-700 duration-200 cursor-pointer py-1 px-2 rounded" @click="removeEndProcess">Annuler</button>
                    </div>
                </div>

                <!-- Outputs cards -->
                <div class="mt-2" v-if="outputs.length > 0">
                    <div
                    class="mb-2 bg-slate-100 hover:bg-slate-200 border duration-200 cursor-pointer p-3 rounded flex items-center justify-between text-xs font-light"
                    v-for="(output, outputIndex) in outputs"
                    :key="'output-' + outputIndex"
                    @click="$eventHub.$emit('select-new-node', output.type == 'message' ? ('msg-' + output.id) : ('rep-' + output.id))"
                    >
                        <div class="flex items-center gap-4">
                            <button class="bg-red-400 hover:bg-red-500 duration-200 cursor-pointer text-white px-2 py-1 rounded" @click.stop="output.type == 'message' ? removeLinkMessage() : removeLinkResponse(output)"><i class="fas fa-times"></i></button>
                            <div class="flex gap-2">
                                <div v-if="output.type == 'message'"><i class="fa-solid fa-robot"></i></div>
                                <div v-if="output.type == 'reponse'"><i class="fa-solid fa-question"></i></div>
                                <div class="font-bold">
                                    {{ getMsgLabel(output) }}
                                </div>
                            </div>
                        </div>
                        <i class="fas fa-chevron-right"></i>
                    </div>
                </div>
                <div class="flex gap-2" v-if="outputs[0] && outputs[0].type == 'reponse'">
                    <button @click="addNewResponse()" class="mb-2 text-xs font-light px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer border rounded">
                        <i class="fas fa-plus mr-1"></i> Ajouter une réponse
                    </button>
                    <button @click="$modal.show('modal-link-reponse')" class="mb-2 text-xs font-light px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer border rounded">
                        <i class="fas fa-link mr-1"></i> Attacher une réponse
                    </button>
                </div>

            </template>
            <!-- Actions -->
            <h2 style="color: #6a7d8b" class="text-sm font-semibold border-b pb-3 mt-6"><i class="fa-solid fa-computer-mouse mr-1"></i> Actions</h2>
            <button @click="deleteBloc" class="mt-2 text-xs font-light px-4 py-2 bg-red-200 hover:bg-red-300 duration-200 cursor-pointer rounded">
                <i class="fas fa-trash mr-1"></i> Supprimer le message
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {required: true},
        node: {required: true},
    },
    watch: {
        id() {
            this.getMsg()
        }
    },
    data() {
        return {
            msg: null,
            outputs: [],

            form: {
                typeMessage: 'message',
                message: ''
            },
            displayForm: false,
            linkMessageForm: {},

            confirmDelete: false,

            loading: false,
            loadingUpdate: false,
        }
    },
    computed: {
        allMessages() {
            let msgs = this.$parent.$parent.pattern.messages.filter((item) => item.id != this.id)
            return msgs.map((item) => ({...item, nameAndLabel: item.name + ' - ' + item.message}))
        },
        allResponses() {
            return this.$parent.$parent.pattern.reponses.map((item) => ({...item, nameAndLabel: item.name + ' - ' + item.reponse}))
        }
    },
    methods: {
        async getMsg() {
            this.loading = true

            let response = await this.$request.post('getblocbyid', {
                action: 'bdc',
                type: 'message',
                current_id: this.id,
            }, 'districloud_preprod')

            let msg = response.data.posts.post.current

            if (msg.meta) {
                msg.meta = JSON.parse(msg.meta)
            }

            this.msg = msg
            this.outputs = response.data.posts.post.output

            this.form.typeMessage = this.msg.meta.typeMessage || 'message'

            if (!this.msg.message) {
                this.displayForm = true
            } else {
                this.displayForm = false
            }
            this.form.message = this.msg.meta.inpValue || this.msg.message

            this.loading = false
        },

        async getOutputs(id = null) {
            let response = await this.$request.post('getblocbyid', {
                action: 'bdc',
                type: 'message',
                current_id: id || this.id,
            }, 'districloud_preprod')
            if (id) {
                return response.data.posts.post.output
            } else {
                this.outputs = response.data.posts.post.output
            }
        },

        async updateMsg() {
            if (!this.$validation.check([
                {value: this.form.message, rules: 'required', id: 'new-node-label'},
            ])) {return}

            // Overwrite message
            let label = this.form.message
            if (!this.msg.meta) {
                this.msg.meta = {}
            }
            if (typeof this.msg.meta == 'string') {
                this.msg.meta = JSON.parse(this.msg.meta)
            }
            this.msg.meta.inpValue = this.form.message
            // File
            if (this.form.typeMessage == 'file') {
                label = '<a class="file-link" href="' + this.form.message.url + '" target="_blank">Consulter le fichier</a>'
                this.msg.meta.typeMessage = 'file'
            }
            // link
            if (this.form.typeMessage == 'link') {
                label = '<a class="message-link" href="' + this.form.message + '" target="_blank">Consulter le lien</a>'
                this.msg.meta.typeMessage = 'link'
            }
            // Youtube
            if (this.form.typeMessage == 'youtube') {
                let youtube_parser = (url) =>{
                    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                    var match = url.match(regExp);
                    return (match&&match[7].length==11)? match[7] : false;
                }
                label = '<iframe class="video-youtube" width="100%" height="250" src="https://www.youtube.com/embed/' + youtube_parser(this.form.message) + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; allowfullscreen" allowfullscreen></iframe>'
                this.msg.meta.typeMessage = 'youtube'
            }

            // Send request
            this.loadingUpdate = true
            await this.$request.post('updatebloc', {
                action: 'bdc',
                type: 'message',
                current_id: this.msg.id,
                nom: this.msg.name,
                label: label,
                meta: JSON.stringify(this.msg.meta)
            }, 'districloud_preprod')
            this.$emit('updated')
            await this.getMsg()
            this.loadingUpdate = false
        },

        async removeMsg() {
            this.loadingUpdate = true
            await this.$request.post('removebloc', {
                action: 'bdc',
                type: 'message',
                current_id: this.id,
            }, 'districloud_preprod')
            this.loadingUpdate = false
            this.getOutputs()
            this.$parent.$parent.refresh()
            this.$modal.hide('modal-edit-node')
        },

        async addNewMessage(defaultLabel = null) {
            let nextId = parseInt(Object.keys(this.$parent.$parent.nodes).length)
            let blocName = nextId + 1

            // Save new bloc
            let newBlocReponse = await this.$request.post('addbloc', {
                action: 'bdc',
                type: 'message',
                nom: blocName,
                id_pattern: this.msg.id_pattern,
                label: defaultLabel || '',
                meta: JSON.stringify({
                    coordinates: {
                        x: this.$parent.$parent.selectedNode.coordinates.x + 150 + 20,
                        y: this.$parent.$parent.selectedNode.coordinates.y
                    }
                })
            }, 'districloud_preprod')
            let newBlocId = newBlocReponse.data.posts.post

            await this.$request.post('updatebloc', {
                action: 'bdc',
                type: 'message',
                current_id: this.msg.id,
                question_id: newBlocId
            }, 'districloud_preprod')

            await this.getOutputs()
            await this.$parent.$parent.refresh()

            return newBlocId
        },

        async addNewResponse(label = null, msgId = null) {
            let nextId = parseInt(Object.keys(this.$parent.$parent.nodes).length)
            let blocName = nextId + 1
            
            // Bloc Y
            let newBlocY = null
            let node = msgId ? this.$parent.$parent.nodes['msg-' + msgId] : this.$parent.$parent.selectedNode
            let outputs = this.outputs
            if (msgId && msgId != this.id) {
                outputs = await this.getOutputs(msgId)
            }
            if (!outputs || outputs.length <= 0) {
                newBlocY = node.coordinates.y
            } else {
                let output = outputs[outputs.length - 1]
                output.meta = JSON.parse(output.meta)
                newBlocY = output.meta.coordinates.y + 84
            }

            // Bloc X
            let newBlocX = null
            if (msgId) {
                newBlocX = this.$parent.$parent.nodes['msg-' + msgId].coordinates.x
            } else {
                newBlocX = this.$parent.$parent.selectedNode.coordinates.x
            }
            // Save new bloc
            let response = await this.$request.post('addbloc', {
                action: 'bdc',
                type: 'reponse',
                nom: blocName,
                id_pattern: this.msg.id_pattern,
                current_id: msgId || this.id,
                label: label || '',
                meta: JSON.stringify({
                    coordinates: {
                        x: newBlocX + 150 + 20,
                        y: newBlocY
                    }
                })
            }, 'districloud_preprod')
            
            await this.getOutputs()
            await this.$parent.$parent.refresh()

            return response.data.posts.post
        },

        async addSolutionHelped() {
            let newQuestionId = await this.addNewMessage('Cette solution vous a t-elle aidé ?')
            let newRepOui = await this.addNewResponse('Oui', newQuestionId)
            await this.addNewResponse('Non', newQuestionId)

            await this.$request.post('updatebloc', {
                action: 'bdc',
                type: 'reponse',
                current_id: newRepOui,
                resolu: 1
            }, 'districloud_preprod')

            await this.getOutputs()
            await this.$parent.$parent.refresh()
        },

        async addYesNo() {
            await this.addNewResponse('Oui')
            await this.addNewResponse('Non')
        },

        async linkToMessage() {
            if (!this.$validation.check([
                {value: this.linkMessageForm.selectedMessage, rules: 'required', id: 'link-message-id'},
            ])) {return}

            await this.$request.post('updatebloc', {
                action: 'bdc',
                type: 'message',
                current_id: this.msg.id,
                question_id: this.linkMessageForm.selectedMessage
            }, 'districloud_preprod')

            this.$modal.hide('modal-link-message')
            this.getOutputs()
            this.$parent.$parent.refresh()
        },

        async linkToReponse() {
            if (!this.$validation.check([
                {value: this.linkMessageForm.selectedResponse, rules: 'required', id: 'link-response-id'},
            ])) {return}

            await this.$request.post('attach_response', {
                action: 'bdc',
                type: 'message',
                question_id: this.msg.id,
                reponse_id: this.linkMessageForm.selectedResponse
            }, 'districloud_preprod')

            this.$modal.hide('modal-link-reponse')
            this.getOutputs()
            this.$parent.$parent.refresh()
        },

        getMsgLabel(msg) {
            let label = null
            if ((msg.type == 'message' && !msg.message) || (msg.type == 'reponse' && !msg.label)) {
                return 'Nouveau message'
            }
            if (msg.type == 'reponse') {
                return msg.label
            }
            if (!msg.meta) {
                label = msg.message
            } else {
                if (typeof msg.meta == 'string') {
                    msg.meta = JSON.parse(msg.meta)
                }
                if (!msg.meta.typeMessage || msg.meta.typeMessage == 'message') {
                    label = msg.message
                }
                if (msg.meta.typeMessage && msg.meta.typeMessage == 'file') {
                    label = 'Fichier'
                }
                if (msg.meta.typeMessage && msg.meta.typeMessage == 'link') {
                    label = 'Lien'
                }
                if (msg.meta.typeMessage && msg.meta.typeMessage == 'youtube') {
                    label = 'Vidéo Youtube'
                }
            }
            return label
        },

        async removeLinkMessage() {
            await this.$request.post('updatebloc', {
                action: 'bdc',
                type: 'message',
                current_id: this.msg.id,
                question_id: null
            }, 'districloud_preprod')
            this.getOutputs()
            this.$parent.$parent.refresh()
        },

        async removeLinkResponse(response) {
            await this.$request.post('attach_response', {
                action: 'bdc',
                type: 'message',
                question_id: null,
                reponse_id: response.id
            }, 'districloud_preprod')
            this.getOutputs()
            this.$parent.$parent.refresh()
        },

        async deleteBloc() {
            await this.$request.post('deletebloc', {
                action: 'bdc',
                type: 'message',
                current_id: this.id
            }, 'districloud_preprod')
            // Delete linked message
            for (const [key, value] of Object.entries(this.$parent.$parent.nodes)) {
                // Get linked node
                if (key && value.portsOut['msg-' + this.id] != undefined) {
                    // Delete linked message
                    if (value.data.type == 'message-bot') {
                        await this.$request.post('updatebloc', {
                            action: 'bdc',
                            type: 'message',
                            current_id: value.data.db.id,
                            question_id: null,
                        }, 'districloud_preprod')
                    }
                    // Delete linked response
                    if (value.data.type == 'message-user') {
                        await this.$request.post('updatebloc', {
                            action: 'bdc',
                            type: 'reponse',
                            current_id: value.data.db.id,
                            suivant_id: null
                        }, 'districloud_preprod')
                    }
                }
            }
            this.getOutputs()
            this.$parent.$parent.refresh()
            this.$modal.hide('modal-edit-node')
        },

        async addEndProcess(validExit) {
            await this.$request.post('updatebloc', {
                action: 'bdc',
                type: 'message',
                current_id: this.id,
                resolu: validExit
            }, 'districloud_preprod')
            await this.getOutputs()
            await this.$parent.$parent.refresh()
            this.$modal.hide('modal-edit-node')
        },

        async removeEndProcess() {
            await this.$request.post('updatebloc', {
                action: 'bdc',
                type: 'message',
                current_id: this.id,
                resolu: 0,
            }, 'districloud_preprod')
            this.getMsg()
            await this.$parent.$parent.refresh()
        },
    },
    mounted () {
        this.getMsg();
    },
}
</script>
