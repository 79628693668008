<template>
    <div></div>
</template>

<script>
export default {
    mounted() {
        localStorage.removeItem('auth')
        localStorage.removeItem('account')
        console.log(localStorage);
        location.href = '/login'
    }
}
</script>
