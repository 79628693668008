<template>
    <div>
        <modal name="modal-convert-claim" height="auto" :scrollable="true">
            <div class="p-10 min-h-[300px]" style="z-index: 999 !important;">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-xl">
                    <h2>Convertir en demande fournisseur</h2>
                    <button @click="$modal.hide('modal-convert-claim')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <button :disabled="loading || loadingSimilar" @click="convertClaim" class="px-4 py-2 bg-green-500 hover:bg-green-600 text-sm text-white font-light rounded mt-2 duration-200 cursor-pointer">
                    <template v-if="!loading"><i class="fa-solid fa-right-left mr-1"></i> Convertir</template>
                    <spinner v-if="loading" :size="10" />
                </button>
            </div>
        </modal>

        <button @click="$modal.show('modal-convert-claim')" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer rounded text-sm font-light">
            Convertir en demande fournisseur
        </button>
    </div>
</template>

<script>
export default {
    props: {
        claimId: {required: true},
        type: {required: true},
    },
    watch: {
        newCentraleId() {
            this.getSimilarShops()
        }
    },
    data() {
        return {
            newCentraleId: null,
            loading: false,
        }
    },
    methods: {
        async convertClaim() {
            let endObj = {
                claim_id: this.claimId,
                type: 'fournisseur'
            }
            this.loading = true
            let response = await this.$request.post('inscription.convert-type-claim', endObj)
            this.loading = false
            if (response.data.posts.post == 1) {
                location.reload()
            }
        },

        async getSimilarShops() {
            this.loadingSimilar = true
            let response = await this.$request.post('inscription.similar-magasin-from-claim', {
                claim_id: this.claimId,
                centrale_id: this.newCentraleId
            })
            this.loadingSimilar = false
            this.similarShops = response.data.posts.post != 1 ? response.data.posts.post : []
        }
    },
}
</script>

<style lang="scss" scoped>

</style>