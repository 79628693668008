<template>
    <div>
        <app-template>
            <div class="bg-white rounded-xl border shadow-sm p-4">
                <h2 class="text-2xl font-semibold mb-4 mt-2"><i class="fas fa-building mr-1"></i> {{$tt('Paramètres entreprise')}}</h2>
                <template v-if="typeEntite == 1">
                    <fournisseur-view :id="idEntite" :edit="true" :admin="false" />
                </template>
                <template v-if="typeEntite == 2">
                    <centrale-view :id="idEntite" :edit="true" :admin="false" />
                </template>
            </div>
        </app-template>
    </div>
</template>

<script>
import FournisseurView from '../Admin/Fournisseur/FournisseurView.vue';
import CentraleView from './../Admin/Centrale/CentraleView.vue'

export default {
    components: {
        CentraleView,
        FournisseurView
    },
    computed: {
        typeEntite() {
            return this.$request.user().account.type_entite
        },
        idEntite() {
            return this.$request.user().account.id_entite
        }
    },
}
</script>

<style lang="scss" scoped>

</style>