<template>
    <div>
        <div class="bg-white p-4 text-sm rounded border shadow-sm mt-6 mb-24">
            <div class="text-xl font-bold border-b pb-3 mb-3">{{ (index + 1) + '. ' + section.nom }}</div>
            <pricing-bloc
            v-for="(bloc, blocIndex) in blocs"
            :key="'bloc-' + blocIndex"
            :data-src="bloc"
            @change="updateBloc"
            @delete="deleteBloc"
            :index="blocIndex"
            />
            <div class="text-center mt-2">
                <button @click="addBlock()" class="bg-slate-200 hover:bg-slate-300 p-2 px-4 rounded"><i class="fas fa-plus mr-0.5"></i> Ajouter un bloc</button>
            </div>
        </div>
    </div>
</template>

<script>
import PricingBloc from './PricingBloc.vue';

export default {
    props: {
        section: {required: true},
        index: {required: true},
    },
    components: {
        PricingBloc,
    },
    watch: {
        section() {
            this.setBlocs()
        },
        blocs: {
            deep: true,
            handler() {
                this.emitChange()
            }
        },
    },
    data() {
        return {
            prix: 0,
            reduction: 0,
            blocs: []
        }
    },

    methods: {
        addBlock(title = null, lines = []) {
            this.blocs.push({
                title: title,
                lines: lines
            })
        },

        updateBloc(value) {
            this.blocs[value.index] = {
                title: value.bloc.title,
                lines: value.bloc.items
            }
        },

        deleteBloc(index) {
            this.blocs.splice(index, 1)
        },

        setBlocs() {
            if (this.section.blocs) {
                for (const key in this.section.blocs) {
                    this.addBlock(key, this.section.blocs[key].map((bloc) => {
                        return {name: bloc.nom}
                    }));
                }
            }
        },
        emitChange() {
            this.$emit('change', {blocs: this.blocs, index: this.index})
        }
    },

    mounted() {
        this.setBlocs()
    }
}
</script>

<style lang="scss" scoped>

</style>
