<template>
    <div>
        <app-template>
            <div class="bg-white p-10 rounded shadow">
                <data-table
                :server="true"
                :filters="[
                ]"
                :columns="[
                    {label: 'Date', key: 'created_at', type: 'datetime'},
                    {label: 'Type', key: 'type', type: 'text'},
                    {label: 'Opération', key: 'operation', type: 'text'},
                    {label: 'Erreur', key: 'error', type: 'text'},
                ]"
                :request="{
                    name: 'error.list',
                    params: {
                        page: 0,
                        per_page: 50,
                    },
                    apiName: 'api2'
                }"
                >
                    <template v-slot:actions='{line}'>
                        <div class="flex items-center">
                            <button @click="selectedLine = line; $modal.show('modal-confirm-resend')" class="flex items-center mr-1 text-xs bg-slate-200 hover:bg-slate-300 duration-200 cursor-pointer font-light px-3 py-1.5 rounded">
                                <i class="mr-1 fa-solid fa-rotate-right"></i> Renvoyer
                            </button>
                            <button @click="selectedLine = line; $modal.show('modal-display-mail')" class="mr-1 text-xs bg-orange-500 hover:bg-orange-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white">
                                <i class="fas fa-eye"></i>
                            </button>
                        </div>
                    </template>
                </data-table>
            </div>
        </app-template>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

</style>