<template>
    <div>
        <modal name="modal-cancel-avoir" height="auto" :scrollable="true">
            <div class="p-10">
                <template v-if="selectedAvoir">
                    <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                        <h2 class="font-bold">
                            Annuler un avoir
                        </h2>
                        <button @click="$modal.hide('modal-cancel-avoir'); motifCancel = null">
                            <i class="fas fa-times text-red-500"></i>
                        </button>
                    </div>
                    <div class="p-4 mb-4 text-sm text-orange-800 rounded-lg bg-orange-50" role="alert">
                        <p>Êtes-vous sûr de vouloir supprimer l'avoir <b>{{ selectedAvoir.num_doc }}</b> d'un montant de <b>{{ selectedAvoir.montant_ht }} € <small class="text-xs font-light">(ttc)</small></b> ?</p>
                    </div>
                    <input-rule v-model="motifCancel" label="Motif d'annulation" type="textarea" id="motif-cancel" :label-inline="false" />
                    <button :disabled="loaderModal" @click="cancelAvoir" class="bg-emerald-600 text-white px-4 py-2 rounded mt-2">
                        <template v-if="!loaderModal">
                            Confirmer <i class="fas fa-check ml-1"></i>
                        </template>
                        <template v-if="loaderModal">
                            <spinner :size="10" />
                        </template>
                    </button>
                </template>
            </div>
        </modal>

        <!-- Table -->
        <div class="flex justify-center">
            <spinner class="m-5" v-if="$parent.$parent.getAvoirListLoading" :size="14" />
        </div>
        <div v-if="!$parent.$parent.getAvoirListLoading" class="relative overflow-x-auto">
            <data-table
            :server="true"
            :columns="[
                {label: 'Créé le', key: 'date_doc', type: 'date'},
                {label: 'Numéro Avoir', key: 'num_doc', type: 'text'},
                {label: 'HT', key: 'montant_ht', type: 'text'},
                {label: 'TVA', key: 'montant_tva', type: 'text'},
                {label: 'TTC', key: 'montant_ttc', type: 'text'},
            ]"
            :request="{
                name: 'A5141listavoir',
                params: {
                    per_page: 30,
                    id_user: userId,
                    id_centrale_ha: selectedSign
                },
                apiName: 'districloud_no_action'
            }"
            >
                <template v-slot:actions='{line}'>
                    <a :href="line.url" target="_blank" class="text-emerald-600 hover:text-emerald-700 text-sm duration-200 cursor-pointer text-lg">
                        <i class="fas fa-circle-down"></i>
                    </a>
                    <!-- <button @click="displayCancelAvoirModal(line)" class="ml-1 text-red-600 hover:text-red-700 text-sm duration-200 cursor-pointer text-lg">
                        <i class="fas fa-circle-xmark"></i>
                    </button> -->
                </template>
            </data-table>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import apiService from '@/services/api.service'

export default {
    props: {
        selectedSign: {
            required: true
        },
    },
    data() {
        return {
            selectedAvoir: null,
            motifCancel: null,
            
            getAvoirLoading: false,
            loaderModal: false,
        }
    },
    computed: {
        userId() {
            return apiService.user().account.id
        }
    },
    methods: {
        parseDate(date) {
            return moment(date).format('DD/MM/YYYY')
        },

        async displayAvoirDetail(avoir) {
            this.$modal.show('avoir-detail')
            this.getAvoirLoading = true
            let response = await apiService.post('A5141listdscacc', {
                id_user: apiService.user().account.id,
                conditions: '["num_doc_avoir=\'' + avoir.num_doc + '\'"]'
            }, 'districloud_no_action')
            this.selectedAvoir = avoir
            this.selectedAvoir.folders = response.data.posts.post
            this.getAvoirLoading = false
        },

        displayCancelAvoirModal(line) {
            this.selectedAvoir = line
            this.$modal.show('modal-cancel-avoir')
        },

        async cancelAvoir() {
            if (!this.$validation.check([
                {value: this.motifCancel, rules: 'required', id: 'motif-cancel'},
            ])) {return}

            this.loaderModal = true
            this.loaderModal = false
        },
        // overwriteLines(value) {
        //     value.callback([value.lines[0]])
        // }
    },
}
</script>
