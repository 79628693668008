<template>
    <div>
        <multiselect
        v-model="inpValue"
        :options="items"
        :multiple="multiple"
        :label="label"
        :track-by="keyTrack"
        placeholder=""
        class="w-full"
        />
    </div>
</template>

<script>
import apiService from '@/services/api.service'

export default {
    props: {
        defaultItems: {
            type: Array,
        },
        api: {},
        label: {},
        keyTrack: {},
        multiple: {}
    },
    data() {
        return {
            inpValue: null,
            items: []
        }
    },
    methods: {
        async getLines() {
            let response = await apiService.post(this.api.action, this.api.params, this.api.apiName)
            this.items = response.data.posts.post
        }
    },
    mounted () {
        if (this.defaultItems) {
            this.items = this.defaultItems
        }
        if (this.api && !this.defaultItems) {
            this.getLines()
        }
    },
}
</script>
