import axios from 'axios'
import CryptoTool from '@/tools/CryptoTool'

import confService from './conf/conf.service'

import EventHub from '@/eventHub';

function getUserAccount()
{
    let accountData = CryptoTool.decrypt(localStorage['account'])
    if (accountData) {
        const account = JSON.parse(accountData)
        return account
    }
}

function convertObjToFormData(keyname, obj, keyType, id_type)
{
    // 1 : fournisseur
    // 2 : enseigne
    // 3 : magasin
    // 4 et 5 : station de réparation
    // 6 : technicien

    var formData = new FormData();

    for (var key in obj) {
        formData.append(key, obj[key]);
    }

    formData.append(keyType, keyname)
    
    const account = getUserAccount()
    if (account) {
        formData.append(id_type, account.account.id_entite)
        formData.append('token', account.account.token)
        if (!obj || !obj.lang) {
            formData.append('lang', (localStorage.getItem('lang') || 'fr'))
        }
    }

    return formData
}

const ApiService = {
    post: async function (keyname, obj, apiName = 'api2', url = null) {
        let apiConf = confService[apiName]
        let response = await axios.post(url || apiConf.url, convertObjToFormData(keyname, obj, apiConf.type, (apiConf.id_type || 'id_grossite')))
        if (response?.data?.posts?.displayError) {
            EventHub.$emit('error-toast', response.data.posts.error)
        }
        return response
    },
    user: function() {
        return getUserAccount()
    }
}
export default ApiService
