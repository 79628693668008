<template>
    <div>
        <div class="py-4" v-if="loaderGetData">
            <div class="flex justify-center">
                <spinner :size="12" />
            </div>
            <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
        </div>
        <template v-if="!loaderGetData && model">
            <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mb-4">
                <ul class="flex flex-wrap -mb-px">
                    <li @click="selectedTab = 'dossier'" class="mr-2">
                        <span class="inline-block p-4 rounded-t-lg border-b-2 cursor-pointer" :class="selectedTab == 'dossier' ? 'text-blue-600 border-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'">Dossier</span>
                    </li>
                    <li @click="selectedTab = 'appareil'" class="mr-2">
                        <span class="inline-block p-4 rounded-t-lg border-b-2 cursor-pointer" :class="selectedTab == 'appareil' ? 'text-blue-600 border-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'">Appareil</span>
                    </li>
                    <li @click="selectedTab = 'documents'" class="mr-2">
                        <span class="inline-block p-4 rounded-t-lg border-b-2 cursor-pointer" :class="selectedTab == 'documents' ? 'text-blue-600 border-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'">Documents</span>
                    </li>
                    <li @click="selectedTab = 'historique'" class="mr-2">
                        <span class="inline-block p-4 rounded-t-lg border-b-2 cursor-pointer" :class="selectedTab == 'historique' ? 'text-blue-600 border-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'">Historique</span>
                    </li>
                </ul>
            </div>

            <!-- Dossier -->
            <template v-if="selectedTab == 'dossier'">
                <div class="grid lg:grid-cols-3 gap-2">
                    <div>
                        <input-rule v-model="model.num_dsc" :display-value="!edit" type="text" class="mb-2" :label-inline="false" label="Numéro dossier" id="inp-dsc" />
                        <input-rule v-model="model.num_serie" :display-value="!edit" type="text" class="mb-2" :label-inline="false" label="Numéro serie" id="inp-num_serie" />
                        <input-rule v-model="model.descript" :display-value="!edit" type="text" class="mb-2" :label-inline="false" label="Description" id="inp-description" />
                    </div>
                    <div>
                        <input-rule v-model="model.date_achat" :display-value="!edit" type="date" class="mb-2" :label-inline="false" label="Date d'achat" id="inp-date_ha" />
                        <input-rule v-model="model.date_demande" :display-value="!edit" type="date" class="mb-2" :label-inline="false" label="Date demande" id="inp-date_demande" />
                        <input-rule v-model="model.date_accept" :display-value="!edit" type="date" class="mb-2" :label-inline="false" label="Date accepté" id="inp-date_accept" />
                        <input-rule v-model="model.date_refus" :display-value="!edit" type="date" class="mb-2" :label-inline="false" label="Date de refus" id="inp-date_refus" />
                    </div>
                    <div>
                        <input-rule v-model="model.comment_interne" :display-value="!edit" type="textarea" class="mb-2" :label-inline="false" label="Commentaire interne" id="inp-commentaire_interne" />
                        <input-rule v-model="model.comment_refus" :display-value="!edit" type="textarea" class="mb-2" :label-inline="false" label="Commentaire refus" id="inp-commentaire_refus" />
                    </div>
                </div>
                <div class="text-center">
                    <button :disabled="loaderEditAdd" class="px-4 py-2 rounded my-1 bg-green-200 hover:bg-green-300 duration-200 text-sm" @click="editAdd">
                        <template v-if="!loaderEditAdd">
                            <template v-if="model?.id">Modifier <i class="fas fa-pen ml-1"></i></template>
                            <template v-if="!model?.id">Enregistrer <i class="fas fa-plus ml-1"></i></template>
                        </template>
                        <template v-else>
                            <spinner :size="10" />
                        </template>
                    </button>
                </div>
            </template>

            <!-- Appareil -->
            <template v-if="selectedTab == 'appareil'">
                <appareil-view :id="model.id_appareil" :edit="true" />
            </template>

            <!-- Documents -->
            <template v-if="selectedTab == 'documents'">
                <div class="grid grid-cols-8 gap-4">
                    <a class="group" :href="doc.url" target="_blank" v-for="(doc, docIndex) in model.documents" :key="'dossier-doc-' + docIndex">
                        <div class="text-3xl text-center py-6 rounded opacity-60 group-hover:opacity-100 duration-200" :class="docBgColor(doc.type)">
                            <template v-if="doc.type == 'application/pdf'">
                                <i class="fa-regular fa-file-pdf"></i>
                            </template>
                            <template v-else>
                                <i class="fa-regular fa-file"></i>
                            </template>
                        </div>
                        <div class="text-sm font-semibold text-center mt-2">{{ doc.type_doc }}</div>
                        <div class="text-xs font-thin text-center mt-0.5">{{ doc.date_upload | momentDate }}</div>
                    </a>
                </div>
            </template>

            <!-- Avancement -->
            <template v-if="selectedTab == 'historique'">
                <ol class="relative border-l border-gray-200 mt-5">                  
                    <li v-for="(avancement, avancementIndex) in model.avancements" class="mb-5 ml-4" :key="'avancement-' + avancementIndex">
                        <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white"></div>
                        <div class="font-bold">{{ avancement.libelle_av }}</div>
                        <div class="font-light text-xs mt-0.5">{{avancement.date_av | momentDateTime}}</div>
                    </li>
                </ol>
            </template>
        </template>
    </div>
</template>

<script>
import moment from 'moment'
import AppareilView from '../Appareil/AppareilView.vue'

export default {
    props: {
        id: {},
        edit: {
            type: Boolean,
        },
        dataSrc: {},
    },

    components: {
        AppareilView,
    },

    data() {
        return {
            model: null,
            loaderGetData: false,
            loaderEditAdd: false,
            loadingModal: false,
            selectedTab: 'dossier'
        }
    },

    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },

    methods: {
        async getData(id = null) {
            this.loaderGetData = true
            if (id || this.id) {
                let response = await this.$request.post('dossier.dsc.view', {
                    line_id: id || this.id
                })
                if (response.data.posts.post) {
                    this.model = response.data.posts.post
                }
            } else {
                this.model = {}
            }
            this.loaderGetData = false
        },

        async editAdd() {
            if (!this.$validation.check([
                // {value: this.model.contact_mail, rules: 'required|email', id: 'inp-contact_email'},
            ])) {return}

            this.loaderEditAdd = true
            let endObj = {...this.model}
            if (endObj.id) {
                endObj.line_id = endObj.id
                delete endObj.id
            }
            for (let key in endObj) {
                if (endObj[key] === null || endObj[key] === undefined) {
                    delete endObj[key]
                }
            }
            let response = await this.$request.post('dossier.edit-add', endObj)
            if (response.data.posts.post) {
                this.$emit('update', response.data.posts.post)
                this.getData(response.data.posts.post.id)
                this.$toast.success(endObj.id ? 'Dossier modifié' : 'Dossier enregistré')
            } else {
                this.$toast.error("Erreur lors de l'enregistrement")
            }
            this.loaderEditAdd = false
        },

        docBgColor(keyword) {
            let types = {
                'application/pdf': 'bg-indigo-200'
            }
            return types[keyword] || 'bg-slate-200'
        }
    },

    mounted () {
        this.getData();

        if (this.dataSrc) {
            this.model = this.dataSrc
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
