<template>
    <div>
        <app-template>
            <!-- Modals -->
            <modal name="modal-confirm-delete-line" height="auto" width="70%" :scrollable="true">
                <div class="p-10 text-center" v-if="selectedLine">
                    <h2 class="font-bold text-3xl mb-3">Confirmer la suppression</h2>
                    <p>Souhaitez-vous réellement supprimer l'utilisateur <b>{{ selectedLine.client_id }}</b> ?</p>
                    <div class="mt-3">
                        <button @click="$modal.hide('modal-confirm-delete-line')" class="mr-2 text-sm bg-slate-300 hover:bg-slate-400 duration-200 cursor-pointer font-light px-4 py-2.5 rounded-xl" type="submit">
                            Non <i class="fas fa-times ml-1"></i>
                        </button>
                        <button @click="deletePartner(selectedLine.client_id)" class="text-sm bg-red-500 hover:bg-red-600 duration-200 cursor-pointer font-light px-4 py-2.5 rounded-xl text-white" type="submit">
                            Oui <i class="fas fa-check ml-1"></i>
                        </button>
                    </div>
                </div>
            </modal>

            <modal name="modal-edit-add-line" height="auto" width="70%" :scrollable="true">
                <div class="p-10" v-if="form">
                    <input-rule class="mb-4" v-model="form.client_id" type="text" label="Client ID" :custom="selectedLine && selectedLine.client_id ? 'bg-gray-300 cursor-no-drop' : ''" :label-inline="false" :disabled="selectedLine && selectedLine.client_id" />
                    <input-rule class="mb-4" v-model="form.username" type="text" label="Username" :label-inline="false" />
                    <input-rule class="mb-4" v-model="form.password" type="text" label="Mot de passe" :label-inline="false" />
                    <input-rule class="mb-4" v-model="form.production" type="pcheck" label="Production" :label-inline="false" />

                    <div class="border-t text-center">
                        <button :disabled="sendLoading" @click="sendData" class="mt-4 px-8 py-3 font-bold text-white duration-100 duration-200 bg-green-600 border-green-700 hover:bg-green-700 rounded-xl">
                            <div v-show="!sendLoading">
                                <i class="fas fa-check mr-1"></i> Enregistrer
                            </div>
                            <div v-if="sendLoading">
                                <spinner :size="12" />
                            </div>
                        </button>
                    </div>
                </div>
            </modal>

            <!-- Page -->
            <div class="text-right mb-5">
                <button @click="selectedLine = null; $modal.show('modal-edit-add-line')" class="text-sm font-bold bg-green-500 hover:bg-green-600 duration-200 cursor-pointer px-5 py-3 rounded-xl text-white" type="submit">
                    Ajouter <i class="fas fa-plus ml-1"></i>
                </button>
            </div>

            <div class="bg-white p-7 rounded-xl border shadow-sm mb-16">
                <data-table
                v-if="!getDataLoading"
                :filters-inline="false"
                :filters="[
                    {key: 'client_id', label: 'Client ID', type: 'text', value: null},
                    {key: 'username', label: 'Username', type: 'text', value: null},
                ]"
                :columns="[
                    {label: 'Client ID', key: 'client_id', type: 'text'},
                    {label: 'Username', key: 'username', type: 'text'},
                    {label: 'Password', key: 'password', type: 'text'},
                    {label: 'Production', key: 'production', type: 'boolean'},
                ]"
                :lines="partners"
                >
                    <template v-slot:actions='{line}'>
                        <button @click="editLineModal(line)" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                            <i class="fas fa-pen"></i>
                        </button>
                        <button @click="selectedLine = line; $modal.show('modal-confirm-delete-line')" class="mr-1 text-xs bg-red-500 hover:bg-red-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                            <i class="fas fa-trash"></i>
                        </button>
                    </template>
                </data-table>

                <spinner :size="12" v-if="getDataLoading" />
            </div>
        </app-template>
    </div>
</template>

<script>
import apiService from '@/services/api.service'

export default {
    data() {
        return {
            sendLoading: false,
            getDataLoading: false,
            partners: [],
            form: null,
            selectedLine: null
        }
    },
    methods: {
        initForm() {
            this.form = null
            this.$nextTick(() => {
                this.form = {
                    client_id: null,
                    username: null,
                    password: null,
                    production: null,
                }
            })
        },

        async getPartners() {
            this.getDataLoading = true
            let response = await apiService.post('ecosysteme.get-partners', {})
            this.partners = response.data.posts.post
            this.getDataLoading = false
        },

        async sendData() {
            this.sendLoading = true
            for (let formInput in this.form) {
                if (!this.form[formInput]) {
                    this.$toast.error('Formulaire incomplet')
                    return
                }
            }
            let response = await apiService.post('ecosysteme.edit-add-partners', {
                client_id: this.form.client_id,
                username: this.form.username,
                password: this.form.password,
                production: this.form.production ? 1 : 0,
            })
            this.sendLoading = false
            if (response.data.posts.error) {
                this.$toast.error(response.data.posts.error)
            }
            this.$modal.hide('modal-edit-add-line')
            this.initForm()
            await this.getPartners()
        },

        editLineModal(line) {
            this.selectedLine = line
            this.form = {
                client_id: line.client_id,
                username: line.username,
                password: line.password,
                production: line.production,
            }
            this.$modal.show('modal-edit-add-line')
        },

        async deletePartner(clientId) {
            await apiService.post('ecosysteme.remove-partners', {
                client_id: clientId,
            })
            this.$modal.hide('modal-confirm-delete-line')
            await this.getPartners()
        }
    },
    mounted () {
        this.initForm()
        this.getPartners()
    },
}
</script>
