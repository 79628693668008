<template>
    <div>
        <app-template>
            <div class="bg-white p-10 rounded-xl border shadow-sm mt-6 mb-24">
                <template v-if="displayForm">
                    <div class="w-1/2 mx-auto">
                        <div class="mb-6">
                            <div v-if="accessAccount" class="bg-green-600 text-white font-bold rounded p-4 text-center">
                                <i class="fas fa-circle-check text-xl mr-1"></i> Vous êtes connecté à Ecosysteme
                            </div>
                            <div v-if="!accessAccount" class="bg-red-700 text-white font-bold rounded p-4 text-center">
                                <i class="fas fa-times text-xl mr-1"></i> Vous n'êtes pas connecté à Ecosysteme
                            </div>
                        </div>
                        <card-title title="Identifiants">
                            <input-rule class="mb-4" v-model="username" type="text" rules="required" label="Nom d'utilisateur ecosystème" :display-errors="displayErrors" />
                            <input-rule class="mb-4" v-model="password" type="text" rules="required" label="Mot de passe" :display-errors="displayErrors" />
                            <div class="text-center">
                                <button :disabled="sendLoading" @click="sendForm" class="mt-2 px-5 py-2 text-sm text-white duration-100 duration-200 bg-green-600 border-green-700 hover:bg-green-700 rounded-xl">
                                    <template v-if="!sendLoading">
                                        <i class="fas fa-check mr-1"></i> Valider
                                    </template>
                                    <spinner v-else :size="10" />
                                </button>
                            </div>
                        </card-title>
                    </div>
                </template>
                <div class="flex justify-center" v-else>
                    <spinner :size="10" />
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import apiService from '@/services/api.service'

export default {
    data() {
        return {
            accessAccount: null,
            sendLoading: false,
            displayErrors: false,
            username: null,
            password: null,
            displayForm: false
        }
    },
    methods: {
        async sendForm() {
            if (!this.username || !this.password) {
                this.$toast.error('Formulaire incomplet')
                this.displayErrors = true
                return
            } else {
                this.displayErrors = false
            }

            this.sendLoading = true
            let response = await apiService.post('ecosysteme.save-eco-account', {
                username: this.username,
                password: this.password,
            })
            if (!response.data.posts.error) {
                this.$toast.success('Identifiants mis à jour')
                await this.hasEcoAccount()
            } else {
                this.$toast.error(response.data.posts.error)
            }
            this.sendLoading = false
        },

        async hasEcoAccount() {
            let response = await apiService.post('ecosysteme.has-eco-access', {})
            this.accessAccount = response.data.posts.post
            if (this.accessAccount) {
                this.username = this.accessAccount.username
                this.password = this.accessAccount.password
            }
            this.displayForm = true
        }
    },
    mounted () {
        this.hasEcoAccount()
    },
}
</script>
