<template>
    <div>
        <!-- Modal confirm delete -->
        <modal name="modal-confirm-delete-zone" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedLine">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        Confirmer la suppression
                    </h2>
                    <button @click="$modal.hide('modal-confirm-delete-zone')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="text-lg">Souhaitez-vous supprimer la zone {{ selectedLine.nom }} ?</div>
                    <div class="bg-orange-100 text-orange-800 p-4 rounded mt-4 font-bold">
                        <i class="fas fa-exclamation-triangle mr-1"></i>
                        {{ $tt(('En supprimant cette zone, tous les tarifs liés à cette zone seront également supprimés')) }}
                    </div>
                    <div class="flex gap-2 items-center mt-4">
                        <button @click="$modal.hide('modal-confirm-delete-zone')" class="px-4 py-2 rounded text-sm bg-slate-200 hover:bg-slate-300 duration-200">Annuler <i class="fas fa-times ml-1"></i></button>
                        <button :disabled="loadingDelete" @click="deleteZone" class="px-4 py-2 rounded text-sm bg-red-500 hover:bg-red-600 duration-200 text-white">
                            <spinner :size="10" v-if="loadingDelete" />
                            <template v-if="!loadingDelete">
                                {{$tt('Supprimer')}} <i class="fas fa-trash ml-1"></i>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <!-- Modal edit address -->
        <modal name="modal-edit-add-zone" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedZoneEdit">
                <div class="flex items-center justify-between mb-4 pb-4 border-b">
                    <div>
                        <h2 class="text-xl font-semibold">
                            <template v-if="!selectedZoneEdit.id">{{$tt("Ajouter une zone")}}</template>
                            <template v-if="selectedZoneEdit.id">{{$tt("Modifier la zone")}}</template>
                        </h2>
                    </div>
                    <button @click="$modal.hide('modal-edit-add-zone')" class="text-2xl">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <input-rule v-model="selectedZoneEdit.nom" type="text" :label="$tt('Nom')" :label-inline="false" />
                    <button @click="saveZone" :disabled="loadingSaveZone" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-sm font-bold text-white rounded mt-4">
                        <spinner v-if="loadingSaveZone" :size="10" />
                        <template v-if="!loadingSaveZone">
                            <i class="fas fa-check mr-1"></i> {{ $tt('Enregistrer') }}
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <div class="grid grid-cols-10 gap-4">
            <div class="col-span-2 mt-2">
                <h2 class="text-lg font-bold mb-2 pb-2 border-b">{{ $tt('Mes zones') }}</h2>
                <button @click="selectedZoneEdit = {}; $modal.show('modal-edit-add-zone')" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 rounded text-sm mb-4"><i class="fas fa-plus mr-1"></i> {{ $tt('Nouvelle zone') }}</button>
                <div v-if="loadingGetZones" class="p-2">
                    <spinner :size="10" />
                </div>
                <div class="border-t p-2 flex items-center gap-2 justify-between cursor-pointer" :class="selectedZone && selectedZone.id == zone.id ? 'text-blue-500 font-bold' : 'hover:bg-slate-100 duration-200'" v-for="(zone, zoneIndex) in zones" :key="'zone-' + zoneIndex" @click="selectZone(zone)">
                    <div>{{ zone.nom }}</div>
                    <div class="flex items-center gap-3">
                        <button @click.stop="selectedZoneEdit = zone; $modal.show('modal-edit-add-zone')" class="opacity-40 hover:opacity-100 duration-200 text-sm"><i class="fas fa-pen"></i></button>
                        <button @click.stop="selectedLine = zone; $modal.show('modal-confirm-delete-zone')" class="text-red-500 opacity-40 hover:opacity-100 duration-200 text-sm"><i class="fas fa-trash"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-span-8">
                <!-- <input-rule
                type="api-select"
                :options="{
                    api: {action: 'reparateur.search-ville', apiName: 'api2', options: {limit: 100, orderBy: 'ville_nom_reel'}},
                    label: 'ville_nom_reel',
                    init: false,
                    label2: 'ville_code_postal'
                }"
                :label-inline="false"
                :label="$tt('Rechercher une ville')"
                class="mb-4"
                /> -->
                <div v-if="!selectedZone">
                    <div class="text-center mt-10 text-slate-300">
                        <h2 class="text-2xl font-bold">{{ $tt('Séléctionner ou créer une zone') }}</h2>
                        <p class="font-light">{{ $tt('Dans le menu sur votre gauche') }}</p>
                    </div>
                </div>
                <div v-if="selectedZone" class="grid grid-cols-3 gap-2 mt-2">
                    <div>
                        <h2 class="text-lg font-bold mb-2 pb-2 border-b">{{ $tt('Régions') }}</h2>
                        <div v-for="(cp, cpIndex) in cps" :key="'cp-' + cpIndex" :class="cp.open ? 'my-4' : ''">
                            <div class="text-lg cursor-pointer" :class="selectedDepart && selectedDepart.reg_name == cp.title ? 'opacity-100 text-blue-500' : 'opacity-50 hover:opacity-100 duration-200'" @click="cp.open = !cp.open; $forceUpdate()">{{ cp.title }}</div>
                            <div class="pl-4" v-if="cp.open">
                                <div v-for="(item, itemIndex) in cp.data" :key="'cp-' + itemIndex" class="opacity-60 hover:opacity-100 duration-200 cursor-pointer" :class="selectedDepart && selectedDepart.dep_name == item.dep_name ? 'opacity-100 text-blue-500' : 'opacity-50 hover:opacity-100 duration-200'" @click="selectedDepart = item">
                                    {{ item.code + ' ' + item.dep_name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2 class="text-lg font-bold mb-2 pb-2 border-b">{{ selectedDepart ? selectedDepart.dep_name : $tt('Villes du département') }}</h2>
                        <div v-if="selectedDepart && !loadingGetVilles" class="flex items-center mb-2">
                            <div class="border p-2.5 bg-slate-50 px-3 text-sm rounded-l">
                                <i class="fas fa-search"></i>
                            </div>
                            <input v-model="searchVille" @input="filterVille" class="border border-l-0 text-sm p-2.5 rounded rounded-l-none w-full focus:outline-none" type="text" :label-inline="false" :placeholder="$tt('Rechercher une ville (cp, nom de ville)')" />
                        </div>
                        <div v-if="loadingGetVilles" class="p-4"><spinner :size="10" /></div>
                            <template v-if="selectedDepart">
                                <div class="max-h-[700px] overflow-y-auto" v-if="!loadingGetVilles">
                                    <div class="border-b p-2 cursor-copy hover:bg-green-100 duration-200 prevent-select" v-for="(ville, villeIndex) in notSelectedVilles" :key="'depart-ville-' + villeIndex" @click="addVille(ville)">
                                        <span class="text-xs bg-slate-100 p-1 px-2 rounded font-semibold">{{ ville.ville_code_postal }}</span>
                                        <span class="font-light text-sm ml-2">{{ ville.ville_nom_reel }}</span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    <div>
                        <h2 class="text-lg font-bold mb-2 pb-2 border-b">{{ $tt('Villes séléctionnées dans la zone') + ' (' + selectedVilles.length + ')' }}</h2>
                        <div class="max-h-[600px] overflow-y-auto">
                            <div class="prevent-select border-b p-2 cursor-w-resize bg-green-100 hover:bg-red-100 duration-200 focus:outline-none" v-for="(ville, villeIndex) in selectedVilles" :key="'depart-ville-' + villeIndex" @click="selectedVilles.splice(villeIndex, 1); setCpsInZone()">
                                <span class="text-xs bg-slate-100 p-1 px-2 rounded font-semibold">{{ ville.ville_code_postal }}</span>
                                <span class="font-light text-sm ml-2">{{ ville.ville_nom_reel }}</span>
                            </div>
                        </div>
                        <!-- <div class="text-center">
                            <button :disabled="loadingSaveZone" @click="setCpsInZone" class="px-4 py-2 rounded font-bold bg-green-600 hover:bg-green-700 duration-200 mt-4 text-white">
                                <spinner :size="10" v-if="loadingSaveZone" />
                                <template v-if="!loadingSaveZone">
                                    <i class="fas fa-check mr-1"></i> {{ $tt('Sauvegarder la zone') }}
                                </template>
                            </button>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-span-2"></div>
        </div>
    </div>
</template>

<script>
export default {
    watch: {
        selectedDepart: {
            deep: true,
            handler() {
                this.getDepartVilles()
            }
        }
    },
    data() {
        return {
            zones: null,
            selectedZone: null,
            selectedZoneEdit: null,
            selectedDepart: null,
            departVilles: [],
            selectedVilles: [],

            searchVille: null,
            villesList: [],

            selectedLine: null,

            loadingGetVilles: false,
            loadingGetZones: false,
            loadingSaveZone: false,
            loadingDelete: false,
        }
    },
    methods: {
        async getZones() {
            this.loadingGetZones = true
            this.zones = []
            let response = await this.$request.post('reparateur.get-zones')
            this.zones = response.data.posts.post
            this.loadingGetZones = false
        },

        async saveZone() {
            let endData = {...this.selectedZoneEdit}
            if (endData.id) {
                endData.line_id = endData.id
                delete endData.id
            }
            this.loadingSaveZone = true
            let response = await this.$request.post('reparateur.edit-add-zone', endData)
            if (response.data.posts.post == 1) {
                await this.getZones()
                this.selectZone(this.zones.find((item) => item.nom == endData.nom))
                this.$modal.hide('modal-edit-add-zone')
            }
            this.loadingSaveZone = false
        },

        async deleteZone() {
            this.loadingDelete = true
            let response = await this.$request.post('reparateur.delete-zone', {
                line_id: this.selectedLine.id
            })
            this.loadingDelete = false
            if (response.data.posts.post == 1) {
                await this.getZones()
                this.$modal.hide('modal-confirm-delete-zone')
            }
        },

        selectZone(zone) {
            this.selectedZone = zone
            this.selectedVilles = []
            zone.cities.forEach((item) => {
                this.selectedVilles.push({
                    ville_nom_reel: item.ville,
                    ville_code_postal: item.cp,
                    ville_id: item.id_ville
                })
            })
        },

        async getDepartVilles() {
            this.loadingGetVilles = true
            let response = await this.$request.post('reparateur.get-departement-villes', {
                depart_code: this.selectedDepart.code,
            })
            this.departVilles = response.data.posts.post
            this.villesList = response.data.posts.post
            this.loadingGetVilles = false
        },

        async setCpsInZone() {
            await this.$request.post('reparateur.zone.edit-cp', {
                zone_id: this.selectedZone.id,
                villes: JSON.stringify(this.selectedVilles)
            })
            // if (response.data.posts.post == 1) {
            //     this.$toast.success(this.$tt('Zone enregistrée'))
            //     this.getZones()
            // } else {
            //     this.$toast.error(this.$tt('Une erreur est survenue'))
            // }
        },

        addVille(ville) {
            this.selectedVilles.push(ville)
            this.setCpsInZone()

            if (this.notSelectedVilles.length <= 0) {
                this.searchVille = null
                this.filterVille()
            }
        },

        filterVille() {
            let isNumeric = (n) => {
                return !isNaN(parseFloat(n)) && isFinite(n);
            }

            if (!this.searchVille) {
                this.villesList = this.departVilles
                return
            }

            if (isNumeric(this.searchVille)) {
                this.villesList = this.departVilles.filter((item) => item.ville_code_postal.startsWith(this.searchVille))
            } else {
                this.villesList = this.departVilles.filter((item) => item.ville_nom_reel.toLowerCase().includes(this.searchVille.toLowerCase().replace(' ', '-')))
            }
        }
    },
    mounted () {
        this.getZones();
    },

    computed: {
        notSelectedVilles() {
            if (this.villesList) {
                return this.villesList.filter((item) => !this.selectedVilles.find((sVille) => sVille.ville_code_postal == item.ville_code_postal && sVille.ville_nom_reel == item.ville_nom_reel))
            } else {
                return []
            }
        },

        cps() {
            var groupBy = function(xs, key) {
                return xs.reduce(function(rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            };
            let cps =  [
                {
                    "code": "01",
                    "dep_name": "Ain",
                    "reg_name": "Auvergne-Rhône-Alpes"
                },
                {
                    "code": "02",
                    "dep_name": "Aisne",
                    "reg_name": "Hauts-de-France"
                },
                {
                    "code": "03",
                    "dep_name": "Allier",
                    "reg_name": "Auvergne-Rhône-Alpes"
                },
                {
                    "code": "04",
                    "dep_name": "Alpes-de-Haute-Provence",
                    "reg_name": "Provence-Alpes-Côte d'Azur"
                },
                {
                    "code": "05",
                    "dep_name": "Hautes-Alpes",
                    "reg_name": "Provence-Alpes-Côte d'Azur"
                },
                {
                    "code": "06",
                    "dep_name": "Alpes-Maritimes",
                    "reg_name": "Provence-Alpes-Côte d'Azur"
                },
                {
                    "code": "07",
                    "dep_name": "Ardèche",
                    "reg_name": "Auvergne-Rhône-Alpes"
                },
                {
                    "code": "08",
                    "dep_name": "Ardennes",
                    "reg_name": "Grand Est"
                },
                {
                    "code": "09",
                    "dep_name": "Ariège",
                    "reg_name": "Occitanie"
                },
                {
                    "code": "10",
                    "dep_name": "Aube",
                    "reg_name": "Grand Est"
                },
                {
                    "code": "11",
                    "dep_name": "Aude",
                    "reg_name": "Occitanie"
                },
                {
                    "code": "12",
                    "dep_name": "Aveyron",
                    "reg_name": "Occitanie"
                },
                {
                    "code": "13",
                    "dep_name": "Bouches-du-Rhône",
                    "reg_name": "Provence-Alpes-Côte d'Azur"
                },
                {
                    "code": "14",
                    "dep_name": "Calvados",
                    "reg_name": "Normandie"
                },
                {
                    "code": "15",
                    "dep_name": "Cantal",
                    "reg_name": "Auvergne-Rhône-Alpes"
                },
                {
                    "code": "16",
                    "dep_name": "Charente",
                    "reg_name": "Nouvelle-Aquitaine"
                },
                {
                    "code": "17",
                    "dep_name": "Charente-Maritime",
                    "reg_name": "Nouvelle-Aquitaine"
                },
                {
                    "code": "18",
                    "dep_name": "Cher",
                    "reg_name": "Centre-Val de Loire"
                },
                {
                    "code": "19",
                    "dep_name": "Corrèze",
                    "reg_name": "Nouvelle-Aquitaine"
                },
                {
                    "code": "21",
                    "dep_name": "Côte-d'Or",
                    "reg_name": "Bourgogne-Franche-Comté"
                },
                {
                    "code": "22",
                    "dep_name": "Côtes-d'Armor",
                    "reg_name": "Bretagne"
                },
                {
                    "code": "23",
                    "dep_name": "Creuse",
                    "reg_name": "Nouvelle-Aquitaine"
                },
                {
                    "code": "24",
                    "dep_name": "Dordogne",
                    "reg_name": "Nouvelle-Aquitaine"
                },
                {
                    "code": "25",
                    "dep_name": "Doubs",
                    "reg_name": "Bourgogne-Franche-Comté"
                },
                {
                    "code": "26",
                    "dep_name": "Drôme",
                    "reg_name": "Auvergne-Rhône-Alpes"
                },
                {
                    "code": "27",
                    "dep_name": "Eure",
                    "reg_name": "Normandie"
                },
                {
                    "code": "28",
                    "dep_name": "Eure-et-Loir",
                    "reg_name": "Centre-Val de Loire"
                },
                {
                    "code": "29",
                    "dep_name": "Finistère",
                    "reg_name": "Bretagne"
                },
                {
                    "code": "2A",
                    "dep_name": "Corse-du-Sud",
                    "reg_name": "Corse"
                },
                {
                    "code": "2B",
                    "dep_name": "Haute-Corse",
                    "reg_name": "Corse"
                },
                {
                    "code": "30",
                    "dep_name": "Gard",
                    "reg_name": "Occitanie"
                },
                {
                    "code": "31",
                    "dep_name": "Haute-Garonne",
                    "reg_name": "Occitanie"
                },
                {
                    "code": "32",
                    "dep_name": "Gers",
                    "reg_name": "Occitanie"
                },
                {
                    "code": "33",
                    "dep_name": "Gironde",
                    "reg_name": "Nouvelle-Aquitaine"
                },
                {
                    "code": "34",
                    "dep_name": "Hérault",
                    "reg_name": "Occitanie"
                },
                {
                    "code": "35",
                    "dep_name": "Ille-et-Vilaine",
                    "reg_name": "Bretagne"
                },
                {
                    "code": "36",
                    "dep_name": "Indre",
                    "reg_name": "Centre-Val de Loire"
                },
                {
                    "code": "37",
                    "dep_name": "Indre-et-Loire",
                    "reg_name": "Centre-Val de Loire"
                },
                {
                    "code": "38",
                    "dep_name": "Isère",
                    "reg_name": "Auvergne-Rhône-Alpes"
                },
                {
                    "code": "39",
                    "dep_name": "Jura",
                    "reg_name": "Bourgogne-Franche-Comté"
                },
                {
                    "code": "40",
                    "dep_name": "Landes",
                    "reg_name": "Nouvelle-Aquitaine"
                },
                {
                    "code": "41",
                    "dep_name": "Loir-et-Cher",
                    "reg_name": "Centre-Val de Loire"
                },
                {
                    "code": "42",
                    "dep_name": "Loire",
                    "reg_name": "Auvergne-Rhône-Alpes"
                },
                {
                    "code": "43",
                    "dep_name": "Haute-Loire",
                    "reg_name": "Auvergne-Rhône-Alpes"
                },
                {
                    "code": "44",
                    "dep_name": "Loire-Atlantique",
                    "reg_name": "Pays de la Loire"
                },
                {
                    "code": "45",
                    "dep_name": "Loiret",
                    "reg_name": "Centre-Val de Loire"
                },
                {
                    "code": "46",
                    "dep_name": "Lot",
                    "reg_name": "Occitanie"
                },
                {
                    "code": "47",
                    "dep_name": "Lot-et-Garonne",
                    "reg_name": "Nouvelle-Aquitaine"
                },
                {
                    "code": "48",
                    "dep_name": "Lozère",
                    "reg_name": "Occitanie"
                },
                {
                    "code": "49",
                    "dep_name": "Maine-et-Loire",
                    "reg_name": "Pays de la Loire"
                },
                {
                    "code": "50",
                    "dep_name": "Manche",
                    "reg_name": "Normandie"
                },
                {
                    "code": "51",
                    "dep_name": "Marne",
                    "reg_name": "Grand Est"
                },
                {
                    "code": "52",
                    "dep_name": "Haute-Marne",
                    "reg_name": "Grand Est"
                },
                {
                    "code": "53",
                    "dep_name": "Mayenne",
                    "reg_name": "Pays de la Loire"
                },
                {
                    "code": "54",
                    "dep_name": "Meurthe-et-Moselle",
                    "reg_name": "Grand Est"
                },
                {
                    "code": "55",
                    "dep_name": "Meuse",
                    "reg_name": "Grand Est"
                },
                {
                    "code": "56",
                    "dep_name": "Morbihan",
                    "reg_name": "Bretagne"
                },
                {
                    "code": "57",
                    "dep_name": "Moselle",
                    "reg_name": "Grand Est"
                },
                {
                    "code": "58",
                    "dep_name": "Nièvre",
                    "reg_name": "Bourgogne-Franche-Comté"
                },
                {
                    "code": "59",
                    "dep_name": "Nord",
                    "reg_name": "Hauts-de-France"
                },
                {
                    "code": "60",
                    "dep_name": "Oise",
                    "reg_name": "Hauts-de-France"
                },
                {
                    "code": "61",
                    "dep_name": "Orne",
                    "reg_name": "Normandie"
                },
                {
                    "code": "62",
                    "dep_name": "Pas-de-Calais",
                    "reg_name": "Hauts-de-France"
                },
                {
                    "code": "63",
                    "dep_name": "Puy-de-Dôme",
                    "reg_name": "Auvergne-Rhône-Alpes"
                },
                {
                    "code": "64",
                    "dep_name": "Pyrénées-Atlantiques",
                    "reg_name": "Nouvelle-Aquitaine"
                },
                {
                    "code": "65",
                    "dep_name": "Hautes-Pyrénées",
                    "reg_name": "Occitanie"
                },
                {
                    "code": "66",
                    "dep_name": "Pyrénées-Orientales",
                    "reg_name": "Occitanie"
                },
                {
                    "code": "67",
                    "dep_name": "Bas-Rhin",
                    "reg_name": "Grand Est"
                },
                {
                    "code": "68",
                    "dep_name": "Haut-Rhin",
                    "reg_name": "Grand Est"
                },
                {
                    "code": "69",
                    "dep_name": "Rhône",
                    "reg_name": "Auvergne-Rhône-Alpes"
                },
                {
                    "code": "70",
                    "dep_name": "Haute-Saône",
                    "reg_name": "Bourgogne-Franche-Comté"
                },
                {
                    "code": "71",
                    "dep_name": "Saône-et-Loire",
                    "reg_name": "Bourgogne-Franche-Comté"
                },
                {
                    "code": "72",
                    "dep_name": "Sarthe",
                    "reg_name": "Pays de la Loire"
                },
                {
                    "code": "73",
                    "dep_name": "Savoie",
                    "reg_name": "Auvergne-Rhône-Alpes"
                },
                {
                    "code": "74",
                    "dep_name": "Haute-Savoie",
                    "reg_name": "Auvergne-Rhône-Alpes"
                },
                {
                    "code": "75",
                    "dep_name": "Paris",
                    "reg_name": "Île-de-France"
                },
                {
                    "code": "76",
                    "dep_name": "Seine-Maritime",
                    "reg_name": "Normandie"
                },
                {
                    "code": "77",
                    "dep_name": "Seine-et-Marne",
                    "reg_name": "Île-de-France"
                },
                {
                    "code": "78",
                    "dep_name": "Yvelines",
                    "reg_name": "Île-de-France"
                },
                {
                    "code": "79",
                    "dep_name": "Deux-Sèvres",
                    "reg_name": "Nouvelle-Aquitaine"
                },
                {
                    "code": "80",
                    "dep_name": "Somme",
                    "reg_name": "Hauts-de-France"
                },
                {
                    "code": "81",
                    "dep_name": "Tarn",
                    "reg_name": "Occitanie"
                },
                {
                    "code": "82",
                    "dep_name": "Tarn-et-Garonne",
                    "reg_name": "Occitanie"
                },
                {
                    "code": "83",
                    "dep_name": "Var",
                    "reg_name": "Provence-Alpes-Côte d'Azur"
                },
                {
                    "code": "84",
                    "dep_name": "Vaucluse",
                    "reg_name": "Provence-Alpes-Côte d'Azur"
                },
                {
                    "code": "85",
                    "dep_name": "Vendée",
                    "reg_name": "Pays de la Loire"
                },
                {
                    "code": "86",
                    "dep_name": "Vienne",
                    "reg_name": "Nouvelle-Aquitaine"
                },
                {
                    "code": "87",
                    "dep_name": "Haute-Vienne",
                    "reg_name": "Nouvelle-Aquitaine"
                },
                {
                    "code": "88",
                    "dep_name": "Vosges",
                    "reg_name": "Grand Est"
                },
                {
                    "code": "89",
                    "dep_name": "Yonne",
                    "reg_name": "Bourgogne-Franche-Comté"
                },
                {
                    "code": "90",
                    "dep_name": "Territoire de Belfort",
                    "reg_name": "Bourgogne-Franche-Comté"
                },
                {
                    "code": "91",
                    "dep_name": "Essonne",
                    "reg_name": "Île-de-France"
                },
                {
                    "code": "92",
                    "dep_name": "Hauts-de-Seine",
                    "reg_name": "Île-de-France"
                },
                {
                    "code": "93",
                    "dep_name": "Seine-Saint-Denis",
                    "reg_name": "Île-de-France"
                },
                {
                    "code": "94",
                    "dep_name": "Val-de-Marne",
                    "reg_name": "Île-de-France"
                },
                {
                    "code": "95",
                    "dep_name": "Val-d'Oise",
                    "reg_name": "Île-de-France"
                },
                {
                    "code": "971",
                    "dep_name": "Guadeloupe",
                    "reg_name": "Guadeloupe"
                },
                {
                    "code": "972",
                    "dep_name": "Martinique",
                    "reg_name": "Martinique"
                },
                {
                    "code": "973",
                    "dep_name": "Guyane",
                    "reg_name": "Guyane"
                },
                {
                    "code": "974",
                    "dep_name": "La Réunion",
                    "reg_name": "La Réunion"
                },
                {
                    "code": "976",
                    "dep_name": "Mayotte",
                    "reg_name": "Mayotte"
                }
            ]
            let groupCps = groupBy(cps, 'reg_name')
            let endData = {}
            for (let index in groupCps) {
                endData[index] = {
                    open: false,
                    title: index,
                    data: groupCps[index]
                }
            }
            return endData
        }
    },
}
</script>

<style>
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>