<template>
    <div>
        <spinner v-if="loading" :size="12" />
        <div v-if="!loading && catalog && data">
            <div class="grid grid-cols-1 lg:grid-cols-6 gap-10">
                <div class="col-span-2">
                    <card-title title="Produit">
                        <!-- Categorie -->
                        <input-rule v-if="catalog" v-model="data.categorie" type="select" label="Catégorie" class="mb-4" rules="required" :options="{values: catalog, key: 'ProductID', label: 'ProductName'}" />
                        <!-- Marque -->
                        <input-rule v-if="catalogSelectedCategory" @input="manageBrand" v-model="data.marque" :options="{values: catalogSelectedCategory['Brands'], key: 'BrandID', label: 'BrandName'}" type="select" label="Marque" class="mb-4" rules="required"/>
                        <input-rule v-if="catalogSelectedCategory && data.marque == '0000'" v-model="data.customMarque" type="text" label="Saisir la marque manuellement" rules="required" class="mb-4" />
                        <!-- Code réparation -->
                        <input-rule v-if="catalogSelectedCategory" v-model="data.codeRepair" :options="{values: catalogSelectedCategory['RepairCode'], key: 'Code', label: 'Description'}" type="select" label="Défaut" class="mb-4"/>
                        <!-- Iris Code -->
                        <input-rule v-model="data.iris" type="text" label="Réparation" rules="required" class="mb-4" />
                        <!-- Model name -->
                        <input-rule v-model="data.modele" type="text" label="Modèle" rules="required" class="mb-4" />
                    </card-title>
                    
                    <card-title title="Réparation" class="mt-8">
                        <input-rule v-model="data.dateReparation" :options="{min: catalogSelectedCategory ? catalogSelectedCategory['ProductEligibleFrom'] : null, max: catalogSelectedCategory ? catalogSelectedCategory['ProductEligibleUntil'] : null}" type="date" label="Réparé le" rules="required" class="mb-4" />
                    </card-title>
                </div>

                <!-- Repair -->
                <!-- <div class="col-span-2">
                    <h4 class="text-xl font-bold mb-5 text-blue-500">Réparation</h4>
                    <input-rule v-model="data.dateReparation" :options="{min: catalogSelectedCategory ? catalogSelectedCategory['ProductEligibleFrom'] : null, max: catalogSelectedCategory ? catalogSelectedCategory['ProductEligibleUntil'] : null}" type="date" label="Date de réparation" rules="required" class="mb-4" />
                    <div class="mb-4">
                        <label class="block text-sm font-medium text-gray-900">Pièces de rechange</label>
                        <div v-for="(sparePart, sparePartIndex) in spareParts" :key="'spare-part-' + sparePartIndex" class="mb-2">
                            <div class="mt-2 bg-slate-100 rounded-xl p-4">
                                <input-rule v-model="sparePart['IdentificationNumber']" type="text" label="Nom du modèle" rules="required" class="mb-4" />
                                <div class="grid grid-cols-5 gap-5 mt-2">
                                    <div class="col-span-3">
                                        <input-rule v-model="sparePart['Status']" type="select" label="Etat" class="mb-4" rules="required" :options="{values: ['Neuve', 'Occasion']}" />
                                    </div>
                                    <div class="col-span-2">
                                        <input-rule v-model="sparePart['Quantity']" type="text" label="Qtt" class="mb-4" rules="required|num|min:0" />
                                    </div>
                                </div>
                                <div>
                                    <button @click="deleteSparePart(sparePartIndex)" class="text-xs mt-3 p-3 px-4 bg-red-300 hover:bg-red-400 duration-200 rounded-xl text-white"><i class="fas fa-trash"></i></button>
                                </div>
                            </div>
                        </div>
                        <button @click="addSparePart" class="text-sm mt-2 p-2 px-4 bg-slate-600 hover:bg-slate-700 duration-200 rounded-xl text-white">
                            <i class="fas fa-plus mr-1"></i> Ajouter une pièce
                        </button>
                    </div>
                </div> -->

                <!-- Client -->
                <div class="col-span-2">
                    <card-title title="Client">
                        <!-- Genre -->
                        <input-rule v-model="data.clientTitre" type="select" label="Titre" class="mb-4" rules="required" :options="{values: [{value: 'M.', label: 'Monsieur'}, {value: 'Mme', label: 'Madame'}], key: 'value', label: 'label'}" />
                        
                        <!-- Nom -->
                        <input-rule v-model="data.clientNom" type="text" label="Nom" rules="required" class="mb-4" />
                        <input-rule v-model="data.clientPrenom" type="text" label="Prénom" rules="required" class="mb-4" />
                        <input-rule v-model="data.clientTel" type="text" label="Téléphone" rules="required|tel" class="mb-4" />
                        <input-rule v-model="data.clientEmail" type="text" label="Email" class="mb-4" />
                        <input-rule @input="getAddress" type="address2" :options="{defaultValue: {adresse1: data?.clientAdresse, cp: data?.clientCp, ville: data?.clientVille, pays: data?.clientPays}, country: data?.clientPays}" label="Adresse" id="inp-address" />
                        
                    </card-title>

                    <card-title title="Pièces" class="mt-8">
                        <div class="text-sm font-light text-sm" v-if="folder.folder.spare_parts.length <= 0">
                            Aucune
                        </div>
                        <!-- <li>{{ folder.folder.spare_parts.length == 0 ? 'Aucune' : '' }}</li> -->
                        <ul class="list-disc ml-2 mb-6 font-light text-slate-600">
                            <li class="text-xs mt-2" v-for="(sparePart, sparePartIndex) in folder.folder.spare_parts" :key="'part-' + sparePartIndex">
                                <div class="mb-1">{{ sparePart.reference || 'Inconnue' }}</div>
                                <ul class="list-disc ml-6 font-light text-slate-600">
                                    <li class="mb-1">Désignation: {{ sparePart.designation || '-' }}</li>
                                    <li class="mb-1">Etat: {{ sparePart.neuf == 1 ? 'Neuve' : 'Occasion' }}</li>
                                    <li class="mb-1">Quantité: {{ sparePart.qty }}</li>
                                    <li class="mb-1">Prix vente: {{ sparePart.prix_vente }} €</li>
                                </ul>
                            </li>
                        </ul>
                    </card-title>
                </div>

                <!-- Invoice -->
                <div class="col-span-2">
                    <card-title title="Facturation">
                        <!-- SparePartsCost -->
                        <div class="mb-2 flex items-center justify-between" v-if="prixTotalPieces">
                            <label class="block mb-2 text-xs font-light text-gray-900">Coût des pièces</label>
                            <div class="font-bold text-sm">{{ prixTotalPieces }} €</div>
                        </div>

                        <!-- HT -->
                        <div class="mb-2 flex items-center justify-between" v-if="totalPrixHT">
                            <label class="block mb-2 text-xs font-light text-gray-900">Montant HT</label>
                            <div class="font-bold text-sm">{{ totalPrixHT }} €</div>
                        </div>

                        <!-- Frais de main oeuvre -->
                        <div class="mb-2 flex items-center justify-between" v-if="laborCost">
                            <label class="block mb-2 text-xs font-light text-gray-900">Frais de main oeuvre</label>
                            <div class="font-bold text-sm">{{ laborCost }} €</div>
                        </div>

                        <!-- Frais de déplacement -->
                        <div class="mb-2 flex items-center justify-between" v-if="travelExpenses">
                            <label class="block mb-2 text-xs font-light text-gray-900">Frais de déplacement</label>
                            <div class="font-bold text-sm">{{ travelExpenses }} €</div>
                        </div>

                        <!-- AmountCovered -->
                        <div class="mb-2 flex items-center justify-between" v-if="catalogSelectedCategory">
                            <label class="block mb-2 text-xs font-light text-gray-900">Prise en charge Ecosystème</label>
                            <div class="font-bold text-sm">{{ catalogSelectedCategory['Amounts'][0]['Amount'] }} €</div>
                        </div>

                        <!-- TTC -->
                        <div class="mb-2 flex items-center justify-between">
                            <label for="beforeTax" class="block mb-2 text-xs font-light text-gray-900">Montant TTC</label>
                            <div class="font-bold text-sm">{{ totalPrixTTC }} €</div>
                        </div>

                        <!-- Cout client -->
                        <div class="mb-2 flex items-center justify-between">
                            <label class="block mb-2 text-xs font-light text-orange-900">Coût du client</label>
                            <div class="font-bold text-xs bg-orange-200 rounded-xl p-2 px-4">{{ clientTotalPay }} €</div>
                        </div>

                        <!-- Ecosystem -->
                        <ecosystem-files-form class="mt-4 py-4 pb-3 border-t" :folder="folder" />
                    </card-title>
                </div>
            </div>

            <!-- SendForm -->
            <div class="border-t mt-8 pt-8 text-center">
                <button :disabled="sendLoading" @click="end" class="px-8 py-3 font-bold text-white duration-100 duration-200 bg-green-600 border-green-700 hover:bg-green-700 rounded-xl">
                    <spinner v-if="sendLoading" :size="10" />
                    <div v-show="!sendLoading">
                        <i class="fas fa-check mr-1"></i> Valider
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import apiService from '@/services/api.service'
import moment from 'moment'

import EcosystemFilesForm from './EcosystemFilesForm.vue'

export default {
    components: {EcosystemFilesForm},

    props: {
        dataSrc: {
            required: true,
        },
        sendLoading: {default: false},
        folder: {}
    },
    watch: {
        dataSrc: function() {
            this.data = null
            this.$nextTick(() => {
                this.setClaimData()
            })
        },
    },

    data() {
        return {
            claim: null,
            catalog: null,
            spareParts: [],
            loading: false,
            address: null,

            prixTotalPieces: null,
            totalPrixHT: null,
            totalPrixTTC: null,
            travelExpenses: null,
            laborCost: null,

            data: {
                categorie: null,
                marque: null,
                customMarque: null,
                iris: null,
                modele: null,
                dateReparation: null,
                pieces: {
                    valid: true,
                    value: ['a']
                },
                clientTitre: null,
                clientNom: null,
                clientPrenom: null,
                clientTel: null,
                clientAdresse: null,
                clientCp: null,
                clientVille: null,
                clientPays: null,
                clientEmail: null,
            }
        }
    },

    computed: {
        catalogSelectedCategory() {
            if (this.catalog && this.data.categorie) {
                return this.catalog.find((catalog) => catalog['ProductID'] == this.data.categorie)
            }
            return null
        },

        clientTotalPay() {
            return (Number(this.totalPrixTTC ? this.totalPrixTTC : 0) - Number(this.catalogSelectedCategory ? this.catalogSelectedCategory['Amounts'][0]['Amount'] : 0)).toFixed(2)
        },

        fullAddress() {
            if (this.data && this.data.clientAdresse && this.data.clientCp && this.data.clientVille && this.data.clientPays) {
                return this.data.clientAdresse + ' ' + this.data.clientCp + ' ' + this.data.clientVille + ' ' + this.data.clientPays
            }
            return null
        }
    },

    methods: {
        async init() {
            this.loading = true

            // Get catalog
            let responseCatalog = await apiService.post('ecosysteme.get-catalog')
            this.catalog = responseCatalog.data.posts.post

            // Set claim
            if (this.dataSrc) {
                this.setClaimData()
            }

            this.loading = false
        },

        setClaimData() {
            if (this.dataSrc) {
                const dataSrc = JSON.parse(this.dataSrc)

                this.prixTotalPieces = dataSrc['Bill']['SparePartsCost']['amount']
                this.totalPrixHT = 0
                this.totalPrixTTC = dataSrc['Bill']['TotalAmountInclVAT']['amount']
                this.travelExpenses = dataSrc['Bill']['TravelExpenses'] ? dataSrc['Bill']['TravelExpenses']['amount'] : null
                this.laborCost = dataSrc['Bill']['LaborCost'] ? dataSrc['Bill']['LaborCost']['amount'] : null

                this.data = {
                    categorie: dataSrc['Product']['ProductID'],
                    marque: dataSrc['Product']['BrandID'],
                    customMarque: dataSrc['Product']['PartnerProduct'],
                    iris: dataSrc['Product']['IrisCode'],
                    modele: dataSrc['Product']['CommercialReference'],
                    dateReparation: dataSrc['RepairDate'],
                    pieces: {
                        valid: true,
                        value: ['a']
                    },
                    codeRepair: dataSrc['Product']['RepairTypeCode'],
                    clientTitre: dataSrc['Customer']['Title'],
                    clientNom: dataSrc['Customer']['LastName'],
                    clientEmail: dataSrc['Customer']['Email'] || 'mail@label-qualirepar.fr',
                    clientPrenom: dataSrc['Customer']['FirstName'],
                    clientTel: dataSrc['Customer']['PhoneNumber'],
                    clientAdresse: dataSrc['Customer']['StreetLine1'],
                    clientCp: dataSrc['Customer']['PostalCode'],
                    clientVille: dataSrc['Customer']['City'],
                    clientPays: dataSrc['Customer']['Country'],
                }
            }
        },

        addSparePart() {
            this.spareParts.push({
                Status: 'Neuve',
                IdentificationNumber: '',
                Quantity: 1
            })
        },

        deleteSparePart(index) {
            this.spareParts.splice(index, 1)
        },

        calcTTC() {
            // let nbHt = this.data.totalPrixHT
            // this.data.totalPrixTTC = (Number(nbHt) + (Number(nbHt) * 0.2)).toFixed(2)
        },

        end() {
            // Check errors
            const dataSrc = JSON.parse(this.dataSrc)
            for (let key in this.data) {
                if (!this.data[key] || ((this.data[key] === undefined && !this.data[key]) || this.data[key] == false)) {
                    if (key != 'customMarque' && key != 'codeRepair') {
                        if (key == 'marque' && (this.data[key] == '' || this.data[key] == null)) {
                            console.log(key + ' : ' + this.data[key]);
                            this.$toast.error('Formulaire incorrect')
                            return
                        }
                    }
                }
            }

            // convert spareparts qtt to number
            let amountSparePart = 0
            this.folder.folder.spare_parts.forEach((sparePart) => {
                amountSparePart += sparePart.prix_vente
            })

            // Get ecosystem amount covered
            let amountCovered = 0
            this.catalogSelectedCategory['Amounts'].forEach((itemCatalog) => {
                var compareDate = moment()
                var startDate = moment(itemCatalog.AmountValidFrom, "YYYY-MM-DD");
                var endDate = moment(itemCatalog.AmountValidUntil, "YYYY-MM-DD");
    
                if (compareDate.isBetween(startDate, endDate)) {
                    amountCovered = itemCatalog.Amount
                }
            })

            // Build end object
            let endObj = {
                "PurchaseOrderByCustomer": dataSrc['PurchaseOrderByCustomer'],
                "RepairDate": this.data.dateReparation,
                "RepairPlaceID": dataSrc['RepairPlaceID'],
                "RepairerId": dataSrc['RepairerId'],
                "Product": {
                    "ProductID": this.data.categorie,
                    "BrandID": this.data.marque,
                    "ProductIdentificationNumber": dataSrc['ProductIdentificationNumber'] || '-',
                    "RepairTypeCode": this.data.codeRepair,
                    "IrisCode" : this.data.iris,
                    "CommercialReference": this.data.modele
                },
                "SpareParts": {
                    "NewSparePartsAmount": amountSparePart,
                    "SecondHandSparePartsAmount": 0
                },
                "Customer": {
                    "Title": this.data.clientTitre,
                    "LastName": this.data.clientNom,
                    "FirstName": this.data.clientPrenom,
                    "PhoneNumber": this.data.clientTel,
                    "StreetLine1": this.data.clientAdresse,
                    "PostalCode": this.data.clientCp,
                    "City": this.data.clientVille,
                    "Country": this.data.clientPays,
                    "Email": this.data.clientEmail,
                },
                "Bill": {
                    "SparePartsCost": {
                        "amount": Number(this.prixTotalPieces),
                        "currency": "EUR"
                    },
                    "TotalAmountInclVAT": {
                        "amount": this.totalPrixTTC,
                        "currency": "EUR"
                    },
                    "LaborCost": {
                        "amount": Number(this.laborCost),
                        "currency": "EUR"
                    },
                    "TravelExpenses": {
                        "amount": Number(this.travelExpenses),
                        "currency": "EUR"
                    },
                    "AmountCovered": {
                        "amount": Number(amountCovered),
                        "currency": "EUR"
                    }
                }
            }

            if (this.data.marque == '0000') {
                endObj['Product']['PartnerProduct'] = this.data.customMarque
            }

            // Emit end data
            this.$emit('submit', endObj)
        },

        getAddress(data) {
            if (data) {
                this.address = data
                if (data.adresse1) {
                    this.data.clientAdresse = data.adresse1
                }
                if (data.cp) {
                    this.data.clientCp = data.cp
                }
                if (data.ville) {
                    this.data.clientVille = data.ville
                }
                if (data.pays) {
                    this.data.clientPays = data.pays
                }
            }
        },

        manageBrand() {
            if (this.data && this.data.marque && this.data.marque != '0000') {
                this.data.customMarque = {valid: true}
            } else if (this.data && this.dataSrc) {
                this.data.customMarque = JSON.parse(this.dataSrc)['Product']['PartnerProduct']
            }
        },
    },

    mounted() {
        this.init()
    }
}
</script>

<style lang="scss" scoped>

</style>
