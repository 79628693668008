<template>
    <div>
        <modal :name="'modal-confirm-delete-' + _uid" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        Confirmer la suppression
                    </h2>
                    <button @click="$modal.hide('modal-confirm-delete-' + _uid)">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="text-lg" v-if="label">Souhaitez-vous supprimer <b>{{ label }}</b> ?</div>
                    <div class="text-lg" v-if="!label">Confirmez-vous la suppression ?</div>
                    <div class="flex gap-2 items-center mt-4">
                        <button @click="$modal.hide('modal-confirm-delete-' + _uid)" class="px-4 py-2 rounded text-sm bg-slate-200 hover:bg-slate-300 duration-200">Annuler <i class="fas fa-times ml-1"></i></button>
                        <button @click="confirmDelete" class="px-4 py-2 rounded text-sm bg-red-500 hover:bg-red-600 duration-200 text-white">Supprimer <i class="fas fa-trash ml-1"></i></button>
                    </div>
                </div>
            </div>
        </modal>
        <button @click="$modal.show('modal-confirm-delete-' + _uid)" class="mr-1 text-xs bg-red-500 hover:bg-red-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white">
            <i class="fas fa-trash"></i>
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            label: {},
            meta: {}
        },
        methods: {
            confirmDelete() {
                this.$emit('click', this.meta)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>