<template>
    <div class="text-xs">
        <span v-if="state == 0 || state == 1" class="p-1 px-3 bg-blue-200 rounded-xl font-light" style="font-size: 11px;">
            A compléter
        </span>
        <span v-if="state == 2" class="p-1 px-3 bg-slate-300 rounded-xl font-light" style="font-size: 11px;">
            En cours de validation
        </span>
        <span v-if="state == 3" class="p-1 px-3 bg-green-600 text-white rounded-xl font-light" style="font-size: 11px;">
            Approuvé, remboursement en cours
        </span>
        <span v-if="state == 4" class="p-1 px-3 bg-green-600 text-white rounded-xl font-light" style="font-size: 11px;">
            Remboursé
        </span>
        <span v-if="state == 5" class="p-1 px-3 bg-red-300 rounded-xl font-light" style="font-size: 11px;">
            Refusé
        </span>
    </div>
</template>

<script>
export default {
    props: {
        state: {
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
