<template>
    <div>
        <modal name="modal-add-transporter" height="auto" :scrollable="true">
            <div class="p-10" v-if="newTransporterForm">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{$tt('Nouveau transporteur')}}</h2>
                    <button @click="$modal.hide('modal-add-transporter')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <input-rule @input="selectNewTransporter" v-model="newTransporterForm.id_transport" type="select" :options="{values: transportersList, label: 'transporteur', key: 'id'}" custom="text-xs" :label="$tt('Transporteur')" :label-inline="false" />
                <template v-if="newTransporterForm.id_transport">
                    <div class="mt-4">
                        <label class="mb-1 text-xs font-bold text-gray-900">{{$tt('Connexion')}}</label>
                        <input-rule v-model="newTransporterForm.compte" type="text" class="mt-2" custom="text-xs" :placeholder="$tt('Compte')" :label-inline="false" />
                        <input-rule v-model="newTransporterForm.password" type="text" class="mt-2" custom="text-xs" :placeholder="$tt('Mot de passe')" :label-inline="false" />
                    </div>
                    <div class="mt-4" v-if="newTransporterForm.contrat_savlog == 0">
                        <label class="mb-1 text-xs font-bold text-gray-900">{{$tt('Tarifs par poids')}}</label>
                        <div class="mb-2" v-for="(poids, poidsIndex) in newTransporterForm.poids" :key="'poids-form-' + poidsIndex">
                            <div class="flex gap-2 items-center">
                                <div class="text-sm font-light">{{ poidsIndex }} kg</div>
                                <div class="border-b grow"></div>
                                <input-rule v-model="newTransporterForm.poids[poidsIndex]" type="price" :label-inline="false" :placeholder="$tt('Tarif')" custom="text-xs" />
                            </div>
                        </div>
                    </div>
                    <button @click="addTransporter" class="mt-3 bg-green-500 text-white hover:bg-green-600 duration-200 rounded px-4 py-2 text-sm">
                        {{$tt('Valider')}} <i class="fas fa-check ml-1"></i>
                    </button>
                </template>
            </div>
        </modal>

        <app-template>
            <div class="bg-white p-7 rounded border shadow-sm mb-16">
                <div class="relative overflow-x-auto">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th v-for="(header, headerIndex) in headers" scope="col" class="px-6 py-3" :key="'table-header-' + headerIndex">
                                    {{ header }} <button v-if="headerIndex > 0" @click="editTransporter(header)" class="ml-1"><i class="fas fa-pen"></i></button>
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    <button @click="initNewForm(); $modal.show('modal-add-transporter')" class="px-4 py-2 bg-green-400 hover:bg-green-500 duration-200 rounded text-white"><i class="fas fa-plus mr-0.5"></i> {{$tt('Transporteur')}}</button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <tr v-for="(poidsItem, poidsItemIndex) in poidsItems" :key="'cell-poids-' + poidsItemIndex" class="bg-white border-b">
                                <th class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                    {{ poidsItem.key }} kg
                                </th>
                                <td v-for="(transporter, transporterIndex) in transporters" :key="'cell-transporter-' + transporterIndex" class="px-6 py-4">
                                    <input-rule v-model="transporter.tarifs.find((item) => item.poids_min == poidsItem.min && item.poids_max == poidsItem.max).tarif" type="text" :label-inline="false" />
                                </td>
                                <td></td>
                            </tr> -->
                            <tr class="border-b hover:bg-gray-50 duration-200 hover:bg-opacity-50" v-for="(line, lineIndex) in lines" :key="'table-line-' + lineIndex">
                                <td class="px-6 py-3" v-for="(cell, cellIndex) in line" :key="'table-cell-' + cellIndex">
                                    <template v-if="cellIndex == 0">{{ cell }} kg</template>
                                    <template v-if="cellIndex > 0">{{ cell ? (cell + ' €') : '-' }}</template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- <div class="grid grid-cols-4 gap-2">
                    <div>
                        <div class="sticky top-5">
                            <div class="font-semibold pb-3"><i class="fas fa-truck mr-1"></i> Mes transporteurs</div>
                            <div class="border border-b-0 max-h-[80vh] overflow-y-auto rounded">
                                <div class="border-b p-3 text-sm hover:text-blue-500 duration-200 cursor-pointer">
                                    <i class="fas fa-plus mr-1"></i> Ajouter un transporteur
                                </div>
                                <div @click="selectTransporter(transporter)" v-for="(transporter, transporterIndex) in transporters" :key="'transporter-' + transporterIndex" class="border-b p-3 text-sm hover:text-blue-500 duration-200 cursor-pointer" :class="selectedTransporter && selectedTransporter.id == transporter.id ? 'font-black text-blue-500 bg-blue-50' : ''">
                                    {{ transporter.transporteur }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-3 px-4">
                        <div v-if="!selectedTransporter" class="my-6 text-center text-slate-400 text-xl font-bold">
                            <i class="fa-solid fa-computer-mouse"></i>
                            <div>Choisir un transporteur</div>
                            <div class="text-sm font-light">Parmis la liste ci-contre</div>
                        </div>
                        <div v-if="selectedTransporter">
                            <h2 class="text-2xl font-bold">{{ selectedTransporter.transporteur }}</h2>

                            <div class="relative overflow-x-auto">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 mt-4 border">
                                    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                        <tr>
                                            <th scope="col" class="px-2 py-3 pl-4">
                                                Poids
                                            </th>
                                            <th scope="col" class="px-2 py-3">
                                                Tarif
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, itemIndex) in items" :key="'table-line-' + itemIndex" class="bg-white hover:bg-slate-100 duration-200">
                                            <td class="p-2 pl-4 w-[100px]">{{ item.min + ' - ' + item.max }} kg</td>
                                            <td class="py-1 pr-4"><input v-model="item.value" type="text" class="w-full border rounded text-sm p-1.5 focus:outline-none"></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button class="px-4 py-2 rounded bg-green-500 hover:bg-green-600 duration-200 rounded text-white mt-4">Enregistrer <i class="fas fa-check ml-1"></i></button>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </app-template>
    </div>
</template>

<script>
// import ApiService from '../../services/api.service'
export default {
    data() {
        return {
            headers: [],
            lines: [],
            transportersList: [],
            // selectedTransporter: null,
            poidsItems: [],
            newTransporterForm: null
        }
    },
    methods: {
        initNewForm() {
            this.newTransporterForm = {
                id_transport: null,
                compte: null,
                password: null,
                contrat_savlog: 0,
                poids: {}
            }
        },

        async getTransporters() {
            // let response = await this.$request.post('backoffice', {
            //     operation: 'get_all_tarif',
            //     id_four: ApiService.user().account.id_entite
            // }, 'districloud_no_action')
            // this.transporters = response.data.posts.post
            // this.transporters.forEach((transporter) => {
            //     transporter.tarifs.forEach((tarif) => {
            //         this.poidsItems.push({
            //             key: tarif.poids_min + '-' + tarif.poids_max,
            //             min: tarif.poids_min,
            //             max: tarif.poids_max,
            //         })
            //     })
            // })

            let response = await this.$request.post('transporteur.grossiste-list')
            this.lines = response.data.posts.post
            this.headers = this.lines.shift()
            this.headers[0] = this.$tt(this.headers[0])
        },

        async getTransportersList() {
            let response = await this.$request.post('transporteur.list-all')
            this.transportersList = response.data.posts.post
        },

        async addTransporter() {
            if (!this.newTransporterForm.compte || !this.newTransporterForm.password || !this.newTransporterForm.id_transport) {
                this.$toast.error(this.$tt('formulaire incomplet'))
                return
            }
            let endObj = {...this.newTransporterForm}
            endObj.poids = JSON.stringify(endObj.poids)
            await this.$request.post('transporteur.edit-add-tarif', endObj)
            this.getTransporters()
            this.getTransportersList()
            this.$modal.hide('modal-add-transporter')
        },

        selectNewTransporter() {
            if (this.newTransporterForm.id_transport && !this.newTransporterForm.edit) {
                let selectedNewTransporter =  this.transportersList.find((item) => item.id == this.newTransporterForm.id_transport)
                this.newTransporterForm.poids = {}
                selectedNewTransporter.poids.forEach((item) => {
                    this.newTransporterForm.poids[item.key] = null
                })
            }
        },

        editTransporter(header) {
            this.$nextTick(() => {
                let transporteur = this.transportersList.find((item) => item.transporteur == header)
                console.log(transporteur.contrat_savlog);
                this.newTransporterForm = {
                    edit: true,
                    id_transport: transporteur.id,
                    compte: transporteur.compte,
                    password: transporteur.password,
                    contrat_savlog: transporteur.contrat_savlog,
                    poids: {}
                }
                let columnIndex = this.headers.findIndex((item) => item == header)
                this.lines.forEach((item) => {
                    this.newTransporterForm.poids[item[0]] = (item[columnIndex] ? item[columnIndex] : null)
                })
            })
    
            this.$modal.show('modal-add-transporter')
        }
    },
    async mounted () {
        await this.getTransportersList()
        this.getTransporters()
    },
}
</script>

<style lang="scss" scoped>

</style>