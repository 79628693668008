<template>
    <div>
        <app-template>
            <div class="flex gap-1">
                <div @click="selectedTab = 'station'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'station' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-home mr-1"></i> {{$tt('Informations Station')}}</div>
                <!-- <div @click="selectedTab = 'categs_client'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'categs_client' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-users mr-1"></i> {{$tt('Types clients')}}</div> -->
                <div @click="selectedTab = 'zone'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'zone' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-location-dot mr-1"></i> {{$tt("Zones d'intervention sur site")}}</div>
                <div @click="selectedTab = 'categs'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'categs' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-layer-group mr-1"></i> {{$tt('Catégories de produit')}}</div>
                <div @click="selectedTab = 'tarif_hg'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'tarif_hg' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-euro mr-1"></i> {{$tt('Tarifs hors garantie')}}</div>
                <div @click="selectedTab = 'agrements'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'agrements' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-euro mr-1"></i> {{$tt('Agréments de marque')}}</div>
                <div @click="selectedTab = 'coeff'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'coeff' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-euro mr-1"></i> {{$tt('Coefficients')}}</div>
                <!-- <div @click="selectedTab = 'tarif_sg'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'tarif_sg' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-euro mr-1"></i> {{$tt('Tarifs sous garantie')}}</div> -->
            </div>
            <div class="bg-white p-2 rounded rounded-tl-none border shadow-sm mb-16">
                <div class="p-4 my-2">
                    <div v-if="selectedTab == 'station'">
                        <reparateur-station-form />
                    </div>
                    <div v-if="selectedTab == 'categs'">
                        <reparateur-station-categ />
                    </div>
                    <div v-if="selectedTab == 'categs_client'">
                        <reparateur-manage-entite />
                    </div>
                    <div v-if="selectedTab == 'zone'">
                        <reparateur-station-zone />
                    </div>
                    <div v-if="selectedTab == 'tarif_hg'">
                        <reparateur-tarif-hg />
                    </div>
                    <div v-if="selectedTab == 'agrements'">
                        <reparateur-agrements />
                    </div>
                    <div v-if="selectedTab == 'coeff'">
                        <reparateur-coeff />
                    </div>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import ReparateurStationForm from './Components/ReparateurStationForm.vue'
import ReparateurStationZone from './Components/ReparateurStationZone.vue'
import ReparateurStationCateg from './Components/ReparateurStationCateg.vue'
import ReparateurTarifHg from './Components/ReparateurTarifHg.vue'
import ReparateurManageEntite from './Components/ReparateurManageEntite.vue'
import ReparateurAgrements from './Components/ReparateurAgrements.vue'
import ReparateurCoeff from './Components/ReparateurCoeff.vue'

export default {
    components: {
        ReparateurStationForm,
        ReparateurStationZone,
        ReparateurStationCateg,
        ReparateurTarifHg,
        ReparateurManageEntite,
        ReparateurAgrements,
        ReparateurCoeff
    },
    data() {
        return {
            selectedTab: 'station'
        }
    },
}
</script>

<style lang="scss" scoped>

</style>