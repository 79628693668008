<template>
    <div>
        <modal name="modal-confirm-change">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">
                        Modification en cours
                    </h2>
                    <button @click="$modal.hide('modal-confirm-change'); typeActor = tempTypeActor">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div class="p-4 mb-4 text-sm text-orange-800 rounded-lg bg-orange-50" role="alert">
                    <p>Vous avez modifier le formulaire sans l'avoir enregistré, souhaitez-vous quand même changer de section ?</p>
                </div>
                <div class="flex justify-center gap-4">
                    <button :disabled="sendLoading" @click="$modal.hide('modal-confirm-change'); typeActor = tempTypeActor" class="bg-slate-200 text-slate-600 px-4 py-2 rounded mt-2">
                        <i class="fas fa-times mr-1"></i> Annuler
                    </button>
                    <button :disabled="sendLoading" @click="getOffers" class="bg-orange-600 text-white px-4 py-2 rounded mt-2">
                        <i class="fas fa-arrows-rotate mr-1"></i> Changer de section
                    </button>
                    <button :disabled="sendLoading" @click="saveForm" class="bg-emerald-600 text-white px-4 py-2 rounded mt-2">
                        <template v-if="!sendLoading">
                            <i class="fas fa-floppy-disk mr-1"></i> Enregistrer
                        </template>
                        <template v-if="sendLoading">
                            <spinner :size="10" />
                        </template>
                    </button>
                </div>
            </div>
        </modal>
        <app-template>
            <div class="text-right">
                <select class="bg-slate-300 hover:bg-slate-400 duration-200 cursor-pointer rounded px-4 py-2 roudned-xl mr-2" v-model="typeActor">
                    <option value="Fournisseur">Fournisseur</option>
                    <option value="Distributeur">Distributeur</option>
                </select>
                <button @click="saveForm" :disabled="sendLoading" class="bg-green-700 hover:bg-green-800 duration-200 cursor-pointer rounded px-4 py-2 roudned-xl text-white">
                    <template v-if="sendLoading">
                        <spinner :size="10" />
                    </template>
                    <template v-if="!sendLoading">
                        Enregistrer <i class="fas fa-check ml-1"></i>
                    </template>
                </button>
            </div>
            <div class="grid grid-cols-2 gap-3">
                <pricing-section
                v-for="(offer, offerIndex) in offers"
                :key="'offer-' + offerIndex"
                :section="offer"
                :index="offerIndex"
                @change="changeOffer"
                />
            </div>
        </app-template>
    </div>
</template>

<script>
import apiService from '@/services/api.service'

import PricingSection from './Components/PricingSection.vue';

export default {
    components: {
        PricingSection,
    },
    watch: {
        typeActor(value, oldValue) {
            this.tempTypeActor = oldValue
            if (this.hasUpdated) {
                this.$modal.show('modal-confirm-change')
            } else {
                this.getOffers()
            }
        }
    },
    data() {
        return {
            offers: [],
            sendLoading: false,
            typeActor: 'Fournisseur',
            tempTypeActor: null,
            hasUpdated: false,
        }
    },

    methods: {
        async getOffers() {
            this.$modal.hide('modal-confirm-change')
            this.offers = []
            this.hasUpdated = false
            let response = await apiService.post('vitrine.tarifs.get', {
                type: 'Fournisseur'
            })
            this.offers = response.data.posts.post
        },

        changeOffer(value) {
            this.offers[value.index].blocs = value.blocs
        },

        async saveForm() {
            this.sendLoading = true
            await apiService.post('vitrine.tarifs.update', {
                offers: JSON.stringify(this.offers),
            })
            this.$toast.success('Enregistré')
            this.sendLoading = false
            this.getOffers()
        }
    },

    mounted () {
        this.getOffers()
        this.$eventHub.$on('update-price', () => {
            this.hasUpdated = true
        })
    },
}
</script>

<style lang="scss" scoped>

</style>
