<template>
    <div>
        <modal name="modal-convert-claim" height="auto" :scrollable="true">
            <div class="p-10 min-h-[500px]" style="z-index: 999 !important;">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-xl">
                    <h2>Convertir en demande magasin</h2>
                    <button @click="$modal.hide('modal-convert-claim')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div class="bg-slate-100 border p-4 rounded mb-4">
                    <div class="font-bold">{{ claim.claim.enseigne.nom }}</div>
                    <div class="grid grid-cols-2 gap-4 text-sm mt-1">
                        <div>
                            <div>Siret: {{ claim.claim.enseigne.siret }}</div>
                            <div>Tva: {{ claim.claim.enseigne.tva }}</div>
                            <div>Adresse: {{ claim.claim.enseigne.adresse.adresse1 + ' ' + claim.claim.enseigne.adresse.cp + ' ' + claim.claim.enseigne.adresse.ville }}</div>
                        </div>
                        <div>
                            <div>Nom: {{ claim.claim.account.prenom + ' ' + claim.claim.account.nom }}</div>
                            <div>Tel: {{ claim.claim.account.tel }}</div>
                            <div>Email: {{ claim.claim.account.email }}</div>
                        </div>
                    </div>
                </div>
                <div class="border p-4 rounded">
                    <input-rule
                    :label-inline="false"
                    v-model="newCentraleId"
                    type="api-select"
                    :options="{
                        api: {action: 'centrale.search', apiName: 'api2', options: {limit: 500, orderBy: 'centrale_ha'}},
                        label: 'centrale_ha',
                        keyTrack: 'id'
                    }"
                    label="Sur quelle enseigne souhaitez-vous lier cette demande ?"
                    id="inp-centrale-ha"
                    />
                    <div v-if="loadingSimilar" class="py-5">
                        <spinner :size="10" />
                    </div>
                    <div v-if="similarShops && similarShops.length > 0 && !selectedShop" class="bg-orange-100 rounded p-4 mt-2">
                        <h2 class="font-bold border-b border-orange-800 pb-2 text-orange-900">Magasins similaires</h2>
                        <div class="flex items-center justify-between border-b border-orange-800 py-2 text-orange-700" v-for="(similarShop, similarIndex) in similarShops" :key="'similar-' + similarIndex">
                            <div>
                                <div class="font-bold text-sm">{{ similarShop.magasin }}</div>
                                <div class="text-xs">{{ similarShop.adresse1 + ' ' + similarShop.cp + ' ' + similarShop.ville }}</div>
                            </div>
                            <button @click="selectedShop = similarShop" class="px-4 py-2 bg-orange-600 hover:bg-orange-700 text-white text-xs rounded">Selectionner</button>
                        </div>
                    </div>
                    <div class="mb-4" v-if="selectedShop">
                        <h2 class="font-bold text-xs mt-4">Magasin séléctionné</h2>
                        <div class="my-2 bg-slate-200 p-2 rounded flex items-center justify-between">
                            <div>
                                <div class="font-bold text-sm">{{ selectedShop.magasin }}</div>
                                <div class="text-xs">{{ selectedShop.adresse1 + ' ' + selectedShop.cp + ' ' + selectedShop.ville }}</div>
                            </div>
                            <button class="bg-red-500 hover:bg-red-600 duration-200 text-white px-2 py-1 rounded text-sm" @click="selectedShop = null"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                    <button :disabled="loading || loadingSimilar" @click="convertClaim" class="px-4 py-2 bg-green-500 hover:bg-green-600 text-sm text-white font-light rounded mt-2 duration-200 cursor-pointer">
                        <template v-if="!loading"><i class="fa-solid fa-right-left mr-1"></i> Convertir</template>
                        <spinner v-if="loading" :size="10" />
                    </button>
                </div>
            </div>
        </modal>

        <button @click="$modal.show('modal-convert-claim')" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer rounded text-sm font-light">
            Convertir en demande magasin
        </button>
    </div>
</template>

<script>
export default {
    props: {
        claim: {required: true},
    },
    watch: {
        newCentraleId() {
            this.getSimilarShops()
        }
    },
    data() {
        return {
            newCentraleId: null,
            loading: false,
            loadingSimilar: false,
            similarShops: [],
            selectedShop: null,
        }
    },
    computed: {
        claimId() {
            return this.claim.id 
        }
    },
    methods: {
        async convertClaim() {
            let endObj = {
                claim_id: this.claimId,
                type: 'magasin'
            }
            if (!this.$validation.check([
                {value: this.newCentraleId, rules: 'required', id: 'inp-centrale-ha'},
            ])) {return}

            endObj.meta = {
                centrale_id: this.newCentraleId,
            }
            if (this.selectedShop) {
                endObj.meta.magasin_id = this.selectedShop.id
            }
            endObj.meta = JSON.stringify(endObj.meta)
            this.loading = true
            let response = await this.$request.post('inscription.convert-type-claim', endObj)
            this.loading = false
            if (response.data.posts.post == 1) {
                location.reload()
            }
        },

        async getSimilarShops() {
            this.loadingSimilar = true
            let response = await this.$request.post('inscription.similar-magasin-from-claim', {
                claim_id: this.claimId,
                centrale_id: this.newCentraleId
            })
            this.loadingSimilar = false
            this.similarShops = response.data.posts.post != 1 ? response.data.posts.post : []
        }
    },
}
</script>

<style lang="scss" scoped>

</style>