<template>
    <div>
        <div class="flex items-center justify-between p-3" :class="(open ? 'pb-0' : 'hover:bg-slate-100 duration-200 cursor-pointer') + ' ' + customClass" @click="open = !open">
            <h2 class="text-sm" :class="open ? 'font-semibold' : 'font-light'" v-html="title"></h2>
            <div v-show="!open">
                <i class="fas fa-chevron-right"></i>
            </div>
            <div v-show="open">
                <i class="fas fa-chevron-down"></i>
            </div>
        </div>
        <div class="pb-3" v-if="open">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {required: true},
        title: {required: true},
        customClass: {},
    },
    watch: {
        open(value) {
            if (value) {
                this.$eventHub.$emit('toggle-card-is-open', this.id)
            }
        }
    },
    data() {
        return {
            open: false
        }
    },
    mounted () {
        this.$eventHub.$on('toggle-card-is-open', (id) => {
            if (id != this.id) {
                this.open = false
            }
        });

        this.$eventHub.$on('go-open-card', (id) => {
            if (id == this.id) {
                this.open = true
            }
        });

        this.$eventHub.$on('go-close-card', (id) => {
            if (id == this.id) {
                this.open = false
            }
        });
    },
}
</script>

<style lang="scss" scoped>

</style>
