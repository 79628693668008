<template>
    <div>
        <modal name="modal-confirm-delete" height="auto" :scrollable="true">
            <div class="p-10">
                <template v-if="selectedLine">
                    <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                        <h2 class="font-bold">
                            Confirmer la suppression
                        </h2>
                        <button @click="$modal.hide('modal-confirm-resend'); selectedLine = null">
                            <i class="fas fa-times text-red-500"></i>
                        </button>
                    </div>
                    <div class="p-4 mb-4 text-sm text-orange-800 rounded-lg bg-orange-50" role="alert">
                        <p>Êtes-vous sûr de vouloir supprimer le dossier <b>{{ selectedLine.ref }}</b> ?</p>
                    </div>
                    <button :disabled="waitingDelete" @click="deleteFolder(selectedLine)" class="bg-red-600 hover:bg-red-700 duration-200 cursor-pointer text-white px-4 py-2 rounded text-sm">
                        <template v-if="!waitingDelete">
                            Confirmer <i class="fas fa-trash ml-1"></i>
                        </template>
                        <template v-if="waitingDelete">
                            <spinner :size="10" />
                        </template>
                    </button>
                </template>
            </div>
        </modal>

        <app-template>
            <div class="bg-white p-7 rounded border shadow-sm mb-16">
                <template v-if="loadingData" class="flex justify-center">
                    <div class="flex justify-center">
                        <spinner :size="12" />
                    </div>
                    <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
                </template>
                
                <template v-if="!loadingData">
                    <data-table
                    :filters-inline="false"
                    :emit-filters="true"
                    @apply-filters="applyFilters"
                    :filters="[
                        {key: 'state', type: 'select', label: 'Statut', options: statesFilterOptions, value: null},
                        {label: 'Date demande', key: 'claim_date', type: 'date-range', value: null},
                        {label: 'Ref dossier', key: 'ref', type: 'text', value: null},
                        {label: 'Numéro service ordre', key: 'service_order', type: 'text', value: null},
                        {label: 'Marque', key: 'brand', type: 'text', value: null},
                        {label: 'Appareil', key: 'device', type: 'text', value: null},
                    ]"
                    :columns="[
                        {label: 'Statut', key: 'state', slot: 'state', type: 'text', css: 'min-w-[300px]'},
                        {label: 'Ref Dossier', key: 'ref', type: 'text'},
                        {label: 'Catégorie produit', key: 'category', type: 'text'},
                        {label: 'Marque', key: 'brand', type: 'text'},
                        {label: 'Appareil', key: 'device', type: 'text'},
                        {label: 'Numéro Service Ordre', key: 'service_order', type: 'text'},
                        {label: 'Facture', key: 'invoice', type: 'file'},
                        {label: 'Date demande', key: 'claim_date', type: 'date'},
                        {label: 'Accepté le', key: 'accept_date', type: 'date'},
                        {label: 'Payé le', key: 'pay_date', type: 'date'},
                        {label: 'Rejeté le', key: 'refused_date', type: 'date'},
                    ]"
                    :lines="folders"
                    >
                        <template v-slot:state='{line}'>
                            <eco-system-badge-state :state="line.state" />
                        </template>
                        <template v-slot:actions='{line}'>
                            <div class="flex items-center">
                                <button class="mr-1 text-xs bg-slate-300 hover:bg-slate-400 duration-200 cursor-pointer font-light px-3 py-1.5 rounded" v-if="line.state == 1" @click="resendFolder(line)">Renvoyer</button>
                                <button @click="$router.push({name: 'ecosystem.folder.view', params: {ref: line.url_ref}})" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white">
                                    <i class="fas fa-pen"></i>
                                </button>
                                <button v-if="line.state == 1" @click="selectedLine = line; $modal.show('modal-confirm-delete')" class="mr-1 text-xs bg-red-300 hover:bg-red-400 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </template>
                    </data-table>
                </template>
            </div>
        </app-template>
    </div>
</template>

<script>
import moment from 'moment'
import apiService from '@/services/api.service'
import EcoSystemBadgeState from './components/EcoSystemBadgeState.vue'

export default {
    components: {
        EcoSystemBadgeState,
    },
    data() {
        return {
            loadingData: false,
            waitingDelete: false,

            selectedLine : null,

            folders: [],

            states: [
                {value: 1, label: 'A compléter', checked: false, nbFolders: null},
                {value: 2, label: 'En attente', checked: false, nbFolders: null},
                {value: 3, label: 'Approuvé', checked: false, nbFolders: null},
                {value: 4, label: 'Payé', checked: false, nbFolders: null},
                {value: 5, label: 'Réfusé', checked: false, nbFolders: null},
            ],

            // filters: {
            //     state: '-1',
            //     ref: '',
            //     sap_service_order: '',
            //     dates: {
            //         minDate: '2010-05-02 04:00:00',
            //         maxDate: '2050-05-02 04:00:00',
            //     }
            // },

        }
    },

    computed: {
        statesFilterOptions() {
            let endObj = []
            this.states.forEach((state) => {
                endObj.push({
                    label: state.label + (state.nbFolders ? (' (' + state.nbFolders + ')') : ''),
                    value: state.value,
                })
            })
            return endObj
        }
    },

    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        }
    },

    methods: {
        async init() {
            this.loadingData = true

            let response = await apiService.post('ecosysteme.has-eco-access', {})
            if (!response.data.posts.post) {
                this.$router.push({name: 'ecosystem.connect'})
                return
            }

            // Get Folders
            await this.getFiltersStats()
            await this.getFilteredFolders({state: [0, 1]})

            this.loadingData = false
        },

        async getFiltersStats() {
            let responseStats = await apiService.post('ecosysteme.get-stats-filters', {})
            const stats = responseStats.data.posts.post
            for (let statIndex in stats) {
                this.states.find((state) => state.value == statIndex).nbFolders = stats[statIndex]
            }
        },

        async getFilteredFolders(endObj = {}) {
            this.folders = []
            this.loadingData = true
            let response = await apiService.post('ecosysteme.get-filtered-folders', {
                filters: JSON.stringify(endObj)
            })
            const folders = response.data.posts.post
            this.folders = []
            if (folders) {
                this.folders = folders.map((folder) => ({
                    'state': folder.state,
                    'ref': folder.folder?.ref_dossier,
                    'category': folder.folder?.device.categorie_str,
                    'brand': folder.folder?.device['Marque'],
                    'device': folder.folder?.device['Modele'],
                    'service_order': folder.sap_service_order,
                    'invoice': folder.uploaded_invoice,
                    'claim_date': folder.claim_date,
                    'url_ref': folder.folder?.ref_dossier,
                    'accept_date': folder.accept_date,
                    'pay_date': folder.pay_date,
                    'refused_date': folder.refused_date
                }))
            }
            this.loadingData = false
        },

        applyFilters(filters) {
            let findValue = (name) => {
                return filters.find((filter) => filter.key == name)
            }
            let endObj = {
                ref_folder: findValue('ref').value,
                sap_service_order: findValue('service_order').value,
                start_date: findValue('claim_date')?.startDate?.value ? (moment(findValue('claim_date').startDate).format('YYYY-MM-DD')).value : null,
                end_date: findValue('claim_date')?.endDate?.value ? (moment(findValue('claim_date').endDate.value).format('YYYY-MM-DD')) : null,
                brand: findValue('brand').value,
                modele: findValue('device').value,
                state: filters.find((filter) => filter.key == 'state').value ? [filters.find((filter) => filter.key == 'state').value] : [],
            }
            this.getFilteredFolders(endObj)
        },

        getTotalWaitingFolder(state) {
            if (this.stats) {
                let stat = this.stats.find((stat) => stat.state == state)
                if (stat) {
                    return stat.total
                }
            }
            return 0
        },

        async deleteFolder(folder) {
            this.waitingDelete = true
            let response = await this.$request.post('ecosysteme.delete-waiting-folder', {
                ref: folder.ref
            })
            if (response.data.posts.post == 1) {
                this.init()
                this.$toast.success('Demande supprimée')
                this.$modal.hide('modal-confirm-delete')
            }
            this.waitingDelete = false
        },

        async resendFolder(folder) {
            let response = await this.$request.post('ecosysteme.resend-folder', {
                folder_ref: folder.ref
            })
            if (response.data.posts.post == 1 && !response.data.posts.error) {
                this.$toast.success('Dossier renvoyé')
                this.getFilteredFolders()
            }
        }
    },

    mounted() {
        this.init()
    }
}
</script>

<style>
.v-dropdown-menu {
    width: 100% !important;
}

.vue-daterange-picker {
    width: 100% !important;
}
</style>
