<template>
    <div>
        <app-template>
            <div class="bg-white rounded border shadow-sm p-4">
                <div class="grid grid-cols-8 gap-2">
                    <div class="col-span-2">
                        <input-rule v-model="filters.type" type="select" :options="{values: [{label: 'Fournisseur', value: 1}, {label: 'Distributeur', value: 2}], label: 'label', key: 'value'}" label="Type" :label-inline="false" />
                    </div>
                    <div class="col-span-6">
                        <input-rule
                        id="select-grossiste"
                        class="mb-4"
                        v-model="filters.company"
                        :label-inline="false"
                        :label="$tt('Pour quel société ?')"
                        type="api-select"
                        :options="{
                            api: {action: (filters.type == 1 ? 'inscription.search-grossiste' : 'inscription.search-centrale'), apiName: 'api2'},
                            label: (filters.type == 1 ? 'nom' : 'centrale_ha'),
                            init: false,
                            min: 3,
                            uppercase: true
                        }"
                        />
                    </div>
                </div>
                <div v-if="filters.type && filters.company" class="mt-5 pt-5 border-t">
                    <admin-client-liaisons-table
                    :type="filters.type == 1 ? 'fournisseur' : 'distributeur'"
                    :connected="1"
                    :entite-id="filters.company.id"
                    />
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import AdminClientLiaisonsTable from './../../AdminClient/Components/AdminClientLiaisonsTable.vue'

export default {
    components: {
        AdminClientLiaisonsTable
    },
    data() {
        return {
            filters: {},
            companies: []
        }
    },
}
</script>

<style lang="scss" scoped>

</style>