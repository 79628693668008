<template>
    <div>
        <div class="mt-2 border rounded">
            <div class="flex items-center bg-gray-100 border-b">
                <input v-model="form.title" type="text" class="bg-gray-100 w-full p-3 focus:outline-none font-bold" placeholder="Nom du bloc">
                <button class="px-4" @click="$emit('delete', index)"><i class="fas fa-trash text-red-500"></i></button>
            </div>
            <div class="p-1 px-2">
                <ul class="list-disc">
                    <li class="mb-1 flex items-center gap-2" v-for="(item, itemIndex) in form.items" :key="'item-' + itemIndex">
                        <input @input="emitUpdate" v-model="item.name" type="text" class="border-b w-full border-slate-200 p-2 text-sm font-light focus:outline-none" placeholder="Description">
                        <button @click="removeItem(itemIndex)"><i class="fas fa-times text-red-500"></i></button>
                    </li>
                </ul>
                <button @click="addItem" class="bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer px-3 py-2 rounded-xl mt-2 text-xs">
                    <i class="fas fa-plus mr-1"></i> Ajouter
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dataSrc: {},
        index: {required: true},
    },
    watch: {
        dataSrc() {
            this.setData()
        },
        form: {
            deep: true,
            handler(value) {
                this.$emit('change', {bloc: value, index: this.index})
            }
        }
    },
    data() {
        return {
            form: {
                title: null,
                items: []
            }
        }
    },

    methods: {
        addItem() {
            this.form.items.push({
                name: null,
            })
        },

        removeItem(index) {
            this.form.items.splice(index, 1)
        },
        setData() {
            if (this.dataSrc) {
                this.form.title = this.dataSrc.title
                this.form.items = this.dataSrc.lines
            }
        },
        emitUpdate() {
            this.$eventHub.$emit('update-price')
        }
    },

    mounted() {
        this.setData()
    }
}
</script>

<style lang="scss" scoped>

</style>
