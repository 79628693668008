<template>
    <div>
        <app-template>
            <button class="mb-5 bg-slate-300 font-bold hover:bg-slate-400 duration-200 px-4 py-2.5 rounded-xl text-sm" @click="$router.push({name: 'apps.list'})">
                <i class="fas fa-table mr-0.5"></i> Retour au tableau
            </button>
            <div class="bg-white p-5 py-8 mb-5 rounded-xl border shadow-sm">
                <div class="grid xl:grid-cols-3 gap-10" v-if="form">
                    <!-- Detail du produit -->
                    <card-title title="Détail du produit">
                        <input-rule v-model="form.actif" type="pcheck" label="Actif" class="mb-4" :display-errors="displayErrors" />
                        <input-rule v-model="form.ean" type="text" label="Code EAN" class="mb-4" rules="required" :display-errors="displayErrors" />
                        <input-rule v-model="form.modele" type="text" label="Modèle" class="mb-4" rules="required" :display-errors="displayErrors" />
                        <input-rule v-model="form.libelle" type="text" label="Libelle" class="mb-4" rules="required" :display-errors="displayErrors" />
                        <input-rule v-model="form.brand" type="text" label="Marque" class="mb-4" rules="required" :display-errors="displayErrors" />
                        <input-rule v-if="categories" v-model="form.categ" :options="{values: categories, key: 'id', label: 'label_fr'}" type="select" label="Catégorie" class="mb-4" :display-errors="displayErrors" />
                        <input-rule v-model="form.start_com" type="date" label="Début com." class="mb-4" rules="required" :display-errors="displayErrors" />
                        <input-rule v-model="form.end_com" type="date" label="Fin com." class="mb-4" :display-errors="displayErrors" />
                    </card-title>

                    <!-- Deee -->
                    <card-title title="Classification deee">
                        <input-rule v-model="form.deeeCategories" @input="getDeee" :options="{values: deeeCategories, key: 'id', label: 'libelle_lang'}" type="select" label="Catégorie" class="mb-4" rules="required" :display-errors="displayErrors" />
                        <input-rule v-if="deee" v-model="form.deee" :options="{values: deee, key: 'id', label: 'code'}" type="select" label="DEEE" class="mb-4" rules="required" :display-errors="displayErrors" />
                        <input-rule v-model="form.sorecop" type="text" label="SORECOP" class="mb-4" :display-errors="displayErrors" />
                    </card-title>

                    <!-- Documents -->
                    <card-title title="Documents">
                        <input-rule v-model="form.img" type="file" label="Photo du produit" class="mb-4" rules="required" :display-errors="displayErrors" />
                        <input-rule v-model="form.doc" type="file" label="Documentation commerciale" class="mb-4" rules="required" :display-errors="displayErrors" />
                        <input-rule v-model="form.notice" type="file" label="Notice" class="mb-4" rules="required" :display-errors="displayErrors" />
                        <input-rule v-model="form.information" type="textarea" label="Information produit (en point d'accueil)" class="mb-4" rules="required" :display-errors="displayErrors" />
                    </card-title>
                </div>
                <div class="text-right mt-10">
                    <button :disabled="loadingSendForm" @click="sendForm" class="px-7 py-3 text-white duration-100 duration-200 bg-green-600 border-green-700 hover:bg-green-700 rounded-xl">
                        <spinner v-if="loadingSendForm" :size="10" />
                        <div v-show="!loadingSendForm">
                            <i class="fas fa-check mr-1"></i> Enregistrer
                        </div>
                    </button>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import apiService from '@/services/api.service'

export default {
    data() {
        return {
            form: null,
            displayErrors: false,

            categories: [],
            deeeCategories: [],
            deee: null,

            pieces: [],

            loadingSendForm: false,
        }
    },
    methods: {
        async initFormData() {
            let defaultApp = null
            if (this.$route.query.id) {
                let response = await apiService.post('A5141getappareilbyid', {
                    id_appareil: this.$route.query.id
                }, 'districloud_no_action')
                if (response.data.posts.post && response.data.posts.post.length > 0) {
                    defaultApp = response.data.posts.post[0]
                } else {
                    this.$router.push({name: 'apps.list'})
                }
            }
            this.form = {
                actif: defaultApp?.['actif'] == '0' ? false : true,
                ean: defaultApp?.['Ean'] || null,
                modele: defaultApp?.['Modele'] || null,
                libelle: defaultApp?.['Libelle'] || null,
                brand: defaultApp?.['Marque'] || null,
                start_com: defaultApp?.['date_debcom'] || null,
                end_com: defaultApp?.['date_fincom'] || null,
                categ: defaultApp?.['id_categorie'] || null,
                deee: defaultApp?.['id_deee'] || null,
                deeeCategories: defaultApp?.['id_deee_categorie'] || null,
                sorecop: defaultApp?.['sorecop_app'] || null,
                img: defaultApp?.['url_photo'] || null,
                doc: null,
                notice: null,
                information: null,
            }
        },

        async getCateg() {
            let response = await apiService.post('A5141categorie', {
                operation: 'get'
            }, 'districloud_action')

            this.categories = response.data.posts.post
        },

        async getDeeeCateg() {
            let response = await apiService.post('A5141getdeeecategorie', {}, 'districloud_action')
            this.deeeCategories = response.data.posts.post
            this.getDeee()
        },

        async getDeee() {
            if (this.form && this.form.deeeCategories && this.form.deeeCategories) {
                this.form.deee = null
                let response = await apiService.post('A5141getdeee', {
                    id_deee_categorie: this.form.deeeCategories
                }, 'districloud_action')
                this.deee = response.data.posts.post
            }
        },

        async sendForm() {
            this.displayErrors = false
            for (let product in this.form.product) {
                if (this.form.product[product].valid !== null && this.form.product[product].valid === false) {
                    this.displayErrors = true
                }
            }
            
            if (this.displayErrors === true) {
                this.$toast.error('Formulaire incorrect')
                return
            }
            this.loadingSendForm = true

            let formData = {
                actif: this.form.actif == true ? 1 : 0,
                Marque: this.form.brand,
                Modele: this.form.modele,
                id_categorie: this.form.categ,
                Ean: this.form.ean,
                Libelle: this.form.libelle,
                date_debcom: this.form.start_com,
                date_fincom: this.form.end_com,
                comment: this.form.information,
                id_deee: this.form.deee,
                sorecop_app: this.form.sorecop,
                id_photo: this.form.img?.id || null,
                id_doccom: this.form.doc?.id || null,
                id_notice: this.form.notice?.id || null,
            }

            if (this.$route.query.id) {
                formData.id_appareil = this.$route.query.id
            }
            let response = await apiService.post('A5141updateappareil', formData, 'districloud_no_action')
            if (response.data.posts.post == 1) {
                this.$toast.success(this.$route.query.id ? 'Appareil mis à jour' : 'Appareil créé')
                this.$router.push({name: 'apps.list'})
            }
            this.loadingSendForm = false
        },
    },
    mounted () {
        this.initFormData();
        this.$nextTick(() => {
            this.getCateg()
            this.getDeeeCateg()
        })
    },
}
</script>
