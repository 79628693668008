<template>
    <div>
        <!-- Modal call center licences -->
        <modal name="modal-edit-callcenter" height="auto" :scrollable="true">
            <div v-if="selectedLine" class="p-10" style="z-index: 999 !important;">
                <div class="text-right mb-4 border-b pb-4 flex items-center justify-between">
                    <h2 class="font-bold text-lg">
                        {{$tt('Configuration')}}
                    </h2>
                    <button @click="$modal.hide('modal-edit-callcenter')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div class="bg-orange-100 rounded p-4 text-sm font-light">
                    <template v-if="!clientId">{{ $tt('En modifiant ce module, des frais peuvent être changés sur votre prochaine facture') }}</template>
                    <template v-if="clientId">{{$tt('En modifiant ce module, des frais peuvent être changés sur la prochaine facture')}}</template>
                </div>
                <admin-client-manage-callcenter
                @update="(data) => {metaLicences = data}"
                :client-id="clientId"
                :init-data="selectedLine.callcenter_licences"
                :amount="selectedLine.value"
                :edit="true"
                :frais-supp="selectedLine.frais_supp"
                />
                <div v-if="!clientId" class="mt-4">
                    <input v-model="acceptCondition" id="inp-accept-conditions" type="checkbox" />
                    <label class="ml-2" for="inp-accept-conditions">{{$tt('J\'accepte les')}} <a class="text-blue-500 underline" target="_blank" href="https://disticloudfiles.net/upload/files/CONDITIONS%20GENERALES%20SAVLOG.pdf">{{$tt('conditions générales de vente')}}</a></label>
                </div>
                <div class="text-center">
                    <button :disabled="selectedLine.loading" @click="updateCallcenterLicences" class="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded text-sm font-light mt-4 duration-200">
                        <template v-if="!selectedLine.loading">
                            <span v-if="!selectedLine.is_applied">{{$tt('Activer')}}</span>
                            <span v-if="selectedLine.is_applied">{{$tt('Modifier')}}</span>
                            <i class="fas fa-check ml-1"></i>
                        </template>
                        <spinner :size="6" v-if="selectedLine.loading" />
                    </button>
                </div>
            </div>
        </modal>

        <!-- Modal stats licences -->
        <modal name="modal-edit-stats" height="auto" :scrollable="true">
            <div v-if="selectedLine" class="p-10" style="z-index: 999 !important;">
                <div class="text-right mb-4 border-b pb-4 flex items-center justify-between">
                    <h2 class="font-bold text-lg">
                        {{$tt('Configuration')}}
                    </h2>
                    <button @click="$modal.hide('modal-edit-stats')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div class="bg-orange-100 rounded p-4 text-sm font-light">
                    <template v-if="!clientId">{{$tt('En modifiant ce module, des frais peuvent être changés sur votre prochaine facture')}}</template>
                    <template v-if="clientId">{{$tt('En modifiant ce module, des frais peuvent être changés sur la prochaine facture')}}</template>
                </div>
                <admin-client-manage-callcenter
                @update="(data) => {metaLicences = data}"
                :client-id="clientId"
                :init-data="selectedLine.stats_licences"
                :amount="selectedLine.value"
                :edit="true"
                :frais-supp="selectedLine.frais_supp"
                />
                <div v-if="!clientId" class="mt-4">
                    <input v-model="acceptCondition" id="inp-accept-conditions" type="checkbox" />
                    <label class="ml-2" for="inp-accept-conditions">{{ $tt('J\'accepte les') }} <a class="text-blue-500 underline" target="_blank" href="https://disticloudfiles.net/upload/files/CONDITIONS%20GENERALES%20SAVLOG.pdf">{{$tt('conditions générales de vente')}}</a></label>
                </div>
                <div class="text-center">
                    <button :disabled="selectedLine.loading" @click="updateStatsLicences" class="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded text-sm font-light mt-4 duration-200">
                        <template v-if="!selectedLine.loading">
                            <span v-if="!selectedLine.is_applied">{{$tt('Activer')}}</span>
                            <span v-if="selectedLine.is_applied">{{$tt('Modifier')}}</span>
                            <i class="fas fa-check ml-1"></i>
                        </template>
                        <spinner :size="6" v-if="selectedLine.loading" />
                    </button>
                </div>
            </div>
        </modal>

        <!-- Modal info site vitrine -->
        <modal name="modal-website-info" width="70%" height="auto" :scrollable="true">
            <div v-if="selectedOptionInfoUrl">
                <div class="flex justify-end pr-10 my-4">
                    <button class="bg-red-500 hover:bg-red-600 duration-200 cursor-pointer px-4 py-2 rounded text-white text-sm" @click="$modal.hide('modal-website-info')"><i class="fas fa-times mr-1"></i> {{$tt('Fermer')}}</button>
                </div>
                <iframe class="w-full h-[80vh]" :src="selectedOptionInfoUrl" />
            </div>
        </modal>

        <!-- Modale valider mise en production -->
        <modal name="modal-end-waiting-option" height="auto" :scrollable="true">
            <div v-if="selectedLine" class="p-10" style="z-index: 999 !important;">
                <div class="text-right mb-4 border-b pb-4 flex items-center justify-between">
                    <h2 class="font-bold text-lg">
                        {{$tt('Valider la mise en production')}}
                    </h2>
                    <button @click="$modal.hide('modal-end-waiting-option')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <p>{{$tt('Confirmez-vous la mise en production de')}} <b>{{ selectedLine.label }}</b> ?</p>
                <button :disabled="loadingModal" @click="endWaitingOption" class="bg-green-500 hover:bg-green-600 duration-200 cursor-pointer text-white rounded px-4 py-2 text-sm mt-4 mr-2">
                    <spinner v-if="loadingModal" :size="10" />
                    <template v-if="!loadingModal">
                        <i class="fas fa-check mr-1"></i> {{$tt('Confirmer')}}
                    </template>
                </button>
            </div>
        </modal>

        <!-- Modale delete option -->
        <modal name="modal-delete-option" height="auto" :scrollable="true">
            <div v-if="selectedLine" class="p-10" style="z-index: 999 !important;">
                <div class="text-right mb-4 border-b pb-4 flex items-center justify-between">
                    <h2 class="font-bold text-lg">
                        {{$tt('Supprimer un module')}}
                    </h2>
                    <button @click="$modal.hide('modal-delete-option')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <p>{{$tt('Confirmez-vous la suppression de')}} <b>{{ selectedLine.label }}</b> ?</p>
                <p v-if="!clientId" class="text-sm font-light mt-2 bg-slate-100 p-4 rounded">{{$tt('Si vous supprimez cette option, elle sera encore active jusqu\'au dernier jour du mois, puis ne vous sera plus facturée')}}</p>
                <button :disabled="loadingModal" @click="removeOption(selectedLine)" class="bg-red-500 hover:bg-red-600 duration-200 cursor-pointer text-white rounded px-4 py-2 text-sm mt-4 mr-2">
                    <spinner v-if="loadingModal" :size="10" />
                    <template v-if="!loadingModal">
                        <i class="fas fa-check mr-1"></i> {{$tt('Confirmer')}}
                    </template>
                </button>
            </div>
        </modal>

        <!-- Modal reactivate option -->
        <modal name="modal-reactivate" height="auto" :scrollable="true">
            <div v-if="selectedLine" class="p-10" style="z-index: 999 !important;">
                <div class="text-right mb-4 border-b pb-4 flex items-center justify-between">
                    <h2 class="font-bold text-lg">
                        {{$tt('Réactiver une option')}}
                    </h2>
                    <button @click="$modal.hide('modal-reactivate')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <p>{{$tt('Confirmez-vous la réactivation de')}} <b>{{ selectedLine.label }}</b> ?</p>
                <p v-if="!clientId" class="text-sm font-light mt-2 bg-slate-100 p-4 rounded">{{$tt('Si vous réactivez cette option, elle ne sera pas supprimée sur votre prochaine facture')}}</p>
                <button :disabled="loadingModal" @click="reactivateOption" class="bg-green-500 hover:bg-green-600 duration-200 cursor-pointer text-white rounded px-4 py-2 text-sm mt-4 mr-2">
                    <spinner v-if="loadingModal" :size="10" />
                    <template v-if="!loadingModal">
                        <i class="fas fa-check mr-1"></i> {{$tt('Confirmer')}}
                    </template>
                </button>
            </div>
        </modal>

        <!-- Modal add option -->
        <modal name="modal-alert-add" height="auto" :scrollable="true">
            <div v-if="selectedLine" class="p-10" style="z-index: 999 !important;">
                <div class="text-right mb-4 border-b pb-4 flex items-center justify-between">
                    <h2 class="font-bold text-lg">
                        <span v-if="!selectedLine.is_applied">{{$tt('Activer un module')}}</span>
                        <span v-if="selectedLine.is_applied">{{$tt('Modifier le module')}}</span>
                    </h2>
                    <button @click="$modal.hide('modal-alert-add')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <!-- Selfcare -->
                <template v-if="selectedLine.keyword == 'selfcare' && !selectedLine.is_applied">
                    <div class="bg-slate-100 p-4 rounded text-sm">
                        <ul class="ml-4 list-disc">
                            <li class="mb-2">{{ $tt('Le Module Selfcare requiert une configuration spécifique') }}</li>
                            <li class="mb-2">{{ $tt('Une fois votre demande faite, nous vous recontacterons dans les plus brefs délais') }}</li>
                        </ul>
                    </div>
                    <div class="bg-slate-100 p-4 rounded text-sm mt-2">
                        {{$tt('Abonnement')}}: {{ getAmount(selectedLine) }}
                    </div>
                    <div v-if="!clientId" class="my-4 bg-slate-100 p-4 rounded text-sm mt-2">
                        <div class="font-bold text-sm mb-2 border-b border-slate-300 pb-2">{{$tt('Configuration de l\'espace')}}</div>
                        <input-rule @input="(data) => {selfcareConf.logo = data && data.url ? data.url : null}" type="file" :label="$tt('Votre logo')" :label-inline="false" class="mb-2" />
                        <input-rule @input="(data) => {selfcareConf.image = data && data.url ? data.url : null}" type="file" :label="$tt('Image par défaut')" :label-inline="false" class="mb-2" />
                        <input-rule v-model="selfcareConf.couleur" type="color" :label="$tt('Couleur dominante')" :label-inline="false" class="mb-2" />
                        <div class="grid grid-cols-3 gap-2">
                            <input-rule v-model="selfcareConf.options.mobile" type="pcheck" :label="$tt('Utilisation sur mobile')" :label-inline="false" class="mb-2" />
                            <input-rule v-model="selfcareConf.options.traduction" type="pcheck" :label="$tt('Multilingue')" :label-inline="false" class="mb-2" />
                            <!-- <input-rule v-model="selfcareConf.options.newsletter" type="pcheck" label="Newsletter" :label-inline="false" class="mb-2" /> -->
                        </div>
                    </div>
                    <div v-if="!clientId" class="my-4">
                        <input v-model="acceptCondition" id="inp-accept-conditions" type="checkbox" />
                        <label class="ml-2" for="inp-accept-conditions">{{$tt('J\'accepte les')}} <a class="text-blue-500 underline" target="_blank" href="https://disticloudfiles.net/upload/files/CONDITIONS%20GENERALES%20SAVLOG.pdf">{{$tt('conditions générales de vente')}}</a></label>
                    </div>
                    <div class="text-center">
                        <button :disabled="selectedLine.loading" @click="addNewOption(selectedLine)" class="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded text-sm font-light mt-4 duration-200">
                            <template v-if="!selectedLine.loading">
                                {{$tt('Faire une demande')}}
                                <i class="fas fa-check ml-1"></i>
                            </template>
                            <spinner :size="6" v-if="selectedLine.loading" />
                        </button>
                    </div>
                </template>
                <!-- Callcenter -->
                <template v-else-if="selectedLine.keyword == 'callcenter' && !selectedLine.is_applied">
                    <div v-if="selectedLine.type != 'remise'" class="bg-orange-100 rounded p-4 text-sm font-light">
                        <template v-if="!clientId">{{$tt('En activant ce module, des frais seront appliqués sur votre prochaine facture')}}</template>
                        <template v-if="clientId">{{$tt('En activant ce module, des frais seront ajoutés sur la prochaine facture')}}</template>
                    </div>
                    <div v-if="selectedLine.type != 'remise'" class="mt-2 bg-purple-100 rounded p-4 text-sm font-light">
                        {{$tt('Abonnement')}}: {{ getAmount(selectedLine) }}
                    </div>
                    <admin-client-manage-callcenter :amount="selectedLine.value" @update="(data) => {metaLicences = data}" :client-id="clientId" :frais-prod="selectedLine.frais_prod" />
                    <div v-if="!clientId" class="mt-4">
                        <input v-model="acceptCondition" id="inp-accept-conditions" type="checkbox" />
                        <label class="ml-2" for="inp-accept-conditions">{{$tt('J\'accepte les')}} <a class="text-blue-500 underline" target="_blank" href="https://disticloudfiles.net/upload/files/CONDITIONS%20GENERALES%20SAVLOG.pdf">{{ $tt('conditions générales de vente') }}</a></label>
                    </div>
                    <div class="text-center">
                        <button :disabled="selectedLine.loading" @click="addNewOption(selectedLine)" class="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded text-sm font-light mt-4 duration-200">
                            <template v-if="!selectedLine.loading">
                                <span v-if="!selectedLine.is_applied">{{$tt('Activer')}}</span>
                                <span v-if="selectedLine.is_applied">{{$tt('Modifier')}}</span>
                                <i class="fas fa-check ml-1"></i>
                            </template>
                            <spinner :size="6" v-if="selectedLine.loading" />
                        </button>
                    </div>
                </template>
                <!-- Stats -->
                <template v-else-if="selectedLine.keyword == 'stats' && !selectedLine.is_applied">
                    <div v-if="selectedLine.type != 'remise'" class="bg-orange-100 rounded p-4 text-sm font-light">
                        <template v-if="!clientId">{{$tt('En activant ce module, des frais seront appliqués sur votre prochaine facture')}}</template>
                        <template v-if="clientId">{{$tt('En activant ce module, des frais seront ajoutés sur la prochaine facture')}}</template>
                    </div>
                    <div v-if="!clientId" class="bg-orange-100 rounded p-4 text-sm font-light mt-2">
                        {{$tt('Cette option sera activée sous 24h (jours ouvrés), le cas échéant, merci de nous relancer')}}
                    </div>
                    <div v-if="selectedLine.type != 'remise'" class="mt-2 bg-purple-100 rounded p-4 text-sm font-light">
                        {{$tt('Abonnement mensuel')}}: {{selectedLine.frais_supp}} € + {{ selectedLine.value }} € {{ $tt('par utilisateur') }}
                    </div>
                    <admin-client-manage-callcenter :amount="selectedLine.value" @update="(data) => {metaLicences = data}" :client-id="clientId" :frais-supp="selectedLine.frais_supp" :frais-prod="selectedLine.frais_prod" />
                    <!-- <admin-client-manage-stat-licences :amount="selectedLine.value" @update="(data) => {metaLicences = data}" :client-id="clientId" :frais-prod="selectedLine.frais_prod" :frais-supp="selectedLine.frais_supp" /> -->
                    <div v-if="!clientId" class="mt-4">
                        <input v-model="acceptCondition" id="inp-accept-conditions" type="checkbox" />
                        <label class="ml-2" for="inp-accept-conditions">{{ $tt('J\'accepte les') }} <a class="text-blue-500 underline" target="_blank" href="https://disticloudfiles.net/upload/files/CONDITIONS%20GENERALES%20SAVLOG.pdf">{{$tt('conditions générales de vente')}}</a></label>
                    </div>
                    <div class="text-center">
                        <button :disabled="selectedLine.loading" @click="addNewOption(selectedLine)" class="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded text-sm font-light mt-4 duration-200">
                            <template v-if="!selectedLine.loading">
                                <span v-if="!selectedLine.is_applied">{{$tt('Activer')}}</span>
                                <span v-if="selectedLine.is_applied">{{$tt('Modifier')}}</span>
                                <i class="fas fa-check ml-1"></i>
                            </template>
                            <spinner :size="6" v-if="selectedLine.loading" />
                        </button>
                    </div>
                </template>
                <!-- Station Mag -->
                <template v-else-if="selectedLine.keyword == 'atelier_reparation' && !selectedLine.is_applied">
                    <div v-if="selectedLine.type != 'remise'" class="bg-orange-100 rounded p-4 text-sm font-light">
                        <template v-if="!clientId">{{$tt('En activant ce module, des frais seront appliqués sur votre prochaine facture')}}</template>
                        <template v-if="clientId">{{$tt('En activant ce module, des frais seront ajoutés sur la prochaine facture')}}</template>
                    </div>
                    <div v-if="selectedLine.type != 'remise'" class="mt-2 bg-purple-100 rounded p-4 text-sm font-light">
                        {{$tt('Abonnement')}}: {{ getAmount(selectedLine) }}
                    </div>
                    <div class="mt-4 bg-slate-100 p-4 rounded">
                        <h2 class="text-lg font-bold border-b border-slate-300 mb-2 pb-2"><i class="fas fa-user mr-1"></i> {{ $tt('Création de votre compte réparateur') }}</h2>
                        <input-rule v-model="selectedLine.account_identifiant" @input="checkAvailableIdentifiant" type="text" :label="$tt('Identifiant de connexion')" :label-inline="false" :tooltip="$tt('Cet identifiant sera utilisé pour accéder à votre compte réparateur')" id="inp-account_identifiant" />
                        <div class="text-xs mt-2" v-if="availableIdentifiant !== null">
                            <spinner v-if="loadingCheckIdentifiant" :size="8" />
                            <template v-if="!loadingCheckIdentifiant">
                                <span class="text-red-500" v-if="!availableIdentifiant"><i class="fas fa-times mr-0.5"></i> {{$tt('Cet identifiant est indisponible')}}</span>
                                <span class="text-green-500" v-if="availableIdentifiant"><i class="fas fa-check mr-0.5"></i> {{$tt('Cet identifiant est correct')}}</span>
                            </template>
                        </div>
                        <input-rule v-model="selectedLine.account_email" type="text" :label="$tt('Adresse email')" :label-inline="false" :tooltip="$tt('Saisir une adresse email valide, le lien d\'activation de votre compte sera envoyé sur celle-ci')" class="mt-4" id="inp-account_email" />
                    </div>
                    <div v-if="!clientId" class="mt-4">
                        <input v-model="acceptCondition" id="inp-accept-conditions" type="checkbox" />
                        <label class="ml-2" for="inp-accept-conditions">{{ $tt('J\'accepte les') }} <a class="text-blue-500 underline" target="_blank" href="https://disticloudfiles.net/upload/files/CONDITIONS%20GENERALES%20SAVLOG.pdf">{{$tt('conditions générales de vente')}}</a></label>
                    </div>
                    <div class="text-center">
                        <button :disabled="selectedLine.loading" @click="addNewOption(selectedLine)" class="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded text-sm font-light mt-4 duration-200">
                            <template v-if="!selectedLine.loading">
                                <span v-if="!selectedLine.is_applied">{{$tt('Activer')}}</span>
                                <span v-if="selectedLine.is_applied">{{$tt('Modifier')}}</span>
                                <i class="fas fa-check ml-1"></i>
                            </template>
                            <spinner :size="6" v-if="selectedLine.loading" />
                        </button>
                    </div>
                </template>
                <!-- Other -->
                <template v-else>
                    <!-- Prévention frais supplémentaires -->
                    <div v-if="selectedLine.type != 'remise'" class="bg-orange-100 rounded p-4 text-sm font-light">
                        <template v-if="!clientId">{{$tt('En activant ce module, des frais seront appliqués sur votre prochaine facture')}}</template>
                        <template v-if="clientId">{{$tt('En activant ce module, des frais seront ajoutés sur la prochaine facture')}}</template>
                    </div>
                    <!-- Details prix -->
                    <div v-if="typeLabels[selectedLine.type] && getAmount(selectedLine)" class="bg-slate-100 rounded p-4 text-sm font-light mt-2">
                        <div v-if="typeLabels[selectedLine.type]">{{$tt('Calcul selon')}}: <b>{{ typeLabels[selectedLine.type] }}</b></div>
                        <div v-if="selectedLine.frais_prod != 0">{{$tt('Coût unique de mise en production (démarrage)')}}: <b>{{ selectedLine.frais_prod }}</b> €</div>
                        <div v-if="getAmount(selectedLine)">
                            <span>{{$tt('Abonnement')}}: <b>{{ getAmount(selectedLine) }}</b></span>
                            <span class="ml-1 text-xs" v-if="selectedLine.keyword == 'connection'">{{$tt('(Par lot de 80 dossiers)')}}</span>
                        </div>
                    </div>
                    <!-- Admin gestion remise -->
                    <div v-if="clientId" class="bg-slate-100 rounded p-4 text-sm font-light mt-2">
                        <div class="grid grid-cols-3 gap-2">
                            <div><input v-model="remiseType" value="0" type="radio" id="remise-0" /> <label for="remise-0">{{$tt('Aucune remise')}}</label></div>
                            <div v-if="selectedLine.type != 'remise'"><input v-model="remiseType" value="1" type="radio" id="remise-1" /> <label for="remise-1">{{$tt('Prix fixe')}}</label></div>
                            <div><input v-model="remiseType" value="2" type="radio" id="remise-2" /> <label for="remise-2">{{$tt('Pourcentage')}}</label></div>
                        </div>
                        <div class="grid grid-cols-2 gap-2 mt-4">
                            <input-rule v-model="remiseFixe" v-if="remiseType == 1" type="price" :label="$tt('Combien sera facturée cette option ?')" :label-inline="false" />
                            <input-rule v-model="remisePercent" v-if="remiseType == 2" type="number" :label="$tt('Appliquer une remise (en %)')" :label-inline="false" />
                        </div>
                    </div>
                    <!-- Dossiers illimités -->
                    <div v-if="clientId && selectedLine.keyword == 'connection'" class="bg-slate-100 rounded p-4 text-sm font-light mt-2">
                        <div class="grid grid-cols-2 gap-2 border-b mb-2 pb-2 border-slate-300">
                            <div><input @input="() => {selectedLine.nb_dossier = null}" v-model="typeLotDsc" value="1" type="radio" id="lot-custom" /> <label for="lot-custom">{{$tt('Définir un lot')}}</label></div>
                            <div><input @input="() => {selectedLine.nb_dossier = '-1'}" v-model="typeLotDsc" value="0" type="radio" id="lot-illimite" /> <label for="lot-illimite">{{$tt('Dossiers illimités')}}</label></div>
                        </div>

                        <template v-if="typeLotDsc == '1'">
                            <input-rule v-model="selectedLine.nb_dossier" type="text" :label="$tt('Nombre de dossier par lot')" :label-inline="false" />
                        </template>
                        <template v-if="typeLotDsc == '0'">
                            <div class="bg-green-300 p-2 rounded">
                                <i class="fas fa-check-circle mr-1"></i> {{$tt('Dossiers illimités')}}
                            </div>
                        </template>
                    </div>
                    <!-- Accepter les conditions -->
                    <div v-if="!clientId" class="my-4">
                        <input v-model="acceptCondition" id="inp-accept-conditions" type="checkbox" />
                        <label class="ml-2" for="inp-accept-conditions">{{$tt('J\'accepte les')}} <a class="text-blue-500 underline" target="_blank" href="https://disticloudfiles.net/upload/files/CONDITIONS%20GENERALES%20SAVLOG.pdf">{{$tt('conditions générales de vente')}}</a></label>
                    </div>
                    <!-- Button send -->
                    <div class="text-center">
                        <button :disabled="selectedLine.loading" @click="addNewOption(selectedLine)" class="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded text-sm font-light mt-4 duration-200">
                            <template v-if="!selectedLine.loading">
                                <span v-if="!selectedLine.is_applied">{{$tt('Activer')}}</span>
                                <span v-if="selectedLine.is_applied">{{$tt('Modifier')}}</span>
                                <i class="fas fa-check ml-1"></i>
                            </template>
                            <spinner :size="6" v-if="selectedLine.loading" />
                        </button>
                    </div>
                </template>
            </div>
        </modal>

        <div class="flex items-center justify-center" v-if="loading">
            <spinner :size="10" />
        </div>
        <template v-if="options && !loading">
            <div v-if="!clientId" class="p-4 rounded bg-orange-200 text-orange-900 mb-10">
                <div class="text-xl font-base"><i class="fas fa-exclamation-triangle mr-1"></i> {{ $tt('En activant une option, vous acceptez les conditions suivantes') }} :</div>
                <ul class="list-disc ml-4 text-sm font-light mt-2">
                    <li>{{$tt('Lorsqu\'un module est activé, il sera automatiquement facturé lors de la prochaine échéance')}}</li>
                    <li>{{ $tt('Vous avez un mois de préavis lorsque vous la désactiver') }}</li>
                </ul>
            </div>
            <div class="grid xl:grid-cols-3 sm:grid-cols-2 gap-3">
                <div v-for="(option, optionIndex) in options" :key="'option-' + optionIndex" class="p-5" :class="clientId && option.attente ? 'border border-orange-800 bg-orange-100 text-orange-800' : 'border'">
                    <div class="mb-2">
                        <div class="border-b flex items-center justify-between pb-3 mb-2.5" :class="option.attente && clientId ? 'border-orange-800' : ''">
                            <div>
                                <h2 class="font-bold">{{ $tt(option.label) }}</h2>
                                <p class="my-1 font-light text-xs">{{ $tt(option.description) }}</p>
                            </div>
                            <button v-if="option.info_url && !clientId" @click="selectedOptionInfoUrl = option.info_url; $modal.show('modal-website-info')" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer rounded text-xs"><i class="fa-solid fa-circle-info mr-1"></i> {{$tt('En savoir plus')}}</button>
                        </div>

                        <div v-if="(option.deleted || option.remise || option.is_applied) && !option.attente">
                            <!-- Remise -->
                            <div class="text-xs font-light" v-if="option.remise && !option.deleted">
                                {{$tt('Remise de')}} {{ option.remise }} %
                            </div>
    
                            <!-- Tarif -->
                            <div>
                                <span class="text-xs font-light bg-yellow-200 text-yellow-700 rounded px-2 py-1.5" v-if="option.forfait && !option.deleted">
                                    <i class="fas fa-calculator mr-0.5"></i> {{$tt('Coût mensuel')}}: {{ option.forfait.value }} €
                                </span>
                            </div>
    
                            <!-- En attente de suppression -->
                            <div v-if="option.deleted" class="bg-orange-200 text-orange-800 rounded p-2 text-sm font-bold"><i class="fas fa-exclamation-triangle mr-1"></i> {{$tt('Active jusqu\'au')}} {{ option.end_at | momentDateTime }}</div>
                        </div>
                    </div>

                    <div>
                        <div v-if="!option.is_waiting && !option.is_applied">
                            <!-- Activer -->
                            <button @click="displayEditModal(option)" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 cursor-pointer mt-1 text-white text-xs rounded"><i class="fa-solid fa-check mr-1"></i> {{$tt('Activer')}}</button>
                        </div>
                        <div class="flex gap-1" v-if="option.is_applied && !option.attente">
                            <!-- Manage options -->
                            <button v-if="option.keyword == 'callcenter'" @click="selectedLine = option; $modal.show('modal-edit-callcenter')" class="px-4 py-2 bg-slate-400 hover:bg-slate-600 duration-200 cursor-pointer mt-1 text-white text-xs rounded">{{$tt('Licences')}}</button>
                            <button v-if="option.keyword == 'stats'" @click="selectedLine = option; $modal.show('modal-edit-stats')" class="px-4 py-2 bg-slate-400 hover:bg-slate-600 duration-200 cursor-pointer mt-1 text-white text-xs rounded">{{$tt('Licences')}}</button>
                            <!-- Delete -->
                            <button v-if="!option.deleted && clientId" @click="displayEditModal(option)" class="text-xs bg-slate-400 hover:bg-slate-500 duration-200 cursor-pointer text-white rounded text-xs rounded px-4 py-2 mt-1">{{$tt('Configurer')}}</button>
                            <button v-if="!option.deleted" :disabled="option.loading" @click="selectedLine = option; $modal.show('modal-delete-option')" class="text-xs bg-red-500 hover:bg-red-600 duration-200 cursor-pointer text-white rounded text-xs rounded px-4 py-2 mt-1"><i class="fas fa-times mr-1"></i> {{$tt('Supprimer')}}</button>
                            <!-- Réactiver -->
                            <button v-if="option.deleted" :disabled="option.loading" @click="selectedLine = option; $modal.show('modal-reactivate')" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 cursor-pointer mt-1 text-white text-xs rounded">{{$tt('Réactiver')}}</button>
                        </div>
                        <div v-if="option.is_applied && option.attente">
                            <template v-if="clientId && option.keyword == 'selfcare'">
                                <div class="mb-2">
                                    <div class="font-bold mb-1">{{$tt('Logo')}}</div>
                                    <a :href="option.meta.selfcare.logo" target="_blank" class="font-light text-blue-500 text-sm"><i class="fas fa-link mr-1"></i> {{$tt('Consulter')}}</a>
                                </div>
                                <div class="mb-2">
                                    <div class="font-bold mb-1">{{$tt('Image principale')}}</div>
                                    <a :href="option.meta.selfcare.image" target="_blank" class="font-light text-blue-500 text-sm"><i class="fas fa-link mr-1"></i> {{$tt('Consulter')}}</a>
                                </div>
                                <div class="mb-2">
                                    <div class="font-bold mb-1">{{$tt('Couleur')}}</div>
                                    <div class="text-sm">{{ option.meta.selfcare.couleur }}</div>
                                </div>
                                <div class="mb-2">
                                    <div class="font-bold mb-1">{{$tt('Options')}}</div>
                                    <div class="grid grid-cols-3 gap-2">
                                        <div class="flex items-center gap-2">
                                            <i v-if="option.meta.selfcare.options.mobile" class="fas fa-check text-green-500"></i>
                                            <i v-if="!option.meta.selfcare.options.mobile" class="fas fa-times text-red-500"></i>
                                            <div class="text-sm">{{$tt('Mobile')}}</div>
                                        </div>
                                        <div class="flex items-center gap-2">
                                            <i v-if="option.meta.selfcare.options.traduction" class="fas fa-check text-green-500"></i>
                                            <i v-if="!option.meta.selfcare.options.traduction" class="fas fa-times text-red-500"></i>
                                            <div class="text-sm">{{$tt('Traduction')}}</div>
                                        </div>
                                        <div class="flex items-center gap-2">
                                            <i v-if="option.meta.selfcare.options.newsletter" class="fas fa-check text-green-500"></i>
                                            <i v-if="!option.meta.selfcare.options.newsletter" class="fas fa-times text-red-500"></i>
                                            <div class="text-sm">{{$tt('Newsletter')}}</div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="clientId && option.keyword == 'stats'">
                                <div class="mb-4">
                                    <div class="font-bold">{{ option.stats_licences.length + ' ' + (option.stats_licences.length > 1 ? 'licences' : 'licence') }}</div>
                                    <ul class="list-disc text-sm font-light ml-4">
                                        <li v-for="(licence, licenceIndex) in option.stats_licences" :key="'licence-' + licenceIndex">{{ licence.email }}</li>
                                    </ul>
                                </div>
                            </template>
                            <!-- En attente -->
                            <button v-if="!clientId" class="px-4 py-2 bg-purple-500 cursor-default text-white text-xs rounded"><i class="fa-solid fa-hourglass-half mr-1"></i> {{$tt('En attente d\'activation')}}</button>
                            <div class="bg-orange-200 border border-orange-300 p-3 rounded" v-if="clientId">
                                <p class="text-sm mb-2"><i class="fas fa-exclamation-triangle mr-1"></i> {{$tt('Cette option est en attente d\'activation')}}</p>
                                <button @click="selectedLine = option; $modal.show('modal-end-waiting-option')" class="px-4 py-2 border border-orange-800 hover:bg-orange-900 hover:text-white text-orange-900 duration-200 text-white text-xs rounded cursor-pointer"><i class="fa-solid fa-check mr-1"></i> {{$tt('Valider la mise en production')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import moment from 'moment'
import AdminClientManageCallcenter from './AdminClientManageCallcenter.vue'
// import AdminClientManageStatLicences from './AdminClientManageStatLicences.vue'

export default {
    components: {
        // AdminClientManageStatLicences,
        AdminClientManageCallcenter,
    },
    props: {
        clientId: {},
    },
    watch: {
        selectedLine() {
            this.acceptCondition = false
        },
    },
    data() {
        return {
            options: [],
            selectedLine: null,
            typeLabels: {
                'connections': "Nombre de liaisons",
                'users': "Nombre de licences",
                'fixe': "Tarif fixe",
                'pourcentage': "Pourcentage",
            },

            nbLicences: 0,
            metaLicences: [{email: null}],

            selectedOptionInfoUrl: null,

            selfcareConf: {
                options: {}
            },

            availableIdentifiant: null,
            timerSearchIdentifiant: null,

            remiseType: 0,
            remisePercent: null,
            remiseFixe: null,

            typeLotDsc: null,

            acceptCondition: false,

            loading: false,
            loadingModal: false,
            loadingCheckIdentifiant: false,
        }
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        async getOptions() {
            this.loading = true
            let endObjModules = {
                start_period: moment().format('MM/YYYY'),
                end_period: moment().format('MM/YYYY'),
            }
            if (this.clientId) {
                endObjModules.client_id = this.clientId
            }
            let responseModules = await this.$request.post('facturation.simulation', endObjModules)
            let forfaits = responseModules.data.posts.post.details
            let endObj = {}
            if (this.clientId) {
                endObj.client_id = this.clientId
            }
            let response = await this.$request.post('facturation.get-client-options', endObj)
            this.options = response.data.posts.post.map((item) => ({...item, loading: false}))
            this.options.forEach((item) => {
                item.meta = JSON.parse(item.meta)
                item.forfait = null
                if (item.is_applied) {
                    item.forfait = forfaits.find((forfait) => item.keyword == forfait.key)
                }
            })

            this.$forceUpdate()


            this.loading = false
        },

        async addNewOption(option) {
            if (!this.clientId && !this.acceptCondition) {
                this.$toast.error(this.$tt('Vous devez accepter les conditions générales de vente'))
                return
            }

            let endObj = {
                option_id: option.id,
                tarif_fixe: this.remiseType == '1' && this.remiseFixe ? this.remiseFixe : 0,
                pourcentage: this.remiseType == '2' && this.remisePercent ? this.remisePercent : 0,
                meta: {}
            }
            // Licences callcenter
            let metaLicences = this.metaLicences.filter((item) => item.email)
            if (metaLicences && metaLicences.length > 0) {
                // let hasError = null
                // metaLicences.forEach((item) => {
                //     if (!this.$validation.check([
                //         {value: item.email, rules: 'required|email'},
                //     ])) {
                //         hasError = this.$tt('Adresse email incorrect')
                //     }
                // })
                // if (hasError) {
                //     option.loading = false
                //     this.$toast.error(this.$tt('Adresse email incorrect'))
                //     return
                // }
                endObj.meta.licences = metaLicences
            }

            // Conf selfcare
            if (this.selfcareConf && this.selfcareConf != {}) {
                endObj.meta.selfcare = this.selfcareConf
            }

            // Nb Dossier
            if (this.selectedLine.nb_dossier) {
                endObj.meta.nb_dossier = this.selectedLine.nb_dossier
            }
            if (this.clientId) {
                endObj.client_id = this.clientId
            }
            // Station reparation
            if (this.selectedLine.keyword == 'atelier_reparation') {
                endObj.account_identifiant = this.selectedLine.account_identifiant
                endObj.account_email = this.selectedLine.account_email
                if (!this.$validation.check([
                    {value: endObj.account_identifiant, rules: 'required', id: 'inp-account_identifiant'},
                    {value: endObj.account_email, rules: 'required|email', id: 'inp-account_email'},
                ])) {return}

                if (!this.availableIdentifiant) {
                    this.$toast.error(this.$tt('Identifiant invalide'))
                    return
                }
            }
            endObj.meta = JSON.stringify(endObj.meta)
            option.loading = true
            let response = await this.$request.post('facturation.add-option', endObj)
            if (response.data.posts.post == 1) {
                this.$toast.success(this.$tt('Option ajoutée'))
            }
            option.loading = false
            this.$eventHub.$emit('refresh-facture-details')
            this.$modal.hide('modal-alert-add')
            this.getOptions()
            this.acceptCondition = false
            window.parent.postMessage({from: 'modules', type: 'activate', meta: {option: this.selectedLine.keyword}}, "*");
        },

        async removeOption(option) {
            let endObj = {option_id: option.id}
            if (this.clientId) {
                endObj.client_id = this.clientId
            }
            this.loadingModal = true
            let response = await this.$request.post('facturation.delete-option', endObj)
            if (response.data.posts.post == 1) {
                this.$toast.success(this.$tt('Option désactivée'))
                await this.getOptions()
                this.$modal.hide('modal-delete-option')
            }
            window.parent.postMessage({from: 'modules', type: 'delete', meta: {option: this.selectedLine.keyword}}, "*");
            this.loadingModal = false
        },

        getAmount(option) {
            if (option.type == 'connections') {
                return option.value + ' € ' + this.$tt('par liaisons par mois')
            }
            if (option.type == 'users') {
                return option.value + ' € ' + this.$tt('par licence utilisateur mensuel')
            }
            if (option.type == 'fixe') {
                return option.value + ' € ' + this.$tt('mensuel')
            }
            if (option.type == 'pourcentage') {
                return option.value + '%'
            }
        },

        displayEditModal(option) {
            if (option.tarif_fixe) {
                this.remiseType = 1
            } else if (option.remise) {
                this.remiseType = 2
            } else {
                this.remiseType = 0
            }

            if (option.keyword == 'connection') {
                if (option.nb_dossier && option.nb_dossier == '-1') {
                    this.typeLotDsc = '0'
                } else {
                    this.typeLotDsc = '1'
                    if (!option.is_applied) {
                        option.nb_dossier = 300
                    }
                }
            }
            this.remiseFixe = option.tarif_fixe
            this.remisePercent = option.remise
            this.selectedLine = option
            this.$nextTick(() => {
                this.$modal.show('modal-alert-add')
            })
        },

        async updateCallcenterLicences() {
            if (!this.clientId && !this.acceptCondition) {
                this.$toast.error(this.$tt('Vous devez accepter les conditions générales de vente'))
                return
            }

            if (this.metaLicences.length <= 0) {
                this.$toast.error(this.$tt('Vous devez avoir au moins une licence active'))
                return
            }

            let metaLicences = this.metaLicences.filter((item) => item.email)
            let endObj = {
                emails: JSON.stringify(metaLicences)
            }

            if (this.clientId) {
                endObj.client_id = this.clientId
            }
            // const pluck = (arr, key) => arr.map(item => item[key]);
            // const hasDuplicates = (arr) => arr.length !== new Set(arr).size;
            // let onlyEmails = pluck(metaLicences, 'email')
            // if (hasDuplicates(onlyEmails)) {
            //     this.$toast.error(this.$tt('Vous avez configuré plusieurs fois le même compte'))
            //     return
            // }

            this.selectedLine.loading = true
            await this.$request.post('facturation.update-callcenter-licences', endObj)
            this.selectedLine.loading = false
            this.$toast.success(this.$tt('Licences modifiées'))
            this.$modal.hide('modal-edit-callcenter')
            this.getOptions()
            window.parent.postMessage({from: 'modules', type: 'update', meta: {option: this.selectedLine.keyword}}, "*");
        },

        async updateStatsLicences() {
            if (!this.clientId && !this.acceptCondition) {
                this.$toast.error('Vous devez accepter les conditions générales de vente')
                return
            }

            let metaLicences = this.metaLicences.filter((item) => item.email)
            // const pluck = (arr, key) => arr.map(item => item[key]);
            // const hasDuplicates = (arr) => arr.length !== new Set(arr).size;
            // let onlyEmails = pluck(metaLicences, 'email')
            // if (hasDuplicates(onlyEmails)) {
            //     this.$toast.error(this.$tt('Vous avez configuré plusieurs fois le même compte'))
            //     return
            // }

            if (this.metaLicences.length <= 0) {
                this.$toast.error(this.$tt('Vous devez avoir au moins une licence active'))
                return
            }

            this.selectedLine.loading = true
            let endObj = {
                emails: JSON.stringify(metaLicences)
            }

            if (this.clientId) {
                endObj.client_id = this.clientId
            }
            await this.$request.post('facturation.update-stats-licences', endObj)
            this.selectedLine.loading = false
            this.$toast.success(this.$tt('Licences modifiées'))
            this.$modal.hide('modal-edit-stats')
            this.getOptions()
            window.parent.postMessage({from: 'modules', type: 'update', meta: {option: this.selectedLine.keyword}}, "*");
        },

        async endWaitingOption() {
            let endObj = {
                option_id: this.selectedLine.id
            }
            if (this.clientId) {
                endObj.client_id = this.clientId
            }
            this.loadingModal = true
            let response = await this.$request.post('facturation.end-waiting-option', endObj)
            if (response.data.posts.post == 1) {
                await this.getOptions()
                this.$toast.success(this.$tt('Option activée'))
                this.$modal.hide('modal-end-waiting-option')
            }
            this.loadingModal = false
        },

        async reactivateOption() {
            let endObj = {
                option_id: this.selectedLine.id
            }
            if (this.clientId) {
                endObj.client_id = this.clientId
            }

            this.loadingModal = true
            let response = await this.$request.post('facturation.reactivate-option', endObj)
            if (response.data.posts.post == 1) {
                this.getOptions()
                this.$toast.success(this.$tt('Option activée'))
                this.$modal.hide('modal-reactivate')
            }
            this.loadingModal = false

            window.parent.postMessage({from: 'modules', type: 'activate', meta: {option: this.selectedLine.keyword}}, "*");
        },

        async checkAvailableIdentifiant() {
            clearTimeout(this.timerSearchIdentifiant)
            if (this.selectedLine.account_identifiant) {
                this.timerSearchIdentifiant = setTimeout(async () => {
                    this.availableIdentifiant = false
                    this.loadingCheckIdentifiant = true
                    let response = await this.$request.post('user.check-available-identifiant', {
                        identifiant: this.selectedLine.account_identifiant
                    })
                    this.loadingCheckIdentifiant = false
                    this.availableIdentifiant = response.data.posts.post
                }, 700);
            }
        }
    },
    mounted () {
        this.getOptions();
    },
}
</script>

<style lang="scss" scoped>

</style>