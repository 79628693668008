<template>
    <div>
        <app-template>
            <div class="bg-white p-10 rounded shadow">
                <h2 class="text-2xl font-bold">Inviter une station de réparation</h2>
            </div>
        </app-template>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>