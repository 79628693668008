<template>
    <div>
        <modal name="modal-confirm-resend" height="auto" :scrollable="true">
            <div class="p-10">
                <template v-if="selectedLine">
                    <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                        <h2 class="font-bold">
                            Renvoyer le mail
                        </h2>
                        <button @click="$modal.hide('modal-confirm-resend'); selectedLine = null">
                            <i class="fas fa-times text-red-500"></i>
                        </button>
                    </div>
                    <div class="p-4 mb-4 text-sm text-orange-800 rounded-lg bg-orange-50" role="alert">
                        <p>Êtes-vous sûr de vouloir renvoyer ce mail à <b>{{ selectedLine.to }}</b> ?</p>
                    </div>
                    <button :disabled="loaderModal" @click="resendMail" class="bg-emerald-600 hover:bg-emerald-700 duration-200 cursor-pointer text-white px-4 py-2 rounded text-sm">
                        <template v-if="!loaderModal">
                            Confirmer <i class="fas fa-check ml-1"></i>
                        </template>
                        <template v-if="loaderModal">
                            <spinner :size="10" />
                        </template>
                    </button>
                </template>
            </div>
        </modal>

        <modal name="modal-display-mail" height="auto" :scrollable="true">
            <template v-if="selectedLine">
                <div class="p-4">
                    <div class="block mb-1 text-xs text-gray-900">Envoyé à: <span class="font-bold">{{ selectedLine.to }}</span></div>
                    <div class="block mb-1 text-xs text-gray-900">Sujet: <span class="font-bold">{{ selectedLine.subject }}</span></div>
                </div>
    
                <div v-html="selectedLine.content"></div>
            </template>
        </modal>

        <app-template>
            <div class="bg-white p-10 rounded shadow">
                <data-table
                :server="true"
                :filters="[
                    {label: 'Envoyé à', key: 'to', type: 'text', value: null},
                    {label: 'Sujet', key: 'subject', type: 'text', value: null},
                    {label: 'Etiquette', key: 'tag', type: 'select', options: [
                        {label: 'Aucun', value: ''},
                        {label: 'Inscription', value: 'inscription'},
                        {label: 'Ecosystème', value: 'ecosystem'},
                        {label: 'Site vitrine', value: 'site_vitrine'},
                    ]},
                ]"
                :columns="[
                    {label: 'Date', key: 'created_at', type: 'datetime'},
                    {label: 'Envoyé à', key: 'to', type: 'text'},
                    {label: 'Subject', key: 'subject', type: 'text'},
                    {label: 'Etiquette', slot: 'tag', type: 'text'},
                    {label: 'Ouvert', key: 'open', type: 'boolean'},
                ]"
                :request="{
                    name: 'email.list',
                    params: {
                        page: 0,
                        per_page: 50,
                    },
                    apiName: 'api2'
                }"
                >
                    <template v-slot:tag='{line}'>
                        <span
                        v-if="tagConfig[line.tag]"
                        :style="'background: ' + tagConfig[line.tag].color"
                        class="text-white py-1 px-2 text-xs rounded"
                        >
                            {{ tagConfig[line.tag].label }}
                        </span>
                    </template>
                    <template v-slot:actions='{line}'>
                        <div class="flex items-center">
                            <button @click="selectedLine = line; $modal.show('modal-confirm-resend')" class="flex items-center mr-1 text-xs bg-slate-200 hover:bg-slate-300 duration-200 cursor-pointer font-light px-3 py-1.5 rounded">
                                <i class="mr-1 fa-solid fa-rotate-right"></i> Renvoyer
                            </button>
                            <button @click="selectedLine = line; $modal.show('modal-display-mail')" class="mr-1 text-xs bg-orange-500 hover:bg-orange-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white">
                                <i class="fas fa-eye"></i>
                            </button>
                        </div>
                    </template>
                </data-table>
            </div>
        </app-template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedLine: null,
            loaderModal: false
        }
    },
    computed: {
        tagConfig() {
            return {
                'site_vitrine': {label: 'Site vitrine', color: '#8a5aa1'},
                'ecosystem': {label: 'Ecosystème', color: '#49915e'},
                'inscription': {label: 'Inscription', color: '#356073'},
                'procedure': {label: 'Procédure', color: '#89a2cc'},
            }
        }
    },
    methods: {
        async resendMail() {
            this.loaderModal = true
            await this.$request.post('email.resend', {
                line_id: this.selectedLine.id
            })
            this.$modal.hide('modal-confirm-resend')
            this.$toast.success('Email envoyé')
            this.loaderModal = false
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
