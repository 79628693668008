<template>
    <div>
        <modal name="modal-detail-amount" height="auto" :scrollable="true">
            <div v-if="selectedLine" class="p-10" style="z-index: 999 !important;">
                <div class="text-right mb-4 border-b pb-4 flex items-center justify-between">
                    <h2 class="font-bold text-lg">Détails du montant</h2>
                    <button @click="$modal.hide('modal-detail-amount')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <admin-client-price-details :details="selectedLine.details.details" :total-saved="selectedLine.details.total_saved" />
            </div>
        </modal>
        <div class="flex items-center justify-center" v-if="loading">
            <spinner :size="10" />
        </div>
        <template v-if="items && !loading">
            <table class="w-full text-sm text-left text-gray-500 border">
                <thead class="text-xs text-gray-700 uppercase bg-gray-200">
                    <tr>
                        <th scope="col" class="px-6 py-3">
                            Date
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Montant
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, itemIndex) in items" :key="'item-' + itemIndex" class="bg-white border-b hover:bg-gray-100 duration-200 cursor-pointer">
                        <td scope="row" class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap">
                            {{ item.month + '/' + item.year }}
                        </td>
                        <td scope="row" class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap">
                            {{ item.amount }} €
                        </td>
                        <td scope="row" class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap">
                            <button @click="displayDetailsModal(item)" class="text-xs bg-slate-500 hover:bg-slate-600 duration-200 cursor-pointer font-light px-2 py-1 rounded text-white">
                                Details
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </template>
    </div>
</template>

<script>
import AdminClientPriceDetails from './AdminClientPriceDetails.vue'

export default {
    props: {
        clientId: {},
    },
    components: {
        AdminClientPriceDetails,
    },

    data() {
        return {
            items: null,
            loading: false,
            selectedLine: null,
        }
    },
    methods: {
        async getHistory() {
            this.loading = true
            let endObj = {}
            if (this.clientId) {
                endObj.client_id = this.clientId
            }
            let response = await this.$request.post('facturation.get-history', endObj)
            this.items = response.data.posts.post
            this.loading = false
        },

        displayDetailsModal(item) {
            this.selectedLine = null
            let line = {...item}
            line.details = JSON.parse(line.details)
            this.selectedLine = line
            this.$modal.show('modal-detail-amount')
        }
    },
    mounted () {
        this.getHistory();
    },
}
</script>

<style lang="scss" scoped>

</style>