<template>
    <div>
        <div class="py-4" v-if="loaderGetData">
            <div class="flex justify-center">
                <spinner :size="12" />
            </div>
            <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
        </div>
        <template v-if="!loaderGetData && model">
            <input-rule v-model="model['actif']" :display-value="!edit" type="pcheck" class="mb-2" :label-inline="false" label="Actif" id="inp-actif" />
            <div class="grid lg:grid-cols-2 gap-4">
                <div>
                    <input-rule v-model="model['Marque']" :display-value="!edit" type="text" class="mb-2" :label-inline="false" label="Marque" id="inp-marque" />
                    <input-rule v-model="model['Modele']" :display-value="!edit" type="text" class="mb-2" :label-inline="false" label="Modèle" id="inp-modele" />
                    <input-rule v-model="model['Libelle']" :display-value="!edit" type="text" class="mb-2" :label-inline="false" label="Libelle" id="inp-libelle" />
                </div>
                <div>
                    <input-rule v-model="model['id_grossiste']" :display-value="!edit" type="text" class="mb-2" :label-inline="false" label="Grossite" id="inp-grossiste" />
                    <input-rule v-model="model['img']" :display-value="!edit" type="file" class="mb-2" :label-inline="false" label="Numéro dossier" id="inp-img" />
                </div>
            </div>
            <div class="text-center">
                <button :disabled="loaderEditAdd" class="px-4 py-2 rounded my-1 bg-green-200 hover:bg-green-300 duration-200 text-sm" @click="editAdd">
                    <template v-if="!loaderEditAdd">
                        <template v-if="model?.id">Modifier <i class="fas fa-pen ml-1"></i></template>
                        <template v-if="!model?.id">Enregistrer <i class="fas fa-plus ml-1"></i></template>
                    </template>
                    <template v-else>
                        <spinner :size="10" />
                    </template>
                </button>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        id: {},
        edit: {
            type: Boolean,
        },
        dataSrc: {},
    },

    data() {
        return {
            model: null,
            loaderGetData: false,
            loaderEditAdd: false,
            loadingModal: false,
        }
    },

    methods: {
        async getData(id = null) {
            this.loaderGetData = true
            if (id || this.id) {
                let response = await this.$request.post('appareil.view', {
                    line_id: id || this.id
                })
                if (response.data.posts.post) {
                    this.model = response.data.posts.post
                }
            } else {
                this.model = {}
            }
            this.loaderGetData = false
        },

        async editAdd() {
            if (!this.$validation.check([
                // {value: this.model.id_entite, rules: 'required'},
            ])) {return}

            this.loaderEditAdd = true
            let endObj = {...this.model}
            if (endObj.id) {
                endObj.line_id = endObj.id
                delete endObj.id
            }
            for (let key in endObj) {
                if (endObj[key] === null || endObj[key] === undefined) {
                    delete endObj[key]
                }
            }
            let response = await this.$request.post('user.edit-add', endObj)
            if (response.data.posts.post) {
                this.$emit('update', response.data.posts.post)
                this.getData(response.data.posts.post.id)
                this.$toast.success((endObj.id ? 'Appareil modifié' : 'Appareil enregistré'), {position: "bottom-right"})

            } else {
                this.$toast.error("Erreur lors de l'enregistrement")
            }
            this.loaderEditAdd = false
        },
    },

    mounted () {
        this.getData();

        if (this.dataSrc) {
            this.model = this.dataSrc
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
