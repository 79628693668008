<template>
    <div>
        <div class="bg-slate-50 p-4 rounded text-xs font-light text-center" v-if="tchatUnable">
            Tchat indisponible
        </div>
        <div v-else>
            <!-- Messages -->
            <div v-for="(message, messageIndex) in messages" :key="'chat-msg-' + messageIndex">
                <div class="mb-2 w-full md:w-[75%] flex items-center" v-if="message.bot && message.show">
                    <div
                    class="mr-3 bg-gray-100 border rounded-full hidden md:block"
                    :style="'background-image: url(' + avatar + ')'"
                    style="
                        background-size: cover;
                        background-position: center center;
                        min-width: 40px;
                        max-width: 40px;
                        height: 40px;
                    "
                    ></div>
                    <div v-if="message.typing" class="px-4">
                        <div class="typing-loader"></div>
                    </div>
                    <div v-if="!message.typing" class="tchat-message border border p-4 rounded">
                        <span v-html="message.label"></span>
                    </div>
                </div>
                <div v-if="!message.bot" class="tchat-message border p-4 rounded mb-2 ml-[25%] w-[75%] bg-blue-100 hover:bg-blue-200 cursor-pointer duration-100">
                    <span v-html="message.label"></span>
                </div>
            </div>
            <!-- Responses -->
            <template v-if="canDisplayReponses">
                <div v-for="(reponse, reponseIndex) in reponses" :key="'chat-reponse-' + reponseIndex">
                    <div @click="selectReponse(reponse)" class="tchat-message border p-4 rounded mb-2 ml-[25%] w-[75%] bg-blue-100 hover:bg-blue-200 cursor-pointer duration-100">
                        {{ reponse.label }}
                    </div>
                </div>
            </template>
            <!-- End form -->
            <!-- <div class="tchat-message border border p-4 rounded" v-if="solved">
                <h2 class="font-bold">Cette solution vous a t-elle aidée ?</h2>
                <div class="flex items-center gap-1 mt-1">
                    <button class="bg-green-200 hover:bg-green-300 duration-200 cursor-pointer px-4 py-1 rounded">Oui</button>
                    <button class="bg-red-200 hover:bg-red-300 duration-200 cursor-pointer px-4 py-1 rounded">Non</button>
                </div>
            </div> -->
            <!-- Reset tchat -->
            <div v-if="!solved || infinite" class="text-center mt-5">
                <button class="bg-slate-50 px-6 py-2 rounded border text-xs" @click="resetForm">
                    <i class="mr-1 fas fa-redo hover:opacity-100"></i> Recommencer le tchat
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'bdc-tchat',
    
    props: {
        liaison: {},
        canResetForm: {
            default: false
        },
    },

    data() {
        return {
            messages: [],
            reponses: [],

            liaisonId: null,
            confService: false,
            infinite: false,
            tchatKey: null,

            solved: null,
            tchatUnable: false,

            avatar: 'https://disticloudfiles.net/img/savinfo/default/avatar-chat-bot.png'
        }
    },

    computed: {
        canDisplayReponses() {
            let unshowMessages = this.messages.find((msg) => msg.bot == 1 && (msg.show == false || msg.typing == true))
            return unshowMessages ? false : true
        }
    },

    methods: {
        async init() {
            this.loading = true

            let history = null
            if (this.tchatKey) {
                let response = await this.$request.post('tchat.get-history', {
                    key: this.tchatKey,
                })
                if (response.data.posts.post) {
                    history = response.data.posts.post
                    history.json = JSON.parse(history.json)
                }
            }
            if (history) {
                let msgs = [...history.json]
                let lastResponse = msgs.pop()
                this.messages = msgs
                this.selectReponse(lastResponse)
            } else {
                this.getFirstMessage()
            }

            this.loading = false
        },

        resetForm() {
            this.messages = []
            window.parent.postMessage({from: 'bdc-tchat', type: 'reset', meta: null}, "*");
            this.$emit('update', this.$options.name)
            this.$emit('reset')
            this.getFirstMessage()
        },

        async getFirstMessage() {
            let endObj = {
                action: 'bdc',
                id_bdc: this.liaisonId,
                id: 777,
                token: 'MlNykMcT78moIEkwtti4qWP0rwYNNCG'
            }
            if (this.confService == 'districloud_preprod_tchat') {
                endObj.domain_name = this.$route.query.domain_name || ''
            }
            const response = await this.$request.post('getpattern', endObj, this.confService)
            if (response.data.posts.post) {   
                this.getMessage(response.data.posts.post.id)
            } else {
                this.tchatUnable = true
                window.parent.postMessage({from: 'bdc-tchat', type: 'error', meta: {valid: false}}, "*");
            }
        },

        displayLastBotMessage() {
            let waitingMessages = this.messages.find((msg) => msg.bot == 1 && msg.show == 0 && msg.typing == false)

            if (waitingMessages) {
                waitingMessages.show = true
                waitingMessages.typing = true
                setTimeout(() => {
                    // Events
                    this.$emit('new-message', {type: 'message', value: waitingMessages})
                    window.parent.postMessage({from: 'bdc-tchat', type: 'bot-message', meta: null}, "*");

                    waitingMessages.typing = false
                    if (this.messages.find((msg) => msg.bot == 1 && msg.show == 0 && msg.typing == false)) {
                        this.displayLastBotMessage()
                    }
                    this.scrollDown()

                    // End tchat
                    if (waitingMessages.resolu == '1') {
                        this.solved = true
                        this.endForm(true, waitingMessages)
                    }
                    if (waitingMessages.resolu == '2') {
                        this.endForm(false, waitingMessages)
                    }
                }, 900)
            }
        },

        addBotMessage(message) {
            this.messages.push({...message, bot: 1, typing: false, show: false})
            // Si le bot n'ecrit pas
            if (!this.messages.find((msg) => msg.bot == 1 && msg.typing == 1)) {
                this.displayLastBotMessage()
            }
        },

        async getMessage(msgId) {
            if (msgId && msgId != '0') {
                let endObj = {
                    action: 'bdc',
                    id_question: msgId,
                    id_conso: this.$crypto.decrypt(localStorage.getItem('client_id')),
                    id_app: 28,
                    id: 777,
                    token: 'MlNykMcT78moIEkwtti4qWP0rwYNNCG',
                }
                if (this.confService == 'districloud_preprod_tchat') {
                    endObj.domain_name = this.$route.query.domain_name || ''
                }
                let response = await this.$request.post('getbdcmessages', endObj, this.confService)
                response = response.data.posts.post
                response.messages.forEach((msg) => {
                    this.addBotMessage(msg)
                })                
                this.reponses = response.reponses.map((msg) => ({...msg, bot: 0}))
                this.scrollDown()
            }
        },

        async selectReponse(reponse) {
            // Events
            this.$emit('new-message', {type: 'response', value: reponse})
            window.parent.postMessage({from: 'bdc-tchat', type: 'user-response', meta: null}, "*");
            this.scrollDown()

            this.reponses = []
            this.messages.push(reponse)
            if (this.tchatKey) {
                this.$request.post('tchat.edit-add-history', {
                    key: this.tchatKey,
                    json: JSON.stringify(this.messages),
                })
            }
            if (reponse.question_next && reponse.question_next != '0') {
                this.getMessage(reponse.question_next)
            } else {
                if (reponse.resolu == '1') {
                    this.solved = true
                    this.endForm(true, reponse)
                }
                if (reponse.resolu == '2') {
                    this.endForm(false, reponse)
                }
            }
        },

        async endForm(solutionFound, response) {
            let endHistory = []
            this.messages.forEach((msg, index) => {
                endHistory.push({
                    order: index,
                    type: msg.bot ? 'message' : 'reponse',
                    id: msg.id,
                    label: msg.label
                })
            })
            setTimeout(() => {
                this.$emit('end-form', {response: response, value: solutionFound})
                window.parent.postMessage({from: 'bdc-tchat', type: 'end-form', meta: {valid: solutionFound, history: endHistory}}, "*");
            }, 900)
        },

        end(callbackData) {
            let callback = {
                next: callbackData.next,
                messages: this.messages
            }
            this.$formTool.addFormData(this.$options.name, callback)
            this.nextBlock = callbackData.next
        },

        scrollDown() {
            window.setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight)
            }, 1000)
        }
    },

    mounted() {
        if (!this.$route.query.liaison_id && !this.liaison) {
            console.error('Tchat widget: "liaison_id" parameter is required')            
            return
        }
        this.liaisonId = this.$route.query.liaison_id || this.liaison
        this.confService = this.$route.query.preprod ? 'districloud_preprod_tchat' : 'districloud_preprod'
        this.infinite = this.$route.query.infinite || this.canResetForm ? true : false
        this.tchatKey = this.$route.query.key

        this.init()
        window.parent.postMessage({from: 'bdc-tchat', type: 'loaded', meta: null}, "*");
    }
}
</script>

<style>
.typing-loader {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation: loading 1s linear infinite alternate;
}

@keyframes loading {
 0% {
  background-color: rgba(000, 000, 000, 1);
  box-shadow: 
		12px 0px 0px 0px rgba(000, 000, 000, 0.2), 
		24px 0px 0px 0px rgba(000, 000, 000, 0.2);
 }
 25% {
  background-color: rgba(000, 000, 000, 0.4);
  box-shadow: 
		12px 0px 0px 0px rgba(000, 000, 000, 2), 
		24px 0px 0px 0px rgba(000, 000, 000, 0.2);
 }
 75% {
  background-color: rgba(000, 000, 000, 0.4);
  box-shadow: 
		12px 0px 0px 0px rgba(000, 000, 000, 0.2), 
		24px 0px 0px 0px rgba(000, 000, 000, 1);
 }
}

.file-link, .message-link {
    color: #1f73bb;
    text-decoration: underline;
}

.video-youtube {
    border-radius: 0.25rem;
}
</style>
