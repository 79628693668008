var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal',{attrs:{"name":"modal-cancel-avoir","height":"auto","scrollable":true}},[_c('div',{staticClass:"p-10"},[(_vm.selectedAvoir)?[_c('div',{staticClass:"flex items-center justify-between mb-4 pb-4 border-b text-2xl"},[_c('h2',{staticClass:"font-bold"},[_vm._v(" Annuler un avoir ")]),_c('button',{on:{"click":function($event){_vm.$modal.hide('modal-cancel-avoir'); _vm.motifCancel = null}}},[_c('i',{staticClass:"fas fa-times text-red-500"})])]),_c('div',{staticClass:"p-4 mb-4 text-sm text-orange-800 rounded-lg bg-orange-50",attrs:{"role":"alert"}},[_c('p',[_vm._v("Êtes-vous sûr de vouloir supprimer l'avoir "),_c('b',[_vm._v(_vm._s(_vm.selectedAvoir.num_doc))]),_vm._v(" d'un montant de "),_c('b',[_vm._v(_vm._s(_vm.selectedAvoir.montant_ht)+" € "),_c('small',{staticClass:"text-xs font-light"},[_vm._v("(ttc)")])]),_vm._v(" ?")])]),_c('input-rule',{attrs:{"label":"Motif d'annulation","type":"textarea","id":"motif-cancel","label-inline":false},model:{value:(_vm.motifCancel),callback:function ($$v) {_vm.motifCancel=$$v},expression:"motifCancel"}}),_c('button',{staticClass:"bg-emerald-600 text-white px-4 py-2 rounded mt-2",attrs:{"disabled":_vm.loaderModal},on:{"click":_vm.cancelAvoir}},[(!_vm.loaderModal)?[_vm._v(" Confirmer "),_c('i',{staticClass:"fas fa-check ml-1"})]:_vm._e(),(_vm.loaderModal)?[_c('spinner',{attrs:{"size":10}})]:_vm._e()],2)]:_vm._e()],2)]),_c('div',{staticClass:"flex justify-center"},[(_vm.$parent.$parent.getAvoirListLoading)?_c('spinner',{staticClass:"m-5",attrs:{"size":14}}):_vm._e()],1),(!_vm.$parent.$parent.getAvoirListLoading)?_c('div',{staticClass:"relative overflow-x-auto"},[_c('data-table',{attrs:{"server":true,"columns":[
            {label: 'Créé le', key: 'date_doc', type: 'date'},
            {label: 'Numéro Avoir', key: 'num_doc', type: 'text'},
            {label: 'HT', key: 'montant_ht', type: 'text'},
            {label: 'TVA', key: 'montant_tva', type: 'text'},
            {label: 'TTC', key: 'montant_ttc', type: 'text'},
        ],"request":{
            name: 'A5141listavoir',
            params: {
                per_page: 30,
                id_user: _vm.userId,
                id_centrale_ha: _vm.selectedSign
            },
            apiName: 'districloud_no_action'
        }},scopedSlots:_vm._u([{key:"actions",fn:function({line}){return [_c('a',{staticClass:"text-emerald-600 hover:text-emerald-700 text-sm duration-200 cursor-pointer text-lg",attrs:{"href":line.url,"target":"_blank"}},[_c('i',{staticClass:"fas fa-circle-down"})])]}}],null,false,1410757106)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }