<template>
    <div>
        <div class="relative">
            <div class="text-sm rounded-0 grow w-full focus:outline-none focus:border-gray-400">
                <div class="flex">
                    <div v-if="canSearch" class="grow">
                        <input
                        :disabled="disabled"
                        :placeholder="placeholder"
                        class="p-1.5 w-full focus:outline-none"
                        :class="(labelInline ? 'border-0 border-b' : 'border border-r-0 rounded-lg rounded-r-none bg-gray-50') + ' ' + (hasError ? 'border-2 border-red-500 text-red-500' : 'border border-gray-300 text-gray-900') + ' ' + (disabled ? 'cursor-not-allowed' : '')"
                        type="text"
                        v-model="search"
                        @input="getLines"
                        @focusin="goFocus"
                        :ref="uniqueId"
                        />
                    </div>
                    <button class="p-1.5 bg-slate-50 hover:bg-red-100 duration-200 focus:outline-none rounded-r-lg border border-l-0 px-3" :class="(hasError ? 'border-2 border-red-500 text-red-500' : 'border border-gray-300 text-gray-900')" @click="resetInp"><i class="fas fa-times"></i></button>
                </div>
                <div v-if="!canSearch" class="p-1.5 w-full text-ellipsis overflow-hidden" :class="(labelInline ? 'border-0 border-b' : 'border rounded-lg bg-gray-50')" @click="displayInput">
                    <template v-if="selectedLine">
                        {{ selectedLine[label] }}
                    </template>
                </div>
            </div>
            <div
            v-if="displayResults"
            class="shadow absolute left-0 right-0 bg-white border max-h-[150px] overflow-y-auto overflow-x-hidden"
            style="z-index: 1000 !important"
            v-closable="{
                exclude: [uniqueId],
                handler: 'unFocus'
            }"
            >
                <div class="p-2" v-if="loaderSearch">
                    <spinner :size="10" />
                </div>
                <div v-if="!loaderSearch">
                    <div
                    v-for="(item, itemIndex) in items"
                    :key="uniqueId + '-item-' + itemIndex"
                    class="hover:bg-slate-100 duration-200 cursor-pointer font-light text-sm border-b p-1"
                    @click="selectLine(item)"
                    >
                        <div :class="uppercase ? 'uppercase' : ''">{{ displayLabel(item, label) }}</div>
                        <div class="font-light" style="font-size: 11px;" v-if="label2">{{ displayLabel(item, label2) }}</div>
                    </div>
                    <div class="text-xs font-light p-1" v-if="items.length <= 0">Aucun résultat</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        id: {},
        api: {},
        label: {},
        label2: {},
        keyTrack: {},
        placeholder: {},
        min: {},
        labelInline: {default: true},
        init: {default: true},
        value: {},
        uppercase: {},
        disabled: {default: false},
        defaultValue: {},
        hasError: {default: false}
    },
    watch: {
        selectedLine(value) {
            let endValue = null
            if (value) {
                endValue = this.keyTrack ? this.selectedLine[this.keyTrack] : this.selectedLine
            }
            this.$emit('input', endValue)
        },
        search(value) {
            this.$emit('inp', value)
        }
    },
    data() {
        return {
            search: null,
            displayResults: false,
            items: [],

            canSearch: true,
            selectedLine: null,

            timerSearch: null,
            loaderSearch: false
        }
    },
    computed: {
        uniqueId() {
            return 'sav-input-' + this._uid
        },
    },
    methods: {
        goFocus() {
            if (this.min && (!this.search || this.search.length < this.min)) {
                this.displayResults = false
            } else {
                this.displayResults = true
            }
        },
        unFocus() {
            if (this.displayResults) {
                this.displayResults = false
                if (!this.selectLine || (this.selectedLine && this.selectedLine[this.label] != this.search)) {
                    this.search = null
                    this.getLines()
                    this.selectedLine = null
                }
            }
        },
        selectLine(line) {
            this.selectedLine = line
            if (this.selectedLine) {
                this.search = this.selectedLine[this.label]
            } else {
                this.search = null
            }
            this.displayResults = false
            this.canSearch = true
        },
        resetInp() {
            this.selectedLine = null
            this.search = null
            // this.displayResults = false
            // this.canSearch = false
        },
        getLines() {
            window.clearTimeout(this.timerSearch)
            if (this.min && (!this.search || this.search.length < this.min)) {
                this.displayResults = false
                return
            } else {
                this.displayResults = true
            }
            this.loaderSearch = true
            this.timerSearch = window.setTimeout(async () => {
                this.loaderSearch = true

                let endObj = {
                    key: this.label
                }
                if (this.search) {
                    endObj.search = this.search
                }
                if (this.api.options?.limit) {
                    endObj.limit = this.api.options?.limit
                }
                if (this.api.options?.orderBy) {
                    endObj.orderBy = this.api.options?.orderBy
                }
                if (this.api.options?.relations) {
                    endObj.relations = this.api.options?.relations
                }
                if (this.api.meta) {
                    endObj.meta = JSON.stringify(this.api.meta)
                }
                let response = await this.$request.post(this.api.action, endObj, this.api.apiName)
                this.items = response.data.posts.post
                this.loaderSearch = false

                if (this.value) {
                    let line = null
                    if (this.keyTrack) {
                        line = this.items.find(item => item[this.keyTrack] == this.value)
                    } else {
                        line = this.items.find(item => item == this.value)
                    }
                    if (line) {
                        this.selectLine(line)
                    }
                }
            }, 300)
        },
        displayInput() {
            this.canSearch = true
            this.displayResults = true
            setTimeout(() => {
                this.$refs[this.uniqueId].focus()
            }, 1)
        },

        displayLabel(item, label) {
            let keys = label.split('.')
            let endLabel = item
            keys.forEach((key) => {
                if (endLabel && endLabel[key]) {
                    endLabel = endLabel[key]
                } else {
                    endLabel = null
                }
            })
            return endLabel
        },

        async getDefaultValue() {
            let response = await this.$request.post(this.api.action, {
                default_search: this.defaultValue,
                key: this.keyTrack
            }, this.api.apiName)
            this.selectLine(response.data.posts.post)
        }
    },
    mounted () {
        if (this.defaultValue) {
            this.getDefaultValue()
        }
        if (this.init) {
            this.getLines()
        }

        this.$eventHub.$on('clear-' + this.id, () => {
            this.items = []
            this.selectedLine = null
            this.search = null
        })
    },
}
</script>
