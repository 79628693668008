<template>
    <div>
        <div class="py-4" v-if="loaderGetData">
            <div class="flex justify-center">
                <spinner :size="12" />
            </div>
            <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
        </div>
        <template v-if="!loaderGetData && model">
            <div class="grid lg:grid-cols-2 gap-4">
                <div>
                    <div class="mb-4 pb-4 border-2 rounded p-2">
                        <input-rule v-if="admin" v-model="model.actif" :display-value="!edit" type="pcheck" label="Actif" class="mb-1" />
                        <input-rule v-if="admin" v-model="model.ecommerce" :display-value="!edit" type="pcheck" label="Ecommerce" class="mb-1" />
                        <input-rule v-if="admin" v-model="model.is_grossiste" :display-value="!edit" type="pcheck" label="Grossiste" class="mb-1" />
                        <div class="flex items-center gap-2 mb-2">
                            <input-rule class="grow" v-model="model.centrale_ha" :display-value="!edit" :disabled="notEditName" type="text" label="Nom" id="inp-nom" />
                            <a v-if="admin" :href="googleLink" target="_blank" class="bg-purple-200 hover:bg-purple-300 cursor-pointer duration-200 text-xs p-1 px-2 rounded-full"><i class="fas fa-search"></i></a>
                        </div>
                        <input-rule v-model="model.nom_enseigne" :display-value="!edit" type="text" label="Nom à afficher" id="inp-nom-enseigne" />
                    </div>
                    <div class="mb-4 pb-4 border-2 rounded p-2">
                        <input-rule v-model="form.adresse" :display-value="!edit" type="address2" :options="{defaultValue: model, country: model.code_pays}" label="Adresse" id="inp-address" />
                    </div>
                    <div class="mb-4 pb-4 border-2 rounded p-2">
                        <input-rule v-model="model.siret" :display-value="!edit" type="text" label="SIRET" class="mb-2" />
                        <input-rule v-model="model.numintra" :display-value="!edit" type="text" label="Numéro intra-communautaire" />
                    </div>
                </div>
                <div>
                    <div class="mb-4 pb-4 border-2 rounded p-2">
                        <input-rule v-model="model.tel" :display-value="!edit" type="text" label="Téléphone" class="mb-2" />
                        <input-rule v-model="model.fax" :display-value="!edit" type="text" label="Fax" class="mb-2" />
                        <input-rule v-model="model.mail" :display-value="!edit" type="text" label="Mail" class="mb-2" />
                    </div>
                    <div class="mb-4 pb-4 border-2 rounded p-2">
                        <div class="flex items-center justify-between">
                            <input-rule v-model="model.logo" :display-value="!edit" type="file" label="Logo" />
                            <img v-if="model.logo" class="h-[45px]" :src="model.logo.url ? model.logo.url : model.logo" />
                        </div>
                    </div>
                    <!-- <div class="mb-4 pb-4 border-2 rounded">
                        <label class="hidden rounded-t-lg text-xs text-center p-2 font-bold border-b bg-slate-100 border-slate-200 block"><i class="fas fa-users mr-0.5"></i> Identifiants</label>
                        <div class="p-2">
                            <label class="text-sm font-semibold border-b pb-3 block"><i class="fas fa-users mr-0.5"></i> Liste des identifiants</label>
                            <div class="max-h-[180px] overflow-y-auto">
                                <div
                                v-for="(user, userIndex) in model.users"
                                :key="'user-' + userIndex"
                                class="text-sm py-1"
                                :class="userIndex < (model.users.length - 1) ? 'border-b' : ''"
                                >
                                    {{ user.email }}
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div v-if="edit" class="text-center">
                <button :disabled="loaderEditAdd" class="px-4 py-2 rounded my-1 bg-green-200 hover:bg-green-300 duration-200 text-sm" @click="editAdd">
                    <template v-if="!loaderEditAdd">
                        <template v-if="model.id">Modifier <i class="fas fa-pen ml-1"></i></template>
                        <template v-if="!model.id">Enregistrer <i class="fas fa-plus ml-1"></i></template>
                    </template>
                    <template v-else>
                        <spinner :size="10" />
                    </template>
                </button>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        id: {},
        edit: {
            type: Boolean,
        },
        dataSrc: {},
        admin: {default: true},
        notEditName: {default: false}
    },

    watch: {
        id: function() {
            this.getData()
        }
    },

    data() {
        return {
            model: null,
            loaderGetData: false,
            loaderEditAdd: false,
            
            form: {
                adresse: null,
            }
        }
    },

    computed: {
        googleLink() {
            if (this.model && this.model.centrale_ha) {
                return 'https://www.google.com/search?q=' + (this.model.centrale_ha.replace(' ', '+')) + '+societe.com'
            }
            return null
        }
    },

    methods: {
        async getData(id = null) {
            if (id || this.id) {
                this.loaderGetData = true
                let response = await this.$request.post('centrale.view', {
                    line_id: id || this.id
                })
                if (response.data.posts.post) {
                    this.model = response.data.posts.post
                }
                this.loaderGetData = false
            }
        },

        async editAdd() {
            if (!this.$validation.check([
                {value: this.model.centrale_ha, rules: 'required', id: 'inp-nom'},
            ])) {return}

            this.loaderEditAdd = true
            let endObj = {...this.model}
            if (endObj.id) {
                endObj.line_id = endObj.id
                delete endObj.id
            }
            if (endObj.logo && endObj.logo.url) {
                endObj.logo = endObj.logo.url
            }
            // Set address
            if (this.form.adresse) {
                endObj.adresse1 = this.form.adresse.adresse1
                endObj.adresse2 = this.form.adresse.adresse2
                endObj.cp = this.form.adresse.cp
                endObj.ville = this.form.adresse.ville
                endObj.pays = this.form.adresse.pays
                endObj.longitude = this.form.adresse.longitude
                endObj.latitude = this.form.adresse.latitude
            }

            for (let key in endObj) {
                if (endObj[key] === null || endObj[key] === undefined) {
                    delete endObj[key]
                }
            }

            let response = await this.$request.post('centrale.edit-add', endObj)
            if (response.data.posts.post) {
                this.$emit('update', response.data.posts.post)
                this.getData(response.data.posts.post.id)
                this.$toast.success((endObj.id ? 'Enseigne modifié' : 'Enseigne enregistré'), {position: "bottom-right"})
            } else {
                this.$toast.error("Erreur lors de l'enregistrement")
            }
            this.loaderEditAdd = false
        }
    },

    mounted () {
        this.getData()
        if (this.dataSrc) {
            this.model = this.dataSrc
            if (this.model && this.model.adresse1) {
                this.form.adresse = this.model.adresse1 + ' ' + (this.model.adresse2 || '') + ' ' + this.model.ville + ' ' + this.model.cp
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
