import ApiService from '@/services/api.service'

// Get url params
let urlString = window.location.href
let paramString = urlString.split('?')[1]
let queryString = new URLSearchParams(paramString)
let endObj = {
    lang: null,
}

for (let pair of queryString.entries()) {
    if (pair[0] == 'lang') {
        endObj.lang = pair[1]
    }
}


let currentLang = localStorage.getItem('lang') || 'fr'
if (endObj.lang) {
    currentLang = endObj.lang
}

let langs = null

async function init() {
    let response = await ApiService.post('traduction.list', {
        key: 'distri2',
        lang: currentLang,
    })
    langs = response.data.posts.post
}

async function addText(keyword) {
    await ApiService.post('traduction.add-text', {
        key: 'distri2',
        content: keyword,
        lang: currentLang,
    })
}

function getText(keyword)
{
    if (langs) {
        if (!langs[keyword]) {
            langs[keyword] = keyword
            addText(keyword)
        }
        return langs[keyword]
    }
    return null
}

export default {
    init,
    getText
}
