<template>
    <div>
        <template v-if="liaisons && liaisons.length > 0">
            <bdc-tchat
            @new-message="simulationNewMessage"
            @reset="resetSimulation"
            @end-form="endTchat"
            :liaison="liaisons[0].id_liaison"
            :can-reset-form="true"
            />
        </template>
    </div>
</template>

<script>
import BdcTchat from './../BdcTchat.vue'

export default {
    components: {
        BdcTchat,
    },

    props: {
        pattern: {required: true},
    },

    data() {
        return {
            selectedCateg: null,
            liaisons: [],
        }
    },

    methods: {
        simulationNewMessage(event) {
            let nodeId = event.type == 'message' ? 'msg' : 'rep'
            nodeId += '-' + event.value.id

            if (this.$parent.nodes[nodeId]) {
                this.$nextTick(() => {
                    let nodeEl = document.querySelector('[id="' + nodeId + '"]')
                    if (nodeEl) {
                        let rectCard = nodeEl.querySelector('foreignObject')
                        rectCard.setAttribute('style', 'background-color: #cbf59f')
                    }
                })
            }
        },

        resetSimulation() {
            for (let key in this.$parent.nodes) {
                let nodeEl = document.querySelector('[id="' + key + '"]')
                if (nodeEl) {
                    let rectCard = nodeEl.querySelector('foreignObject')
                    rectCard.setAttribute('style', 'background-color: #fff')
                }
            }
        },

        endTchat(value) {
            let msgKeyword = value.response.bot == 1 ? 'msg-' : 'rep-'
            let node = this.$parent.nodes[msgKeyword + value.response.id]
            if (!node) {
                return
            }
            node = node.portsOut
            let endKey = Object.keys(node)[0]

            let nodeEl = document.querySelector('[id="' + endKey + '"]')
            if (nodeEl) {
                let rectCard = nodeEl.querySelector('foreignObject')
                rectCard.setAttribute('style', 'background-color: #cbf59f')
            }
        },

        async getLiaisons() {
            let response = await this.$request.post('getpatternbyid', {
                action: 'bdc',
                id_pattern: this.pattern.id_pattern
            }, 'districloud_preprod')

            console.log(response.data);
            this.liaisons = response.data.posts.post
        },
    },
    mounted () {
        this.getLiaisons();
    },
}
</script>

<style lang="scss" scoped>

</style>
