import myaxiosUpload from './axiosUpload.service'

function convertObjToFormData(obj)
{
    var formData = new FormData();

    for (var key in obj) {
        formData.append(key, obj[key]);
    }

    formData.append('id', process.env.VUE_APP_API_FILE_ID)
    formData.append('token', process.env.VUE_APP_API_FILE_TOKEN)

    return formData
}

const ApiUploadService = {
    post: async function (obj) {
        return await myaxiosUpload.post('', convertObjToFormData(obj))
    }
}
export default ApiUploadService
