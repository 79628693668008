<template>
    <div>
        <!-- Modal add -->
        <modal name="modal-edit-add-article" height="auto" width="50%" :scrollable="true">
            <div class="p-10">
                <template v-if="selectedArticle">
                    <h2 class="font-bold text-3xl mb-5">
                        Rédaction article
                    </h2>
                    <input-rule v-model="selectedArticle.display" label="Article en ligne" type="pcheck" :label-inline="false" />
                    <input-rule v-model="selectedArticle.title" label="Titre" type="text" :label-inline="false" class="mt-4" />
                    <input-rule v-model="selectedArticle.img" label="Image (optionnel)" type="text" :label-inline="false" class="mt-4" />
                    <input-rule v-model="selectedArticle.summary" label="Résumé" type="textarea" :label-inline="false" class="mt-4" />
                    <input-rule v-model="selectedArticle.content" label="Contenu" type="textarea" :label-inline="false" class="mt-4" />
                    <button :disabled="saveLoading" @click="save" class="bg-green-500 text-white px-4 py-2 rounded mt-4">
                        <template v-if="!saveLoading">
                            Enregistrer
                        </template>
                        <template v-if="saveLoading">
                            <spinner :size="10" />
                        </template>
                    </button>
                </template>
            </div>
        </modal>

        <app-template>
            <div class="bg-white p-4 text-sm rounded border shadow-sm mt-6 mb-24">
                <button @click="editArticle(null)" class="bg-green-500 text-white px-4 py-2 rounded my-4">
                    Ajouter un article <i class="fas fa-plus"></i>
                </button>
                <data-table
                :server="true"
                :columns="[
                    {label: 'En ligne', key: 'display', type: 'boolean'},
                    {label: 'Titre', key: 'title', type: 'text'},
                    {label: 'Edité le', key: 'updated_at', type: 'datetime'},
                ]"
                :lines="articles"
                >
                    <template v-slot:actions='{line}'>
                        <button @click="editArticle(line)" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                            <i class="fas fa-pen mr-0.5"></i> Editer
                        </button>
                    </template>
                </data-table>
            </div>
        </app-template>
    </div>
</template>

<script>
import apiService from '@/services/api.service'

export default {
    data() {
        return {
            selectedArticle: null,
            articles: [],

            saveLoading: false,
        }
    },

    methods: {
        async getArticles() {
            let response = await apiService.post('vitrine.article.list', {}, 'api2')
            this.articles = response.data.posts.post
        },

        editArticle(article) {
            console.log(article);
            this.selectedArticle = {...article}
            this.$modal.show('modal-edit-add-article')
        },

        async save() {
            let endObj = {
                title: this.selectedArticle.title,
                summary: this.selectedArticle.summary,
                content: this.selectedArticle.content,
                img: this.selectedArticle.img,
                display: this.selectedArticle.display ? 1 : 0
            }
            if (this.selectedArticle.id) {
                endObj.article_id = this.selectedArticle.id
            }
            this.saveLoading = true
            await apiService.post('vitrine.article.edit-add', endObj, 'api2')        
            await this.getArticles()
            this.saveLoading = false

            this.selectedArticle = null
            this.$modal.hide('modal-edit-add-article')
        }
    },
    mounted () {
        this.getArticles();
    },
}
</script>

<style lang="scss" scoped>

</style>
