<template>
    <div>
        <div v-if="displayTour && steps && steps.length > 0">
            <!-- <div class="fixed top-0 left-0 right-0 bottom-0" style="z-index: 990"> -->
                <div
                id="tour-hole"
                :style="
                    'top:' + holeCss.top + 'px;' +
                    'left:' + holeCss.left + 'px;' +
                    'width:' + holeCss.width + 'px;' +
                    'height:' + holeCss.height + 'px;'
                "
                ></div>
                <div
                id="tour-modal"
                class="bg-white p-4 rounded-lg"
                :style="
                    'width:' + modalCss.width + 'px;' +
                    'top:' + modalCss.top + 'px;' +
                    'left:' + modalCss.left + 'px;'
                ">
                    <div>
                        <div class="border-b pb-4 mb-4">
                            <div v-html="steps[currentStep].message"></div>
                            <div class="flex items-center gap-2 mt-2">
                                <div v-for="(s, sIndex) in steps" :key="'step-' + sIndex">
                                    <div class="rounded-full h-[8px]" :class="sIndex == currentStep ? 'bg-emerald-600 w-[16px]' : 'bg-slate-300 w-[8px]'"></div>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center justify-between">
                            <button @click="displayTour = false"><i class="fas fa-times"></i></button>
                            <div class="flex gap-2">
                                <button class="px-4 py-2 rounded text-sm bg-slate-100 hover:bg-slate-200 duration-200" @click="prevStep">Précédent</button>
                                <button class="px-4 py-2 rounded text-sm bg-slate-100 hover:bg-slate-200 duration-200" @click="nextStep">
                                    <template v-if="currentStep != steps.length - 1">
                                        Suivant
                                    </template>
                                    <template v-if="currentStep == steps.length - 1">
                                        Terminer
                                    </template>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div> -->
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            steps: {},
        },
        watch: {
            steps() {
                this.currentStep = 0
                this.targetEl()
                this.displayTour = true
            },
            displayTour(value) {
                if (value) {
                    document.body.style.overflow = 'hidden'
                } else {
                    document.body.style.overflow = 'auto'
                }
            }
        },
        data() {
            return {
                displayTour: true,
                currentStep: 0,
                holeCss: {
                    top: 0,
                    left: 0,
                    width: 0,
                    height: 0
                },
                modalCss: {
                    width: 300,
                    top: 0,
                    left: 0,
                }
            }
        },
        methods: {
            targetEl() {
                if (this.steps && this.steps.length > 0) {
                    let el = document.querySelector(this.steps[this.currentStep].el)
                    let elRect = el.getBoundingClientRect()
    
                    elRect = el.getBoundingClientRect()
                    let padding = 20
    
                    // el.style.position = 'relative'
                    // el.style.zIndex = '999'
    
                    this.holeCss.width = (elRect.width + (padding * 2))
                    this.holeCss.height = (elRect.height + (padding * 2))
                    this.holeCss.top = (elRect.top - padding)
                    this.holeCss.left = (elRect.left - padding)
    
                    this.modalCss.top = (elRect.top + elRect.height + (padding * 2))
                    this.modalCss.left = (elRect.left + (elRect.width / 2) - (this.modalCss.width / 2))
                }
            },

            prevStep() {
                this.currentStep--
                this.targetEl()
            },

            nextStep() {
                if (this.currentStep + 1 > this.steps.length - 1) {
                    this.displayTour = false
                } else {
                    this.currentStep++
                    this.targetEl()
                }
            }
        },
        mounted () {
            if (this.steps && this.steps.length > 0) {
                this.targetEl()
            }

            // var container = document.querySelector("#template-content");
            // container.onscroll = () => {  
            //     if (this.displayTour) {
            //         this.targetEl()
            //     }
            // } 
        },
    }
</script>

<style>
#tour-modal {
    position: absolute;
    z-index: 991;
}

#tour-modal:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: #fff transparent;
    border-width: 0 10px 10px;
    top: -10px;
    left: 50%;
    margin-left: -10px;
}

#tour-hole {
    position: absolute;
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, .6);
    z-index: 990;
    border-radius: 15px;
}

body {
  overflow: hidden; /* Hide scrollbars */
}
</style>