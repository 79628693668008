<template>
    <div>
        <app-template>

            <h1 class="text-2xl font-bold py-4 mb-2">{{$tt('Mon abonnement')}}</h1>

            <div class="bg-white rounded-xl border shadow-sm p-4">
                <!-- <div class="text-right mb-4">
                    <button class="bg-red-100 text-red-500 rounded px-4 py-2"><i class="fas fa-times mr-1"></i> Annuler mon abonnement</button>
                </div> -->
                <div class="p-4" v-if="loadingGetData">
                    <spinner :size="10" />
                </div>
                <div class="grid lg:grid-cols-3 gap-4" v-if="!loadingGetData && data">
                    <div>
                        <div class="border p-4 rounded-lg max-h-[600px] overflow-y-auto">
                            <h2 class="text-xl font-bold mb-4 border-b pb-4">{{$tt('Mes connexions')}}</h2>
                            <div class="border-b mb-4 pb-4">
                                <button @click="changePage('go-to-connexions')" class="px-4 py-2 text-sm bg-blue-200 hover:bg-blue-300 duration-200 rounded"><i class="fas fa-link mr-1"></i> {{$tt('Gérer mes connexions')}}</button>
                            </div>
                            <div class="mb-4 pb-4" :class="connexionIndex < data.connexions.details.length - 1 ? 'border-b' : ''" v-for="(connexion, connexionIndex) in data.connexions.details" :key="'connexion-' + connexionIndex">
                                <div class="font-bold">{{ connexion.centrale.centrale_ha }}</div>
                                <div class="flex gap-2 font-light text-xs mt-1">
                                    <div v-if="connexion.prix <= 0 || data.prix_nego > 0">{{ $tt('Offert') }}</div>
                                    <div v-if="connexion.prix > 0 && !data.prix_nego">{{connexion.prix}} € / {{$tt('mensuel')}}</div>
                                    <div>|</div>
                                    <div>{{ connexion.nb_dossier }} {{$tt('dossiers mensuels max.')}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="border p-4 rounded-lg">
                            <h2 class="text-xl font-bold mb-4 border-b pb-4">{{$tt('Mes modules')}}</h2>
                            <div class="border-b mb-4 pb-4">
                                <button @click="changePage('go-to-modules')" class="px-4 py-2 text-sm bg-blue-200 hover:bg-blue-300 duration-200 rounded"><i class="fas fa-link mr-1"></i> {{$tt('Gérer mes modules')}}</button>
                            </div>
                            <div v-if="data.modules.details.length <= 0">
                                Aucun module
                            </div>
                            <div class="mb-4 pb-4" :class="moduleIndex < data.modules.details.length - 1 ? 'border-b' : ''" v-for="(moduleData, moduleIndex) in data.modules.details" :key="'module-' + moduleIndex">
                                <div class="font-bold">{{ moduleData.label }}</div>
                                <div class="font-light text-sm">{{ moduleData.value }} € / {{$tt('mensuel')}}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="border border-green-300 p-4 rounded-lg bg-green-200">
                            <h2 class="text-xl font-bold mb-4 border-b border-green-700 pb-4">{{$tt('Récapitulatif')}}</h2>
                            <ul class="list-disc ml-4">
                                <li>Connexions: {{ data.connexions.total }} € / {{$tt('mensuel')}}</li>
                                <li>Modules: {{ data.modules.total }} € / {{$tt('mensuel')}}</li>
                            </ul>
                            <div class="font-bold mt-4 pt-4 border-t border-green-700">Total: {{ data.total }} € / {{ $tt('mensuel') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                data: null,
                loadingGetData: false
            }
        },
        methods: {
            async getData() {
                this.loadingGetData = true
                let response = await this.$request.post('facturation.get-pricing')
                this.data = response.data.posts.post
                this.loadingGetData = false
            },
            changePage(page) {
                window.parent.postMessage({from: 'settings', type: page, meta: null}, "*");
            }
        },
        mounted () {
            this.getData();
        },
    }
</script>

<style lang="scss" scoped>

</style>