<template>
    <div>
        <label class="block mb-1 text-xs font-bold">Codes pannes</label>
        <div class="flex gap-2 font-light text-sm" v-for="(codePanne, codePanneIndex) in codePannes" :key="'code-panne-' + codePanneIndex">
            <input :id="'code-' + codePanne.code" v-model="codePannesInp" type="checkbox" :value="codePanne" />
            <label :for="'code-' + codePanne.code">{{codePanne.fr}}</label>
        </div>
        <div class="flex gap-2">
            <button class="mt-1 bg-green-500 hover:bg-green-600 cursor-pointer duration-200 text-white text-xs font-light px-3 py-2 rounded" @click="editAddCateg">Valider</button>
            <button v-if="displayDelete" class="mt-1 bg-red-500 hover:bg-red-600 cursor-pointer duration-200 text-white text-xs font-light px-3 py-2 rounded" @click="deleteCateg">Supprimer</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pattern: {required: true},
        categId: {required: true},
        codes: {},
        displayDelete: {default: true}
    },
    data() {
        return {
            codePannesInp: [],
            codePannes: [],
        }
    },
    watch: {
        categId() {
            this.getCodePannes()
        }
    },
    methods: {
        async getCodePannes() {
            this.codePannes = []
            let response = await this.$request.post('getcodepanne', {
                action: 'bdc',
                id_categ: this.categId,
            }, 'districloud_preprod')
            this.codePannes = response.data.posts.post
            this.codePannes.forEach((code) => {
                if (this.codes.find((c) => code.code == c)) {
                    this.codePannesInp.push(code)
                }
            })
        },
        async editAddCateg() {
            await this.$request.post('editadd_pattern', {
                id_pattern: this.pattern.id_pattern,
                action: 'bdc',
                id_categ: this.categId,
                code_symptome: this.codePannesInp && this.codePannesInp.length > 0 ? JSON.stringify(this.codePannesInp?.map((codePanne) => codePanne.code)) : JSON.stringify([]),
            }, 'districloud_preprod')

            this.$emit('refresh')
            this.$modal.hide('modal-add-categ')
            this.$eventHub.$emit('go-close-card', 'categ-' + this.categId)
        },

        async deleteCateg() {
            if (this.$parent.$parent.categs.length - 1 <= 0) {
                this.$toast.error('Vous ne pouvez pas supprimer la dernière catégorie')
                return
            }
            await this.$request.post('deletepattern', {
                action: 'bdc',
                id_pattern: this.pattern.id_pattern,
                id_categ: this.categId,
            }, 'districloud_preprod')

            this.$emit('refresh')
        }
    },
    mounted () {
        this.getCodePannes()
    },
}
</script>

<style lang="scss" scoped>

</style>
