<template>
    <div>
        <modal name="modal-details-payment" height="auto" width="80%" :scrollable="true">
            <div class="p-10">
                <template v-if="selectedPayment">
                    <div class="pb-5 mb-5 border-b flex items-center justify-between">
                        <h2 class="font-bold text-2xl">Détail du paiement</h2>
                        <button @click="selectedPayment = null; $modal.hide('modal-details-payment')">
                            <i class="fas fa-times text-red text-xl text-red-500"></i>
                        </button>
                    </div>
                    <div class="mb-4">
                        <label class="block mb-1 text-xs font-bold text-gray-900">Montant</label>
                        <div class="font-light">{{ selectedPayment['Amount'] }} €</div>
                    </div>
                    <div class="mb-4">
                        <label class="block mb-1 text-xs font-bold text-gray-900">Reference</label>
                        <div class="font-light">{{ selectedPayment['Reference'] }}</div>
                    </div>
                    <div class="mb-4">
                        <label class="block mb-1 text-xs font-bold text-gray-900">Date du paiement</label>
                        <div class="font-light">{{ selectedPayment['Date'] | momentDate }}</div>
                    </div>

                    <table class="text-left w-full">
                        <thead class="bg-slate-50">
                            <tr class="border-b">
                                <th class="text-xs font-light px-1 py-2">Référence dossier</th>
                                <th class="text-xs font-light px-1 py-2">Marque</th>
                                <th class="text-xs font-light px-1 py-2">Modèle</th>
                                <th class="text-xs font-light px-1 py-2">Réparé le</th>
                                <th class="text-xs font-light px-1 py-2">Montant</th>
                                <th class="text-xs font-light px-1 py-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(line, lineIndex) in selectedPayment['Details']" :key="'line-' + lineIndex" class="bg-white border-b">
                                <td class="py-2 px-1 text-sm font-light">{{ line['ref_dossier'] }}</td>
                                <td class="py-2 px-1 text-sm font-light uppercase">{{ line['appareil']['Marque'] }}</td>
                                <td class="py-2 px-1 text-sm font-light uppercase">{{ line['appareil']['Modele'] }}</td>
                                <td class="py-2 px-1 text-sm font-light">{{ line['RepairDate'] | momentDate }}</td>
                                <td class="py-2 px-1 text-sm font-light">{{ line['Amount'] }} €</td>
                                <td class="py-2 px-1 text-sm font-light">
                                    <a :href="'/ecosysteme/dossier/' + line['ref_dossier']" target="_blank" class="my-1 mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                                        <i class="fas fa-folder mr-0.5"></i> Consulter le dossier
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </div>
        </modal>

        <app-template>
            <div class="bg-white p-5 rounded-xl border shadow-sm mb-16">
                <div class="flex my-3 gap-2">
                    <input-rule v-model="filters.start" type="date" label="Date début" :label-inline="false" />
                    <input-rule v-model="filters.end" type="date" label="Date fin" :label-inline="false" />
                </div>
                <div v-if="loadingData">
                    <div class="flex justify-center">
                        <spinner :size="12" />
                    </div>
                    <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
                </div>
    
                <div v-if="!loadingData">
                    <data-table
                    :columns="[
                        {label: 'Date', key: 'Date', type: 'date'},
                        {label: 'Reference', key: 'Reference', type: 'text'},
                        {label: 'Montant', key: 'Amount', type: 'euro'},
                    ]"
                    :lines="paiements"
                    >
                        <template v-slot:actions='{line}'>
                            <button @click="selectPayment(line)" class="my-1 mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                                <i class="fas fa-eye mr-0.5"></i> Détails
                            </button>
                            <button @click="csvExport(line)" class="my-1 mr-1 text-xs bg-green-500 hover:bg-green-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                                <i class="fas fa-download mr-0.5"></i> Exporter
                            </button>
                        </template>
                    </data-table>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    watch: {
        filters: {
            deep: true,
            handler() {
                this.getPaiements()
            }
        }
    },
    data() {
        return {
            paiements: [],
            selectedPayment: null,
            loadingData: false,
            filters: {
                start: null,
                end: null
            }
        }
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        }
    },
    methods: {
        async getPaiements() {
            this.loadingData = true
            let response = await this.$request.post('ecosysteme.get-paiement', {
                from: this.filters.start,
                to: this.filters.end
            })
            this.paiements = response.data.posts.post
            this.loadingData = false
        },

        selectPayment(payment) {
            this.selectedPayment = payment
            this.$modal.show('modal-details-payment')
        },
        
        csvExport(line) {
            let rows = [["Référence dossier", "Marque", "Modèle", "Réparé le", "Montant"]];

            line['Details'].forEach((item) => {
                rows.push([
                    item['ref_dossier'],
                    item['appareil']['Marque'],
                    item['appareil']['Modele'],
                    moment(item['RepairDate']).format('DD/MM/YYYY'),
                    item['Amount'] + ' €',
                ])
            })

            let csvContent = "data:text/csv;charset=utf-8,";

            rows.forEach(function(rowArray) {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
            });

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "export_avoir.csv");
            document.body.appendChild(link);

            link.click();
        },
    },
    mounted () {
        this.filters.start = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD')
        this.filters.end = moment().add(1, 'year').startOf('year').format('YYYY-MM-DD')
        this.getPaiements();
    },
}
</script>

<style lang="scss" scoped>

</style>
