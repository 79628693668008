<template>
    <div>
        <div class="relative">
            <input
            type="text"
            v-model="addressSearchInput"
            @input="searchAddress"
            class="text-sm rounded-0 block w-full p-1.5 focus:outline-none focus:border-gray-400"
            :class="custom"
            :placeholder="placeholder"
            test-id="address-input"
            >
            <!-- List results -->
            <div v-if="results && results.length > 0" class="absolute w-full overflow-y-auto text-black bg-white border shadow rounded-b-xl max-h-40 z-20">
                <div @click="selectAddress(result)" v-for="(result, resultIndex) in results" :key="'address-result-' + resultIndex" :class="resultIndex < results.length -1 ? 'border-b' : ''" class="p-4 text-sm duration-100 cursor-pointer hover:bg-gray-50" :test-id="'address-search-result-' + resultIndex">
                    <i class="mr-2 fas fa-location-dot"></i> {{result.formatted_address}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MapService from '@/services/map.service'

export default {
    props: {
        placeholder: {},
        country: {},
        defaultValue: {},
        custom: {},
        allWays: {default: false}
    },

    watch: {
        country: function() {
            this.addressSearchInput = null
            this.results = []
        },

        defaultValue: function() {
            this.setDefaultAddress()
        }
    },

    data() {
        return {
            addressSearchInput: "",
            results: [],
        }
    },

    methods: {
        async searchAddress() {
            if (this.addressSearchInput) {
                clearTimeout(this.timer)
                this.timer = await setTimeout(async () => {
                    let responseAddresses = await MapService.search(this.addressSearchInput, this.country)
                    this.results = responseAddresses.filter((address) => address.types.includes('premise') || address.types.includes('street_address'))
                }, 200)
            } else {
                this.results = []
            }
        },

        selectAddress(result) {
            this.addressSearchInput = result.formatted_address
            this.results = []
            this.$emit('get', result)
        },

        async setDefaultAddress() {
            let responseAddresses = await MapService.search(this.defaultValue, this.country)
            let addresses = responseAddresses
            if (!this.allWays) {
                addresses = responseAddresses.filter((address) => address.types.includes('premise') || address.types.includes('street_address'))
            }
            if (addresses.length > 0) {
                this.addressSearchInput = this.defaultValue
                this.selectAddress(responseAddresses[0])
            }
        }
    },

    mounted() {
        if (this.defaultValue) {
            this.setDefaultAddress()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
