<template>
    <div>
        <modal name="modal-similar-shops" height="auto" width="80%" :scrollable="true">
            <div v-if="similars && similars.length > 0" class="p-10" style="z-index: 999 !important;">
                <h2 class="text-xl font-bold mb-2">Magasins similaires</h2>
                <p class="font-light mb-4">Nous avons trouvés des magasins qui peuvent correspondre au votre, merci de faire une vérification.</p>

                <div v-for="(magasin, magasinIndex) in similars" class="border rounded p-2 mb-2" :key="'similar-' + magasinIndex">
                    <div class="flex items-center justify-between">
                        <div>
                            <div class="font-bold text-sm">{{ magasin.magasin }}</div>
                            <div class="font-light text-xs">Adresse: {{ magasin.adresse1 + ' ' + magasin.cp + ' ' + magasin.ville }}</div>
                            <div v-if="magasin.siret_mag && magasin.siret_mag != '0'" class="font-light text-xs">SIRET: {{ magasin.siret_mag }}</div>
                        </div>
                        <button @click="$emit('select-magasin', magasin); $modal.hide('modal-similar-shops')" class="bg-green-500 hover:bg-green-600 duration-200 cursor-pointer text-white rounded px-4 py-2 text-sm">Choisir ce magasin</button>
                    </div>
                </div>

                <div class="text-center mt-5">
                    <button @click="$emit('end', endObj); $modal.hide('modal-similar-shops')" class="px-4 py-4 bg-yellow-200 hover:bg-yellow-300 duration-200 cursor-pointer rounded text-sm ">Magasin introuvable</button>
                </div>
            </div>
        </modal>

        <input-rule :disabled="magasin.nom ? true : false" v-model="form.nom" type="text" class="text-left mb-4" :label="$tt('Nom de l\'enseigne')" id="shop-name" :label-inline="false" />
        <input-rule v-model="form.address" type="address2" :options="{defaultValue: magasin.adresse, country: country}" class="text-left mb-4" custom="bg-gray-100" :label="$tt('Adresse')" id="shop-address" :label-inline="false" />
        <input-rule v-model="form.tel" type="tel" :options="{country: country}" class="text-left mb-4" custom="bg-gray-100" :label="$tt('Téléphone')" id="shop-tel" :label-inline="false" />
        <input-rule v-model="form.email" type="text" class="text-left mb-4" custom="bg-gray-100" :label="$tt('Email')" id="shop-email" :label-inline="false" />

        <input-rule
        id="select-grossiste"
        class="mb-4"
        v-model="selectedGrossiste"
        :label-inline="false"
        :label="$tt('Pour quel fournisseur souhaitez-vous gérer la garantie ?')"
        type="api-select"
        @inp="getInpFournisseur"
        :options="{
            api: {action: 'inscription.search-grossiste', apiName: 'api2'},
            label: 'nom',
            init: false,
            min: 3,
            uppercase: true
        }"
        />

        <button @click="endForm" class="text-sm flex items-center font-light bg-emerald-600 text-white px-4 py-2 border-emerald-600 rounded-lg">
            {{ $tt('Valider') }} <i class="fas fa-check ml-1"></i>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        magasin: {},
        country: {},
        enseigneId: {},
    },

    data() {
        return {
            form: {},
            defaultAddress: null,

            selectedGrossiste: null,
            fournisseurInp: null,

            similars: [],
        }
    },

    computed: {
        endObj() {
            let endObj = {
                nom: this.form.nom,
                email: this.form.email,
                tel: this.form.tel,
            }

            if (this.selectedGrossiste) {
                endObj.fournisseurs = [{id: this.selectedGrossiste.id, nom: this.selectedGrossiste.nom}]
            } else {
                if (this.fournisseurInp) {
                    endObj.fournisseurs = [{id: null, nom: this.fournisseurInp}]
                } else {
                    endObj.fournisseurs = []
                }
            }
            endObj = {...endObj, ...this.form.address}
            return endObj
        }
    },

    methods: {
        async endForm() {
            if (!this.$validation.check([
                {value: this.endObj.nom, rules: 'required', id: 'shop-name'},
                {value: this.form.address, rules: 'required', id: 'shop-address'},
                {value: this.endObj.tel, rules: 'required', id: 'shop-tel'},
                {value: this.endObj.email, rules: 'required|email', id: 'shop-email'},
                {value: this.endObj.fournisseurs && this.endObj.fournisseurs.length > 0, rules: 'required', id: 'select-grossiste'},
            ])) {return}
            if (this.country == 'FR') {
                if (!this.$validation.check([
                    {value: this.endObj.adresse1, rules: 'required', id: 'shop-address'},
                ])) {return}
                if (!this.$validation.check([
                    {value: this.endObj.cp, rules: 'required', id: 'shop-address'},
                ])) {return}
            }
            if (!this.$validation.check([
                {value: this.endObj.ville, rules: 'required', id: 'shop-address'},
            ])) {return}
            if (!this.$validation.check([
                {value: this.endObj.longitude, rules: 'required', id: 'shop-address'},
            ])) {return}
            if (!this.$validation.check([
                {value: this.endObj.latitude, rules: 'required', id: 'shop-address'},
            ])) {return}

            let response = await this.$request.post('inscription.similar-magasin', {
                longitude: this.endObj.longitude,
                latitude: this.endObj.latitude,
                cp: this.endObj.cp,
                ville: this.endObj.ville,
                nom: this.form.nom,
                enseigneId: this.enseigneId ? this.enseigneId : 0,
            })
            if (response.data.posts.post == 1) {
                this.$emit('end', this.endObj)
            } else {
                this.similars = response.data.posts.post
                this.$modal.show('modal-similar-shops')
            }
        },

        getInpFournisseur(value) {
            this.fournisseurInp = value
        }
    },
    mounted () {
        if (this.magasin) {
            this.form.nom = this.magasin.nom || ''
            this.defaultAddress = this.magasin.adresse.adresse1 ? (this.magasin.adresse.adresse1 + ' ' + (this.magasin.adresse.adresse2 ? this.magasin.adresse.adresse2 : '') + this.magasin.adresse.cp + ' ' + this.magasin.adresse.ville) : ''
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
