<template>
    <div>
        <div class="table-atelier">
            <div class="relative overflow-x-auto">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 bg-slate-100">
                    <thead class="text-xs text-gray-700 uppercase">
                        <tr>
                            <th scope="col" class="px-2 py-2" style="width: 70px">{{ $tt('Action') }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt('Dossier') }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt("Donneur d'ordre") }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt("Demande du") }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt('Marque') }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt('Modèle') }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt('Garantie') }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt('Client') }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt('Tel. client') }}</th>
                            <th scope="col" class="px-2 py-2">{{ $tt('IAD') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(line, lineIndex) in lines" :key="'line-' + lineIndex" class="bg-white hover:bg-blue-50 duration-200 border-b">
                            <!-- Actions -->
                            <td class="px-2 py-1 text-xs" style="width: 70px"><button class="text-sm text-slate-600 px-2.5 py-1.5 rounded"><i class="fas fa-circle-info"></i></button></td>
                            <!-- Dossier -->
                            <td class="px-2 py-1 text-xs">{{ line.ref_dossier }}</td>
                            <!-- Donneur -->
                            <td class="px-2 py-1 text-xs">{{ line.nom_do }}</td>
                            <!-- Date demande -->
                            <td class="px-2 py-1 text-xs">{{ line.date_create | momentDate }}</td>
                            <!-- Marque -->
                            <td class="px-2 py-1 text-xs">{{ line.device['Marque'] }}</td>
                            <!-- Modèle -->
                            <td class="px-2 py-1 text-xs">{{ line.device['Modele'] }}</td>
                            <!-- Garantie -->
                            <td class="px-2 py-1 text-xs">{{ line.garantie }}</td>
                            <!-- Client -->
                            <td class="px-2 py-1 text-xs">{{ line.end_customer.nom + ' ' + line.end_customer.prenom }}</td>
                            <!-- Tel client -->
                            <td class="px-2 py-1 text-xs">{{line.end_customer.gsm}} <br v-if="line.end_customer.gsm && line.end_customer.tel" /> {{line.end_customer.tel}}</td>
                            <!-- IAD -->
                            <td class="px-2 py-1 text-xs">
                                <button v-if="line.iad" class="bg-green-500 text-white px-2 py-1 rounded"><i class="fas fa-home mr-0.5"></i> IAD</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    data() {
        return {
            lines: [],
        }
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        async getLines() {
            let response = await this.$request.post('technicien.devis_attente')
            this.lines = response.data.posts.post
        },
    },
    mounted () {
        this.getLines()
    },
}
</script>

<style lang="scss" scoped>

</style>