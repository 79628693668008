<template>
    <div>
        <app-template
        page-name="Catégories"
        >
            <!-- Modals -->
            <modal name="modal-history" height="auto" :scrollable="true">
                <div class="p-10" v-if="selectedClaim">
                    <h2 class="text-lg mb-2 border-b mb-3 pb-3 font-bold">Historique de la demande</h2>
                    <div class="bg-slate-100 p-4 rounded text-sm mb-4">
                        <div class="text-xs font-light">Numéro service ordre</div>
                        <b>{{ selectedClaim.sap_service_order }}</b>
                    </div>
                    <ol class="relative border-l border-gray-200 mb-8">                  
                        <li class="pt-2 mb-6 ml-4">
                            <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white"></div>
                            <h3 class="text-gray-900 text-sm font-bold">Envoyée, en cours de vérification</h3>
                            <h6 class="text-xs font-light opacity-80 mt-1">{{ selectedClaim.claim_date | momentDate }}</h6>
                        </li>
                        <li class="mb-6 ml-4" v-if="selectedClaim.state >= 3 && selectedClaim.state != 5">
                            <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white"></div>
                            <h3 :class="selectedClaim.state == 3 ? 'font-semibold' : ''" class="text-gray-900 text-sm font-bold">Approuvé, en cours de remboursement</h3>
                            <h6 class="text-xs font-light opacity-80 mt-1">{{ selectedClaim.accept_date | momentDate }}</h6>
                        </li>
                        <li class="mb-6 ml-4" v-if="selectedClaim.state >= 4 && selectedClaim.state != 5">
                            <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white"></div>
                            <h3 :class="selectedClaim.state == 4 ? 'font-semibold' : ''" class="text-green-500 text-sm font-bold">Remboursé</h3>
                            <h6 class="text-xs font-light opacity-80 mt-1">{{ selectedClaim.pay_date | momentDate }}</h6>
                        </li>
                        <li class="mb-6 ml-4" v-if="selectedClaim.state >= 5">
                            <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white"></div>
                            <h3 :class="selectedClaim.state == 5 ? 'font-semibold' : ''" class="text-gray-900 text-red-500  text-sm font-bold">Demande rejetée</h3>
                            <h6 class="text-xs font-light opacity-80 mt-1">{{ selectedClaim.refused_date | momentDate }}</h6>
                            <div v-if="selectedClaim.reject_reason" class="bg-red-100 p-4 rounded-xl text-red-700 mt-2">
                                <div class="font-bold text-sm">{{ selectedClaim.reject_reason }}</div>
                            </div>
                        </li>
                    </ol>
                </div>
            </modal>

            <modal name="modal-complete-form" height="auto" width="70%" :scrollable="true">
                <div class="p-10" v-if="selectedFolder">
                    <div class="text-right">
                        <button @click="$modal.hide('modal-complete-form')" class="text-red-500 text-xl">
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                    <ecosystem-claim-form
                    class="mt-5"
                    :data-src="selectedFolder.json"
                    :folder="selectedFolder"
                    @submit="makeClaim"
                    :sendLoading="loadingMakeClaim"
                    />
                </div>
            </modal>

            <!-- Content -->
            <div class="bg-white p-10 rounded-xl border shadow-sm mt-6 mb-24">
                <!-- Loader -->
                <div v-if="loadingData">
                    <div class="flex justify-center">
                        <spinner :size="12" />
                    </div>
                    <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
                </div>

                <!-- Page content -->
                <div v-if="!loadingData">
                    <div>
                        <div class="p-4">
                            <!-- Selected folder -->
                            <div v-if="selectedFolder">
                                <!-- Title -->
                                <div class="flex items-center justify-between">
                                    <div class="flex items-center">
                                        <div class="mr-2">
                                            <div class="font-light text-xs">Référence dossier</div>
                                            <h2 class="text-xl font-bold mr-2">{{selectedFolder.folder.ref_dossier}}</h2>
                                        </div>
                                        <eco-system-badge-state :state="selectedFolder.state" />
                                    </div>
                                    <button @click="$router.push({name: 'ecosystem.folders'})" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer rounded text-sm font-light">Retour au tableau</button>
                                </div>

                                <!-- Form -->
                                <ecosystem-claim-form
                                v-show="folders[0].state < 2"
                                class="mt-5"
                                :data-src="selectedFolder.json"
                                :folder="selectedFolder"
                                @submit="makeClaim"
                                :sendLoading="loadingMakeClaim"
                                />

                                <div class="mt-6" v-show="folders[0].state >= 2">
                                    <data-table
                                    :columns="[
                                        {label: 'Numéro service ordre', key: 'sap_service_order', type: 'text'},
                                        {label: 'Etat', slot: 'state', key: 'state', type: 'text'},
                                        {label: 'Date de demande', key: 'claim_date', type: 'date'},
                                        {label: 'Informations', key: 'reject_reason', type: 'text'},
                                    ]"
                                    :lines="folders"
                                    >
                                        <template v-slot:state='{line}'>
                                            <eco-system-badge-state :state="line.state" />
                                        </template>
                                        <template v-slot:actions='{line}'>
                                            <button @click="displayHistory(line)" v-if="line.state > 1" class="mr-1 my-1 text-xs bg-slate-500 hover:bg-slate-600 duration-200 cursor-pointer font-light px-3 py-1 rounded-xl text-white">
                                                <i class="fa-solid fa-bars-staggered mr-1"></i> Historique
                                            </button>
                                            <button v-if="line.state < 2" @click="selectedFolder = line; $modal.show('modal-complete-form')" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-4 py-2.5 rounded-xl text-white">
                                                <i class="fa-solid fa-pen mr-1"></i> Compléter
                                            </button>
                                        </template>
                                    </data-table>

                                    <div v-if="folders[folders.length - 1].state == 5" class="border border-t-0 border-gray-100 bg-gray-50 text-center p-2">
                                        <button @click="makeNewClaim" :disabled="loadingNewClaim" class="mr-1 text-xs bg-green-500 hover:bg-green-600 duration-200 cursor-pointer font-light px-4 py-2.5 rounded-xl text-white">
                                            <div v-show="!loadingNewClaim">
                                                <i class="fa-solid fa-plus mr-1"></i> Nouvelle réclamation
                                            </div>
                                            <spinner :size="10" v-if="loadingNewClaim" />
                                        </button>
                                    </div>
                                            
                                    <spinner v-if="loadingEcosystemFolder" :size="14" class="m-10" />
                                    <div v-if="ecosystemFolder && !loadingEcosystemFolder">
                                        <div class="grid grid-cols-4 gap-3 mt-6">
                                            <card-title title="Produit" class="mb-4">
                                                <ul class="list-disc ml-4 font-light text-sm">
                                                    <li>Catégorie: {{ ecosystemFolder['Product']['ProductSubCategory'] }}</li>
                                                    <li>Marque: {{ ecosystemFolder['Product']['BrandId'] == '0000' ? ecosystemFolder['Product']['PartnerProduct'] : ecosystemFolder['Product']['BrandName'] }}</li>
                                                    <li>Modele: {{ ecosystemFolder['Product']['CommercialReference'] }}</li>
                                                    <li>Code iris: {{ ecosystemFolder['Product']['IrisCode'] }}</li>
                                                </ul>
                                            </card-title>
                                            <card-title title="Réparation" class="mb-4">
                                                <ul class="list-disc ml-4 font-light text-sm">
                                                    <li>Date de réparation: {{ ecosystemFolder['RepairDate'] | momentDate }}</li>
                                                    <li>Pièces: {{ selectedFolder.folder.spare_parts.length == 0 ? 'Aucune' : '' }}</li>
                                                    <ul class="list-disc ml-4 font-light text-slate-600">
                                                        <li class="text-xs mt-2" v-for="(sparePart, sparePartIndex) in selectedFolder.folder.spare_parts" :key="'part-' + sparePartIndex">
                                                            <div class="mb-1">{{ sparePart.reference || 'Inconnue' }}</div>
                                                            <ul class="list-disc ml-8 font-light text-slate-600">
                                                                <li class="mb-1">Désignation: {{ sparePart.designation || '-' }}</li>
                                                                <li class="mb-1">Etat: {{ sparePart.neuf == 1 ? 'Neuve' : 'Occasion' }}</li>
                                                                <li class="mb-1">Quantité: {{ sparePart.qty }}</li>
                                                                <li class="mb-1">Prix vente: {{ sparePart.prix_vente }} €</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </ul>
                                            </card-title>
                                            <card-title title="Client" class="mb-4">
                                                <ul class="list-disc ml-4 font-light text-sm">
                                                    <li>Nom: {{ ecosystemFolder['Customer']['LastName'] }}</li>
                                                    <li>Prénom: {{ ecosystemFolder['Customer']['FirstName'] }}</li>
                                                    <li>Téléphone: {{ ecosystemFolder['Customer']['PhoneNumber'] }}</li>
                                                    <li>Adresse: {{ ecosystemFolder['Customer']['StreetLine1'] + ' ' + ecosystemFolder['Customer']['PostalCode'] + ' ' + ecosystemFolder['Customer']['City'] }}</li>
                                                </ul>
                                            </card-title>
                                            <card-title title="Facturation" class="mb-4">
                                                <ul class="list-disc ml-4 font-light text-sm">
                                                    <li v-if="selectedFolder">Facture: <a :href="selectedFolder.uploaded_invoice" class="text-blue-500 underline" target="_blank">Consulter le fichier</a></li>
                                                    <li v-if="selectedFolder && selectedFolder.serial_tag_required">Plaque signalétique: <a :href="selectedFolder.uploaded_serial_tag" class="text-blue-500 underline" target="_blank">Consulter le fichier</a></li>
                                                    <li v-if="ecosystemFolder['Bill']['TotalAmountInclVAT']">TTC: {{ ecosystemFolder['Bill']['TotalAmountInclVAT'].amount }} €</li>
                                                    <li v-if="ecosystemFolder['Bill']['AmountBeforeTax']">HT: {{ ecosystemFolder['Bill']['AmountBeforeTax'].amount }} €</li>
                                                    <li v-if="ecosystemFolder['Bill']['SparePartsCost']">Coût des pièces: {{ ecosystemFolder['Bill']['SparePartsCost'].amount }} €</li>
                                                    <li v-if="ecosystemFolder['Bill']['TravelExpenses']">Frais de déplacement: {{ ecosystemFolder['Bill']['TravelExpenses'].amount }} €</li>
                                                    <li v-if="ecosystemFolder['Bill']['LaborCost']">Coût main oeuvre: {{ ecosystemFolder['Bill']['LaborCost'].amount }} €</li>
                                                    <li v-if="ecosystemFolder['Bill']['AmountCovered']">Remboursement: {{ ecosystemFolder['Bill']['AmountCovered'].amount }} €</li>
                                                </ul>
                                            </card-title>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import apiService from '@/services/api.service'
import moment from 'moment'

import EcoSystemBadgeState from './components/EcoSystemBadgeState.vue'
import EcosystemClaimForm from './components/EcosystemClaimForm.vue'

export default {
    components: {
        EcoSystemBadgeState,
        EcosystemClaimForm
    },
    data() {
        return {
            loadingData: false,
            loadingMakeClaim: false,
            loadingNewClaim: false,
            selectedFolder: null,
            folders: [],
            loadingEcosystemFolder: false,
            ecosystemFolder: null,

            selectedClaim: null,

        }
    },
    computed: {
        historyItem() {
            return {
                '1': {bg: 'bg-blue-200', text: 'text-blue-600', label: 'A compléter', icon: 'fas fa-pen', border: 'border-blue-400'},
                '2': {bg: 'bg-slate-200', text: 'text-slate-600', label: 'En attente', icon: 'fas fa-hourglass-half', border: 'border-slate-400'},
                '3': {bg: 'bg-green-200', text: 'text-green-600', label: 'Validée', icon: 'fas fa-check', border: 'border-green-400'},
                '4': {bg: 'bg-green-200', text: 'text-green-600', label: 'Payée', icon: 'fas fa-euro-sign', border: 'border-green-400'},
                '5': {bg: 'bg-red-200', text: 'text-red-600', label: 'Refusée', icon: 'fas fa-times', border: 'border-red-400'},
            }
        }
    },
    filters: {
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('LLL')
            }
            return '-'
        },

        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        }
    },
    methods: {
        // Get all claims in folder
        async getClaims() {
            this.loadingData = true
            let response = await apiService.post('ecosysteme.get-folders-list', {
                ref_folder: this.$route.params.ref
            })
            this.folders = response.data.posts.post
            if (!this.folders || this.folders.length <= 0) {
                this.$toast.error('Vous ne pouvez pas accéder à cette demande')
                this.$router.push({name: 'ecosystem.folders'})
                return
            }
            this.selectFolder(this.folders[0])
            this.loadingData = false
        },
        // Make claim if is empty
        async makeClaim(json) {
            this.loadingMakeClaim = true
            let response = await apiService.post('ecosysteme.completer_demande', {
                id_case: this.selectedFolder.folder.id_dossiersav,
                url_token: this.selectedFolder.url_token,
                json: JSON.stringify(json),
            })
            
            if (response.data.posts.post && response.data.posts.post.valid) {
                await this.getClaims()
            } else {
                let messages = response.data.posts.error != 0 ? [response.data.posts.error] :  response.data.posts.post.errors
                messages.forEach((message) => {
                    this.$toast.error(message)
                })
            }
            this.$modal.hide('modal-complete-form')
            this.loadingMakeClaim = false
        },
        // Get data in ecosystem claim
        async getEcosystemFolder(sapServiceOrder) {
            this.loadingEcosystemFolder = true
            let response = await apiService.post('ecosysteme.get-ecosystem-folder', {
                service_order: sapServiceOrder
            })
            this.ecosystemFolder = response.data.posts.post
            this.loadingEcosystemFolder = false
        },
        // Time passed for history navigation
        timePassed(date) {
            return moment(date).lang('fr').fromNow()
        },
        // Select folder from menu
        selectFolder(folder) {
            this.selectedFolder = folder
            if (folder.state > 1) {
                this.getEcosystemFolder(folder.sap_service_order)
            }
        },
        // Make new claim
        async makeNewClaim() {
            if (this.selectedFolder && !this.loadingNewClaim) {
                this.loadingNewClaim = true
                await apiService.post('ecosysteme.new-claim-on-folder', {
                    folder_id: this.selectedFolder.folder_id,
                })
                await this.getClaims()
                this.selectedFolder = this.folders[0]
                this.loadingNewClaim = false
            }
        },
        // Display history modal
        displayHistory(claim) {
            this.selectedClaim = claim
            this.$modal.show('modal-history')
        },
    },
    async mounted () {
        this.loadingData = true

        let response = await apiService.post('ecosysteme.has-eco-access', {})
        if (!response.data.posts.post) {
            this.$router.push({name: 'ecosystem.connect'})
            return
        }

        this.getClaims();
    },
}
</script>
