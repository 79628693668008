var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal',{attrs:{"name":"modal-confirm-delete","height":"auto","scrollable":true}},[_c('div',{staticClass:"p-10"},[(_vm.selectedLine)?[_c('div',{staticClass:"flex items-center justify-between mb-4 pb-4 border-b text-2xl"},[_c('h2',{staticClass:"font-bold"},[_vm._v(" Confirmer la suppression ")]),_c('button',{on:{"click":function($event){_vm.$modal.hide('modal-confirm-resend'); _vm.selectedLine = null}}},[_c('i',{staticClass:"fas fa-times text-red-500"})])]),_c('div',{staticClass:"p-4 mb-4 text-sm text-orange-800 rounded-lg bg-orange-50",attrs:{"role":"alert"}},[_c('p',[_vm._v("Êtes-vous sûr de vouloir supprimer le dossier "),_c('b',[_vm._v(_vm._s(_vm.selectedLine.ref))]),_vm._v(" ?")])]),_c('button',{staticClass:"bg-red-600 hover:bg-red-700 duration-200 cursor-pointer text-white px-4 py-2 rounded text-sm",attrs:{"disabled":_vm.waitingDelete},on:{"click":function($event){return _vm.deleteFolder(_vm.selectedLine)}}},[(!_vm.waitingDelete)?[_vm._v(" Confirmer "),_c('i',{staticClass:"fas fa-trash ml-1"})]:_vm._e(),(_vm.waitingDelete)?[_c('spinner',{attrs:{"size":10}})]:_vm._e()],2)]:_vm._e()],2)]),_c('app-template',[_c('div',{staticClass:"bg-white p-7 rounded border shadow-sm mb-16"},[(_vm.loadingData)?[_c('div',{staticClass:"flex justify-center"},[_c('spinner',{attrs:{"size":12}})],1),_c('div',{staticClass:"mt-5 text-sm font-extralight text-center"},[_vm._v("Chargement des données..")])]:_vm._e(),(!_vm.loadingData)?[_c('data-table',{attrs:{"filters-inline":false,"emit-filters":true,"filters":[
                    {key: 'state', type: 'select', label: 'Statut', options: _vm.statesFilterOptions, value: null},
                    {label: 'Date demande', key: 'claim_date', type: 'date-range', value: null},
                    {label: 'Ref dossier', key: 'ref', type: 'text', value: null},
                    {label: 'Numéro service ordre', key: 'service_order', type: 'text', value: null},
                    {label: 'Marque', key: 'brand', type: 'text', value: null},
                    {label: 'Appareil', key: 'device', type: 'text', value: null},
                ],"columns":[
                    {label: 'Statut', key: 'state', slot: 'state', type: 'text', css: 'min-w-[300px]'},
                    {label: 'Ref Dossier', key: 'ref', type: 'text'},
                    {label: 'Catégorie produit', key: 'category', type: 'text'},
                    {label: 'Marque', key: 'brand', type: 'text'},
                    {label: 'Appareil', key: 'device', type: 'text'},
                    {label: 'Numéro Service Ordre', key: 'service_order', type: 'text'},
                    {label: 'Facture', key: 'invoice', type: 'file'},
                    {label: 'Date demande', key: 'claim_date', type: 'date'},
                    {label: 'Accepté le', key: 'accept_date', type: 'date'},
                    {label: 'Payé le', key: 'pay_date', type: 'date'},
                    {label: 'Rejeté le', key: 'refused_date', type: 'date'},
                ],"lines":_vm.folders},on:{"apply-filters":_vm.applyFilters},scopedSlots:_vm._u([{key:"state",fn:function({line}){return [_c('eco-system-badge-state',{attrs:{"state":line.state}})]}},{key:"actions",fn:function({line}){return [_c('div',{staticClass:"flex items-center"},[(line.state == 1)?_c('button',{staticClass:"mr-1 text-xs bg-slate-300 hover:bg-slate-400 duration-200 cursor-pointer font-light px-3 py-1.5 rounded",on:{"click":function($event){return _vm.resendFolder(line)}}},[_vm._v("Renvoyer")]):_vm._e(),_c('button',{staticClass:"mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white",on:{"click":function($event){return _vm.$router.push({name: 'ecosystem.folder.view', params: {ref: line.url_ref}})}}},[_c('i',{staticClass:"fas fa-pen"})]),(line.state == 1)?_c('button',{staticClass:"mr-1 text-xs bg-red-300 hover:bg-red-400 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white",on:{"click":function($event){_vm.selectedLine = line; _vm.$modal.show('modal-confirm-delete')}}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e()])]}}],null,false,4272069229)})]:_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }