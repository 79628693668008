<template>
    <div>
        <!-- Modal confirm delete -->
        <modal name="modal-confirm-delete" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedLine">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        Confirmer la suppression
                    </h2>
                    <button @click="$modal.hide('modal-confirm-delete')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="text-lg">Souhaitez-vous supprimer <b>{{ selectedLine.grossiste.nom }}</b> ?</div>
                    <div class="flex gap-2 items-center mt-4">
                        <button @click="$modal.hide('modal-confirm-delete')" class="px-4 py-2 rounded text-sm bg-slate-200 hover:bg-slate-300 duration-200">Annuler <i class="fas fa-times ml-1"></i></button>
                        <button :disabled="loadingDelete" @click="deleteLine" class="px-4 py-2 rounded text-sm bg-red-500 hover:bg-red-600 duration-200 text-white">
                            <spinner :size="10" v-if="loadingDelete" />
                            <template v-if="!loadingDelete">
                                {{$tt('Supprimer')}} <i class="fas fa-trash ml-1"></i>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <!-- Modal add marque -->
        <modal name="modal-add-marque" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b">
                    <div>
                        <h2 class="text-xl font-semibold"><i class="fas fa-plus mr-1"></i> {{$tt(' Ajouter un agrément de marque')}}</h2>
                    </div>
                    <button @click="$modal.hide('modal-add-marque')" class="text-2xl">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div class="min-h-[200px]" v-if="newForm">
                    <input-rule
                    id="inp-marque"
                    v-model="newForm.marque"
                    :label-inline="false"
                    :disabled="newForm.id_connexion ? true : false"
                    :label="$tt('Nom de la marque')"
                    :placeholder="$tt('Saisir la marque désirée')"
                    type="api-select"
                    :options="{
                        api: {action: 'reparateur.get-list-grossiste', apiName: 'api2'},
                        keyTrack: 'id',
                        label: 'nom',
                        init: false,
                        min: 2,
                        uppercase: true,
                        defaultValue: newForm.marque?.id
                    }"
                    />
                    <div class="mt-4">
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500 mb-4 border">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th class="px-6 py-3">
                                        {{$tt('Activité')}}
                                    </th>
                                    <th class="px-6 py-3">
                                        {{$tt('Agréé')}}
                                    </th>
                                    <th class="px-6 py-3">
                                        {{$tt('Zone intervention à domicile')}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(activite, activiteIndex) in activites" :key="'activite-' + activiteIndex" class="bg-white border-b hover:bg-slate-100 duration-200">
                                    <td class="px-6 py-2 font-semibold">
                                        {{ activite.libelle }}
                                    </td>
                                    <td class="px-6 py-2">
                                        <div class="bg-red-500 text-white rounded p-1 text-xs font-light" v-if="newForm.activites[activite.id].agree == 2">{{ $tt('Refusé') }}</div>
                                        <template v-else>
                                            <input-rule type="pcheck" v-model="newForm.activites[activite.id].agree" @input="(data) => {if (data == 0) { newForm.activites[activite.id].zone = null }; $forceUpdate()}" :label-inline="false"/>
                                        </template>
                                    </td>
                                    <td class="px-6 py-2">
                                        <input-rule v-model="newForm.activites[activite.id].zone" type="vselect" :options="{values: zones, label: 'nom', key: 'id'}" :label-inline="false" class="mb-4 duration-200" :class="newForm.activites[activite.id].agree == 0 ? 'opacity-30' : ''" :disabled="newForm.activites[activite.id].agree == 0" @input="$forceUpdate()" :placeholder="$tt('Optionnel')" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <button @click="newForm.id_connexion ? editGrossistes() : saveForm()" :disabled="loadingAdd" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded text-sm mb-10">
                        <spinner v-if="loadingAdd" :size="10" />
                        <template v-if="!loadingAdd">
                            {{ $tt('Enregistrer') }} <i class="fas fa-check ml-1"></i>
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <div v-if="loadingGetActivites" class="p-4">
            <spinner :size="10" />
        </div>
        <div v-if="!loadingGetActivites">
            <div class="text-right">
                <button @click="displayModalAdd()" class="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded duration-200 mb-8 text-sm"><i class="fas fa-plus mr-1"></i> {{ $tt('Ajouter un agrément') }}</button>
            </div>
        </div>

        <template v-if="grossistes && grossistes.length <= 0">
            <div class="p-4 text-slate-300 text-xl text-right font-semibold -mt-4">{{ $tt("Vous pouvez créer votre premier agrément en cliquant sur le bouton ci-dessus") }} <i class="fas fa-arrow-up ml-4"></i></div>
        </template>
        <div v-if="grossistes && grossistes.length > 0">
            <div class="relative overflow-x-auto">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th class="bg-white w-[190px]"></th>
                            <th class="text-center px-6 py-3 bg-slate-200 border-slate-300 border-t border-x border-b font-bold text-base">
                                {{$tt('Marque')}}
                            </th>
                            <th class="bg-blue-200 border-blue-300 border">
                                <div class="text-center pt-2 pb-2 font-bold text-base border-blue-300 border-b">{{$tt('Agréments selon vos activités')}}</div>
                                <div class="grid" :class="'grid-cols-' + activites.length">
                                    <div v-for="(activite, activiteIndex) in activites" :key="'activite-' + activiteIndex" class="bg-blue-200 border-blue-300 text-center py-3" :class="activiteIndex < activites.length - 1 ? 'border-r' : ''">
                                        {{activite.libelle}}
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(grossiste, grossisteIndex) in grossistes" :key="'grossiste-' + grossisteIndex" class="bg-white duration-200">
                            <td class="px-1 py-2">
                                <div class="flex items-center gap-2">
                                    <button @click="displayModalEdit(grossiste)" class="px-2 py-1 bg-blue-500 hover:bg-blue-600 duration-200 text-white rounded text-xs rounded"><i class="fas fa-pen mr-0.5"></i> {{ $tt('Modifier') }}</button>
                                    <button @click="selectedLine = grossiste; $modal.show('modal-confirm-delete')" class="px-2 py-1 bg-red-500 hover:bg-red-600 duration-200 text-white rounded text-xs rounded"><i class="fas fa-trash mr-0.5"></i> {{ $tt('Supprimer') }}</button>
                                </div>
                            </td>
                            <td class="text-center px-6 py-2 bg-slate-100 border-b border-slate-300 border-x">
                                {{ grossiste.grossiste.nom }}
                            </td>
                            <td class="border-blue-300 border-y border-r bg-blue-100">
                                <div class="grid" :class="'grid-cols-' + activites.length">
                                    <div v-for="(activite, activiteIndex) in activites" :key="'activite-row-' + activiteIndex" class="px-6 py-2 bg-blue-100 text-center border-blue-300" :class="activiteIndex < activites.length - 1 ? 'border-r' : ''">
                                        <!-- Pas agréé -->
                                        <template v-if="!grossiste[activite.id].agree">-</template>
                                        <!-- Agréé -->
                                        <template v-if="grossiste[activite.id].agree">
                                            <div v-if="grossiste[activite.id].agree == 1">
                                                <!-- En cours de validation -->
                                                <div v-if="grossiste[activite.id].valid == 0" class="text-slate-400">
                                                    <div class="font-base">{{ $tt('Attente de validation par la marque') }}..</div>
                                                    <div class="text-xs font-light">{{ grossiste[activite.id].zone_obj?.nom }}</div>
                                                </div>
                                                <!-- Validé -->
                                                <div v-if="grossiste[activite.id].valid == 1" class="text-blue-800">
                                                    <div class="font-base">{{ $tt('Oui') }}</div>
                                                    <div class="text-xs font-light">{{ grossiste[activite.id].zone_obj?.nom }}</div>
                                                </div>
                                            </div>
                                            <!-- Refusé -->
                                            <div v-if="grossiste[activite.id].agree == 2" class="text-red-600">
                                                <div class="font-base">{{ $tt('Refusé par la marque') }}</div>
                                                <div v-if="grossiste[activite.id].motif_refus" class="text-xs font-light">{{$tt('Motif')}}: {{ grossiste[activite.id].motif_refus }}</div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            grossistes: null,
            activites: [],

            newForm: null,
            zones: [],

            selectedLine: null,

            loadingAdd: false,
            loadingDelete: false,
            loadingGetActivites: false
        }
    },
    methods: {
        async getActivites() {
            this.loadingGetActivites = true
            let response = await this.$request.post('reparateur.get-activites')
            this.activites = response.data.posts.post
        },

        async getGrossistes() {
            let response = await this.$request.post('reparateur.get-grossiste-agrements')
            this.grossistes = response.data.posts.post
            this.loadingGetActivites = false
        },

        async getZones() {
            let response = await this.$request.post('reparateur.get-zones')
            this.zones = response.data.posts.post
        },

        async editGrossistes() {
            let hasAgrement = false
            for (let index in this.newForm.activites) {
                if (this.newForm.activites[index].agree == 1) {
                    hasAgrement = true
                }
            }
            if (!hasAgrement) {
                this.$toast.error(this.$tt('Vous devez avoir au moins un agrément'))
                return
            }
            this.loadingAdd = true
            let response = await this.$request.post('reparateur.edit-agrements', {
                id_connexion: this.newForm.id_connexion,
                data: JSON.stringify(this.newForm.activites)
            })
            if (response.data.posts.post == 1) {
                await this.getGrossistes()
                this.$toast.success(this.$tt('Enregistré'))
                this.$modal.hide('modal-add-marque')
            }
            this.loadingAdd = false
        },

        displayModalAdd() {
            this.newForm = {
                marque: null,
                activites: {}
            }

            this.activites.forEach((item) => {
                this.newForm.activites[item.id] = {
                    agree: 0,
                    iad: 0
                }
            })

            this.$modal.show('modal-add-marque')
        },

        async saveForm() {
            if (!this.$validation.check([
                {value: this.newForm.marque, rules: 'required', id: 'inp-marque'},
            ])) {
                this.$toast.error(this.$tt('Formulaire incomplet'))
                return
            }

            let hasAgrement = false
            for (let index in this.newForm.activites) {
                if (this.newForm.activites[index].agree == 1) {
                    hasAgrement = true
                }
            }
            if (!hasAgrement) {
                this.$toast.error(this.$tt('Vous devez avoir au moins un agrément'))
                return
            }

            this.loadingAdd = true
            let response = await this.$request.post('reparateur.add-marque', {
                id_marque: this.newForm.marque,
                data: JSON.stringify(this.newForm.activites)
            })
            if (response.data.posts.post == 1) {
                await this.getGrossistes()
                this.$toast.success(this.$tt('Marque ajoutée'))
                this.$modal.hide('modal-add-marque')
            }
            this.loadingAdd = false
        },

        displayModalEdit(grossiste) {
            let endData = JSON.parse(JSON.stringify({
                marque: grossiste.grossiste,
                activites: grossiste,
                id_connexion: grossiste.id_connexion
            }))
            delete endData.activites.grossiste
            delete endData.activites.id_connexion
            
            this.newForm = endData
            this.$modal.show('modal-add-marque')
        },

        async deleteLine() {
            this.loadingDelete = true
            let response = await this.$request.post('reparateur.remove-agrements', {
                id_line: this.selectedLine.grossiste.id
            })
            if (response.data.posts.post == 1) {
                await this.getGrossistes()
                this.$modal.hide('modal-confirm-delete')
                this.$toast.success(this.$tt('Supprimé'))
            }
            this.loadingDelete = false
        }
    },
    async mounted () {
        await this.getActivites()
        this.getGrossistes()
        this.getZones()
    },
}
</script>