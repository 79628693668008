<template>
    <div>
        <div class="p-2" v-if="loaderGetMessages"><spinner :size="10" /></div>
        <template v-if="!loaderGetMessages">
            <div id="container" class="mt-2 mb-4 max-h-[300px] overflow-y-auto">
                <div
                v-for="(msg, msgIndex) in messages"
                :key="'complement-msg-' + msgIndex"
                class="p-4 mb-2 rounded-lg w-3/4 text-sm"
                :class="(admin && msg.qui == 0) || (!admin && msg.qui != 0) ? 'bg-slate-100' : 'bg-blue-200 ml-auto'"
                >
                    <div class="text-xs font-thin">{{ msg.date | momentDateTime }}</div>
                    <div v-html="msg.texte"></div> 
                </div>
            </div>
            <div class="-mt-3 mb-4 text-xs font-light text-right" v-if="admin && messages && messages.length > 0 && messages[messages.length - 1].qui != 0 && messages[messages.length - 1].lu == 1">
                <i class="fa-solid fa-eye mr-0.5"></i> Ouvert le {{ messages[messages.length - 1].lu_le | momentDateTime }}
            </div>
            <div class="bg-slate-100 text-xs rounded p-4 mb-4 text-center" v-if="messages && messages.length <= 0"><i class="fas fa-info-circle mr-1"></i> Envoyer un message pour lancer la discussion</div>
        </template>
        <input-rule placeholder="Ecrire un message.." v-model="newMsg" class="mt-2" type="textarea" :options="{rows: 3}" :label-inline="false" id="inp-rejet" />
        <div class="text-center">
            <button :disabled="loaderSending" @click="sendMessage" class="mt-2 bg-slate-800 hover:bg-slate-900 text-white duration-200 px-4 py-2 rounded text-sm">
                <spinner v-if="loaderSending" :size="10" color="#fff" />
                <template v-if="!loaderSending">
                    Envoyer <i class="fa-solid fa-paper-plane ml-1"></i>
                </template>
            </button>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props: {
        claimId: {required: true},
        admin: {required: true},
    },
    data() {
        return {
            newMsg: null,
            messages: [],

            loaderGetMessages: false,
            loaderSending: false,
        }
    },
    filters: {
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        async getMessages() {
            let response = await this.$request.post('inscription.get-msg-complement', {
                claim_id: this.claimId,
                admin: this.admin ? 1 : 0
            })
            this.messages = response.data.posts.post

            this.$nextTick(() => {
                this.scrollToBottom()
            })
        },

        async sendMessage() {
            if (!this.newMsg) {
                this.$toast.warning('Vous ne pouvez pas envoyer un message vide')
                return
            }
            this.loaderSending = true
            let message = this.newMsg.replace(/\r?\n/g, '<br />');
            await this.$request.post('inscription.add-msg-complement', {
                claim_id: this.claimId,
                content: message,
                admin: this.admin ? 1 : 0,
                id_user: this.$request.user() ? this.$request.user().account.id : 0,
                id_entite: this.$request.user() ? this.$request.user().account.id_entite : 0,
                type_entite: this.$request.user() ? this.$request.user().account.type_entite : 0,
            })
            this.newMsg = null
            this.loaderSending = false
            this.getMessages()
        },

        scrollToBottom() {
            this.$nextTick(() => {
                var container = document.querySelector("#container");
                if (container) {
                    container.scrollTop = container.scrollHeight;
                }
            })
        }
    },
    async mounted () {
        this.loaderGetMessages = true
        await this.getMessages()
        this.loaderGetMessages = false
        setTimeout(() => {
            this.scrollToBottom()
        }, 50)
        setInterval(() => {
            this.getMessages()
        }, 20000)
    },
}
</script>

<style lang="scss" scoped>

</style>