<template>
    <div>
        <modal name="modal-edit-user" height="auto" :scrollable="true">
            <div v-if="selectedLine" class="p-10" style="z-index: 999 !important;">
                <div class="flex items-center border-b pb-4 mb-2 justify-between">
                    <h2 class="text-xl font-bold">{{$tt('Utilisateur')}}</h2>
                    <button class="cursor-pointer" @click="$modal.hide('modal-edit-user')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <input-rule type="checkbox" v-model="selectedLine.actif" :label="$tt('Compte actif')" :label-inline="false" class="mb-4" id="inp-identifiant" />
                <input-rule type="text" v-model="selectedLine.email" :label="$tt('Identifiant')" :label-inline="false" class="mb-4" id="inp-identifiant" />
                <input-rule type="text" v-model="selectedLine.contact_mail" :label="$tt('Email de contact')" :label-inline="false" class="mb-4" id="inp-contact_email" />
                <button :disabled="loadingModal" @click="editUser" class="mx-auto block bg-blue-600 hover:bg-blue-700 duration-200 text-white font-semibold cursor-pointer rounded-xl text-sm mt-4 px-4 py-2.5">
                    <template v-if="!loadingModal">
                        <template v-if="!selectedLine.id">
                            <i class="fas fa-plus mr-0.5"></i> {{$tt('Ajouter')}}
                        </template>
                        <template v-if="selectedLine.id">
                            <i class="fas fa-check mr-0.5"></i> {{$tt('Modifier')}}
                        </template>
                    </template>
                    <template v-if="loadingModal">
                        <spinner :size="10" />
                    </template>
                </button>
            </div>
        </modal>

        <modal name="modal-new-password" height="auto" :scrollable="true">
            <div v-if="selectedLine" class="p-10" style="z-index: 999 !important;">
                <div class="flex items-center border-b pb-4 mb-2 justify-between">
                    <h2 class="text-xl font-bold">{{$tt('Nouveau mot de passe')}}</h2>
                    <button class="cursor-pointer" @click="$modal.hide('modal-new-password')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div class="font-bold p-4 mt-4 rounded bg-slate-100">
                    {{$tt('Souhaitez-vous envoyer un lien de réinitialiser de mot de passe à')}} <u>{{ selectedLine.contact_mail }}</u> ?
                </div>
                <button :disabled="loadingModal" @click="resetPassword" class="mx-auto block bg-orange-600 hover:bg-orange-700 duration-200 text-white border-emerald-600 font-semibold cursor-pointer rounded-xl text-sm mt-4 px-4 py-2">
                    <template v-if="!loadingModal">
                        <i class="fas fa-check mr-0.5"></i> {{$tt('Envoyer')}}
                    </template>
                    <template v-if="loadingModal">
                        <spinner :size="10" />
                    </template>
                </button>
            </div>
        </modal>

        <app-template>
            <div class="bg-white rounded-xl border shadow-sm p-4">
                <div class="py-4" v-if="loadingGetData">
                    <div class="flex justify-center">
                        <spinner :size="12" />
                    </div>
                    <div class="mt-5 text-sm font-extralight text-center">{{$tt('Chargement des données')}}</div>
                </div>
                <template v-if="!loadingGetData">
                    <div class="flex items-center gap-4 justify-between mb-2">
                        <h2 class="text-2xl font-semibold mb-4 mt-2"><i class="fas fa-users mr-1"></i> {{$tt('Gestion utilisateurs')}}</h2>
                        <button @click="selectedLine = {
                            actif: true,
                            type_entite: $request.user().account.type_entite,
                            id_entite: $request.user().account.id_entite
                        }; $modal.show('modal-edit-user')" class="rounded px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 cursor-pointer text-white text-sm">
                            {{ $tt('Ajouter un compte') }} <i class="fas fa-plus ml-1"></i>
                        </button>
                    </div>
                    <data-table
                    v-if="users"
                    :filters-inline="false"
                    :filters="[
                        {label: $tt('Identifiant'), key: 'email', type: 'text'},
                        {label: $tt('Email de contact'), key: 'contact_mail', type: 'text'},
                    ]"
                    :columns="[
                        {label: $tt('Identifiant'), key: 'email', type: 'text'},
                        {label: $tt('Compte actif'), key: 'actif', type: 'boolean'},
                        {label: $tt('Email de contact'), key: 'contact_mail', type: 'text'},
                    ]"
                    :lines="users"
                    >
                        <template v-slot:actions='{line}'>
                            <button @click="selectedLine = {...line}; $modal.show('modal-edit-user')" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                                <i class="fas fa-pen"></i>
                            </button>
                            <button @click="displayModalResetPassword(line)" class="mr-1 text-xs bg-orange-500 hover:bg-orange-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                                <i class="fas fa-rotate"></i>
                            </button>
                        </template>
                    </data-table>
                    <div class="mt-10" v-if="$request.user().account.type_entite == 2 && users && users.shops">
                        <h2 class="text-xl font-bold mb-4">{{$tt('Identifiants magasin')}}</h2>
                        <data-table
                        :filters-inline="false"
                        :filters="[
                            {label: $tt('Identifiant'), key: 'email', type: 'text'},
                            {label: $tt('Email de contact'), key: 'contact_mail', type: 'text'},
                        ]"
                        :columns="[
                            {label: $tt('Identifiant'), key: 'email', type: 'text'},
                            {label: $tt('Email de contact'), key: 'contact_mail', type: 'text'},
                        ]"
                        :lines="users.shops"
                        >
                            <template v-slot:actions='{line}'>
                                <button @click="selectedLine = {...line}; $modal.show('modal-edit-user')" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                                    <i class="fas fa-pen"></i>
                                </button>
                                <button @click="displayModalResetPassword(line)" class="mr-1 text-xs bg-orange-500 hover:bg-orange-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                                    <i class="fas fa-rotate"></i>
                                </button>
                            </template>
                        </data-table>
                    </div>
                </template>
            </div>
        </app-template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            users: null,

            selectedLine: null,

            loadingGetData: false,
            loadingModal: false,
        }
    },
    methods: {
        async getUsers() {
            this.loadingGetData = true
            let response = await this.$request.post('user.admin-client.list', {})
            this.users = response.data.posts.post.entite.map((item) => ({...item, actif: !item.blocked}))
            this.loadingGetData = false
        },

        async editUser() {
            if (!this.$validation.check([
                {value: this.selectedLine.email, rules: 'required', id: 'inp-identifiant'},
                {value: this.selectedLine.contact_mail, rules: 'required|email', id: 'inp-contact_email'},
            ])) {return}

            let urlOperation = 'client.add-user'
            if (this.selectedLine.id) {
                urlOperation = 'client.edit-user'
                this.selectedLine.line_id = this.selectedLine.id
                delete this.selectedLine.id
            }
            this.selectedLine.blocked = !this.selectedLine.actif ? 1 : 0
            delete this.selectedLine.actif
            let response = await this.$request.post(urlOperation, this.selectedLine)
            if (response.data.posts.post && (response.data.posts.post.id || response.data.posts.post == 1)) {
                this.$modal.hide('modal-edit-user')
                if (this.selectedLine.line_id) {
                    this.$toast.success(this.$tt('Utilisateur modifié'))
                } else {
                    this.$toast.success(this.$tt('Utilisateur ajouté'))
                }
                await this.getUsers()
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
        },

        displayModalResetPassword(user) {
            if (!user.contact_mail) {
                this.$toast.error(this.$tt("Cet utilisateur n'a pas d'email de contact"))
                return
            }
            this.selectedLine = {...user}
            this.$modal.show('modal-new-password')
        },

        async resetPassword() {
            this.loadingModal = true
            await this.$request.post('inscription.send-reset-password', {
                account_id: this.selectedLine.id,
                contact_mail: this.selectedLine.contact_mail
            })
            this.loadingModal = false
            this.$modal.hide('modal-new-password')
            this.$toast.success(this.$tt('Un lien a été envoyé'))
        }
    },
    mounted () {
        this.getUsers()
    },
}
</script>

<style lang="scss" scoped>

</style>