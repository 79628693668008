import axios from 'axios'

function getUrl() {
    let url = process.env.VUE_APP_API_FILE_URL
    let currentDomainName = window.location.hostname
    let configSpace = localStorage['space-conf'] ? JSON.parse(localStorage['space-conf']) : null;

    if ((configSpace && configSpace.demo) || currentDomainName == 'localhost') {
        url = process.env.VUE_APP_API_DEMO_FILE_URL
    }
    return url
}

var myaxiosUpload = axios.create({
    baseURL: getUrl()
})

export default myaxiosUpload
