<template>
    <div>
        <label class="block mb-2 font-semibold text-gray-900">{{ index }}. {{ $tt('Quel est votre pays ?') }}</label>
        <template v-if="!foundedCountry">
            <input-rule @input="confirmCountry" v-model="selectedCountry" type="vselect" :options="{values: countries, label: 'name'}" id="get-country" :label-inline="false" />
        </template>
        <template v-if="foundedCountry && selectedCountry">
            <div class="flex items-center justify-between bg-slate-100 rounded p-2 px-4">
                <span class="text-sm">{{ selectedCountry.name }}</span>
                <button @click="foundedCountry = false; selectedCountry = null" class="flex items-center text-sm bg-slate-300 hover:bg-slate-400 duration-200 px-3 py-1.5 rounded">
                    {{ $tt('Modifier') }}
                    <i class="fas fa-pen ml-1"></i>
                </button>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        index: {},
        default: {}
    },
    data() {
        return {
            selectedCountry: null,
            foundedCountry: false,

            countries: []
        }
    },
    computed: {
        countriesList() {
            return [{"code":"CH","name":this.$tt("Suisse")},{"code":"MC","name":this.$tt("Monaco")},{"code":"UK","name":this.$tt("Royaume Uni")},{"code":"DE","name":this.$tt("Allemagne")},{"code":"AT","name":this.$tt("Autriche")},{"code":"BE","name":this.$tt("Belgique")},{"code":"BG","name":this.$tt("Bulgarie")},{"code":"CA","name":this.$tt("Canada")},{"code":"CY","name":this.$tt("Chypre")},{"code":"HR","name":this.$tt("Croatie")},{"code":"DK","name":this.$tt("Danemark")},{"code":"ES","name":this.$tt("Espagne")},{"code":"EE","name":this.$tt("Estonie")},{"code":"FI","name":this.$tt("Finlande")},{"code":"FR","name":this.$tt("France")},{"code":"HU","name":this.$tt("Hongrie")},{"code":"IE","name":this.$tt("Irlande")},{"code":"IT","name":this.$tt("Italie")},{"code":"LV","name":this.$tt("Lettonie")},{"code":"LT","name":this.$tt("Lituanie")},{"code":"LU","name":this.$tt("Luxembourg")},{"code":"MT","name":this.$tt("Malte")},{"code":"MA","name":this.$tt("Maroc")},{"code":"NL","name":this.$tt("Pays-Bas")},{"code":"PL","name":this.$tt("Pologne")},{"code":"PT","name":this.$tt("Portugal")},{"code":"RO","name":this.$tt("Roumanie")},{"code":"SK","name":this.$tt("Slovaquie")},{"code":"SI","name":this.$tt("Slovénie")},{"code":"SE","name":this.$tt("Suède")},{"code":"FR","name":this.$tt("Tchèque France")},{"code":"CZ","name":this.$tt("Tchèque Republic")}]
        }
    },
    methods: {
        confirmCountry() {
            if (this.selectedCountry) {
                this.foundedCountry = true
                this.$emit('input', this.selectedCountry)
            }
        }
    },
    mounted () {
        let dynamicSort = (property) => {
            var sortOrder = 1;
            if(property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a,b) {
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        }

        this.countries = this.countriesList.sort(dynamicSort("name"))
    },
}
</script>

<style lang="scss" scoped>

</style>
