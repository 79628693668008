<template>
    <div>
        <div class="flex items-center justify-center" v-if="loading">
            <spinner :size="10" />
        </div>
        <template v-if="nextAmount && period && !loading">
            <div class="grid grid-cols-2 gap-4">
                <div>
                    <div class="flex items-center justify-between gap-2">
                        <div>
                            <div class="font-bold text-2xl">Prochaine échéance: {{ period.next | momentDate }}</div>
                            <div v-if="period.periodicite == 1" class="text-lg font-light my-1">Facturation tout les mois</div>
                            <div v-if="period.periodicite != 1" class="text-lg font-light my-1">Facturation tout les {{ period.periodicite }} mois</div>
                        </div>
                    </div>
                </div>
                <div>
                    <admin-client-price-details :details="nextAmount.details" :total-saved="nextAmount.total_saved" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import moment from 'moment'

import AdminClientPriceDetails from './AdminClientPriceDetails.vue'

export default {
    components: {
        AdminClientPriceDetails,
    },
    data() {
        return {
            period: null,
            nextAmount: null,
            loading: false,
        }
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        async getNextAmount() {
            this.loading = true
            let response = await this.$request.post('facturation.simulation', {
                start_period: moment().format('MM/YYYY'),
                end_period: moment().format('MM/YYYY'),
            })
            this.nextAmount = response.data.posts.post
            this.loading = false
        },

        async getPeriod() {
            this.loading = true
            let response = await this.$request.post('facturation.get-period')
            this.period = response.data.posts.post
            this.loading = false
        }
    },
    mounted () {
        this.getPeriod()
        this.getNextAmount();

        this.$eventHub.$on('refresh-facture-details', () => {
            this.getNextAmount()
        })
    },
}
</script>

<style lang="scss" scoped>

</style>