<template>
    <div>
        <div class="text-sm block w-full text-gray-900 rounded-lg">
            <vue-google-autocomplete
            class="w-full p-1.5 focus:outline-none rounded-lg"
            :class="custom"
            :ref="'google-address-' + customId"
            :id="customId"
            classname="form-control"
            :placeholder="placeholder || ''"
            v-on:placechanged="getAddressData"
            :country="[countryCode]"
            ></vue-google-autocomplete>
        </div>
    </div>
</template>

<script>
import MapService from '@/services/map.service'

import VueGoogleAutocomplete from "vue-google-autocomplete";
import countriesList from './../json/countries.json'

export default {
    props: {
        default: {},
        placeholder: {},
        country: {default: "fr"},
        custom: {}
    },
    components: {
        VueGoogleAutocomplete
    },
    data() {
        return {
            address: null
        }
    },
    computed: {
        customId() {
            return 'map-' + this._uid
        },
        countryCode() {
            if (this.country) {
                if (this.country.length == 2) {
                    return this.country
                } else {
                    let code = countriesList.find((item) => item.name.toLowerCase() == this.country.toLowerCase())
                    return code ? code.code : 'FR'
                }
            }
            return 'FR'
        }
    },
    methods: {
        getAddressData: function (data, fullData) {
            let shortCode = fullData.address_components.find((component) => component.types.find((item) => item == 'country'))
            let ville = data.locality
            if (!ville) {
                fullData.address_components.forEach((item) => {
                    if (item.types.find((type) => type == 'sublocality')) {
                        ville = item.long_name
                    }
                })
            }
            this.address = {
                numero: data.street_number,
                rue: data.route,
                adresse1: (data.street_number || '') + ' ' + (data.route || ''),
                adresse2: '',
                cp: data.postal_code,
                ville: ville,
                longitude: data.longitude,
                latitude: data.latitude,
                pays: data.country,
                code_pays: shortCode.short_name,
            }
            this.emitData()
        },
        
        emitData() {
            this.$emit('input', this.address)
        },
    },
    mounted () {
        if (this.default) {
            this.address = {}
            this.address.numero = this.default.numero || null
            this.address.rue = this.default.rue || null
            this.address.adresse1 = this.default.adresse1 || null
            this.address.adresse2 = this.default.adresse2 || null
            this.address.cp = this.default.cp || null
            this.address.ville = this.default.ville || null
            this.address.longitude = this.default.longitude || null
            this.address.latitude = this.default.latitude || null
            if (this.default.pays) {
                if (this.default.pays.length == 2) {
                    let country = countriesList.find((item) => item.code.toLowerCase() == this.default.pays.toLowerCase())
                    this.address.pays = country.name
                } else {
                    this.address.pays = this.default.pays
                }
            } else if (this.default.code_pays) {
                let country = countriesList.find((item) => item.code.toLowerCase() == this.default.code_pays.toLowerCase())
                if (country) {
                    this.address.pays = country.name
                }
            } else {
                this.address.pays = 'France'
            }
            if (this.default.adresse1 && this.default.ville) {
                this.$nextTick(() => {
                    document.getElementById(this.customId).value = this.address.adresse1 + ', ' + this.address.ville + ', ' + this.address.pays
                    this.emitData()
                })
            }
            if (!this.address.longitude || !this.address.latitude) {
                this.$nextTick(async () => {
                    let responseAddresses = await MapService.search(this.address.adresse1 + ', ' + this.address.ville + ', ' + this.address.pays, this.address.pays)
                    let addresses = responseAddresses
                    addresses = responseAddresses.filter((address) => address.types.includes('premise') || address.types.includes('street_address'))
                    if (addresses[0]) {
                        let findAddress = (keyword, type = 'long_name') => {
                            let component = addresses[0].address_components.find((address) => address.types.includes(keyword))
                            return component?.[type]
                        }
                        this.address = {
                            numero: findAddress('street_number'),
                            rue: findAddress('route'),
                            adresse1: findAddress('street_number') ? (findAddress('street_number') + ' ' + findAddress('route')) : addresses[0].address_components[0].long_name,
                            adresse2: '',
                            cp: findAddress('postal_code'),
                            ville: findAddress('locality'),
                            pays: findAddress('country', 'short_name'),
                            longitude: addresses[0].geometry.location.lng(),
                            latitude: addresses[0].geometry.location.lat(),
                        }
                        this.emitData()
                    }
                })
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
