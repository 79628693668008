<template>
    <div>
        <app-template>
            <div class="bg-white p-5 rounded-xl border shadow-sm mb-16">
                <div class="grid grid-cols-5 gap-10">
                    <div class="col-span-2">
                        <div class="bg-slate-100 rounded-xl p-4 border shadow">
                            <h2 class="font-bold text-lg"><i class="fas fa-folder-plus mr-1"></i> {{ $tt('Envoyer un dossier') }}</h2>
                            <input-rule v-model="refDossierInp" type="text" class="mt-4" label="Numéro de dossier (Référence)" :label-inline="false" />
                            <button @click="sendFolder" :disabled="loadingSendFolder" class="px-5 py-2.5 font-bold bg-green-600 hover:bg-green-700 duration-200 rounded-xl text-white mt-4 text-sm">
                                <spinner v-if="loadingSendFolder" :size="10" />
                                <template v-if="!loadingSendFolder">
                                    <i class="fas fa-check mr-1"></i> Envoyer
                                </template>
                            </button>
                        </div>
                    </div>
                    <div class="col-span-3 p-4">
                        <h2 class="font-bold text-lg"><i class="fas fa-clock-rotate-left mr-1"></i> {{ $tt('Historique des actions') }}</h2>
                        <div class="max-h-[2500px] overflow-y-auto mt-2">
                            <spinner :size="10" v-if="loadingGetLogs" />
                            <div class="py-2 border-t-2 flex items-center justify-between" v-for="(log, logIndex) in logs" :key="'log-' + logIndex">
                                <div>
                                    <div class="flex items-center gap-2 w-[150px] rounded-xl text-xs" v-if="log.statut == 1">
                                        <div class="w-[10px] h-[10px] rounded-full bg-green-600"></div>
                                        <div class="text-green-600 font-bold">Dossier renvoyé</div>
                                    </div>
                                    <div class="flex items-center gap-2 w-[150px] rounded-xl text-xs" v-if="log.statut == 0">
                                        <div class="w-[10px] h-[10px] rounded-full bg-red-700"></div>
                                        <div class="text-red-700 font-bold">Erreur survenue</div>
                                    </div>
                                    <div>
                                        <ul class="list-disc ml-4 mt-1">
                                            <li class="ml-4 text-xs font-light" v-for="(msg, msgIndex) in log.messages" :key="'msg-' + msgIndex">{{ msg }}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <div class="ml-2 text-sm">
                                        Dossier: {{ log.folder_ref }}
                                    </div>
                                    <div class="text-xs font-light">{{ log.created_at | momentDateTime }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    data() {
        return {
            logs: [],
            refDossierInp: null,

            loadingGetLogs: false,
            loadingSendFolder: false,
        }
    },
    filters: {
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        async getLogs() {
            this.loadingGetLogs = true
            let response = await this.$request.post('ecosystem.get-resend-logs')
            this.logs = response.data.posts.post
            this.loadingGetLogs = false
        },
        async sendFolder() {
            this.loadingSendFolder = true
            await this.$request.post('ecosystem.resend-error-folder', {
                ref_dossier: this.refDossierInp
            })
            this.loadingSendFolder = false
            this.refDossierInp = null
            this.getLogs()
        }
    },
    mounted () {
        this.getLogs()
    },
}
</script>

<style lang="scss" scoped>

</style>