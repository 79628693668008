<template>
    <div>
        <modal name="modal-refuse-connexion" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedConnexion">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-xl">
                    <h2 class="font-bold">{{ selectedConnexion.centrale.centrale_ha }}</h2>
                    <button @click="$modal.hide('modal-refuse-connexion')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <input-rule v-model="motifRejet" type="textarea" label="Motif de rejet" :label-inline="false" id="motif-rejet" />
                    <button @click="refuseConnexion" :disabled="selectedConnexion.loading" class="px-4 py-2 bg-red-500 hover:bg-red-600 duration-200 rounded mt-4 text-white text-sm">
                        <spinner v-if="selectedConnexion.loading" :size="10" />
                        <template v-if="!selectedConnexion.loading">
                            Refuser <i class="fas fa-check ml-1"></i>
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <modal name="modal-add-connexion" height="auto" width="70%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-xl">
                    <h2>Ajouter une connexion</h2>
                    <button @click="$modal.hide('modal-add-connexion')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div><input-rule type="pcheck" v-model="newConnexion.actif" label="Connexion active" :label-inline="false" class="mb-4" /></div>

                    <input-rule
                    v-model="newConnexion.centrale"
                    type="api-select"
                    :options="{
                        api: {action: 'centrale.search', apiName: 'api2', options: {limit: 500, orderBy: 'centrale_ha'}},
                        label: 'centrale_ha',
                    }"
                    :label-inline="false"
                    label="Centrale d'achat"
                    class="mb-4"
                    id="inp-centrale-ha"
                    />
                    <div><input-rule type="price" v-model="newConnexion.prix" label="Coût par mois" :label-inline="false" class="mb-4" /></div>
                    <div v-if="newConnexion.centrale && newConnexion.centrale.is_big"><input-rule type="text" v-model="newConnexion.nb_dossier_estimation" label="Estimation nombre de dossier" :label-inline="false" class="mb-4" /></div>
                    <div><input-rule type="text" v-model="newConnexion.nb_dossier" label="Nombre de dossier max mensuel" :label-inline="false" class="mb-4" /></div>
                    <div><input-rule type="text" v-model="newConnexion.frais_prod" label="Coût mise en production" :label-inline="false" class="mb-4" /></div>
                    <div class="text-center mt-8">
                    <button :disabled="loadingEdit" @click="addConnexion" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 text-white rounded">
                        <spinner v-if="loadingEdit" :size="10" />
                        <template v-if="!loadingEdit">
                            Enregistrer <i class="fas fa-check ml-1"></i>
                        </template>
                    </button>
                </div>
                </div>
            </div>
        </modal>

        <div v-if="loadingGetdata" class="p-4 flex justify-center">
            <spinner :size="10" />
        </div>
        <template v-if="!loadingGetdata">
            <div v-if="connexionsValidation && connexionsValidation.length > 0" class="mb-8 bg-orange-100 p-4 rounded-xl">
                <h2 class="font-bold text-xl mb-3 mt-1 text-orange-800"><i class="fas fa-exclamation-triangle mr-1"></i> A valider</h2>
                <table class="w-full text-sm text-left rtl:text-right">
                    <thead class="text-xs text-orange-900 uppercase bg-orange-300">
                        <tr>
                            <th scope="col" class="px-4 py-3">Date</th>
                            <th scope="col" class="px-4 py-3">Centrale Achat</th>
                            <th scope="col" class="px-4 py-3">Dossiers estimation</th>
                            <th scope="col" class="px-4 py-3">Dossiers max/mois</th>
                            <th scope="col" class="px-4 py-3">Coût par mois</th>
                            <th scope="col" class="px-4 py-3">Frais prod</th>
                            <th scope="col" class="px-4 py-3">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(connexion, connexionIndex) in connexionsValidation" :key="'connexion-' + connexionIndex" class="bg-white border-b duration-200">
                            <td class="px-4 py-2">
                                <div class="text-slate-900">{{ connexion.demande_le | momentDateTime }}</div>
                            </td>
                            <td class="px-4 py-2">
                                <div class="font-bold text-slate-900">{{ connexion.centrale.centrale_ha }}</div>
                            </td>
                            <td class="px-4 py-2">
                                <div class="w-[80px]"><input-rule type="text" v-model="connexion.nb_dossier_estimation" :label-inline="false" /></div>
                            </td>
                            <td class="px-4 py-2">
                                <div class="w-[80px]"><input-rule type="text" v-model="connexion.nb_dossier" :label-inline="false" /></div>
                            </td>
                            <td class="px-4 py-2">
                                <div class="w-[80px]"><input-rule type="price" v-model="connexion.prix" :label-inline="false" /></div>
                            </td>
                            <td class="px-4 py-2">
                                <div class="w-[100px]"><input-rule type="price" v-model="connexion.frais_prod" :label-inline="false" /></div>
                            </td>
                            <td class="px-4 py-2 gap-2 flex">
                                <spinner v-if="connexion.loading" :size="10" />
                                <template v-if="!connexion.loading">
                                    <button @click="validConnexion(connexion)" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 text-white rounded text-xs"><i class="fas fa-check mr-0.5"></i> Valider</button>
                                    <button @click="selectedConnexion = connexion; $modal.show('modal-refuse-connexion')" class="px-4 py-2 bg-red-500 hover:bg-red-600 duration-200 text-white rounded text-xs"><i class="fas fa-times mr-0.5"></i> Refuser</button>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <button @click="$modal.show('modal-add-connexion')" class="mb-4 px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 text-sm rounded">Ajouter une connexion <i class="fas fa-plus ml-1"></i></button>
            <div class="max-h-[500px] overflow-y-auto">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 border">
                    <thead class="text-xs text-gray-700 uppercase bg-slate-50">
                        <tr class="border">
                            <th scope="col" class="px-4 py-3">Actif</th>
                            <th scope="col" class="px-4 py-3">Premium</th>
                            <th scope="col" class="px-4 py-3">Centrale Achat</th>
                            <th scope="col" class="px-4 py-3">Dossiers max/mois</th>
                            <th scope="col" class="px-4 py-3">Coût par mois</th>
                            <th scope="col" class="px-4 py-3">Frais prod</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(connexion, connexionIndex) in connexionsList" :key="'connexion-' + connexionIndex" class="bg-white border-b hover:bg-slate-50 duration-200" :class="!connexion.actif ? 'opacity-30' : ''">
                            <td class="px-4 py-2">
                                <div><input-rule type="pcheck" v-model="connexion.actif" :label-inline="false" /></div>
                            </td>
                            <td class="px-4 py-2">
                                <span v-if="connexion.grossiste_pay" class="bg-yellow-200 rounded text-xs font-bold py-1 px-1.5 text-slate-800"><i class="fas fa-star mr-0.5"></i> Premium</span>
                                <span v-if="!connexion.grossiste_pay" class="bg-slate-200 rounded text-xs py-1 px-1.5 text-slate-800"><i class="fas fa-user mr-0.5"></i> Invité</span>
                            </td>
                            <td class="px-4 py-2">
                                <div class="font-bold text-slate-900">{{ connexion.centrale.centrale_ha }}</div>
                            </td>
                            <td class="px-4 py-2">
                                <div class="w-[80px]"><input-rule type="text" v-model="connexion.nb_dossier" :label-inline="false" /></div>
                            </td>
                            <td class="px-4 py-2">
                                <div class="w-[80px]"><input-rule type="price" v-model="connexion.prix" :label-inline="false" /></div>
                            </td>
                            <td class="px-4 py-2">
                                <span v-if="!connexion.frais_prod" class="bg-slate-200 rounded text-xs py-1 px-2 text-slate-800">0 €</span>
                                <span v-if="connexion.frais_prod" class="bg-orange-300 rounded text-sm font-bold py-1 px-2 text-slate-800">{{ connexion.frais_prod }} €</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-sm font-light mt-2 text-right">{{ connexions.length + (connexions.length > 1 ? ' connexions' : ' connexion') }}</div>
            </div>
            <div class="flex justify-between mt-8">
                <div class="text-xl font-bold">{{ amountTotal }} € / mensuel</div>
                <button :disabled="loadingEdit" @click="saveConnexions" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 text-white rounded">
                    <spinner v-if="loadingEdit" :size="10" />
                    <template v-if="!loadingEdit">
                        Enregistrer <i class="fas fa-check ml-1"></i>
                    </template>
                </button>
            </div>
        </template>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props: {
        client: {required: true},
    },
    data() {
        return {
            connexions: [],
            connexionsList: [],
            connexionsValidation: [],
            newConnexion: {},
            selectedConnexion: null,
            motifRejet: null,

            loadingGetdata: false,
            loadingEdit: false,
        }
    },
    filters: {
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    computed: {
        amountTotal() {
            let amount = 0
            if (this.connexions) {
                this.connexions.forEach((connexion) => {
                    if (connexion.actif == 1) {
                        amount += parseFloat(connexion.prix)
                    }
                })
            }
            return amount.toFixed(2)
        }
    },
    methods: {
        async getConnexions() {
            this.loadingGetdata = true
            let response = await this.$request.post('admin.get-connexions', {
                client_id: this.client.id
            })
            this.loadingGetdata = false
            this.connexions = response.data.posts.post.sort((a,b) => (a.centrale.centrale_ha > b.centrale.centrale_ha) ? 1 : ((b.centrale.centrale_ha > a.centrale.centrale_ha) ? -1 : 0))
            this.connexionsList = this.connexions.filter((item) => !item.validation)
            this.connexionsValidation = this.connexions.filter((item) => item.validation).map((item) => ({...item, loading: false}))
        },

        async saveConnexions() {
            this.loadingEdit = true
            let response = await this.$request.post('admin.set-connexions', {
                connexions: JSON.stringify(this.connexionsList)
            })
            if (response.data.posts.post == 1) {
                this.$toast.success('Connexions modifiées')
            }
            this.loadingEdit = false
        },

        setDefaultConnexion() {
            this.newConnexion = {
                actif: 1,
                prix: 80,
                nb_dossier: 300,
            }
        },

        async addConnexion() {
            this.loadingEdit = true
            let endObj = this.newConnexion
            endObj.client_id = this.client.id
            endObj.centrale_id = endObj.centrale.id
            delete endObj.centrale
            if (!endObj.nb_dossier_estimation) {
                endObj.nb_dossier_estimation = 0
            }
            if (!endObj.frais_prod) {
                endObj.frais_prod = 0
            }
            let response = await this.$request.post('admin.add-connexion', endObj)
            if (response.data.posts.post == 1) {
                await this.getConnexions()
                this.$modal.hide('modal-add-connexion')
                this.setDefaultConnexion()
                this.$toast.success('Connexion ajoutée')
            }
            this.loadingEdit = false
        },

        async validConnexion(connexion) {
            let data = connexion
            data.connexion_id = connexion.id
            data.nb_dossier_estimation = connexion.nb_dossier_estimation ? connexion.nb_dossier_estimation : 0
            delete connexion.id
            connexion.loading = true
            let response = await this.$request.post('admin.valid-connexion', data)
            if (response.data.posts.post == 1) {
                await this.getConnexions()
                this.$toast.success('Connexion validée')
            } else {
                this.$toast.error('Une erreur est survenue')
            }
            connexion.loading = false
        },

        async refuseConnexion() {
            if (!this.$validation.check([
                {value: this.motifRejet, rules: 'required', id: 'motif-rejet'},
            ])) {return}

            this.selectedConnexion.loading = true
            let response = await this.$request.post('admin.refuse-connexion', {
                connexion_id: this.selectedConnexion.id,
                motif: this.motifRejet
            })
            if (response.data.posts.post == 1) {
                await this.getConnexions()
                this.$modal.hide('modal-refuse-connexion')
                this.$toast.success('Connexion refusée')
                this.selectedConnexion = null
                this.motifRejet = null
            } else {
                this.$toast.error('Une erreur est survenue')
            }
            this.selected.loading = false
        }
    },
    mounted () {
        this.getConnexions()
        this.setDefaultConnexion()
    },
}
</script>

<style lang="scss" scoped>

</style>