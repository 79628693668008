<template>
    <div>
        <div class="fixed top-0 bottom-0 left-0 right-0 justify-center flex items-center">
            <div class="grow">
                <div class="sm:w-1/2 lg:w-1/3 mx-auto bg-white rounded-2xl p-10 border shadow">
                    <h1 class="text-3xl font-black text-center">Connexion</h1>
                    <div class="my-5">
                        <label class="text-sm mb-2 block">Utilisateur</label>
                        <input v-model="username" v-on:keyup.enter="sendForm" type="text" class="bg-gray-50 text-sm rounded-lg block w-full p-2.5 focus:outline-none border border-gray-300 text-gray-900" />
                    </div>
                    <div class="my-5">
                        <label class="text-sm mb-2 block">Mot de passe</label>
                        <div class="flex items-center">
                            <input v-model="password" v-on:keyup.enter="sendForm" :type="displayPassword ? 'text' : 'password'" class="bg-gray-50 text-sm rounded-l-lg block w-full p-2.5 focus:outline-none border border-r-0 border-gray-300 text-gray-900" />
                            <button @click="displayPassword = !displayPassword" class="text-sm bg-slate-200 hover:bg-slate-300 cursor-pointer px-3 py-2.5 border rounded-r-lg border-gray-300">
                                <div v-show="!displayPassword"><i class="fas fa-eye"></i></div>
                                <div v-show="displayPassword"><i class="fas fa-eye-slash"></i></div>
                            </button>
                        </div>
                    </div>
                    <div class="text-center">
                        <button :disabled="loading" @click="sendForm" class="p-3 px-6 text-white bg-green-500 hover:bg-green-500 duration-200 rounded-xl text-sm">
                            <spinner v-if="loading" :size="10" />
                            <template v-if="!loading">
                                <i class="fas fa-check mr-1"></i> Se connecter
                            </template>
                        </button>
                        <div class="mt-10 pt-4 border-t">
                            <a class="text-sm font-light hover:underline opacity-70" href="https://auth.districloud.net/mot-de-passe-oublie">Mot de passe oublié ?</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            username: null,
            password: null,

            loading: false,
            displayPassword: false,
        }
    },

    methods: {
        async sendForm() {
            this.loading = true
            let response = await this.$request.post('A5141login', {
                user: this.username,
                pwd: this.password,
                id_grossiste: 82,
                token: '8DROmU1h8uRUqDBdzLHkqJQqEzool125',
                domaine: 'www.districloud.net'
            }, 'districloud_action')

            if (response.data.posts.error == "AUTH_FAILED") {
                this.$toast.error('Identifiants incorrects')
            } else if (response.data.posts.error == "expire") {
                this.$toast.error('Votre compte est expiré')
            } else if (response.data.posts.post && response.data.posts.post.length > 0) {
                localStorage.setItem('account', this.$crypto.encrypt(JSON.stringify({account: response.data.posts.post[0], info: response.data.posts.options.infos})))
                window.location = '/'
            } 
            this.loading = false
        }
    }
}
</script>
