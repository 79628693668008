import ApiService from '@/services/api.service';

async function list() {
    let permissions = localStorage.getItem('permissions')
    if (!permissions) {
        let response = await ApiService.post('account.permissions-list')
        permissions = response.data.posts.post
        localStorage.setItem('permissions', JSON.stringify(permissions))
    } else {
        permissions = JSON.parse(permissions)
    }

    return permissions
}

function hasPermission(keyname) {
    let permissions = localStorage.getItem('permissions')
    if (permissions) {
        permissions = JSON.parse(permissions)
        return permissions.find((permission) => permission == keyname) ? true : false
    }
    return false
}

var PermissionTool = {
    list,
    hasPermission
}

export default PermissionTool
