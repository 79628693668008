var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('input-rule',{staticClass:"text-left mb-4",attrs:{"disabled":_vm.enseigne?.nom,"type":"text","label":_vm.$tt('Nom de l\'enseigne'),"id":"shop-name","label-inline":false},model:{value:(_vm.form.nom),callback:function ($$v) {_vm.$set(_vm.form, "nom", $$v)},expression:"form.nom"}}),_c('input-rule',{staticClass:"text-left mb-4",attrs:{"type":"address2","options":{defaultValue: _vm.enseigne.adresse, country: _vm.country},"custom":"bg-gray-100","label":_vm.$tt('Adresse'),"id":"shop-address","label-inline":false},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('input-rule',{staticClass:"text-left mb-4",attrs:{"type":"text","label":_vm.$tt('Forme juridique'),"label-inline":false,"id":"shop-societe","custom":"bg-gray-100"},model:{value:(_vm.form.type_societe),callback:function ($$v) {_vm.$set(_vm.form, "type_societe", $$v)},expression:"form.type_societe"}}),_c('input-rule',{staticClass:"mb-4",attrs:{"id":"select-grossiste","label-inline":false,"label":_vm.$tt('Avec quelle enseigne travaillez-vous ?'),"type":"api-select","options":{
        api: {action: 'inscription.search-centrale', apiName: 'api2'},
        label: 'centrale_ha',
        init: false,
        min: 3,
        uppercase: true
    }},on:{"inp":_vm.getInpCentrale},model:{value:(_vm.selectedCentrale),callback:function ($$v) {_vm.selectedCentrale=$$v},expression:"selectedCentrale"}}),_c('button',{staticClass:"text-sm flex items-center font-light bg-emerald-600 text-white px-4 py-2 border-emerald-600 rounded-lg",on:{"click":_vm.endForm}},[_vm._v(" "+_vm._s(_vm.$tt('Valider'))+" "),_c('i',{staticClass:"fas fa-check ml-1"})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }