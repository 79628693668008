<template>
    <div>
        <modal name="modal-confirm-export" height="auto" width="70%" :scrollable="true">
            <div class="p-10 text-center">
                <h2 class="font-bold text-3xl mb-5">Confirmer l'export</h2>
                <div class="bg-orange-100 text-orange-500 font-bold p-5 rounded-xl mb-5">
                    <i class="fas fa-triangle-exclamation mr-1"></i> Attention: Une fois l'export généré, vous ne pourrez plus modifier le document, ni revenir en arrière.
                </div>
                <button :disabled="sendLoading" @click="$modal.hide('modal-confirm-export')" class="px-6 py-3 font-bold text-sm duration-100 duration-200 bg-slate-100 border border-slate-200 mr-1 hover:bg-slate-200 rounded-xl">
                    <i class="fas fa-times mr-1"></i> Annuler
                </button>
                <button :disabled="sendLoading" @click="makeExport" class="ml-1 px-6 py-3 font-bold text-white text-sm duration-100 duration-200 bg-green-600 border-green-700 hover:bg-green-700 rounded-xl">
                    <template v-if="!sendLoading">
                        Confirmer l'export <i class="fas fa-check ml-1"></i>
                    </template>
                    <template v-if="sendLoading">
                        <spinner :size="10" />
                    </template>
                </button>
            </div>
        </modal>

        <app-template>
            <template v-slot:submenu>
                <div class="bg-white py-3 sticky top-0 z-10">
                    <img class="h-8 mx-auto" src="https://theme.zdassets.com/theme_assets/2297156/48b2d6fef57068433ac44701fdc73efeee8d433d.png" alt="">
                </div>
                <shopping-feed-menu />
            </template>

            <div v-show="skuFile == 0">
                <div class="bg-white p-10 text-center rounded-xl border shadow mb-2">
                    <h2 class="text-orange-800 font-bold text-2xl">Vous devez ajouter un tableau de correspondance SKU</h2>
                    <div class="mt-5 flex items-center justify-center">
                        <input-file id="addSkuFile" @input="addSkuFile" file-type=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                    </div>
                </div>
            </div>
            <div v-show="clientFile == 0">
                <div class="bg-white p-10 text-center rounded-xl border shadow mb-2">
                    <h2 class="text-orange-800 font-bold text-2xl">Vous devez ajouter un tableau de correspondance Code Client</h2>
                    <div class="mt-5 flex items-center justify-center">
                        <input-file id="addClientFile" @input="addClientFile" file-type=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                    </div>
                </div>
            </div>
            <div v-if="!displayTable && lines" class="bg-white rounded-xl border shadow-sm mt-2 mb-16">
                <div class="overflow-auto max-h-[60vh]">
                    <table class="text-left">
                        <thead class="bg-slate-50">
                            <tr class="border-b">
                                <th v-for="(colHeader, colHeaderIndex) in header" :key="'col-header-' + colHeaderIndex" class="text-xs font-light px-1 py-2">
                                    {{ colHeader.value }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(line, lineIndex) in displayLines" :key="'line-' + lineIndex" class="bg-white border-b">
                                <td class="py-2 px-1" v-for="(col, colIndex) in line" :key="'col-' + colIndex">
                                    <template v-if="!currentConversion.exported_at">
                                        <input @input="addUpdate(col)" class="text-xs rounded-0 p-1.5 border rounded-lg focus:outline-none focus:border-gray-400"  :placeholder="header[colIndex].value" v-model="col.value">
                                    </template>
                                    <template v-else>
                                        <span class="text-xs font-light">{{ col.value }}</span>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="flex justify-between items-center p-4">
                    <pagination
                    v-if="page !== null && lines"
                    v-model="page"
                    :per-page="perPage"
                    :records="lines.length - 1"
                    />
                    <div>
                        <button v-if="!currentConversion.exported_at" :disabled="sendLoading" @click="$modal.show('modal-confirm-export')" class="px-6 py-3 font-bold text-white text-sm duration-100 duration-200 bg-green-600 border-green-700 hover:bg-green-700 rounded-xl">
                            Exporter <i class="fas fa-check ml-1"></i>
                        </button>
                    </div>
                </div>
            </div>
        </app-template>
    </div>
</template>
<script>
import apiService from '@/services/api.service'
import Pagination from 'vue-pagination-2'
import ShoppingFeedMenu from './Components/ShoppingFeedMenu.vue'

export default  {
    components: {
        Pagination,
        ShoppingFeedMenu
    },
    watch: {
        page(value) {
            let start = value * this.perPage
            if (start == 0) {
                start = 1
            }
            this.displayLines = this.lines.slice(start - 2, (start - 2) + this.perPage)
        }
    },
    data() {
        return {
            // Import edition
            lines: null,
            defaultLines: null,
            page: 1,
            perPage: 30,
            displayLines: null,
            header: null,
            updates: [],
            currentConversion: null,
            displayTable: false,

            // Left menu
            skuFile: null,
            clientFile: null,

            // Loaders
            sendLoading: false,
        }
    },
    methods: {
        async addSkuFile(data) {
            // Set data
            try {
                await apiService.post('conversions.add-meta', {
                    meta: data.url,
                    keyname: 'conversion-sku-file'
                })
                this.$toast.success('Fichier mis à jour')
            } catch (error) {
                this.$toast.error('Une erreur est survenue')
                console.error(error);
            }
            // this.getSkuFile()
        },

        async addClientFile(data) {
            // Set data
            try {
                await apiService.post('conversions.add-meta', {
                    meta: data.url,
                    keyname: 'conversion-client-file'
                })
                this.$toast.success('Fichier mis à jour')
            } catch (error) {
                this.$toast.error('Une erreur est survenue')
                console.error(error);
            }
            this.getClientFile()
        },

        async getClientFile() {
            // Set data
            let response = await apiService.post('conversions.get-meta', {
                keyname: 'conversion-client-file'
            })
            this.clientFile = response.data.posts.post || 0
        },

        async makeExport() {
            this.sendLoading = true

            this.updates.forEach((update) => {
                this.defaultLines[update.line][update.cell] = update.value
            })
            let response = await apiService.post('conversions.convert-to-quadra', {
                lines: JSON.stringify(this.defaultLines),
                excel: 1,
                conversion_id: this.currentConversion.id
            })
            try {
                if (response.data.posts.post.file_url) {
                    window.open(response.data.posts.post.file_url, '_blank').focus();
                    this.$eventHub.$emit('refresh-imports', {selectLast: true})
                    this.$eventHub.$emit('refresh-exports')
                    this.displayTable = true
                }
                this.$modal.hide('modal-confirm-export')
                this.$toast.success('Exporté')
                this.sendLoading = false
            } catch (error) {
                this.$toast.error(response.data.posts.error)
                console.error(error);
                this.sendLoading = false
            }
        },

        getImport(conversion) {
            this.page = 1
            this.currentConversion = conversion
            this.defaultLines = JSON.parse(this.currentConversion.content)
            this.lines = []
            let lines = JSON.parse(this.currentConversion.content)
            lines.forEach((line, lineIndex) => {
                let endLine = []
                line.forEach((cell, cellIndex) => {
                    let endCell = {
                        value: cell,
                        line: lineIndex,
                        cell: cellIndex
                    }
                    endLine.push(endCell)
                })
                this.lines.push(endLine)
            })
            this.header = this.lines[0]
            this.displayLines = this.lines.slice(1, this.perPage + 1)
        },

        addUpdate(col) {
            if (!this.updates.find((update) => update.line == col.line && update.cell == col.cell)) {
                this.updates.push({line: col.line, cell: col.cell, value: col.value})
            } else {
                this.updates.find((update) => update.line == col.line && update.cell == col.cell).value = col.value
            }
        },
    },
}
</script>
