<template>
    <div>
        <app-template>
            <!-- Menu -->
            <template v-slot:submenu>
                <div class="p-4 pb-28">
                    <template v-if="filtersValues">
                        <input-rule class="mb-2" v-model="filters.flag" type="select" :options="{values: [{label: 'Demande à traiter', value: 0}, {label: 'Demandes clôturées', value: 1}, {label: 'Demandes refusées', value: 2}, {label: 'Demandes supprimées', value: 3}], label: 'label', key: 'value'}" label="Etat" :label-inline="false" />
                        <input-rule class="mb-2" v-model="filters.ville" type="select" :options="{values: filtersValues.villes}" label="Ville" :label-inline="false" />
                        <input-rule class="mb-2" v-model="filters.enseigne" type="select" :options="{values: filtersValues.enseignes}" label="Enseigne" :label-inline="false" />
                        <button class="text-xs duration-200 cursor-pointer mt-1 mb-2 text-slate-800 bg-slate-100 hover:bg-slate-200 rounded p-1 px-3 font-light" @click="$eventHub.$emit('refresh-claim-list')">
                            <i class="fa-solid fa-arrows-rotate mr-1"></i> Actualiser
                        </button>
                        <paginate-menu-item id="claim-list" :config="{name: 'inscription.list', form: {flag: filters.flag, ville: filters.ville, enseigne: filters.enseigne}}">
                            <template v-slot:item='{item}'>
                                <div @click="changeClaim(item)" class="border-b p-2 cursor-pointer" :class="claim && item.id == claim.id ? 'bg-slate-100' : 'hover:bg-slate-100 duration-200'">
                                    <div class="flex items-center justify-between">
                                        <div class="flex items-center">
                                            <i v-if="item.flag == '1'" class="text-green-500 text-sm fa-solid fa-circle-check mr-2"></i>
                                            <div class="font-bold text-sm">{{ item.nom }}</div>
                                        </div>
                                        <div
                                        class="text-xs font-light capitalize"
                                        :class="
                                        (item.type_ident == 'magasin' && item.meta.enseigne.grossiste ? 'text-orange-500' : '') + ' '
                                        + (item.type_ident == 'magasin' ? 'text-blue-500' : '') + ' '
                                        + (item.type_ident == 'fournisseur' ? 'text-fuchsia-600' : '') + ' '
                                        + (item.type_ident == 'reparateur' ? 'text-green-600' : '') + ' '
                                        "
                                        >
                                            <template v-if="item.type_ident == 'magasin'">
                                                <template v-if="item.meta.enseigne.grossiste">Grossiste</template>
                                                <template v-else>Magasin</template>
                                            </template>
                                            <template v-else>
                                                {{ item.type_ident }}
                                            </template>
                                        </div>
                                    </div>
                                    <div class="flex font-light text-xs mt-1">{{ item.enseigne }} - {{ item.date_dem | momentDateTime }}</div>
                                    <div class="flex items-center gap-2">
                                        <div v-if="item.messages.length > 0" class="px-2 py-0.5 text-xs font-thin mt-0.5 bg-slate-100 rounded">
                                            <template v-if="item.messages[item.messages.length - 1].qui == 0">
                                                <i class="fas fa-comments mr-0.5"></i> Nouveau message
                                            </template>
                                            <template v-if="item.messages[item.messages.length - 1].qui != 0">
                                                <i class="fas fa-comments mr-0.5"></i> Demande de complément en cours
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </paginate-menu-item>
                    </template>
                </div>
            </template>

            <!-- Page content -->
            <div v-if="claim">
                <shop-claim v-if="claim.type_ident == 'magasin'" :data-src="claim" />
                <fournisseur-claim v-if="claim.type_ident == 'fournisseur'" :data-src="claim" />
                <reparateur-claim v-if="claim.type_ident == 'reparateur'" :data-src="claim" />
            </div>
        </app-template>
    </div>
</template>

<script>
import moment from 'moment'
import ShopClaim from './Components/Admin/ShopClaim.vue'
import FournisseurClaim from './Components/Admin/FournisseurClaim.vue'
import ReparateurClaim from './Components/Admin/ReparateurClaim.vue'

export default {
    components: {
        ShopClaim,
        FournisseurClaim,
        ReparateurClaim
    },

    data() {
        return {
            filtersValues: null,
            filters: {
                flag: 0,
                ville: '-',
                enseigne: '-'
            },
            claimsList: [],
            claim: {},
        }
    },

    filters: {
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },

    methods: {
        async getFilters() {
            let response = await this.$request.post('inscription.list-filters')
            this.filtersValues = response.data.posts.post
        },

        changeClaim(claim) {
            if (claim.id != this.claim) {
                if (!claim.claim) {
                    claim.claim = claim.meta
                }
                this.$eventHub.$emit('close-mobile-submenu')
                this.getClaim(claim)
            }
        },

        async getClaim(claim) {
            if (typeof claim.meta == 'string') {
                claim.claim = JSON.parse(claim.meta)
            }
            if (typeof claim.errors == 'string') {
                claim.errors = JSON.parse(claim.errors)
            }

            window.history.replaceState(null, null, '?id=' + claim.id);
            this.claim = claim
        },
    },

    async mounted () {
        await this.getFilters()
        var url = new URL(window.location.href);
        var urlId = url.searchParams.get("id");
        if (urlId) {
            let response = await this.$request.post('inscription.get', {
                line_id: urlId
            })
            this.getClaim(response.data.posts.post);
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
