<template>
    <div>
        <app-template>
            <div class="bg-white p-10 rounded shadow">
                <!-- <crud-content
                modal-width="60%"
                id="access"
                :label="{singular: 'un accès', plural: 'des accès'}"
                ref-keyword="email"
                :default-form-obj="{id: null, newPermissions: [1], group: false}"
                :routes="{
                    list: {key: 'admin.access.users'},
                    editAdd: {operation: 'admin.access.edit-add', form: {'user_line_id': 'id', 'permissions': 'newPermissions'}},
                    delete: {key: 'admin.access.delete'}
                }"
                :table="[
                    {label: 'Id', key: 'id', type: 'text'},
                    {label: 'Type', key: 'type', type: 'text'},
                    {label: 'Email', key: 'email', type: 'text'},
                    {label: 'Id entite', key: 'id_entite', type: 'text'},
                    {label: 'Token', key: 'token', type: 'text'},
                ]"
                :filters="[
                    {label: 'Id entite', key: 'id_entite', type: 'text', value: null},
                ]"
                >
                    <template v-slot:modal-view="props">
                        <account-view :id="props.line.id" :edit="false" />
                    </template>
                    <template v-slot:modal-edit-add="props">
                        <h1 class="text-xl font-bold mb-4">Ajouter un accès</h1>
                        <input-rule
                        :disabled="props.onEdit"
                        @input="(value) => {props.modalForm.id = value.id}"
                        :label-inline="false"
                        placeholder="Rechercher un identifiant utilisateur"
                        type="api-select"
                        class="mb-4"
                        :options="{
                            api: {action: 'user.search', apiName: 'api2', options: {relations: 'entite'}},
                            label: 'email',
                            label2: 'entite.libelle',
                            init: false,
                            min: 3
                        }"
                        />
                        <label class="block mb-2 text-xs font-bold text-gray-900'">Permissions</label>

                        <div class="mb-2" v-for="(permission, permissionIndex) in permissions" :key="'permission-item-' + permissionIndex">
                            <div class="flex items-center gap-2">
                                <input v-model="props.modalForm.newPermissions" :value="permission.id" :id="'permission-input-' + permissionIndex" type="checkbox">
                                <label class="flex items-center gap-2" :for="'permission-input-' + permissionIndex">
                                    <span class="block text-xs text-gray-900'">{{ permission.description }}</span>
                                    <span class="font-thin" style="font-size: 10px;">{{ permission.keyword }}</span>
                                </label>
                            </div>
                        </div>

                        <spinner :size="10" v-if="props.loadingModal" />
                        <template v-if="!props.loadingModal">
                            <button @click="props.sendForm" class="mr-1 text-sm bg-green-500 hover:bg-green-600 duration-200 cursor-pointer font-light px-5 py-2.5 rounded-xl text-white" type="submit">
                                <i class="fas fa-check mr-0.5"></i> Ajouter
                            </button>
                        </template>
                    </template>
                </crud-content> -->

                <!-- Modal view -->
                <modal name="modal-view-account" height="auto" width="60%" :scrollable="true">
                    <div v-if="selectedLine" class="p-10">
                        <account-view :id="selectedLine.id" :edit="false" />
                    </div>
                </modal>

                <!-- Modal edit -->
                <modal name="modal-edit-account" height="auto" width="60%" :scrollable="true">
                    <div v-if="selectedLine" class="p-10">
                        <h1 class="text-xl font-bold mb-4">Ajouter un accès</h1>
                        <input-rule
                        v-if="!selectedLine.id"
                        v-model="userId"
                        :label-inline="false"
                        placeholder="Rechercher un identifiant utilisateur"
                        type="api-select"
                        class="mb-4"
                        :options="{
                            api: {action: 'user.search', apiName: 'api2', options: {relations: 'entite'}},
                            label: 'email',
                            keyTrack: 'id',
                            label2: 'entite.libelle',
                            init: false,
                            min: 3
                        }"
                        />
                        <label class="block mb-2 text-xs font-bold text-gray-900'">Permissions</label>

                        <div class="mb-2" v-for="(permission, permissionIndex) in permissions" :key="'permission-item-' + permissionIndex">
                            <div class="flex items-center gap-2">
                                <input v-model="newPermissions" :value="permission.id" :id="'permission-input-' + permissionIndex" type="checkbox">
                                <label class="flex items-center gap-2" :for="'permission-input-' + permissionIndex">
                                    <span class="block text-xs text-gray-900'">{{ permission.description }}</span>
                                    <span class="font-thin" style="font-size: 10px;">{{ permission.keyword }}</span>
                                </label>
                            </div>
                        </div>

                        <spinner :size="10" v-if="loadingModal" />
                        <template v-if="!loadingModal">
                            <button @click="sendEditAdd" class="mr-1 text-sm bg-green-500 hover:bg-green-600 duration-200 cursor-pointer font-light px-5 py-2.5 rounded-xl text-white" type="submit">
                                <i class="fas fa-check mr-0.5"></i> Ajouter
                            </button>
                        </template>
                    </div>
                </modal>

                <!-- Spinner get data -->
                <div class="p-4" v-if="loadingData">
                    <div class="flex justify-center">
                        <spinner :size="12" />
                    </div>
                    <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
                </div>
                <!-- Page content -->
                <div v-else>
                    <button @click="displayModalEditAccount()" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 cursor-pointer text-sm text-white rounded mb-4">
                        <i class="fas fa-plus mr-1"></i> Ajouter un accès
                    </button>
                    <div class="bg-white p-4 rounded border mb-2" v-for="(user, userIndex) in users" :key="'user-' + userIndex">
                        <div :class="user.open ? 'mb-2' : ''" @click="user.open = !user.open">
                            <div class="flex items-center justify-between">
                                <div>
                                    <div class="font-bold">{{ user.data[0].company_name }}</div>
                                    <div class="font-light text-sm">{{ user.data[0].entite?.libelle }}</div>
                                </div>
                                <template v-if="!user.open">
                                    <i class="fas fa-chevron-right"></i>
                                </template> 
                                <template v-if="user.open">
                                    <i class="fas fa-chevron-down"></i>
                                </template>
                            </div>
                        </div>
                        <div v-if="user.open">
                            <data-table
                            :filters-inline="false"
                            :filters="[
                            ]"
                            :columns="[
                                {label: 'Email', key: 'email', type: 'text'},
                                {label: 'Token', key: 'token', type: 'text'},
                            ]"
                            :lines="user.data"
                            >
                                <template v-slot:actions='{line}'>
                                    <button @click="selectedLine = line; $modal.show('modal-view-account')" class="mr-1 text-xs bg-orange-500 hover:bg-orange-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                                        <i class="fas fa-eye"></i>
                                    </button>
                                    <button @click="displayModalEditAccount(line)" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                                        <i class="fas fa-pen"></i>
                                    </button>
                                </template>
                            </data-table>
                        </div>
                    </div>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import AccountView from '../Magasin/AccountView.vue'

export default {
    components: {
        AccountView
    },

    data() {
        return {
            permissions: [],
            users: [],
            loadingData: false,
            loadingModal: false,
            selectedLine: null,
            userId: null,
            newPermissions: [],
        }
    },

    methods: {
        async init() {
            this.loadingData = true
            await this.getPermissions()
            await this.getUsers()
            this.loadingData = false
        },

        async getPermissions() {
            let response = await this.$request.post('admin.permissions.list', {})
            this.permissions = response.data.posts.post
        },

        async getUsers() {
            let response = await this.$request.post('admin.access.users', {})
            let usersTemp = response.data.posts.post
            let endUsers = []
            for (const user in usersTemp) {
                endUsers.push({
                    data: usersTemp[user],
                    open: false
                })
            }
            this.users = []
            this.$nextTick(() => {
                this.users = endUsers
            })
        },

        selectUser(user) {
            this.props.modalForm.id = user.id
        },

        displayModalEditAccount(line) {
            if (line) {
                this.userId = line.id
                this.selectedLine = line
                this.newPermissions = this.selectedLine.permissions.map((item) => {return item.id})
            } else {
                this.selectedLine = {}
            }
            this.$modal.show('modal-edit-account')
        },

        async sendEditAdd() {
            this.loadingModal = true
            let endObj = {
                user_line_id: this.userId,
                permissions: this.newPermissions
            }
            let response = await this.$request.post('admin.access.edit-add', endObj, 'api2')
            if (response.data.posts.post) {
                await this.getUsers()
                this.$modal.hide('modal-edit-account')
                this.newPermissions = []
                this.selectedLine = null
            }
            this.loadingModal = false
        }
    },

    mounted () {
        this.init();
    },
}
</script>
