<template>
    <div>
        <modal name="modal-display-detail" height="auto" :scrollable="true">
            <div v-if="selectedLine" class="p-10" style="z-index: 999 !important;">
                <div class="text-right mb-4 border-b pb-4 flex items-center justify-between">
                    <h2 class="font-bold text-lg">{{ selectedLine.client.nom }}</h2>
                    <button @click="$modal.hide('modal-display-detail')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <admin-client-price-details :details="selectedLine.details.details" :total-saved="selectedLine.details.total_saved" />
            </div>
        </modal>

        <modal name="modal-display-history" height="auto" :scrollable="true" width="60%">
            <div v-if="selectedLine" class="p-10" style="z-index: 999 !important;">
                <div class="text-right mb-4 border-b pb-4 flex items-center justify-between">
                    <h2 class="font-bold text-lg">Historique des montants</h2>
                    <button @click="$modal.hide('modal-display-history')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <admin-client-price-history :client-id="selectedLine.client.id" />
            </div>
        </modal>

        <app-template>
            <div class="bg-white rounded-xl border shadow-sm p-4">
                <spinner v-if="loading" />
                <template v-if="!loading">
                    <div class="bg-slate-100 rounded border pt-2 px-3 pb-4 mb-2">
                        <div class="grid grid-cols-4">
                            <div>
                                <label class="mb-1 text-xs font-bold text-gray-900">Période</label>
                                <input type="month" v-model="period" :max="maxDate" class="text-sm rounded-0 grow w-full p-1.5 focus:outline-none focus:border-gray-400 bg-gray-50 border rounded" />
                            </div>
                        </div>
                    </div>

                    <div class="mt-4 text-sm bg-orange-50 rounded p-3" v-if="amounts.length <= 0">
                        Aucun montant
                    </div>
                    <div v-for="(amount, amountIndex) in amounts" :key="'amount-' + amountIndex">
                        <div v-if="amount.client" class="border-b py-2 flex items-center justify-between" :class="amount.amount <= 0 ? 'opacity-40 text-sm' : ''">
                            <div class="flex items-center gap-5">
                                <div class="font-semibold uppercase">{{ amount.client.nom }}</div>
                                <div :class="amount.amount > 0 ? 'bg-green-500 px-4 text-white rounded' : ''">{{ amount.amount }} €</div>
                            </div>
                            <div class="flex gap-2">
                                <template v-if="amount.amount > 0">
                                    <button @click="selectedLine = amount; $modal.show('modal-display-detail')" class="px-2 py-1.5 rounded bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer text-white text-sm"><i class="fas fa-circle-info"></i></button>
                                    <a :href="'/facturation/edit-client-options?client_id=' + amount.client.id" target="_blank" class="px-2.5 py-1.5 rounded bg-slate-500 hover:bg-slate-600 duration-200 cursor-pointer text-white text-sm"><i class="fas fa-plug"></i></a>
                                    <button @click="selectedLine = amount; $modal.show('modal-display-history')" class="px-2 py-1.5 rounded bg-orange-500 hover:bg-orange-600 duration-200 cursor-pointer text-white text-sm"><i class="fas fa-clock-rotate-left"></i></button>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </app-template>
    </div>
</template>

<script>
import moment from 'moment'
import AdminClientPriceDetails from '../../AdminClient/Components/AdminClientPriceDetails.vue'
import AdminClientPriceHistory from '../../AdminClient/Components/AdminClientPriceHistory.vue'

export default {
    components: {
        AdminClientPriceDetails,
        AdminClientPriceHistory
    },
    watch: {
        period() {
            this.getAmounts()
        }
    },
    data() {
        return {
            amounts: null,
            selectedLine: null,
            loading: false,

            period: null,
        }
    },
    computed: {
        maxDate() {
            return moment().format('YYYY-MM')
        }
    },
    methods: {
        async getAmounts() {
            this.loading = true
            let response = await this.$request.post('facturation.admin.client-list-amounts', {
                period: moment(this.period).format('MM/YYYY')
            })
            let endData = response.data.posts.post.map((item) => ({...item, details: JSON.parse(item.details)}))
            this.amounts = endData
            this.loading = false
        }
    },
    mounted () {
        this.period = moment().format('YYYY-MM')
        this.getAmounts();
    },
}
</script>

<style lang="scss" scoped>

</style>