<template>
    <div>
        <div v-if="loaderInit" class="mt-5 px-5 flex justify-center">
            <spinner :size="10" />
            <div class="ml-5 font-light text-xs">Chargement de la donnée</div>
        </div>
        <div class="p-4" v-if="shoppingFeedAccount">
            <!-- Shoppingfeed Import -->
            <toggle-card id="importer" title="<i class='fas fa-rocket mr-1'></i> Importer">
                <div class="bg-white p-4 rounded-xl border shadow mt-2">
                    <label class="block mb-1 text-xs font-bold text-gray-900">Import ShoppingFeed</label>
                    <div class="mb-2">
                        <div class="text-xs font-light opacity-80 mt-2 mb-1">Connexion:</div>
                        <div class="cursor-not-allowed border rounded-lg bg-gray-50 border-gray-300 p-2 font-semibold opacity-80" style="font-size: 10px">{{ shoppingFeedAccount.login }}</div>
                    </div>
                    <div class="mb-2">
                        <div class="text-xs font-light opacity-80 mt-2 mb-1">Magasin:</div>
                        <input-rule v-model="shoppingFeedFilters.shop" type="select" :options="{values: shoppingFeedAccount._embedded.store, label: 'name'}" :label-inline="false" :display-errors="false" custom="p-1 text-xs" />
                    </div>
                    <div>
                        <div class="text-xs font-light opacity-80 mt-2 mb-1">Depuis le</div>
                        <input-rule v-model="shoppingFeedFilters.since" type="datetime" class="mb-4" custom="p-1 text-xs" :options="{min: shoppingFeedFilters.minDate, max: maxToday}" :label-inline="false" :display-errors="false" />
                    </div>
                    <button @click="makeImport" class="mt-1 bg-slate-100 hover:bg-slate-200 duration-200 px-4 py-2 rounded-lg font-semilight text-xs">
                        <template v-if="!loadingMakeImport">
                            Importer <i class="fas fa-cloud-arrow-down ml-1"></i>
                        </template>
                        <template v-if="loadingMakeImport">
                            <spinner :size="8" />
                        </template>
                    </button>
                    <div class="mt-2 font-light text-xs">
                        <template v-if="loadingMakeImport">
                            Importation ShoppingFeed en cours..
                        </template>
                    </div>
                </div>
            </toggle-card>

            <!-- Imports -->
            <toggle-card id="imports" v-if="importsRequest" :title="importsRequest.data && (importsRequest.data.length <= 0 || importsRequest.data[0].exported_at) ? '<i class=\'fas fa-layer-group mr-1\'></i> Mes imports' : '<span class=\'text-orange-700 font-bold\'><i class=\'fas fa-exclamation-triangle mr-1\'></i> Mes imports</span>'">
                <paginate-menu-item :data-src="importsRequest" operation="conversions.get-imports">
                    <template v-slot:item='{item}'>
                        <div @click="$parent.$parent.getImport(item)" class="flex items-center justify-between p-2 border-b rounded-xl cursor-pointer" :class="$parent.$parent.currentConversion && item.id == $parent.$parent.currentConversion.id ? 'bg-slate-100' : 'hover:bg-slate-100 duration-200'">
                            <div>
                                <label class="block text-xs text-gray-900 cursor-pointer">
                                    Du <b>{{ JSON.parse(item.meta).since | momentDateTime }}</b><br />
                                    Au <b>{{ JSON.parse(item.meta).imported_at | momentDateTime }}</b>
                                </label>
                                <div class="text-xs font-light opacity-80 mt-1">
                                    <template v-if="item.exported_at">
                                        {{ item.file_url }}
                                        Exporté le {{ item.exported_at | momentDateTime }}
                                    </template>
                                    <template v-else>
                                        <span class="text-orange-700 font-bold"><i class="fas fa-exclamation-triangle mr-1"></i> En attente d'exportation</span>
                                    </template>
                                </div>
                            </div>
                            <i class="fas fa-chevron-right"></i>
                        </div>
                    </template>
                </paginate-menu-item>
            </toggle-card>

            <!-- Exports -->
            <toggle-card id="exports" title="<i class='fas fa-file-export mr-1'></i> Mes exports">
                <paginate-menu-item :data-src="exportsRequest" operation="conversions.get-exported-files">
                    <template v-slot:item='{item}'>
                        <div class="flex items-center justify-between py-2 border-b">
                            <div class="text-xs font-light">
                                {{ item.created_at | momentDateTime }}
                            </div>
                            <button class="text-xs font-light bg-green-100 hover:bg-green-200 cursor-pointer rounded px-3 py-1" @click="downloadFile(item.file_url)"><i class="fas fa-download ml-0.5"></i></button>
                        </div>
                    </template>
                </paginate-menu-item>
            </toggle-card>

            <!-- Correspondances -->
            <toggle-card
            id="configs"
            title="<i class='fas fa-cog mr-1'></i> Configurations"
            >
                <div class="bg-white p-4 rounded-xl border shadow mt-2">
                    <label class="block mb-1 text-xs font-bold text-gray-900">Tableau de correspondance SKU</label>
                    <div v-if="skuFile && skuFile != 0" class="text-xs font-light opacity-80 mb-2">{{ skuFile.created_at | momentDate }}</div>
                    <div class="flex">
                        <a v-if="skuFile && skuFile != 0" class="bg-white hover:bg-slate-100 duration-200 px-4 py-2 rounded-xl text-xs border" :href="skuFile.meta" target="_blank">
                            <i class="fas fa-eye mr-1"></i> Consulter
                        </a>
                        <button onclick="document.getElementById('addSkuFile').click()" class="ml-1 bg-white hover:bg-slate-100 duration-200 px-4 py-2 rounded-xl text-xs border" :href="skuFile.meta" target="_blank">
                            <template v-if="skuFile && skuFile != 0">
                                <i class="fas fa-pen mr-1"></i> Modifier
                            </template>
                            <template v-else>
                                <i class="fas fa-plus mr-1"></i> Ajouter
                            </template>
                        </button>
                    </div>
                </div>
                <div class="bg-white p-4 rounded-xl border shadow mt-2">
                    <label class="block mb-1 text-xs font-bold text-gray-900">Tableau de correspondance Code client</label>
                    <div v-if="clientFile && clientFile != 0" class="text-xs font-light opacity-80 mb-2">{{ clientFile.created_at | momentDate }}</div>
                    <div class="flex">
                        <a v-if="clientFile && clientFile != 0" class="bg-white hover:bg-slate-100 duration-200 px-4 py-2 rounded-xl text-xs border" :href="clientFile.meta" target="_blank">
                            <i class="fas fa-eye mr-1"></i> Consulter
                        </a>
                        <button onclick="document.getElementById('addClientFile').click()" class="ml-1 bg-white hover:bg-slate-100 duration-200 px-4 py-2 rounded-xl text-xs border" :href="skuFile.meta" target="_blank">
                            <template v-if="clientFile && clientFile != 0">
                                <i class="fas fa-pen mr-1"></i> Modifier
                            </template>
                            <template v-else>
                                <i class="fas fa-plus mr-1"></i> Ajouter
                            </template>
                        </button>
                    </div>
                </div>
            </toggle-card>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

import apiService from '@/services/api.service'

import PaginateMenuItem from './PaginateMenuItem.vue'

export default {
    components: {
        PaginateMenuItem
    },
    data() {
        return {
            shoppingFeedAccount: null,
            skuFile: null,
            clientFile: null,
            // imports: null,
            // fileExports: [],
            importsRequest: null,
            exportsRequest: null,
            shoppingFeedFilters: {
                since: null,
                shop: null,
                date: null,
                minDate: null
            },

            // Loaders
            loadingMakeImport: false,
            loaderInit: false,
        }
    },

    computed: {
        maxToday() {
            return moment().format('yyyy-mm-DD')
        }
    },

    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY HH:mm:ss')
            }
            return '-'
        },
    },

    methods: {
        async initData() {
            this.loaderInit = true
            let response = await apiService.post('conversions.shoppingfeed.init-data')

            // Set shopping feed account
            this.shoppingFeedAccount = response.data.posts.post.sf_account
            this.shoppingFeedFilters.shop = this.shoppingFeedAccount._embedded.store[0]

            // Set imports
            this.importsRequest = response.data.posts.post.imports
            if (this.importsRequest.data[0]) {
                this.shoppingFeedFilters.since = JSON.parse(this.importsRequest.data[0].meta).imported_at
                this.shoppingFeedFilters.minDate = JSON.parse(this.importsRequest.data[0].meta).imported_at
            } else {
                this.shoppingFeedFilters.minDate = moment().subtract(5, 'days').format('YYYY-MM-DDTHH:mm:ss')
                this.shoppingFeedFilters.since = moment().subtract(5, 'days').format('YYYY-MM-DDTHH:mm:ss')
            }

            // Set exports
            // this.fileExports = response.data.posts.post.exports
            this.exportsRequest = response.data.posts.post.exports

            // Correspondance files
            this.skuFile = response.data.posts.post.files.sku || 0
            this.clientFile = response.data.posts.post.files.client || 0

            // Select last import
            if (this.importsRequest.data[0] && !this.importsRequest.data[0].exported_at) {
                this.$parent.$parent.getImport(this.importsRequest.data[0])
            }
            this.loaderInit = false
            this.$nextTick(() => {
                if (this.importsRequest.data[0] && !this.importsRequest.data[0].exported_at) {
                    this.$eventHub.$emit('go-open-card', 'imports')
                } else {
                    this.$eventHub.$emit('go-open-card', 'importer')
                }
            })
        },

        async makeImport() {
            let success = false
            try {
                this.loadingMakeImport = true
                let response = await apiService.post('conversions.shoppingfeed.make-import', {
                    store_id: this.shoppingFeedFilters.shop.id,
                    since: this.shoppingFeedFilters.since
                })
                if (response.data.posts.post == 0) {
                    this.$toast.warning("Aucune nouvelle entrée sur ShoppingFeed");
                    this.loadingMakeImport = false
                    return
                } else if (response.data.posts.error) {
                    this.$toast.error(response.data.posts.error)
                } else {
                    await this.getImports()
                    await this.getExports()
                    if (this.importsRequest.data && this.importsRequest.data.length > 0) {
                        this.$parent.$parent.getImport(this.importsRequest.data[0])
                    }
                    success = true
                }
            } catch (error) {
                this.$toast.error('Une erreur est survenue')
                console.error(error);
            }
            this.loadingMakeImport = false
            if (success) {
                this.$toast.success('Importé')
                this.$nextTick(() => {
                    this.$eventHub.$emit('go-open-card', 'imports')
                })
            }
        },

        async getImports() {
            try {
                let response = await apiService.post('conversions.get-imports', {})
                this.importsRequest = response.data.posts.post
                if (this.importsRequest.data.length > 0) {
                    this.shoppingFeedFilters.since = JSON.parse(this.importsRequest.data[0].meta).imported_at
                    this.shoppingFeedFilters.minDate = JSON.parse(this.importsRequest.data[0].meta).imported_at
                } else {
                    this.shoppingFeedFilters.minDate = moment().subtract(5, 'days').format('YYYY-MM-DDTHH:mm:ss')
                    this.shoppingFeedFilters.since = moment().subtract(5, 'days').format('YYYY-MM-DDTHH:mm:ss')
                }
            } catch (error) {
                this.$toast.error('Une erreur est survenue')
                console.error(error);
            }
        },

        async getExports() {
            try {
                let response = await apiService.post('conversions.get-exported-files', {})
                this.exportsRequest = response.data.posts.post
            } catch (error) {
                this.$toast.error('Une erreur est survenue')
            }
        },

        downloadFile(fileExport) {
            if (fileExport) {
                window.open(fileExport, '_blank').focus();
            }
        }
    },

    mounted () {
        this.initData();

        this.$eventHub.$on('refresh-imports', async (value) => {
            await this.getImports()
            if (value.selectLast && this.imports && this.imports.length > 0) {
                this.$parent.$parent.getImport(this.importsRequest.data[0])
            }
        })

        this.$eventHub.$on('refresh-exports', async () => {
            await this.getExports()
            this.$eventHub.$emit('go-open-card', 'exports')
        })
    },
}
</script>

<style lang="scss" scoped>

</style>
