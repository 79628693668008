<template>
    <div>
        <app-template
        page-name="Catégories"
        >
            <div v-if="!loadingData && hasEmptyCateg" class="bg-white text-orange-700 p-10 rounded-xl border shadow-sm">
                <div class="font-bold text-lg"><i class="fas fa-warning mr-1"></i> A compléter</div>
                <p class="font-light">Afin de faciliter la saisie des demandes de remboursement, merci de renseigner les nouvelles catégories correspondantes aux catégories précédemment enregistrées.</p>
            </div>

            <div class="bg-white p-10 rounded-xl border shadow-sm mt-5 mb-28">
                <div v-if="loadingData">
                    <div class="flex justify-center">
                        <spinner :size="12" />
                    </div>
                    <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
                </div>

                <div v-if="!loadingData">
                    <div v-if="nbAnalyses > 0" class="p-8 mb-10 text-sm text-blue-800 rounded-lg bg-blue-50" role="alert">
                        <div class="font-bold text-g"><i class="fas fa-eye mr-1"></i> Vérification</div>
                        <p>Nous avons pu identifier {{ nbAnalyses }} {{ nbAnalyses > 1 ? 'catégories' : 'catégorie' }}, nous vous conseillons tout de même de les vérifier manuellement.</p>
                    </div>
                    <data-table
                    :filters="[
                        {label: 'Catégorie STA', key: 'categsta', type: 'text', value: null},
                    ]"
                    :filters-inline="false"
                    :without-action="true"
                    :columns="[
                        {label: 'Catégorie STA', key: 'categsta', type: 'text'},
                        {label: 'Catégorie Ecosystème', key: 'category', slot: 'categeco', type: 'text'},
                    ]"
                    :lines="categories"
                    >
                        <template v-slot:categeco='{line}'>
                            <select @change="sendData(line)" class="my-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-1.5 focus:outline-none" v-model="line.design_categ_eco">
                                <option value="-">Aucune</option>
                                <option v-for="(itemCatalog, itemCatalogIndex) in catalog" :key="'item-catalog-' + itemCatalogIndex" :value="itemCatalog">
                                    {{ itemCatalog }}
                                </option>
                            </select>
                        </template>
                    </data-table>

                    <!-- Send form -->
                    <!-- <div class="text-right">
                        <button v-if="categories.length > 0" :disabled="sendLoading" @click="sendData" class="mt-4 px-8 py-3 font-bold text-white duration-100 duration-200 bg-green-600 border-green-700 hover:bg-green-700 rounded-xl">
                            <div v-show="!sendLoading">
                                <i class="fas fa-check mr-1"></i> Valider
                            </div>
                            <div v-if="sendLoading">
                                <spinner :size="12" />
                            </div>
                        </button>
                    </div> -->
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import apiService from '@/services/api.service'

export default {
    data() {
        return {
            loadingData: false,
            sendLoading: false,
            categories: [],
            catalog: null,
            nbAnalyses: 0,
        }
    },

    computed: {
        hasEmptyCateg() {
            return this.categories.find((category) => !category.design_categ_eco)
        }
    },

    methods: {
        async init() {
            this.loadingData = true

            let response = await apiService.post('ecosysteme.has-eco-access', {})
            if (!response.data.posts.post) {
                this.$router.push({name: 'ecosystem.connect'})
                return
            }

            this.getCategories()

            let responseCatalog = await apiService.post('ecosysteme.get-catalog')
            if (responseCatalog.data.posts.post) {
                this.catalog = responseCatalog.data.posts.post.map((item) => { return item['ProductName']})
            }

            // this.botInitCateg()

            this.loadingData = false
        },


        async getCategories() {
            let response = await apiService.post('ecosysteme.get-custom-categories', {})
            if (response.data.posts.post) {
                this.categories = response.data.posts.post
                this.categories = this.categories.map((categ, categIndex) => ({...categ, key: categIndex, changed: 0}))
            }
        },

        // botInitCateg() {
        //     let indexes = [
        //         {'Aspirateur : balai, robot, traineau': ['aspirateur', 'balai', 'aspiration']},
        //         {'Robot culinaire': ['batteur', 'blender', 'couteau', 'friteuse', 'mixeur', 'nespresso', 'laminoir', 'bouilloire', 'cocotte', 'cafetiere', 'centrifugeuse', 'cooking chef', 'crepiere', 'cafe', 'gauffre', 'cuiseur', 'eplucheuse', 'machine a', 'trancheuse', 'cuisine', 'BARBECUE']},
        //         {'Réfrigérateur / Congélateur': ['congelateur', 'refrigerateur']},
        //         {'Lave-linge': ['lave linge']},
        //         {'Sèche-linge': ['seche linge']},
        //         {'Téléviseur': ['televiseur', 'lcd']},
        //         {'Plaque de cuisson': ['cuisiniere', 'cuisson', 'piano', 'plaque', 'domino', 'gaziniere', 'grill', 'hachoir']},
        //         {'Vélo à assistance électrique': ['velo']},
        //         {'Lave-vaisselle': ['lave vaisselle']},
        //         {'Four posable': ['four']},
        //         {'Four micro-onde': ['micro-ondes', 'micro onde', 'micro-ondes', 'micro-onde']},
        //     ]
        //     this.categories.forEach((categ) => {
        //         if (!categ.design_categ_eco) {
        //             indexes.forEach((index) => {
        //                 for (let keys in index) {
        //                     index[keys].forEach((key) => {
        //                         if (categ.categsta && key && categ.categsta.toLowerCase().includes(key.toLowerCase())) {
        //                             categ.design_categ_eco = keys
        //                             categ.changed = 1
        //                             this.nbAnalyses++
        //                         }
        //                     })
        //                 }
        //             })
        //         }
        //     })
        // },

        async sendData(line) {
            this.sendLoading = true
            await apiService.post('ecosysteme.update-categories', {
                savlog_category_name: line.categsta,
                eco_category_name: line.design_categ_eco
            })
            this.sendLoading = false
        }
    },

    mounted() {
        this.init()
    }
}
</script>

<style lang="scss" scoped>

</style>
