<template>
    <div>
        <app-template>
            <div class="bg-white p-10 rounded shadow">
                <crud-content
                modal-width="90%"
                :can-delete="false"
                id="permission"
                :label="{singular: 'un magasin', plural: 'des magasins'}"
                ref-keyword="keyword"
                :routes="{
                    list: {key: 'magasin.list'},
                }"
                :table="[
                    {label: 'Actif', key: 'actif', type: 'boolean'},
                    {label: 'Nom', key: 'magasin', type: 'text'},
                    {label: 'Code postal', key: 'cp', type: 'text'},
                    {label: 'Ville', key: 'ville', type: 'text'},
                    {label: 'Tel sav', key: 'telsav', type: 'text'},
                    {label: 'Email', key: 'mailant', type: 'text'},
                ]"
                :filters="[
                    {label: 'Nom', key: 'magasin', type: 'text', value: null},
                    {label: 'Code postal', key: 'cp', type: 'text', value: null},
                    {label: 'Ville', key: 'ville', type: 'text', value: null},
                    {label: 'Email', key: 'mailant', type: 'text', value: null},
                ]"
                >
                    <template v-slot:modal-edit-add="props">
                        <magasin-view :edit="true" :id="props.modalForm?.id" />
                    </template>
                    <template v-slot:modal-view="line">
                        <magasin-view :edit="false" :id="line.line.id" />
                    </template>
                    <!-- <template v-slot:buttons='{line}'>
                        <button @click="selectedLine = line; $modal.show('modal-display-line')" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                            <i class="fas fa-eye"></i>
                        </button>
                        {{ line }}
                    </template> -->
                </crud-content>
            </div>
        </app-template>
    </div>
</template>

<script>
import CrudContent from './../../../components/CrudContent.vue'
import MagasinView from './MagasinView.vue'

export default {
    components: {
        CrudContent,
        MagasinView
    },
}
</script>

<style lang="scss" scoped>

</style>
