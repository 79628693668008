<template>
    <div>
        <label class="block mb-2 font-semibold text-gray-900">{{ index }}. {{ label }}</label>
        <template v-if="!hasSearch">
            <input-rule v-model="search" type="text" :label-inline="false" id="verify-societe" />
            <button :disabled="loaderSearch" @click="verify" class="text-sm flex items-center font-light bg-emerald-600 text-white px-4 py-2 mt-2 border-emerald-600 rounded-lg">
                <template v-if="!loaderSearch">
                    {{ $tt('Vérifier') }}
                    <i class="fas fa-check ml-2"></i>
                </template>
                <spinner v-if="loaderSearch" :size="10" />
            </button>
        </template>
        <template v-if="hasSearch">
            <div class="flex items-center justify-between bg-slate-100 rounded p-2 px-4">
                <span class="text-sm">{{ search }}</span>
                <button @click="hasSearch = null" class="flex items-center text-sm bg-slate-300 hover:bg-slate-400 duration-200 px-3 py-1.5 rounded">
                    {{ $tt('Modifier') }}
                    <i class="fas fa-pen ml-1"></i>
                </button>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        index: {},
        country: {},
        typeLabel: {},
        actor: {},
        displayLabel: {default: true},
    },
    data() {
        return {
            search: '',
            hasSearch: false,
            loaderSearch: false
        }
    },
    computed: {
        label() {
            if (this.country == 'FR') {
                let labelTemp = 'Numéro de SIRET'
                if (this.displayLabel) {
                    labelTemp += ' du ' + this.typeLabel
                }
                return this.$tt(labelTemp)
            }
            return this.$tt('Numéro TVA intracommunautaire')
        }
    },
    methods: {
        async verify() {
            this.search = this.search.replaceAll(' ', '')
            this.search = this.search.replaceAll('.', '')
            this.search = this.search.replaceAll('-', '')
            let type = (this.country == 'FR' ? 'siret' : 'tvaintra')
            if (!this.$validation.check([
                {value: this.search, rules: 'required|' + type, id: 'verify-societe'},
            ])) {return}

            this.loaderSearch = true
            let response = await this.$request.post('inscription.verify-societe', {
                actor: this.actor,
                type: type,
                value: this.search,
                country: this.country
            })
            this.$emit('end', response.data.posts.post);
            if (response.data.posts.post.found == 1) {
                this.hasSearch = true
            }
            this.loaderSearch = false
        }
    },
    mounted () {
    },
}
</script>

<style lang="scss" scoped>

</style>
