<template>
    <div>
        <div class="flex items-center gap-5">
            <label v-if="label" class="mb-1 text-xs font-bold text-gray-900">{{ label }}</label>
            <div class="grow border-b border-dashed"></div>
            <div @click="$emit('click')" class="text-sm" :class="data ? 'underline' : ''">
                <template v-if="type == 'text'">
                    <div v-html="data || '-'"></div>
                </template>
                <template v-if="type == 'pcheck'">
                    <i v-show="data" class="fas fa-check text-green-500"></i>
                    <i v-show="!data" class="fas fa-times text-red-500"></i>
                </template>
                <template v-if="type == 'address'">
                    <template v-if="options && options.defaultValue">
                        {{ options.defaultValue }}
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {required: true},
        type: {required: true},
        data: {},
        options: {},
    },
}
</script>

<style lang="scss" scoped>

</style>
