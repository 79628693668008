<template>
    <div>
        <app-template>
            <div class="bg-white p-10 rounded-xl border shadow-sm mt-6 mb-24">
                <template v-if="catalog">
                    <div class="mb-2" v-for="(catalogItem, catalogIndex) in catalog" :key="'catalog-item-' + catalogIndex">
                        <div
                        @click="openItem(catalogItem)"
                        class="text-lg font-semibold cursor-pointer duration-200"
                        :class="!catalogItem.open ? 'opacity-60 hover:opacity-100' : ''"
                        >{{ catalogItem['ProductName'] }}</div>
                        <div v-if="catalogItem.open" class="bg-slate-100 p-4 rounded-xl my-2">
                            <ul class="list-disc mt-2 ml-4">
                                <template v-for="(brand, brandIndex) in catalogItem['Brands']">
                                    <li v-if="brand.isEcosystemBrand" class="text-sm font-light" :key="'brand-' + brandIndex">
                                        {{ brand['BrandName'] }}
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </template>
                <div class="flex justify-center" v-if="loading">
                    <spinner :size="10" />
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import apiService from '@/services/api.service'

export default {
    data() {
        return {
            loading: false,

            catalog: null
        }
    },
    methods: {
        async getCatalog() {
            this.loading = true
            let responseCatalog = await apiService.post('ecosysteme.get-catalog')
            this.catalog = responseCatalog.data.posts.post.map((item) => ({...item, open: false}))
            this.loading = false
        },
        openItem(selectedItem) {
            let state = !selectedItem.open
            this.catalog = this.catalog.map((item) => {item.open = false; return item})
            selectedItem.open = state
        }
    },
    mounted () {
        this.getCatalog()
    },
}
</script>
