<template>
    <div>
        <div v-if="loadingGetPattern" class="p-5">
            <spinner />
        </div>
        <div v-if="!loadingGetPattern">
            <!-- <div class="text-xl font-semibold mb-2">{{ $tt('Séléctionner un espace') }}</div> -->
            <div class="flex gap-3 mb-4">
                <!-- <button class="px-4 py-2 duration-200 rounded text-sm" :class="selectedSpace == 'magasin' ? 'bg-blue-500 hover:bg-blue-600 text-white' : 'bg-slate-200 hover:bg-slate-300'" @click="selectedSpace = 'magasin'">Magasin</button> -->
                <!-- <button class="px-4 py-2 duration-200 rounded text-sm" :class="selectedSpace == 'selfcare' ? 'bg-blue-500 hover:bg-blue-600 text-white' : 'bg-slate-200 hover:bg-slate-300'" @click="selectedSpace = 'selfcare'">Selfcare</button> -->
                <div class="flex gap-4 items-center">
                    <button @click="selectedSpace = 'magasin'" class="px-6 py-4 rounded-lg" :class="selectedSpace == 'magasin' ? 'bg-blue-200 border-2 border-blue-300 text-blue-900' : 'bg-slate-100 hover:bg-slate-200 duration-200 text-slate-700 border-2 border-white'">
                        <div class="flex items-center gap-3">
                            <i class="fas fa-shop text-xl opacity-70"></i>
                            <div class="text-left">
                                <div class="font-semibold text-sm">{{$tt('Espace Magasin')}}</div>
                                <div class="font-light text-xs">{{ $tt('Gestion de vos procédures magasin') }}</div>
                            </div>
                        </div>
                    </button>
                    <button @click="hasSelfcare ? (selectedSpace = 'selfcare') : $toast.error($tt('Vous devez activer le module selfcare'))" class="px-6 py-4 rounded-lg" :class="(selectedSpace == 'selfcare' ? 'bg-blue-200 border-2 border-blue-300 text-blue-900' : 'bg-slate-100 hover:bg-slate-200 duration-200 text-slate-700 border-2 border-white') + ' ' + (hasSelfcare ? '' : 'cursor-not-allowed')">
                        <div class="flex items-center gap-3">
                            <i class="fas fa-mobile-screen-button text-2xl opacity-70"></i>
                            <div class="text-left">
                                <div class="font-semibold text-sm">{{$tt('Espace Selfcare')}}</div>
                                <div class="font-light text-xs">{{$tt('Gestion de vos procédures selfcare')}}</div>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
            <div class="bg-slate-100 rounded p-4" v-if="!procedures || procedures.length <= 0 || Object.keys(procedures).length <= 0">Aucune procédure enregistrée</div>
            <div class="mb-6" v-for="(procType, procTypeIndex) in procedures" :key="'proc-type-' + procTypeIndex">
                <div v-if="procType" class="border p-4 rounded">
                    <div class="text-xl font-semibold mb-2">{{ procTypeIndex | typeGarantieLabel }}</div>
                    <!-- <div>{{ selectedConfigs }}</div>
                    <div>{{ (procTypeIndex + '-' + (procType[0]?.selfcare_proc ? 'selfcare' : 'magasin')) }}</div>
                    <div>{{ procType }}</div> -->
                    <table-procedure
                    :can-create="false"
                    :procedures="procType"
                    @select="(item) => {selectConfigProc(procTypeIndex, item)}"
                    @refresh-proc="getPatterns"
                    @update-pattern="getPatterns"
                    :selected-id="selectedConfigs[(procTypeIndex + '-' + (procType[0]?.selfcare_proc ? 'selfcare' : 'magasin'))]"
                    :space="selectedSpace"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TableProcedure from '../../Procedure/Components/TableProcedure.vue';

export default {
    props: {
        value: {},
    },
    watch: {
        value(value) {
            if (value) {
                this.selectedConfigs = value
            } else {
                this.selectedConfigs = {}
            }
        },
        selectedSpace() {
            this.sortPatterns()
        }
    },
    components: {
        TableProcedure,
    },
    data() {
        return {
            procedures: null,
            allProcedures: null,
            selectedProcs: {},
            selectedConfigs: {},
            selectedSpace: 'magasin',

            hasSelfcare: false,

            loadingGetPattern: false,
        }
    },
    filters: {
        typeGarantieLabel(label) {
            let labels = {
                'garantie_marque': 'Garantie Marque',
                'garantie_distributeur': 'Garantie Distributeur',
                'garantie_pms': 'Garantie PMS',
                'garantie_comp': 'Garantie Complémentaire',
                'hors_garantie': 'Hors garantie',
            }
            return labels[label]
        }
    },
    methods: {
        async getPatterns() {
            this.loadingGetPattern = true
            let response = await this.$request.post('procedures.get-patterns')
            this.loadingGetPattern = false
            let data = response.data.posts.post
            this.allProcedures = data
            this.sortPatterns()
        },

        sortPatterns() {
            var groupBy = function(xs, key) {
                return xs.reduce(function(rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            };

            let data = JSON.parse(JSON.stringify(this.allProcedures))
            this.procedures = groupBy(data.filter((item) => item.selfcare_proc == (this.selectedSpace == 'selfcare' ? 1 : 0)), 'type_garantie')
            for (let key in this.procedures) {
                let endKey = key + '-' + (this.procedures[key][0].selfcare_proc ? 'selfcare' : 'magasin')
                this.selectedProcs[endKey] = null
            }
        },

        selectConfigProc(procType, item) {
            if (item) {
                this.selectedConfigs[procType] = item.id
            } else {
                delete this.selectedConfigs[procType]
            }
            this.$emit('input', this.selectedConfigs)
        },

        async checkHasSelfcare() {
            let response = await this.$request.post('module.check', {
                module: 'selfcare'
            })
            this.hasSelfcare = response.data.posts.post
        }
    },
    mounted () {
        this.getPatterns()
        if (this.value) {
            this.selectedConfigs = this.value
        } else {
            this.selectedConfigs = {}
        }

        this.$eventHub.$on('refresh-multi-proc-patterns', () => {
            this.getPatterns()
        })

        this.checkHasSelfcare()
    },
}
</script>

<style lang="scss" scoped>

</style>