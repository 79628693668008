<template>
    <div>
        <modal name="modal-start-pc" height="auto" width="60%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        <i class="fas fa-folder mr-1"></i> {{ $tt("Initialiser le dossier") }}
                    </h2>
                    <button @click="$modal.hide('modal-start-pc')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div class="flex justify-center mt-10" v-if="loadingGetData">
                    <div class="text-center">
                        <div class="flex justify-center">
                            <spinner :size="10" />
                        </div>
                        <div class="text-xs font-light mt-4">{{ $tt('Chargement des données') }}</div>
                    </div>
                </div>
                <div v-if="data && !loadingGetData" class="min-h-[500px]">
                    <table class="w-full">
                        <thead class="text-left">
                            <tr>
                                <th class="border border-b-2 bg-slate-200 border-slate-300 text-sm px-2 py-2 w-[180px]">{{ $tt('Champ') }}</th>
                                <th class="border border-b-2 bg-slate-100 border-slate-300 text-sm px-2 py-2 w-[180px]">{{ $tt('Opérateur') }}</th>
                                <th class="border border-b-2 border-slate-300 text-sm px-2 py-2">{{ $tt('Correction') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Marque') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">{{ data.device['Marque'] }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5">-</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Modèle') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">{{ data.device['Modele'] }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5"><input-rule type="vselect" v-model="form.modele" id="categorie" :options="{values: modeles}" :label-inline="false" :placeholder="$tt('Nouveau modèle')" /></td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Date d\'achat') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">{{ data.date_ha | momentDate }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5">-</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Date de dépot') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">{{ data.date_depot | momentDate }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5">-</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Référence comm') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">{{ data.ref_commerciale || '-' }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5"><input-rule type="vselect" v-model="form.refcomm" id="categorie" :options="{values: references}" :label-inline="false" /></td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Garantie') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">{{ data.garantie }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5"><input-rule type="vselect" v-model="form.id_garantie" id="garantie" :options="{values: garanties, label: 'libelle', key: 'id'}" :label-inline="false" class="text-sm" /></td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Bonus QualiRepar') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">
                                    <template v-if="ecosystemAmount == -1">{{ $tt('Non applicable') }} </template>
                                    <template v-if="ecosystemAmount >= 0">{{ ecosystemAmount || '0.00' }} €</template>
                                </td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5">-</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Numéro de série') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">-</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5">
                                    <div class="grid grid-cols-2 gap-4">
                                        <div>
                                            <input-rule @input="checkNumSerie" :type="confirmNewNum ? 'password' : 'text'" v-model="form.numSerie" :label-inline="false" id="num_serie" />
                                        </div>
                                        <div class="text-right text-sm" v-if="numSerieValid !== null">
                                            <div class="font-bold text-green-600" v-if="numSerieValid">
                                                <div class="text-xs">{{ $tt('Numéro de série valide') }}</div>
                                            </div>
                                            <div class="font-bold text-orange-600" v-if="numSerieValid === false">
                                                <div class="text-xs">{{ $tt('Numéro de série invalide') }}</div>
                                                <button v-if="!confirmNewNum" @click="confirmNewNum = true" class="px-2 py-1.5 bg-orange-600 hover:bg-orange-700 duration-200 mt-1 text-xs rounded text-white"><i class="fas fa-pen mr-0.5"></i> {{ $tt(('Corriger')) }}</button>
                                                <button v-if="confirmNewNum" @click="confirmNewNum = false" class="px-2 py-1.5 bg-red-600 hover:bg-red-700 duration-200 mt-1 text-xs rounded text-white"><i class="fas fa-times mr-0.5"></i> {{ $tt(('Annuler')) }}</button>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="confirmNewNum">
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Confirmer le numéro de série') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">-</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5">
                                    <input-rule type="text" v-model="form.newNumSerie" :label-inline="false" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="mt-4">
                        <div class="grid grid-cols-2 gap-10">
                            <div>
                                <h2 class="mb-2 font-bold text-slate-500 text-sm">{{$tt('Photos du produit')}} :</h2>
                                <input id="fileUpload" type="file" hidden @change="uploadPhoto">
                                <div class="grid grid-cols-4 gap-2">
                                    <div class="rounded h-[50px] bg-slate-100 hover:bg-slate-200 duration-200 justify-center items-center flex cursor-pointer" @click="openInpPhoto()">
                                        <div class="text-center">
                                            <i class="fas fa-plus text-sm"></i>
                                            <div class="-mt-1 font-light" style="font-size: 10px">Ajouter</div>
                                        </div>
                                    </div>
                                    <div v-for="(img, imgIndex) in data.app_imgs" :key="'app-image-' + imgIndex">
                                        <a :href="img.url" target="_blank">
                                            <img :src="img.url" class="w-full border rounded-sm">
                                        </a>
                                    </div>
                                    <div v-if="loadingAddPhoto">
                                        <spinner :size="10" />
                                    </div>
                                </div>
                            </div>
                            <div class="text-right">
                                <template v-if="accessoires.length > 0">
                                    <h2 class="mb-2 font-bold text-slate-500 text-sm">{{$tt('Accessoires présents')}} :</h2>
                                    <div>
                                        <div v-for="(accessoire, accessoireIndex) in accessoires" :key="'accessoire-start-' + accessoireIndex" class="rounded text-sm duration-200 mb-1">
                                            <div class="flex items-center justify-end gap-2">
                                                <div class="ml-1 mt-1 font-bold" :class="accessoire.verif_sta ? '' : 'opacity-50'">{{ accessoire.accessoire.designation }}</div>
                                                <input-rule type="pcheck" v-model="accessoire.verif_sta" :label-inline="false" />
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="text-right mt-4 pt-3 border-t-2">
                        <button :disabled="loadingStartFolder" @click="goToFolder" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 mt-1 text-sm rounded text-white">
                            <spinner :size="10" v-if="loadingStartFolder" />
                            <template v-if="!loadingStartFolder">
                                <i class="fas fa-check mr-0.5"></i> {{ $tt(('Enregistrer le dossier')) }}
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import moment from 'moment'
import apiUploadService from '@/services/apiUpload.service'

export default {
    props: {
        line: {
            required: true
        },
    },
    watch: {
        line() {
            this.init()
        }
    },
    data() {
        return {
            data: null,
            modeles: [],
            references: [],
            form: {},
            garanties: [],

            numSerieValid: null,
            confirmNewNum: false,

            ecosystemAmount: 0,

            accessoires: [],

            loadingStartFolder: false,
            loadingGetData: false,
            loadingAddPhoto: false,
        }
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        }
    },
    methods: {
        async init() {
            this.form = {
                modele: null,
                refcomm: this.line.device.ref_commerciale,
                garantie: this.line.garantie,
            }
            this.numSerieValid = null
            this.confirmNewNum = false
            this.ecosystemAmount = 0
            this.accessoires = []
            this.data = this.line            
            this.loadingGetData = true
            await this.getModeles()
            await this.getRefcomm()
            await this.getGaranties()
            await this.getEcosystemAmount()
            await this.getAccessoires()
            this.loadingGetData = false
        },

        async getModeles() {
            let response = await this.$request.post('technicien.get-modeles-in-marque', {
                marque: this.data.device['Marque']
            })
            this.modeles = response.data.posts.post
        },

        async getRefcomm() {
            let response = await this.$request.post('technicien.get-refcomm-in-marque', {
                marque: this.data.device['Marque']
            })
            this.references = response.data.posts.post
        },

        async getGaranties() {
            let response = await this.$request.post('technicien.get-dossier-garantie', {
                id_dossier: this.data.id_dossiersav
            })
            this.garanties = response.data.posts.post
        },

        checkNumSerie() {
            if (!this.form.numSerie) {
                this.numSerieValid = null
            } else {
                this.numSerieValid = this.form.numSerie == this.data.num_serie
            }
        },

        async goToFolder() {
            if (!this.$validation.check([
                {value: this.form.numSerie, rules: 'required', id: 'num_serie'},
                {value: this.form.id_garantie, rules: 'required', id: 'garantie'},
            ])) {return}

            if (this.form.numSerie != this.data.num_serie && !this.confirmNewNum) {
                this.$toast.error(this.$tt('Numéro de série invalide'))
                return
            }

            if (this.confirmNewNum) {
                if (this.form.numSerie != this.form.newNumSerie) {
                    this.$toast.error(this.$tt('Les numéros de série ne correspondent pas'))
                    return
                }
            }
            let endData = {
                id_dossier: this.data.id_dossiersav,
                accessoires: JSON.stringify(this.accessoires.filter((item) => item.verif_sta).map((item) => item.id_accessoire))
            }
            if (this.data.num_serie != this.form.numSerie) {
                endData.num_serie = this.form.numSerie
            }
            if (this.form.modele && this.line.device['Modele'] != this.form.modele) {
                endData.modele = this.form.modele
            }
            if (this.data.id_garantie != this.form.id_garantie) {
                endData.id_garantie = this.form.id_garantie
            }
            if (this.form.refcomm && this.line.device.ref_commerciale != this.form.refcomm) {
                endData.ref_commerciale = this.form.refcomm
            }
            if (this.ecosystemAmount && this.ecosystemAmount > 0) {
                endData.montant_ecosystem = this.ecosystemAmount
            }            
            
            this.loadingStartFolder = true
            let response = await this.$request.post('technicien.start-dossier', endData)
            this.loadingStartFolder = false
            if (response.data.posts.post == 1) {
                this.init()
                this.$emit('started-folder')
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
            this.numSerieValid = true
            this.newNumSerie = false
        },

        async getEcosystemAmount() {
            let response = await this.$request.post('technicien.get-ecosystem-amount', {
                id_dossier: this.data.id_dossiersav
            })
            this.ecosystemAmount = response.data.posts.post
        },

        async getAccessoires() {
            let response = await this.$request.post('technicien.dossier-get-accessoires', {
                id_dossier: 333493
            })
            this.accessoires = response.data.posts.post
        },

        openInpPhoto() {
            document.getElementById("fileUpload").click()
        },

        async uploadPhoto(event) {
            let fileInput = event.target.files.length > 0 ? event.target.files[0] : null

            if (fileInput) {
                this.fileUrl = null
                this.loadingAddPhoto = true
                let responseFile = await apiUploadService.post({
                    files: fileInput
                })
                let url = responseFile.data.files[0].url
                let response = await this.$request.post('technicien.add-app-photo', {
                    id_dossier: this.data.id_dossiersav,
                    url: url
                })
                this.data.app_imgs.push(response.data.posts.post)
                this.loadingAddPhoto = false
                this.$forceUpdate()
            }
        }
    },
    mounted () {
        this.init()
    },
}
</script>