<template>
    <div>
        <!-- Menu component -->
        <div v-if="loadingGetMarques" class="p-2 mt-2">
            <spinner :size="10" />
        </div>
        <div class="text-sm font-light mt-2" v-if="!loadingGetMarques && marques.length <= 0">
            {{$tt('Aucun')}}
        </div>
        <div v-if="!loadingGetMarques && marques.length > 0" class="bg-slate-100 rounded p-4 mb-2">
            <div class="text-sm mb-1 font-light"><i class="fas fa-search mr-1"></i> {{$tt('Rechercher un produit')}} :</div>
            <div class="flex items-center gap-1 border rounded mb-2 py-1 px-2 bg-slate-50">
                <i class="fas fa-search text-sm opacity-30"></i>
                <input v-model="inpSearchApps" class="bg-slate-50 w-full p-1 font-light focus:outline-none text-xs" :label-inline="false" type="text" :placeholder="$tt('Marque, Modele, Ean..')" />
            </div>
        </div>
        <div class="flex justify-between gap-2" v-if="!loadingGetMarques && marques.length > 0">
            <div>
                <button v-if="!marques.every((item) => item.selected == true)" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 text-xs rounded mb-2" @click="selectAllMarques">{{$tt('Tout cocher')}}</button>
                <button v-if="!marques.find((item) => item.selected == false)" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 text-xs rounded mb-2" @click="unselectAllMarques">{{$tt('Tout décocher')}}</button>
            </div>
        </div>
        <div class="p-2 mt-2" v-if="loadingSearchCard">
            <spinner :size="10" />
        </div>
        <div v-if="!loadingSearchCard && resultsSearch && resultsSearch.length > 0" class="mb-4 pb-6 border-b-2">
            <div v-for="(result, resultIndex) in resultsSearch" :key="'result-search-' + resultIndex">
                <div @click="loadingAddingCard ? null : selectSearchApp(result)" class="hover:bg-slate-100 duration-200 cursor-pointer p-2 rounded border mt-2 bg-white" :class="loadingAddingCard ? 'cursor-not-allowed' : ''">
                    <div class="p-2" v-if="loadingAddingCard && loadingAddingCard.id == result.id">
                        <spinner :size="10" />
                    </div>
                    <template v-else>
                        <div class="font-bold">{{ result['Marque'] + ' ' + result['Modele'] }}</div>
                        <div class="font-light text-sm">{{ result['Ean'] }}</div>
                    </template>
                </div>
            </div>
        </div>
        <div v-if="!loadingGetMarques">
            <div v-for="(marque, marqueIndex) in marques" :key="'marque-' + marqueIndex">
                <!-- Marque -->
                <div class="flex gap-2 items-center">
                    <div @click="selectMarque(marque)" class="w-[11px] h-[11px] rounded-full" :class="getMarqueBtnColor(marque)"></div>
                    <div @click="openMarque(marque)" class="py-1 hover:font-bold duration-200 hover:underline cursor-pointer">{{ marque.marque }}</div>
                </div>
                <div v-if="marque.open">
                    <div class="border-l-2 pl-2 ml-1 py-0.5" v-if="marque.loading">
                        <spinner :size="10" />
                    </div>
                    <!-- Categorie -->
                    <div class="border-l-2 pl-2 ml-1 py-0.5 text-sm font-light" v-for="(categorie, categorieIndex) in marque.categories" :key="marque.marque + '-categorie-' + categorieIndex">
                        <div class="flex gap-2 items-center mb-1">
                            <div @click="selectCateg(categorie)" class="w-[11px] h-[11px] rounded-full" :class="categorie.selected == 1 ? 'bg-green-500' : (categorie.appareils.find((app) => app.selected) ? 'bg-yellow-500' : 'bg-slate-300 hover:bg-slate-400 duration-200')"></div>
                            <div class="hover:underline duration-200 hover:font-semibold cursor-pointer" @click="openCategorie(marque, categorie)">{{ categorie.fr }}</div>
                        </div>
                        <!-- Appareil -->
                        <div v-if="categorie.open">
                            <div class="border-l-2 pl-2 ml-1 py-0.5" v-if="categorie.loading">
                                <spinner :size="10" />
                            </div>
                            <div
                            class="border-l-2 pl-2 ml-1 hover:bg-slate-50 duration-200 cursor-pointer"
                            :class="appareilIndex < categorie.appareils.length - 1 ? 'border-b' : ''"
                            v-for="(appareil, appareilIndex) in categorie.appareils"
                            :key="marque.marque + '-categ-' + categorieIndex + '-app-' + appareilIndex"
                            >
                                <div @click="selectAppareil(appareil)" class="flex items-center gap-2 py-2">
                                    <div class="w-[11px] h-[11px] rounded-full" :class="appareil.selected == 1 ? 'bg-green-500' : 'bg-slate-300 hover:bg-slate-400 duration-200'"></div>
                                    <div class="text-sm">
                                        <div class="font-semibold">{{ appareil['Modele'] }}</div>
                                        <div class="text-xs font-thin mb-0.5">{{ appareil['Ean'] }}</div>
                                        <!-- <span v-if="appareil.procedure" class="bg-green-200 rounded text-sm px-2 py-0.5 text-center"><i class="far fa-face-smile mr-0.5"></i> Procédure configurée</span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        withoutProc: {default: false},
        centrales: {},
    },
    watch: {
        marques: {
            deep: true,
            handler() {
                this.$emit('update', this.formatData())
            }
        },
        inpSearchApps() {
            this.searchInApps()
        }
    },
    data() {
        return {
            marqueData: null,
            marques: [],
            categories: [],
            inpSearchApps: null,
            resultsSearch: [],
            loadingGetMarques: false,
            loadingSearchCard: false,
            loadingAddingCard: false,
            timerSearch: null,
        }
    },
    methods: {
        formatData() {
            let endData = {
                marques: [],
                categories: [],
                appareils: []
            }
            for (let indexMarque in this.marques) {
                let marque = this.marques[indexMarque]
                if (marque.selected) {
                    endData.marques.push(marque.marque)
                    continue
                }
                for (let categorieIndex in marque.categories) {
                    let categorie = marque.categories[categorieIndex]
                    if (categorie.selected) {
                        endData.categories.push(marque.marque + '-' + categorie.id)
                        continue
                    }
                    for (let appareilIndex in categorie.appareils) {
                        let appareil = categorie.appareils[appareilIndex]
                        if (appareil.selected) {
                            endData.appareils.push(appareil.id)
                            continue
                        }
                    }
                }
            }
            return endData
        },

        async getMarques() {
            this.loadingGetMarques = true
            let response = await this.$request.post('procedures.get-marques', {
                without_proc: this.withoutProc,
                centrales: JSON.stringify(this.centrales)
            })
            this.loadingGetMarques = false
            this.marques = response.data.posts.post.map((marque) => {
                return {
                    marque: marque,
                    open: false,
                    selected: false,
                    loading: false,
                    categories: [],
                }
            })
        },
        async openMarque(marque, openAfterSearch = true) {
            if (marque.open == true) {
                marque.open = false
            } else {
                if (openAfterSearch) {
                    marque.open = true
                }
                if (marque.categories.length <= 0) {
                    marque.categories = []
                    marque.loading = true
                    let response = await this.$request.post('procedures.get-categories', {
                        marque: marque.marque,
                        without_proc: this.withoutProc,
                        centrales: JSON.stringify(this.centrales)
                    })
                    marque.categories = response.data.posts.post.map((categorie) => ({
                        ...categorie,
                        open: false,
                        selected: marque.selected,
                        loading: false,
                        appareils: []
                    }))
                    marque.loading = false
                }
            }
        },

        async openCategorie(marque, categorie, openAfterSearch = true) {
            if (categorie.open == true) {
                categorie.open = false
            } else {
                if (openAfterSearch) {
                    categorie.open = true
                }
                if (categorie.appareils.length <= 0) {
                    categorie.loading = true
                    let response = await this.$request.post('procedures.get-appareils', {
                        id_categ: categorie.id,
                        marque: marque.marque,
                        without_proc: this.withoutProc,
                        centrales: JSON.stringify(this.centrales)
                    })
                    categorie.appareils = response.data.posts.post.map((appareil) => ({
                        ...appareil,
                        selected: categorie.selected,
                    }))
                    categorie.loading = false
                }
            }
        },

        checkSelected() {
            this.marques.forEach((marque) => {
                marque.categories.forEach((categorie) => {
                    // Unselected categorie in marque
                    if (categorie.appareils.find((appareil) => !appareil.selected)) {
                        categorie.selected = 0
                    }

                    // All appareil are selected in categorie
                    if (categorie.appareils.filter((appareil) => appareil.selected).length == categorie.appareils.length && categorie.appareils.length > 0) {
                        categorie.selected = 1
                    }
                })
                if (marque.categories.length > 0) {
                    // Unselected appareil in categorie
                    if (marque.categories.find((categories) => !categories.selected)) {
                        marque.selected = 0
                    }
                    // All categories are selected in marque
                    if (marque.categories.filter((categorie) => categorie.selected).length == marque.categories.length) {
                        marque.selected = 1
                    }
                }
            })
        },

        selectMarque(marque) {
            marque.selected = !marque.selected

            // Select childs
            marque.categories.forEach((categorie) => {
                categorie.selected = marque.selected
                categorie.appareils.forEach((appareil) => {
                    appareil.selected = categorie.selected
                })
            })

            this.checkSelected()
            this.$nextTick(() => {
                this.$emit('item-changed')
            })
        },

        selectCateg(categorie) {
            categorie.selected = !categorie.selected

            // Select childs
            categorie.appareils.forEach((appareil) => {
                appareil.selected = categorie.selected
            })

            this.checkSelected()
            this.$nextTick(() => {
                this.$emit('item-changed')
            })
        },

        selectAppareil(appareil) {
            appareil.selected = !appareil.selected
            this.checkSelected()
            this.$nextTick(() => {
                this.$emit('item-changed')
            })
        },

        async searchInApps() {
            clearTimeout(this.timerSearch)
            if (this.inpSearchApps) {
                this.loadingSearchCard = true
                this.timerSearch = setTimeout(async () => {
                    let response = await this.$request.post('procedures.search-apps', {
                        search: this.inpSearchApps,
                        without_proc: this.withoutProc
                    })
                    this.loadingSearchCard = false
                    this.resultsSearch = response.data.posts.post
                }, 300);
            } else {
                this.resultsSearch = []
            }
        },

        async selectSearchApp(app) {
            if (this.loadingAddingCard) {
                return
            }
            this.loadingAddingCard = app
            let marque = this.marques.find((marque) => marque.marque == app['Marque'])
            await this.openMarque(marque)
            let categ = marque.categories.find((categ) => categ.id == app.id_categorie)
            await this.openCategorie(marque, categ)
            let selectedApp = categ.appareils.find((item) => item.id == app.id)
            if (selectedApp) {
                selectedApp.selected = true
            }
            this.$emit('update', this.formatData())
            this.resultsSearch = []
            this.loadingAddingCard = null
            this.inpSearchApps = null
            this.$emit('item-changed')
        },
        selectAllMarques() {
            this.marques.forEach((marque) => {
                marque.selected = true
                marque.categories.forEach((categ) => {
                    categ.selected = true
                    categ.appareils.forEach((app) => {
                        app.selected = true
                    })
                })
            })
            this.$nextTick(() => {
                // this.$emit('update', this.marques)
                this.$emit('item-changed')
            })
        },
        unselectAllMarques() {
            this.marques.forEach((marque) => {
                marque.selected = false
                marque.categories.forEach((categ) => {
                    categ.selected = false
                    categ.appareils.forEach((app) => {
                        app.selected = false
                    })
                })
            })
            this.$nextTick(() => {
                // this.$emit('update', this.marques)
                this.$emit('item-changed')
            })
        },

        getMarqueBtnColor(marque) {
            if (marque.selected == 1) {
                return 'bg-green-500'
            }
            let hasAppSelected = false
            marque.categories.forEach((categ) => {
                let appIsSelected = categ.appareils.find((item) => item.selected == 1)
                if (appIsSelected) {
                    hasAppSelected = true
                }
            })
            if (hasAppSelected == true) {
                return 'bg-yellow-500'
            }
            return 'bg-slate-300 hover:bg-slate-400 duration-200'
        },
    },
    mounted () {
        this.getMarques()

        this.$eventHub.$on('refresh-product-menu', () => {
            console.log('ok-refresh');
            this.getMarques()
        })

        this.$eventHub.$on('remove-selected-app', () => {
            this.marques.forEach((marque) => {
                marque.selected = false
                marque.open = false
                marque.categories.forEach((categorie) => {
                    categorie.selected = false
                    categorie.open = false
                    categorie.appareils.forEach((appareil) => {
                        appareil.selected = false
                        appareil.open = false
                    })
                })
            })
        })
    },
}
</script>

<style lang="scss" scoped>

</style>