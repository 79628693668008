<template>
    <div>
        <!-- Modal start pc -->
        <form-tech-start-pc v-if="selectedLine" :line="selectedLine" @started-folder="goToStartedFolder" />

        <!-- Modal display dossier -->
        <form-tech-devis v-if="selectedLineDossier" :id-dossier="selectedLineDossier.id_dossiersav" @devis-sended="devisSended" />

        <!-- Modal confirm delete -->
        <modal name="modal-encours-atelier" height="auto" :scrollable="true" width="70%">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        Encours atelier
                    </h2>
                    <button @click="$modal.hide('modal-encours-atelier')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div v-for="(encoursType, encoursTypeIndex) in dossiersEncours" :key="'encours-type-' + encoursTypeIndex" class="mb-4">
                        <h2 class="font-bold text-xl mb-2">
                            <template v-if="encoursTypeIndex == '0C'">{{ $tt('Devis accordé') }}</template>
                            <template v-if="encoursTypeIndex == '0B'">{{ $tt('Devis refusé') }}</template>
                        </h2>
                        <div class="table-atelier">
                            <div class="relative overflow-x-auto">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                                    <thead class="text-xs text-gray-700 uppercase">
                                        <tr class="bg-white border-b">
                                            <th class="px-4 py-2">{{$tt('Date')}}</th>
                                            <th class="px-4 py-2">{{$tt('Num. Dossier')}}</th>
                                            <th class="px-4 py-2">{{$tt('Modèle')}}</th>
                                            <th class="px-4 py-2">{{$tt('Catégorie')}}</th>
                                            <th class="px-4 py-2">{{$tt("Donneur d'ordre")}}</th>
                                            <th class="px-4 py-2">{{$tt('Techinicien')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="bg-white border-b" v-for="(dossierEncours, dossierEncoursIndex) in encoursType" :key="'dossier_encour' + dossierEncoursIndex">
                                            <td class="px-4 py-2">{{dossierEncours.date_create}}</td>
                                            <td class="px-4 py-2">{{dossierEncours.ref_dossier}}</td>
                                            <td class="px-4 py-2"><span v-if="dossierEncours.device">{{dossierEncours.device['Modele']}}</span></td>
                                            <td class="px-4 py-2"><span v-if="dossierEncours.device">{{dossierEncours.device.categorie_str}}</span></td>
                                            <td class="px-4 py-2">{{dossierEncours.nom_do}}</td>
                                            <td class="px-4 py-2"><span v-if="dossierEncours.technicien">{{dossierEncours.technicien.nom}}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- <div class="relative overflow-x-auto mb-10">
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 my-2">
                                <thead>
                                    <tr class="bg-white border-b">
                                        <th class="px-4 py-2">{{$tt('Date')}}</th>
                                        <th class="px-4 py-2">{{$tt('Num. Dossier')}}</th>
                                        <th class="px-4 py-2">{{$tt('Modèle')}}</th>
                                        <th class="px-4 py-2">{{$tt('Catégorie')}}</th>
                                        <th class="px-4 py-2">{{$tt("Donneur d'ordre")}}</th>
                                        <th class="px-4 py-2">{{$tt('Techinicien')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-white border-b" v-for="(dossierEncours, dossierEncoursIndex) in encoursType" :key="'dossier_encour' + dossierEncoursIndex">
                                        <td class="px-4 py-2">{{dossierEncours.date_create}}</td>
                                        <td class="px-4 py-2">{{dossierEncours.ref_dossier}}</td>
                                        <td class="px-4 py-2"><span v-if="dossierEncours.device">{{dossierEncours.device['Modele']}}</span></td>
                                        <td class="px-4 py-2"><span v-if="dossierEncours.device">{{dossierEncours.device.categorie_str}}</span></td>
                                        <td class="px-4 py-2">{{dossierEncours.nom_do}}</td>
                                        <td class="px-4 py-2"><span v-if="dossierEncours.technicien">{{dossierEncours.technicien.nom}}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> -->
                    </div>
                </div>
            </div>
        </modal>

        <!-- Page content -->
        <div class="grid grid-cols-3 gap-4 my-2" v-if="admin">
            <input-rule v-model="filters.intervenant" type="vselect" :options="{values: intervenants, label: 'nom', key: 'id'}" :label="$tt('Intervenant')" :label-inline="false" class="mb-2" />
            <input-rule
            v-model="filters.nom_do"
            type="api-select"
            :options="{
                api: {action: 'technicien.search-donneur-ordre', apiName: 'api2', options: {limit: 500, orderBy: 'nom_do'}},
                label: 'nom_do',
                keyTrack: 'nom_do',
                init: false,
            }"
            :label="$tt('Donneur d\'ordre')"
            :label-inline="false"
            class="mb-2"
            />
            <div class="pt-4 text-right">
                <button @click="$modal.show('modal-encours-atelier')" class="px-4 py-2 bg-green-500 text-white rounded text-sm">{{ $tt('Encours atelier') }}</button>
            </div>
        </div>
        <div class="relative overflow-x-auto">
            <div class="mb-20">
                <h2 class="text-xl font-bold mb-2">{{ $tt('Dossiers en cours') }}</h2>
                <factu-atelier-table :lines="currentFolders" table-class="bg-slate-200" :admin="admin">
                    <template v-slot:action="{line}">
                        <button @click="selectFolder(line)" class="bg-slate-600 hover:bg-slate-700 text-xs text-white px-2.5 py-1.5 rounded"><i class="fas fa-hammer"></i></button>
                    </template>
                </factu-atelier-table>
            </div>
            <div v-if="!admin" class="mb-10">
                <h2 class="text-xl font-bold mb-2">{{ $tt('Dossiers en attente') }}</h2>
                <factu-atelier-table :lines="waitingFolders" table-class="bg-orange-300" :admin="admin">
                    <template v-slot:action="{line}">
                        <button @click="startFolder(line)" class="bg-green-600 hover:bg-green-700 text-xs text-white px-2.5 py-1.5 rounded"><i class="fas fa-briefcase"></i></button>
                    </template>
                </factu-atelier-table>
            </div>
        </div>
    </div>
</template>

<script>
import FactuAtelierTable from './FactuAtelierTable.vue'
import FormTechStartPc from './FormTechStartPC.vue'
import FormTechDevis from './FormTechDevis.vue'

export default {
    components: {
        FactuAtelierTable,
        FormTechStartPc,
        FormTechDevis
    },
    props: {
        admin: {
            default: false
        },
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.getLines()
            }
        },
        admin: {
            handler() {
                this.init()
            }
        }
    },
    data() {
        return {
            currentFolders: null,
            waitingFolders: null,

            dossiersEncours: [],

            filters: {},
            intervenants: [],
            donneurOrdres: [],
            timerGetLines: null,

            selectedLine: null,
            selectedLineDossier: null,
        }
    },
    methods: {
        init() {
            this.getCurrentFolders()
            this.getWaitingFolders()
            this.getEncours()
            this.getIntervenants()
            this.getDonneurOrdres()
        },

        async getLines() {
            clearTimeout(this.timerGetLines)
            this.timerGetLines = setTimeout(() => {
                this.getCurrentFolders()
                this.getWaitingFolders()
            }, 500)
        },

        async getCurrentFolders() {
            let endData = {
                en_cours: 1,
                admin: this.admin ? 1 : 0,
            }
            if (this.filters.intervenant) {
                endData.intervenant_id = this.filters.intervenant
            }
            if (this.filters.nom_do && this.filters.nom_do !== undefined) {
                endData.nom_do = this.filters.nom_do
            }
            if (this.filters.date && this.filters.date !== undefined) {
                endData.date = this.filters.date
            }
            let response = await this.$request.post('technicien.get-dossier-atelier', endData)
            this.currentFolders = response.data.posts.post
        },

        async getWaitingFolders() {
            let endData = {
                en_cours: 0,
                admin: this.admin ? 1 : 0,
            }
            if (this.filters.intervenant) {
                endData.intervenant_id = this.filters.intervenant
            }
            if (this.filters.nom_do && this.filters.nom_do !== undefined) {
                endData.nom_do = this.filters.nom_do
            }
            if (this.filters.date && this.filters.date !== undefined) {
                endData.date = this.filters.date
            }
            let response = await this.$request.post('technicien.get-dossier-atelier', endData)
            this.waitingFolders = response.data.posts.post
        },
        
        async getEncours() {
            let response = await this.$request.post('technicien.encours-atelier')
            this.dossiersEncours = response.data.posts.post
        },

        async getIntervenants() {
            let response = await this.$request.post('technicien.get-techniciens-list')
            this.intervenants = response.data.posts.post
        },

        async getDonneurOrdres() {
            let response = await this.$request.post('technicien.get-donneur-ordre-list')
            this.donneurOrdres = response.data.posts.post
        },

        selectFolder(line) {
            this.selectedLineDossier = line
            this.$nextTick(() => {
                this.$modal.show('modal-tech-devis')
            })
        },

        startFolder(line) {
            this.selectedLine = line
            this.$nextTick(() => {
                this.$modal.show('modal-start-pc')
            })
        },

        goToStartedFolder() {
            this.selectedLineDossier = this.selectedLine
            this.$nextTick(() => {
                this.$modal.hide('modal-start-pc')
                this.$modal.show('modal-tech-devis')
            })
            this.getCurrentFolders()
            this.getWaitingFolders()
        },

        devisSended() {            
            this.getCurrentFolders()
        }
    },
    mounted () {
        this.init()
    },
}
</script>

<style lang="scss" scoped>

</style>