<template>
    <div>
        <vue-phone-number-input
        :default-country-code="options?.country"
        class="w-full bg-gray-50"
        :class="hasError ? 'border-2 border-red-500 rounded' : ''"
        size="sm"
        @update="telValidation"
        v-model="inpValue"
        :translations="{
            countrySelectorLabel: 'Code pays',
            countrySelectorError: 'Choisir un pays',
            phoneNumberLabel: 'Numéro de téléphone',
            example: 'Exemple'
        }"
        />
    </div>
</template>

<script>
export default {
    props: {
        value: {},
        options: {},
        hasError: {default: false}
    },

    watch: {
        value() {
            if (this.value) {
                this.inpValue = this.value.replaceAll(' ', '')
                if (this.inpValue.length == 10 && !this.inpValue.includes('+')) {
                    this.inpValue = this.inpValue.substring(1)
                    this.inpValue = '+33' + this.inpValue
                }
            }
        },
    },
    data() {
        return {
            inpValue: null,
        }
    },
    methods: {
        telValidation: function(value) {
            if (value.isValid) {
                let endValue = null
                if (value.countryCode == 'FR') {
                    endValue = value.formatNational
                } else {
                    endValue = value.formattedNumber
                }
                this.$emit('input', endValue)
            } else {
                this.$emit('input', null)
            }
        },
    },
    mounted () {
        if (this.value) {
            this.inpValue = this.value.replaceAll(' ', '')
            if (this.inpValue.length == 10 && !this.inpValue.includes('+')) {
                this.inpValue = this.inpValue.substring(1)
                this.inpValue = '+33' + this.inpValue
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
