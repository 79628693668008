<template>
    <div>
        <spinner v-if="loading" />
        <template v-if="!loading">
            <data-table
            :filters-inline="false"
            :filters="[
                {label: 'Numéro dossier', key: 'num_dsc',  type: 'text', value: null},
            ]"
            :columns="[
                {label: 'Numéro dossier', key: 'num_dsc', type: 'text'},
            ]"
            :lines="dossiers"
            >
                <template v-slot:actions='{line}'>
                    <button @click="displayFolder(line)" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                        <i class="fas fa-eye"></i>
                    </button>
                </template>
            </data-table>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        magId: {},
        displayCallback: {},
    },
    data() {
        return {
            dossiers: [],
            loading: false,
        }
    },
    methods: {
        async initData() {
            this.loading = true
            let response = await this.$request.post('dossier.dsc.list', {
                id_mag: this.magId
            })
            this.dossiers = response.data.posts.post
            this.loading = false
        },
        displayFolder(folder) {
            if (this.displayCallback) {
                this.displayCallback(folder)
            }
        }
    },
    mounted () {
        this.initData();
    },
}
</script>

<style lang="scss" scoped>

</style>