<template>
    <div>
        <!-- Modal edit add -->
        <modal name="modal-edit-add-entite" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        Ajouter un type de client
                    </h2>
                    <button @click="$modal.hide('modal-edit-add-entite')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <input-rule v-model="form.libelle" type="text" :label="$tt('Type de client')" :label-inline="false" id="inp-libelle" />
                    <div class="flex gap-2 items-center mt-4">
                        <button :disabled="loadingEditAdd" @click="editAddEntite" class="px-4 py-2 rounded text-sm bg-green-500 hover:bg-green-600 duration-200 text-white">
                            <spinner :size="10" v-if="loadingEditAdd" />
                            <template v-if="!loadingEditAdd">
                                <i class="fas fa-check mr-1"></i> {{$tt('Enregistrer')}}
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <!-- Modal confirm delete -->
        <modal name="modal-confirm-delete-entite" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedLine">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        Confirmer la suppression
                    </h2>
                    <button @click="$modal.hide('modal-confirm-delete-entite')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="text-lg">Souhaitez-vous supprimer <b>{{ selectedLine.libelle }}</b> ?</div>
                    <div class="bg-orange-100 text-orange-800 p-4 rounded mt-4 font-bold">
                        <i class="fas fa-exclamation-triangle mr-1"></i>
                        {{ $tt(('En supprimant cette entité, tous les tarifs liés à cette entité seront également supprimés')) }}
                    </div>
                    <div class="flex gap-2 items-center mt-4">
                        <button @click="$modal.hide('modal-confirm-delete-entite')" class="px-4 py-2 rounded text-sm bg-slate-200 hover:bg-slate-300 duration-200">Annuler <i class="fas fa-times ml-1"></i></button>
                        <button :disabled="loadingDelete" @click="deleteEntite" class="px-4 py-2 rounded text-sm bg-red-500 hover:bg-red-600 duration-200 text-white">
                            <spinner :size="10" v-if="loadingDelete" />
                            <template v-if="!loadingDelete">
                                {{$tt('Supprimer')}} <i class="fas fa-trash ml-1"></i>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <div class="p-2">
            <spinner v-if="loadingGetData" :size="10" />
        </div>

        <div v-if="!loadingGetData">
            <button @click="form = {}; $modal.show('modal-edit-add-entite')" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 mb-4 rounded text-sm"><i class="fas fa-plus mr-1"></i> {{ $tt('Ajouter un type de client') }}</button>
            <div class="relative overflow-x-auto">
                <table class="w-full text-sm text-left border">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" class="px-6 py-3 w-[50px]">
                                {{$tt('Actions')}}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{$tt('Type de client')}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(entite, entiteIndex) in entites" :key="'entite-' + entiteIndex" class="bg-white border-b hover:bg-slate-50 duration-200">
                            <td class="px-4 py-2 border-r">
                                <div class="flex items-center gap-2">
                                    <button @click="form = {...entite}; $modal.show('modal-edit-add-entite')" class="py-1.5 px-3 bg-blue-500 hover:bg-blue-600 text-white rounded text-xs font-bold">
                                        <i class="fas fa-pen"></i>
                                    </button>
                                    <button v-if="!entite.block_delete" @click="selectedLine = entite; $modal.show('modal-confirm-delete-entite')" class="py-1.5 px-3 bg-red-500 hover:bg-red-600 text-white rounded text-xs font-bold"><i class="fas fa-trash"></i></button>
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                {{entite.libelle}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            entites: [],

            form: {},
            selectedLine: null,

            loadingGetData: false,
            loadingEditAdd: false,
            loadingDelete: false,
        }
    },
    methods: {
        async getEntites() {
            this.loadingGetData = true
            let response = await this.$request.post('reparateur.get-entites')
            this.entites = response.data.posts.post
            this.loadingGetData = false
        },

        async editAddEntite() {
            if (!this.$validation.check([
                {value: this.form.libelle, rules: 'required', id: 'inp-libelle'},
            ])) {return}

            let endObj = this.form
            if (endObj.id) {
                endObj.line_id = endObj.id
                delete endObj.id
            }

            this.loadingEditAdd = true
            let response = await this.$request.post('reparateur.edit-add-entite', this.form)
            this.loadingEditAdd = false
            if (response.data.posts.post == 1) {
                await this.getEntites()
                this.$toast.success(this.$tt('Entité ajoutée'))
                this.$modal.hide('modal-edit-add-entite')
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
        },

        async deleteEntite() {
            this.loadingDelete = true
            let response = await this.$request.post('reparateur.delete-entite', {
                line_id: this.selectedLine.id
            })
            this.loadingDelete = false
            if (response.data.posts.post == 1) {
                await this.getEntites()
                this.$modal.hide('modal-confirm-delete-entite')
                this.$toast.success(this.$tt('Entité supprimée'))
            }
        }
    },
    mounted () {
        this.getEntites();
    },
}
</script>

<style lang="scss" scoped>

</style>