<template>
    <div>
        <app-template>
            <crud-content
            id="modules"
            :can-view="false"
            :label="{singular: 'un module', plural: 'des modules'}"
            ref-keyword="label"
            :routes="{
                list: {key: 'admin.modules.list'},
                delete: {key: 'admin.modules.delete'}
            }"
            :table="[
                {label: 'Nom', key: 'label', type: 'text'},
                {label: 'Type', key: 'type', type: 'text'},
                {label: 'Prix abonnement', key: 'value', type: 'euro'},
                {label: 'Frais production', key: 'frais_prod', type: 'euro'},
                {label: 'Description', key: 'description', type: 'text'},
                {label: 'Gestion par le client', key: 'gestion_client', type: 'boolean'},
                {label: 'Fournisseur', key: 'fournisseur', type: 'boolean'},
                {label: 'Centrale HA', key: 'centrale_ha', type: 'boolean'},
                {label: 'Station', key: 'station', type: 'boolean'},
            ]"
            :filters="[
                {label: 'Nom', key: 'label', type: 'text', value: null},
            ]"
            >
                <template v-slot:modal-edit-add="props">
                    <template v-if="props.modalForm">
                        <input-rule v-model="props.modalForm.label" type="text" label="Nom" :label-inline="false" class="mb-2" />
                        <input-rule v-model="props.modalForm.keyword" type="text" label="Mot clé" :label-inline="false" class="mb-2" />
                        <input-rule v-model="props.modalForm.description" type="textarea" label="Description" :label-inline="false" class="mb-2" />
                        <input-rule v-model="props.modalForm.value" type="price" label="Coût" :label-inline="false" class="mb-2" />
                        <input-rule v-model="props.modalForm.frais_prod" type="price" label="Frais mise en production" :label-inline="false" class="mb-2" />
                        <input-rule v-model="props.modalForm.type" type="text" label="Type" :label-inline="false" class="mb-2" />
                        <input-rule v-model="props.modalForm.gestion_client" type="pcheck" label="Gestion du module par le client" :label-inline="false" class="mb-2" />
                        <div class="grid grid-cols-3 gap-2">
                            <input-rule v-model="props.modalForm.fournisseur" type="pcheck" label="Fournisseur" :label-inline="false" class="mb-2" />
                            <input-rule v-model="props.modalForm.centrale_ha" type="pcheck" label="Centrale Achat" :label-inline="false" class="mb-2" />
                            <input-rule v-model="props.modalForm.station" type="pcheck" label="Réparateur" :label-inline="false" class="mb-2" />
                        </div>
                        <button :disabled="loadingModal" class="mt-4 px-4 py-2 bg-green-500 text-white rounded text-sm" @click="editAddOption(props.modalForm)">
                            <spinner v-if="loadingModal" :size="10" />
                            <template v-if="!loadingModal">
                                Enregistrer <i class="fas fa-check ml-1"></i>
                            </template>
                        </button>
                    </template>
                </template>
            </crud-content>
        </app-template>
    </div>
</template>

<script>
import CrudContent from './../../../components/CrudContent.vue'

export default {
    components: {
        CrudContent,
    },
    data() {
        return {
            loadingModal: false
        }
    },
    methods: {
        async editAddOption(formData) {
            if (formData.id) {
                formData.line_id = formData.id
                delete formData.id
            }
            if (!formData.description) {
                delete formData.description
            }
            this.loadingModal = true
            let response = await this.$request.post('admin.modules.edit', formData)
            if (response.data.posts.post.id) {
                this.$modal.hide('modal-edit-add-modules')
                if (formData.line_id) {
                    this.$toast.success('Module modifié')
                } else {
                    this.$toast.success('Module ajouté')
                }
                this.$eventHub.$emit('refresh-crud', 'modules')
            } else {
                this.$toast.error('Une erreur est survenue')
            }
            this.loadingModal = false
        }
    },
}
</script>

<style lang="scss" scoped>

</style>