var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-template',[_c('div',{staticClass:"bg-white p-10 rounded shadow"},[_c('crud-content',{attrs:{"modal-width":"90%","can-delete":false,"id":"permission","label":{singular: 'un magasin', plural: 'des magasins'},"ref-keyword":"keyword","routes":{
                list: {key: 'magasin.list'},
            },"table":[
                {label: 'Actif', key: 'actif', type: 'boolean'},
                {label: 'Nom', key: 'magasin', type: 'text'},
                {label: 'Code postal', key: 'cp', type: 'text'},
                {label: 'Ville', key: 'ville', type: 'text'},
                {label: 'Tel sav', key: 'telsav', type: 'text'},
                {label: 'Email', key: 'mailant', type: 'text'},
            ],"filters":[
                {label: 'Nom', key: 'magasin', type: 'text', value: null},
                {label: 'Code postal', key: 'cp', type: 'text', value: null},
                {label: 'Ville', key: 'ville', type: 'text', value: null},
                {label: 'Email', key: 'mailant', type: 'text', value: null},
            ]},scopedSlots:_vm._u([{key:"modal-edit-add",fn:function(props){return [_c('magasin-view',{attrs:{"edit":true,"id":props.modalForm?.id}})]}},{key:"modal-view",fn:function(line){return [_c('magasin-view',{attrs:{"edit":false,"id":line.line.id}})]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }