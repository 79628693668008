<template>
    <div>
        <modal name="modal-add-categ" height="auto" :scrollable="false">
            <div class="p-5 z-20">
                <input-rule class="mb-2" v-model="selectedCateg" label="Catégorie" type="select" :options="{values: listCategs, label: 'fr'}" id="get-categ" :label-inline="false" />
                <template v-if="selectedCateg">
                    <bdc-pattern-categ @refresh="getCategs" :categId="selectedCateg.id" :pattern="pattern" :display-delete="false" />
                </template>
            </div>
        </modal>

        <input-rule @input="editName" class="mb-2" v-model="patternName" type="text" label="Nom du schéma" :label-inline="false" id="bdc-pattern-name" />

        <toggle-card v-for="(categ, categIndex) in categs" :key="'categ-' + categIndex" class="mb-2 bg-slate-100 rounded" :id="'categ-' + categ.id_categorie" :title="categ.categ">
            <div class="p-2">
                <bdc-pattern-categ @refresh="getCategs" class="p-2 rounded bg-white border-2" :categId="categ.id_categorie" :pattern="pattern" :codes="categ.code_panne" />
            </div>
        </toggle-card>
        <button @click="$modal.show('modal-add-categ')" class="px-4 py-2 bg-slate-400 hover:bg-slate-500 duration-200 text-white rounded-xl text-xs font-light mb-2">
            <i class="fas fa-plus"></i> Ajouter
        </button>
    </div>
</template>

<script>
import BdcPatternCateg from './BdcPatternCateg.vue'

export default {
    components: {BdcPatternCateg},
    props: {
        pattern: {required: true},
    },
    data() {
        return {
            categs: [],
            listCategs: [],
            selectedCateg: null,
            patternName: null,
            patternNameTimer: null
        }
    },
    methods: {
        async getListCategs() {
            let response = await this.$request.post('getcateg', {
                action: 'bdc'
            }, 'districloud_preprod')
            this.listCategs = response.data.posts.post
        },

        async getCategs() {
            let response = await this.$request.post('getpatternbyid', {
                action: 'bdc',
                id_pattern: this.pattern.id_pattern
            }, 'districloud_preprod')
            this.categs = response.data.posts.post
        },

        async editName() {
            clearTimeout(this.patternNameTimer)
            this.patternNameTimer = setTimeout(async () => {
                await this.$request.post('editadd_pattern', {
                    id_pattern: this.pattern.id_pattern,
                    action: 'bdc',
                    nom_pattern: this.patternName
                }, 'districloud_preprod')
            }, 500);
        }
    },
    async mounted () {
        await this.getListCategs()
        await this.getCategs()
        this.patternName = this.pattern.nom
    },
}
</script>

<style lang="scss" scoped>

</style>
