<template>
    <div>
        <modal name="modal-add-categ" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">
                        Ajouter une catégorie
                    </h2>
                    <button @click="$modal.hide('modal-add-categ')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <input-rule v-model="newCategName" type="text" :label-inline="false" :label="$tt('Nom de la catégorie')" id="new-categ-name" />
                    <button :disabled="addCategLoader" @click="addNewCateg" class="mt-2 px-4 py-2 text-sm bg-green-500 text-white rounded">
                        <spinner v-if="addCategLoader" :size="10" />
                        <template v-if="!addCategLoader">
                            Ajouter <i class="fas fa-plus ml-1"></i>
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <div class="mb-4">
            <div class="flex items-end gap-2">
                <input-rule class="grow" type="vselect" v-model="newProductForm['id_categorie']" id="categorie" :options="{values: allCategories, label: 'fr', key: 'id_categorie'}" label="Catégorie" :label-inline="false" />
                <button @click="$modal.show('modal-add-categ')" class="px-4 py-2.5 rounded text-xs bg-slate-100 hover:bg-slate-200 duration-200"><i class="fas fa-plus mr-0.5"></i> Nouvelle catégorie</button>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mb-4">
            <div class="-mt-1.5">
                <label class="text-xs font-bold" :class="marqueError ? 'text-red-500' : 'text-gray-900'">Marque</label>

                <v-select
                v-model="marqueInp"
                :options="marques"
                @search="(search, loading) => { 
                    $forceUpdate()
                }"
                class="mt-1"
                label="Marque"
                ref="inpMarque"
                id="marque"
                >
                    <template v-slot:no-options="{search}">
                        <div class="hover:bg-blue-400 hover:text-white duration-200 text-left px-5 cursor-pointer py-1.5" @click="$refs.inpMarque.open = false; marques.push({Marque: search}); marqueInp = {Marque: search}">
                            <i class="fas fa-plus mr-0.5 "></i> <b>{{ search }}</b>
                        </div>
                    </template>
                </v-select>
                <ul v-if="marqueError" class="list-disc text-red-500 ml-4 mt-2 text-xs">
                    <li>{{ marqueError }}</li>
                </ul>
            </div>
            <!-- <input-rule v-model="newProductForm['Marque']" id="marque" type="text" label="Marque" :label-inline="false" custom="bg-white" /> -->
            <input-rule v-model="newProductForm['Modele']" id="modele" type="text" label="Modèle" :label-inline="false" custom="bg-white" />
        </div>
        <div class="grid grid-cols-2 gap-4 mb-4">
            <input-rule v-model="newProductForm['Ean']" id="ean" type="text" label="Ean" :label-inline="false" custom="bg-white" />
            <input-rule v-model="newProductForm['ref_commercial']" type="text" label="Ref. Commerciale" :label-inline="false" custom="bg-white" />
        </div>
        <div class="mb-4">
            <input-rule v-model="newProductForm['Libelle']" id="libelle" type="text" label="Libellé" :label-inline="false" custom="bg-white" />
        </div>
        <div class="grid grid-cols-2 gap-4 mb-4">
            <!-- <input-rule type="select" v-model="newProductForm['deee']" :options="{values: [], label: 'fr', key: 'id_categorie'}" label="DEEE" :label-inline="false" custom="bg-white" /> -->
            <input-rule v-model="newProductForm['poids']" type="text" label="Poids (kg)" :label-inline="false" custom="bg-white" id="poids" />
        </div>
        <div class="mb-4">
            <label class="text-xs font-bold text-gray-900">Documents</label>
            <div class="mt-1 relative overflow-x-auto">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 border">
                    <thead class="text-xs text-gray-900 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" class="px-6 py-1.5">
                                Type
                            </th>
                            <th scope="col" class="px-6 py-1.5">
                                Fichier
                            </th>
                            <th scope="col" class="px-6 py-1.5">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-white border-b">
                            <td class="px-6 py-1.5">
                                Notice
                            </td>
                            <td class="px-6 py-1.5">
                                <!-- <a href="#" class="text-blue-500 hover:underline font-light"><i class="fas fa-link mr-0.5"></i> Voir le fichier</a> -->
                                <input-rule @input="(data) => {if (data) {newProductForm.url_notice = data.url; $forceUpdate()}}" type="file" />
                            </td>
                            <td class="px-6 py-1.5">
                                <div v-if="newProductForm.url_notice && newProductForm.url_notice != '-1'" class="flex gap-1">
                                    <a target="_blank" :href="newProductForm.url_notice" class="px-2 py-1 bg-blue-500 hover:bg-blue-600 duration-200 text-xs text-white rounded"><i class="fas fa-eye"></i></a>
                                    <button @click="newProductForm.url_notice = '-1'; $forceUpdate()" class="px-2 py-1 bg-red-500 hover:bg-red-600 duration-200 text-xs text-white rounded"><i class="fas fa-trash"></i></button>
                                </div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b">
                            <td class="px-6 py-1.5">
                                Photo du produit
                            </td>
                            <td class="px-6 py-1.5 text-xs col-span-2">
                                <input-rule @input="(data) => {if (data) {newProductForm.url_photo = data.url; $forceUpdate()}}" type="file" />
                            </td>
                            <td class="px-6 py-1.5">
                                <div v-if="newProductForm.url_photo && newProductForm.url_photo != '-1'" class="flex gap-1">
                                    <a target="_blank" :href="newProductForm.url_photo" class="px-2 py-1 bg-blue-500 hover:bg-blue-600 duration-200 text-xs text-white rounded"><i class="fas fa-eye"></i></a>
                                    <button @click="newProductForm.url_photo = '-1'; $forceUpdate()" class="px-2 py-1 bg-red-500 hover:bg-red-600 duration-200 text-xs text-white rounded"><i class="fas fa-trash"></i></button>
                                </div>
                            </td>
                        </tr>
                        <tr class="bg-white border-b">
                            <td class="px-6 py-1.5">
                                Vue éclatée
                            </td>
                            <td class="px-6 py-1.5 text-xs col-span-2">
                                <input-rule @input="(data) => {if (data) {newProductForm.url_vue = data.url; $forceUpdate()}}" type="file" />
                            </td>
                            <td class="px-6 py-1.5">
                                <div v-if="newProductForm.url_vue && newProductForm.url_vue != '-1'" class="flex gap-1">
                                    <a target="_blank" :href="newProductForm.url_vue" class="px-2 py-1 bg-blue-500 hover:bg-blue-600 duration-200 text-xs text-white rounded"><i class="fas fa-eye"></i></a>
                                    <button @click="newProductForm.url_vue = '-1'; $forceUpdate()" class="px-2 py-1 bg-red-500 hover:bg-red-600 duration-200 text-xs text-white rounded"><i class="fas fa-trash"></i></button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="border-t-2 mt-6 pt-3">
            <button :disabled="loadingAddApp" @click="addProduct" class="px-4 py-2 rounded text-sm bg-green-200 hover:bg-green-300 cursor-pointer duration-200">
                <spinner v-if="loadingAddApp" :size="10" />
                <template v-if="!loadingAddApp">
                    <i class="fas fa-check mr-1"></i> Enregistrer
                </template>
            </button>
        </div>
    </div>
</template>

<script>
import ApiService from '@/services/api.service';

export default {
    data() {
        return {
            marques: [],
            allCategories: [],
            newProductForm: {},
            marqueError: null,
            marqueInp: {Marque: null},
            loadingAddApp: false,
            newCategName: null,
            addCategLoader: false,
        }
    },
    methods: {
        async getMarques() {
            let response = await this.$request.post('A5141getmarquefromgrossiste', {
                grossiste: ApiService.user().account.id_entite
            }, 'districloud_no_action')
            this.marques = response.data.posts.post
        },

        async getAllCategories() {
            let response = await this.$request.post('procedures.get-categs')
            this.allCategories = response.data.posts.post
        },

        async addProduct() {
            let endObj = {...this.newProductForm}
            endObj['Marque'] = this.marqueInp
            this.marqueError = null

            if (!this.$validation.check([
                {value: endObj['Ean'], rules: 'required|minLength:13|maxLength:13|num', id: 'ean'},
                {value: endObj['Modele'], rules: 'required', id: 'modele'},
                {value: endObj['Libelle'], rules: 'required', id: 'libelle'},
                {value: endObj.id_categorie, rules: 'required', id: 'categorie'},
                {value: endObj.poids, rules: 'num|min:0', id: 'poids'},
            ])) {return}

            if (!endObj['Marque']) {
                this.marqueError = 'Champ obligatoire'
                return
            }

            this.loadingAddApp = true
            let response = await this.$request.post('backoffice', {
                operation: 'addapp',
                id_four: ApiService.user().account.id_entite,
                ean: endObj['Ean'],
                marque: endObj['Marque']['Marque'],
                modele: endObj['Modele'],
                categ: endObj.id_categorie.id_categorie,
                libelle: endObj['Libelle'],
                ref_com: endObj.ref_commercial ? endObj.ref_commercial : '',
                poids: endObj.poids ? endObj.poids : 0,
                url_photo: endObj.url_photo ? endObj.url_photo : '-1',
                url_notice: endObj.url_notice ? endObj.url_notice : '-1',
                url_vue: endObj.url_vue ? endObj.url_vue : '-1',
            }, 'districloud_no_action')
            
            this.loadingAddApp = false

            if (response.data.posts.error == 0) {
                this.$modal.hide('modal-add-product')
                this.$toast.success('Appareil ajouté')
                this.newProductForm = {}
                this.$nextTick(() => {
                    this.marqueInp = null
                    this.$forceUpdate()
                })
                this.$emit('added')
                await this.getMarques()
                await this.getAllCategories()
            } else {
                this.$toast.error(response.data.posts.error)
            }
        },

        async addNewCateg() {
            if (!this.$validation.check([
                {value: this.newCategName, rules: 'required', id: 'new-categ-name'},
            ])) {return}

            this.addCategLoader = true
            let response = await this.$request.post('procedures.add-categ', {
                name: this.newCategName
            })

            if (response.data.posts.post == 1) {
                await this.getAllCategories()
                this.$forceUpdate()
                this.newProductForm['id_categorie'] = this.allCategories.find((item) => item.fr == this.newCategName)
                this.$toast.success('Catégorie ajoutée')
                this.newCategName = null
                this.$modal.hide('modal-add-categ')
            }
            this.addCategLoader = false
        }
    },
    mounted () {
        this.getMarques()
        this.getAllCategories()
    },
}
</script>

<style lang="scss" scoped>

</style>