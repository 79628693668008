import CryptoJS from "crypto-js"
const key = 'BT9aoJ0c'

function encrypt(value) {
    if (value) {
        var cryptValue = CryptoJS.AES.encrypt(value, key).toString();
        return cryptValue
    }
}

function decrypt(value) {
    if (value) {
        var bytes  = CryptoJS.AES.decrypt(value, key);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
}

var CryptoTool = {
    encrypt,
    decrypt
}

export default CryptoTool
