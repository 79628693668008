<template>
    <div>
        <app-template>
            <div class="bg-white p-7 rounded border shadow-sm mb-16">
                <categorie-form />
            </div>
        </app-template>
    </div>
</template>

<script>
import CategorieForm from './Components/CategorieForm.vue';

export default {
    components: {
        CategorieForm,
    },
}
</script>

<style lang="scss" scoped>

</style>