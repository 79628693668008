<template>
    <div>
        <app-template>
            <!-- Modal edit add -->
            <modal name="modal-bdc-editor" height="auto" width="90%" :scrollable="true">
                <div v-if="selectedLine">
                    <bdc-editor :default-id="selectedLine.id" :default-name="selectedLine.nom" />
                </div>
            </modal>

            <!-- Crud -->
            <div class="bg-white p-10 rounded shadow">
                <crud-content
                modal-width="75%"
                :can-view="false"
                :can-add="true"
                :can-edit="false"
                :can-delete="true"
                :id="$options.name"
                :label="{singular: 'un schéma', plural: 'des schémas'}"
                ref-keyword="nom"
                :routes="{
                    list: {key: 'getallpatterns', request: {action: 'bdc'}, api: 'districloud_preprod'},
                    delete: {key: 'deletepatternbyid', request: {action: 'bdc'}, keyname: 'id_pattern', api: 'districloud_preprod'}
                }"
                :table="[
                    {label: 'Nom', key: 'nom', type: 'text'},
                ]"
                >
                    <!-- Modal add -->
                    <template v-slot:modal-edit-add>
                        <h1 class="text-xl font-bold mb-4 pb-4 border-b">Ajouter un pattern</h1>
                        <input-rule v-model="modalForm.nom" type="text" label="Nom du schéma" class="mb-4" id="new-pattern-name" :label-inline="false" :display-errors="false" />
                        <input-rule class="mb-4" v-model="selectedCateg" label="Catégorie" type="select" :options="{values: listCategs, label: 'fr'}" id="get-categ" :label-inline="false" />
                        <template v-if="selectedCateg && codePannes && codePannes.length > 0">
                            <label class="block mb-1 text-xs font-bold">Codes pannes</label>
                            <div class="flex gap-2 font-light text-sm" v-for="(codePanne, codePanneIndex) in codePannes" :key="'code-panne-' + codePanneIndex">
                                <input :id="'code-' + codePanne.code" v-model="codePannesInp" type="checkbox" :value="codePanne" />
                                <label :for="'code-' + codePanne.code">{{codePanne.fr}}</label>
                            </div>
                        </template>

                        <spinner v-if="loadingModal" />
                        <button v-if="!loadingModal" @click="sendForm" class="mt-4 mr-1 text-sm bg-green-500 hover:bg-green-600 duration-200 cursor-pointer font-light px-5 py-2.5 rounded-xl text-white">
                            <i class="fas fa-check mr-0.5"></i> Ajouter
                        </button>
                    </template>

                    <!-- Actions buttons -->
                    <template v-slot:buttons="props">
                        <button @click="selectPattern(props.line)" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white">
                            <i class="fas fa-pen"></i>
                        </button>
                    </template>
                </crud-content>
            </div>
        </app-template>
    </div>
</template>

<script>
import CrudContent from '@/components/CrudContent.vue'
import BdcEditor from './BdcEditor.vue'

export default {
    name: 'bdc',
    components: {
        CrudContent,
        BdcEditor,
    },
    watch: {
        selectedCateg() {
            this.getCodePannes()
        }
    },
    data() {
        return {
            modalForm: {
                nom: ''
            },
            loadingModal: false,
            selectedLine: null,
            listCategs: [],
            selectedCateg: null,
            codePannesInp: [],
            codePannes: []
        }
    },
    methods: {
        async sendForm() {
            if (!this.$validation.check([
                {value: this.modalForm.nom, rules: 'required', id: 'new-pattern-name'},
                {value: this.selectedCateg?.id, rules: 'required'},
            ])) {this.$toast.error('Formulaire incomplet'); return}

            await this.$request.post('editadd_pattern', {
                action: 'bdc',
                nom_pattern: this.modalForm.nom,
                id_categ: this.selectedCateg.id,
                code_symptome: this.codePannesInp && this.codePannesInp.length > 0 ? JSON.stringify(this.codePannesInp?.map((codePanne) => codePanne.code)) : JSON.stringify([]),
            }, 'districloud_preprod')
            this.modalForm = {}
            this.codePannesInp = []
            this.selectedCateg = null
            this.$modal.hide('modal-edit-add-bdc')
            this.$eventHub.$emit('refresh-crud', this.$options.name)
        },

        selectPattern(pattern) {
            this.selectedLine = pattern
            this.$modal.show('modal-bdc-editor')
        },

        async getListCategs() {
            let response = await this.$request.post('getcateg', {
                action: 'bdc'
            }, 'districloud_preprod')
            this.listCategs = response.data.posts.post
        },

        async getCodePannes() {
            if (this.selectedCateg) {
                this.codePannes = []
                let response = await this.$request.post('getcodepanne', {
                    action: 'bdc',
                    id_categ: this.selectedCateg.id,
                }, 'districloud_preprod')
                this.codePannes = response.data.posts.post
                this.codePannes.forEach((code) => {
                    if (this.codes.find((c) => code.code == c)) {
                        this.codePannesInp.push(code)
                    }
                })
            }
        },
    },
    mounted () {
        this.getListCategs();
    },
}
</script>

<style lang="scss" scoped>

</style>
