<template>
    <div>
        <modal name="modal-edit-node" height="auto" :scrollable="true">
            <div v-if="selectedNode" class="p-10">
                <template v-if="selectedNode.data.type == 'message-bot'">
                    <bdc-view-message :id="selectedNode.data.db.id" @updated="updatedModalNode" :node="selectedNode" />
                </template>
                <template v-if="selectedNode.data.type == 'message-user'">
                    <bdc-view-response :id="selectedNode.data.db.id" @updated="updatedModalNode" :node="selectedNode" />
                </template>
            </div>
        </modal>
        <div id="grid-editor" class="grid grid-cols-7">
            <div class="col-span-5">
                <VueDiagramEditor
                v-if="displayDiagram"
                ref="diagram"
                :node-color="nodeColor"
                :node-pulsable="nodePulsable"
                :port-disabled="disabledPort"
                :node-deletable="nodeDeletable"
                @updated-node="updatedSchemaNode"
                >
                    <div @click="node.data.type != 'end-form' ? selectNode(node) : null" :class="node.data.type != 'end-form' ? 'hover:bg-slate-100 duration-100 cursor-pointer' : 'cursor-not-allowed'" class="p-2 font-light" slot="node" slot-scope="{node}">
                        <div class="text-sm font-semibold" :style="'color: ' + getNodeColor(node)">
                            <template v-if="node.data.type == 'message-bot'">
                                <i class="fas fa-robot"></i>
                            </template> 
                            {{ node.data.label || '-' }}
                        </div>
                    </div>
                </VueDiagramEditor>
            </div>
            <div class="col-span-2 p-2 border-l overflow-y-auto h-[500px]">
                <bdc-menu-new-node :pattern-id="idPattern" />
                <div v-if="menuType == 'menu'">
                    <h2 class="font-bold border-b pb-3 text-slate-700">{{ defaultName }}</h2>
                    <div class="p-3 border-b text-sm font-light hover:bg-slate-100 duration-200 cursor-pointer" @click="menuType = 'simulation'">
                        <i class="fa-solid fa-play text-green-500 mr-1"></i> Lancer la simulation
                    </div>
                    <div class="p-3 border-b text-sm font-light hover:bg-slate-100 duration-200 cursor-pointer" @click="menuType = 'edit-pattern'">
                        <i class="fa-solid fa-pen text-blue-500 mr-1"></i> Editer le schéma
                    </div>
                    <div class="p-3 border-b text-sm font-light hover:bg-slate-100 duration-200 cursor-pointer" @click="$modal.hide('modal-bdc-editor')">
                        <i class="fa-solid fa-right-from-bracket text-red-500 mr-1"></i> Quitter
                    </div>
                </div>
                <div v-if="menuType == 'simulation'">
                    <div class="flex items-center justify-between border-b pb-3 mb-3">
                        <h2 class="text-sm font-bold"><i class="fas fa-play text-green-500 mr-1"></i> Simulation</h2>
                        <button @click="menuType = 'menu'; resetSimulation()"><i class="fas fa-times text-red-500"></i></button>
                    </div>
                    <bdc-simulation v-if="pattern" :pattern="pattern" />
                </div>
                <div v-if="menuType == 'edit-pattern'">
                    <div class="flex items-center justify-between border-b pb-3 mb-3">
                        <h2 class="text-sm font-bold"><i class="fas fa-pen text-blue-500 mr-1"></i> Editer le schéma</h2>
                        <button @click="menuType = 'menu'"><i class="fas fa-times text-red-500"></i></button>
                    </div>
                    <bdc-edit-add-pattern v-if="pattern" :pattern="pattern" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueDiagramEditor from 'vue-diagram-editor';
import 'vue-diagram-editor/dist/vue-diagram-editor.css';

import BdcViewMessage from './Components/BdcViewMessage.vue'
import BdcViewResponse from './Components/BdcViewResponse.vue'
import BdcEditAddPattern from './Components/BdcEditAddPattern.vue'
import BdcMenuNewNode from './Components/BdcMenuNewNode.vue'

import BdcSimulation from './Components/BdcSimulation.vue'

export default {
    components: {
        VueDiagramEditor,

        BdcViewMessage,
        BdcViewResponse,
        BdcEditAddPattern,
        BdcMenuNewNode,

        BdcSimulation
    },

    props: {
        defaultId: {},
        defaultName: {},
    },

    data() {
        return {
            idPattern: null,
            pattern: null,
            selectedNode: null,
            modalOutputs: [],
            nodes: {},
            links: {},

            displayDiagram: false,

            menuType: 'menu'
        }
    },

    computed: {
        nextId() {
            return Object.keys(this.nodes).length + 1
        }
    },

    methods: {
        /* ---------------
          INIT & RESET
        ----------------*/

        async init() {
            // this.$refs.diagram.disableDblClickZoom()
            this.refresh()
            let diagram = document.querySelector('.diagram-editor__wrapper rect[x="-5000px"][y="-5000px"]')
            if (diagram) {
                diagram.setAttribute('fill', '#ededed')
            }
        },
        
        async refresh() {
            await this.getPattern()
            this.resetSchema()
        },
        
        resetSchema() {
            this.$refs.diagram.setModel({
                nodes: this.nodes,
                links: this.links
            });

            this.$nextTick(() => {
                // Style of node
                for (let node in this.nodes) {
                    let nodeEl = document.querySelector('[id="' + node + '"]')
                    if (nodeEl) {
                        let nodeTitle = nodeEl.querySelector('svg[style] svg svg text')
                        if (nodeTitle) {   
                            nodeTitle.classList.add("font-thin");
                            nodeTitle.classList.add("text-xs");
                        }
                    }
                }
                // Style of links
                for (let link in this.links) {
                    let line = document.querySelector('#' + link + ' [stroke-width="2"]')
                    if (line) {
                        line.setAttribute('stroke-width', 1)
                    }
                }
            })
        },

        /* ---------------
          Librairie conf
        ----------------*/

        nodeColor(node) {
            if (node.data.type == 'message-bot') {
                return '#93bddb'
            }
            if (node.data.type == 'message-user') {
                return '#a274e3'
            }
            if (node.data.type == 'end-form') {
                if (node.data.resolu == 1) {
                    return '#34ad5c'
                } else {
                    return '#b0392e'
                }
            }
            return '#000'
        },
        nodePulsable(node) {
            // Node data is not filled
            if ((!node.data.label || !node.data.name) && (node.data.type != 'end-form')) {
                return true
            }

            // Node is not linked
            if (this.nodes[Object.keys(this.nodes)[0]].id != node.id && Object.keys(node.portsIn).length <= 0) {
                return true
            }

            // Node hasn't output
            if (Object.keys(node.portsOut).length <= 0 && node.data.type != 'end-form') {
                return true
            }
            return false
        },
        disabledPort() {
            return true
        },
        nodeDeletable() {
            return false
        },

        /* ---------------
          MODAL FORM
        ----------------*/
        selectNode(node) {
            if (node) {
                this.selectedNode = JSON.parse(JSON.stringify(node))
                this.modalOutputs = []
                this.$modal.show('modal-edit-node')
            }
        },

        async updatedSchemaNode(node) {
            if (node.data.db) {
                await this.$request.post('updatebloc', {
                    action: 'bdc',
                    type: node.data.type == 'message-bot' ? 'message' : 'reponse',
                    current_id: node.data.db.id,
                    meta: JSON.stringify({
                        coordinates: node.coordinates,
                        size: node.size
                    })
                }, 'districloud_preprod')
            }
        },

        updatedModalNode() {
            // this.$modal.hide('modal-edit-node')
            this.init()
        },

        /* ---------------
          Init data
        ----------------*/

        async getPattern() {
            let response = await this.$request.post('getallblocs', {
                action: 'bdc',
                id_pattern: this.idPattern
            }, 'districloud_preprod')

            if (response.data.posts.error) {
                this.$toast.error(response.data.posts.error)
            }

            this.pattern = response.data.posts.post
            let messages = response.data.posts.post.messages
            let reponses = response.data.posts.post.reponses

            let size = null
            let portsIn = {}
            let portsOut = {}
            this.nodes = {}
            this.links = {}
            // init messages
            if (messages && messages.length > 0) {
                messages.forEach((msg) => {
                    portsIn = {}
                    portsOut = {}
                    // Msg meta
                    if (msg.meta) {
                        msg.meta = JSON.parse(msg.meta)
                    } else {
                        msg.meta = {}
                    }
                    // Set label depend of message type
                    let label = null
                    if (!msg.meta.typeMessage || msg.meta.typeMessage == 'message') {
                        label = msg.message
                    }
                    if (msg.meta.typeMessage && msg.meta.typeMessage == 'file') {
                        label = 'Fichier'
                    }
                    if (msg.meta.typeMessage && msg.meta.typeMessage == 'link') {
                        label = 'Lien'
                    }
                    if (msg.meta.typeMessage && msg.meta.typeMessage == 'youtube') {
                        label = 'Vidéo Youtube'
                    }
                    // Get card size
                    if (!msg.message) {
                        msg.message = ''
                    }
                    if (msg.meta && msg.meta.size) {
                        size = {width: msg.meta.size.width, height: msg.meta.size.height }
                    } else {
                        size = {width: 150, height: 68 + (16 * (label.length / 22))}
                    }
                    // Init out ports
                    if (msg.question_id && msg.question_id != '0') {
                        portsOut['msg-' + msg.question_id] = ''
                    } else {
                        let hasReponses = reponses.filter((msgRep) => msgRep.question_id == msg.id)
                        if (hasReponses && hasReponses.length > 0) {
                            hasReponses.forEach((hasReponse) => {
                                portsOut['rep-' + hasReponse.id] = ''
                            })
                        }
                    }
                    // Init in ports
                    let hasPortIn = messages.find((sMsg) => msg.id == sMsg.question_id) || reponses.find((rep) => rep.suivant_id == msg.id)
                    if (hasPortIn) {
                        portsIn['default'] = ''
                    }
                    // Init node
                    this.nodes['msg-' + msg.id] = {
                        id: 'msg-' + msg.id,
                        title: msg.name,
                        size: size,
                        coordinates: msg.meta.coordinates,
                        portsIn: portsIn,
                        portsOut: portsOut,
                        data: {
                            type: 'message-bot',
                            name: msg.name,
                            label: label,
                            db: msg,
                        }
                    }

                    // Manage end tchat
                    if (msg.resolu != '0') {
                        this.nodes['msg-' + msg.id].portsOut['end-' + msg.id] = 'Fin'
                        this.nodes['end-' + msg.id] = {
                            id: 'end-' + msg.id,
                            title: 'Fin de formulaire',
                            size: {width: 150, height: 68 + (16 * (msg.message.length / 22))},
                            coordinates: {x: msg.meta.coordinates.x + 150 + 20, y: msg.meta.coordinates.y},
                            portsIn: {default: ''},
                            portsOut: {},
                            data: {
                                type: 'end-form',
                                label: msg.resolu == 1 ? 'Résolu' : 'Non résolu',
                                resolu: msg.resolu
                            }
                        }
                    }
                })
            }

            // Init responses
            if (reponses && reponses.length > 0) {
                reponses.forEach((rep) => {
                    portsIn = {}
                    portsOut = {}
                    // Get card size
                    if (rep.meta) {
                        rep.meta = JSON.parse(rep.meta)
                    } else {
                        rep.meta = {}
                    }
                    if (!rep.message) {
                        rep.message = ''
                    }
                    if (rep.meta && rep.meta.size) {
                        size = {width: rep.meta.size.width, height: rep.meta.size.height }
                    } else {
                        size = {width: 150, height: 68 + (16 * (rep.message.length / 22))}
                    }
                    // Init out ports
                    if (rep.suivant_id && rep.suivant_id != '0') {
                        portsOut['msg-' + rep.suivant_id] = ''
                    }
                    // Init int ports
                    portsIn['default'] = ''
                    // Init node
                    this.nodes['rep-' + rep.id] = {
                        id: 'rep-' + rep.id,
                        title: rep.name,
                        size: size,
                        coordinates: rep.meta.coordinates,
                        portsIn: portsIn,
                        portsOut: portsOut,
                        data: {
                            type: 'message-user',
                            name: rep.name,
                            label: rep.reponse,
                            db: rep
                        }
                    }

                    // Manage end tchat
                    if (rep.resolu != '0') {
                        this.nodes['rep-' + rep.id].portsOut['end-' + rep.id] = 'Fin'
                        this.nodes['end-' + rep.id] = {
                            id: 'end-' + rep.id,
                            title: 'Fin de formulaire',
                            size: {width: 150, height: 68 + (16 * (rep.message.length / 22))},
                            coordinates: {x: rep.meta.coordinates.x + 150 + 20, y: rep.meta.coordinates.y},
                            portsIn: {default: ''},
                            portsOut: {},
                            data: {
                                type: 'end-form',
                                label: rep.resolu == 1 ? 'Résolu' : 'Non résolu',
                                resolu: rep.resolu
                            }
                        }
                    }
                })
            }

            // Make link
            for (const key in this.nodes) {
                if (this.nodes[key].portsOut) {
                    for (const keyOut in this.nodes[key].portsOut) {
                        if (keyOut) {
                            this.links['link-' + key + '-' + keyOut] = {
                                id: 'link-' + key + '-' + keyOut,
                                start_id: key,
                                start_port: keyOut,
                                end_id: keyOut,
                                end_port: 'default'
                            }
                        }
                    }
                }
            }
        },

        getNodeColor(node) {
            switch (node.data.type) {
                case 'message-bot':
                    return '#494e4f'
                case 'message-user':
                    return '#593091'
                case 'end-form':
                    if (node.data.resolu == 1) {
                        return '#34ad5c'
                    } else {
                        return '#b0392e'
                    }
            }
            return '#000'
        },
    },

    mounted () {
        if (!this.$route.query.pattern && !this.defaultId) {
            this.$toast.error('Pattern obligatoire')
            return
        }
        this.idPattern = this.$route.query.pattern || this.defaultId
        setTimeout(() => {
            this.displayDiagram = true
            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.init()
                })
            })
        }, 500);
        this.init()

        this.$eventHub.$on('select-new-node', async (id) => {
            await this.refresh()
            this.selectNode(this.nodes[id])
        })
    },
}
</script>

<style>
.tchat-message {
    font-size: 14px !important;
}

.diagram-editor__wrapper, .diagram-editor__wrapper svg {
    height: 90vh !important;
}
</style>
