<template>
    <div>
        <!-- Modal new procs -->
        <modal name="modal-new-procs" height="auto" width="90%" :scrollable="true" :click-to-close="false">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b">
                    <div>
                        <h2 class="text-xl font-semibold"><i class="fas fa-plus mr-1"></i> {{$tt('Ajouter une procédure')}}</h2>
                    </div>
                    <button @click="closeModalNewProcs" class="text-2xl">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div v-if="selectedCategConfig">
                    <iframe width="100%" height="750px" :src="currentDomain + '/widget/gestion-categories?display-menu=0&categ-id=' + selectedCategConfig.id"></iframe>
                </div>
            </div>
        </modal>

        <!-- Modal not apply proc -->
        <modal name="modal-not-apply-proc" height="auto" width="90%" :scrollable="true" :click-to-close="false">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b">
                    <div>
                        <h2 class="text-xl font-semibold">{{$tt("Import terminé")}}</h2>
                    </div>
                </div>
                <div>
                    <div class="bg-orange-100 text-orange-800 p-2 rounded font-bold"><i class="fas fa-info-circle mr-1"></i> {{ $tt("N'oubliez pas d'appliquer vos procédures aux distributeurs") }}</div>
                    <div class="bg-green-100 text-green-800 p-2 rounded mt-2"><i class="fas fa-check mr-1"></i> {{ $tt('Vous avez terminé votre import') }}</div>
                    <button @click="$modal.hide('modal-not-apply-proc'); $modal.hide('modal-import-list')" class="px-4 py-2 rounded mt-4 bg-red-500 hover:bg-red-600 duration-200 text-white">
                        <i class="fas fa-times mr-1"></i> Fermer la fenêtre
                    </button>
                </div>
            </div>
        </modal>

        <!-- Modal confirm close -->
        <modal name="modal-confirm-close" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b">
                    <div>
                        <h2 class="text-xl font-semibold">{{$tt('Confirmation')}}</h2>
                    </div>
                    <button @click="$modal.hide('modal-confirm-close')" class="text-2xl">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <p class="bg-red-100 text-red-800 font-bold p-4 rounded">
                        <i class="fas fa-exclamation-triangle mr-1"></i>
                        {{ $tt('Êtes-vous certain de vouloir fermer cette fenêtre sans avoir importé') + ' ' + (fileContents.length > 1 ? $tt('les') : '') + ' ' + fileContents.length + ' ' + (fileContents.length > 1 ? $tt('appareils') : $tt('appareil')) }} ?
                    </p>
                    <div class="mt-4 flex gap-2 items-center">
                        <button @click="$modal.hide('modal-confirm-close')" class="px-4 py-2 bg-green-200 hover:bg-green-300 duration-200 rounded"><i class="fas fa-arrow-left mr-1"></i> {{ $tt("Retour à l'import") }}</button>
                        <button @click="$modal.hide('modal-confirm-close'); $modal.hide('modal-import-list')" class="px-4 py-2 bg-orange-200 hover:bg-orange-300 duration-200 rounded"><i class="fas fa-exclamation-triangle mr-1"></i> {{ $tt("Annuler l'import") }}</button>
                    </div>
                </div>
            </div>
        </modal>

        <!-- Modal Ignore -->
        <modal name="modal-ignore-errors" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b">
                    <div>
                        <h2 class="text-xl font-semibold"><i class="fas fa-exclamation-triangle mr-1"></i> {{$tt('Ignorer les erreurs')}}</h2>
                    </div>
                    <button @click="$modal.hide('modal-ignore-errors')" class="text-2xl">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <p class="font-light">{{ $tt("En ignorant les erreurs, vous n'importerez que les produits validés par le système. Tout les autres produits ne seront pas importés") }}.</p>
                    <div class="flex items-center gap-2 mt-4">
                        <button @click="$modal.hide('modal-ignore-errors')" class="px-4 py-2 bg-slate-200 hover:bg-slate-300 duration-200 text-sm rounded"><i class="fas fa-times mr-1"></i> Annuler</button>
                        <button @click="ignoreErrors" class="px-4 py-2 bg-orange-500 hover:bg-orange-600 duration-200 text-white text-sm rounded">Ignorer et continuer <i class="fas fa-check ml-1"></i></button>
                    </div>
                </div>
            </div>
        </modal>

        <!-- Modal conf -->
        <modal name="modal-edit-file-conf" height="auto" :scrollable="true">
            <div class="p-10 min-h-[500px]">
                <div class="flex items-center justify-between mb-4 pb-4 border-b">
                    <div>
                        <h2 class="text-2xl font-semibold text-orange-500"><i class="fas fa-exclamation-triangle mr-1"></i> {{$tt('Nous avons detecté un nouveau format de fichier')}}</h2>
                        <h4 class="">{{$tt("Merci d'enregistrer cette nouvelle configuration afin de pouvoir continuer")}}</h4>
                    </div>
                    <button @click="$modal.hide('modal-edit-file-conf')" class="text-2xl">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="border border-b-0">
                        <div class="grid grid-cols-3 items-center p-3 bg-slate-100 border-b">
                            <div class="font-light text-sm">{{$tt('Champs demandés')}}</div>
                            <div class="font-light text-sm col-span-2">{{$tt('Correspondance dans votre fichier')}}</div>
                        </div>
                        <div class="p-3 border-b hover:bg-slate-50 duration-200" v-for="(column, columnIndex) in newConf" :key="'column-' + columnIndex">
                            <div class="grid grid-cols-3 items-center">
                                <div>
                                    <div class="font-bold">{{column.label}}</div>
                                    <span class="bg-purple-200 font-light rounded p-1 px-2" style="font-size: 10px" v-if="column.oblig">{{$tt('Obligatoire')}}</span>
                                    <span class="bg-slate-100 text-slate-800 font-light rounded p-1 px-2" style="font-size: 10px" v-if="!column.oblig">{{$tt('Champ optionnel')}}</span>
                                </div>
                                <div class="col-span-2">
                                    <input-rule type="select" :options="{values: fileHeaders}" v-model="column.inp" :id="'inp-conf-' + column.key" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-8">
                        <button @click="createNewConf" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 rounded text-white"><i class="fas fa-check mr-1"></i> {{$tt('Enregistrer et continuer')}}</button>
                    </div>
                </div>
            </div>
        </modal>

        <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
            <h2 class="font-bold">
                {{$tt('Import par fichier')}}
            </h2>
            <button @click="closeModal">
                <i class="fas fa-times text-red-500"></i>
            </button>
        </div>

        <!-- Stepper -->
        <div v-if="withProc" class="mb-4">
            <ol class="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm gap-3">
                <li @click="changeStep(1)" class="flex items-center cursor-pointer" :class="step == 1 ? 'text-blue-600' : ''">
                    <span class="flex items-center justify-center w-5 h-5 me-2 text-xs border rounded-full shrink-0" :class="step == 1 ? 'border-blue-600' : 'border-gray-500'">
                        1
                    </span>
                    {{$tt('Import du fichier')}}
                    <svg class="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 9 4-4-4-4M1 9l4-4-4-4"/>
                    </svg>
                </li>
                <li @click="changeStep((fileContents && fileContents.length > 0) ? 2 : 1)" class="flex items-center cursor-pointer" :class="step == 2 ? 'text-blue-600' : ''">
                    <span class="flex items-center justify-center w-5 h-5 me-2 text-xs border rounded-full shrink-0" :class="step == 2 ? 'border-blue-600' : 'border-gray-500'">
                        2
                    </span>
                    {{$tt('Vérification du fichier')}}
                </li>
                <!-- <li @click="goToProcStep" class="flex items-center cursor-pointer" :class="step == 3 ? 'text-blue-600' : ''">
                    <span class="flex items-center justify-center w-5 h-5 me-2 text-xs border rounded-full shrink-0" :class="step == 3 ? 'border-blue-600' : 'border-gray-500'">
                        3
                    </span>
                    {{$tt('Procédures')}}
                </li> -->
            </ol>
        </div>

        <!-- Content -->
        <div>
            <div v-show="step == 1">
                <div v-if="fileConfig" class="grid grid-cols-5 gap-4 py-5">
                    <div class="bg-slate-50 rounded-lg p-4 col-span-3">
                        <div>
                            <label class="font-bold text-gray-900">{{$tt('Importer le fichier CSV')}}</label>
                            <input ref="fileuploadcsv" class="border text-xs p-2 bg-white w-full mt-2" type="file" @change="loadCsvFile" accept=".csv">
                        </div>
    
                        <button v-if="csvFile" :disabled="loadingUploadCsv" @click="makeCsvImport" class="mt-4 px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 text-white text-sm rounded mt-2">
                            <spinner v-if="loadingUploadCsv" :size="10" />
                            <template v-if="!loadingUploadCsv">
                                {{$tt('Importer le fichier')}} <i class="fas fa-check ml-1"></i>
                            </template>
                        </button>
                    </div>
                    <div class="p-4 mb-2 text-sm text-yellow-800 rounded-lg bg-yellow-100 col-span-2" role="alert">
                        <div class="font-bold text-base mb-1 border-b pb-3 mb-3 border-orange-800"><i class="fas fa-sheet-plastic mr-1"></i> {{$tt("Notice d'utilisation")}}</div>
                        <div class="mt-2">
                            <button @click="downloadCsvModel" :disabled="loadingDownloadModel" class="text-orange-900 hover:underline cursor-pointer flex items-center gap-2"><i class="fas fa-download mr-1"></i> {{$tt("Télécharger le fichier d'exemple")}} <spinner v-if="loadingDownloadModel" :size="2" /></button>
                            <div @click="noticeOpens.formatFichier = !noticeOpens.formatFichier" class="text-orange-900 hover:underline cursor-pointer"><i class="fas fa-question mr-1"></i> {{ $tt("Comment utiliser un format de fichier différent") }} ?</div>
                            <div v-if="noticeOpens.formatFichier" class="bg-yellow-300 rounded mt-2 mb-5 p-4 text-orange-900">
                                <div>{{ $tt("Vous pouvez importer n'importe quel fichier au format .csv. Si ce fichier est différent du format de fichier précedemment enregistré, le logiciel vous proposera de modifier votre format de fichier") }}.</div>
                            </div>
                            <div @click="noticeOpens.convertExcel = !noticeOpens.convertExcel" class="text-orange-900 hover:underline cursor-pointer"><i class="fas fa-question mr-1"></i> {{$tt('Comment convertir un fichier Excel en CSV')}} ?</div>
                            <div v-if="noticeOpens.convertExcel" class="bg-yellow-300 rounded mt-2 mb-5 p-4 text-orange-900 max-h-[500px] overflow-y-auto">
                                <div class="mb-8">
                                    <h2 class="text-xl font-bold mb-2">{{$tt('Utilisation de Microsoft Excel')}}</h2>

                                    <h3 class="font-semibold underline mb-1">{{$tt('Étape 1 : Ouvrir le Fichier Excel')}}</h3>
                                    <ol class="list-decimal list-inside mb-4">
                                        <li>{{ $tt('Lancez Microsoft Excel') }}.</li>
                                        <li>{{ $tt('Cliquez sur "Fichier" dans le coin supérieur gauche') }}.</li>
                                        <li>{{$tt('Sélectionnez "Ouvrir" et choisissez le fichier Excel que vous souhaitez convertir')}}.</li>
                                    </ol>

                                    <h3 class="font-semibold underline mb-1">{{$tt('Étape 2 : Sauvegarder en Format CSV')}}</h3>
                                    <ol class="list-decimal list-inside mb-4">
                                        <li>{{$tt('Une fois le fichier ouvert, cliquez à nouveau sur "Fichier"')}}.</li>
                                        <li>{{$tt('Sélectionnez "Enregistrer sous" ou "Exporter" (selon la version d\'Excel).')}}</li>
                                        <li>{{$tt('Choisissez l\'emplacement où vous souhaitez enregistrer le fichier.')}}</li>
                                        <li>{{$tt('Dans le champ "Nom du fichier", entrez le nom souhaité pour le fichier CSV.')}}</li>
                                        <li>{{$tt('Dans le menu déroulant "Type de fichier" ou "Format", sélectionnez "CSV (séparateur: point-virgule)" ou "CSV UTF-8 (délimité par des virgules)" selon vos besoins.')}}</li>
                                        <li>{{$tt('Cliquez sur "Enregistrer".')}}</li>
                                    </ol>

                                    <p class="italic">{{$tt('Remarques')}} :</p>
                                    <ul class="list-disc list-inside mb-4">
                                        <li>{{$tt('Si votre feuille Excel contient plusieurs onglets, seuls les données de l\'onglet actif seront sauvegardées dans le fichier CSV')}}.</li>
                                        <li>{{$tt("Assurez-vous que vos données ne contiennent pas de virgules (si vous utilisez un délimiteur de virgule) ou de points-virgules (si vous utilisez ce séparateur), ou bien choisissez un autre séparateur pour éviter des erreurs lors de l'importation")}}.</li>
                                    </ul>
                                </div>

                                <div class="mb-8">
                                    <h2 class="text-xl font-bold mb-2">{{$tt('Utilisation de Google Sheets')}}</h2>

                                    <h3 class="font-semibold underline mb-1">{{ $tt('Étape 1 : Importer le Fichier Excel dans Google Sheets') }}</h3>
                                    <ol class="list-decimal list-inside mb-4">
                                        <li>{{$tt('Allez sur')}} <a href="https://sheets.google.com" class="text-blue-500 hover:underline">Google Sheets</a>.</li>
                                        <li>{{ $tt('Cliquez sur le bouton "Ouvrir un fichier" (icône de dossier) ou allez dans "Fichier" > "Importer"') }}.</li>
                                        <li>{{ $tt('Sélectionnez "Importer un fichier" et choisissez le fichier Excel à partir de votre ordinateur') }}.</li>
                                    </ol>

                                    <h3 class="font-semibold underline mb-1">{{ $tt('Étape 2 : Sauvegarder en Format CSV') }}</h3>
                                    <ol class="list-decimal list-inside mb-4">
                                        <li>{{$tt('Une fois le fichier Excel importé et ouvert dans Google Sheets, cliquez sur "Fichier"')}}.</li>
                                        <li>{{$tt('Sélectionnez "Télécharger sous"')}}.</li>
                                        <li>{{$tt('Choisissez "Valeurs séparées par des virgules (.csv, feuille actuelle)"')}}.</li>
                                    </ol>

                                    <p class="italic">{{$tt('Remarques')}} :</p>
                                    <ul class="list-disc list-inside mb-4">
                                        <li>{{$tt('Comme pour Excel, si votre document contient plusieurs feuilles, vous devez exporter chaque feuille individuellement')}}.</li>
                                    </ul>
                                </div>

                                <div class="mb-8">
                                    <h2 class="text-xl font-bold mb-2">{{$tt('Utilisation de Logiciels en Ligne (Alternatif)')}}</h2>
                                    <p class="mb-4">{{$tt('Si vous ne disposez pas de Microsoft Excel ou Google Sheets, vous pouvez utiliser des outils en ligne comme')}} <a href="https://www.zamzar.com/convert/xlsx-to-csv/" class="text-blue-500 hover:underline">Zamzar</a>, <a href="https://convertio.co/" class="text-blue-500 hover:underline">Convertio</a>, {{$tt('ou')}} <a href="https://www.online2pdf.com/" class="text-blue-500 hover:underline">Online2pdf</a>. {{$tt('Voici les étapes générales')}} :</p>

                                    <h3 class="font-semibold underline mb-1">{{ $tt('Étape 1 : Accéder à un Service de Conversion en Ligne') }}</h3>
                                    <ol class="list-decimal list-inside mb-4">
                                        <li>{{ $tt('Ouvrez votre navigateur et allez sur le site de conversion de votre choix, par exemple') }} <a href="https://www.zamzar.com/convert/xlsx-to-csv/" class="text-blue-500 hover:underline">Zamzar</a>.</li>
                                    </ol>

                                    <h3 class="font-semibold underline mb-1">{{ $tt('Étape 2 : Télécharger et Convertir le Fichier') }}</h3>
                                    <ol class="list-decimal list-inside mb-4">
                                        <li>{{ $tt('Téléchargez votre fichier Excel sur le site.')}}'</li>
                                        <li>{{ $tt('Sélectionnez le format de sortie comme CSV.')}}'</li>
                                        <li>{{ $tt('Cliquez sur "Convertir" et suivez les instructions pour télécharger le fichier CSV converti.')}}'</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div class="hidden">
                            <div><i class="fas fa-exclamation-triangle mr-1"></i> {{$tt('Il est important d\'utiliser le même format que le fichier CSV d\'exemple')}}</div>
                            <button @click="downloadCsvModel" class="bg-yellow-800 hover:bg-yellow-900 duration-200 text-yellow-50 px-4 py-2 mt-2 rounded text-xs">
                                <template v-if="!loadingDownloadModel">
                                    {{$tt("Télécharger le fichier d'exemple")}} <i class="fas fa-download ml-1"></i>
                                </template>
                                <spinner v-if="loadingDownloadModel" :size="10" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="step == 2">
                <template v-if="Object.keys(fileErrors).length <= 0">
                    <template v-if="!importationResultsProcs">
                        <div class="grid grid-cols-4 mt-10 items-center gap-4">
                            <div class="text-center text-green-600 border-2 border-green-600 p-6 rounded-xl">
                                <i class="fas fa-check-circle mr-1 text-2xl"></i>
                                <div class="mt-2 font-semibold">{{$tt('Votre fichier est vérifié')}}</div>
                            </div>
                            <table class="text-left text-slate-800 col-span-3">
                                <tbody>
                                    <tr>
                                        <td class="px-4 py-2 border">{{ $tt('Lignes trouvées') }}</td>
                                        <td class="px-4 py-2 border">{{ nbFileLines }}</td>
                                    </tr>
                                    <tr v-if="nbErrors > 0">
                                        <td class="px-4 py-2 border text-orange-500">
                                            <div class="flex items-center gap-2 justify-between">
                                                <div>{{ $tt('Lignes à corriger') }}</div>
                                                <button @click="downloadStackErrors" class="px-4 py-2 text-xs font-light bg-slate-100 hover:bg-slate-200 duration-200 rounded text-slate-800"><i class="fas fa-download mr-1"></i> {{ $tt('Télécharger la trace') }}</button>
                                            </div>
                                        </td>
                                        <td class="px-4 py-2 border text-orange-500">{{ nbErrors }}</td>
                                    </tr>
                                    <tr>
                                        <td class="px-4 py-2 border bg-slate-50 font-bold">
                                            <div>{{ $tt("Lignes validées, prêt à être importées") }}</div>
                                        </td>
                                        <td class="px-4 py-2 border bg-slate-50 font-bold">{{ fileContents.length }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="text-right mt-4">
                            <button @click="importFile" :disabled="loadingImport" class="px-4 py-2 font-semibold bg-green-200 hover:bg-green-300 duration-200 rounded text-green-800 shadow">
                                <spinner :size="10" v-if="loadingImport" />
                                <template v-if="!loadingImport">
                                    <i class="fas fa-cloud-arrow-up mr-1"></i> {{$tt('Importer') + ' ' + fileContents.length + ' ' + (fileContents.length > 1 ? $tt('appareils') : $tt('appareil'))}}
                                </template>
                            </button>
                        </div>
                    </template>
                    <template v-if="importationResultsProcs">
                        <!-- <template v-if="importationResultsProcs.without_proc <= 0"> -->
                            <div class="bg-green-100 border border-green-600 text-green-800 rounded p-4">
                                <h1 class="text-xl font-bold">{{ $tt('Vos appareils ont été importés') }}</h1>
                                <p class="font-light border-b border-green-600 pb-3 mb-3">Votre importation est terminée</p>
                                <button @click="$modal.hide('modal-import-list')" class="px-4 py-2 bg-green-800 hover:bg-green-900 text-white duration-200 rounded">{{ $tt('Terminer') }} <i class="fas fa-check ml-1"></i></button>
                            </div>
                            <div class="mt-4 bg-orange-100 border border-orange-800 text-orange-800 rounded p-4">
                                <h1 class="text-xl font-bold"><i class="fas fa-exclamation-triangle mr-1"></i> {{ $tt("N'oubliez pas de paramétrer vos procédures") }}</h1>
                                <p class="font-light border-b border-orange-800 pb-3 mb-3">{{ $tt("Les appareils importés n'ont pas de procédures attribuées") }}</p>
                                <button @click="emitGoToProc" class="px-4 py-2 bg-orange-800 hover:bg-orange-900 text-white duration-200 rounded"><i class="fas fa-cog mr-1"></i> {{ $tt('Configurer maintenant') }}</button>
                            </div>
                        <!-- </template> -->
                        <!-- <template v-if="importationResultsProcs.without_proc > 0">
                            <div class="bg-orange-100 border border-orange-800 text-orange-800 rounded p-4">
                                <h1 class="text-xl font-bold">{{ importationResultsProcs.without_proc + ' ' + (importationResultsProcs.without_proc > 1 ? $tt('appareils sans procédures') : $tt('appareil sans procédure')) }}</h1>
                                <p class="border-orange-800 text-lg">{{ $tt('Souhaitez-vous configurer des procédures') }} ?</p>
                                <div class="flex items-center gap-2 mt-2">
                                    <button @click="$modal.hide('modal-import-list')" class="px-4 py-2 bg-orange-800 hover:bg-orange-900 text-white duration-200 rounded text-sm">{{ $tt('Non') }} <i class="fas fa-times ml-1"></i></button>
                                    <button @click="goToProcStep" class="px-4 py-2 bg-green-700 hover:bg-green-800 text-white duration-200 rounded text-sm">{{ $tt('Oui') }} <i class="fas fa-check ml-1"></i></button>
                                </div>
                            </div>
                        </template> -->
                    </template>
                </template>
                <div class="bg-red-100 rounded-lg p-4 text-red-800 mb-4" v-if="Object.keys(fileErrors).length > 0">
                    <i class="fas fa-exclamation-triangle mr-1"></i> <span class="font-bold text-lg">{{$tt('Des erreurs ont été détéctées lors du traitement de votre fichier')}}</span>
                    <div class="border-t border-red-700 pt-2 mt-2">{{$tt("Merci d'apporter les modifications nécessaires ou d'importer votre fichier corrigé")}}</div>
                </div>
                <div v-if="Object.keys(fileErrors).length > 0">
                    <div class="relative overflow-x-auto max-h-[500px] border-t">
                        <table class="w-full text-sm text-left rtl:text-right bg-white">
                            <thead>
                                <tr>
                                    <th class="px-4 py-3 text-center border bg-slate-100"></th>
                                    <th class="px-4 py-3 text-center border bg-slate-100">{{ $tt('Ligne') }}</th>
                                    <th v-for="(header, headerIndex) in fileConfig.columns" :key="'file-header-' + headerIndex" class="px-4 py-3 text-center border bg-slate-100">
                                        {{ $tt(defaultLabels[header.champ]) }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(error, errorIndex) in fileErrors" :key="'error-correction-' + errorIndex">
                                    <td class="px-4 py-3 text-center border">
                                        <button @click="deleteError(errorIndex)" class="px-4 py-2 text-red-700  text-xs font-light">
                                                <i class="fas fa-times mr-0.5"></i> {{$tt('Supprimer')}}
                                        </button>
                                    </td>
                                    <td class="px-4 py-2 border text-center">{{ Number(errorIndex) + 2 }}</td>
                                    <td v-for="(cell, cellIndex) in fileConfig.columns" :key="'file-cell-' + cellIndex" class="px-4 py-3 text-center border" :class="error.find((e) => e.cell == cellIndex) ? 'min-w-[250px] bg-red-100' : ''">
                                        <template v-if="error.find((e) => e.cell == cellIndex)">
                                            <div class="text-left mb-1 text-red-800 font-bold text-xs">{{ error.find((e) => e.cell == cellIndex).error }} :</div>
                                            <input type="text" v-model="fileContents[errorIndex][error.find((e) => e.cell == cellIndex).cell]" class="text-sm rounded-0 grow w-full p-1.5 focus:outline-none focus:border-gray-400 rounded-lg bg-gray-50" />
                                        </template>
                                        <template v-else>
                                            {{ fileContents[errorIndex][cellIndex] || '-' }}
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="flex items-center justify-end gap-2 mt-10">
                        <button @click="$modal.show('modal-ignore-errors')" :disabled="loadingImport" class="px-4 py-2 bg-slate-200 hover:bg-slate-400 duration-200 rounded font-light">
                            {{ $tt('Ignorer les erreurs') }}
                        </button>
                        <button @click="checkLinesErrors" :disabled="loadingImport" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 rounded text-white font-bold">
                            <template v-if="withProc">
                                {{$tt('Continuer')}} <i class="fas fa-chevron-right ml-1"></i>
                            </template>
                            <template v-if="!withProc">
                                <spinner :size="10" v-if="loadingImport" />
                                <template v-if="!loadingImport">
                                    {{$tt('Vérifier')}} <i class="fas fa-check ml-1"></i>
                                </template>
                            </template>
                        </button>
                    </div>
                </div>
            </div>

            <div v-show="step == 3">
                <div class="p-4" v-if="loadingGetCategProcs">
                    <spinner :size="10" />
                </div>
                <template v-if="categProcs && !loadingGetCategProcs">
                    <div v-if="categProcs.length > 0" class="bg-orange-200 rounded p-4 text-orange-800 mb-4">
                        <h1 class="font-bold">{{$tt('Ne pas appliquer de procédures')}}</h1>
                        <p class="text-sm font-light">{{ $tt('Si vous ne souhaitez pas appliquer de procédures sur les nouveaux produits importés, cliquez sur le bouton ci-dessous') }}.</p>
                        <p class="text-sm font-light">{{ $tt("Vous pourrez toujours appliquer des procédures aux produits plus tard, via l'interface des procédures") }}</p>
                        <button @click="cancelProcStep" class="px-4 py-2 bg-orange-800 hover:bg-orange-900 duration-200 text-orange-100 rounded text-sm font-semibold mt-2"><i class="fas fa-times mr-1"></i> Ne pas appliquer de procédures</button>
                    </div>
                    <div class="p-4 bg-green-100 text-green-800 rounded-lg" v-if="categProcs.length <= 0">
                        <div class="font-bold">{{ $tt('Aucune configuration de procédure nécessaire') }}</div>
                        <p class="font-light text-sm">{{$tt("Vous n'avez pas de nouveaux produits")}}</p>
                        <div class="pt-4 mt-4 border-t border-green-600"></div>
                        <button @click="$modal.hide('modal-import-list')" class="px-4 py-2 bg-green-700 hover:bg-green-800 duration-200 text-white rounded text-sm"><i class="fas fa-check mr-1"></i> {{$tt('Terminer')}}</button>
                    </div>
                    <div v-else>
                        <div class="my-4 bg-slate-100 p-4 rounded">
                            <div class="font-bold mb-2">{{$tt("Appliquer les procédures à l'ensemble de vos distributeurs")}}</div>
                            <div class="flex gap-2 items-center">
                                <button :disabled="loadingImportProc" @click="saveImport" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 rounded text-sm text-white">
                                    <spinner v-if="loadingImportProc" :size="10" />
                                    <template v-if="!loadingImportProc">
                                        <i class="fas fa-check mr-1"></i> {{$tt("Oui")}}
                                    </template>
                                </button>
                                <button @click="$modal.show('modal-not-apply-proc')" class="px-4 py-2 bg-orange-600 hover:bg-orange-700 duration-200 rounded text-sm text-white">
                                    <i class="fas fa-times mr-1"></i> {{$tt("Non")}}
                                </button>
                            </div>
                        </div>

                        <div class="font-bold mb-4">{{$tt('Appliquer les procédures aux catégories')}} :</div>
                        <div v-for="(categ, categIndex) in categProcs" :key="'categ-proc-' + categIndex" class="border mb-2 rounded">
                            <div @click="categ.open = !categ.open; $forceUpdate()" class="px-4 py-2 cursor-pointer" :class="categ.open ? 'border-b bg-slate-200' : ''">
                                <div class="flex items-center justify-between gap-2">
                                    <div class="font-semibold">{{ categ.fr }}</div>
                                    <div>
                                        <span v-if="categ.procedures.length <= 0" class="bg-orange-100 rounded p-2 text-xs font-bold"><i class="fas fa-exclamation-triangle mr-1"></i> {{ $tt('Aucune procédure') }}</span>
                                        <span v-if="categ.procedures.length > 0" class="bg-slate-100 rounded p-2 text-xs font-bold"><i class="fas fa-exclamation-triangle mr-1"></i> {{categ.procedures.length}} {{ categ.procedures.length > 1 ? $tt('procédures') : $tt('procédure') }}</span>
                                        <span class="ml-4">
                                            <i class="fas fa-chevron-right" v-if="!categ.open"></i>
                                            <i class="fas fa-chevron-down" v-if="categ.open"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="p-4 bg-slate-50" v-if="categ.open">
                                <!-- <div class="font-bold mb-2 text-slate-700"><i class="fas fa-mobile-screen-button mr-1"></i> {{$tt('Appareils')}}</div>
                                <div class="max-h-[30vh] overflow-y-auto">
                                    <table class="w-full text-sm text-left rtl:text-right border bg-white">
                                        <thead class="border-b font-bold bg-slate-100 text-xs">
                                            <th scope="col" class="px-4 py-2"></th>
                                            <th scope="col" class="px-4 py-2">{{ $tt('Marque') }}</th>
                                            <th scope="col" class="px-4 py-2">{{$tt('Modele')}}</th>
                                            <th scope="col" class="px-4 py-2">{{$tt('Ean')}}</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(product, productIndex) in categ.products" :key="'product-' + productIndex" class="border-b duration-200" :class="product.actif ? '' : 'opacity-30'">
                                                <td class="px-4 py-2"><input-rule v-model="product.actif" type="pcheck" :label-inline="false" /></td>
                                                <td class="px-4 py-2">{{ product['Marque'] }}</td>
                                                <td class="px-4 py-2">{{ product['Modele'] }}</td>
                                                <td class="px-4 py-2">{{ product['Ean'] }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="flex items-center justify-between mb-2 mt-6">
                                    <div class="font-bold text-slate-700"><i class="fas fa-code-branch mr-1"></i> {{$tt('Procédures')}}</div>
                                    <button @click="selectedCategConfig = categ; $modal.show('modal-new-procs')" class="px-4 py-2 rounded text-xs bg-slate-300 hover:bg-slate-400 duration-200">
                                        <i class="fas fa-plus mr-0.5"></i> {{$tt('Ajouter une procédure')}}
                                    </button>
                                </div>
                                <div class="bg-white">
                                    <table-procedure
                                    class="mt-2"
                                    :can-create="false"
                                    :read-only="true"
                                    :procedures="categ.procedures"
                                    :activation="true"
                                    @on-change="(data) => getDisabledProcs(data, categIndex)"
                                    />
                                </div> -->

                                <iframe width="100%" height="750px" :src="currentDomain + '/widget/gestion-categories?display-menu=0&categ-id=' + categ.id"></iframe>

                            </div>
                        </div>
                        <!-- <button :disabled="loadingImportProc" @click="saveImport" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 rounded text-sm text-white">
                            <spinner v-if="loadingImportProc" :size="10" />
                            <template v-if="!loadingImportProc">
                                <i class="fas fa-check mr-1"></i> {{$tt('Enregistrer')}}
                            </template>
                        </button> -->
                    </div>
                </template>


                <!-- <div class="grid grid-cols-3 gap-10 py-10">
                    <div class="border-r">
                        <div class="font-bold mb-1 pr-5">{{$tt('Souhaitez-vous appliquer les procédures paramétrées aux catégories ?')}}</div>
                        <input-rule v-model="applyProcs" type="pcheck" class="mt-2" :label-inline="false" />
                        <div class="border-b mt-4"></div>
                        <button :disabled="loadingImport" @click="importProducts" class="px-4 py-2 rounded bg-green-500 hover:bg-green-600 duration-200 text-white my-6">
                            <spinner :size="10" v-if="loadingImport" />
                            <template v-if="!loadingImport">
                                {{$tt('Importer les produits')}} <i class="fas fa-check mr-1"></i>
                            </template>
                        </button>
                    </div>
                    <div class="col-span-2 bg-slate-100 p-4 rounded">
                        <div class="font-bold text-lg mb-4"><i class="fas fa-microchip mr-1"></i> {{$tt('Procédures paramétrées par défaut')}}</div>
                        <div v-if="loadingGetProc">
                            <spinner :size="10" />
                        </div>
                        <div v-if="!loadingGetProc" class="max-h-[500px] overflow-y-auto">
                            <div v-for="(procCateg, procCategIndex) in procCategs" :key="'proc-categ-' + procCategIndex">
                                <div @click="procCateg.open = !procCateg.open" class="mb-2 border p-4 rounded bg-white">
                                    <div class="flex items-center justify-between cursor-pointer">
                                        <div class="">{{ procCateg.fr }}</div>
                                        <div v-if="!procCateg.open"><i class="fas fa-chevron-right"></i></div>
                                        <div v-if="procCateg.open"><i class="fas fa-chevron-down"></i></div>
                                    </div>
                                    <div @click.stop="" class="mt-4 border-t pt-4" v-if="procCateg.open">
                                        <table-procedure
                                        :can-create="false"
                                        :procedures="procCateg.procedures"
                                        :read-only="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
// import TableProcedure from '../../Procedure/Components/TableProcedure.vue';
// import TableMultiProc from '../../Categories/Components/TableMultiProc.vue';
export default {
    components: {
        // TableProcedure,
        // TableMultiProc
    },
    props: {
        withProc: {default: true},
    },
    data() {
        return {
            step: 1,
            csvFile: null,
            lastFileContents: [],
            fileContents: [],
            fileConfig: null,
            fileErrors: {},
            lastFileErrors: {},
            fileHeaders: [],
            applyProcs: true,
            procCategs: [],
            categProcs: [],
            newProcsList: null,

            noticeOpens: {
                formatFichier: false,
                convertExcel: false,
            },

            nbFileLines: 0,
            nbErrors: 0,
            importationResultsProcs: null,
            importationResultsErrors: null,
            eansWithoutProc: null,

            newConf: [
                {key: "marque", label: "Marque", oblig: 1, inp: null},
                {key: "modele", label: "Modele", oblig: 1, inp: null},
                {key: "ean", label: "Ean", oblig: 1, inp: null},
                {key: "categorie_ref", label: "Catégorie", oblig: 1, inp: null},
                {key: "libelle", label: "Description produit", oblig: 1, inp: null},
                {key: "ref_commerciale", label: "Ref. Commerciale", oblig: 0, inp: null},
                {key: "poids", label: "Poids", oblig: 0, inp: null},
                {key: "img_url", label: "Url de l'image", oblig: 0, inp: null},
                {key: "deee_appareil", label: "DEEE", oblig: 0, inp: null},
            ],

            defaultLabels: {
                "marque": "Marque",
                "modele": "Modele",
                "ean": "Ean",
                "categorie_ref": "Catégorie",
                "libelle": "Description produit",
                "ref_commerciale": "Ref. Commerciale",
                "poids": "Poids",
                "img_url": "Url de l'image",
                "deee_appareil": "DEEE"
            },

            selectedCategConfig: null,

            loadingDownloadModel: false,
            loadingUploadCsv: false,
            loadingImport: false,
            loadingGetProc: false,
            loadingGetCategProcs: false,
            loadingImportProc: false,
        }
    },
    computed: {
        hasWaitingFile() {
            return localStorage.getItem('import-file-products') ? 1 : 0 
        },
        currentDomain() {
            return location.protocol + '//' + location.host
        }
    },
    methods: {
        changeStep(step) {
            if (localStorage.getItem('import-file-products') && step <= 2) {
                this.$toast.error(this.$tt('Terminer cette étape pour importer un nouveau fichier'))
                return
            }
            this.step = step
        },

        downloadBlob(content, filename, contentType) {
            // Create a blob
            var blob = new Blob([content], { type: contentType });
            var url = URL.createObjectURL(blob);

            // Create a link to download it
            var pom = document.createElement('a');
            pom.href = url;
            pom.setAttribute('download', filename);
            pom.click();
        },

        async downloadCsvModel() {
            this.loadingDownloadModel = true
            let response = await this.$request.post('procedure.get-app-csv-model')
            const rows = [response.data.posts.post]
            let csvContent = ""
            rows.forEach(function(rowArray) {
                let row = rowArray.join(";")
                csvContent += row + "\r\n"
            });
            this.downloadBlob(csvContent, 'appareils_districloud.csv', 'text/csv;charset=utf-8;')
            this.loadingDownloadModel = false
        },
        
        loadCsvFile(file) {
            this.csvFile = file.target.files[0]
        },

        async makeCsvImport() {
            if (!this.csvFile) {
                this.$toast.error(this.$tt('Le fichier CSV est obligatoire'))
                return
            }

            const reader = new FileReader();
            reader.onload = e => {
                this.fileContents = []
                let fileContent = e.target.result
                if (!fileContent) {
                    this.$toast.error(this.$tt('Le fichier est vide'))
                    return
                }
                fileContent.split("\n").forEach((line, index) => {
                    if (index > 0) {
                        // File content lines
                        line = line.replace(/(\r)/gm, "").split(";")
                        if (line.length > 1) {
                            this.fileContents.push(line)
                        }
                    } else {
                        // File header
                        this.fileHeaders = line.replace(/(\r)/gm, "").split(";").filter(n => n)
                    }
                })
                let fileConfHasChanged = false
                this.fileConfig.columns.forEach((column) => {
                    if (column.label != this.fileHeaders[column.colonne]) {
                        fileConfHasChanged = true
                    }
                })
                if (fileConfHasChanged) {
                    this.$modal.show('modal-edit-file-conf')
                    return
                }
                this.nbFileLines = this.fileContents.length
                this.checkLinesErrors()
            }
            reader.readAsText(this.csvFile);

            // this.loadingUploadCsv = true
            // let response = await this.$request.post('procedures.import-products-file', {
            //     file: this.csvFile,
            //     procedures: JSON.stringify(Object.values(this.selectedConfigs).filter(n => n))
            // })
            // if (response.data.posts.error) {
            //     this.$toast.error(response.data.posts.error)
            // } else {
            //     this.importationResults = response.data.posts.post.ok
            //     this.importationResultsErrors = response.data.posts.post.errors
            //     this.$refs.fileuploadcsv.value = null;
            // }
            // this.loadingUploadCsv = false
        },

        checkLinesErrors() {
            this.lastFileErrors = JSON.parse(JSON.stringify(this.fileErrors))
            this.lastFileContents = JSON.parse(JSON.stringify(this.fileContents))
            this.fileErrors = {}
            let lineErrors = []
            this.nbErrors = 0
            this.fileContents.forEach((line, lineIndex) => {
                lineErrors = []
                // Check errors
                let cellIndexEan = this.getCellIndex('ean')
                if (!this.fileContents[lineIndex][cellIndexEan]) {
                    lineErrors.push({error: this.$tt("Ean obligatoire"), line: lineIndex, cell: cellIndexEan, deleted: 0})
                } else if (this.fileContents[lineIndex][cellIndexEan]?.length != 13) {
                    lineErrors.push({error: this.$tt("Ean doit faire 13 caractères"), line: lineIndex, cell: cellIndexEan, deleted: 0})
                }
                let cellIndexMarque = this.getCellIndex('marque')
                if (!this.fileContents[lineIndex][cellIndexMarque]) {
                    lineErrors.push({error: this.$tt("Marque obligatoire"), line: lineIndex, cell: cellIndexMarque, deleted: 0})
                }
                let cellIndexModele = this.getCellIndex('modele')
                if (!this.fileContents[lineIndex][cellIndexModele]) {
                    lineErrors.push({error: this.$tt("Modèle obligatoire"), line: lineIndex, cell: cellIndexModele, deleted: 0})
                }
                let cellIndexCateg = this.getCellIndex('categorie_ref')
                if (!this.fileContents[lineIndex][cellIndexCateg]) {
                    lineErrors.push({error: this.$tt("Catégorie obligatoire"), line: lineIndex, cell: cellIndexCateg, deleted: 0})
                }
                if (lineErrors.length > 0) {
                    lineErrors.forEach((line) => {
                        line.ean = this.fileContents[lineIndex][cellIndexEan]
                        line.marque = this.fileContents[lineIndex][cellIndexMarque]
                        line.modele = this.fileContents[lineIndex][cellIndexModele]
                        line.categ = this.fileContents[lineIndex][cellIndexCateg]
                    })
                    this.fileErrors[lineIndex] = JSON.parse(JSON.stringify(lineErrors))
                    this.nbErrors++
                }
            })

            if (Object.keys(this.fileErrors).length > 0) {
                this.$toast.error(this.$tt('Erreurs détéctées'))
            }

            this.step = 2
        },

        getCellIndex(cellname) {
            if (this.fileConfig) {
                let column = this.fileConfig.columns.find((column) => column.champ == cellname)
                if (column) {
                    return column.colonne
                }
            }
            return null
        },

        async getFileConfig() {
            let response = await this.$request.post('categ.get-config')
            this.fileConfig = response.data.posts.post
        },

        async importProducts() {
            this.loadingImport = true
            let response = await this.$request.post('procedures.import-products-file', {
                apps: JSON.stringify(this.fileContents),
                apply_proc: this.applyProcs ? 1 : 0
            })
            this.loadingImport = false
            if (response.data.posts.error) {
                this.$toast.error(response.data.posts.error)
            } else {
                // this.$emit('submit')
                this.importationResults = response.data.posts.post.ok
                this.importationResultsErrors = response.data.posts.post.errors
                this.importationResultsProcs = {
                    with_proc: response.data.posts.post.app_with_procs,
                    without_proc: response.data.posts.post.app_without_procs,
                }
                this.$refs.fileuploadcsv.value = null;
                // this.$modal.hide('modal-import-list')
                this.$toast.success(this.$tt('Fichier importé'))
                // localStorage.setItem('import-file-products', JSON.stringify(response.data.posts.post.created))
                return response.data.posts.post.app_hasnt_procs
            }
        },

        deleteError(errorIndex) {
            delete this.fileErrors[errorIndex]
            for (let index in this.fileErrors) {
                if (index > errorIndex) {
                    delete Object.assign(this.fileErrors, {[index - 1]: this.fileErrors[index] })[index]
                }
            }
            this.$forceUpdate()
            this.fileContents.splice(errorIndex, 1)
        },

        async importFile() {
            if (!this.fileContents || this.fileContents.length <= 0 || Object.keys(this.fileErrors).length > 0) {
                return
            }
            this.eansWithoutProc = await this.importProducts()
            this.$emit('refresh-categs')
        },

        async goToProcStep() {
            this.step = 3
            this.loadingGetCategProcs = true
            let response = await this.$request.post('procedures.detect-procs-in-file', {
                eans: this.eansWithoutProc ? JSON.stringify(this.eansWithoutProc) : "[]"
            })
            this.categProcs = response.data.posts.post
            this.loadingGetCategProcs = false
        },

        getDisabledProcs(data, index) {
            data.forEach((d) => {
                this.categProcs[index].procedures.find((item) => item.id == d.id).actif = d.actif
            })
        },

        async saveImport() {
            let endApps = []
            for (let categIndex in this.categProcs) {
                let categ = this.categProcs[categIndex]
                let procs = []
                categ.products.forEach((pr) => {
                    if (pr.actif == 1) {
                        let nProduct = JSON.parse(JSON.stringify(pr))
                        let product = {
                            actif: nProduct.actif,
                            id: nProduct.id
                        }
                        procs = []
                        categ.procedures.forEach((proc) => {
                            if (proc.actif == 1) {
                                procs.push(proc.configs[0].id)
                            }
                        })
                        product.procedures = procs
                        delete product.actif
                        endApps.push(product)
                    }
                })
            }

            this.loadingImportProc = true
            try {
                let response = await this.$request.post('procedure.apply-proc-import-file', {
                    apps: JSON.stringify(endApps)
                })
                if (response.data.posts.post == 1) {
                    this.$toast.success(this.$tt('Procédures importées'))
                    this.$modal.hide('modal-import-list')
                } else {
                    this.$toast.error(this.$tt('Une erreur est survenue'))
                }
            } catch (error) {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
            // localStorage.removeItem('import-file-products')
            this.loadingImportProc = false
        },

        cancelProcStep() {
            // localStorage.removeItem('import-file-products')
            this.$modal.hide('modal-import-list')
            this.$toast.success('Importation terminée')
        },

        async createNewConf() {
            this.$eventHub.$emit('clear-input-error')

            let hasError = false
            this.newConf.forEach((conf) => {
                if (conf.oblig && !conf.inp) {
                    this.$eventHub.$emit('validation-input-error', {
                        id: 'inp-conf-' + conf.key,
                        error: this.$tt('Champ obligatoire')
                    })
                    hasError = true
                } else {
                    conf['newlabel'] = conf.inp
                    conf['colonne'] = this.fileHeaders.findIndex((item) => item == conf.inp)
                }
            })
            if (hasError) {
                this.$toast.error(this.$tt('Configuration incorrecte'))
                return
            }

            this.newConf = this.newConf.filter((item) => item.colonne != -1)

            let response = await this.$request.post('procedure.add-import-config', {
                config: JSON.stringify(this.newConf)
            })

            if (response.data.posts.post == 1) {
                await this.getFileConfig()
                await this.makeCsvImport()
                this.$toast.success(this.$tt('Configuration modifiée'))
                this.$modal.hide('modal-edit-file-conf')
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
        },

        ignoreErrors() {
            let linesIndexes = []
            for (let index in this.fileErrors) {
                linesIndexes.push(this.fileErrors[index][0].line)
            }
            let nbDeleted = 0
            linesIndexes.forEach((lineIndex) => {
                this.fileContents.splice(lineIndex - nbDeleted, 1)
                nbDeleted++
            })
            this.lastFileErrors = JSON.parse(JSON.stringify(this.fileErrors))
            this.fileErrors = {}
            this.$nextTick(() => {
                this.$forceUpdate()
                this.$modal.hide('modal-ignore-errors')
            })
        },

        downloadStackErrors() {
            var element = document.createElement('a');

            let endText = 'Ligne;Erreur;Marque;Modele;EAN;Categorie\n'
            for (let index in this.lastFileErrors) {
                let error = this.lastFileErrors[index][0]
                endText +=  index + ';'
                endText +=  this.lastFileErrors[index].map(item => item['error']).join(', ') + ';'
                endText +=  error.marque?.replaceAll('+', '')?.replaceAll(';', '')?.replaceAll('\\', '')?.replaceAll('"', '') + ';'
                endText +=  error.modele?.replaceAll('+', '')?.replaceAll(';', '')?.replaceAll('\\', '')?.replaceAll('"', '') + ';'
                endText +=  error.ean + ';'
                endText +=  error.categ?.replaceAll('+', '')?.replaceAll(';', '')?.replaceAll('\\', '')?.replaceAll('"', '')
                endText +=  "\n"
            }

            element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(endText));
            element.setAttribute('download', 'districloud-importation-appareils-erreurs');

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        },

        closeModal() {
            if (this.fileContents && this.fileContents.length > 0 && Object.keys(this.fileErrors).length <= 0 && !this.importationResultsProcs) {
                this.$modal.show('modal-confirm-close')
            } else {
                this.$modal.hide('modal-import-list')
            }
        },

        closeModalNewProcs() {
            this.$modal.hide('modal-new-procs')
            this.goToProcStep()
        },

        emitGoToProc() {
            window.parent.postMessage({from: 'import-file', type: 'go-proc', meta: {}}, "*");
            this.$modal.hide('modal-import-list')
        }
    },
    mounted () {
        this.getFileConfig();

        // localStorage.removeItem('import-file-products')
        // if (localStorage.getItem('import-file-products')) {
        //     this.goToProcStep(true)
        // }
    },
}
</script>

<style lang="scss" scoped>

</style>