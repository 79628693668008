<template>
    <div>
        <!-- Modal add product -->
        <modal name="modal-add-product" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{$tt('Ajouter un produit')}}</h2>
                    <button @click="$modal.hide('modal-add-product')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <add-product-form @added="addedProduct" />
                </div>
            </div>
        </modal>

        <!-- Modal workflow -->
        <modal name="modal-descriptif-workflow" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-5 mb-5">
                    <h2 class="font-light">{{$tt('Description du workflow')}}</h2>
                </div>
                <div v-if="selectedWorflowInfo">
                    <h2 class="text-xl font-bold">{{ selectedWorflowInfo.descriptif }}</h2>
                    <p class="bg-slate-100 p-4 rounded mt-4 font-light">{{ selectedWorflowInfo.detail }}</p>
                </div>
                <div class="mt-4 text-center">
                    <button class="px-4 py-2 text-sm font-bold bg-red-500 hover:bg-red-600 duration-200 rounded text-white" @click="$modal.hide('modal-descriptif-workflow')">Fermer</button>
                </div>
            </div>
        </modal>

        <!-- Modal set proc -->
        <modal name="modal-apply-proc" height="auto" width="90%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{$tt('Ajouter une nouvelle procédure')}}</h2>
                    <button @click="$modal.hide('modal-apply-proc')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <!-- <div class="bg-orange-100 text-orange-800 p-4 mb-4 rounded">
                        <div class="font-semibold mb-1"><i class="fas fa-circle-info mr-1"></i> {{$tt('A savoir')}}</div>
                        <div class="text-sm">
                            <div>{{$tt('Une fois la nouvelle procédure appliquée, tous les appareils sans procédure et les produits avec la même garantie seront configurés avec celle-ci')}}</div>
                        </div>
                    </div> -->
                    <!-- <div class="border-b mb-3 pb-3">
                        <h2 class="text-xl">{{$tt('Choisir une procédure')}}</h2>
                        <h3 class="text-sm font-light">{{$tt('Parmis la liste des procédures ci-dessous')}}</h3>
                    </div> -->

                    <div class="mb-4">
                        <div class="font-medium text-slate-700 text-xl mt-10 mb-4">1. {{$tt('Séléctionner votre espace')}}</div>
                        <div class="border-l-2 ml-2 pl-6 py-4">
                            <div class="flex gap-4 items-center">
                                <button @click="selectedSpace = 'magasin'" class="px-6 py-4 rounded-lg" :class="selectedSpace == 'magasin' ? 'bg-emerald-200 border-2 border-emerald-300 text-emerald-900' : 'bg-slate-100 hover:bg-slate-200 duration-200 text-slate-700 border-2 border-white'">
                                    <div class="flex items-center gap-3">
                                        <i class="fas fa-shop text-xl opacity-70"></i>
                                        <div class="text-left">
                                            <div class="font-semibold text-sm">{{$tt('Espace Magasin')}}</div>
                                            <div class="font-light text-xs">{{ $tt('Gestion de vos procédures magasin') }}</div>
                                        </div>
                                    </div>
                                </button>
                                <button @click="hasSelfcare ? (selectedSpace = 'selfcare') : $toast.error($tt('Vous devez activer le module selfcare'))" class="px-6 py-4 rounded-lg" :class="(selectedSpace == 'selfcare' ? 'bg-emerald-200 border-2 border-emerald-300 text-emerald-900' : 'bg-slate-100 hover:bg-slate-200 duration-200 text-slate-700 border-2 border-white') + ' ' + (hasSelfcare ? '' : 'cursor-not-allowed')">
                                    <div class="flex items-center gap-3">
                                        <i class="fas fa-mobile-screen-button text-2xl opacity-70"></i>
                                        <div class="text-left">
                                            <div class="font-semibold text-sm">{{$tt('Espace Selfcare')}}</div>
                                            <div class="font-light text-xs">{{$tt('Gestion de vos procédures selfcare')}}</div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div v-if="selectedSpace" class="mb-4">
                        <div class="font-medium text-slate-700 text-xl mb-4">2. {{$tt('Séléctionner une procédure')}} {{ selectedSpace == 'magasin' ? 'magasin' : 'selfcare' }}</div>
                        <div class="border-l-2 ml-2 pl-6 py-4">
                            <div class="p-4" v-if="loadingGetPatterns">
                                <spinner :size="10" />
                            </div>
                            <div v-if="!loadingGetPatterns">
                                <table-procedure
                                :procedures="procedures"
                                @select="selectProcedure"
                                @update-pattern="getPatterns"
                                @refresh-proc="getPatterns"
                                :space="selectedSpace"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-if="selectedProcedure?.id" class="mb-10">
                        <div class="font-medium text-slate-700 text-xl mb-4">3. {{$tt('Confirmation')}}</div>
                        <div class="border-l-2 ml-2 pl-6 py-4">
                            <button :disabled="loadingApplyProc" @click="applyProcedure" class="bg-green-600 hover:bg-green-700 duration-200 text-white rounded px-6 py-3 font-bold">
                                <spinner v-if="loadingApplyProc" :size="10" />
                                <template v-if="!loadingApplyProc">
                                    {{$tt('Appliquer la procédure')}} <i class="fas fa-check ml-1"></i>
                                </template>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <!-- Modal config details -->
        <modal name="modal-view-config" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedConfig">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{$tt('Champs demandés')}}</h2>
                    <button @click="$modal.hide('modal-view-config')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <div v-for="(config, configIndex) in selectedConfig.config" :key="'config-view-' + configIndex">
                        <div v-if="configKeys.find((item) => item.key == configIndex) && config > 0">
                            <div class="flex items-center gap-4 mt-4">
                                <div>{{ configKeys.find((item) => item.key == configIndex).label }}</div>
                                <div class="border-b border-dashed grow"></div>
                                <template v-if="config == 1">
                                    <div class="px-2 py-1 rounded text-sm bg-orange-200">
                                        {{$tt('Optionnel')}}
                                    </div>
                                </template>
                                <template v-if="config == 2">
                                    <div class="px-2 py-1 rounded text-sm bg-green-200">
                                        {{$tt('Obligatoire')}}
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <!-- Modal view proc -->
        <modal name="modal-view-proc" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedProcView">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{$tt('Consulter une procédure')}}</h2>
                    <button @click="$modal.hide('modal-view-proc')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <div class="mb-4">
                        <div class="text-sm">{{$tt('Type garantie')}}</div>
                        <div class="font-bold">{{ selectedProcView.procedure.type_garantie | typeGarantieLabel }}</div>
                    </div>
                    <div class="mb-4">
                        <div class="text-sm">{{$tt('Durée')}}</div>
                        <div class="font-bold">{{ selectedProcView.procedure.duree }}</div>
                    </div>
                    <div class="mb-4">
                        <div class="text-sm">{{$tt('Durée de rétention (jour)')}}</div>
                        <div class="font-bold">{{ selectedProcView.procedure.to_resend }}</div>
                    </div>
                    <div class="mb-4">
                        <div class="text-sm">{{$tt('Durée maximum de l\'extension de garantie (mois)')}}</div>
                        <div class="font-bold">{{ selectedProcView.procedure.extension_garantie }}</div>
                    </div>
                    <div class="mb-4">
                        <div class="text-sm">{{$tt('Date de fin de validité')}}</div>
                        <div class="font-bold">{{ selectedProcView.procedure.date_fin || '-' }}</div>
                    </div>

                    <div class="mb-4">
                        <div class="text-sm">{{$tt('Workflow')}}</div>
                        <div class="font-bold">{{ selectedProcView.procedure.workflow?.descriptif || '-' }}</div>
                    </div>
                    <div class="mb-4">
                        <div class="text-sm">{{$tt('Station')}}</div>
                        <div class="font-bold">{{ selectedProcView.procedure.station?.nom || '-' }}</div>
                    </div>
                    <div class="mb-4">
                        <div class="text-sm">{{$tt('Message')}}</div>
                        <p v-html="selectedProcView.procedure.description_proc"></p>
                        <div class="font-bold" v-if="!selectedProcView.procedure.description_proc">{{$tt('Aucun message')}}</div>
                    </div>
                    <div class="mb-4">
                        <div class="text-sm">{{$tt('Document')}}</div>
                        <div class="font-bold" v-if="selectedProcView.procedure.id_file_proc == 0">{{$tt('Aucun document')}}</div>
                        <div v-else><a :href="selectedProcView.procedure.id_file_proc" class="text-blue-500 hover:underline text-sm" target="_blank"><i class="fas fa-link mr-1"></i> Consulter le fichier</a></div>
                    </div>
                    <div class="mb-4">
                        <div class="text-sm">{{$tt('Transporteur aller')}}</div>
                        <div class="font-bold">{{ selectedProcView.procedure.transporteur_aller?.transporteur || '-' }}</div>
                    </div>
                </div>
            </div>
        </modal>

        <!-- Component -->
        <app-template>
            <div class="text-right mb-2">
                <button @click="exportProcs" class="px-4 py-2 text-sm bg-blue-300 hover:bg-blue-400 duration-200 text-white rounded">
                    <spinner v-if="loadingExportProc" :size="10" />
                    <template v-if="!loadingExportProc">
                        {{$tt('Exporter les procédures')}} <i class="fa-solid fa-cloud-arrow-down ml-0.5"></i>
                    </template>
                </button>
            </div>
            <div class="bg-white p-2 rounded border shadow-sm mb-16">
                <div class="flex">
                    <div class="border-r p-4 min-w-[275px] max-w-[275px]">
                        <div class="mb-2 border-b pb-2">
                            <h1 class="text-lg font-bold"><i class="fa-solid fa-building mr-0.5"></i> {{$tt('Centrales')}} <span class="text-sm" v-if="centrales.premiums && centrales.frees">({{ centrales.premiums.length + centrales.frees.length }})</span></h1>
                            <p class="text-xs font-light mt-1">{{$tt('Sur quelles centrales souhaitez-vous appliquer une procédure ?')}}</p>
                        </div>
                        <div class="p-2 mt-2" v-if="loadingGetCentrales">
                            <spinner :size="10" />
                        </div>
                        <template v-if="!loadingGetCentrales">
                            <div class="flex items-center gap-1 border rounded mb-2 py-1 px-2 bg-slate-50">
                                <i class="fas fa-search text-sm opacity-30"></i>
                                <input v-model="inpSearchCentrale" class="bg-slate-50 w-full p-1 font-light focus:outline-none text-sm" :label-inline="false" type="text" placeholder="Rechercher.." />
                            </div>
                            <input-rule v-model="filterWithoutProc" class="mt-4 my-2" type="pcheck" label="Sans procédure" />
                            <div class="max-h-[75vh] overflow-y-auto">
                                <div v-if="listCentralesPremiums && listCentralesPremiums.length > 0">
                                    <div class="flex">
                                        <div class="font-semibold bg-blue-200 px-2 py-1 rounded text-sm text-blue-900">{{$tt('Connexions premiums')}}</div>
                                    </div>
                                    <div class="py-2 border-b" v-for="(centrale, centraleIndex) in listCentralesPremiums" :key="'select-centrale-premium-' + centraleIndex">
                                        <div @click="selectCentrale(centrale, 'premium')" class="flex items-center gap-2 cursor-pointer" :class="centrale.selected ? 'text-green-500' : ''">
                                            <div class="min-w-[11px] h-[11px] rounded-full cursor-pointer" :class="centrale.selected ? 'bg-green-500' : 'bg-slate-300 hover:bg-slate-400 duration-200'"></div>
                                            {{ centrale.centrale_ha }}
                                        </div>
                                    </div>
                                </div>
                                <div v-if="listCentralesFrees && listCentralesFrees.length > 0">
                                    <div class="flex">
                                        <div class="font-semibold bg-blue-200 px-2 py-1 rounded text-sm text-blue-900" :class="listCentralesPremiums && listCentralesPremiums.length > 0 ? 'mt-10' : ''">{{$tt('Connexions gratuites')}}</div>
                                    </div>
                                    <div class="py-2 border-b" v-for="(centrale, centraleIndex) in listCentralesFrees" :key="'select-centrale-free-' + centraleIndex">
                                        <div @click="selectCentrale(centrale, 'free')" class="flex items-center gap-2 cursor-pointer" :class="centrale.selected ? 'text-green-500' : ''">
                                            <div class="min-w-[11px] h-[11px] rounded-full cursor-pointer" :class="centrale.selected ? 'bg-green-500' : 'bg-slate-300 hover:bg-slate-400 duration-200'"></div>
                                            {{ centrale.centrale_ha }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div v-if="selectedTypeList" class="min-w-[300px] max-w-[300px] border-r p-4">
                        <div class="mb-2 border-b pb-2">
                            <h1 class="text-lg font-bold"><i class="fa-solid fa-mobile-screen-button mr-0.5"></i> {{$tt('Produits')}}</h1>
                            <p class="text-xs font-light mt-1">{{$tt('Séléctionner les produits concernés par cette nouvelle procédure')}}</p>
                        </div>
                        <button @click="$modal.show('modal-add-product')" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 rounded text-xs mb-2"><i class="fas fa-plus mr-1"></i> {{$tt('Nouveau produit')}}</button>
                        <div class="max-h-[75vh] overflow-y-auto">
                            <div class="bg-slate-100 rounded p-4 mb-2">
                                <div class="text-sm mb-1 font-light"><i class="fas fa-search mr-1"></i> {{$tt('Rechercher par')}} :</div>
                                <div class="flex gap-2 items-center">
                                    <button @click="typeMenu = 'marque'" class="text-xs rounded py-2 px-4" :class="typeMenu == 'marque' ? 'bg-blue-500 text-white' : 'bg-slate-300 hover:bg-slate-400 hover:text-white duration-200'">{{$tt('Marques')}}</button>
                                    <button @click="typeMenu = 'categ'" class="text-xs rounded py-2 px-4" :class="typeMenu == 'categ' ? 'bg-blue-500 text-white' : 'bg-slate-300 hover:bg-slate-400 hover:text-white duration-200'">{{$tt('Catégories')}}</button>
                                </div>
                            </div>
                            <select-product-menu
                            v-if="typeMenu == 'marque'"
                            @update="(apps) => {selectedApps = apps}"
                            :without-proc="filterWithoutProc"
                            @item-changed="getWarningExceptions"
                            :centrales="centraleIds"
                            />
                            <select-product-menu-categ
                            v-if="typeMenu == 'categ'"
                            @update="(apps) => {selectedApps = apps}"
                            :without-proc="filterWithoutProc"
                            @item-changed="getWarningExceptions"
                            :centrales="centraleIds"
                            />
                        </div>
                    </div>
                    <div v-if="selectedTypeList && hasSelectedProducts" class="p-4 grow" style="min-width: 0;">
                        <h1 class="text-lg font-bold mb-2 border-b pb-2"><i class="fa-solid fa-microchip mr-0.5"></i> {{$tt('Procédures déjà appliquées')}}</h1>
                        <!-- <div class="bg-slate-100 p-4 rounded" v-if="procedures.length <= 0">
                            <div class="text-xl font-bold text-slate-500">Aucune procédure enregistrée</div>
                            <button @click="$modal.show('modal-edit-add-proc')" class="px-4 py-2 bg-slate-200 border hover:bg-slate-300 duration-200 rounded text-sm my-2"><i class="fas fa-plus mr-1"></i> Ajouter une procédure</button>
                        </div> -->
                        <!-- Table apps -->
                        <div class="p-2 mt-4" v-if="loadingGetException">
                            <spinner :size="10" />
                        </div>
                        <div v-if="!loadingGetException">
                            <div v-for="(categ, categIndex) in proceduresWarnings" :key="'warn-categ-' + categIndex">
                                <div v-if="categ.procedures.length > 0 || categ.noProcApps.length > 0" class="border p-3 rounded mb-2" :class="!categ.open ? 'hover:bg-slate-50 duration-200 cursor-pointer bg-white' : ''">
                                    <div @click="categ.open = !categ.open; $forceUpdate()" class="flex items-center justify-between gap-2 cursor-pointer">
                                        <div>
                                            <div class="font-bold">{{ categ.fr }}</div>
                                            <div class="text-xs font-light">{{ nbSelectedApps(categ) + (nbSelectedApps(categ) > 1 ? $tt(' appareils') : $tt(' appareil')) }} sur {{ selectedCentralesList.filter((item) => item.selected).length + ' ' + (selectedCentralesList.filter((item) => item.selected).length > 1 ? $tt('centrales') : $tt('centrale')) }}</div>
                                            <div v-if="categ.noProcApps.length > 0 && !categ.open" class="mt-1 bg-orange-200 px-2 py-1 rounded text-xs">
                                                <i class="fas fa-exclamation-triangle mr-0.5"></i> {{ categ.noProcApps.length + (categ.noProcApps.length > 1 ? $tt(' appareils') : $tt(' appareil')) }} {{$tt('sans procédure')}}
                                            </div>
                                        </div>
                                        <div class="flex items-center gap-2">
                                            <button>
                                                <template v-if="!categ.open"><i class="fas fa-chevron-right"></i></template>
                                                <template v-if="categ.open"><i class="fas fa-chevron-down"></i></template>
                                            </button>
                                        </div>
                                    </div>
                                    <div v-if="categ.open" class="rounded mt-2 relative overflow-x-auto">
                                        <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                                            <thead class="text-xs text-gray-700 uppercase bg-slate-50">
                                                <tr class="border">
                                                    <th scope="col" class="px-4 py-1">{{$tt('Consulter')}}</th>
                                                    <th scope="col" class="px-4 py-1">{{$tt('Espace')}}</th>
                                                    <th scope="col" class="px-4 py-1">{{$tt('Nb App.')}}</th>
                                                    <th scope="col" class="px-4 py-1">{{$tt('Centrale')}}</th>
                                                    <th scope="col" class="px-4 py-1">{{$tt('Garantie')}}</th>
                                                    <th scope="col" class="px-4 py-1">{{$tt('Durée')}}</th>
                                                    <th scope="col" class="px-4 py-1 min-w-[250px]">{{$tt('Procédure')}}</th>
                                                    <th scope="col" class="px-4 py-1">{{$tt('Réparateur')}}</th>
                                                    <th scope="col" class="px-4 py-1">{{$tt('Transporteur')}}</th>
                                                    <th scope="col" class="px-4 py-1"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- App without proc -->
                                                <template v-if="categ.noProcApps.length > 0">
                                                    <tr class="bg-orange-50 font-bold text-orange-600 cursor-pointer" @click="openNoProc(categ)" :class="categ.noProcOpen ? 'bg-orange-50 border-orange-300 border-2' : 'border hover:bg-orange-100 duration-200'">
                                                        <td class="px-4 py-3"><i class="fas fa-ban"></i></td>
                                                        <td class="px-4 py-3" colspan="7">{{categ.noProcApps.length + ' ' + (categ.noProcApps.length > 1 ? $tt('appareils sans procédure') : $tt('appareil sans procédure')) }}</td>
                                                        <td class="px-4 py-3">
                                                            <template v-if="!categ.noProcOpen">
                                                                <i class="fas fa-chevron-right"></i>
                                                            </template>
                                                            <template v-if="categ.noProcOpen">
                                                                <i class="fas fa-chevron-down"></i>
                                                            </template>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="categ.noProcOpen">
                                                        <td class="pl-4" colspan="9">
                                                            <div class="flex">
                                                                <div class="flex">
                                                                    <div class="border-l-2 h-[50%] border-orange-300"></div>
                                                                    <div class="border-b-2 h-[50%] border-orange-300 w-[20px]"></div>
                                                                </div>
                                                                <div class="mt-4 border-2 mb-6 border-orange-300 rounded relative overflow-x-auto overflow-y-auto max-h-[400px] w-full">
                                                                    <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                                                                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                                                            <tr>
                                                                                <th scope="col" class="px-4 py-1 bg-orange-300 text-orange-900">
                                                                                    <!-- {{ categ.noProcApps }} -->
                                                                                    <!-- {{ exceptionProducts }} -->
                                                                                    <!-- -> {{ exceptionProducts.every((exceptionId) => categ.noProcApps.find((noApp) => noApp.id == exceptionId)) }} -->
                                                                                    <!-- <button v-if="true" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 text-xs rounded mb-2" @click="selectAllNoProcApp(categ.noProcApps)">Tout cocher</button> -->
                                                                                    <!-- <button v-if="true" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 text-xs rounded" @click="unselectAllNoProcApp(categ.noProcApps)">Tout décocher</button> -->
                                                                                </th>
                                                                                <th scope="col" class="px-4 py-1 bg-orange-300 text-orange-900">{{$tt('Centrale')}}</th>
                                                                                <th scope="col" class="px-4 py-1 bg-orange-300 text-orange-900">{{$tt('Marque')}}</th>
                                                                                <th scope="col" class="px-4 py-1 bg-orange-300 text-orange-900">{{$tt('Modele')}}</th>
                                                                                <th scope="col" class="px-4 py-1 bg-orange-300 text-orange-900">{{$tt('Libelle')}}</th>
                                                                                <th scope="col" class="px-4 py-1 bg-orange-300 text-orange-900">{{$tt('Ean')}}</th>
                                                                                <!-- <th scope="col" class="px-4 py-1">Centrale Achat</th> -->
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr v-for="(liaison, liaisonIndex) in categ.noProcApps" :key="'warn-no-liaison-' + liaisonIndex" class="bg-white border-b bg-orange-50 hover:bg-orange-100 duration-200 cursor-pointer" @click="toggleAppException(liaison)">
                                                                                <td class="px-4 py-2 text-orange-800">
                                                                                    <button class="rounded-full border-2 border-green-800 w-[15px] h-[15px]" :class="!exceptionProducts.find((item) => item == (liaison.id + '-' + liaison.centrale.id)) ? 'bg-green-400' : ''"></button>
                                                                                </td>
                                                                                <td class="px-4 py-2 text-orange-800">{{ liaison.centrale.centrale_ha }}</td>
                                                                                <td class="px-4 py-2 text-orange-800">{{ liaison['Marque'] }}</td>
                                                                                <td class="px-4 py-2 text-orange-800">{{ liaison['Modele'] }}</td>
                                                                                <td class="px-4 py-2 text-orange-800">{{ liaison['Libelle'] }}</td>
                                                                                <td class="px-4 py-2 text-orange-800">{{ liaison['Ean'] }}</td>
                                                                                <!-- <td class="px-4 py-2">{{ liaison.centrale['centrale_ha'] }}</td> -->
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <!-- Proc apps -->
                                                <template v-for="(proc, procIndex) in categ.procedures">
                                                    <tr :key="'warn-proc-' + procIndex" @click="openProc(proc)" class="bg-white cursor-pointer" :class="proc.open ? 'bg-sky-50 border-sky-300 border-2' : 'border hover:bg-slate-100 duration-200'">
                                                        <!-- Consulter -->
                                                        <td class="px-4 py-2 flex items-center gap-2 my-4">
                                                            <button @click.stop="selectedProcView = proc; $modal.show('modal-view-proc')" class="hover:text-blue-500 duration-200"><i class="fas fa-eye"></i></button>
                                                            <div @click.stop="proc.selected = !proc.selected; proc.liaisons.map((item) => item.appareil.selected = proc.selected)">
                                                                <i class="fas fa-check-circle text-sm" :class="proc.selected ? 'text-green-500' : ''"></i>
                                                            </div>
                                                        </td>
                                                        <!-- Espace -->
                                                        <td class="px-4 py-2">
                                                            <span v-if="!proc.procedure.selfcare_proc" class="px-2 py-1 text-xs bg-slate-200 text-slate-800 rounded">{{$tt('Magasin')}}</span>
                                                            <span v-if="proc.procedure.selfcare_proc" class="px-2 py-1 text-xs bg-yellow-200 text-yellow-800 rounded">Selfcare</span>
                                                        </td>
                                                        <td class="px-4 py-2">{{ proc.liaisons.filter((item) => item.appareil.selected).length }}</td>
                                                        <td class="px-4 py-2">{{ proc.procedure.centrale?.centrale_ha || '-' }}</td>
                                                        <td class="px-4 py-2">{{ proc.procedure.type_garantie | typeGarantieLabel }}</td>
                                                        <td class="px-4 py-2">{{ proc.procedure.garantie_mois + ' ' + (proc.procedure.type_garantie == 'garantie_pms' ? $tt('jours') : $tt('mois')) }}</td>
                                                        <!-- Workflow -->
                                                        <td class="px-4 py-2">
                                                            <template v-if="proc.procedure.workflow?.descriptif">
                                                                <button @click.stop="selectedWorflowInfo = proc.procedure.workflow; $modal.show('modal-descriptif-workflow')" class="text-slate-600 hover:text-slate-800 duration-200 cursor-pointer"><i class="fas fa-info-circle mr-1"></i></button>
                                                                {{ proc.procedure.workflow.descriptif }}
                                                            </template>
                                                            <template v-else>-</template>
                                                        </td>
                                                        <td class="px-4 py-2">{{ proc.procedure.station?.nom || '-' }}</td>
                                                        <td class="px-4 py-2">{{ proc.procedure.transporteur_aller?.transporteur || '-' }}</td>
                                                        <td class="px-4 py-2">
                                                            <template v-if="!proc.open">
                                                                <i class="fas fa-chevron-right"></i>
                                                            </template>
                                                            <template v-if="proc.open">
                                                                <i class="fas fa-chevron-down"></i>
                                                            </template>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="proc.open" :key="'warn-liaison-' + procIndex">
                                                        <td class="pl-4" colspan="9">
                                                            <div class="flex">
                                                                <div class="flex">
                                                                    <div class="border-l-2 h-[50%] border-sky-300"></div>
                                                                    <div class="border-b-2 h-[50%] border-sky-300 w-[20px]"></div>
                                                                </div>
                                                                <div class="mt-4 border-2 mb-6 border-sky-300 rounded relative overflow-x-auto overflow-y-auto max-h-[400px] w-full">
                                                                    <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                                                                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                                                            <tr>
                                                                                <th scope="col" class="px-4 py-1 bg-sky-300 text-sky-900 w-[100px]"></th>
                                                                                <th scope="col" class="px-4 py-1 bg-sky-300 text-sky-900">{{$tt('Marque')}}</th>
                                                                                <th scope="col" class="px-4 py-1 bg-sky-300 text-sky-900">{{$tt('Libelle')}}</th>
                                                                                <th scope="col" class="px-4 py-1 bg-sky-300 text-sky-900">{{$tt('Modele')}}</th>
                                                                                <th scope="col" class="px-4 py-1 bg-sky-300 text-sky-900">{{$tt('Ean')}}</th>
                                                                                <!-- <th scope="col" class="px-4 py-1">Centrale Achat</th> -->
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr v-for="(liaison, liaisonIndex) in proc.liaisons" :key="'warn-liaison-' + liaisonIndex" class="bg-white border-b">
                                                                                <td class="px-4 py-2 bg-sky-50 text-sky-800 flex gap-4 items-center w-[100px]">
                                                                                    <input-rule type="pcheck" @input="selectApp(proc)" v-model="liaison.appareil.selected" :label-inline="false" />
                                                                                    <button @click="selectedConfig = liaison; $modal.show('modal-view-config')" class="bg-slate-100 rounded text-sm"><i class="fas fa-code-branch"></i></button>
                                                                                </td>
                                                                                <td class="px-4 py-2 bg-sky-50 text-sky-800">{{ liaison.appareil['Marque'] }}</td>
                                                                                <td class="px-4 py-2 bg-sky-50 text-sky-800">{{ liaison.appareil['Libelle'] }}</td>
                                                                                <td class="px-4 py-2 bg-sky-50 text-sky-800">{{ liaison.appareil['Modele'] }}</td>
                                                                                <td class="px-4 py-2 bg-sky-50 text-sky-800">{{ liaison.appareil['Ean'] }}</td>
                                                                                <!-- <td class="px-4 py-2">{{ liaison.centrale['centrale_ha'] }}</td> -->
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <!-- Go to proc -->
                            <div v-if="selectedTypeList == 'premium'" class="text-center mt-4">
                                <button @click="displayProcModal()" class="px-4 py-2 bg-slate-600 hover:bg-slate-700 duration-200 rounded text-white">
                                    <div>{{$tt('Ajouter une nouvelle procédure')}}</div>
                                    <div class="text-xs font-light">Sur {{ nbSelectedApps() + ' ' + (nbSelectedApps() > 1 ? $tt('appareils') : $tt('appareil')) }}</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import moment from 'moment'
import SelectProductMenu from './Components/SelectProductMenu.vue'
import SelectProductMenuCateg from './Components/SelectProductMenuCateg.vue'
import TableProcedure from './Components/TableProcedure.vue'
import AddProductForm from './../Appareils/Components/AddProductForm.vue'


export default {
    components: {
        SelectProductMenu,
        SelectProductMenuCateg,
        TableProcedure,
        AddProductForm,
    },
    watch: {
        inpSearchCentrale() {
            this.searchCentrale()
        },
        filterWithoutProc() {
            this.getCentrales()
        },
        selectedSpace() {
            this.getPatterns()
        }
    },
    data() {
        return {
            procedures: [],
            selectedProcedure: null,
            listCentralesPremiums: [],
            listCentralesFrees: [],
            selectedTypeList: null,
            centrales: [],
            appareils: [],
            inpSearchCentrale: null,
            proceduresWarnings: [],
            exceptionProducts: [],
            selectedWorflowInfo: null,
            selectedProcView: null,
            selectedConfig: null,
            selectedSpace: null,
            filterWithoutProc: false,
            loadingApplyProc: false,
            loadingGetCentrales: false,
            loadingGetException: false,
            loadingExportProc: false,
            loadingGetPatterns: false,

            selectedApps: null,
            typeMenu: 'marque',

            configKeys: [
                {label: 'N. de facture', key: 'facture_oblig'},
                {label: 'Client', key: 'client_oblig'},
                {label: 'Numéro de série', key: 'num_serie_oblig'},
                {label: 'Date de fabrication', key: 'date_fab_oblig'},
                {label: 'Numéro accord', key: 'num_accord'},
                {label: 'Envoie plaque signalétique', key: 'plaque'},
                {label: 'Destruction', key: 'destruction'},
                {label: 'Numéro de facture', key: 'num_fact_oblig'},
                {label: 'Saisie client', key: 'saisie_client_oblig'},
                {label: 'Destruction', key: 'destruction'},
                {label: 'Justificatif d\'achat', key: 'justif_oblig'},
                {label: 'Code panne', key: 'code_panne_oblig'},
                {label: 'Photo de la panne', key: 'photo_oblig'},
                {label: 'IMEI', key: 'imei_oblig'},
                {label: 'Photo plaque signalétique', key: 'envoi_plaque'},
                {label: 'Date de dépôt', key: 'date_demande'},
                {label: 'Gestion de l\'emballage', key: 'emballage'}
            ],

            checkWarningApp: {
                selectedRowChange: ({row, isSelected}) => {
                    row.selected = isSelected ? 1 : 0
                },
                selectedAllChange: ({ isSelected }) => {
                    this.proceduresWarnings.forEach((item) => item.selected = isSelected ? 1 : 0)
                },
            },

            appMarques: [],
            appCategories: [],

            hasSelfcare: false,
        }
    },
    filters: {
        momentDate(date) {
            if (date && moment(date).isValid()) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date && moment(date).isValid()) {
                return moment(date).lang('fr').format('DD/MM/YYYY HH:mm:ss')
            }
            return '-'
        },
        typeGarantieLabel(label) {
            let labels = {
                'garantie_marque': 'Garantie Marque',
                'garantie_distributeur': 'Garantie Distributeur',
                'garantie_pms': 'Garantie PMS',
                'garantie_comp': 'Garantie Complémentaire',
                'hors_garantie': 'Hors garantie',
            }
            return labels[label]
        }
    },
    computed: {
        hasSelectedProducts() {
            if (this.selectedApps && (this.selectedApps.marques.length > 0 || this.selectedApps.categories.length > 0 || this.selectedApps.appareils.length > 0)) {
                return true
            }
            return false
        },

        exceptionProcedures() {
            let exceptionsNotDestroyIds = []
            this.proceduresWarnings.forEach((categ) => {
                categ.procedures.forEach((procedure) => {
                    // Get all apps that aren't check and save his liaison
                    procedure.liaisons.forEach((liaison) => {
                        if (!liaison.appareil.selected) {
                            exceptionsNotDestroyIds.push(liaison.proc_id)
                        }
                    })
                })
            })
            return exceptionsNotDestroyIds
        },

        centraleIds() {
            if (!this.selectedTypeList || !this.selectedCentralesList) {
                return []
            }
            let centraleIds = this.selectedCentralesList.map((centrale) => {
                if (centrale.selected) {
                    return centrale.id
                }
            }).filter(function( element ) {
                return element !== undefined;
            })
            return centraleIds
        },

        selectedCentralesList() {
            if (this.selectedTypeList) {
                return this.selectedTypeList == 'premium' ? this.listCentralesPremiums : this.listCentralesFrees
            }
            return null
        }
    },
    methods: {
        async getPatterns() {
            this.loadingGetPatterns = true
            this.selectedProcedure = null
            let response = await this.$request.post('procedures.get-patterns', {
                space: this.selectedSpace
            })
            this.procedures = response.data.posts.post
            this.loadingGetPatterns = false
        },

        async getCentrales() {
            this.loadingGetCentrales = true
            let endObj = {}
            if (this.filterWithoutProc) {
                endObj.without_proc = 1
            }
            let response = await this.$request.post('procedures.get-centrales', endObj)
            this.loadingGetCentrales = false
            this.centrales = response.data.posts.post
            this.centrales.premiums = this.centrales.premiums.map((item) => ({...item, selected: false}))
            this.centrales.frees = this.centrales.frees.map((item) => ({...item, selected: false}))
            this.listCentralesPremiums = this.centrales.premiums
            this.listCentralesFrees = this.centrales.frees
            this.exceptionProducts = []
        },

        selectProcedure(procedure) {
            if (this.selectedApps.marques.length <= 0 && this.selectedApps.categories.length <= 0 && this.selectedApps.appareils.length <= 0) {
                this.$toast.warning(this.$tt('Vous devez séléctionner au moins un appareil'))
                return
            }
            this.selectedProcedure = procedure
        },

        async applyProcedure() {
            if (!this.selectedProcedure || !this.selectedProcedure.id) {
                this.$toast.error(this.$tt('Merci de sélectionner une procédure'))
                return
            }

            this.loadingApplyProc = true

            try {
                let response = await this.$request.post('procedures.apply-pattern', {
                    appareils: JSON.stringify(this.selectedApps),
                    centrales: JSON.stringify(this.centraleIds),
                    pattern_config_id: this.selectedProcedure.id,
                    exceptions: JSON.stringify(this.exceptionProcedures),
                    exceptions_products: JSON.stringify(this.exceptionProducts),
                    type: this.typeMenu
                })

                // Response
                if (response.data.posts.post > 0) {
                    this.selectedProcedure = null
                    await this.getWarningExceptions()
                    this.$modal.hide('modal-apply-proc')
                    if (response.data.posts.post == 1) {
                        this.$toast.success(this.$tt('Procédure appliquée aux produits'))
                    }
                    if (response.data.posts.post == 2) {
                        this.$toast.success(this.$tt('En cours de configuration, un email sera envoyé une fois la tâche terminée'), {
                            hideProgressBar: true,
                            timeout: 10000,
                        })
                    }
                    if (this.filterWithoutProc == true) {
                        this.$eventHub.$emit('refresh-product-menu')
                    }
                }   
            } catch (error) {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }

            this.loadingApplyProc = false
        },

        searchCentrale() {
            if (this.centrales) {
                if (this.inpSearchCentrale) {
                    this.listCentralesPremiums = this.centrales.premiums.filter((centrale) => centrale.centrale_ha.toLowerCase().includes(this.inpSearchCentrale.toLowerCase()))
                    this.listCentralesFrees = this.centrales.frees.filter((centrale) => centrale.centrale_ha.toLowerCase().includes(this.inpSearchCentrale.toLowerCase()))
                } else {
                    this.listCentralesPremiums = [...this.centrales.premiums]
                    this.listCentralesFrees = [...this.centrales.frees]
                }
            }
        },

        async getWarningExceptions() {
            if (!this.selectedApps) {
                return
            }
            this.loadingGetException = true
            let response = await this.$request.post('procedures.apps-format-procedure', {
                list: JSON.stringify(this.selectedApps),
                centrales: JSON.stringify(this.centraleIds),
                without_proc: this.filterWithoutProc,
                type: this.typeMenu
            })
            this.proceduresWarnings = response.data.posts.post.with_proc.map((item) => ({
                ...item,
                selected: false,
                open: response.data.posts.post.with_proc.length > 1 ? false : true,
                noProcOpen: false,
            }))
            this.loadingGetException = false
        },

        addAllExceptions() {
            if (!this.proceduresWarnings.find((item) => !item.selected)) {
                this.proceduresWarnings.map((item) => item.selected = false)
            } else {
                this.proceduresWarnings.map((item) => item.selected = true)
            }
        },

        parseMomentDate(date) {
            if (date && moment(date).isValid()) {
                return moment(date).lang('fr').format('DD/MM/YYYY HH:mm:ss')
            }
            return '-'
        },

        openProc(proc) {
            let procState = proc.open
            this.proceduresWarnings.forEach((categ) => {
                categ.procedures.map((item) => item.open = false)
                categ.noProcOpen = false
            })
            proc.open = !procState
            this.$forceUpdate()
        },

        openNoProc(selectedCateg) {
            let procState = selectedCateg.noProcOpen
            this.proceduresWarnings.forEach((categ) => {
                categ.procedures.map((item) => item.open = false)
                categ.noProcOpen = false
            })
            selectedCateg.noProcOpen = !procState
        },

        selectCentrale(centrale, type) {
            if (this.selectedTypeList && type != this.selectedTypeList && this.centraleIds.length > 0) {
                this.$toast.error(this.$tt('Vous ne pouvez pas utiliser simultanément les abonnements gratuits et les abonnements premiums'))
                return
            }
            this.selectedTypeList = type
            if (this.filterWithoutProc == 1) {
                if (this.centraleIds && this.centraleIds.length > 0 && !centrale.selected) {
                    this.$toast.warning(this.$tt("Vous ne pouvez sélectionner qu'une centrale lorsque vous consulter les produits sans procédure"))
                    return
                }
            }
            centrale.selected = !centrale.selected
            this.inpSearchCentrale = null
            this.getWarningExceptions()
        },
        selectApp(proc) {
            if (proc.liaisons.find((item) => !item.appareil.selected)) {
                proc.selected = false
            }
            if (!proc.liaisons.find((item) => !item.appareil.selected)) {
                proc.selected = true
            }
        },

        async exportProcs() {
            this.loadingExportProc = true
            try {
                let response = await this.$request.post('procedure.send-proc-export')
                if (response.data.posts.post == 1) {
                    this.$toast.success(this.$tt("Vous allez recevoir un email une fois l'export terminé"))
                } else {
                    this.$toast.error(this.$tt('Une erreur est survenue'))
                }
            } catch (error) {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
            this.loadingExportProc = false
        },

        nbSelectedApps(categ = null) {
            let arrayEans = []
            let categs = []
            if (categ) {
                categs = [categ]
            } else {
                categs = this.proceduresWarnings
            }
            categs.forEach((categ) => {
                categ.noProcApps.forEach((app) => {arrayEans.push(app['Ean'])})
                categ.procedures.forEach((proc) => {
                    proc.liaisons.forEach((liaison) => {
                        if (liaison.appareil.selected) {
                            arrayEans.push(liaison.appareil['Ean'])
                        }
                    })
                })
            })
            // Remove EAN duplicate
            arrayEans = arrayEans.filter((item, index) => arrayEans.indexOf(item) === index)
            return arrayEans.length
        },

        toggleAppException(product) {
            let exceptionIndex = this.exceptionProducts.findIndex((item) => item == (product.id + '-' + product.centrale.id))
            if (exceptionIndex == -1) {
                this.exceptionProducts.push(product.id + '-' + product.centrale.id)
            } else {
                this.exceptionProducts.splice(exceptionIndex, 1)
            }
        },

        addedProduct() {
            this.getWarningExceptions()
            this.$eventHub.$emit('refresh-product-menu')
            this.$modal.hide('modal-add-product')
        },

        selectAllNoProcApp(noProcApps) {
            noProcApps.forEach((item) => {
                let findIndex = this.exceptionProducts.findIndex((exceptionId) => exceptionId == item.id)
                if (findIndex != -1) {
                    this.exceptionProducts.splice(findIndex, 1)
                }
            })
            this.$forceUpdate()
        },

        unselectAllNoProcApp(noProcApps) {
            noProcApps.forEach((item) => {
                let findIndex = this.exceptionProducts.findIndex((exceptionId) => exceptionId == item.id)
                if (findIndex == -1) {
                    this.exceptionProducts.push(item.id)
                }
            })
            this.$forceUpdate()
        },

        displayProcModal() {
            this.selectedSpace = null
            this.selectedProcedure = null
            this.$modal.show('modal-apply-proc')
        },

        async checkHasSelfcare() {
            let response = await this.$request.post('module.check', {
                module: 'selfcare'
            })
            this.hasSelfcare = response.data.posts.post
        }
    },
    mounted () {
        this.getCentrales()
        this.checkHasSelfcare()
    },
}
</script>

<style>
.warning-table th, .warning-table td {
    width: 200px !important;
}

.ve-table-body-td {
    padding: 5px 10px 5px 10px !important;
    font-size: 13px !important;
}
</style>