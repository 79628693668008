<template>
    <div>
        <modal name="modal-add-account" width="80%" height="auto">
            <div class="p-10" style="z-index: 999 !important;">
                <div class="flex items-center border-b pb-4 mb-2 justify-between">
                    <h2 class="text-xl font-bold">{{ $tt('Ajouter un utilisateur') }}</h2>
                    <button class="cursor-pointer" @click="$modal.hide('modal-add-account')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <input-rule v-model="form.nom" type="text" class="text-left mb-4" custom="bg-gray-100" :label="$tt('Nom')" id="account-name" :label-inline="false" />
                <input-rule v-model="form.prenom" type="text" class="text-left mb-4" custom="bg-gray-100" :label="$tt('Prénom')" id="account-name" :label-inline="false" />
                <input-rule v-model="form.email" type="text" class="text-left mb-4" custom="bg-gray-100" :label="$tt('Email')" id="account-email" :label-inline="false" />
                <input-rule v-model="form.tel" type="tel" :options="{country: country}" class="text-left mb-4" custom="bg-gray-100" :label="$tt('Téléphone')" id="account-tel" :label-inline="false" />
                <input-rule v-model="form.poste" type="text" class="text-left mb-4" custom="bg-gray-100" :label="$tt('Poste (optionnel)')" id="account-name" :label-inline="false" />
                <button @click="endForm" class="mx-auto block bg-emerald-600 hover:bg-emerald-700 duration-200 text-white border-emerald-600 font-semibold cursor-pointer border rounded-xl text-sm mt-4 px-4 py-2">
                    <i class="fas fa-check mr-0.5"></i> {{ $tt('Ajouter') }}
                </button>
            </div>
        </modal>

        <div v-if="!onlyForm">
            <button @click="$modal.show('modal-add-account')" class="font-light bg-sky-600 hover:bg-sky-700 px-6 py-2 text-sm text-white rounded">
                <i class="fas fa-plus mr-0.5"></i> {{ $tt('Ajouter un utilisateur') }}
            </button>
        </div>
        <div v-if="onlyForm">
            <input-rule v-model="form.nom" type="text" class="text-left mb-4" custom="bg-gray-100" :label="$tt('Nom')" id="account-name" :label-inline="false" />
            <input-rule v-model="form.prenom" type="text" class="text-left mb-4" custom="bg-gray-100" :label="$tt('Prénom')" id="account-name" :label-inline="false" />
            <input-rule v-model="form.email" type="text" class="text-left mb-4" custom="bg-gray-100" :label="$tt('Email')" id="account-email" :label-inline="false" />
            <input-rule v-model="form.tel" type="tel" :options="{country: country}" class="text-left mb-4" custom="bg-gray-100" :label="$tt('Téléphone')" id="account-tel" :label-inline="false" />
            <input-rule v-model="form.poste" type="text" class="text-left mb-4" custom="bg-gray-100" :label="$tt('Poste (optionnel)')" id="account-name" :label-inline="false" />
            <button :disabled="loaderEndForm" @click="endForm" class="mx-auto block bg-emerald-600 hover:bg-emerald-700 duration-200 text-white border-emerald-600 font-semibold cursor-pointer border rounded-xl text-sm mt-4 px-4 py-2">
                <template v-if="!loaderEndForm">
                    <i class="fas fa-check mr-0.5"></i> {{ $tt('Ajouter') }}
                </template>
                <template v-if="loaderEndForm">
                    <spinner :size="10" />
                </template>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        signId: {required: true},
        signType: {required: true},
        onlyForm: {default: false},
        country: {}
    },

    data() {
        return {
            form: {},
            loaderEndForm: false
        }
    },

    methods: {
        async endForm() {
            if (!this.$validation.check([
                {value: this.form.nom, rules: 'required', id: 'account-name'},
                {value: this.form.email, rules: 'required|email', id: 'account-email'},
                {value: this.form.tel, rules: 'required', id: 'account-tel'}
            ])) {return}

            let accountExist = await this.$request.post('inscription.account-exist', {
                email: this.form.email,
            })
            if (accountExist.data.posts.post.email_exist == 1) {
                this.$toast.error(this.$tt('Cette adresse email est déjà enregistrée'))
            } else if (accountExist.data.posts.post.claim_exist == 1) {
                this.$toast.error(this.$tt('Une demande est déjà en cours pour cette adresse email'))
            } else {
                this.$modal.hide('modal-add-account')
                this.$emit('update', this.form)
            }
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
