<template>
    <div>
        <modal name="modal-display-centrale" height="auto" width="80%" :scrollable="true">
            <div v-if="model" class="p-10" style="z-index: 999 !important;">
                <h2 class="text-xl font-bold mb-2">Centrale d'achat</h2>
                <centrale-view
                :edit="false"
                :id="model.id_centrale_ha"
                />
            </div>
        </modal>

        <modal name="reset-password-modal" height="auto" :scrollable="false">
            <div class="p-10 z-20" v-if="selectedLine">
                <h1 class="text-xl font-bold">Réinitialiser le mot de passe ?</h1>
                <div class="bg-slate-100 border rounded p-4 my-4">{{ selectedLine.email }}</div>
                <spinner :size="10" v-if="loadingModal" />
                <template v-if="!loadingModal">
                    <button @click="$modal.hide('reset-password-modal')" class="mr-1 text-sm bg-slate-200 hover:bg-slate-300 duration-200 cursor-pointer font-light px-5 py-2.5 rounded-xl" type="submit">
                        <i class="fas fa-times mr-0.5"></i> Annuler
                    </button>
                    <button @click="resetUserPassword" class="mr-1 text-sm bg-orange-500 hover:bg-orange-600 duration-200 cursor-pointer font-light px-5 py-2.5 rounded-xl text-white" type="submit">
                        <i class="fas fa-check mr-0.5"></i> Confirmer
                    </button>
                </template>
            </div>
        </modal>

        <div class="py-4" v-if="loaderGetData">
            <div class="flex justify-center">
                <spinner :size="12" />
            </div>
            <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
        </div>
        <template v-if="!loaderGetData && model">
            <div class="grid lg:grid-cols-2 gap-2">
                <div>
                    <div class="rounded-lg mb-2 border bg-white">
                        <label class="rounded-t-lg text-xs text-center p-2 font-bold border-b hidden block"><i class="fas fa-file mr-0.5"></i> Nom</label>
                        <div class="p-2">
                            <input-rule v-model="model.web" :display-value="!edit" type="pcheck" label="Ecommerce" class="mb-1" />
                            <input-rule v-model="model.independant" :display-value="!edit" type="pcheck" label="Indépendant" class="mb-1" />
                            <input-rule v-model="model.actif" :display-value="!edit" type="pcheck" label="Actif" />
                            <div class="flex items-center gap-2">
                                <input-rule class="grow" v-model="model.magasin" :display-value="!edit" type="text" label="Nom du magasin *" id="inp-nom" />
                                <a :href="googleLink" target="_blank" class="bg-purple-200 hover:bg-purple-300 cursor-pointer duration-200 text-xs p-1 px-2 rounded-full"><i class="fas fa-search"></i></a>
                            </div>
                            <template v-if="model.centrale">
                                <display-value @click="$modal.show('modal-display-centrale')" type="text" :data="'<a class=\'text-blue-500 underline\' href=\'#\'> <i class=\'fas fa-eye mr-1\'></i>' + model.centrale.centrale_ha + '</a>'" label="Enseigne" />
                            </template>
                            <template v-else>
                                <input-rule
                                v-model="form.selectedCentrale"
                                :display-value="!edit"
                                type="api-select"
                                :options="{
                                    api: {action: 'centrale.search', apiName: 'api2', options: {limit: 500, orderBy: 'centrale_ha'}},
                                    label: 'centrale_ha',
                                    keyTrack: 'id'
                                }"
                                label="Enseigne"
                                id="inp-centrale-ha"
                                />
                            </template>
                        </div>
                    </div>
                    <div class="rounded-lg mb-2 border bg-white">
                        <label class="rounded-t-lg text-xs text-center p-2 font-bold border-b hidden block"><i class="fas fa-home mr-0.5"></i> Adresse</label>
                        <div class="p-2">
                            <input-rule v-model="form.adresse" :display-value="!edit" type="address2" :options="{defaultValue: model, country: (model.pays || 'FR')}" label="Adresse *" id="inp-address" />
                            <div v-if="!form.adresse" class="bg-orange-100 text-orange-800 mt-2 text-xs p-3 rounded">
                                Adresse introuvable: {{ fullAddress }}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="rounded-lg mb-2 border bg-white">
                        <label class="rounded-t-lg text-xs text-center p-2 font-bold border-b hidden block"><i class="fas fa-building mr-0.5"></i> Société</label>
                        <div class="p-2">
                            <input-rule v-model="model.telsav" :display-value="!edit" type="text" label="Téléphone" />
                            <input-rule v-model="model.mailant" :display-value="!edit" type="text" label="Email" />
                        </div>
                    </div>
                    <div class="rounded-lg mb-2 border bg-white">
                        <label class="rounded-t-lg text-xs text-center p-2 font-bold border-b hidden block"><i class="fas fa-building mr-0.5"></i> Société</label>
                        <div class="p-2">
                            <input-rule v-model="model.numintra" :display-value="!edit" type="text" label="Numéro intra-communautaire" />
                            <div class="flex items-center gap-2">
                                <input-rule class="grow" v-model="model.siret_mag" :display-value="!edit" type="text" label="SIRET" />
                                <a :href="societeSiretLink" target="_blank" class="bg-purple-200 hover:bg-purple-300 cursor-pointer duration-200 text-xs p-1 px-2 rounded-full"><i class="fas fa-search"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="edit" class="text-center">
                <button :disabled="loaderEditAdd" class="px-4 py-2 rounded my-1 bg-green-200 hover:bg-green-300 duration-200 text-sm" @click="editAdd">
                    <template v-if="!loaderEditAdd">
                        <template v-if="model?.id">Modifier <i class="fas fa-pen ml-1"></i></template>
                        <template v-if="!model?.id">Enregistrer <i class="fas fa-plus ml-1"></i></template>
                    </template>
                    <template v-else>
                        <spinner :size="10" />
                    </template>
                </button>
            </div>
        </template>
    </div>
</template>

<script>
import CentraleView from './../Centrale/CentraleView.vue'

export default {
    components: {
        CentraleView,
    },
    props: {
        id: {},
        edit: {
            type: Boolean,
        },
        dataSrc: {},
    },

    data() {
        return {
            model: null,
            loaderGetData: false,

            selectedLine: null,
            loadingModal: false,
            loaderEditAdd: false,

            form: {
                selectedCentrale: null,
                adresse: null,
            }
        }
    },

    computed: {
        fullAddress() {
            if (this.model && this.model.adresse1) {
                return this.model.adresse1 + ' ' + (this.model.adresse2 || '') + ' ' + this.model.ville + ' ' + this.model.cp
            }
            return null
        },

        googleLink() {
            return 'https://www.google.com/search?q=' + (this.model.magasin.replace(' ', '+')) + '+societe.com'
        },

        societeSiretLink() {
            return 'https://www.societe.com/cgi-bin/search?champs=' + this.model.siret_mag
        }
    },

    methods: {
        async getData(id = null) {
            this.loaderGetData = true
            if (id || this.id) {
                let response = await this.$request.post('magasin.view', {
                    line_id: id || this.id
                })
                if (response.data.posts.post) {
                    this.model = response.data.posts.post
                }
            } else {
                this.model = {}
            }
            this.loaderGetData = false
        },

        async editAdd() {
            let endObj = {...this.model}
            // Remove useless field
            if (endObj.id) {
                endObj.line_id = endObj.id
                endObj.id_centrale_ha = 1
                delete endObj.users
                delete endObj.centrale
                delete endObj.id
            }
            if (!this.$validation.check([
                {value: this.form.adresse, rules: 'required', id: 'inp-address'},
            ])) {return}
            // Set address
            endObj.adresse1 = this.form.adresse.adresse1
            endObj.adresse2 = this.form.adresse.adresse2
            endObj.cp = this.form.adresse.cp
            endObj.ville = this.form.adresse.ville
            endObj.pays = this.form.adresse.pays
            endObj.lng = this.form.adresse.longitude
            endObj.lat = this.form.adresse.latitude
            // Set centrale
            endObj.id_centrale_ha = this.form.selectedCentrale
            if (!endObj.id_centrale_ha) {
                endObj.id_centrale_ha = this.model.centrale.id
            }

            for (let key in endObj) {
                if (endObj[key] === null || endObj[key] === undefined) {
                    delete endObj[key]
                }
            }

            if (!this.$validation.check([
                {value: this.model.magasin, rules: 'required', id: 'inp-nom'},
                {value: this.model.id_centrale_ha, rules: 'required', id: 'inp-centrale-ha'},
                {value: this.form.adresse, rules: 'required', id: 'inp-address'},
            ])) {return}
            
            this.loaderEditAdd = true
            let response = await this.$request.post('magasin.edit-add', endObj)
            if (response.data.posts.post) {
                this.$emit('update', response.data.posts.post)
                this.getData(response.data.posts.post.id)
                this.$toast.success((endObj.id ? 'Magasin modifié' : 'Magasin enregistré'), {position: "bottom-right"})

            } else {
                this.$toast.error("Erreur lors de l'enregistrement")
            }
            this.loaderEditAdd = false
        },

        displayResetPasswordModal(line) {
            this.selectedLine = line
            this.$modal.show('reset-password-modal')
        },

        async resetUserPassword() {
            this.loadingModal = true
            let response = await this.$request.post('inscription.send-reset-password', {
                account_id: this.selectedLine.id,
                contact_mail: this.selectedLine.contact_mail,
            })
            if (response.data.posts.post == 1) {
                this.$toast.success('Email de réinitialisation envoyé')
                this.$modal.hide('reset-password-modal')
            } else {
                this.$toast.error('Une erreur est survenue')
            }
            this.loadingModal = false
        }
    },

    async mounted () {
        await this.getData();

        if (this.dataSrc) {
            this.model = this.dataSrc
            this.form.selectedCentrale = this.model.id_centrale_ha
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
