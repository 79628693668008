<template>
    <div>
        <template v-if="loader">
            <spinner :size="10" />
        </template>
        <template v-if="!loader">
            <div class="bg-slate-100 p-4 mb-4 rounded">
                <input-rule v-model="typePaiement" :options="{values: [{id: 'fixe', label: 'Fixe'}, {id: 'custom', label: 'A la carte'}], key: 'id', label: 'label'}" type="select" label="Type de paiement" id="inp-periodicite" />
            </div>
            <input-rule class="mb-1" v-model="form.periodicite" :options="{values: [{label: 'Mois', value: 1}, {label: 'Trimestre', value: 3}, {label: 'Année', value: 12}], label: 'label', key: 'value'}" type="select" label="Périodicité" id="inp-periodicite" />
            <!-- <div class="grid grid-cols-2 gap-4 my-4">
                <div :class="typePaiement == 'fixe' ? 'bg-blue-200': 'bg-slate-100 hover:bg-slate-200'" class="cursor-pointer text-center rounded py-2 text-sm font-light" @click="typePaiement = 'fixe'">Fixe</div>
                <div :class="typePaiement == 'custom' ? 'bg-blue-200': 'bg-slate-100 hover:bg-slate-200'" class="cursor-pointer text-center rounded py-2 text-sm font-light" @click="typePaiement = 'custom'">A la carte</div>
            </div> -->
            <template v-if="typePaiement == 'fixe'">
                <input-rule class="mb-1" v-model="form.tarif_fixe" type="price" label="Tarif fixe" id="inp-tarif_fixe" />
            </template>
            <template v-if="typePaiement == 'custom'">
                <input-rule class="mb-1" v-model="form.pieces_dettachees" type="price" label="Pièces déttachées" id="inp-pieces_dettachees" />
                <input-rule class="mb-1" v-model="form.dossier_sav_avec_transport" type="price" label="Dossier sav (avec transport)" id="inp-dossier_sav_avec_transport" />
                <input-rule class="mb-1" v-model="form.dossier_sav_sans_transport" type="price" label="Dossier sav (sans transport)" id="inp-dossier_sav_sans_transport" />
                <input-rule class="mb-1" v-model="form.dossier_dsc_faible_valeur" type="price" label="Dsc faible valeur" id="inp-dossier_dsc_faible_valeur" />
                <input-rule class="mb-1" v-model="form.callcenter" type="price" label="Callcenter (par utilisateur)" id="inp-callcenter" />
            </template>
            <div class="text-center mt-4">
                <button @click="sendForm" class="text-sm px-4 py-2 bg-green-200 hover:bg-green-300 duration-200 cursor-pointer rounded">
                    <spinner :size="10" v-if="sendLoader" />
                    <template v-if="!sendLoader">
                        <i class="fas fa-check mr-1"></i> Enregistrer
                    </template>
                </button>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        signId: {required: true},
    },
    data() {
        return {
            typePaiement: 'fixe',
            form: {},
            loader: false,
            sendLoader: false,
        }
    },
    methods: {
        initForm() {
            this.form.client_id = this.signId
            this.form.periodicite = 0
            this.form.tarif_fixe = 0
            this.form.pieces_dettachees = 0
            this.form.dossier_sav_avec_transport = 0
            this.form.dossier_sav_sans_transport = 0
            this.form.dossier_dsc_faible_valeur = 0
            this.form.callcenter = 0
        },

        async getOffer() {
            this.loader = true
            let response = await this.$request.post('facturation.get-offer', {
                client_id: this.signId
            })
            this.loader = false
            delete response.data.posts.post.id
            this.form = response.data.posts.post
            this.typePaiement = this.form.tarif_fixe ? 'fixe' : 'custom'
        },

        async sendForm() {
            // Validation
            if (!this.$validation.check([
                {value: this.form.periodicite, rules: 'required', id: 'inp-periodicite'},
            ])) {return}

            // Remove useless values
            if (this.typePaiement == 'fixe') {
                this.form.pieces_dettachees = 0
                this.form.dossier_sav_avec_transport = 0
                this.form.dossier_sav_sans_transport = 0
                this.form.dossier_dsc_faible_valeur = 0
                this.form.callcenter = 0
            } else {
                this.form.tarif_fixe = 0
            }

            // Send request
            this.sendLoader = true
            let response = await this.$request.post('facturation.edit-add-offer', this.form)
            if (response.data.posts.post && response.data.posts.post.id) {
                this.$toast.success('Offre modifée')
            }
            this.sendLoader = false
        }
    },
    mounted () {
        this.initForm()
        this.getOffer()
    },
}
</script>

<style lang="scss" scoped>

</style>