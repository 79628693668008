<template>
    <div class="bg-slate-50 h-[100vh]" style="overflow-y: auto !important;">
        <div class="container mx-auto px-4 lg:px-0 mb-10">
            <div class="py-4 flex items-center gap-4 justify-between">
                <img src="https://disticloudfiles.net/upload/files/logo_districloud.png" class="mx-auto lg:mx-0">
                <div class="text-lg font-light text-sky-600 hidden lg:block">
                    {{ $tt('Validation des agréments') }}
                </div>
            </div>
            <div v-if="formSaved" class="bg-green-600 px-5 py-10 rounded-lg text-white text-center max-w-[500px] mt-10 mx-auto shadow-xl">
                <i class="fas fa-check-circle text-2xl mb-2"></i>
                <div class="text-xl font-bold">{{$tt('Votre validation a bien été enregistrée')}}</div>
                <div class="font-light mt-1">{{$tt('Merci pour votre retour')}}</div>
            </div>
            <div v-if="!formSaved">
                <div class="py-4 bg-white border shadow-lg" v-if="loadingGetData">
                    <div class="flex justify-center">
                        <spinner :size="12" />
                    </div>
                    <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
                </div>
                <template v-if="data">
                    <div class="lg:grid grid-cols-6 gap-4">
                        <div class="col-span-2">
                            <div class="border p-4 rounded mb-4 shadow-lg bg-white">
                                <h2 class="font-bold mb-4">{{ $tt('Station de réparation') }}</h2>
                                <div class="border-t py-1"><span class="text-xs font-light">{{ $tt('Nom') }} :</span> <br /><span class="font-light text-sm">{{ data.station.nom || '-' }}</span></div>
                                <div class="border-t py-1"><span class="text-xs font-light">{{ $tt('Téléphone') }} :</span> <br /><span class="font-light text-sm">{{ data.station.tel || '-' }}</span></div>
                                <div class="border-t py-1"><span class="text-xs font-light">{{ $tt('Email') }} :</span> <br /><span class="font-light text-sm">{{ data.station.mail || '-' }}</span></div>
                                <div v-if="data.station.adresse" class="border-t py-1"><span class="text-xs font-light">{{ $tt('Adresse postale') }} :</span> <br /><span class="font-light text-sm">{{ data.station.adresse + ' ' + data.station.cp + ', ' + data.station.ville }}</span></div>
                                <div class="border-t py-1"><span class="text-xs font-light">{{ $tt('SIRET') }} :</span> <br /><span class="font-light text-sm">{{ data.station.siret || '-' }}</span></div>
                            </div>
                        </div>
                        <div class="col-span-4">
                            <div class="border p-4 rounded mb-4 shadow-lg bg-white">
                                <h2 class="text-xl font-bold mb-6 mt-2">{{ $tt('Pourriez-vous confirmer les agréments entre') + ' ' + data.station.nom + ' ' + $tt('et') + ' ' + data.grossiste.nom }}</h2>
                                <div v-for="(agrement, agrementIndex) in data.agrements" :key="'agrement-' + agrementIndex">
                                    <div class="border-t py-4 lg:flex items-center justify-between gap-2">
                                        <div class="flex items-center gap-4">
                                            <div :class="agrement.agree == 0 ? 'opacity-20' : ''">
                                                <div class="font-bold">{{agrement.activite.libelle}}</div>
                                                <div class="font-light text-xs">{{agrement.activite.description}}</div>
                                            </div>
                                            <div v-if="agrement.id_zone_iad" class="px-4 py-2 bg-orange-100 border border-orange-300 text-orange-500 font-bold rounded text-xs"><i class="fas fa-home mr-1"></i> {{$tt('Intervention à domicile')}}</div>
                                        </div>
                                        <!-- 1. Responsive: reverse buttons -->
                                        <div class="items-center gap-2 text-sm hidden lg:flex">
                                            <input-rule v-if="!agrement.agree || agrement.agree == 0 || agrement.agree == 2" v-model="agrement.motif_refus" type="textarea" :options="{rows: 2}" :placeholder="$tt('Motif du refus')" :label-inline="false" class="my-2 lg:my-0 w-[300px]" />
                                            <select v-model="agrement.agree" class="focus:outline-none rounded px-1.5 py-1 text-white" :class="agrement.agree == 1 ? 'bg-green-500' : 'bg-red-500'">
                                                <option value="1">{{ $tt('Validé') }}</option>
                                                <option value="2">{{ $tt('Refusé') }}</option>
                                            </select>
                                        </div>
                                        <!-- 2. Responsive: reverse buttons -->
                                        <div class="text-sm lg:hidden mt-2">
                                            <select v-model="agrement.agree" class="focus:outline-none rounded px-1.5 py-1 text-white" :class="agrement.agree == 1 ? 'bg-green-500' : 'bg-red-500'">
                                                <option value="1">{{ $tt('Validé') }}</option>
                                                <option value="2">{{ $tt('Refusé') }}</option>
                                            </select>
                                            <input-rule v-if="!agrement.agree || agrement.agree == 0 || agrement.agree == 2" v-model="agrement.motif_refus" type="textarea" :options="{rows: 2}" :placeholder="$tt('Motif du refus')" :label-inline="false" class="my-2 lg:my-0" />
                                        </div>
                                    </div>
                                </div>
                                <div class="border-t py-4 text-right">
                                    <button :disabled="loadingSave" @click="saveForm" class="px-4 py-2 text-white bg-sky-700 hover:bg-sky-800 text-sm duration-200 rounded">
                                        <spinner v-if="loadingSave" :size="10" color="#FFF" />
                                        <template v-if="!loadingSave">
                                            {{ $tt('Terminer la validation') }}
                                        </template> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: null,

            formSaved: false,

            loadingGetData: false,
            loadingSave: false,
        }
    },
    methods: {
        async getData() {
            this.loadingGetData = true
            let response = await this.$request.post('reparateur.get-validation-agrements', {
                validation_token: this.$route.params.token,
            })
            this.loadingGetData = false

            if (response.data.posts.post && response.data.posts.post != 0) {
                this.data = response.data.posts.post
                this.data.agrements = this.data.agrements.filter((item) => item.agree)
            } else {
                this.$toast.error(this.$tt('Page introuvable'))
            }
        },

        async saveForm() {
            let endData = this.data.agrements.map((item) => {
                return {
                    id: item.id,
                    agree: item.agree,
                    motif_refus: item.motif_refus || ''
                }
            })
            this.loadingSave = true
            let response = await this.$request.post('reparateur.save-validation-agrements', {
                validation_token: this.$route.params.token,
                agrements: JSON.stringify(endData),
            })
            this.loadingSave = false
            if (response.data.posts.post == 1) {
                this.formSaved = true
            } else {
                this.$toast.error('Une erreur est survenue')
            }
        }
    },
    mounted () {
        this.getData()
    },
}
</script>

<style lang="scss" scoped>

</style>