/*global google*/

var apiKey = "pk.eyJ1IjoidmljZHVib3NzIiwiYSI6ImNqczNpN3B4djI3eHM0M3RmY29qcTZ0c2cifQ.DiW4jJ5luny6EX3ajfjoKg"
const MapService = {
    apiKey,

    search: async (address, country = 'FR') => {
        return new Promise((resolve) => {
            const request = {
                address: address,
                componentRestrictions: {
                    country: country
                }
            }

            var geocoder = new google.maps.Geocoder();
            geocoder.geocode(request, function(results, status) {
                if (status == 'OK') {
                    resolve(results)
                } else {
                    console.warn(status)
                    resolve([]);
                }
            });
        })
    }
}
export default MapService
