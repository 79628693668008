<template>
    <div>
        <app-template>
            <div class="bg-white p-10 rounded shadow">
                <data-table
                :server="true"
                :filters="[
                    // {label: 'Nom', key: 'nom', type: 'text'},
                ]"
                :columns="[
                    {label: 'Nom', key: 'nom', type: 'text'},
                    {label: 'Type entité', key: 'type_entite_libelle', type: 'text'},
                    {label: 'Date licence', key: 'date_licence', type: 'datetime'},
                ]"
                :request="{
                    name: 'client.list',
                    params: {
                        page: 0,
                        per_page: 50,
                    },
                    apiName: 'api2'
                }"
                >
                    <template v-slot:actions='{line}'>
                        <div class="flex items-center">
                            <button @click="$router.push({name: 'client.view', params: {id: line.id}})" class="mr-1 text-xs bg-orange-500 hover:bg-orange-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white">
                                <i class="fas fa-eye"></i>
                            </button>
                        </div>
                    </template>
                </data-table>
            </div>
        </app-template>
    </div>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            selectedLine: null,
            loaderModal: false
        }
    },
    methods: {
    },
}
</script>

<style lang="scss" scoped>

</style>
