var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-template',[_c('div',{staticClass:"bg-white p-10 rounded shadow"},[_c('data-table',{attrs:{"server":true,"filters":[
                // {label: 'Nom', key: 'nom', type: 'text'},
            ],"columns":[
                {label: 'Nom', key: 'nom', type: 'text'},
                {label: 'Type entité', key: 'type_entite_libelle', type: 'text'},
                {label: 'Date licence', key: 'date_licence', type: 'datetime'},
            ],"request":{
                name: 'client.list',
                params: {
                    page: 0,
                    per_page: 50,
                },
                apiName: 'api2'
            }},scopedSlots:_vm._u([{key:"actions",fn:function({line}){return [_c('div',{staticClass:"flex items-center"},[_c('button',{staticClass:"mr-1 text-xs bg-orange-500 hover:bg-orange-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white",on:{"click":function($event){return _vm.$router.push({name: 'client.view', params: {id: line.id}})}}},[_c('i',{staticClass:"fas fa-eye"})])])]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }