<template>
    <div>
        <modal name="modal-view-log" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedLog">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">Log</h2>
                    <button @click="$modal.hide('modal-view-log')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div class="bg-slate-100 p-4 rounded">
                    <json-viewer :value="parseLog(selectedLog['3'])"></json-viewer>
                </div>
            </div>
        </modal>

        <app-template>
            <div class="bg-white p-10 rounded shadow">
                <div class="grid grid-cols-6 gap-4">
                    <div>
                        <div class="bg-slate-100 p-4 rounded sticky top-5">
                            <h2 class="text-xl font-bold">Période</h2>
                            <input class="p-2 text-sm w-full border rounded mt-2" v-model="filename" type="month">
                        </div>
                    </div>
                    <div class="col-span-5" v-if="logs">
                        <div class="relative overflow-x-auto">
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                                <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            Date
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Opération
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Type
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            User
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(log, logIndex) in logs.data" :key="'logs-' + logIndex" class="bg-white border-b">
                                        <td class="px-6 py-1">
                                            {{ log['2'].split(' - ')[0] }}
                                        </td>
                                        <td class="px-6 py-1">
                                            {{ log['4'] }}
                                        </td>
                                        <td class="px-6 py-1">
                                            {{ log['1'] }}
                                        </td>
                                        <td class="px-6 py-1">
                                            {{ log['2'].split(' - ')[1] }}
                                        </td>
                                        <td class="px-6 py-1">
                                            <button @click="selectedLog = log; $modal.show('modal-view-log')" class="px-2 py-1 bg-slate-200 hover:bg-slate-300 duration-200 rounded"><i class="fas fa-eye"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <pagination
                        v-model="page"
                        :per-page="logs.per_page"
                        :records="logs.total"
                        />
                    </div>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import moment from 'moment'
import JsonViewer from 'vue-json-viewer'

import Pagination from 'vue-pagination-2';

export default {
    components: {
        JsonViewer,
        Pagination
    },
    watch: {
        filename() {
            this.getLogs()
        },
        page() {
            this.getLogs()
        }
    },
    data() {
        return {
            filename: null,
            logs: null,
            selectedLog: null,
            page: 1
        }
    },
    computed: {
        filenameFormat() {
            if (this.filename) {
                return moment(this.filename).format('MM_YYYY')
            }
            return null
        }
    },
    methods: {
        async getLogs() {
            let response = await this.$request.post('log.list', {
                filename: this.filenameFormat,
                page: this.page
            })
            this.logs = null
            this.$nextTick(() => {
                this.logs = response.data.posts.post
            })
        },

        parseLog(log) {
            return JSON.parse(log)
        }
    },
}
</script>

<style lang="scss" scoped>

</style>