<template>
    <div>
        <div class="relative overflow-x-auto">
            <div class="grid grid-cols-3 gap-4 my-2" v-if="admin">
                <input-rule v-model="filters.intervenant" type="vselect" :options="{values: intervenants, label: 'nom', key: 'id'}" :label="$tt('Intervenant')" :label-inline="false" class="mb-2" />
                <input-rule
                v-model="filters.nom_do"
                type="api-select"
                :options="{
                    api: {action: 'technicien.search-donneur-ordre', apiName: 'api2', options: {limit: 500, orderBy: 'nom_do'}},
                    label: 'nom_do',
                    keyTrack: 'nom_do',
                    init: false,
                }"
                :label="$tt('Donneur d\'ordre')"
                :label-inline="false"
                class="mb-2"
                />
                <div class="flex items-center mb-2">
                    <input-rule v-model="filters.date" type="date" :label="$tt('Date')" :label-inline="false" class="grow" />
                    <button class="px-2 mt-4 pt-1.5 text-slate-700 text-sm" @click="filters.date = null"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="mb-20">
                <h2 class="text-xl font-bold mb-2">{{ $tt('Dossiers en cours') }}</h2>
                <factu-iad-table :lines="currentFolders" table-class="bg-slate-200" :admin="admin" />
            </div>
            <div v-if="!admin" class="mb-10">
                <h2 class="text-xl font-bold mb-2">{{ $tt('Dossiers en attente') }}</h2>
                <factu-iad-table :lines="waitingFolders" table-class="bg-orange-300" :admin="admin" />
            </div>
        </div>
    </div>
</template>

<script>
import FactuIadTable from './FactuIadTable.vue'

export default {
    components: {
        FactuIadTable,
    },
    props: {
        admin: {default: false}
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.getLines()
            }
        },
        admin: {
            handler() {
                this.init()
            }
        }
    },
    data() {
        return {
            currentFolders: null,
            waitingFolders: null,

            intervenants: [],
            donneurOrdres: [],
            dossiersEncours: [],
            
            timerGetLines: null,

            filters: {}
        }
    },
    methods: {
        init() {
            this.getLines()

            if (this.admin) {
                this.getIntervenants()
                this.getDonneurOrdres()
            }
        },

        async getLines() {
            clearTimeout(this.timerGetLines)
            this.timerGetLines = setTimeout(() => {
                this.getCurrentFolders()
                this.getWaitingFolders()
            }, 500)
        },

        async getCurrentFolders() {
            let endData = {
                en_cours: 1,
                admin: this.admin ? 1 : 0,
            }
            if (this.filters.intervenant) {
                endData.intervenant_id = this.filters.intervenant
            }
            if (this.filters.nom_do && this.filters.nom_do !== undefined) {
                endData.nom_do = this.filters.nom_do
            }
            if (this.filters.date && this.filters.date !== undefined) {
                endData.date = this.filters.date
            }
            let response = await this.$request.post('technicien.encours-iad', endData)
            this.currentFolders = response.data.posts.post
        },

        async getWaitingFolders() {
            let endData = {
                en_cours: 0,
                admin: this.admin ? 1 : 0,
            }
            if (this.filters.intervenant) {
                endData.intervenant_id = this.filters.intervenant
            }
            if (this.filters.nom_do && this.filters.nom_do !== undefined) {
                endData.nom_do = this.filters.nom_do
            }
            if (this.filters.date && this.filters.date !== undefined) {
                endData.date = this.filters.date
            }
            let response = await this.$request.post('technicien.encours-iad', endData)
            this.waitingFolders = response.data.posts.post
        },
        
        async getIntervenants() {
            let response = await this.$request.post('technicien.get-techniciens-list')
            this.intervenants = response.data.posts.post
        },

        async getDonneurOrdres() {
            let response = await this.$request.post('technicien.get-donneur-ordre-list')
            this.donneurOrdres = response.data.posts.post
        }
    },
    mounted () {
        this.init()
    },
}
</script>

<style lang="scss" scoped>

</style>