<template>
    <div>
        <app-template
        page-name="Distriboard"
        >
            <!-- App -->
            <template v-slot:submenu>
                <div class="p-4">
                    <h1 class="text-xl font-bold mb-4 pb-4 border-b">
                        <i class="fa-solid fa-filter mr-1"></i> {{ $tt('Filtres') }}
                    </h1>
                    <div class="mb-4">
                        <label class="block mb-1 text-xs font-bold">{{ $tt('Je souhaite faire') }}</label>
                        <div>
                            <div class="mb-1 text-sm">
                                <input v-model="typeAvoir" value="centrale" id="centrale" class="mr-2" type="radio">
                                <label for="centrale">{{ $tt('Un avoir à la centrale') }}</label>
                            </div>
                            <div class="mb-1 text-sm">
                                <input v-model="typeAvoir" value="magasin" id="magasin" class="mr-2" type="radio">
                                <label for="magasin">{{ $tt('Un avoir au magasin') }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="mb-4">
                        <label class="block mb-1 text-xs font-bold">{{ $tt("Centrale d'achat / Magasin") }}</label>
                        <select v-model="selectedSign" class="bg-gray-50 text-sm rounded-lg block w-full p-1.5 focus:outline-none border border-gray-300">
                            <option v-for="(sign, signIndex) in waitingSigns" :key="'waiting-sign-' + signIndex" :value="sign">{{ sign.centrale_ha }} ({{ sign.nb_dossier + (' ' + (sign.nb_dossier > 1 ? $tt('dossiers') : $tt('dossier'))) }})</option>
                            <option disabled="true">------------</option>
                            <option v-for="(sign, signIndex) in signsWithoutWaitingFolders" :key="'sign-' + signIndex" :value="sign">{{ sign.centrale_ha }}</option>
                        </select>
                    </div>
                    <div v-if="shops && typeAvoir == 'magasin'" class="mb-4">
                        <label class="block mb-1 text-xs font-bold">{{ $tt('Liste des magasins') }}</label>
                        <select v-model="filters.shop" class="bg-gray-50 text-sm rounded-lg block w-full p-1.5 focus:outline-none border border-gray-300">
                            <option :value="null">-</option>
                            <option v-for="(shop, shopIndex) in shops" :key="'shop-' + shopIndex" :value="shop">{{ shop.cp + ' ' + shop.name }}</option>
                        </select>
                    </div>
                    <div class="border-y py-5 mt-5">
                        <div class="mb-4">
                            <input-rule v-model="filters.startDate" type="date" :label="$tt('Date du')" :display-errors="false" :label-inline="false" />
                        </div>
                        <div class="mb-4">
                            <input-rule v-model="filters.endDate" :default="filters.endDate" type="date" :label="$tt('Date au')" :display-errors="false" :label-inline="false" />
                        </div>
                    </div>
                    <toggle-card custom-class="py-5 px-0" id="importer" :title="advancedSearchTitle">
                        <div class="mt-5 border-t pt-2 flex items-center justify-between">
                            <label class="block text-xs font-bold">{{ $tt('Import WCA') }}</label>
                            <div class="flex items-center">
                                <div>
                                    <input ref="fileWcas" type="file" @change="getFoldersByWca" hidden>
                                    <button class="text-xs bg-red-100 hover:bg-red-200 duration-200 cursor-pointer p-2 px-4 rounded-lg mr-1" v-if="filters.wcas" @click="filters.wcas = null">
                                        <i class="fas fa-times text-red-800"></i>
                                    </button>
                                </div>
                                <button class="text-xs bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer p-2 px-4 rounded-lg" @click="openWcasFilter">
                                    <div v-if="filters.wcas">
                                        <i class="fas fa-pen mr-1"></i>
                                    </div>
                                    <div v-else>
                                        <i class="fas fa-plus-circle mr-1"></i> {{ $tt('Ajouter') }}
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div class="mt-2 pt-3 border-t">
                            <label class="block mb-1 text-xs font-bold">{{ $tt('Numéro WCA') }}</label>
                            <div class="flex items-center">
                                <div class="rounded-l-lg text-sm rounded-0 grow p-1.5 focus:outline-none focus:border-gray-400 bg-gray-200 border border-r-0 border-gray-300 text-gray-900 font-base">WCA-</div>
                                <input class="text-sm grow w-full p-1.5 focus:outline-none focus:border-gray-400 rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900" type="text" v-model="filters.wca" />
                            </div>
                        </div>
                    </toggle-card>
                    <div class="mt-5">
                        <button class="bg-green-200 hover:bg-green-300 duration-200 p-2 px-4 text-sm rounded-xl mb-1" @click="searchFolders">
                            <i class="fas fa-search mr-0.5"></i> {{ $tt('Rechercher') }}
                        </button>
                    </div>
                </div>
            </template>


            <!-- Avoir -->
            <div v-if="loadingFolders" class="flex justify-center text-center bg-white p-10 rounded shadow">
                <div>
                    <spinner class="flex justify-center" :size="15" />
                    <div class="mt-5 font-light">{{ $tt('Recherche en cours') }}..</div>
                </div>
            </div>
            <distriboard-avoir-sheet
            v-if="displayAvoirSheet"
            :account="account"
            :selected-sign="frozen.selectedSign"
            :folders="frozen.folders"
            :shop="frozen.typeAvoir == 'magasin' ? frozen.shop : null"
            />
            <div v-else-if="!displayAvoirSheet && !loadingFolders" class="text-center bg-white p-10 rounded shadow">
                <h2 class="text-xl font-bold">Distriboard</h2>
                <p class="text-sm font-light mt-1">{{ $tt('Utiliser les filtres ci-contre pour effectuer une recherche') }}</p>
            </div>

        </app-template>
    </div>
</template>

<script>
import apiService from '@/services/api.service'
import moment from 'moment'

import DistriboardAvoirSheet from './Components/DistriboardAvoirSheet.vue'

export default {
    components: {
        DistriboardAvoirSheet,
    },

    watch: {
        selectedSign: function() {
            this.selectedSignId = this.selectedSign.id
            this.filters.shop = null
            this.getShops()
        },
        typeAvoir: function() {
            this.filters.shop = null
        }
    },

    data() {
        return {
            account: null,
            filters: {
                startDate: null,
                endDate: null,
                shop: null,
                wcas: null,
                wca: null
            },
            frozen: {
                selectedSign: null,
                folders: null,
                shop: null,
            },
            waitingSigns: [],
            signs: [],
            selectedSign: null,
            selectedSignId: null,
            shops: null,
            folders: [],
            typeAvoir: 'centrale',

            displayAvoirSheet: false,

            // typeTable: 'accords',
            // avoirs: null,

            hasSearch: false,
            loadingFolders: false,
        }
    },

    computed: {
        signsWithoutWaitingFolders() {
            if (this.signs) {
                return this.signs.filter((sign) => !this.waitingSigns.find((waitingSign) => waitingSign.id == sign.id))
            }
            return []
        },

        advancedSearchTitle() {
            let endTitle = "<i class='fas fa-plus mr-1'></i> " + this.$tt('Recherche avancée')
            if (this.filters.wcas) {
                endTitle += "<span class='bg-blue-100 text-blue-800 text-xs font-medium ml-1 px-2.5 py-0.5 rounded-full'>1 " + this.$tt('filtre') 
            }
            endTitle += '</span>'
            return endTitle
        }
    },

    methods: {
        async refreshAll() {
            this.initNewSpace()
            await this.getFolders()
            await this.getShops()
        },

        initNewSpace(refreshAll = true) {
            this.folders = []
            this.addedFolders = []
            this.editAmount = false
            this.avoirs = null
            this.typeTable = 'accords'
            if (refreshAll) {
                this.filters.wcas = null
                this.shops = []
                this.filters.shop = null
            }
        },

        async getSigns() {
            let response = await apiService.post('getlistcentrale', {}, 'districloud')
            this.signs = response.data.posts.post
        },

        async getWaitingSigns() {
            this.avoirs = []
            let response = await apiService.post('getlistcentraleattavoir', {
                id_centrale_ha: this.selectedSignId,
            }, 'districloud')
            this.waitingSigns = response.data.posts.post
        },

        async getShops() {
            let response = await apiService.post('getmabycentrale', {
                id_centrale_ha: this.selectedSignId
            }, 'districloud')
            this.shops = response.data.posts.post
        },

        async getFolders() {
            if (!this.selectedSignId) {
                this.$toast.error("Vous devez sélectionner une centrale d'achat")
                return
            }

            if (this.typeAvoir == 'magasin' && (!this.filters.shop || !this.filters.shop.id)) {
                this.$toast.error("Vous devez sélectionner un magasin")
                return
            }

            if ((!this.filters.startDate || !this.filters.endDate) && !this.filters.wcas && !this.filters.wca) {
                this.$toast.error("Vous devez sélectionner une date ou ajouter un import")
                return
            }
            this.initNewSpace(false)
            this.folders = []
            this.loadingFolders = true

            // Apply filters
            let endObj = {
                id_centrale_ha: this.selectedSignId,
            }

            if (this.filters.startDate) {
                endObj.date_deb = moment(this.filters.startDate).format('YYYY-MM-DD')
            }

            if (this.filters.endDate) {
                endObj.date_fin = moment(this.filters.endDate).format('YYYY-MM-DD')
            }
            
            if (this.filters.wcas) {
                endObj.import = this.filters.wcas
            }

            if (this.filters.shop && this.filters.shop.id) {
                endObj.id_magasin = this.filters.shop.id
            }

            if (this.filters.wca) {
                endObj.num_dsc = 'WCA-' + this.filters.wca
            }

            try {
                this.displayAvoirSheet = false
                const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})
                this.hasSearch = true
                this.defaultFoldersList = []

                let response = await apiService.post('A5141getdscwithnotecred', endObj, 'districloud_action')
    
                if (response.data.posts.post) {
                    let lines = response.data.posts.post.map((folder) => ({...folder, added: false, editAmount: false}))
                    lines = lines.sort((a,b)=> (a['Modele'] > b['Modele'] ? 1 : -1))
                    lines = groupByKey(lines, 'num_dsc')
                    for (const [key, value] of Object.entries(lines)) { // eslint-disable-line
                        this.defaultFoldersList.push(value[0])
                    }
                    this.folders = [...this.defaultFoldersList]
                }
                this.loadingFolders = false
                // Frozen data
                this.frozen = {
                    selectedSign: this.selectedSign,
                    folders: this.folders,
                    shop: this.filters.shop,
                    typeAvoir: this.typeAvoir
                }
                this.$nextTick(() => {
                    this.displayAvoirSheet = true
                })
            } catch (error) {
                console.log(error);
                this.$toast.error('Une erreur est survenue lors de la récupération des dossiers')
                this.loadingFolders = false
            }
        },

        getFoldersByWca(e) {
            try {
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.onload = e => {
                    let lines = e.target.result
                    lines = lines.replaceAll(';', '')
                    lines = lines.replaceAll('\r', '')
                    lines = lines.split('\n')
                    if (lines.length <= 0) {
                        this.$toast.error('Fichier incorrect')
                    } else {
                        this.filters.wcas = lines.join(';')
                    }
                }
                reader.readAsText(file);
            } catch (error) {
                this.$toast.error("Erreur lors de l'importation du fichier")
            }
        },

        openWcasFilter(){
            let fileInputElement = this.$refs.fileWcas;
            fileInputElement.click();
        },

        async searchFolders() {
            this.editAmount = false
            this.addedFolders = []
            await this.getFolders()
        },
    },

    mounted() {
        this.getWaitingSigns()
        this.getSigns()
        this.account = this.$request.user().account
        this.filters.startDate = moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD')
        this.filters.endDate = moment().format('YYYY-MM-DD')
    }
}
</script>

<style>

</style>
