var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-template',[_c('div',{staticClass:"bg-white rounded border shadow-sm p-4"},[_c('div',{staticClass:"grid grid-cols-8 gap-2"},[_c('div',{staticClass:"col-span-2"},[_c('input-rule',{attrs:{"type":"select","options":{values: [{label: 'Fournisseur', value: 1}, {label: 'Distributeur', value: 2}], label: 'label', key: 'value'},"label":"Type","label-inline":false},model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}})],1),_c('div',{staticClass:"col-span-6"},[_c('input-rule',{staticClass:"mb-4",attrs:{"id":"select-grossiste","label-inline":false,"label":_vm.$tt('Pour quel société ?'),"type":"api-select","options":{
                        api: {action: (_vm.filters.type == 1 ? 'inscription.search-grossiste' : 'inscription.search-centrale'), apiName: 'api2'},
                        label: (_vm.filters.type == 1 ? 'nom' : 'centrale_ha'),
                        init: false,
                        min: 3,
                        uppercase: true
                    }},model:{value:(_vm.filters.company),callback:function ($$v) {_vm.$set(_vm.filters, "company", $$v)},expression:"filters.company"}})],1)]),(_vm.filters.type && _vm.filters.company)?_c('div',{staticClass:"mt-5 pt-5 border-t"},[_c('admin-client-liaisons-table',{attrs:{"type":_vm.filters.type == 1 ? 'fournisseur' : 'distributeur',"connected":1,"entite-id":_vm.filters.company.id}})],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }