<template>
    <div>
        <app-template
        page-name="Distriboard"
        >
            <!-- App -->
            <div class="bg-white p-7 rounded-xl border shadow-sm mb-16">
                <div class="mb-2">
                    <label class="block mb-1 text-xs font-bold">Centrale d'achat / Magasin *</label>
                    <select v-model="selectedSignId" class="bg-gray-50 text-sm rounded-lg block w-full p-1.5 focus:outline-none border border-gray-300">
                        <option v-for="(sign, signIndex) in signs" :key="'sign-' + signIndex" :value="sign.id">{{ sign.centrale_ha }}</option>
                    </select>
                </div>

                <distriboard-avoir-table
                v-if="avoirs && displayTable"
                class="mt-5"
                :selected-sign="selectedSignId"
                />
            </div>
        </app-template>
    </div>
</template>

<script>
import DistriboardAvoirTable from './Components/DistriboardAvoirTable.vue'
import apiService from '@/services/api.service'

export default {
    components: {
        DistriboardAvoirTable,
    },
    watch: {
        selectedSignId: {
            deep: true,
            handler() {
                this.getAvoirs()
            }
        }
    },
    data() {
        return {
            signs: [],
            selectedSignId: null,
            avoirs: null,

            displayTable: false,
            loadingSearch: false,
        }
    },
    methods: {
        async getAvoirs() {
            if (!this.selectedSignId) {
                this.$toast.error("Vous devez sélectionner une centrale d'achat")
                return
            }
            this.avoirs = []
            this.loadingSearch = true
            this.displayTable = false
            let response = await apiService.post('getlistcentraleattavoir', {
                id_centrale_ha: this.selectedSignId,
            }, 'districloud')
            this.loadingSearch = false
            this.avoirs = response.data.posts.post
            this.$nextTick(() => {
                this.displayTable = true
            })
        },

        async getSigns() {
            let response = await apiService.post('getlistcentrale', {}, 'districloud')
            this.signs = response.data.posts.post;
        },
    },
    mounted () {
        this.getSigns();
    },
}
</script>
