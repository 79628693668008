var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal',{attrs:{"name":"modal-view-magasin","height":"auto","width":"80%","scrollable":true}},[(_vm.selectedMagasin)?_c('div',{staticClass:"p-5",staticStyle:{"z-index":"999 !important"}},[_c('div',{staticClass:"text-sm font-medium text-center text-gray-500 border-b border-gray-200"},[_c('ul',{staticClass:"flex flex-wrap -mb-px"},[_c('li',{staticClass:"mr-2",on:{"click":function($event){_vm.selectedModalTab = 'magasin'}}},[_c('span',{staticClass:"inline-block p-4 rounded-t-lg border-b-2 cursor-pointer",class:_vm.selectedModalTab == 'magasin' ? 'text-blue-600 border-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'},[_vm._v("Magasin")])]),_c('li',{staticClass:"mr-2",on:{"click":function($event){_vm.selectedModalTab = 'dossiers'}}},[_c('span',{staticClass:"inline-block p-4 rounded-t-lg border-b-2 cursor-pointer",class:_vm.selectedModalTab == 'dossiers' ? 'text-blue-600 border-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'},[_vm._v("Dossiers")])])])]),_c('div',{staticClass:"mt-4"},[(_vm.selectedModalTab == 'magasin')?_c('magasin-view',{attrs:{"id":_vm.selectedMagasin.id,"edit":true},on:{"update":_vm.updatedMagasin}}):_vm._e(),(_vm.selectedModalTab == 'dossiers')?_c('dossier-list',{staticClass:"max-h-[300px] overflow-y-auto",attrs:{"mag-id":_vm.selectedMagasin.id,"display-callback":(folder) => {_vm.selectedFolder = folder; _vm.$modal.show('modal-view-folder')},"edit":true},on:{"update":_vm.updatedMagasin}}):_vm._e()],1)]):_vm._e()]),_c('modal',{attrs:{"name":"modal-view-folder","height":"auto","width":"90%","scrollable":true}},[(_vm.selectedFolder)?_c('div',{staticClass:"p-10",staticStyle:{"z-index":"999 !important"}},[_c('dossier-view',{attrs:{"id":_vm.selectedFolder.id,"edit":true}})],1):_vm._e()]),_c('div',{staticClass:"bg-white p-4 rounded border border-slate-100"},[_c('data-table',{attrs:{"filters-inline":false,"filters":[
            {key: 'magasin', label: 'Magasin', type: 'text', value: null},
            {key: 'cp', label: 'Code postal', type: 'text', value: null},
            {key: 'ville', label: 'Ville', type: 'text', value: null},
        ],"columns":[
            {label: 'Magasin', key: 'magasin', type: 'text'},
            {label: 'Ville', key: 'ville', type: 'text'},
            {label: 'Code postal', key: 'cp', type: 'text'},
            {label: 'Erreur', slot: 'error'},
        ],"lines":_vm.magasins},scopedSlots:_vm._u([{key:"error",fn:function({line}){return [(line.error)?_c('div',{staticClass:"bg-orange-400 text-xs font-semibold rounded px-2 py-0.5"},[_vm._v(_vm._s(line.error))]):_vm._e()]}},{key:"actions",fn:function({line}){return [_c('button',{staticClass:"mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white",attrs:{"type":"submit"},on:{"click":function($event){return _vm.viewMagasin(line)}}},[_c('i',{staticClass:"fas fa-eye"})])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }