var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-template',[_c('div',{staticClass:"bg-white p-10 rounded shadow"},[_c('data-table',{attrs:{"without-action":true,"server":true,"filters":[
                {label: 'Ref dossier', key: 'ref_dossier', type: 'text'},
            ],"columns":[
                {label: 'Date', key: 'created_at', type: 'datetime'},
                {label: 'Ref dossier', key: 'ref_dossier', type: 'text'},
                {label: 'Etat', key: 'state_label', slot: 'state_label'},
                {label: 'Station', key: 'station_name', type: 'text'},
                {label: 'Montant remb', key: 'eco_amount', type: 'euro'},
                {label: 'Facture', key: 'uploaded_invoice', type: 'file'},
                {label: 'Accepté le', key: 'accept_date', type: 'date'},
                {label: 'Payé le', key: 'pay_date', type: 'date'},
                {label: 'Rejeté le', key: 'refused_date', type: 'date'},
                {label: 'Motif rejet', key: 'reject_reason', type: 'text'},
            ],"request":{
                name: 'admin.ecosystem.list',
                params: {
                    page: 0,
                    per_page: 50,
                },
                apiName: 'api2'
            }},scopedSlots:_vm._u([{key:"state_label",fn:function({line}){return [(line.state == 1)?_c('span',{staticClass:"bg-orange-400 px-2 rounded text-white text-xs font-light py-0.5"},[_vm._v(" "+_vm._s(line.state_label)+" ")]):_vm._e(),(line.state == 2)?_c('span',{staticClass:"bg-slate-300 px-2 rounded text-white text-xs font-light py-0.5"},[_vm._v(" "+_vm._s(line.state_label)+" ")]):_vm._e(),(line.state == 3 || line.state == 4)?_c('span',{staticClass:"bg-green-500 px-2 rounded text-white text-xs font-light py-0.5"},[_vm._v(" "+_vm._s(line.state_label)+" ")]):_vm._e(),(line.state == 5)?_c('span',{staticClass:"bg-red-500 px-2 rounded text-white text-xs font-light py-0.5"},[_vm._v(" "+_vm._s(line.state_label)+" ")]):_vm._e()]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }