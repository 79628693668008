<template>
    <div>
        <modal name="modal-view-piece" height="auto" width="70%" :scrollable="true">
            <div class="flex items-center justify-between bg-blue-100 px-4 py-1">
                <div class="text-xs font-light"><i class="fas fa-eye mr-1"></i> {{ $tt('Consulter une pièce') }}</div>
                <button @click="$modal.hide('modal-view-piece')">
                    <i class="fas fa-times text-red-500 text-xl"></i>
                </button>
            </div>
            <div class="p-4 overflow-x-auto min-h-[500px]" v-if="selectedPiece">
                <div class="border-t-2 pt-4 mt-4">
                    <div class="mb-2 flex items-center justify-between">
                        <div class="font-bold">{{ $tt('Pièces similaires') }}</div>
                        <input-rule
                        id="inp-search-similar"
                        :label-inline="false"
                        placeholder="Ajouter une pièce"
                        type="api-select"
                        @input="(data) => addSimilarPiece(data)"
                        :options="{
                            api: {action: 'reparateur.search-piece-ref', apiName: 'api2'},
                            label: 'design_ref',
                            label2: 'fournisseur_nom',
                            init: false,
                            min: 1,
                            uppercase: true,
                        }"
                        />
                    </div>
                    <div class="relative overflow-x-auto border rounded">
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-4 py-2 border-b">{{$tt('Référence')}}</th>
                                    <th scope="col" class="px-4 py-2 border-b">{{$tt('Désignation')}}</th>
                                    <th scope="col" class="px-4 py-2 border-b">{{$tt('Fournisseur')}}</th>
                                    <th scope="col" class="px-4 py-2 border-b">{{$tt('Actions')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(piece, pieceIndex) in selectedPiece.catalogue.similaires" :key="'piece-similaire-' + pieceIndex" class="hover:bg-slate-100 duration-200">
                                    <td class="px-4 py-1 border-b border-r">{{ piece.ref }}</td>
                                    <td class="px-4 py-1 border-b border-r">{{ piece.design }}</td>
                                    <td class="px-4 py-1 border-b border-r">{{ piece.fournisseur.nom }}</td>
                                    <td class="px-4 py-1 border-b border-r">
                                        <div class="flex items-center gap-2">
                                            <button @click="removeSimilarPiece(piece)" class="hover:text-red-600 duration-200"><i class="fas fa-trash"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </modal>
        <app-template>
            <div class="grid grid-cols-3 gap-4">
                <div>
                    <div class="bg-white p-4 rounded border shadow-sm mb-16">
                        <h2 class="text-xl font-bold mb-2"><i class="fas fa-plus mr-1"></i> {{ $tt('Nouvelle pièce') }}</h2>
                        <div class="grid grid-cols-2 gap-2 mb-2">
                            <input-rule v-model="formNewPiece.ref" type="text" :label-inline="false" class="mb-1" :label="$tt('Référence')" />
                            <input-rule v-model="formNewPiece.version" type="text" :label-inline="false" class="mb-1" :label="$tt('Version')" />
                        </div>
                        <div class="grid grid-cols-2 gap-2 mb-2">
                            <input-rule v-model="formNewPiece.designation" type="text" :label-inline="false" class="mb-1" :label="$tt('Désignation')" />
                            <input-rule v-model="formNewPiece.id_fournisseur" type="vselect" :options="{values: fournisseurs, label: 'nom', key: 'id'}" :label-inline="false" class="mb-1" :label="$tt('Fournisseur')" />
                        </div>
                        <!-- <div class="mb-2">
                            <label class="font-bold text-xs mb-0.5">{{ $tt('Dernier achat') }}</label>
                            <div class="font-light text-xs">13/09/2026 - 15h57</div>
                        </div> -->
                        <div class="grid grid-cols-2 gap-2 mb-2">
                            <input-rule v-model="formNewPiece.prix_base_ht" type="price" :label-inline="false" class="mb-1" :label="$tt('Prix Base HT')" />
                            <input-rule v-model="formNewPiece.prix_vente_force" type="price" :label-inline="false" class="mb-1" :label="$tt('Prix Forcé HT')" />
                        </div>
                        <input-rule v-model="formNewPiece.aa" type="price" :label-inline="false" class="mb-2" :label="$tt('Prix net HT')" />
                        <input-rule v-model="formNewPiece.emplacement" type="text" :label-inline="false" class="mb-2" :label="$tt('Emplacement')" />
                        <div class="grid grid-cols-2 gap-2 mb-2">
                            <input-rule v-model="formNewPiece.qtt" type="text" :label-inline="false" class="mb-1" :label="$tt('Quantité stock')" />
                            <input-rule v-model="formNewPiece.qtt_reserve" type="text" :label-inline="false" class="mb-1" :label="$tt('Quantité réservée')" />
                        </div>
                        <div class="mb-2">
                            <input-rule v-model="formNewPiece.occasion" type="pcheck" :label-inline="false" class="mb-1" :label="$tt('Occasion')" />
                        </div>
                        <button @click="submitNewPiece" :disabled="loadingAddPiece" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded text-sm mt-2">
                            <spinner :size="10" v-if="loadingAddPiece" />
                            <template v-if="!loadingAddPiece">
                                <i class="fas fa-plus mr-1"></i> {{ $tt('Enregistrer') }}
                            </template>
                        </button>
                    </div>
                </div>
                <div class="col-span-2">
                    <div class="bg-white p-4 rounded border shadow-sm mb-16">
                        <h2 class="text-xl font-bold"><i class="fas fa-list mr-2"></i> {{ $tt('Mes pièces') }}</h2>
                        <div class="relative overflow-x-auto mt-4 border rounded">
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                                <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-4 py-2 border-b">{{$tt('Référence')}}</th>
                                        <th scope="col" class="px-4 py-2 border-b">{{$tt('Version')}}</th>
                                        <th scope="col" class="px-4 py-2 border-b">{{$tt('Désignation')}}</th>
                                        <th scope="col" class="px-4 py-2 border-b">{{$tt('Stockeur')}}</th>
                                        <th scope="col" class="px-4 py-2 border-b">{{$tt('Quantité')}}</th>
                                        <th scope="col" class="px-4 py-2 border-b">{{$tt('Reservée')}}</th>
                                        <th scope="col" class="px-4 py-2 border-b">{{$tt('Actions')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(piece, pieceIndex) in pieces" :key="'piece-stock-' + pieceIndex" class="hover:bg-slate-100 duration-200">
                                        <td class="px-4 py-1 border-b border-r">{{ piece.ref }}</td>
                                        <td class="px-4 py-1 border-b border-r">{{ piece.version }}</td>
                                        <td class="px-4 py-1 border-b border-r">{{ piece.designation }}</td>
                                        <td class="px-4 py-1 border-b border-r">{{ piece.emplacement }}</td>
                                        <td class="px-4 py-1 border-b border-r">{{ piece.qtt }}</td>
                                        <td class="px-4 py-1 border-b border-r">{{ piece.qtt_reserve }}</td>
                                        <td class="px-4 py-1 border-b border-r">
                                            <div class="flex items-center gap-2">
                                                <button class="" @click="selectedPiece = piece; $modal.show('modal-view-piece')"><i class="fas fa-pen"></i></button>
                                                <button class=""><i class="fas fa-print"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pieces: [],

            formNewPiece: {},
            loadingAddPiece: false,

            selectedPiece: null,

            fournisseurs: []
        }
    },
    methods: {
        async getFournisseurs() {
            let response = await this.$request.post('reparateur.get-fournisseurs')
            this.fournisseurs = response.data.posts.post
        },

        async getPieces(line_id = null) {
            let endData = {}
            if (line_id) {
                endData.line_id = line_id
            }
            let response = await this.$request.post('reparateur.get-stock-pieces', endData)
            if (!line_id) {
                this.pieces = response.data.posts.post
            } else {
                return response.data.posts.post[0]
            }
        },
        
        async submitNewPiece() {
            this.loadingAddPiece = true
            let response = await this.$request.post('reparateur.edit-piece-stock', this.formNewPiece)
            this.loadingAddPiece = false
            if (response.data.posts.post == 1) {
                this.getPieces()
                this.formNewPiece = {}
            }
        },

        async addSimilarPiece(piece) {
            if (piece) {
                this.$eventHub.$emit('clear-inp-search-similar')
                await this.$request.post('reparateur.add-similar-piece', {
                    id_current: this.selectedPiece.id,
                    id_similar: piece.id
                })
                let newPiece = await this.getPieces(this.selectedPiece.id)
                this.selectedPiece = newPiece
            }
        },

        async removeSimilarPiece(piece) {
            await this.$request.post('reparateur.remove-similar-piece', {
                id_piece_ref: piece.pivot.id_piece_ref,
                id_piece_similaire: piece.pivot.id_piece_similaire,
            })
            let newPiece = await this.getPieces(this.selectedPiece.id)
            this.selectedPiece = newPiece
        }
    },
    mounted () {
        this.getPieces()
        this.getFournisseurs()
    },
}
</script>

<style lang="scss" scoped>

</style>