<template>
    <div>
        <app-template>
            <h1 class="text-3xl pb-4 my-2">{{$tt('Catalogue des')}} {{ type == 'fournisseur' ? $tt('distributeurs') : $tt('fournisseurs') }}</h1>
            <div class="bg-white rounded-xl border shadow-sm p-4 mb-8">
                <welcome-connections />
            </div>
        </app-template>
    </div>
</template>

<script>
import WelcomeConnections from './Welcome/WelcomeConnections.vue';

export default {
    components: {
        WelcomeConnections
    },
    computed: {
        type() {
            let typeEntite = this.$request.user().account.type_entite
            if (typeEntite == 1) {
                return 'fournisseur'
            }
            if (typeEntite == 2 || typeEntite == 3) {
                return 'distributeurs'
            }
            return null 
        },
    },
}
</script>

<style lang="scss" scoped>

</style>