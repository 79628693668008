var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-template',[_c('div',{staticClass:"bg-white p-10 rounded shadow"},[_c('data-table',{attrs:{"server":true,"filters":[
            ],"columns":[
                {label: 'Date', key: 'created_at', type: 'datetime'},
                {label: 'Type', key: 'type', type: 'text'},
                {label: 'Opération', key: 'operation', type: 'text'},
                {label: 'Erreur', key: 'error', type: 'text'},
            ],"request":{
                name: 'error.list',
                params: {
                    page: 0,
                    per_page: 50,
                },
                apiName: 'api2'
            }},scopedSlots:_vm._u([{key:"actions",fn:function({line}){return [_c('div',{staticClass:"flex items-center"},[_c('button',{staticClass:"flex items-center mr-1 text-xs bg-slate-200 hover:bg-slate-300 duration-200 cursor-pointer font-light px-3 py-1.5 rounded",on:{"click":function($event){_vm.selectedLine = line; _vm.$modal.show('modal-confirm-resend')}}},[_c('i',{staticClass:"mr-1 fa-solid fa-rotate-right"}),_vm._v(" Renvoyer ")]),_c('button',{staticClass:"mr-1 text-xs bg-orange-500 hover:bg-orange-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white",on:{"click":function($event){_vm.selectedLine = line; _vm.$modal.show('modal-display-mail')}}},[_c('i',{staticClass:"fas fa-eye"})])])]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }