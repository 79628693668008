<template>
    <div>
        <app-template>
            <div class="bg-white rounded-xl border shadow-sm p-4">
                <!-- <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mb-6">
                    <ul class="flex flex-wrap -mb-px">
                        <li @click="selectedTab = 'options'" class="mr-2">
                            <span class="cursor-pointer inline-block p-4 border-b-2 border-transparent rounded-t-lg" :class="selectedTab == 'options' ? 'text-blue-500 font-bold' : 'font-light hover:text-gray-600 hover:border-gray-300'">
                                <i class="fa-solid fa-puzzle-piece mr-1"></i> Modules
                            </span>
                        </li>
                        <li @click="selectedTab = 'simulation'" class="mr-2">
                            <span aria-current="page" class="cursor-pointer inline-block p-4 border-b-2 border-transparent rounded-t-lg" :class="selectedTab == 'simulation' ? 'text-blue-500 font-bold' : 'font-light hover:text-gray-600 hover:border-gray-300'">
                                <i class="fa-solid fa-chart-line mr-1"></i> Période en cours
                            </span>
                        </li>
                        <li @click="selectedTab = 'history'" class="mr-2">
                            <span class="cursor-pointer inline-block p-4 border-b-2 border-transparent rounded-t-lg" :class="selectedTab == 'history' ? 'text-blue-500 font-bold' : 'font-light hover:text-gray-600 hover:border-gray-300'">
                                <i class="fa-solid fa-file-lines mr-1"></i> Historique des paiements
                            </span>
                        </li>
                    </ul>
                </div> -->

                <admin-client-price-options v-if="selectedTab == 'options'" />
                <admin-client-price-simulation v-if="selectedTab == 'simulation'" />
                <admin-client-price-history v-if="selectedTab == 'history'" />
            </div>
        </app-template>
    </div>
</template> 

<script>
import AdminClientPriceSimulation from './Components/AdminClientPriceSimulation'
import AdminClientPriceOptions from './Components/AdminClientPriceOptions'
import AdminClientPriceHistory from './Components/AdminClientPriceHistory'

export default {
    components: {
        AdminClientPriceSimulation,
        AdminClientPriceOptions,
        AdminClientPriceHistory
    },
    data() {
        return {
            selectedTab: 'options'
        }
    },
}
</script>

<style lang="scss" scoped>

</style>