<template>
    <div>
        <modal name="modal-edit-options" height="auto" :scrollable="true" width="70%">
            <div v-if="selectedLine && modalContentKey" class="p-10" style="z-index: 999 !important;">
                <div class="text-right mb-4 border-b pb-4 flex items-center justify-between">
                    <h2 class="font-bold text-lg">{{ selectedLine.nom }}</h2>
                    <button @click="$modal.hide('modal-edit-options')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>

                <!-- <div class="text-sm font-medium text-center mb-5 bg-slate-100 rounded p-3">
                    <ul class="flex flex-wrap -mb-px">
                        <li class="mr-2">
                            <div @click="selectedTab = 'client'" class="inline-block text-sm px-3 py-2 rounded-lg" :class="selectedTab == 'client' ? 'bg-blue-600 text-white' : 'hover:text-gray-900 hover:bg-slate-200 duration-200 cursor-pointer'">
                                <i class="fas fa-user mr-1"></i> Fiche client
                            </div>
                        </li>
                        <li class="mr-2">
                            <div @click="selectedTab = 'connexions'" class="inline-block text-sm px-3 py-2 rounded-lg" :class="selectedTab == 'connexions' ? 'bg-blue-600 text-white' : 'hover:text-gray-900 hover:bg-slate-200 duration-200 cursor-pointer'">
                                <i class="fas fa-users mr-1"></i> Connexions
                            </div>
                        </li>
                        <li class="mr-2">
                            <div @click="selectedTab = 'options'" class="inline-block text-sm px-3 py-2 rounded-lg" :class="selectedTab == 'options' ? 'bg-blue-600 text-white' : 'hover:text-gray-900 hover:bg-slate-200 duration-200 cursor-pointer'">
                                <i class="fas fa-plug mr-1"></i> Options <span v-if="nbOptions > 0" class="inline-flex items-center justify-center w-4 h-4 mx-0.5 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">{{ nbOptions }}</span>
                            </div>
                        </li>
                        <li class="mr-2">
                            <div @click="selectedTab = 'remise_generale'" class="inline-block text-sm px-3 py-2 rounded-lg" :class="selectedTab == 'remise_generale' ? 'bg-blue-600 text-white' : 'hover:text-gray-900 hover:bg-slate-200 duration-200 cursor-pointer'">
                                <i class="fas fa-percent mr-1"></i> Remise générale <span v-if="getRemiseGenerale" class="inline-flex items-center justify-center px-1 h-4 mx-0.5 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">{{ getRemiseGenerale.remise + '%' }}</span>
                            </div>
                        </li>
                        <li class="mr-2">
                            <div @click="selectedTab = 'history'" class="inline-block text-sm px-3 py-2 rounded-lg" :class="selectedTab == 'history' ? 'bg-blue-600 text-white' : 'hover:text-gray-900 hover:bg-slate-200 duration-200 cursor-pointer'">
                                <i class="fas fa-euro mr-1"></i> Historique
                            </div>
                        </li>
                    </ul>
                </div> -->
                <template v-if="modalContentKey == 'client'">
                    <input-rule type="pcheck" v-model="formClient.is_premium" label="Accès aux fonctionnalitées premium" :label-inline="false" class="mb-4" />
                    <input-rule type="text" v-model="formClient.nom" label="Nom" :label-inline="false" class="mb-4" />
                    <input-rule type="datetime" v-model="formClient.date_licence" label="Date licence" :label-inline="false" class="mb-4" />
                    <input-rule type="text" v-model="formClient.id_dolibarr" label="ID Dolibarr" :label-inline="false" class="mb-4" />
                    <input-rule type="select" v-model="formClient.periodicite" label="Périodicité de facturation" :label-inline="false" class="mb-4" :options="{values: [{label: 'Mensuel', value: 1}, {label: 'Trimestriel', value: 3}, {label: 'Annuel', value: 12}], key: 'value', label: 'label'}" />
                    <button @click="updateClient" class="px-4 py-2 text-sm rounded bg-blue-600 hover:bg-blue-700 duration-200 cursor-pointer text-white">Enregistrer <i class="fas fa-check ml-1"></i></button>
                </template>
                <template v-if="modalContentKey == 'connexions'">
                    <facturation-manage-connexions v-if="selectedLine" :client="selectedLine" />
                </template>
                <template v-if="modalContentKey == 'options'">
                    <admin-client-price-options :client-id="selectedLine.id" />
                </template>
                <template v-if="modalContentKey == 'facturation'">
                    <template v-if="selectedTab == 'history'">
                        <admin-client-price-history :client-id="selectedLine.id" />
                    </template>
                    <template v-if="selectedTab == 'remise_generale'">
                        <input-rule type="number" v-model="remiseGenerale" label="Pourcentage de la remise" :label-inline="false" />
                        <button @click="updateRemise" class="mt-4 px-4 py-2 text-sm rounded bg-blue-600 hover:bg-blue-700 duration-200 cursor-pointer text-white">Appliquer <i class="fas fa-check ml-1"></i></button>
                    </template>
                </template>
            </div>
        </modal>

        <app-template>
            <h1 class="text-2xl font-bold py-4 mb-2">Gestion des clients</h1>

            <div class="bg-white rounded-xl border shadow-sm p-4">
                <div class="p-4" v-if="loading">
                    <spinner :size="10" />
                </div>
                <template v-if="!loading">
                    <data-table
                    :filters-inline="false"
                    :filters="[
                        {key: 'nom', label: 'Nom', type: 'text', value: null},
                        {label: 'Entité', key: 'entite', type: 'list', options: [
                            {label: 'Marque', value: 'Marque'},
                            {label: 'Centrale achat', value: 'Centrale achat'},
                            {label: 'Station technique', value: 'Station technique'},
                        ]},
                    ]"
                    :columns="[
                        {label: 'Id', key: 'id', type: 'text'},
                        {label: 'Nom', key: 'nom', type: 'text'},
                        {label: 'Type entité', key: 'entite', type: 'text'},
                        {label: 'Fin licence le', key: 'date_licence', type: 'datetime'},
                        {label: 'Premium', key: 'is_premium', type: 'boolean'},
                    ]"
                    :lines="clients"
                    >
                        <template v-slot:actions='{line}'>
                            <div class="flex gap-2 items-center py-1">
                                <button @click="displayClientModal(line, 'client')" class="px-2 py-2 rounded bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer text-white text-xs"><i class="fas fa-user mr-0.5"></i> Client</button>
                                <button @click="displayClientModal(line, 'connexions')" class="px-2 py-2 rounded bg-emerald-500 hover:bg-emerald-600 duration-200 cursor-pointer text-white text-xs"><i class="fas fa-bezier-curve mr-0.5"></i> Connexions</button>
                                <button @click="displayClientModal(line, 'options')" class="px-2 py-2 rounded bg-orange-500 hover:bg-orange-600 duration-200 cursor-pointer text-white text-xs"><i class="fas fa-puzzle-piece mr-0.5"></i> Modules</button>
                                <button @click="displayClientModal(line, 'facturation')" class="px-2 py-2 rounded bg-purple-500 hover:bg-purple-600 duration-200 cursor-pointer text-white text-xs"><i class="fas fa-euro mr-0.5"></i> Facturation</button>
                            </div>
                        </template>
                    </data-table>
                    <!-- <div v-for="(client, clientIndex) in clients" :key="'client-' + clientIndex" class="border-b p-1 py flex items-center justify-between hover:bg-slate-100 duration-200">
                        <div class="flex items-center gap-5">
                            <div class="text-sm uppercase">{{ client.nom }}</div>
                            <span v-if="client.client_facture_options.filter((item) => item.option.keyword != 'remise_generale').length > 0" class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                                {{ client.client_facture_options.filter((item) => item.option.keyword != 'remise_generale').length }} {{ (client.client_facture_options.filter((item) => item.option.keyword != 'remise_generale').length > 1) ? 'options' : 'option' }}
                            </span>
                        </div>
                        <div class="flex gap-2">
                            <button @click="displayClientModal(client)" class="px-2 py-1.5 rounded bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer text-white text-sm"><i class="fas fa-pen"></i></button>
                        </div>
                    </div> -->
                </template>
            </div>
        </app-template>
    </div>
</template>

<script>
import AdminClientPriceOptions from './../../AdminClient/Components/AdminClientPriceOptions'
import AdminClientPriceHistory from './../../AdminClient/Components/AdminClientPriceHistory'
import FacturationManageConnexions from './FacturationManageConnexions.vue'

export default {
    components: {
        AdminClientPriceOptions,
        AdminClientPriceHistory,
        FacturationManageConnexions
    },
    data() {
        return {
            clients: null,
            options: null,
            selectedLine: null,
            selectedTab: 'client',
            formClient: {},
            remiseGenerale: 0,
            modalContentKey: null,

            loading: false,
            loadingModal: false
        }
    },
    computed: {
        nbOptions() {
            if (this.selectedLine) {
                return this.selectedLine.client_facture_options.filter((item) => item.option.keyword != 'remise_generale').length
            }
            return null
        },
        getRemiseGenerale() {
            if (this.selectedLine) {
                let hasRemise = this.selectedLine.client_facture_options.filter((item) => item.option.keyword == 'remise_generale')
                if (hasRemise) {
                    return hasRemise[0]
                }
            }
            return null
        }
    },
    methods: {
        async getClients() {
            this.loading = true
            let response = await this.$request.post('facturation.admin.client-list-options')
            this.clients = response.data.posts.post
            this.clients = this.clients.map(item => ({...item, entite: item.type_entite?.libelle}))
            console.log(this.clients);
            this.loading = false
        },
        getAmount(option) {
            if (option.type == 'connections') {
                return option.value + ' € par liaisons'
            }
            if (option.type == 'users') {
                return option.value + ' € par licences'
            }
            if (option.type == 'fixe') {
                return option.value + ' €'
            }
            if (option.type == 'pourcentage') {
                return option.value + '%'
            }
        },
        async addOption(option) {
            if (this.selectedLine) {
                this.$request.post('facturation.add-option', {
                    client_id: this.selectedLine.id,
                    option_id: option.id
                })
            }
        },
        displayClientModal(client, modalKeyContent) {
            let remiseGenerale = client.client_facture_options.find((option) => option.option.keyword == 'remise_generale')
            this.remiseGenerale = remiseGenerale ? remiseGenerale.remise : 0
            this.selectedLine = {...client}
            this.formClient = {...client}
            this.$modal.show('modal-edit-options')
            this.selectedTab = 'client'
            this.modalContentKey = modalKeyContent
        },
        async updateClient() {
            this.formClient.client_id = this.formClient.id
            delete this.formClient.id
            let response = await this.$request.post('facturaion.edit-add-client', this.formClient)
            let newClient = response.data.posts.post
            if (newClient.id) {
                let clientIndex = this.clients.findIndex((client) => client.id == newClient.id)
                if (clientIndex >= 0) {
                    this.clients[clientIndex] = newClient
                    this.$forceUpdate()
                }
                this.$toast.success((this.formClient.client_id ? 'Client modifié' : 'Client ajouté'))
            }
            if (!this.formClient.client_id) {
                this.getClients()
            }
        },
        async updateRemise() {
            if (this.remiseGenerale < 0) {
                this.$toast.error('Le pourcentage ne peut pas être inférieur à 0%')
                return
            }
            if (this.remiseGenerale > 100) {
                this.$toast.error('Le pourcentage ne peut pas dépasser 100%')
                return
            }

            let response = await this.$request.post('facturation.add-remise', {
                client_id: this.selectedLine.id,
                remise: this.remiseGenerale
            })
            if (response.data.posts.post == 1) {
                this.$toast.success('Remise appliquée')
            }
        },
        getOptionSelectedLine(keyword) {
            if (this.selectedLine) {
                this.selectedLine.client_facture_options.find((item) => {
                    if (item.option.keyword == keyword) {
                        return item
                    }
                })
            }
        }
     },
    async mounted () {
        await this.getClients()
        let urlString = window.location.href
        let paramString = urlString.split('?')[1]
        let queryString = new URLSearchParams(paramString)
        for (let pair of queryString.entries()) {
            if (pair[0] == 'client_id') {
                this.selectedLine = this.clients.find((item) => item.id == pair[1])
                this.$modal.show('modal-edit-options')
            }
            if (pair[0] == 'tab') {
                // this.selectedTab = pair[1]
                this.modalContentKey = pair[1]
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>