<template>
    <div>
        <modal name="modal-add-mail" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{ $tt("Ajouter une adresse email") }}</h2>
                    <button @click="$modal.hide('modal-add-mail')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <template v-if="!confirmEmailCode">
                        <input-rule type="text" v-model="newEmail" :label="$tt('Adresse email')" :label-inline="false" id="email-validation" />
                        <button :disabled="loadingCodeValidation" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 text-white rounded text-sm mt-4" @click="sendEmailConfirmation">
                            <spinner v-if="loadingCodeValidation" :size="10" />
                            <template v-if="!loadingCodeValidation">
                                {{$tt('Valider')}} <i class="fas fa-check"></i>
                            </template> 
                        </button>
                    </template>
                    <template v-if="confirmEmailCode">
                        <div class="my-4 p-4 rounded bg-slate-200 text-sm">
                            <div><i class="fas fa-check mr-1"></i>{{$tt('Email envoyé à')}} {{ newEmail }}</div>
                            <button @click="confirmEmailCode = false" class="px-4 py-2 text-xs font-light bg-slate-400 text-white mt-2 rounded"><i class="fas fa-pen mr-1"></i> Modifier l'adresse email</button>
                        </div>
                        <input-rule type="text" v-model="codeValidation" :label="$tt('Code de validation')" :label-inline="false" id="inp-code-validation" />
                        <button :disabled="loadingCodeValidation" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 text-white rounded text-sm mt-4" @click="confirmCode">
                            <spinner v-if="loadingCodeValidation" :size="10" />
                            <template v-if="!loadingCodeValidation">
                                {{ $tt('Valider') }} <i class="fas fa-check"></i>
                            </template> 
                        </button>
                    </template>
                </div>
            </div>
        </modal>

        <div class="py-4" v-if="loaderGetData">
            <div class="flex justify-center">
                <spinner :size="12" />
            </div>
            <div class="mt-5 text-sm font-extralight text-center">{{ $tt('Chargement des données..') }}</div>
        </div>
        <template v-if="!loaderGetData && model">
            <div class="grid lg:grid-cols-2 gap-2">
                <div>
                    <div class="mb-4 pb-4 border-2 rounded p-2">
                        <input-rule v-if="admin" v-model="model.actif" :display-value="!edit" type="pcheck" :label="$tt('Actif')" />
                        <div class="flex items-center">
                            <input-rule class="grow mb-2" v-model="model.nom" :display-value="!edit" type="text" :label="$tt('Nom du fournisseur')" id="inp-nom" />
                            <a v-if="admin" :href="googleLink" target="_blank" class="bg-purple-200 hover:bg-purple-300 cursor-pointer duration-200 text-xs p-1 px-2 rounded-full"><i class="fas fa-search"></i></a>
                        </div>
                        <input-rule v-model="model.type_societe" :display-value="!edit" type="text" :label="$tt('Type de société')" id="inp-type_societe" />
                    </div>
                    <div class="mb-4 pb-4 border-2 rounded p-2">
                        <input-rule v-model="form.adresse" :display-value="!edit" type="address2" :options="{defaultValue: model, country: model.code_pays}" :label="$tt('Adresse *')" id="inp-adresse" />
                    </div>
                    <div class="mb-4 pb-4 border-2 rounded p-2">
                        <input-rule v-model="model.numintra" :display-value="!edit" type="text" :label="$tt('Numéro TVA intra-communautaire')" class="mb-2" id="inp-numintra" />
                        <input-rule v-model="model.siret" :display-value="!edit" type="text" :label="$tt('SIRET')" id="inp-siret" />
                    </div>
                </div>
                <div>
                    <div class="mb-4 pb-4 border-2 rounded p-2">
                        <input-rule v-model="model.contact_princ" class="mb-2" :display-value="!edit" type="text" :label="$tt('Contact principal')" id="inp-contact-princ" />
                        <input-rule v-model="model.tel" class="mb-2" :display-value="!edit" type="tel" :label="$tt('Numéro de téléphone')" id="inp-tel" />
                        <template v-if="admin">
                            <input-rule v-model="model.mail" :display-value="!edit" type="text" :label="$tt('Email')" id="inp-mail" />
                        </template>
                        <template v-if="!admin">
                            <template v-if="!model.mail">
                                <label class="mb-1 text-xs font-bold text-gray-900 mr-4" :class="errorMail ? 'text-red-500' : ''">{{ $tt('Email') }}</label>
                                <button @click="$modal.show('modal-add-mail')" class="px-4 py-2 text-xs bg-slate-100 hover:bg-slate-200 duration-200 rounded"><i class="fas fa-plus mr-1"></i> {{$tt('Ajouter une adresse email')}}</button>
                            </template>
                            <div class="flex items-center gap-2" v-if="model.mail">
                                <input-rule class="grow" v-model="model.mail" :display-value="!edit" type="text" :label="$tt('Email')" id="inp-mail" :disabled="true" />
                                <button @click="$modal.show('modal-add-mail')" class="px-4 py-2 text-xs bg-slate-100 hover:bg-slate-200 duration-200 rounded"><i class="fas fa-pen"></i></button>
                            </div>
                            <ul v-if="errorMail" class="text-xs text-red-500 mt-2 font-light list-disc ml-4">
                                <li>{{ errorMail }}</li>
                            </ul>
                        </template>
                    </div>
                    <div class="mb-4 pb-4 border-2 rounded p-2">
                        <div class="flex items-center justify-between">
                            <input-rule v-model="model.logo" :display-value="!edit" type="file" :label="$tt('Logo')" />
                            <img v-if="model.logo" class="h-[45px]" :src="model.logo.url ? model.logo.url : model.logo" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="edit" class="text-center">
                <button :disabled="loaderEditAdd" class="px-4 py-2 rounded my-1 bg-green-200 hover:bg-green-300 duration-200 text-sm" @click="editAdd">
                    <template v-if="!loaderEditAdd">
                        <template v-if="btnLabel">
                            <span v-html="btnLabel"></span>
                        </template>
                        <template v-if="!btnLabel">
                            <template v-if="model.id">{{$tt('Modifier')}} <i class="fas fa-pen ml-1"></i></template>
                            <template v-if="!model.id">{{$tt('Enregistrer')}} <i class="fas fa-plus ml-1"></i></template>
                        </template>
                    </template>
                    <template v-else>
                        <spinner :size="10" />
                    </template>
                </button>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        id: {},
        edit: {
            type: Boolean,
        },
        dataSrc: {},
        admin: {default: true},
        btnLabel: {},
    },

    data() {
        return {
            model: null,
            loaderGetData: false,
            loaderEditAdd: false,
            
            form: {
                adresse: null,
            },

            newEmail: null,
            confirmEmailCode: false,
            codeValidation: null,
            loadingCodeValidation: false,
            errorMail: null,
        }
    },

    computed: {
        googleLink() {
            return 'https://www.google.com/search?q=' + (this.model.nom.replace(' ', '+')) + '+societe.com'
        }
    },

    methods: {
        async getData(id = null) {
            this.loaderGetData = true
            let response = await this.$request.post('grossiste.view', {
                line_id: id || this.id
            })
            if (response.data.posts.post) {
                this.model = response.data.posts.post
                this.model.pays = this.model.code_pays
            }
            this.loaderGetData = false
        },

        async editAdd() {
            if (!this.admin) {
                this.errorMail = null
                this.$eventHub.$on('validation-input-error', (error) => {
                    if (error.id && error.id == 'inp-mail') {
                        this.errorMail = this.$tt('Adresse email obligatoire')
                    }
                })
                if (!this.$validation.check([
                    {value: this.model.nom, rules: 'required', id: 'inp-nom'},
                    {value: this.form.adresse, rules: 'required', id: 'inp-adresse'},
                    {value: this.model.tel, rules: 'required', id: 'inp-tel'},
                    {value: this.model.mail, rules: 'required|email', id: 'inp-mail'},
                    {value: this.model.contact_princ, rules: 'required', id: 'inp-contact-princ'},
                ])) {return}
            } else {
                if (!this.$validation.check([
                    {value: this.model.nom, rules: 'required', id: 'inp-nom'},
                    {value: this.form.adresse, rules: 'required', id: 'inp-adresse'},
                ])) {return}
            }

            this.loaderEditAdd = true
            let endObj = {...this.model}
            if (endObj.id) {
                endObj.line_id = endObj.id
                delete endObj.id
            }
            if (endObj.logo && endObj.logo.url) {
                endObj.logo = endObj.logo.url
            }
            // Set address
            endObj.adresse1 = this.form.adresse.adresse1
            endObj.adresse2 = this.form.adresse.adresse2
            endObj.cp = this.form.adresse.cp
            endObj.ville = this.form.adresse.ville
            endObj.pays = this.form.adresse.pays
            endObj.code_pays = this.form.adresse.code_pays || this.form.adresse.pays
            endObj.longitude = this.form.adresse.longitude
            endObj.latitude = this.form.adresse.latitude

            for (let key in endObj) {
                if (endObj[key] === null || endObj[key] === undefined) {
                    delete endObj[key]
                }
            }
            let response = await this.$request.post('grossiste.edit-add', endObj)
            if (response.data.posts.post) {
                this.$emit('update', response.data.posts.post)
                this.getData(response.data.posts.post.id)
                this.$toast.success((endObj.id ? this.$tt('Société modifiée') : this.$tt('Société enregistrée')), {position: "bottom-right"})
                this.$emit('submit', response.data.posts.post)
            } else {
                this.$toast.error(this.$tt("Erreur lors de l'enregistrement"))
            }
            this.loaderEditAdd = false
        },

        async sendEmailConfirmation() {
            if (!this.$validation.check([
                {value: this.newEmail, rules: 'required|email', id: 'email-validation'},
            ])) {return}

            this.loadingCodeValidation = true
            let response = await this.$request.post('grossiste.valid-email', {
                email: this.newEmail
            })
            if (response.data.posts.post == 1) {
                this.confirmEmailCode = true
            }
            this.loadingCodeValidation = false
        },

        async confirmCode() {
            if (!this.$validation.check([
                {value: this.codeValidation, rules: 'required', id: 'inp-code-validation'},
            ])) {return}

            this.loadingCodeValidation = true
            let response = await this.$request.post('grossiste.valid-email-code', {
                code: this.codeValidation
            })
            if (response.data.posts.post == 1) {
                this.model.mail = this.newEmail
                this.codeValidation = null
                this.confirmEmailCode = false
                this.newEmail = null
                this.$modal.hide('modal-add-mail')
                this.errorMail = null
            } else {
                this.$toast.error(this.$tt('Code invalide'))
            }
            this.loadingCodeValidation = false
        }
    },

    mounted () {
        if (this.dataSrc) {
            this.model = this.dataSrc
        } else {
            this.getData()
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
