<template>
    <div>
        <template v-if="!addedDolibarr">
            <div class="rounded-lg mb-2 border bg-white p-2">
                <input-rule v-model="form.societe" type="text" :label-inline="false" label="Nom de société" class="mb-4" />
                <div class="grid grid-cols-4 gap-2">
                    <input-rule v-model="form.adresse" type="text" :label-inline="false" label="Adresse" class="mb-4" />
                    <input-rule v-model="form.cp" type="text" :label-inline="false" label="Code postal" class="mb-4" />
                    <input-rule v-model="form.ville" type="text" :label-inline="false" label="Ville" class="mb-4" />
                    <input-rule v-model="form.pays" type="text" :label-inline="false" label="Pays" class="mb-4" />
                </div>
                <div class="grid grid-cols-2 gap-2">
                    <input-rule v-model="form.tel" type="text" :label-inline="false" label="Tel" class="mb-4" />
                    <input-rule v-model="form.mail" type="text" :label-inline="false" label="Email" class="mb-4" />
                </div>
                <div class="grid grid-cols-3 gap-3">
                    <input-rule v-model="form.nom" type="text" :label-inline="false" label="Nom" class="mb-4" />
                    <input-rule v-model="form.prenom" type="text" :label-inline="false" label="Prénom" class="mb-4" />
                    <input-rule v-model="form.poste" type="text" :label-inline="false" label="Poste" class="mb-4" />
                </div>
                <div class="border-t mt-3 pt-3 pb-1 text-center">
                    <button :disabled="loadingDolibarr" class="px-4 py-2 rounded bg-green-200 hover:bg-green-300 duration-200 text-sm" @click="addDolibarr">
                        <spinner :size="10" v-if="loadingDolibarr" />
                        <template v-if="!loadingDolibarr">
                            <i class="fas fa-share mr-1"></i> Envoyer dans Dolibarr
                        </template>
                    </button>
                </div>
            </div>
        </template>
        <div class="bg-green-200 rounded px-4 py-4 text-sm text-center" v-if="addedDolibarr">
            <i class="fas fa-check"></i> Prospect ajouté dans Dolibarr
        </div>
    </div>
</template>

<script>
export default {
    props: {
        claimId: {},
        dolibarrId: {},
        societe:{},
        adresse:{},
        cp:{},
        ville:{},
        pays:{},
        tel:{},
        nom:{},
        prenom:{},
        poste:{},
        mail:{},
    },
    data() {
        return {
            form: {},
            addedDolibarr: false,
            loadingDolibarr: false,
        }
    },
    methods: {
        async addDolibarr() {
            this.loadingDolibarr = true
            let requiredFields = ['societe', 'tel', 'nom', 'prenom', 'mail'];
            let errorField = null
            requiredFields.forEach((item) => {
                if (!this.form[item]) {
                    errorField = item
                }
            })
            if (errorField) {
                this.$toast.error('Le champ ' + errorField + ' est obligatoire')
                this.loadingDolibarr = false
                return
            }
            let response = await this.$request.post('dolibarr.add-thiers', this.form)
            if (response.data.posts.post) {
                await this.$request.post('inscription.link-dolibarr', {
                    claim_id: this.claimId,
                    dolibarr_id: response.data.posts.post
                })
                this.addedDolibarr = true
                this.$toast.success('Ajouté à Dolibarr')
            }
            this.loadingDolibarr = false
        }
    },
    mounted () {
        this.addedDolibarr = this.dolibarrId ? 1 : 0
        this.form = {
            societe: this.societe || '',
            adresse: this.adresse || '',
            cp: this.cp || '',
            ville: this.ville || '',
            pays: this.pays || '',
            tel: this.tel || '',
            nom: this.nom || '',
            prenom: this.prenom || '',
            poste: this.poste || '',
            mail: this.mail || '',
        }
    },
}
</script>

<style lang="scss" scoped>

</style>