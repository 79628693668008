<template>
    <div>
        <div class="py-4" v-if="loaderGetData">
            <div class="flex justify-center">
                <spinner :size="12" />
            </div>
            <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
        </div>
        <template v-if="!loaderGetData && model">
            <modal name="reset-password-modal" height="auto" :scrollable="false">
                <div class="p-10 z-20" v-if="model">
                    <h1 class="text-xl font-bold">Réinitialiser le mot de passe ?</h1>
                    <div class="bg-slate-100 border rounded p-4 my-4">{{ model.contact_mail }}</div>
                    <spinner :size="10" v-if="loadingModal" />
                    <template v-if="!loadingModal">
                        <button @click="$modal.hide('reset-password-modal')" class="mr-1 text-sm bg-slate-200 hover:bg-slate-300 duration-200 cursor-pointer font-light px-5 py-2.5 rounded-xl" type="submit">
                            <i class="fas fa-times mr-0.5"></i> Annuler
                        </button>
                        <button @click="resetUserPassword" class="mr-1 text-sm bg-orange-500 hover:bg-orange-600 duration-200 cursor-pointer font-light px-5 py-2.5 rounded-xl text-white" type="submit">
                            <i class="fas fa-check mr-0.5"></i> Confirmer
                        </button>
                    </template>
                </div>
            </modal>

            <div class="grid lg:grid-cols-2 gap-2">
                <div>
                    <div class="rounded-lg mb-2 border bg-white p-2">
                        <input-rule v-model="model.email" :display-value="!edit" type="text" label="Identifiant" id="inp-email" />
                        <button v-if="edit && model.id" @click="$modal.show('reset-password-modal')" class="px-4 py-2 mt-4 rounded text-xs font-light bg-orange-200 hover:bg-orange-300 text-orange-800 cursor-pointer duration-200">
                            Nouveau mot de passe <i class="fas fa-redo-alt ml-1"></i>
                        </button>
                    </div>
                </div>
                <div>
                    <div class="rounded-lg mb-2 border bg-white p-2">
                        <input-rule v-model="model.prenom_user" :display-value="!edit" type="text" label="Prénom" id="inp-nom" />
                        <input-rule v-model="model.user_name" :display-value="!edit" type="text" label="Nom" id="inp-nom" />
                        <input-rule v-model="model.contact_mobile" :display-value="!edit" type="text" label="Tel" id="inp-tel" />
                        <input-rule v-model="model.contact_mail" :display-value="!edit" type="text" label="Email" id="inp-contact_email" />
                        <input-rule v-model="model.poste" :display-value="!edit" type="text" label="Poste" id="inp-poste" />
                        <input-rule v-model="model.user_lang" :display-value="!edit" type="text" label="Langue" id="inp-user_lang" />
                    </div>
                </div>
            </div>
            <div v-if="edit" class="text-center">
                <button :disabled="loaderEditAdd" class="px-4 py-2 rounded my-1 bg-green-200 hover:bg-green-300 duration-200 text-sm" @click="editAdd">
                    <template v-if="!loaderEditAdd">
                        <template v-if="model?.id">Modifier <i class="fas fa-pen ml-1"></i></template>
                        <template v-if="!model?.id">Enregistrer <i class="fas fa-plus ml-1"></i></template>
                    </template>
                    <template v-else>
                        <spinner :size="10" />
                    </template>
                </button>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        id: {},
        edit: {
            type: Boolean,
        },
        dataSrc: {},
    },

    data() {
        return {
            model: null,
            loaderGetData: false,
            loaderEditAdd: false,
            loadingModal: false,
        }
    },

    methods: {
        async getData(id = null) {
            this.loaderGetData = true
            if (id || this.id) {
                let response = await this.$request.post('user.view', {
                    line_id: id || this.id
                })
                if (response.data.posts.post) {
                    this.model = response.data.posts.post
                }
            } else {
                this.model = {}
            }
            this.loaderGetData = false
        },

        async editAdd() {
            if (!this.$validation.check([
                {value: this.model.id_entite, rules: 'required'},
                {value: this.model.type_entite, rules: 'required'},
                {value: this.model.email, rules: 'required', id: 'inp-email'},
                {value: this.model.contact_mail, rules: 'required|email', id: 'inp-contact_email'},
            ])) {return}

            this.loaderEditAdd = true
            let endObj = {...this.model}
            if (endObj.id) {
                endObj.line_id = endObj.id
                delete endObj.id
            }
            for (let key in endObj) {
                if (endObj[key] === null || endObj[key] === undefined) {
                    delete endObj[key]
                }
            }
            let response = await this.$request.post('user.edit-add', endObj)
            if (response.data.posts.post) {
                this.$emit('update', response.data.posts.post)
                this.getData(response.data.posts.post.id)
                this.$toast.success((endObj.id ? 'Compte modifié' : 'Compte enregistré'), {position: "bottom-right"})
            } else {
                this.$toast.error("Erreur lors de l'enregistrement")
            }
            this.loaderEditAdd = false
        },

        async resetUserPassword() {
            this.loadingModal = true
            let response = await this.$request.post('inscription.send-reset-password', {
                account_id: this.model.id,
                contact_mail: this.model.contact_mail,
            })
            if (response.data.posts.post == 1) {
                this.$toast.success('Email de réinitialisation envoyé')
                this.$modal.hide('reset-password-modal')
            } else {
                this.$toast.error('Une erreur est survenue')
            }
            this.loadingModal = false
        }
    },

    mounted () {
        this.getData();

        if (this.dataSrc) {
            this.model = this.dataSrc
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
