<template>
    <div>
        <categ-form :type-entite="$request.user().account.type_entite" />
    </div>
</template>

<script>
import CategForm from './CategForm.vue';
export default {
    components: {
        CategForm,
    },
}
</script>

<style lang="scss" scoped>

</style>./ModalReparateurManageCodes.vue