module.exports = {
    api2: {
        url: process.env.VUE_APP_BASE_URL + '/service',
        type: 'operation',
        id_type: 'id_grossiste'
    },
    api2_base: {
        url: process.env.VUE_APP_BASE_URL,
        type: 'operation',
        id_type: 'id_grossiste'
    },
    districloud: {
        url: process.env.VUE_APP_DISTRI_API_URL,
        type: 'operation',
        id_type: 'id_grossiste'
    },
    districloud_action: {
        url: process.env.VUE_APP_DISTRI_API_URL,
        type: 'action',
        id_type: 'id_grossiste'
    },
    districloud_no_action: {
        url: process.env.VUE_APP_DISTRICLOUD_API_URL,
        type: 'action',
        id_type: 'id_grossiste'
    },
    districloud_prod: {
        url: process.env.VUE_APP_DISTRICLOUD_PROD_URL,
        type: 'action',
        id_type: 'id_grossiste'
    },
    districloud_preprod: {
        url: process.env.VUE_APP_DISTRICLOUD_PREPROD_URL,
        type: 'operation',
        id_type: 'id'
    },
    districloud_preprod_grossiste: {
        url: process.env.VUE_APP_DISTRICLOUD_PREPROD_URL,
        type: 'operation',
        id_type: 'id_grossiste'
    },
    districloud_preprod_tchat: {
        url: 'https://preprod.savlog.net/districloud/app/service/rest/ws.php',
        type: 'operation',
        id_type: 'id'
    },
}
