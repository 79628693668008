<template>
    <div>
        <div v-for="(breadcrumb, breadcrumbIndex) in breadcrumbs" :key="'breadcrumb-' + breadcrumbIndex">
            <!-- Pays -->
            <div class="mt-5 pt-5 border-t" v-if="breadcrumb == 'pays'">
                <country-inscription
                @input="selectCountry"
                v-model="endForm.pays"
                :index="breadcrumbIndex + 2"
                default="FR"
                />
            </div>

            <!-- Verify -->
            <div class="mt-5 pt-5 border-t" v-if="breadcrumb == 'verify-societe'">
                <verify-societe
                :index="breadcrumbIndex + 2"
                :country="endForm.pays.code"
                @end="endVerifySociete"
                type-label="magasin"
                :display-label="false"
                actor="magasin"
                />

                <div class="mt-2 border rounded p-4 bg-green-100 border border-green-200 text-green-800" v-if="endForm.magasin.exist">
                    <div class="text-lg font-bold"><i class="fas fa-check mr-1"></i> {{ $tt('Magasin trouvé') }}</div>
                    <p class="font-light mb-2 text-sm border-b border-green-600 pb-2 mb-2">{{ $tt('Votre magasin est déjà enregistré dans notre logiciel.') }}</p>
                    <div class="text-sm">
                        <div>{{ $tt('Nom') }}: <b>{{ endForm.magasin.nom }}</b></div>
                        <!-- <div v-if="endForm.magasin.siret">{{ $tt('Siret') }}: <b>{{ endForm.magasin.siret }}</b></div> -->
                        <div>{{$tt('Adresse')}}: <b>{{ endForm.magasin.adresse.adresse1 + ' ' + (endForm.magasin.adresse.adresse2 ? endForm.magasin.adresse.adresse2 : '') + (endForm.magasin && endForm.magasin.adresse && endForm.magasin.adresse.cp ? endForm.magasin.adresse.cp : '') + ' ' + endForm.magasin.adresse.ville }}</b></div>
                    </div>
                </div>
            </div>

            <!-- Add shop -->
            <div class="mt-5 pt-5 border-t" v-if="breadcrumb == 'add-shop'">
                <label class="block mb-2 font-semibold text-gray-900">{{ breadcrumbIndex + 2 }}. {{ $tt('Informations société') }}</label>
                <div v-show="!added.shop">
                    <add-shop-inscription
                    @select-magasin="selectExistMagasin"
                    @end="endAddShop"
                    :country="endForm.pays.code"
                    :magasin="endForm.magasin"
                    :enseigne-id="endForm.enseigne.id"
                    />
                </div>
                <template v-if="added.shop">
                    <div class="flex items-center justify-between bg-slate-100 rounded p-2 px-4">
                        <span class="text-sm">{{ endForm.magasin.nom }}</span>
                        <button @click="added.shop = 0" class="flex items-center text-sm bg-slate-300 hover:bg-slate-400 duration-200 px-3 py-1.5 rounded">
                            {{ $tt('Modifier') }}
                            <i class="fas fa-pen ml-1"></i>
                        </button>
                    </div>
                </template>
            </div>

            <!-- Accounts -->
            <div class="mt-5 pt-5 border-t" v-if="breadcrumb == 'accounts'">
                <template v-if="!endForm.account.email">
                    <user-accounts-list
                    :country="endForm.pays.code"
                    type="magasin"
                    :sign-id="endForm.magasin.exist ? endForm.magasin.id : null"
                    sign-type="3"
                    :breadcrumb-index="breadcrumbIndex"
                    @update="endAddAccount"
                    />
                </template>
                <template v-if="endForm.account.email">
                    <label class="block mb-2 font-semibold text-gray-900">{{ breadcrumbIndex + 2 }}. Informations utilisateur</label>
                    <div class="flex items-center justify-between bg-slate-100 rounded p-2 px-4">
                        <div>
                            <div v-if="endForm.account.prenom" class="text-sm">{{ $tt('Prénom') }}: <b>{{ endForm.account.prenom }}</b></div>
                            <div class="text-sm">{{ $tt('Nom') }}: <b>{{ endForm.account.nom }}</b></div>
                            <div class="text-sm">{{ $tt('Email') }}: <b>{{ endForm.account.email }}</b></div>
                            <div class="text-sm">{{ $tt('Tel') }}: <b>{{ endForm.account.tel }}</b></div>
                            <div v-if="endForm.account.poste" class="text-sm">{{ $tt('Poste') }}: <b>{{ endForm.account.poste }}</b></div>
                        </div>
                        <button  class="flex items-center text-sm bg-slate-300 hover:bg-slate-400 duration-200 px-3 py-1.5 rounded">
                            {{ $tt('Modifier') }}
                            <i class="fas fa-pen ml-1"></i>
                        </button>
                    </div>
                </template>
            </div>

            <!-- Confirm -->
            <div class="mt-5 pt-5 border-t" v-if="breadcrumb == 'confirm'">
                <div class="text-xl font-bold">{{ $tt('Confirmez-vous ces informations ?') }}</div>
                <p class="font-light my-1">{{ $tt('Êtes-vous sûr des informations saisies ?') }}</p>
                <button :disabled="sendFormLoading" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 rounded text-sm mt-2 text-white" @click="sendJson">
                    <template v-if="!sendFormLoading">
                        <i class="fas fa-check mr-1"></i> {{ $tt('Confirmer la demande') }}
                    </template>
                    <template v-if="sendFormLoading">
                        <spinner :size="10" />
                    </template>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import VerifySociete from './VerifySociete.vue';
import CountryInscription from './CountryInscription.vue';
import UserAccountsList from './UserAccountsList.vue';
import AddShopInscription from './AddShopInscription.vue';

import mapService from '@/services/map.service'

export default {
    components: {
        CountryInscription,
        VerifySociete,
        UserAccountsList,
        AddShopInscription
    },
    props: {
        grossiste: {default: false},
    },
    watch: {
        breadcrumbs() {
            this.$nextTick(() => {
                setTimeout(() => {
                    window.scrollTo(0, document.body.scrollHeight);
                }, 50)
            })
        },
        grossiste() {
            if (this.grossiste) {
                this.endForm.enseigne.ecommerce = 0
                this.endForm.magasin.ecommerce = 0
                this.endForm.enseigne.grossiste = 1
            } else {
                this.endForm.enseigne.ecommerce = 1
                this.endForm.magasin.ecommerce = 1
                this.endForm.enseigne.grossiste = 0
            }
        }
    },
    data() {
        return {
            endForm: {
                lang: localStorage.getItem('lang'),
                type: 'magasin',
                pays: null,
                enseigne: {
                    exist: null,
                    id: null,
                    nom: null,
                    ecommerce: 1,
                    grossiste: 0
                },
                magasin: {
                    exist: null,
                    has_centrale: null,
                    id: null,
                    siret: null,
                    tva: null,
                    nom: null,
                    secteur: null,
                    ecommerce: 1,
                    adresse: {
                        cp: null,
                        ville: null,
                        adresse1: null,
                        adresse2: null,
                        longitude: null,
                        latitude: null,
                    },
                    independant: null,
                },
                account: {
                    id: null,
                    tel: null,
                    email: null,
                    nom: null,
                    poste: null
                },
                liaisons: []
            },

            steps: {
                'pays': 0,
                'secteur': 1,
                'independant_shop': 2,
                'has_centrale': 3,
                'centrale_ha': 4,
                'verify-societe': 5,
                'add-shop': 6,
                'accounts': 7,
                'confirm': 8,
            },

            added: {
                secteur: false,
                centrale: false,
                shop: false,
            },

            breadcrumbs: [],

            validShop: false,
            sendFormLoading: false,
        }
    },

    computed: {
        isUeCountry() {
            let ueCountries = ['BE', 'EL', 'LT', 'PT', 'BG', 'ES', 'LU', 'RO', 'CZ', 'FR', 'HU', 'SI', 'DK', 'HR', 'MT', 'SK', 'DE', 'IT', 'NL', 'FI', 'EE', 'CY', 'AT', 'SE', 'IE', 'LV', 'PL']
            if (this.endForm.pays && this.endForm.pays.code && ueCountries.find((country) => country == this.endForm.pays.code)) {
                return true
            }
            return false
        },
    },

    methods: {
        addBreadcrumb(keyword) {
            let currentStep = this.steps[keyword]
            let deleteItems = []
            this.breadcrumbs.forEach((breadcrumb) => {
                if (this.steps[breadcrumb] >= currentStep) {
                    deleteItems.push(breadcrumb)
                }
            })

            deleteItems.forEach((deleteItem) => {
                let index = this.breadcrumbs.findIndex((item) => item == deleteItem)
                if (index >= 0) {
                    this.breadcrumbs.splice(index, 1)
                }
            })
    
            this.breadcrumbs.push(keyword)
        },

        selectCountry() {
            let tvaCountries = ["DE","AT","BE","BG","CY","HR","DK","ES","EE","FI","FR","EL","HU","IE","IT","LV","LT","LU","MT","NL","PL","PT","CZ","RO","SK","SI","SE"]
            if (tvaCountries.find((code) => this.endForm.pays.code == code)) {
                this.addBreadcrumb('verify-societe')
            } else {
                this.addBreadcrumb('add-societe')
            }
        },

        selectExistMagasin(value) {
            this.endForm.magasin.exist = 1
            this.endForm.enseigne.id = value.id_centrale_ha
            this.endForm.magasin.id = value.id
            this.endForm.magasin.nom = value.magasin
            this.endForm.magasin.adresse.adresse1 = value.adresse1
            this.endForm.magasin.adresse.cp = value.cp
            this.endForm.magasin.adresse.ville = value.ville
            this.endForm.magasin.adresse.longitude = value.lng
            this.endForm.magasin.adresse.latitude = value.lat

            // Set centrale
            this.endForm.enseigne.exist = 1
            this.endForm.enseigne.id = value.centrale.id
            this.endForm.enseigne.nom = value.centrale.centrale_ha

            this.addBreadcrumb('accounts')
            this.added.shop = true
        },

        async endVerifySociete(value) {
            if (value.found == 0 && value.exist == 0 && this.endForm.pays.code == 'FR') {
                this.$toast.error(this.$tt('SIRET introuvable, merci de saisir un numéro de SIRET correct'))
                return
            }
            if (value.value.type == 'tva') {
                // Get address
                let addressMap = await mapService.search(value.adresse, this.endForm.pays.code)
                this.endForm.magasin.adresse = {}
                this.endForm.magasin.adresse.cp = this.$address.get(addressMap[0], 'cp')
                this.endForm.magasin.adresse.ville = this.$address.get(addressMap[0], 'ville')
                this.endForm.magasin.adresse.adresse1 = this.$address.get(addressMap[0], 'adresse1')
                this.endForm.magasin.adresse.adresse2 = this.$address.get(addressMap[0], 'adresse2')
            } else {
                this.endForm.magasin.adresse.cp = value.value.cp
                this.endForm.magasin.adresse.ville = value.value.ville
                this.endForm.magasin.adresse.adresse1 = value.value.adresse1
                this.endForm.magasin.adresse.adresse2 = value.value.adresse2 || ''
                this.endForm.magasin.adresse.longitude = value.value.longitude
                this.endForm.magasin.adresse.latitude = value.value.latitude
                this.endForm.magasin.adresse.pays = value.value.pays
            }

            // Set json value
            this.endForm.magasin.exist = value.exist
            this.endForm.magasin.id = value.value.id
            this.endForm.magasin.siret = value.value.siret
            this.endForm.magasin.tva = value.value.tva
            this.endForm.magasin.nom = value.value.nom

            // Set centrale
            this.endForm.enseigne.exist = false
            this.endForm.enseigne.id = null
            this.endForm.enseigne.nom = this.endForm.magasin.nom

            if (value.exist == 1) {
                this.endForm.enseigne.id = value.value.centrale.id
                this.endForm.enseigne.nom = value.value.centrale.nom_enseigne
                this.endForm.enseigne.exist = 1
                this.addBreadcrumb('accounts')
            } else {
                this.addBreadcrumb('add-shop')
            }
        },

        endAddShop(value) {
            this.validShop = true
            this.endForm.magasin.nom = value.nom
            this.endForm.magasin.email = value.email
            this.endForm.magasin.tel = value.tel
            this.endForm.liaisons = value.fournisseurs
            this.endForm.magasin.adresse.cp = value.cp
            this.endForm.magasin.adresse.ville = value.ville
            this.endForm.magasin.adresse.adresse1 = value.adresse1
            this.endForm.magasin.adresse.adresse2 = value.adresse2
            this.endForm.magasin.adresse.longitude = value.longitude
            this.endForm.magasin.adresse.latitude = value.latitude

            if (this.endForm.magasin.independant && !this.endForm.magasin.has_centrale) {
                this.endForm.enseigne.nom = value.nom
            }
            this.addBreadcrumb('accounts')
            this.added.shop = true
        },

        endAddAccount(value) {
            this.endForm.account.prenom = value.prenom
            this.endForm.account.nom = value.nom
            this.endForm.account.email = value.email
            this.endForm.account.tel = value.tel
            this.endForm.account.poste = value.poste
            this.addBreadcrumb('confirm')
        },

        async sendJson() {
            this.sendFormLoading = true
            let response = await this.$request.post('inscription.add-claim', {
                json: JSON.stringify(this.endForm),
            })
            this.sendFormLoading = false
            if (response.data.posts.post.id) {
                this.$emit('end')
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
        }
    },

    mounted () {
        this.addBreadcrumb('pays')

        if (this.grossiste) {
            this.endForm.enseigne.ecommerce = 0
            this.endForm.magasin.ecommerce = 0
            this.endForm.enseigne.grossiste = 1
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
