<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
    mounted () {
        this.$eventHub.$on('error-toast', (message) => {
            this.$toast.error(message)
        })
    },
}
</script>
