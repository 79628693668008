<template>
    <div>
        <!-- <modal name="modal-view-magasin" height="auto" width="80%" :scrollable="true">
            <div v-if="selectedMagasin" class="p-10" style="z-index: 999 !important;">
                <h2 class="text-xl font-bold mb-4">Magasin</h2>
                <magasin-view :id="selectedMagasin.id" :edit="true" @update="updatedMagasin" />
            </div>
        </modal> -->
        Rechercher un dossier par WCA
        <div class="bg-white p-4 rounded border border-slate-100">
            <!-- <data-table
            :filters-inline="false"
            :filters="[
                {key: 'num_dsc', label: 'Numéro dossier', type: 'text', value: null},
            ]"
            :columns="[
                {label: 'Numéro dossier', key: 'num_dsc', type: 'text'},
                {label: 'Date demande', key: 'date_demande', type: 'date'},
            ]"
            :lines="dossiers"
            >
                <template v-slot:actions='{line}'>
                    <button @click="viewMagasin(line)" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                        <i class="fas fa-eye"></i>
                    </button>
                </template>
            </data-table> -->
        </div>
    </div>
</template>

<script>
// import MagasinView from '../../Magasin/MagasinView.vue'

export default {
    props: {
        signId: {required: true},
    },
    components: {
        // MagasinView,
    },
    data() {
        return {
            // dossiers: null,
            // selectedMagasin: null,
        }
    },
    methods: {
        // async getDossiers() {
        //     let response = await this.$request.post('client.dossiers', {
        //         type: 'centrale_ha',
        //         sign_id: this.signId
        //     })
        //     this.dossiers = response.data.posts.post
        // },

        // viewMagasin(magasin) {
        //     this.selectedMagasin = magasin
        //     this.$modal.show('modal-view-magasin')
        // },

        // async updatedMagasin() {
        //     await this.getDossiers()
        //     this.$modal.hide('modal-view-magasin')
        // }
    },
    mounted () {
        // this.getDossiers();
    },
}
</script>

<style lang="scss" scoped>

</style>