<template>
    <div>
        <div class="absolute -ml-[250px] mt-5 flex flex-col items-end w-[200px]">
            <div>
                <button @click="displayMenu = !displayMenu" class="font-light px-4 py-2 rounded-full bg-slate-300 hover:bg-slate-400 duration-200 cursor-pointer"><i class="fas fa-plus"></i></button>
            </div>
            <div class="mt-2 flex flex-col items-end" v-if="displayMenu">
                <button @click="addNewMsg" class="bg-white hover:opacity-80 duration-200 px-4 py-2 rounded text-sm mb-2">
                    <i class="fas fa-message mr-1"></i> Nouveau message
                </button>
                <button @click="addNewResponse" class="bg-white hover:opacity-80 duration-200 px-4 py-2 rounded text-sm">
                    <i class="fas fa-comment mr-1"></i> Nouvelle réponse
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        patternId: {required: true},
    },
    data() {
        return {
            displayMenu: false
        }
    },
    methods: {
        async addNewMsg() {
            this.displayMenu = false

            let nextId = parseInt(Object.keys(this.$parent.nodes).length)
            let blocName = nextId + 1

            // Save new bloc
            await this.$request.post('addbloc', {
                action: 'bdc',
                type: 'message',
                nom: blocName,
                id_pattern: this.patternId,
                label: '',
                meta: JSON.stringify({
                    coordinates: {
                        x: 0,
                        y: 0
                    }
                })
            }, 'districloud_preprod')

            await this.$parent.refresh()
        },

        async addNewResponse() {
            this.displayMenu = false

            let nextId = parseInt(Object.keys(this.$parent.nodes).length)
            let blocName = nextId + 1

            // Save new bloc
            await this.$request.post('addbloc', {
                action: 'bdc',
                type: 'reponse',
                nom: blocName,
                id_pattern: this.patternId,
                label: '',
                meta: JSON.stringify({
                    coordinates: {
                        x: 0,
                        y: 0
                    }
                })
            }, 'districloud_preprod')

            await this.$parent.refresh()
        }
    },
}
</script>

<style lang="scss" scoped>

</style>