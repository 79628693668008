var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal',{attrs:{"name":"modal-delete-invitation","height":"auto","scrollable":true}},[_c('div',{staticClass:"p-10"},[(_vm.selectedLine)?[_c('div',{staticClass:"flex items-center justify-between mb-4 pb-4 border-b text-2xl"},[_c('h2',{staticClass:"font-bold"},[_vm._v(" Annuler un avoir ")]),_c('button',{on:{"click":function($event){_vm.$modal.hide('modal-cancel-avoir'); _vm.motifCancel = null}}},[_c('i',{staticClass:"fas fa-times text-red-500"})])]),_c('div',{staticClass:"p-4 mb-4 text-sm text-orange-800 rounded-lg bg-orange-50",attrs:{"role":"alert"}},[_c('p',[_vm._v("Êtes-vous sûr de vouloir supprimer l'invitation envoyé à "),_c('b',[_vm._v(_vm._s(_vm.selectedLine.email))]),_vm._v(" ?")])]),_c('button',{staticClass:"bg-red-600 text-white px-4 py-2 rounded mt-2",attrs:{"disabled":_vm.loaderModal},on:{"click":_vm.deleteInvitation}},[(!_vm.loaderModal)?[_vm._v(" Confirmer "),_c('i',{staticClass:"fas fa-check ml-1"})]:_vm._e(),(_vm.loaderModal)?[_c('spinner',{attrs:{"size":10}})]:_vm._e()],2)]:_vm._e()],2)]),_c('app-template',[_c('div',{staticClass:"p-4 rounded shadow bg-white"},[(_vm.loading)?_c('spinner'):_vm._e(),(!_vm.loading)?[_c('data-table',{attrs:{"filters":[
                    {label: 'Email', key: 'email', type: 'text'},
                ],"columns":[
                    {label: 'Date', key: 'created_at', type: 'datetime'},
                    {label: 'Email', key: 'email', type: 'text'},
                    {label: 'Invité par', key: 'nom_client', type: 'text'},
                ],"lines":_vm.invitations},scopedSlots:_vm._u([{key:"actions",fn:function({line}){return [_c('div',{staticClass:"flex items-center"},[_c('button',{staticClass:"mr-1 text-xs bg-red-500 hover:bg-red-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white",on:{"click":function($event){_vm.selectedLine = line; _vm.$modal.show('modal-delete-invitation')}}},[_c('i',{staticClass:"fas fa-trash"})])])]}}],null,false,4005099111)})]:_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }