<template>
    <div>
        <div class="py-24 overflow-y-auto h-screen" v-if="displayPage">
            <div class="fixed top-0 left-0 right-0 flex items-center justify-between px-[15px] py-2 mb-5 bg-gray-100" style="border-bottom: 1px solid #e6e6e6">
                <a href="https://districloud.net"><img src="https://disticloudfiles.net/upload/files/logo_districloud.png" class="h-[39px]" /></a>
                <div class="flex items-center justify-between gap-4">
                    <button @click="changeLang('fr')"><img :class="this.lang == 'fr' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Flag_of_France.png/1280px-Flag_of_France.png" alt=""></button>
                    <button @click="changeLang('en')"><img :class="this.lang == 'en' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Flag_of_the_United_Kingdom.png/1200px-Flag_of_the_United_Kingdom.png" alt=""></button>
                    <button @click="changeLang('de')"><img :class="this.lang == 'de' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Flag_of_Germany.svg/1280px-Flag_of_Germany.svg.png" alt=""></button>
                    <button @click="changeLang('es')"><img :class="this.lang == 'es' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Spain.svg/2560px-Flag_of_Spain.svg.png" alt=""></button>
                </div>
            </div>
            <template v-if="!sended">
                <div class="border rounded p-4 container lg:w-1/2 mx-auto">
                    <!-- Type entreprise -->
                    <div>
                        <label class="block mb-2 font-semibold text-gray-900">1. {{ $tt('Vous êtes') }} ?</label>
                        <div class="grid grid-cols-1 items-center gap-2 justify-center">
                            <button @click="selectEnterprise('magasin')" :class="enterprise.type == 'magasin' ? 'bg-sky-500 border-sky-600 text-white border-2' : 'hover:bg-sky-100 duration-200'" class="font-light text-sm flex items-center gap-4 cursor-pointer border rounded px-3 py-3">
                                <i class="fa-solid fa-shop"></i>
                                <div class="text-left">
                                    <div class="font-semibold">{{ $tt('Magasin') }}</div>
                                    <div class="text-xs">{{ $tt('Torrefacteur - Indépendant - Grande distribution') }}</div>
                                </div>
                            </button>
                            <button @click="selectEnterprise('fournisseur')" :class="enterprise.type == 'fournisseur' ? 'bg-sky-500 border-sky-600 text-white border-2' : 'hover:bg-sky-100 duration-200'" class="font-light text-sm flex items-center gap-4 cursor-pointer border rounded px-3 py-3">
                                <i class="fa-solid fa-box"></i>
                                <div class="text-left">
                                    <div class="font-semibold">{{ $tt('Fabricant') }}</div>
                                    <div class="text-xs">{{ $tt('Marque') }}</div>
                                </div>
                            </button>
                            <button @click="selectEnterprise('grossiste')" :class="enterprise.type == 'grossiste' ? 'bg-sky-500 border-sky-600 text-white border-2' : 'hover:bg-sky-100 duration-200'" class="font-light text-sm flex items-center gap-4 cursor-pointer border rounded px-3 py-3">
                                <i class="fa-solid fa-box"></i>
                                <div class="text-left">
                                    <div class="font-semibold">{{ $tt('Grossiste') }}</div>
                                    <div class="text-xs">{{ $tt('Fournisseur') }}</div>
                                </div>
                            </button>
                            <button @click="selectEnterprise('ecommerce')" :class="enterprise.type == 'ecommerce' ? 'bg-sky-500 border-sky-600 text-white border-2' : 'hover:bg-sky-100 duration-200'" class="font-light text-sm flex items-center gap-4 cursor-pointer border rounded px-3 py-3">
                                <i class="fa-solid fa-globe"></i>
                                <div class="text-left">
                                    <div class="font-semibold">{{ $tt('E-Commerce') }}</div>
                                    <div class="text-xs">{{ $tt('Vente en ligne') }}</div>
                                </div>
                            </button>
                            <button @click="selectEnterprise('reparateur')" :class="enterprise.type == 'reparateur' ? 'bg-sky-500 border-sky-600 text-white border-2' : 'hover:bg-sky-100 duration-200'" class="font-light text-sm flex items-center gap-4 cursor-pointer border rounded px-3 py-3">
                                <i class="fa-solid fa-screwdriver-wrench"></i>
                                <div class="text-left">
                                    <div class="font-semibold">{{ $tt('Réparateur') }}</div>
                                    <div class="text-xs">{{ $tt('Centre de réparation') }}</div>
                                </div>
                            </button>
                        </div>
                    </div>

                    <!-- Custom Form -->
                    <shop-form-inscription @end="sended = true" v-if="enterprise.type == 'magasin'" />
                    <ecommerce-form-inscription @end="sended = true" v-if="enterprise.type == 'grossiste'" :grossiste="1" />
                    <ecommerce-form-inscription @end="sended = true" v-if="enterprise.type == 'ecommerce'" />
                    <fournisseur-form-inscription @end="sended = true" v-if="enterprise.type == 'fournisseur'" />
                    <reparateur-form-inscription @end="sended = true" v-if="enterprise.type == 'reparateur'" />

                </div>
                <!-- Retour -->
                <div class="flex justify-center mt-5">
                    <a class="text-sm text-sky-500 font-light hover:underline" href="https://districloud.net">{{ $tt("Retour à l'accueil") }}</a>
                </div>
            </template>
            <template v-if="sended">
                <div class="container mx-auto mt-24 lg:w-1/2">
                    <div class="bg-green-100 p-10 text-green-900 rounded">
                        <h2 class="font-bold">{{ $tt('Message envoyé') }}</h2>
                        <p class="font-light text-sm mt-1">{{ $tt('Nous allons traiter votre demande dans les plus brefs délais.') }}</p>
                        <p class="font-light text-sm mt-1">{{ $tt('(Pensez à verifier vos courriers indésirables)') }}</p>
                    </div>
                </div>
            </template>
            <div class="fixed bottom-0 left-0 right-0 flex items-center justify-between px-[15px] py-2 bg-gray-100">
                <img src="https://www.districloud.net/img/logosavlog.png" class="h-[29px]" />
                <p class="text-sky-500" style="text-align: right; font-size: 10px; margin-right: 10px;" id="copyright">
                    <a href="https://www.districloud.fr" target="_blank"> www.districloud.fr</a>  © Savlog, DistriCloud 1998 - 2023 v2.2.1
                </p>
            </div>
        </div> 
    </div>
</template>

<script>
import ShopFormInscription from './Components/ShopFormInscription.vue';
import EcommerceFormInscription from './Components/EcommerceFormInscription.vue';
import FournisseurFormInscription from './Components/FournisseurFormInscription.vue';
import ReparateurFormInscription from './Components/ReparateurFormInscription.vue';

export default {
    components: {
        ShopFormInscription,
        EcommerceFormInscription,
        FournisseurFormInscription,
        ReparateurFormInscription,
    },

    data() {
        return {
            displayPage: false,
            enterprise: {
                type: null,
            },
            lang: 'fr',
            sended: false,
        }
    },

    computed: {
        typeEntite() {
            if (this.enterprise?.type) {
                switch (this.enterprise.type) {
                    case 'magasin':
                        return 3;
                    case 'grossiste':
                        return 3;
                    case 'centrale_ha':
                        return 2;
                    case 'fournisseur':
                        return 1;
                    case 'réparateur':
                        return 4;
                }
            }
            return null;
        }
    },
    
    methods: {
        selectEnterprise(type) {
            this.enterprise.type = type
        },

        changeLang(lang) {
            this.$lang.setLang(lang)
            location.reload()
        }
    },

    mounted () {
        this.lang = localStorage.getItem('lang') || 'fr';

        let urlString = window.location.href
        let paramString = urlString.split('?')[1]
        let queryString = new URLSearchParams(paramString)
        let endObj = {
            lang: null,
        }

        for (let pair of queryString.entries()) {
            if (pair[0] == 'lang') {
                endObj.lang = pair[1]
            }
        }

        if (endObj.lang) {
            this.lang = endObj.lang
            this.$lang.setLang(endObj.lang)
        }

        this.displayPage = true

        this.$eventHub.$on('end-form-inscription', () => {
            this.sended = true
        })

        window.LogRocket && window.LogRocket.init('omg4ub/districloud2');
    },
}
</script>

<style lang="scss" scoped>

</style>
