<template>
    <div>
        <modal name="modal-add-user" height="auto" :scrollable="true">
            <div v-if="newUser" class="p-10" style="z-index: 999 !important;">
                <div class="flex items-center border-b pb-4 mb-2 justify-between">
                    <h2 class="text-xl font-bold">{{$tt('Utilisateur')}}</h2>
                    <button class="cursor-pointer" @click="$modal.hide('modal-edit-user')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <input-rule type="checkbox" v-model="newUser.actif" :label="$tt('Compte actif')" :label-inline="false" class="mb-4" id="inp-identifiant" />
                <input-rule type="text" v-model="newUser.email" :label="$tt('Identifiant')" :label-inline="false" class="mb-4" id="inp-identifiant" />
                <input-rule type="text" v-model="newUser.contact_mail" :label="$tt('Email de contact')" :label-inline="false" class="mb-4" id="inp-contact_email" />
                <button :disabled="loadingModalAddUser" @click="editUser" class="mx-auto block bg-green-600 hover:bg-green-700 duration-200 text-white font-semibold cursor-pointer rounded-xl text-sm mt-4 px-4 py-2.5">
                    <template v-if="!loadingModalAddUser">
                        <template v-if="!newUser.id">
                            <i class="fas fa-plus mr-0.5"></i> {{$tt('Ajouter')}}
                        </template>
                        <template v-if="newUser.id">
                            <i class="fas fa-check mr-0.5"></i> {{$tt('Modifier')}}
                        </template>
                    </template>
                    <template v-if="loadingModalAddUser">
                        <spinner :size="10" />
                    </template>
                </button>
            </div>
        </modal>

        <div class="bg-slate-100 p-4 rounded mt-2">
            <div v-if="loadingGetUsers" class="p-4">
                <spinner :size="10" />
            </div>
            <template v-if="users && users.length > 0">
                <div class="border-b pb-4 mb-4">
                    <button @click="newUser = {
                            actif: true,
                            type_entite: $request.user().account.type_entite,
                            id_entite: $request.user().account.id_entite
                        }; $modal.show('modal-add-user')" class="px-4 py-2 bg-slate-200 hover:bg-slate-300 duration-200 rounded text-xs"><i class="fas fa-plus mr-1"></i> Ajouter un utilisateur</button>
                </div>
                <div class="max-h-[850px] overflow-y-auto">
                    <div v-for="(user, userIndex) in users" :key="'licence-user-' + userIndex" class="border-b pb-2 mb-2">
                        <div class="flex items-center gap-4">
                            <input-rule v-model="user.actif" type="pcheck" @input="emitData" :label-inline="false" />
                            <div :class="!user.actif ? 'opacity-50' : ''">
                                <div class="font-bold text-sm">{{ user.email }}</div>
                                <div class="font-light text-xs">{{ user.contact_mail }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-green-300 rounded p-4 mt-4 text-green-800">
                    <h2 class="text-lg font-bold border-b border-green-600 pb-2 mb-2"><i class="fas fa-receipt mr-1"></i> {{ $tt('Récapitulatif des coûts') }}</h2>
                    <div v-if="fraisSupp" class="font-semibold text-sm">{{$tt('Coût forfaitaire')}}: {{ fraisSupp }} €</div>
                    <div class="font-semibold text-sm mt-1">{{$tt('Coût des licences')}}: {{ activesLicences.length * amount }} €</div>
                    <div v-if="fraisProd" class="font-semibold text-sm mt-1">{{$tt('Coût unique de mise en production (démarrage)')}}: {{ fraisProd }} €</div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        amount: {required: true},
        edit: {default: false},
        clientId: {},
        initData: {},
        fraisProd: {},
        fraisSupp: {}
    },
    watch: {
        metaLicences: {
            deep: true,
            handler() {
                this.emitData()
            }
        }
    },
    data() {
        return {
            metaLicences: [],
            availableUsers: [],
            users: [],
            loadingGetUsers: false,
            newUser: {},
            loadingModalAddUser: false,
        }
    },
    computed: {
        activesLicences() {
            return this.users.filter((user) => user.actif == 1)
        }
    },
    // computed: {
    //     availableUsers() {
    //         return this.users.filter((user) => this.metaLicences.map(item => item['id']).filter(n => n).find((userId) => user.id != userId))
    //     }
    // },
    methods: {
        // addLicence() {
        //     console.log(this.users);
        //     this.availableUsers = this.users[0]
        //     this.metaLicences.push({email: null})
        // },

        // removeLicence(index) {
        //     this.metaLicences.splice(index, 1)
        // },

        emitData() {
            this.$emit('update', this.activesLicences)
        },

        async getUsers() {
            let endObj = {}
            if (this.clientId) {
                endObj.client_id = this.clientId
            }
            this.loadingGetUsers = true
            let response = await this.$request.post('facturation.get-client-users', endObj)
            this.users = response.data.posts.post.map((item) => ({...item, actif: false}))
            this.loadingGetUsers = false
        },

        async editUser() {
            if (!this.$validation.check([
                {value: this.newUser.email, rules: 'required', id: 'inp-identifiant'},
                {value: this.newUser.contact_mail, rules: 'required|email', id: 'inp-contact_email'},
            ])) {return}

            this.newUser.blocked = !this.newUser.actif ? 1 : 0
            delete this.newUser.actif
            this.loadingModalAddUser = true
            let response = await this.$request.post('client.add-user', this.newUser)
            if (response.data.posts.post && (response.data.posts.post.id || response.data.posts.post == 1)) {
                await this.getUsers()
                this.$modal.hide('modal-add-user')
                this.$toast.success(this.$tt('Utilisateur ajouté'))
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
            this.loadingModalAddUser = false
        },
    },
    async mounted () {
        await this.getUsers()
        if (this.initData) {
            this.initData.forEach((item) => {
                let user = this.users.find((user) => user.id == item.id)
                if (user) {
                    user.actif = 1
                }
                // this.metaLicences.push({
                //     email: item.email,
                //     id: item.id
                // })
            })
            // } else {
            // this.addLicence()
        }
    },
}
</script>

<style lang="scss" scoped>

</style>