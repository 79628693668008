<template>
    <div>
        <modal name="confirm-email-modal" height="auto" width="80%" :scrollable="true">
            <div class="p-10">
                <template v-if="selectedAccount">
                    <div class="flex items-center border-b pb-4 mb-2 justify-between">
                        <h2 class="text-xl font-bold">{{ $tt('Réinitialiser le mot de passe') }}</h2>
                        <button class="cursor-pointer" @click="$modal.hide('confirm-email-modal')">
                            <i class="fas fa-times text-red-500 text-xl"></i>
                        </button>
                    </div>
                    <div class="bg-slate-100 rounded p-2 mb-4 text-sm font-bold">
                        {{ selectedAccount.contact_mail }}
                    </div>
                    <div class="grow">
                        <p class="font-light mb-2">{{ $tt("Confirmer l'identifiant ci-dessus afin de recevoir un lien de réinitialisation")}} :</p>
                        <input-rule v-model="confirmEmailAccount" type="text" :label-inline="false" />
                    </div>
                    <button :disabled="loaderEmailConfirmation" @click="confirmAccount" class="bg-green-500 text-white px-4 py-2 rounded mt-4">
                        <template v-if="!loaderEmailConfirmation">
                            {{ $tt('Valider') }}
                        </template>
                        <template v-if="loaderEmailConfirmation">
                            <spinner :size="10" />
                        </template>
                    </button>
                </template>
            </div>
        </modal>

        <div>
            <label class="block mb-2 font-semibold text-gray-900">{{ breadcrumbIndex + 2 }}.
                <template v-if="accounts.length > 0">
                    {{ $tt('Liste des utilisateurs') }}
                </template>
                <template v-if="accounts.length <= 0">
                    {{ $tt('Informations de contact') }}
                </template>
            </label>
            <div
            v-for="(account, accountIndex) in accounts"
            :key="'account-' + accountIndex"
            class="gap-2 p-4 border-b"
            :class="accountIndex < accounts.length - 1 ? 'border-b' : ''"
            >
                <div class="flex items-center justify-between">
                    <div class="text-sm font-semibold">{{ account.contact_mail }}</div>
                    <button class="bg-orange-100 hover:bg-orange-200 duration-200 cursor-pointer rounded text-sm px-3 py-1.5" @click="selectAccount(account)">{{ $tt('Réinitialiser le mot de passe') }}</button>
                </div>
            </div>
            <add-account-inscription
            :country="country"
            class="mt-2"
            :sign-type="type"
            :sign-id="signId"
            :only-form="accounts.length <= 0"
            @update="emitNewAccount"
            />
        </div>
    </div>
</template>

<script>
import AddAccountInscription from './AddAccountInscription.vue'

export default {
    components: {
        AddAccountInscription,
    },

    props: {
        breadcrumbIndex: {},
        signType: {required: true},
        signId: {required: true},
        type: {required: true},
        country: {},
    },

    data() {
        return {
            accounts: [],
            selectedAccount: null,

            loaderEmailConfirmation: false,
        }
    },

    methods: {
        async getAccounts() {
            if (this.signId) {
                let response = await this.$request.post('inscription.search.compte-enseigne', {
                    sign_id: this.signId,
                    sign_type: this.signType,
                })
                if (response.data.posts.post) {
                    this.accounts = response.data.posts.post
                }
            }
        },
        selectAccount(account) {
            this.confirmEmailAccount = null
            this.selectedAccount = account
            this.$modal.show('confirm-email-modal')
        },

        async confirmAccount() {
            this.loaderEmailConfirmation = true
            let response = await this.$request.post('inscription.valid-email-account', {
                account_id: this.selectedAccount.id,
                contact_mail: this.confirmEmailAccount,
            })
            if (response.data.posts.post && response.data.posts.post == 1) {                
                let responseReset = await this.$request.post('inscription.send-reset-password', {
                    account_id: this.selectedAccount.id,
                    contact_mail: this.confirmEmailAccount,
                })
                if (responseReset.data.posts.post && responseReset.data.posts.post == 1) {
                    this.$modal.hide(this.$tt('confirm-email-modal'))
                    this.$eventHub.$emit('end-form-inscription')
                }
                this.confirmEmailAccount = null
            } else {
                this.$toast.error(this.$tt('L\'email ne correspond pas'))
            }
            this.loaderEmailConfirmation = false
        },

        emitNewAccount(data) {
            this.$emit('update', data)
        }
    },
    mounted () {
        this.getAccounts();
    },
}
</script>

<style lang="scss" scoped>

</style>
