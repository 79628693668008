<!--
    :filters="[{key: 'date', type: 'text', label: 'Date de demande', value: null}]"
    :columns="[
        # With custom cell, add slot key
        {label: 'Id', key: 'id', slot: 'slotId', type: 'text'},
        # Without custom cell
        {label: 'Nom', key: 'name', type: 'text'}
    ]"
    :lines="[{id: 1, name: 'MarketMaker', type: 'Fournisseur'}]"
-->

<template>
    <div>
        <div class="2xl:flex grid xl:grid-cols-6 lg:grid-cols-3 gap-3" :class="filtersInline ? '' : 'items-end'">
            <!-- FILTERS -->
            <template v-if="filters && filters.length > 0">
                <div class="mb-2" v-for="(filter, filterIndex) in filters" :key="'filter-' + _uid + '-' + filterIndex">
                    <!-- Text -->
                    <template v-if="filter.type == 'text'">
                        <input-rule v-model="filter.value" type="text" :label="filter.label" :label-inline="filtersInline" />
                    </template>
                    <!-- Text -->
                    <template v-if="filter.type == 'select'">
                        <input-rule v-model="filter.value" type="select" :options="{values: filter.options, label: 'label', key: 'value'}" :label="filter.label" :label-inline="filtersInline" />
                    </template>
                    <!-- List -->
                    <template v-if="filter.type == 'list'">
                        <label class="block mb-1.5 mt-1 text-xs font-bold">{{ filter.label }}</label>
                        <dropdown-menu mode="hover" :overlay="false">
                            <button slot="trigger" class="text-sm  text-left rounded-0 block w-full focus:outline-none focus:border-gray-400 text-xs min-w-[150px]" :class="filtersInline ? 'border-0 border-b p-1.5' : 'rounded-lg bg-gray-50 border border-gray-300 mb-1 p-[6px] mb-2'">
                                    <span v-if="getNbListSelected(filter.key) <= 0">Aucun</span>
                                    <span v-if="getNbListSelected(filter.key) > 0">{{getNbListSelected(filter.key)}} {{ getNbListSelected(filter.key) > 1 ? 'filtres' : 'filtre' }}</span>
                            </button>
                            <div slot="body" class="p-4">
                                <div v-for="(option, optionIndex) in filter.options" :key="filter.label + '-filter-' + optionIndex" class="py-2">
                                    <p-check v-model="option.checked" class="p-default p-round p-thick" color="success-o">
                                        <span>{{ option.label }}</span>
                                    </p-check>
                                </div>
                            </div>
                        </dropdown-menu>
                    </template>
                    <!-- Date range -->
                    <template v-if="filter.type =='date-range'">
                        <input-rule v-model="filter.value" type="date-range" :label="filter.label" :label-inline="filtersInline" />
                    </template>
                </div>
                <div>
                    <button class="bg-slate-500 hover:bg-slate-600 text-white duration-200 p-5 py-2.5 rounded-xl font-light text-xs" :class="filtersInline ? '' : 'mb-2'" @click="request ? applyApiFilters() : applyFilters()">{{$tt('Appliquer')}} <i class="fas fa-check ml-1"></i></button>
                </div>
            </template>
        </div>

        <!-- TABLE LINES -->
        <div v-if="loadingApiData" class="flex justify-center py-6">
            <spinner :size="14" />
        </div>
        <div v-if="!loadingApiData" class="relative overflow-auto border border-slate-100" :class="maxHeight ? ('max-h-[' + maxHeight + ']') : ''">
            <table class="w-full text-sm text-left text-gray-500">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                        <th scope="col" class="px-6 py-3" v-if="!withoutAction && actionAtStart">
                            Actions
                        </th>
                        <th v-for="(column, columnIndex) in columns" :key="'datatable-header-' + _uid + '-' + columnIndex" scope="col" class="px-6 py-3">
                            {{ column.label }}
                        </th>
                        <th scope="col" class="px-6 py-3" v-if="!withoutAction && !actionAtStart">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(line, lineIndex) in tableLines" :key="'datatable-line-' + _uid + '-' + lineIndex" class="bg-white hover:bg-slate-50 duration-100 border-b">
                        <td class="px-6" v-if="!withoutAction && actionAtStart">
                            <slot name="actions" :line="line" :index="lineIndex"></slot>
                        </td>
                        <td v-for="(cell, cellIndex) in columns" :key="'datatable-line-' + _uid + '-' + cellIndex" scope="row" class="text-sm px-6 font-light text-gray-900 whitespace-nowrap">
                            <template v-if="cell.slot">
                                <slot :name="cell.slot" :line="line" :index="cellIndex"></slot>
                            </template>
                            <template v-if="!cell.slot">
                                <template v-if="cell.type == 'text'">
                                    {{ line[cell.key] || '-' }}
                                </template>
                                <template v-if="cell.type == 'file'">
                                    <button v-if="line[cell.key]" @click.stop="goToUrl(line[cell.key])" class="cursor-pointer text-blue-500 rounded-xl text-sm font-light">
                                        <i class="fas fa-eye mr-0.5 text-xs"></i> Consulter
                                    </button>
                                </template>
                                <template v-if="cell.type == 'date'">
                                    {{ line[cell.key] | momentDate }}
                                </template>
                                <template v-if="cell.type == 'euro'">
                                    {{ (line[cell.key] + '€') || '-' }}
                                </template>
                                <template v-if="cell.type == 'datetime'">
                                    {{ line[cell.key] | momentDateTime }}
                                </template>
                                <template v-if="cell.type == 'boolean'">
                                    <div v-show="line[cell.key] == 0 || line[cell.key] == '0'">
                                        <i class="fas fa-times text-slate-400"></i>
                                    </div>
                                    <div v-show="line[cell.key] == 1 || line[cell.key] == '1'">
                                        <i class="fas fa-check text-green-500"></i>
                                    </div>
                                </template>
                            </template>
                        </td>
                        <td class="px-6" v-if="!withoutAction && !actionAtStart">
                            <slot name="actions" :line="line" :index="lineIndex"></slot>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- NO RESULTS -->
            <div v-if="tableLines.length <= 0 && !loadingApiData" class="p-5 w-full bg-white font-light text-center">Aucun résultat</div>
            <div v-if="(request && request.params) && (tableLines && tableLines.length > 0 && nbTotalLines > request.params.per_page)" class="flex justify-end text-center mt-5 mr-2">
                <pagination
                v-model="page"
                :per-page="request.params.per_page"
                :records="nbTotalLines"
                />
            </div>
        </div>

    </div>
</template>

<script>
import moment from 'moment'
import apiService from '@/services/api.service'

import Pagination from 'vue-pagination-2';

export default {
    components: {
        Pagination,
    },
    props: {
        filters: {
            type: Array,
            default: null,
        },
        columns: {
            type: Array,
            required: true
        },
        lines: {},
        request: {},
        filtersInline: {
            default: true
        },
        withoutAction: {
            default: false
        },
        actionAtStart: {
            default: false
        },
        emitFilters: {},
        callbackLines: {},
        id: {},
        maxHeight: {}
    },

    watch: {
        lines: {
            deep: true,
            handler() {
                this.initTableLines()
            }
        },

        page: function() {
            this.getApiLines()
        },

        request: {
            deep: true,
            handler: function() {
                // this.getApiLines()
                if (this.request && this.request.params && this.request.params.page) {
                    this.page = this.request.params.page
                }
            }
        }
    },

    data() {
        return {
            tableLines: [],
            loadingApiData: false,

            page: 1,
            nbTotalLines: null,
        }
    },

    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },

    methods: {
        async initTableLines() {
            if (this.lines) {
                this.tableLines = JSON.parse(JSON.stringify(this.lines))
            } else if (this.request) {
                await this.getApiLines()
            }
            if (this.callbackLines) {
                this.callbackLines({lines: this.tableLines, callback: (overwritedLines) => {
                    this.tableLines = overwritedLines
                }})
            }
        },

        async getApiLines() {
            this.loadingApiData = true
            let params = this.request.params
            params.page = this.page

            if (this.filters) {
                let activeFilters = this.filters.filter((filter) => filter.value)
                if (activeFilters && activeFilters.length > 0) {
                    params.conditions = ''
                    
                    if (this.request.apiName == 'api2') {
                        let endConditions = []
                        activeFilters.forEach((filter) => {
                            endConditions.push({key: filter.key, value: filter.value})
                        })
                        params.conditions = JSON.stringify(endConditions)
                    } else {
                        activeFilters.forEach((filter, index) => {
                            params.conditions += '"' + filter.key + '=\'' + filter.value + '\'"'
                            if (index < activeFilters.length) {
                                params.conditions += ','
                            }
                        })
                        params.conditions = '[' + params.conditions + ']'
                    }
                }
            }
            let response = await apiService.post(this.request.name, params, this.request.apiName)
            this.tableLines = this.request.apiName == 'api2' ? response.data.posts.post.data : response.data.posts.post
            this.nbTotalLines = this.request.apiName == 'api2' ? response.data.posts.post.total : parseInt(response.data.posts.options.total)
            this.loadingApiData = false
        },

        applyApiFilters() {
            this.page = 1
            this.getApiLines()
        },

        applyFilters() {
            if (this.emitFilters) {
                this.$emit('apply-filters', this.filters)
                return
            }
            this.initTableLines()
            let endArray = JSON.parse(JSON.stringify(this.tableLines))
            this.filters.forEach((filter) => {
                endArray.forEach((line) => {
                    if (filter.type == 'text' && filter.value && filter.value != '') {
                        if (line[filter.key] && !line[filter.key].toString().toLowerCase().startsWith(filter.value.toLowerCase())) {
                            line.delete = 1
                        }
                    }

                    if (filter.type == 'list') {
                        if (filter.options.find((filter) => filter.checked)) {
                            const options = filter.options.map((option) => {
                                if (option.checked) {
                                    return option.value
                                }
                            }).filter(item => item)
                            if (!options.includes(line[filter.key])) {
                                line.delete = 1
                            }
                        }
                    }

                    if (filter.type == 'date-range') {
                        if (filter.value.startDate && filter.value.endDate) {
                            const date = moment(line[filter.key])
                            if (!date.isBetween(filter.value.startDate, filter.value.endDate)) {
                                line.delete = 1
                            }
                        }
                    }
                })
            })
            this.tableLines = endArray.filter((tableLine) => !tableLine.delete)
        },

        getNbListSelected(listKey) {
            const options = this.filters.find((filter) => filter.key == listKey).options
            if (options && options.length > 0) {
                return options.filter((option) => option.checked).length
            }
            return 0
        },

        goToUrl(url) {
            window.open(url, '_blank').focus();
        }
    },

    mounted() {
        this.initTableLines()

        if (this.request && this.request.params && this.request.params.page) {
            this.page = this.request.params.page
        }
        this.$eventHub.$on('refresh-lines', (data => {
            if (data == this.id) {
                this.getApiLines()
            }
        }))
    }
}
</script>

<style>


.data-table::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.data-table::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.VuePagination__pagination {
    display: flex;
}

.VuePagination__pagination .page-item {
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid #E4E4E4;
}

.VuePagination__pagination .active {
    background: black;
    color: white;
}

.VuePagination__count {
    font-size: 12px !important;
    margin-top: 6px;
    font-weight: 300;
}
</style>
