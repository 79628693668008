<template>
    <div>
        <div v-if="details.length > 0" class="font-bold text-sm">Détails de la facture :</div>
        <div v-for="(detail, detailIndex) in details" class="flex items-center gap-4 py-1.5 text-sm" :key="'detail-' + detailIndex">
            <div>
                <div>{{ detail.label }}</div>
                <div class="text-xs font-light">
                    <template v-if="detail.meta">
                        <div v-if="detail.meta.nbConnexion">
                            <span>Connecté avec {{ detail.meta.nbConnexion }} acteurs</span>
                            <div v-if="detail.meta.nbDscGroup == -1">Dossiers illimité</div>
                            <span v-else-if="detail.meta.nbDsc">, {{ detail.meta.nbDsc + ' ' + (detail.meta.nbDsc > 1 ? 'dossiers' : 'dossier') }}</span>
                            <div v-if="detail.meta.nbDscGroup && detail.meta.nbDscGroup != 300 && detail.meta.nbDscGroup != -1">Lot de {{ detail.meta.nbDscGroup + ' ' + (detail.meta.nbDscGroup > 1 ? 'dossiers' : 'dossier') }}</div>
                        </div>
                        <div v-if="detail.meta.licences">
                            Licences: {{ detail.meta.licences }}
                        </div>
                        <div v-if="detail.meta && detail.meta.nbDossiers">
                            Dossiers: {{ detail.meta.nbDossiers }}
                        </div>
                    </template>
                </div>
            </div>
            <div class="grow border-b border-dashed"></div>
            <div class="font-bold text-right">
                <div v-if="!detail.discount">{{ detail.value }} €</div>

                <!-- Negociated -->
                <div class="text-right" v-if="detail.negotiated">
                    <div class="text-xs font-light">Prix négocié</div>
                </div>

                <!-- Discount -->
                <div class="text-right" v-if="detail.discount">
                    <div>{{ detail.discount.price_with_discount }} €</div>
                    <div class="text-xs font-light">Remise de {{ detail.discount.discount_percent }}%</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        details: {required: true},
        totalSaved: {required: true},
    },
}
</script>

<style lang="scss" scoped>

</style>