<template>
    <div>
        <modal name="modal-view-user" height="auto" width="80%" :scrollable="true">
            <div v-if="selectedLine" class="p-10" style="z-index: 999 !important;">
                <h2 class="text-xl font-bold mb-4">Utilisateur</h2>
                <account-view :id="selectedLine.id" :edit="true" @update="updatedLine" />
            </div>
        </modal>

        <div class="bg-white p-4 rounded border border-slate-100">
            <data-table
            :filters-inline="false"
            :filters="[
                {key: 'email', label: 'Identifiant', type: 'text', value: null},
                {key: 'contact_mail', label: 'Email', type: 'text', value: null},
            ]"
            :columns="[
                {label: 'Identifiant', key: 'email', type: 'text'},
                {label: 'Email', key: 'contact_mail', type: 'text'},
            ]"
            :lines="users"
            >
                <template v-slot:actions='{line}'>
                    <button @click="viewLine(line)" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                        <i class="fas fa-eye"></i>
                    </button>
                </template>
            </data-table>
        </div>
    </div>
</template>

<script>
import AccountView from '../../Magasin/AccountView.vue'

export default {
    props: {
        signId: {required: true},
    },
    components: {
        AccountView,
    },
    data() {
        return {
            users: null,
            selectedLine: null,
        }
    },
    methods: {
        async getData() {
            let response = await this.$request.post('client.users', {
                client_id: this.signId,
            })
            this.users = response.data.posts.post
        },

        viewLine(magasin) {
            this.selectedLine = magasin
            this.$modal.show('modal-view-user')
        },

        async updatedLine() {
            await this.getData()
            this.$modal.hide('modal-view-user')
        }
    },
    mounted () {
        this.getData();
    },
}
</script>

<style lang="scss" scoped>

</style>