<template>
    <div>
        <div class="py-4" v-if="loaderGetData">
            <div class="flex justify-center">
                <spinner :size="12" />
            </div>
            <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
        </div>
        <template v-if="!loaderGetData && model">
            <div class="grid lg:grid-cols-2 gap-2">
                <div>
                    <div class="rounded-lg mb-2 border bg-white">
                        <label class="hidden rounded-t-lg text-xs text-center p-2 font-bold border-b bg-slate-100 border-slate-200 block"><i class="fas fa-file mr-0.5"></i> Nom</label>
                        <div class="p-2">
                            <div class="flex items-center">
                                <input-rule class="grow" v-model="model.nom" :display-value="!edit" type="text" label="Nom de l'enseigne" id="inp-nom" />
                                <a :href="googleLink" target="_blank" class="bg-purple-200 hover:bg-purple-300 cursor-pointer duration-200 text-xs p-1 px-2 rounded-full"><i class="fas fa-search"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="rounded-lg mb-2 border bg-white">
                        <label class="hidden rounded-t-lg text-xs text-center p-2 font-bold border-b bg-slate-100 border-slate-200 block"><i class="fas fa-home mr-0.5"></i> Adresse</label>
                        <div class="p-2">
                            <input-rule v-model="form.adresse" :display-value="!edit" type="address2" :options="{defaultValue: model, country: model.pays}" label="Adresse" id="inp-address" />
                        </div>
                    </div>
                    <div class="rounded-lg mb-2 border bg-white">
                        <label class="hidden rounded-t-lg text-xs text-center p-2 font-bold border-b bg-slate-100 border-slate-200 block"><i class="fas fa-building mr-0.5"></i> Société</label>
                        <div class="p-2">
                            <input-rule v-model="model.siret" :display-value="!edit" type="text" label="SIRET" />
                            <input-rule v-model="model.tva_sta" :display-value="!edit" type="text" label="Numéro intra-communautaire" />
                        </div>
                    </div>
                </div>
                <div>
                    <div class="rounded-lg mb-2 border bg-white p-4" v-if="model.activites_actives && model.activites_actives.length > 0">
                        <div class="xl:grid grid-cols-2 gap-4">
                            <div v-for="(activite, activiteIndex) in model.activites_actives" :key="'activite-' + activiteIndex" class="flex items-center gap-4 mb-4">
                                <input-rule v-model="activite.actif" type="pcheck" :label-inline="false" />
                                <div :class="activite.actif ? '' : 'opacity-60 text-slate-700'">
                                    <div class="font-bold text-sm">{{ $tt(activite.libelle) }}</div>
                                    <div class="font-light text-xs" v-if="activite.description">{{ $tt(activite.description) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="edit" class="text-center">
                <button :disabled="loaderEditAdd" class="px-4 py-2 rounded my-1 bg-green-200 hover:bg-green-300 duration-200 text-sm" @click="editAdd">
                    <template v-if="!loaderEditAdd">
                        <template v-if="model.id">Modifier <i class="fas fa-pen ml-1"></i></template>
                        <template v-if="!model.id">Enregistrer <i class="fas fa-plus ml-1"></i></template>
                    </template>
                    <template v-else>
                        <spinner :size="10" />
                    </template>
                </button>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        id: {},
        edit: {
            type: Boolean,
        },
        dataSrc: {}
    },

    watch: {
        id: function() {
            this.getData()
        }
    },

    data() {
        return {
            model: null,
            loaderGetData: false,
            loaderEditAdd: false,
            
            form: {
                adresse: null,
            }
        }
    },

    computed: {
        fullAddress() {
            if (this.model && this.model.adresse) {
                return this.model.adresse + ' ' + this.model.ville + ' ' + this.model.cp
            }
            return null
        },
        googleLink() {
            return 'https://www.google.com/search?q=' + (this.model.nom.replace(' ', '+')) + '+societe.com'
        }
    },

    methods: {
        async getData(id = null) {
            this.loaderGetData = true
            let response = await this.$request.post('station.view', {
                line_id: id || this.id
            })
            if (response.data.posts.post) {
                this.model = response.data.posts.post
                this.model.adresse1 = response.data.posts.post.adresse
            }
            this.loaderGetData = false
        },

        async editAdd() {
            if (!this.$validation.check([
                {value: this.model.nom, rules: 'required', id: 'inp-nom'},
                {value: this.form.adresse.adresse1, rules: 'required', id: 'inp-address'},
            ])) {return}

            this.loaderEditAdd = true
            let endObj = {...this.model}
            if (endObj.id) {
                endObj.line_id = endObj.id
                delete endObj.id
            }
            // Set address
            delete endObj.adresse1
            delete endObj.activites
            endObj.adresse = this.form.adresse.adresse1
            endObj.cp = this.form.adresse.cp
            endObj.ville = this.form.adresse.ville
            endObj.lon = this.form.adresse.longitude
            endObj.lat = this.form.adresse.latitude
            endObj.activites_actives = JSON.stringify(this.model.activites_actives)

            for (let key in endObj) {
                if (endObj[key] === null || endObj[key] === undefined) {
                    delete endObj[key]
                }
            }
            let response = await this.$request.post('reparateur.edit-add', endObj)
            if (response.data.posts.post) {
                this.$emit('update', response.data.posts.post)
                this.getData(response.data.posts.post.id)
                this.$toast.success((endObj.id ? 'Station modifiée' : 'Station enregistrée'), {position: "bottom-right"})
            } else {
                this.$toast.error("Erreur lors de l'enregistrement")
            }
            this.loaderEditAdd = false
        }
    },

    mounted () {
        this.getData()
        if (this.dataSrc) {
            this.model = this.dataSrc
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
