var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal',{attrs:{"name":"modal-view-user","height":"auto","width":"80%","scrollable":true}},[(_vm.selectedLine)?_c('div',{staticClass:"p-10",staticStyle:{"z-index":"999 !important"}},[_c('h2',{staticClass:"text-xl font-bold mb-4"},[_vm._v("Utilisateur")]),_c('account-view',{attrs:{"id":_vm.selectedLine.id,"edit":true},on:{"update":_vm.updatedLine}})],1):_vm._e()]),_c('div',{staticClass:"bg-white p-4 rounded border border-slate-100"},[_c('data-table',{attrs:{"filters-inline":false,"filters":[
            {key: 'email', label: 'Identifiant', type: 'text', value: null},
            {key: 'contact_mail', label: 'Email', type: 'text', value: null},
        ],"columns":[
            {label: 'Identifiant', key: 'email', type: 'text'},
            {label: 'Email', key: 'contact_mail', type: 'text'},
        ],"lines":_vm.users},scopedSlots:_vm._u([{key:"actions",fn:function({line}){return [_c('button',{staticClass:"mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white",attrs:{"type":"submit"},on:{"click":function($event){return _vm.viewLine(line)}}},[_c('i',{staticClass:"fas fa-eye"})])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }