<template>
    <div>
        <app-template>
            <div class="bg-white p-10 rounded shadow">
                <data-table
                :without-action="true"
                :server="true"
                :filters="[
                    {label: 'Ref dossier', key: 'ref_dossier', type: 'text'},
                ]"
                :columns="[
                    {label: 'Date', key: 'created_at', type: 'datetime'},
                    {label: 'Ref dossier', key: 'ref_dossier', type: 'text'},
                    {label: 'Etat', key: 'state_label', slot: 'state_label'},
                    {label: 'Station', key: 'station_name', type: 'text'},
                    {label: 'Montant remb', key: 'eco_amount', type: 'euro'},
                    {label: 'Facture', key: 'uploaded_invoice', type: 'file'},
                    {label: 'Accepté le', key: 'accept_date', type: 'date'},
                    {label: 'Payé le', key: 'pay_date', type: 'date'},
                    {label: 'Rejeté le', key: 'refused_date', type: 'date'},
                    {label: 'Motif rejet', key: 'reject_reason', type: 'text'},
                ]"
                :request="{
                    name: 'admin.ecosystem.list',
                    params: {
                        page: 0,
                        per_page: 50,
                    },
                    apiName: 'api2'
                }"
                >
                    <template v-slot:state_label='{line}'>
                        <span class="bg-orange-400 px-2 rounded text-white text-xs font-light py-0.5" v-if="line.state == 1">
                            {{ line.state_label }}
                        </span>
                        <span class="bg-slate-300 px-2 rounded text-white text-xs font-light py-0.5" v-if="line.state == 2">
                            {{ line.state_label }}
                        </span>
                        <span class="bg-green-500 px-2 rounded text-white text-xs font-light py-0.5" v-if="line.state == 3 || line.state == 4">
                            {{ line.state_label }}
                        </span>
                        <span class="bg-red-500 px-2 rounded text-white text-xs font-light py-0.5" v-if="line.state == 5">
                            {{ line.state_label }}
                        </span>
                    </template>
                </data-table>
            </div>
        </app-template>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

</style>