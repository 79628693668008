var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-template',[_c('div',{staticClass:"bg-white p-10 rounded shadow"},[_c('crud-content',{attrs:{"id":"permission","label":{singular: 'une permission', plural: 'des permissions'},"ref-keyword":"keyword","routes":{
                list: {key: 'admin.permissions.list'},
                editAdd: {operation: 'admin.permissions.edit-add', form: {'keyword': 'keyword', 'description': 'description'}},
                delete: {key: 'admin.permissions.delete'}
            },"table":[
                {label: 'Mot clé', key: 'keyword', type: 'text'},
                {label: 'Description', key: 'description', type: 'text'},
            ],"filters":[
                {label: 'Mot clé', key: 'keyword', type: 'text', value: null},
            ]},scopedSlots:_vm._u([{key:"modal-edit-add",fn:function(props){return [_c('h1',{staticClass:"text-xl font-bold mb-4"},[_vm._v("Ajouter une permission")]),_c('input-rule',{staticClass:"mb-4",attrs:{"type":"text","label":"Mot clé","rules":"required","label-inline":false,"display-errors":false},model:{value:(props.modalForm.keyword),callback:function ($$v) {_vm.$set(props.modalForm, "keyword", $$v)},expression:"props.modalForm.keyword"}}),_c('input-rule',{staticClass:"mb-4",attrs:{"type":"textarea","label":"Description","rules":"required","label-inline":false,"display-errors":false},model:{value:(props.modalForm.description),callback:function ($$v) {_vm.$set(props.modalForm, "description", $$v)},expression:"props.modalForm.description"}}),(props.loadingModal)?_c('spinner'):_vm._e(),(!props.loadingModal)?_c('button',{staticClass:"mr-1 text-sm bg-green-500 hover:bg-green-600 duration-200 cursor-pointer font-light px-5 py-2.5 rounded-xl text-white",attrs:{"type":"submit"},on:{"click":props.sendForm}},[_c('i',{staticClass:"fas fa-check mr-0.5"}),_vm._v(" Ajouter ")]):_vm._e()]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }