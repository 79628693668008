<template>
    <div>
        <!-- Modal fill prices -->
        <modal name="modal-fill-empty-prices" width="75%" height="auto" :scrollable="true">
            <div class="p-10" style="z-index: 999 !important;">
                <h2 class="text-xl font-bold border-b pb-4 mb-4">{{ $tt('Compléter le formulaire') }}</h2>
                <div class="p-4 mb-4 text-xs text-red-700 rounded-lg bg-red-50 font-bold text-center text-sm" role="alert">
                    {{ $tt("Vous devez compléter les produits qui n'ont pas de prix") }}
                </div>
                <div class="p-4 mb-4 text-xs text-slate-700 rounded-lg bg-slate-50 font-bold text-sm border border-slate-300" role="alert">
                    <div class="border-b pb-2 mb-2">{{ $tt("Recherche automatique: Pensez à les vérifier avant de confirmer.") }}</div>
                    <button @click="searchEmptyPrices" class="bg-slate-300 hover:bg-slate-400 duration-200 cursor-pointer rounded px-4 py-2">{{ $tt('Rechercher') }} <i class="fas fa-search ml-1"></i></button>
                </div>
                <div class="relative overflow-auto border border-slate-100 max-h-[75vh] mt-2">
                    <table class="w-full text-left text-gray-500">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" class="p-3 overflow-hidden whitespace-nowrap">{{ $tt('Numéro') }}</th>
                                <th scope="col" class="p-3 overflow-hidden whitespace-nowrap">{{ $tt('Modèle') }}</th>
                                <th scope="col" class="p-3 overflow-hidden whitespace-nowrap">{{ $tt('Marque') }}</th>
                                <th scope="col" class="p-3 overflow-hidden whitespace-nowrap">{{ $tt('HT') }}</th>
                            </tr>
                        </thead>
                        <tbody class="max-h-[50vh]">
                            <tr
                            v-for="(line, lineIndex) in emptyPrices"
                            :key="'empty-line-' + _uid + '-' + lineIndex"
                            class="border-b"
                            >
                                    <td class="px-3 py-1 text-xs overflow-hidden whitespace-nowrap">{{line.num_dsc}}</td>
                                    <td class="px-3 py-1 text-xs overflow-hidden whitespace-nowrap">{{line.Modele}}</td>
                                    <td class="px-3 py-1 text-xs overflow-hidden whitespace-nowrap">{{line.Marque}}</td>
                                    <td v-if="canEditAvoirAmount" class="px-3 py-1 min-w-[90px] max-w-[90px] text-xs">
                                        <input-rule
                                        :options="{displayCurrency: false}"
                                        type="price"
                                        custom="text-xs"
                                        v-model="line.remb_ht"
                                        @input="updateAmountHT(line)"
                                        :label-inline="false"
                                        />
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <button @click="$modal.hide('modal-fill-empty-prices'); generate()" class="mx-auto block bg-emerald-600 hover:bg-emerald-700 duration-200 text-white border-emerald-600 font-semibold cursor-pointer border rounded-xl text-sm mt-4 px-4 py-2">
                    <i class="fas fa-check mr-0.5"></i> Confirmer
                </button>
            </div>
        </modal>

        <div size="A4">
            <div class="p-5">
                <!-- header -->
                <div class="grid grid-cols-2 gap-2">
                    <div v-if="account && account.info && account.info[0]" class="bg-slate-100 rounded border border-slate-100 p-3">
                        <div class="text-lg font-bold">{{ account.info[0].nom }}</div>
                        <div class="mt-1 text-sm font-light">{{ account.info[0].adresse1 + ' ' + (account.info[0].adresse2 != null ? account.info[0].adresse2 : '') }}</div>
                        <div class="text-sm font-light">{{account.info[0].cp + ' ' + account.info[0].ville }}</div>
                        <div class="mt-1 text-sm font-light">{{ account.info[0].tel }}</div>
                    </div>
                    <div class="rounded border p-3 flex" :class="selectedSign ? '' : 'justify-center items-center'">
                        <template v-if="selectedSign">
                            <div>
                                <div class="font-bold text-lg">{{ selectedSign.centrale_ha }}</div>
                                <template v-if="shop">
                                    <div class="mt-2 text-xs font-light">{{ shop.name + ' ' + shop.ville + ' ' + shop.cp }}</div>
                                    <div class="mt-1 text-sm font-light">{{ shop.adresse1 + ' ' + shop.adresse2 }}</div>
                                    <div class="text-sm font-light">{{shop.cp + ' ' + shop.ville }}</div>
                                </template>
                                <template v-else>
                                    <div class="mt-1 text-sm font-light">{{ selectedSign.adresse1 + ' ' + selectedSign.adresse2 }}</div>
                                    <div class="text-sm font-light">{{selectedSign.cp + ' ' + selectedSign.ville }}</div>
                                </template>
                            </div>
                        </template>
                        <template v-else>
                            <div class="font-light text-sm">{{ $tt('Aucune enseigne séléctionnée') }}</div>
                        </template>
                    </div>
                </div>

                <!-- lines -->
                <div class="mt-2">
                    <!-- Loader get folders -->
                    <div class="text-center py-3 flex justify-center" v-if="$parent.$parent.loadingFolders">
                        <spinner :size="14" />
                    </div>

                    <!-- Table -->
                    <div class="mb-2 border p-3 rounded">
                        <div class="grid grid-cols-4 gap-2">
                            <input-rule v-model="filters.wca" type="text" :label="$tt('WCA')" :display-errors="false" :label-inline="false" />
                            <input-rule v-if="!shop" v-model="filters.shop" type="text" :label="$tt('Magasin')" :display-errors="false" :label-inline="false" />
                            <input-rule v-model="filters.brand" type="text" :label="$tt('Marque')" :display-errors="false" :label-inline="false" />
                            <input-rule v-model="filters.model" type="text" :label="$tt('Modele')" :display-errors="false" :label-inline="false" />
                        </div>
                        <div class="text-right text-xs font-thin my-2">{{ lines.length }} {{ lines.length > 1 ? $tt('lignes') : $tt('ligne') }}</div>
                    </div>
                    <div class="relative overflow-auto border border-slate-100 max-h-[75vh]">
                        <table class="w-full text-left text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th scope="col" class="p-3">
                                        <input type="checkbox" v-model="selectAllCheckbox" />
                                    </th>
                                    <th scope="col" class="p-3 overflow-hidden whitespace-nowrap">{{ $tt('Accord Du') }}</th>
                                    <th scope="col" class="p-3 overflow-hidden whitespace-nowrap">{{ $tt('Numéro') }}</th>
                                    <th scope="col" class="p-3 overflow-hidden whitespace-nowrap">{{ $tt('Magasin') }}</th>
                                    <th scope="col" class="p-3 overflow-hidden whitespace-nowrap">{{ $tt('Modèle') }}</th>
                                    <th scope="col" class="p-3 overflow-hidden whitespace-nowrap">{{ $tt('Marque') }}</th>
                                    <th scope="col" v-if="canEditAvoirAmount" class="p-3 overflow-hidden whitespace-nowrap">{{ $tt('HT') }}</th>
                                    <th scope="col" v-if="canEditAvoirAmount" class="p-3 overflow-hidden whitespace-nowrap">{{ $tt('DEEE') }}</th>
                                    <th scope="col" class="p-3 overflow-hidden whitespace-nowrap">{{ $tt('Destruction') }}</th>
                                </tr>
                            </thead>
                            <tbody class="max-h-[50vh]">
                                <tr
                                @click="folder.added = !folder.added"
                                v-for="(folder, folderIndex) in lines"
                                :key="'datatable-folder-' + _uid + '-' + folderIndex"
                                class="duration-100 border-b cursor-pointer"
                                :class="folder.added ? 'bg-green-100' : 'bg-white hover:bg-slate-50'"
                                >
                                    <td class="px-3 py-1"><input type="checkbox" v-model="folder.added" /></td>
                                    <td class="px-3 py-1 text-xs overflow-hidden whitespace-nowrap">{{folder.date_accept | momentDate}}</td>
                                    <td class="px-3 py-1 text-xs overflow-hidden whitespace-nowrap">{{folder.num_dsc}}</td>
                                    <td class="px-3 py-1 text-xs overflow-hidden whitespace-nowrap">{{folder.magasin}}</td>
                                    <td class="px-3 py-1 text-xs overflow-hidden whitespace-nowrap">{{folder.Modele}}</td>
                                    <td class="px-3 py-1 text-xs overflow-hidden whitespace-nowrap">{{folder.Marque}}</td>
                                    <td v-if="canEditAvoirAmount" class="px-3 py-1 min-w-[90px] max-w-[90px] text-xs">
                                        <input-rule
                                        :options="{displayCurrency: false}"
                                        type="price"
                                        custom="text-xs"
                                        v-model="folder.remb_ht"
                                        @input="updateAmountHT(folder)"
                                        :label-inline="false"
                                        />
                                    </td>
                                    <td v-if="canEditAvoirAmount" class="px-3 py-1 min-w-[90px] max-w-[90px] text-xs">
                                        <input-rule
                                        :options="{displayCurrency: false}"
                                        type="price"
                                        custom="text-xs"
                                        v-model="folder.montant_deee"
                                        @input="updateAmountDeee(folder)"
                                        :label-inline="false"
                                        />
                                    </td>
                                    <td class="px-3 py-1 text-xs text-center overflow-hidden whitespace-nowrap">
                                        <a v-if="folder.url_bondestruc" class="text-blue-500 underline" target="_blank" @click.stop="" :href="folder.url_bondestruc">
                                            <i class="fas fa-eye mr-0.5"></i> {{ $tt('Visualiser') }}
                                        </a>
                                        <div class="text-center" v-if="!folder.url_bondestruc">
                                            <i class="fas fa-times text-red-500"></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- Totals price -->
                    <div v-if="addedFolders.length > 0" class="my-4">
                        <div class="text-right text-sm my-2 font-light">{{ addedFolders.length }} {{ addedFolders.length > 1 ? $tt('lignes sélectionnées') : $tt('ligne sélectionnée') }}</div>
                        <div class="grid grid-cols-3 gap-5">
                            <div class="col-span-2 border rounded p-4 text-sm">
                                <div class="flex items-center justify-between">
                                    <!-- TOTAL HT DEEE -->
                                    <div class="flex items-center w-1/2" :class="!canEditAvoirAmount ? 'mb-4 gap-2' : ' gap-1 mb-2'">
                                        <div class="-mt-1">{{ $tt('Total HT DEEE') }}:</div>
                                        <template v-if="!canEditAvoirAmount">
                                            <input-rule type="price" :label-inline="false" v-model="totalsOverwrite.deee" />
                                        </template>
                                        <template v-else>
                                            <div class="-mt-1">{{ endTotals.deeeHt }} €</div>
                                        </template>
                                    </div>
                                    <!-- PRODUCT HT -->
                                    <div class="flex items-center w-1/2" :class="!canEditAvoirAmount ? 'mb-4 gap-2' : 'gap-1 mb-2'">
                                        <div class="-mt-1">{{ $tt('Total HT produit') }}:</div>
                                        <template v-if="!canEditAvoirAmount">
                                            <input-rule type="price" :label-inline="false" v-model="totalsOverwrite.productHt" />
                                        </template>
                                        <template v-else>
                                            <div class="-mt-1">{{ endTotals.productHt }} €</div>
                                        </template>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between">
                                    <!-- TOTAL HT -->
                                    <div class="w-1/2">{{ $tt('Total HT') }}: {{ !isNaN(endTotals.totalHt) ? endTotals.totalHt : '...'  }} €</div>
                                    <!-- TVA -->
                                    <div class="w-1/2 flex items-center gap-2">
                                        {{ $tt('TVA') }}: 
                                        <select class="text-xs text-center w-min border text-sm rounded-0 block w-full p-1.5 focus:outline-none focus:border-gray-400 rounded-lg bg-gray-50" v-model="tvaPercent">
                                            <option value="20">20%</option>
                                            <option value="5.5">5.5%</option>
                                            <option value="0">0%</option>
                                        </select>
                                        {{ !isNaN(endTotals.totalTva) ? endTotals.totalTva : '...' }} €
                                    </div>
                                </div>
                                <!-- TOTAL TTC -->
                                <div class="border-t pt-2 mt-2 font-semibold text-base">{{ $tt('Total TTC') }}: {{ !isNaN(endTotals.totalTtc) ? endTotals.totalTtc : '...' }} €</div>
                            </div>
                            <div class="flex flex-col items-center justify-center bg-slate-100 rounded p-2">
                                <input-rule v-model="avoirFile" type="file" :label="$tt('Ajouter un avoir (PDF)')" class="w-full" :class="avoirAmountRequired ? 'mb-4' : 'mb-2'" :label-inline="false" :display-errors="false" />
                                <input-rule v-if="avoirAmountRequired" v-model="avoirNumber" type="text" :label="$tt('Numéro d\'avoir')" class="w-full mb-2" :label-inline="false" :display-errors="false" />
                            </div>
                        </div>
                        <div class="flex justify-center mt-5 gap-4">
                            <button @click="csvExport" class="px-4 py-2 rounded-lg bg-slate-500 hover:bg-slate-600 duration-200 text-sm text-white">
                                <i class="fas fa-download mt-1 mr-1"></i> {{ $tt('Exporter') }}
                            </button>
                            <button :disabled="loadingGeneration" @click="generate" class="px-6 py-3 rounded-lg bg-emerald-600 hover:bg-emerald-700 duration-200 font-bold text-white">
                                <template v-if="loadingGeneration">
                                    <spinner :size="10" />
                                </template>
                                <template v-if="!loadingGeneration">
                                    <i class="fas fa-check mt-1 mr-1"></i> {{ $tt('Générer') }}
                                </template>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    props: {
        account: {},
        selectedSign: {},
        shop: {},
        folders: {}
    },

    watch: {
        folders(value) {
            this.lines = value
        },

        selectAllCheckbox(value) {
            if (value) {
                this.lines.map((line) => {
                    line.added = true
                    return line
                })
            } else {
                if (this.addedFolders.length == this.lines.length) {
                    this.lines.map((line) => {
                        line.added = false
                        return line
                    })  
                }
            }
        },

        filters: {
            deep: true,
            handler: function() {
                this.applyFiltersOnLines()
            }
        },

        totalsOverwrite: {
            deep: true,
            handler: function(value) {
                this.setEndTotals(parseFloat(value.productHt), parseFloat(value.deee))
            }
        },

        addedFolders: {
            deep: true,
            handler: function() {
                let productHT = 0
                this.addedFolders.forEach((folder) => {
                    productHT += parseFloat(folder.remb_ht)
                })
                productHT.toFixed(2)

                let deeeHt = 0
                this.addedFolders.forEach((folder) => {
                    deeeHt += parseFloat(folder.montant_deee)
                })
                deeeHt.toFixed(2)

                if (this.canEditAvoirAmount) {   
                    this.setEndTotals(productHT, deeeHt)
                }
            }
        },

        tvaPercent: function() {
            this.setEndTotals(parseFloat(this.endTotals.productHt), parseFloat(this.endTotals.deeeHt))
        }
    },

    data() {
        return {
            selectAllCheckbox: false,
            lines: [],

            // Filters
            filters: {
                wca: null,
                brand: null,
                model: null,
                shop: null
            },

            // Generation
            avoirNumber: null,
            avoirFile: null,

            // Price
            tvaPercent: '20',
            totalsOverwrite: {
                totalHt: null,
                deee: null
            },
            endTotals: {},
            editTotals: false,

            // Empty prices
            emptyPrices: null,

            loadingGeneration: false,
        }
    },

    computed: {
        addedFolders() {
            return this.folders.filter((line) => line.added == true)
        },

        calcTvaPercent() {
            return this.tvaPercent / 100
        },

        canEditAvoirAmount() {
            if (this.account && this.account.info && this.account.info[0] && this.account.info[0].gestion_prix == '0') {
                return false
            }
            return true
        },

        avoirAmountRequired() {
            if (this.account && this.account.info && this.account.info[0] && this.account.info[0].num_avoir_oblig == '1') {
                return true
            }
            return false
        },

        avoirFileRequired() {
            if (this.account && this.account.info && this.account.info[0] && this.account.info[0].doc_avoir_oblig == '1') {
                return true
            }
            return false
        }
    },

    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        }
    },

    methods: {
        updateAmountHT(folder) {
            this.lines.map((line) => {
                if (line['Modele'] == folder['Modele'] && line.id != folder.id) {
                    line.remb_ht = folder.remb_ht
                }
                return line
            })
        },

        updateAmountDeee(folder) {
            this.line = this.lines.map((line) => {
                if (line['Modele'] == folder['Modele'] && line.id != folder.id) {
                    line.montant_deee = folder.montant_deee
                }
                return line
            })
        },

        applyFiltersOnLines() {
            let endFolders = [...this.folders]
            if (this.filters.wca) {
                endFolders = endFolders.filter((line) => {
                    var regex = new RegExp(".*" + this.filters.wca + ".*");
                    var matchesRegex = regex.test(line.num_dsc);
                    if (matchesRegex) {
                        return true
                    }
                    return false
                })
            }
            if (this.filters.model) {
                endFolders = endFolders.filter((line) => line['Modele'].toLowerCase().startsWith(this.filters.model.toLowerCase()))
            }
            if (this.filters.brand) {
                endFolders = endFolders.filter((line) => line['Marque'].toLowerCase().startsWith(this.filters.brand.toLowerCase()))
            }
            if (this.filters.shop && this.filters.shop) {
                endFolders = endFolders.filter((line) => line.magasin.toLowerCase().startsWith(this.filters.shop.toLowerCase()))
            }
            this.lines = endFolders
        },

        setEndTotals(productHT, deeeHt) {
            if (productHT != null && deeeHt != null) {
                this.endTotals = JSON.parse(JSON.stringify({
                    productHt: productHT.toFixed(2),
                    deeeHt: deeeHt.toFixed(2),
                    totalHt: (productHT + deeeHt).toFixed(2),
                    totalTva: ((productHT + deeeHt) * this.calcTvaPercent).toFixed(2),
                    totalTtc:  (parseFloat((productHT + deeeHt)) + parseFloat(((productHT + deeeHt) * this.calcTvaPercent))).toFixed(2)
                }))
            }
        },

        parseDate(date) {
            return moment(date).format('DD/MM/YYYY')
        },

        csvExport() {
            let rows = [["ID_USER", "N° De Sol Com.", "WCA", "Modele", "Marque", "Code EAN", "Code Magasin", "Ville Magasin", "Numéro Série", "IMEI", "HT Accordé", "Montant DEE", "Date demande", "Date accord", "Date d'achat", "Centrale d'achat", "Magasin", "N. Note de crédit", "Url Bon destruction", "Ref Interne"]];

            this.addedFolders.forEach((folder) => {
                rows.push([
                    folder.id_grossiste, folder.num_doc_notecred, folder.num_dsc, folder['Modele'], folder['Marque'], folder['Ean'], folder['code_magasin'], folder['magasin_ville'], folder.num_serie, folder.imei, folder.remb_ht, folder.montant_deee, this.parseDate(folder.date_demande), this.parseDate(folder.date_accept), this.parseDate(folder.date_ha), folder.centrale_ha, folder.magasin, folder.num_doc_notecred, folder.url_bondestruc, folder.ref_interne
                ])
            })

            let csvContent = "data:text/csv;charset=utf-8,";

            rows.forEach(function(rowArray) {
                let row = rowArray.join(";");
                csvContent += row + "\r\n";
            });

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "export_avoir.csv");
            document.body.appendChild(link);

            link.click();
        },

        async generate() {
            if (!this.endTotals.totalHt) {
                this.$toast.error(this.$tt('Le montant HT est obligatoire'))
                return
            }

            if (this.avoirAmountRequired && !this.avoirNumber) {
                this.$toast.error(this.$tt("Le numéro d'avoir est obligatoire"))
                return
            }

            if (this.avoirFileRequired && (!this.avoirFile || !this.avoirFile.url)) {
                this.$toast.error(this.$tt("Un avoir au format PDF est obligatoire"))
                return
            }

            // endObj
            if (this.canEditAvoirAmount) {
                this.emptyPrices = this.addedFolders.filter((item) => item.remb_ht == 0)
                if (this.emptyPrices.length > 0) {
                    this.$modal.show('modal-fill-empty-prices')
                    return
                }
            }

            // Display loader
            this.loadingGeneration = true

            let endObj = {
                url_avoir: this.avoirFile && this.avoirFile.url ? this.avoirFile.url : '',
                montant_ht: this.endTotals.totalHt,
                taux_tva: this.tvaPercent,
                ref_client: this.avoirNumber ? this.avoirNumber : 'Avoir_Districloud',
                listdsc: this.addedFolders.map(function(item) { return item["id"] }).join('-'),
                params: JSON.stringify(this.addedFolders.map(function(item) { return {
                    id_app: item.id_appareil,
                    puht: item.remb_ht,
                    ht_remb: item.remb_ht,
                    deee: item.montant_deee,
                    coeff: 100,
                    id_centrale_ha: item.id_centrale_ha
                }}))
            }

            let response = await this.$request.post('A5141setavoir', endObj, 'districloud_no_action')
            this.errors = []
            if (response.data.posts.error != 0) {
                this.errors.push(response.data.posts.error)
                this.$toast.error(response.data.posts.error)
            } else {
                this.$toast.success('Avoir généré')
                this.$parent.$parent.refreshAll()
            }

            this.loadingGeneration = false
        },

        async searchEmptyPrices() {
            let hasResult = false
            let endArray = []

            this.emptyPrices.forEach((item) => {
                if (!endArray.find((itemArray) => itemArray.id_appareil == item.id_appareil)) {
                    endArray.push(item)
                }
            })
            for (let key in endArray) {
                let response = await this.$request.post('get_price', {
                    action: 'api_bc',
                    id_case: endArray[key].id
                }, 'districloud_preprod_grossiste')
                this.emptyPrices.find((emptyPrice) => emptyPrice.id_appareil == endArray[key].id_appareil).remb_ht = response.data.posts.post
                if (response.data.posts.post > 0) {
                    hasResult = true
                }
            }
            if (!hasResult) {
                this.$toast.error(this.$tt('Aucun prix trouvé'))
            }
        }
    },
    
    mounted () {
        this.folders.forEach((folder, index) => {
            if (!folder.montant_deee) {
                this.folders[index].montant_deee = 0
            }
            if (!folder.remb_ht) {
                this.folders[index].remb_ht = 0
            }
        })
        this.lines = this.folders
    },
}
</script>

<style>
div[size="A4"] {
  background: white;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.2);
}
</style>
