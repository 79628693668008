<template>
    <div>
        <!-- Modal add product -->
        <!-- <modal name="modal-add-product" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">Ajouter un produit</h2>
                    <button @click="$modal.hide('modal-add-product')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <add-product-form @added="addedProduct" />
                </div>
            </div>
        </modal> -->

        <div>
            <button v-if="!categs.every((item) => item.selected == true)" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 text-xs rounded mb-2" @click="selectAllItems">{{$tt('Tout cocher')}}</button>
            <button v-if="!categs.find((item) => item.selected == false)" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 text-xs rounded mb-2" @click="unselectAllItems">{{$tt('Tout décocher')}}</button>
        </div>

        <!-- Component -->
        <div class="p-2 mt-2" v-if="loadingGetData"><spinner :size="10" /></div>
        <div v-if="!loadingGetData">
            <div class="py-2 cursor-pointer" v-for="(categ, categIndex) in categs" :key="'menu-categ-' + categIndex">
                <!-- Categ -->
                <div class="flex gap-2 items-center">
                    <div @click="selectCateg(categ)" class="w-[11px] h-[11px] rounded-full" :class="getCategBtnColor(categ)"></div>
                    <div @click="openCateg(categ)" class="hover:font-bold hover:underline duration-200">{{ categ.fr }}</div>
                </div>
                <div class="border-l-2" v-if="categ.open">
                    <div class="p-2 mt-2" v-if="categ.loading"><spinner :size="10" /></div>
                    <!-- Marque -->
                    <div class="ml-4 cursor-pointer mt-2" v-for="(marque, marqueIndex) in categ.marques" :key="'menu-categ-' + categIndex + '-' + marqueIndex">
                        <div class="flex items-center gap-2">
                            <div @click.stop="selectMarque(marque)" class="w-[11px] h-[11px] rounded-full" :class="getMarqueBtnColor(marque)"></div>
                            <div @click.stop="openMarque(categ, marque)" class="hover:font-bold hover:underline duration-200">{{ marque.marque }}</div>
                        </div>
                        <!-- Appareils -->
                        <div v-if="marque.open">
                            <div class="p-2 mt-2" v-if="marque.loading"><spinner :size="10" /></div>
                            <div
                            class="border-l-2 pl-2 ml-1 hover:bg-slate-50 duration-200 cursor-pointer"
                            :class="appIndex < marque.appareils.length - 1 ? 'border-b' : ''"
                            v-for="(app, appIndex) in marque.appareils"
                            :key="'menu-categ-' + categIndex + '-' + marqueIndex + '-' + appIndex"
                            >
                                <div @click.stop="selectAppareil(app)" class="flex items-center gap-2 py-2">
                                    <div class="w-[11px] h-[11px] rounded-full" :class="app.selected == 1 ? 'bg-green-500' : 'bg-slate-300 hover:bg-slate-400 duration-200'"></div>
                                    <div class="text-sm">
                                        <div class="font-semibold">{{ app['Modele'] }}</div>
                                        <div class="text-xs font-thin mb-0.5">{{ app['Ean'] }}</div>
                                        <!-- <span v-if="appareil.procedure" class="bg-green-200 rounded text-sm px-2 py-0.5 text-center"><i class="far fa-face-smile mr-0.5"></i> Procédure configurée</span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            withoutProc: {default: false},
            centrales: {},
        },
        watch: {
            categs: {
                deep: true,
                handler() {
                    this.$emit('update', this.formatData())
                }
            }
        },
        data() {
            return {
                categs: [],
                loadingGetData: false
            }
        },
        methods: {
            formatData() {
                let endData = {
                    categories: [],
                    marques: [],
                    appareils: []
                }
                for (let indexCateg in this.categs) {
                    let categ = this.categs[indexCateg]
                    if (categ.selected) {
                        endData.categories.push(categ.id)
                        continue
                    }
                    for (let marqueIndex in categ.marques) {
                        let marque = categ.marques[marqueIndex]
                        if (marque.selected) {
                            endData.marques.push(categ.id + '-' + marque.marque)
                            continue
                        }
                        for (let appareilIndex in marque.appareils) {
                            let appareil = marque.appareils[appareilIndex]
                            if (appareil.selected) {
                                endData.appareils.push(appareil.id)
                                continue
                            }
                        }
                    }
                }
                return endData
            },

            async getCategs() {
                this.loadingGetData = true
                let response = await this.$request.post('procedure.all-categ', {
                    without_proc: this.withoutProc,
                    centrales: JSON.stringify(this.centrales)
                })
                this.categs = response.data.posts.post.map((item) => ({...item, marques: [], selected: false, open: false}))
                this.loadingGetData = false
            },

            async openCateg(categ) {
                if (categ.open) {
                    categ.open = false
                    return
                }
                if (categ.marques && categ.marques.length > 0) {
                    categ.open = true
                    return
                }
                categ.open = true
                categ.loading = true
                let response = await this.$request.post('procedure.marques-in-categ', {
                    categ_id: categ.id,
                    without_proc: this.withoutProc,
                    centrales: JSON.stringify(this.centrales)
                })
                response.data.posts.post.forEach((item) => {
                    categ.marques.push({
                        marque: item,
                        appareils: [],
                        selected: categ.selected,
                        open: false
                    })
                })
                categ.loading = false
            },

            async openMarque(categ, marque) {
                if (marque.open) {
                    marque.open = false
                    return
                }
                if (marque.appareils && marque.appareils.length > 0) {
                    marque.open = true
                    return
                }
                marque.open = true
                marque.loading = true
                let response = await this.$request.post('procedure.apps-in-marque', {
                    categ_id: categ.id,
                    marque: marque.marque,
                    without_proc: this.withoutProc,
                    centrales: JSON.stringify(this.centrales)
                })
                marque.appareils = response.data.posts.post.map((item) => ({...item, selected: marque.selected}))
                marque.loading = false
            },

            checkSelected() {
                this.categs.forEach((categorie) => {
                    categorie.marques.forEach((marque) => {
                        // Unselected marque in categorie
                        if (marque.appareils.find((appareil) => !appareil.selected)) {
                            marque.selected = 0
                        }

                        // All appareils are selected in marque
                        if (marque.appareils.filter((appareil) => appareil.selected).length == marque.appareils.length && marque.appareils.length > 0) {
                            marque.selected = 1
                        }
                    })
                    if (categorie.marques.length > 0) {
                        // Unselected appareil in categorie
                        if (categorie.marques.find((marques) => !marques.selected)) {
                            categorie.selected = 0
                        }
                        // All categories are selected in categorie
                        if (categorie.marques.filter((categorie) => categorie.selected).length == categorie.marques.length) {
                            categorie.selected = 1
                        }
                    }
                })
                this.$nextTick(() => {
                    this.$emit('item-changed')
                })
            },

            selectAppareil(appareil) {
                appareil.selected = !appareil.selected
                this.checkSelected()
            },

            selectMarque(marque) {
                marque.selected = !marque.selected

                // Select childs
                marque.appareils.forEach((appareil) => {
                    appareil.selected = marque.selected
                })

                this.checkSelected()
            },

            getMarqueBtnColor(marque) {
                if (marque.selected == 1) {
                    return 'bg-green-500'
                }
                let nbAppSelected = marque.appareils.filter((app) => app.selected == true).length
                if (nbAppSelected > 0 && nbAppSelected < marque.appareils.length) {
                    return 'bg-yellow-500'
                }
                return 'bg-slate-300 hover:bg-slate-400 duration-200'
            },

            getCategBtnColor(categ) {
                if (categ.selected == 1) {
                    return 'bg-green-500'
                }
                let hasAppSelected = false
                categ.marques.forEach((categ) => {
                    let appIsSelected = categ.appareils.find((item) => item.selected == 1)
                    if (appIsSelected) {
                        hasAppSelected = true
                    }
                })
                if (hasAppSelected == true) {
                    return 'bg-yellow-500'
                }
                return 'bg-slate-300 hover:bg-slate-400 duration-200'
            },

            selectCateg(categ) {
                categ.selected = !categ.selected

                // Select childs
                categ.marques.forEach((marque) => {
                    marque.selected = categ.selected
                    marque.appareils.forEach((appareil) => {
                        appareil.selected = marque.selected
                    })
                })

                this.checkSelected()
            },

            selectAllItems() {
                this.categs.forEach((categ) => {
                    categ.selected = true
                    categ.marques.forEach((marque) => {
                        marque.selected = true
                        marque.appareils.forEach((app) => {
                            app.selected = true
                        })
                    })
                })
                this.checkSelected()
            },

            unselectAllItems() {
                this.categs.forEach((categ) => {
                    categ.selected = false
                    categ.marques.forEach((marque) => {
                        marque.selected = false
                        marque.appareils.forEach((app) => {
                            app.selected = false
                        })
                    })
                })
                this.checkSelected()
            },
        },
        mounted () {
            this.getCategs()

            this.$eventHub.$on('refresh-product-menu', () => {
                this.getCategs()
            })
        },
    }
</script>

<style lang="scss" scoped>

</style>