<template>
    <div>
        <!-- Modal edit add codepannes -->
        <modal :name="'modal-edit-add-codepannes' + _uid" height="auto" width="80%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b">
                    <div>
                        <h2 class="text-xl font-semibold">{{ categ.libelle }}</h2>
                        <h3 class="text-sm font-light">{{ $tt('Gestion des codes pannes - Hors garantie') }}</h3>
                    </div>
                    <button @click="$modal.hide('modal-edit-add-codepannes' + _uid)" class="text-2xl">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <!-- <div class="bg-slate-100 p-4 rounded my-6 flex items-center gap-3 text-slate-500">
                        <i class="fas fa-info-circle text-lg"></i>
                        <div class="text-sm">{{ $tt('Les codes sont obligatoires pour la gestion sous garantie, mais optionnels pour la gestion hors garantie') }}</div>
                    </div> -->
                    <div class="flex items-center">
                        <div @click="typeCode = 1" :class="typeCode == 1 ? 'bg-slate-300 font-bold' : 'bg-slate-100 hover:bg-slate-200 duration-200'" class="cursor-pointer px-4 py-2 border-t border-l rounded-tl">{{ $tt('Codes condition') }}</div>
                        <div @click="typeCode = 2" :class="typeCode == 2 ? 'bg-slate-300 font-bold' : 'bg-slate-100 hover:bg-slate-200 duration-200'" class="cursor-pointer px-4 py-2 border-t">{{ $tt('Codes symptome') }}</div>
                        <div @click="typeCode = 3" :class="typeCode == 3 ? 'bg-slate-300 font-bold' : 'bg-slate-100 hover:bg-slate-200 duration-200'" class="cursor-pointer px-4 py-2 border-t">{{ $tt('Codes section') }}</div>
                        <div @click="typeCode = 4" :class="typeCode == 4 ? 'bg-slate-300 font-bold' : 'bg-slate-100 hover:bg-slate-200 duration-200'" class="cursor-pointer px-4 py-2 border-t">{{ $tt('Codes défaut') }}</div>
                        <div @click="typeCode = 5" :class="typeCode == 5 ? 'bg-slate-300 font-bold' : 'bg-slate-100 hover:bg-slate-200 duration-200'" class="cursor-pointer px-4 py-2 border-t border-r rounded-tr">{{ $tt('Codes réparation') }}</div>
                    </div>
                    <div class="border p-4 rounded rounded-tl-none">
                        <div class="grid grid-cols-2 gap-4">
                            <div>
                                <input-rule type="text" v-model="searchCodeInp" @input="searchCode" :placeholder="$tt('Rechercher un code')" :label-inline="false" class="mb-2" />
                                <div class="border max-h-[600px] overflow-y-auto">
                                    <div class="p-2" v-if="loadingGetCode">
                                        <spinner :size="10" />
                                    </div>
                                    <div v-for="(code, codeIndex) in unusedCodes" :key="'code-' + codeIndex" class="border-b p-2 cursor-copy hover:bg-green-100 duration-200 prevent-select" @click="addCode(code)">
                                        <div class="flex items-center gap-2">
                                            <div class="bg-slate-200 px-4 py-1 rounded text-xs font-bold">{{ code.code }}</div>
                                            <div>{{ code.fr }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="max-h-[600px] overflow-y-auto">
                                <div v-for="(code, codeIndex) in selectedCodes" :key="'selected-code-' + codeIndex" class="prevent-select border-b p-2 cursor-w-resize bg-green-100 hover:bg-red-100 duration-200 focus:outline-none" @click="selectedCodes.splice(codeIndex, 1); saveSelectedCodes()">
                                    <div class="flex items-center gap-2">
                                        <div class="bg-slate-200 px-4 py-1 rounded text-xs font-bold">{{ code.code }}</div>
                                        <div>{{ code.fr }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <button class="bg-blue-800 hover:bg-blue-900 duration-200 text-white rounded text-xs p-2" @click.stop="getCodes(); $modal.show('modal-edit-add-codepannes' + _uid)"><i class="fas fa-list"></i> {{ $tt('Code pannes') }}</button>
    </div>
</template>

<script>
export default {
    props: {
        categ: {
            required: true
        },
        initModal: {default: false}
    },
    watch: {
        typeCode() {
            this.getCodes()
        },
    },
    data() {
        return {
            defaultCodes: [],
            codes: [],
            typeCode: 1,

            selectedCodes: [],
            searchCodeInp: null,

            loadingGetCode: false,
        }
    },
    computed: {
        unusedCodes() {
            return this.codes.filter((item) => !this.selectedCodes.find((sCode) => sCode.code == item.code))
        }
    },
    methods: {
        // categorie_codes
        async getCodes() {
            this.codes = []
            this.defaultCodes = []
            this.selectedCodes = []
            this.searchCodeInp = null
            this.loadingGetCode = true
            let response = await this.$request.post('reparateur.get-list-codes', {
                type_code: this.typeCode,
                categ_id: this.categ.id
            })
            this.loadingGetCode = false
            this.defaultCodes = response.data.posts.post.not_selected
            this.codes = response.data.posts.post.not_selected
            this.selectedCodes = response.data.posts.post.selected
        },

        async saveSelectedCodes() {
            await this.$request.post('reparateur.save-list-codes', {
                type_code: this.typeCode,
                codes: JSON.stringify(this.selectedCodes.map((item) => item.code)),
                categ_id: this.categ.id
            })
        },

        searchCode() {
            if (!this.searchCodeInp) {
                this.codes = this.defaultCodes
            } else {
                this.codes = this.defaultCodes.filter((item) => item.fr.toLowerCase().includes(this.searchCodeInp.toLowerCase()))
            }
        },

        addCode(code) {
            this.selectedCodes.push(code)
            if (this.unusedCodes.length <= 0) {
                this.searchCodeInp = null
            }
            this.saveSelectedCodes()
        }
    },
}
</script>

<style>
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>