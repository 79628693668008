<template>
    <div>
        <modal name="modal-add-url" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">Ajouter une url</h2>
                    <button @click="$modal.hide('modal-add-url')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <input-rule v-model="newUrlInp" type="text" label="Url" :label-inline="false" />
                <button :disabled="loadingModal" @click="addUrl" class="mt-3 bg-green-500 hover:bg-green-600 duration-200 text-white rounded px-4 py-2 text-sm">
                    <template v-if="!loadingModal">
                        Ajouter <i class="fas fa-check ml-1"></i>
                    </template>
                    <spinner :size="10" v-if="loadingModal" />
                </button>
            </div>
        </modal>

        <modal name="modal-confirm-delete" height="auto" :scrollable="true">
            <div v-if="selectedUrl" class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">Suppression</h2>
                    <button @click="$modal.hide('modal-display-error')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div class="text-lg">Souhaitez-vous réellement supprimer {{selectedUrl.url}} ?</div>
                <button :disabled="loadingModal" @click="deleteUrl" class="bg-red-400 hover:bg-red-600 text-white duration-200 cursor-pointer rounded px-4 py-2 text-sm mt-2">
                    <spinner :size="10" v-if="loadingModal" />
                    <template v-if="!loadingModal">
                        <i class="fas fa-check mr-1"></i> Confirmer
                    </template>
                </button>
            </div>
        </modal>

        <modal name="modal-display-error" height="auto" width="90%" :scrollable="true">
            <div v-if="selectedError" class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">Erreur</h2>
                    <button @click="$modal.hide('modal-display-error')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div v-html="selectedError"></div>
            </div>
        </modal>

        <app-template>
            <div class="p-4 rounded shadow bg-white">
                <div class="grid grid-cols-6">
                    <div class="col-span-2 border-r h-[90vh] ">
                        <button @click="newUrlInp = ''; $modal.show('modal-add-url')" class="bg-slate-100 hover:bg-slate-200 cursor-pointer duration-200 rounded text-xs font-light px-4 py-2 mb-2"><i class="fas fa-plus mr-1"></i> Ajouter une url</button>
                        <div @click="selectUrl(url)" v-for="(url, urlIndex) in urls" :key="'url-' + urlIndex" class="flex items-center justify-between gap-2 border-b pb-2 pt-2 p-2 pr-4 hover:bg-slate-100 duration-200 cursor-pointer" :class="selectedUrl && selectedUrl.id == url.id ? 'bg-slate-100' : ''">
                            <div>
                                <div class="text-sm">{{ url.url }}</div>
                                <div class="mt-0.5 text-xs font-thin">{{ url.last_test | momentDateTime }}</div>
                            </div>
                            <div class="h-[8px] w-[8px] rounded-full" :class="url.error ? 'bg-red-500' : 'bg-green-500'"></div>
                        </div>
                    </div>
                    <div class="col-span-4 p-4">
                        <template v-if="selectedUrl">
                            <div class="flex items-center justify-between">
                                <h1 class="text-2xl font-bold">{{ selectedUrl.url }}</h1>
                                <button @click="$modal.show('modal-confirm-delete')" class="bg-red-100 hover:bg-red-200 cursor-pointer duration-200 text-red-500 p-2 px-3 rounded"><i class="fas fa-trash"></i></button>
                            </div>

                            <div class="py-10" v-if="loadingGetTest">
                                <div class="flex justify-center">
                                    <spinner :size="12" />
                                </div>
                                <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
                            </div>
                            <template v-if="!loadingGetTest">
                                <div class="mt-4 pt-4 border-t">
                                    <button :disabled="loadingTestUrl" @click="testUrl" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 rounded text-sm">
                                        <template v-if="!loadingTestUrl">
                                            Tester l'url
                                        </template>
                                        <spinner v-if="loadingTestUrl" :size="10" />
                                    </button>
                                </div>
    
                                <div class="mt-4 border-t h-[70vh] overflow-y-auto">
                                    <div class="flex items-center justify-between gap-2 border-b p-2" :class="test.status != 200 ? 'bg-red-200' : 'bg-white'" v-for="(test, testIndex) in tests" :key="'test-' + testIndex">
                                        <div class="flex items-center gap-3">
                                            <div class="text-white rounded text-sm px-2 py-1 font-light" :class="test.status != 200 ? 'bg-red-400' : 'bg-green-500'">{{ test.status }}</div>
                                            <div class="text-sm font-light">{{ test.created_at | momentDateTime }}</div>
                                        </div>
                                        <button v-if="test.status != 200" @click="selectedError = test.result; $modal.show('modal-display-error')" class="px-4 py-2 text-xs bg-red-500 hover:bg-red-600 duration-200 rounded text-white"><i class="fas fa-exclamation-triangle mr-1"></i> Consulter l'erreur</button>
                                    </div>
                                </div>
                            </template>
                        </template> 
                    </div>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    data() {
        return {
            newUrlInp: null,
            urls: [],
            tests: [],
            selectedUrl: null,
            selectedError: null,

            loadingModal: false,
            loadingTestUrl: false,
            loadingGetTest: false,
        }
    },
    filters: {
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        async addUrl() {
            this.loadingModal = true
            let response = await this.$request.post('monitoring.add-url', {
                url: this.newUrlInp
            })
            if (response.data.posts.post == 1) {
                await this.getUrls()
                this.$modal.hide('modal-add-url')
                this.$toast.success('Url ajoutée')
            }
            this.loadingModal = false
        },

        async getUrls() {
            let response = await this.$request.post('monitoring.get-urls')
            this.urls = response.data.posts.post
            this.selectUrl(this.urls[0])
        },

        selectUrl(url) {
            this.selectedUrl = url
            this.tests = []
            this.loadingGetTest = false
            this.getTests()
        },

        async testUrl() {
            if (this.selectedUrl) {
                this.loadingTestUrl = true
                await this.$request.post('monitoring.test-url', {
                    url_id: this.selectedUrl.id
                })
                await this.getTests()
                this.loadingTestUrl = false
            }
        },

        async getTests() {
            if (this.selectedUrl) {
                this.loadingGetTest = true
                let response = await this.$request.post('monitoring.get-url-tests', {
                    url_id: this.selectedUrl.id
                })
                this.tests = response.data.posts.post
                this.loadingGetTest = false
            }
        },

        async deleteUrl() {
            this.loadingModal = true
            await this.$request.post('monitoring.delete-url', {
                url_id: this.selectedUrl.id
            })
            await this.getUrls()
            this.$modal.hide('modal-confirm-delete')
            this.loadingModal = false
        }
    },
    mounted () {
        this.getUrls()
    },
}
</script>

<style lang="scss" scoped>

</style>