<template>
    <div>
        <app-template>
            <div class="bg-white p-10 rounded shadow">
                <crud-content
                id="permission"
                :label="{singular: 'une permission', plural: 'des permissions'}"
                ref-keyword="keyword"
                :routes="{
                    list: {key: 'admin.permissions.list'},
                    editAdd: {operation: 'admin.permissions.edit-add', form: {'keyword': 'keyword', 'description': 'description'}},
                    delete: {key: 'admin.permissions.delete'}
                }"
                :table="[
                    {label: 'Mot clé', key: 'keyword', type: 'text'},
                    {label: 'Description', key: 'description', type: 'text'},
                ]"
                :filters="[
                    {label: 'Mot clé', key: 'keyword', type: 'text', value: null},
                ]"
                >
                    <template v-slot:modal-edit-add="props">
                        <h1 class="text-xl font-bold mb-4">Ajouter une permission</h1>
                        <input-rule v-model="props.modalForm.keyword" type="text" label="Mot clé" class="mb-4" rules="required" :label-inline="false" :display-errors="false" />
                        <input-rule v-model="props.modalForm.description" type="textarea" label="Description" class="mb-4" rules="required" :label-inline="false" :display-errors="false" />
                        <spinner v-if="props.loadingModal" />
                        <button v-if="!props.loadingModal" @click="props.sendForm" class="mr-1 text-sm bg-green-500 hover:bg-green-600 duration-200 cursor-pointer font-light px-5 py-2.5 rounded-xl text-white" type="submit">
                            <i class="fas fa-check mr-0.5"></i> Ajouter
                        </button>
                    </template>
                </crud-content>
            </div>
        </app-template>
    </div>
</template>

<script>
import CrudContent from './../../../components/CrudContent.vue'

export default {
    components: {
        CrudContent,
    },
}
</script>
