<template>
    <div>
        <modal name="modal-display-product" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedProduct">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{ selectedProduct['Modele'] }}</h2>
                    <button @click="$modal.hide('modal-display-product')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <product-form
                    :product="selectedProduct"
                    :all-categories="allCategories"
                    :marques="marques"
                    @submit="$modal.hide('modal-display-product')"
                    :display-title="false"
                    :display-proc="false"
                    />
                </div>
            </div>
        </modal>

        <modal name="modal-edit-add-categ" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedEditCateg">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">
                        <template v-if="selectedEditCateg.id">
                            {{$tt('Modifier une catégorie')}}
                        </template>
                        <template v-else>
                            {{$tt('Ajouter une catégorie')}}
                        </template>
                    </h2>
                    <button @click="$modal.hide('modal-edit-add-categ')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <input-rule type="text" v-model="selectedEditCateg.fr" :label="$tt('Nom de la catégorie (FR)')" :label-inline="false" id="new-categ-name" />
                    <button @click="editAddCateg" :disabled="loadingAddCateg" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white text-sm rounded mt-4">
                        <spinner v-if="loadingAddCateg" :size="10" />
                        <template v-if="!loadingAddCateg">
                            <template v-if="selectedEditCateg.id">
                                {{$tt('Modifier')}}
                            </template>
                            <template v-else>
                                {{$tt('Ajouter')}}
                            </template>
                            <i class="fas fa-check ml-1"></i>
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <modal name="modal-select-proc" height="auto" width="90%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{$tt('Ajouter ou modifier des procédures')}}</h2>
                    <button @click="$modal.hide('modal-select-proc')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <button @click="$modal.show('modal-edit-add-proc-categ')" class="px-4 py-2 mb-4 rounded text-sm bg-slate-100 hover:bg-slate-200 duration-200">
                        <i class="fas fa-plus mr-0.5"></i> {{$tt('Ajouter une procédure')}}
                    </button>
                    <div class="border-t pt-4">
                        <table-multi-proc v-model="procsList" />
                    </div>
                    <div class="text-center mt-8">
                        <button @click="confirmSelectProc" class="px-4 py-2 rounded bg-green-500 hover:bg-green-600 text-white">{{$tt('Valider')}} <i class="fas fa-check ml-1"></i></button>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-edit-add-proc-categ" height="auto" width="80%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{$tt('Ajouter une procédure')}}</h2>
                    <button @click="$modal.hide('modal-edit-add-proc-categ')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <form-procedure @update="updatedPattern" />
                </div>
            </div>
        </modal>

        <div class="p-4" v-if="loadingGetCateg">
            <spinner :size="10" />
        </div>

        <div class="flex gap-8">
            <div v-if="queryConfig.displayMenu" class="bg-slate-100 rounded p-6 min-w-[350px] max-w-[350px]" id="categ-list">
                <div class="border-b pb-4 mb-4">
                    <h1 class="text-xl">{{$tt('Liste des catégories')}}</h1>
                </div>
                <div class="border-b pb-4 mb-4">
                    <button @click="selectedEditCateg = {fr: null}; $modal.show('modal-edit-add-categ')" class="px-4 py-2 rounded bg-slate-300 hover:bg-slate-400 duration-200 text-sm">{{$tt('Ajouter une catégorie')}} <i class="fas fa-plus ml-1"></i></button>
                </div>
                <div class="max-h-[50vh] overflow-y-scroll">
                    <div @click="selectCateg(categ)" v-for="(categ, categIndex) in categs" :key="'categ-' + categIndex" class="pb-2 cursor-pointer" :class="selectedCateg && selectedCateg.categ_id == categ.categ_id ? 'font-bold text-blue-600' : 'hover:font-bold duration-200'">
                        <button @click.stop="selectedEditCateg = {fr: categ.fr, id: categ.categ_id}; $modal.show('modal-edit-add-categ')" class="px-4 py-2 rounded text-sm opacity-40 hover:opacity-100 duration-200"><i class="fas fa-pen"></i></button>
                        {{ categ.fr }}
                    </div>
                </div>
            </div>
            <div class="grow overlow-x-auto relative" style="min-width: 0;">
                <!-- <div v-if="selectedCateg" class="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
                    <ul class="flex flex-wrap -mb-px">
                        <li @click="selectedTab = 'proc'" class="me-2 cursor-pointer">
                            <span class="inline-block p-4 border-b-2" :class="selectedTab == 'proc' ? 'text-blue-600 border-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'"><i class="fas fa-code-branch mr-1"></i> Procédures par défaut pour <span style="text-transform: lowercase;">{{ selectedCateg.fr || '' }}</span></span>
                        </li>
                        <li @click="selectedTab = 'apps'" class="me-2 cursor-pointer">
                            <span class="inline-block p-4 border-b-2"  :class="selectedTab == 'apps' ? 'text-blue-600 border-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'"><i class="fas fa-mobile-screen mr-1"></i> Appareils référencés dans la catégorie</span>
                        </li>
                    </ul>
                </div> -->

                <div v-if="selectedCateg" class="overflow-x-auto">
                    <div class="mb-8">
                        <h1 class="text-2xl font-semibold mb-6 pb-6 border-b">{{$tt('Procédures liées à la catégorie')}}: <span class="text-blue-600">{{ selectedCateg.fr }}</span></h1>
                        <div>
                            <button class="px-4 py-2 bg-slate-100 hover:bg-slate-200 rounded text-sm" @click="$modal.show('modal-select-proc')" id="btn-proc-categ"><i class="fas fa-cog mr-1"></i> {{$tt('Ajouter ou modifier des procédures')}}</button>
                            <!-- <div class="text-xl my-8 text-slate-600" v-if="patterns.length <= 0">Aucune procédure paramétrée</div> -->
                            <div v-if="patterns && patterns.length > 0" class="my-4">
                                <table-procedure
                                :can-create="false"
                                :read-only="true"
                                :procedures="patterns"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="mt-16 mb-8" id="categ-app-list">
                        <h1 class="text-2xl font-semibold mb-6 pb-6 border-b">{{$tt('Appareils dans la catégorie')}}: <span class="text-blue-600">{{ selectedCateg.fr }}</span></h1>
                        <div>
                            <data-table
                            max-height="400px"
                            :filters-inline="false"
                            :action-at-start="true"
                            :filters="[
                                {key: 'Marque', label: $tt('Marque'), type: 'text', value: null},
                                {key: 'Modele', label: $tt('Modèle'), type: 'text', value: null},
                                {key: 'Ean', label: $tt('Ean'), type: 'text', value: null},
                            ]"
                            :columns="[
                                {label: $tt('Marque'), key: 'Marque', type: 'text'},
                                {label: $tt('Modèle'), key: 'Modele', type: 'text'},
                                {label: $tt('Libellé'), key: 'Libelle', type: 'text'},
                                {label: $tt('Ean'), key: 'Ean', type: 'text'},
                            ]"
                            :lines="selectedCateg.appareils"
                            >
                                <template v-slot:actions='{line}'>
                                    <button @click="selectProduct(line)" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                                        <i class="fas fa-eye"></i>
                                    </button>
                                </template>
                            </data-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TableMultiProc from './../Components/TableMultiProc.vue'
import TableProcedure from './../../Procedure/Components/TableProcedure.vue';
import FormProcedure from './../../Procedure/Components/FormProcedure.vue';
import ProductForm from '../../Appareils/Components/ProductForm.vue';
import ApiService from '@/services/api.service';

export default {
    components: {
        TableMultiProc,
        TableProcedure,
        FormProcedure,
        ProductForm
    },
    data() {
        return {
            categs: [],
            selectedCateg: null,
            procsList: null,
            procs: null,
            allPatterns: [],
            patterns: [],

            selectedEditCateg: null,

            queryConfig: {
                displayMenu: true,
                defaultCategId: null,
            },

            // selectedTab: 'proc',

            selectedProduct: null,
            marques: null,
            allCategories: null,
            
            loadingAddCateg: false,
            loadingGetCateg: false,
            loadingUpdate: false,
        }
    },
    methods: {
        async getCategories(selectFirst = false) {
            this.loadingGetCateg = true
            let response = await this.$request.post('categ.list')
            this.categs = response.data.posts.post
            this.loadingGetCateg = false

            if (selectFirst && this.categs.length > 0) {
                this.selectCateg(this.categs[0])
            }
            if (this.queryConfig.defaultCategId) {
                this.selectCateg(this.categs.find((categ) => categ.categ_id == this.queryConfig.defaultCategId))
            } else if (this.selectedCateg) {
                this.selectCateg(this.categs.find((categ) => categ.categ_id == this.selectedCateg.categ_id))
            }
            this.$eventHub.$emit('categ-form-loaded')
        },

        async editAddCateg() {
            if (!this.$validation.check([
                {value: this.selectedEditCateg.fr, rules: 'required', id: 'new-categ-name'},
            ])) {return}

            this.loadingAddCateg = true
            let response = await this.$request.post('categ.edit-add', {
                line_id: this.selectedEditCateg.id ? this.selectedEditCateg.id : 0,
                fr: this.selectedEditCateg.fr,
            })
            await this.getCategories()
            this.selectCateg(this.categs.find((categ) => categ.categ_id == response.data.posts.post.id))
            this.selectedEditCateg = null
            this.$modal.hide('modal-edit-add-categ')
            this.loadingAddCateg = false
        },

        selectCateg(categ) {
            this.selectedCateg = JSON.parse(JSON.stringify(categ))
            this.procs = {}
            // Get pattern in categ
            categ.proc_configs.forEach((config) => {
                let keyname = config.pattern.type_garantie + (config.pattern.selfcare_proc == 1 ? '-selfcare' : '-magasin')
                this.procs[keyname] = config.id
            })
            this.procsList = JSON.parse(JSON.stringify(this.procs))
    

            // this.selectedTab = 'proc'

            // Select only linked pattern with his single conf
            this.selectPatternInProcsVariable()
        },

        selectPatternInProcsVariable() {
            let configIds = Object.values(this.procs)
            this.patterns = []
            this.allPatterns.forEach((item) => {
                let patternConfigsIds = item.configs.map((config) => {return config.id})
                patternConfigsIds.forEach((patternConfigsId) => {
                    if (configIds.find((configId) => configId == patternConfigsId)) {
                        item.configs = [item.configs.find((config) => config.id == patternConfigsId)]
                        this.patterns.push(item)
                    }
                })
            })
            this.$forceUpdate()
        },

        async confirmSelectProc() {
            this.procs = this.procsList
            this.selectPatternInProcsVariable()
            this.$modal.hide('modal-select-proc')
            await this.saveCateg()
            this.$toast.success(this.$tt('Procédures modifiées'))
        },

        async saveCateg() {
            if (!this.$validation.check([
                {value: this.selectedCateg.fr, rules: 'required', id: 'fr'},
            ])) {return}

            this.loadingUpdate = true
            let response = await this.$request.post('categ.edit', {
                categ_id: this.selectedCateg.categ_id,
                fr: this.selectedCateg.fr,
                en: this.selectedCateg.en || '',
                de: this.selectedCateg.de || '',
                it: this.selectedCateg.it || '',
                es: this.selectedCateg.es || '',
                configs: JSON.stringify(Object.values(this.procsList))
            })
            if (response.data.posts.post == 1) {
                await this.getCategories()
            }
            this.loadingUpdate = false
        },

        async getPatterns() {
            this.loadingGetPattern = true
            let response = await this.$request.post('procedures.get-patterns')
            this.loadingGetPattern = false
            this.allPatterns = response.data.posts.post
        },

        async updatedPattern() {
            await this.getPatterns()
            this.$eventHub.$emit('refresh-multi-proc-patterns')
            this.$modal.hide('modal-edit-add-proc-categ')
        },

        async getMarques() {
            let response = await this.$request.post('A5141getmarquefromgrossiste', {
                grossiste: ApiService.user().account.id_entite
            }, 'districloud_no_action')
            this.marques = response.data.posts.post
        },

        async getAllCategories() {
            let response = await this.$request.post('procedures.get-categs')
            this.allCategories = response.data.posts.post
        },

        async selectProduct(product) {
            if (!product) {
                return
            }
            let response = await this.$request.post('backoffice', {
                operation: 'getinfoproduct',
                id_appareil: product.id
            }, 'districloud_no_action')
            this.selectedProduct = response.data.posts.post[0]
            this.selectedProduct.id_categorie = this.allCategories.find((item) => item.id_categorie == this.selectedProduct.id_categorie)
            this.selectedProduct.url_notice = this.selectedProduct.notice
            this.selectedProduct.url_photo = this.selectedProduct.img
            this.selectedProduct.url_vue = this.selectedProduct.vue
            this.$modal.show('modal-display-product')
        },
    },
    async mounted () {
        let urlString = window.location.href
        let paramString = urlString.split('?')[1]
        let queryString = new URLSearchParams(paramString)
        for (let pair of queryString.entries()) {
            if (pair[0] == 'display-menu') {
                this.queryConfig.displayMenu = pair[1] == '0' ? false : true
            } else if (pair[0] == 'categ-id') {
                this.queryConfig.defaultCategId = pair[1]
            }
        }

        this.getPatterns()
        this.getMarques()
        await this.getAllCategories()
        this.getCategories(1)
    },
}
</script>

<style lang="scss" scoped>

</style>