<template>
    <div>
        <div class="border">
            <h6 v-if="title" class="bg-slate-100 p-1 text-center font-bold text-lg border-b">{{ title }}</h6>
            <div class="px-5 py-4">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            required: true
        }
    }
}
</script>
