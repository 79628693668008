<template>
    <div>
        <modal name="modal-tech-devis" width="95%" height="auto" :scrollable="true" :click-to-close="false">
            <div>
                <div class="flex items-center justify-between bg-blue-100 px-4 py-1">
                    <div class="text-xs font-light"><i class="fas fa-file-lines mr-1"></i> {{ $tt('Facturation') }}</div>
                    <button @click="$modal.hide('modal-tech-devis')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div class="p-4 flex gap-4 items-center justify-center" v-if="loadingGetData">
                    <spinner :size="10" />
                    <div class="font-light text-sm">{{$tt('Chargement du dossier')}}..</div>
                </div>
                <div class="p-4" v-if="!loadingGetData && data">
                    <div class="flex mt-2 gap-4">
                        <div class="w-[500px]">
                            <div class="bg-blue-200 p-4 rounded mb-2">
                                <h2 class="text-xs font-bold border-b border-gray-500 pb-2 mb-2"><i class="fas fa-folder mr-1"></i> {{ $tt('Dossier') }}</h2>
                                <div class="grid grid-cols-2 gap-2">
                                    <!-- Dossier -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Dossier') }}:</div>
                                        <div class="font-bold">{{ data.ref_dossier }}</div>
                                    </div>
                                    <!-- Marque -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Marque') }}:</div>
                                        <div class="font-bold">{{ data.device['Marque'] }}</div>
                                    </div>
                                    <!-- Catégorie -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Catégorie') }}:</div>
                                        <div class="font-bold">{{ data.device.categorie_str }}</div>
                                    </div>
                                    <!-- Num serie -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Numéro de série') }}:</div>
                                        <div class="font-bold">{{ data.num_serie }}</div>
                                    </div>
                                    <!-- Ref commerciale -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Référence commerciale') }}:</div>
                                        <div class="font-bold">{{ data.device.ref_commerciale }}</div>
                                    </div>
                                    <!-- Ref commerciale -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Modèle / Vers') }}:</div>
                                        <div class="font-bold">{{ data.device['Modele'] }}</div>
                                    </div>
                                    <div class="col-span-2">
                                        <div class="text-xs font-bold">{{ $tt('Message interne') }}</div>
                                        <div class="text-xs bg-blue-100 border-0 p-2 rounded mt-1">
                                            {{ data.message_interne || '-' }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-blue-200 p-4 rounded mb-2">
                                <h2 class="text-xs font-bold border-b border-gray-500 pb-1 mb-1"><i class="fas fa-user mr-1"></i> {{ $tt('Client') }}</h2>
                                <div class="grid grid-cols-1 gap-2">
                                    <!-- Nom client -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt("Client Intervention / Donneur d'ordre") }}:</div>
                                        <div class="font-bold">{{ data.end_customer.nom + ' ' + data.end_customer.prenom }}</div>
                                    </div>
                                    <!-- Tel -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Téléphone') }}:</div>
                                        <div class="font-bold">{{data.end_customer.gsm}} <span v-if="data.end_customer.gsm && data.end_customer.tel"> - </span> {{data.end_customer.tel}}</div>
                                    </div>
                                    <!-- Mail -->
                                    <div class="flex items-center gap-1 text-xs">
                                        <div>{{ $tt('Adresse email') }}:</div>
                                        <div class="font-bold">{{ data.end_customer.mail || '-' }}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="data.iad" class="bg-orange-200 rounded mb-2 p-3">
                                <h2 class="text-sm font-bold border-b border-gray-500 pb-2 mb-2"><i class="fas fa-screwdriver-wrench"></i> {{ $tt('Intervention à domicile') }}</h2>
                                <div class="grid grid-cols-4 gap-2">
                                    <input-rule v-model="iadForm.kilometrage_facture" type="number" :label="$tt('Km')" :label-inline="false" class="mb-2" custom="text-xs" />
                                    <div>
                                        <div class="text-xs font-bold">Date</div>
                                        <div class="bg-orange-300 text-xs py-1 mt-2 px-0.5 rounded text-center">{{ iadForm.date_inter | momentDate }}</div>
                                    </div>
                                    <div>
                                        <div class="text-xs font-bold">Arrivée</div>
                                        <div class="bg-orange-300 text-xs py-1 mt-2 px-0.5 rounded text-center">{{ iadForm.heure_debut }}</div>
                                    </div>
                                    <div>
                                        <div class="text-xs font-bold">Départ</div>
                                        <div class="bg-orange-300 text-xs py-1 mt-2 px-0.5 rounded text-center">{{ iadForm.heure_fin }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-orange-200 rounded mb-2 p-3">
                                <button @click="$modal.show('modal-devis-codification')" class="mb-2 bg-orange-600 hover:bg-orange-700 duration-200 text-white rounded px-4 py-3 font-light w-full">{{ $tt('Codification EICTA - IRIS') }}</button>
                                <table class="w-full">
                                    <thead>
                                        <tr>
                                            <th><div class="text-xs text-left font-bold">{{ $tt('Travaux IRIS') }}</div></th>
                                            <th><div class="text-xs text-left font-bold">{{ $tt('DO') }}</div></th>
                                            <th><div class="text-xs text-left font-bold">{{ $tt('MA') }}</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="width: 60%;">
                                                <div class="text-xs bg-orange-100 border-0 p-2 rounded mt-1">
                                                    {{ codifications[5].find((item) => item.code == data.code_reparation)?.fr }}
                                                    <span v-if="data.code_section">-</span>
                                                    {{ codifications[3].find((item) => item.code == data.code_section)?.fr }}
                                                </div>
                                            </td>
                                            <td style="width: 20%; vertical-align: top"><div class="text-xs bg-orange-100 border-0 p-2 rounded mt-1">0.00 €</div></td>
                                            <td style="width: 20%; vertical-align: top"><div class="text-xs bg-orange-100 border-0 p-2 rounded mt-1">0.00 €</div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="bg-slate-100 rounded mb-2 p-3">
                                <h2 class="text-xs font-bold border-b border-slate-300 text-slate-500 pb-1 mb-2"><i class="fas fa-file mr-1"></i> {{ $tt('Liste des devis') }}</h2>
                                <div class="text-xs font-bold text-slate-400" v-if="data.devis && data.devis.length <= 0">{{ $tt('Aucun devis') }}</div>
                                <div v-for="(devis, devisIndex) in data.devis" :key="'devis-file-' + devisIndex" class="flex justify-between gap-2 items-center mb-1">
                                    <a :href="devis.devis_sign ? devis.devis_sign.url : devis.devis.url" target="_blank" class="text-blue-500 hover:underline cursor-pointer text-xs">
                                        <template>Devis #{{devis.num_devis}}</template>
                                        <!-- <template v-if="devis.type_doc == 'devis_sign'">Devis signé</template> -->
                                    </a>
                                    <div class="grow border-b border-slate-300 border-dashed"></div>
                                    <div class="text-xs font-light">
                                        <template v-if="!devis.date_sign">{{ $tt('Envoyé le') }}</template>
                                        <template v-if="devis.date_sign">{{ $tt('Signé le') }}</template>
                                        {{ (devis.date_sign ? devis.date_sign : devis.date_edit) | momentDate }}
                                    </div>
                                </div>
                                <!-- {{ data.devis }} -->
                            </div>
                        </div>

                        <div>
                            <div class="border shadow-lg shadow-slate-500 p-4 rounded-lg">
                                <div class="p-2">
                                    <div class="p-2 grid grid-cols-2 items-center">
                                        <div class="flex items-center gap-4">
                                            <div>
                                                <div class="text-xs font-light">{{ $tt('Etat du dossier') }}</div>
                                                <div class="font-bold text-xs">{{ $tt(data.last_avancement?.libelle_codeano) || '-' }}</div>
                                            </div>
                                            <div>
                                                <div class="text-xs font-light">{{$tt('Garantie')}}</div>
                                                <div class="font-bold flex items-center gap-2">
                                                    <template v-if="isGarantie">
                                                        <div class="h-[10px] w-[10px] rounded-full bg-green-500"></div>
                                                        <div class="text-xs">{{ $tt(data.garantie) }}</div>
                                                    </template>
                                                    <template v-if="!isGarantie">
                                                        <div class="h-[10px] w-[10px] rounded-full bg-red-500"></div>
                                                        <div class="text-xs">{{ $tt('Hors garantie') }}</div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-4">
                                            <div>
                                                <template v-if="false && isGarantie">
                                                    <button @click="$modal.show('modal-denonce-garantie')" class="px-4 py-2 bg-slate-200 hover:bg-slate-300 duration-200 rounded text-xs mr-2 font-bold">{{ $tt('Dénoncer la garantie') }}</button>
                                                </template>
                                            </div>
                                            <!-- <div class="">SAV</div> -->
                                            <div class="flex gap-2 col-span-3" style="font-size: 14px">
                                                <div class="px-3 py-2 rounded bg-slate-200 duration-200 text-slate-800 font-bold items-center flex justify-center text-center cursor-not-allowed text-xs">{{ $tt('Nomenclature') }}</div>
                                                <div class="px-3 py-2 rounded bg-blue-200 hover:bg-blue-300 duration-200 text-blue-800 font-bold items-center flex justify-center text-center cursor-pointer text-xs">{{ $tt('Fiches Techniques') }}</div>
                                                <div class="px-3 py-2 rounded bg-blue-200 hover:bg-blue-300 duration-200 text-blue-800 font-bold items-center flex justify-center text-center cursor-pointer text-xs">{{ $tt('Pannes Connues') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <table class="mt-2 w-full border bg-white">
                                        <thead class="bg-slate-200">
                                            <tr>
                                                <th class="px-0.5 py-1 text-xs text-left">{{ $tt('Référence') }}</th>
                                                <th class="px-0.5 py-1 text-xs text-left">{{ $tt('Désignation') }}</th>
                                                <th class="px-0.5 py-1 text-xs text-left w-[105px]">{{ $tt('Issue') }}</th>
                                                <th class="px-0.5 py-1 text-xs text-left w-[80px]">{{ $tt('PV Base') }}</th>
                                                <th class="px-0.5 py-1 text-xs text-left">{{ $tt('Remise') }} (%)</th>
                                                <th class="px-0.5 py-1 text-xs text-left w-[80px]">{{ $tt('PV Net') }}</th>
                                                <th class="px-0.5 py-1 text-xs text-left w-[50px]">{{ $tt('Qté') }}</th>
                                                <th class="px-0.5 py-1 text-xs text-left w-[80px]">{{ $tt('Total HT') }}</th>
                                                <th class="px-0.5 py-1 text-xs text-left w-[120px]">{{ $tt('Facturable') }}</th>
                                                <th class="px-0.5 py-1 text-xs text-left">{{ $tt('Coeff') }}</th>
                                                <th class="px-2 py-1 text-xs text-left"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(line, lineIndex) in devisLines" :key="'invoice-line-' + lineIndex">
                                                <td class="px-0.5"><input-rule :disabled="line.edit ? false : true" v-model="line.reference" type="text" :label-inline="false" custom="rounded-sm text-xs p-1" /></td>
                                                <td class="px-0.5"><input-rule :disabled="line.edit ? false : true" v-model="line.designation" type="text" :label-inline="false" custom="rounded-sm text-xs p-1" /></td>
                                                <td class="px-0.5 w-[105px]"><div class="mt-1 cursor-not-allowed text-xs bg-gray-50 border border-slate-300 p-[6px]">{{ issues.find((item) => item.key == line.int_etat)?.label }}</div></td>
                                                <td class="px-0.5 w-[80px]"><input-rule :disabled="line.edit ? false : true" v-model="line.prix_vente" type="price" :label-inline="false" custom="rounded-sm text-xs p-1" @input="calculEditAmount(line)" /></td>
                                                <td class="px-0.5"><input-rule v-model="line.remise" type="select" :options="{values: trancheRemises}" :label-inline="false" custom="rounded-sm text-xs p-1" @input="(value) => {updateRemise(line, value); calculEditAmount(line)}" /></td>
                                                <td class="px-0.5 w-[80px]"><input-rule :disabled="true" v-model="line.prix_vente_remise" type="price" :label-inline="false" custom="rounded-sm text-xs p-1" /></td>
                                                <td class="px-0.5 w-[50px]"><input-rule :disabled="line.edit ? false : true" v-model="line.qty" type="number" :label-inline="false" custom="rounded-sm text-xs p-1" @input="(value) => {updateQtt(line, value); calculEditAmount(line)}" :options="{min: 1}" /></td>
                                                <td class="px-0.5 w-[80px]"><input-rule :disabled="true" v-model="line.total_ht" type="price" :label-inline="false" custom="rounded-sm text-xs p-1" /></td>
                                                <td class="px-0.5 w-[120px]"><input-rule v-model="line.facturable" type="select" :options="{values: facturables, key: 'key', label: 'label'}" :label-inline="false" @input="(value) => updateFacturable(line, value, lineIndex)" :custom="bgColorFacturables[line.facturable] + ' ' + 'rounded-sm text-xs p-1'" /></td>
                                                <td class="px-0.5"><div class="px-4 py-2 rounded" :class="coeffState(line)"></div></td>
                                                <td class="px-2 text-xs text-right">
                                                    <button v-if="!line.edit" @click="selectedLineDelete = line; $modal.show('modal-confirm-delete-line')"><i class="fas fa-trash text-red-500"></i></button>
                                                    <div class="flex items-center gap-2" v-if="line.edit">
                                                        <button class="bg-green-500 hover:bg-green-600 duration-200 rounded text-white px-2 p-1" @click="saveEditPiece(line)"><i class="fas fa-floppy-disk"></i></button>
                                                        <button class="bg-red-500 hover:bg-red-600 duration-200 rounded text-white px-2 p-1" @click="devisLines.splice(lineIndex, 1)"><i class="fas fa-times"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="flex gap-2">
                                        <button @click="$modal.show('modal-stock-piece')" class="mt-1 px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 text-xs rounded">
                                            <i class="fas fa-plus-circle"></i> {{ $tt('Pièce (Stock / Commande)') }}
                                        </button>
                                        <button @click="devisLines.push({
                                            reference: '',
                                            designation: '',
                                            int_etat: -1,
                                            prix_vente: '',
                                            remise: 0,
                                            prix_vente_remise: '',
                                            qty: 1,
                                            total_ht: '',
                                            facturable: '-1',
                                            edit: true
                                        })" class="mt-1 px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 text-xs rounded">
                                            <i class="fas fa-plus-circle"></i> {{$tt('Saisie libre')}}
                                        </button>
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 my-4">
                                        <div>
                                            <div class="bg-slate-200 rounded mb-2 p-3">
                                                <h2 class="text-sm font-bold border-b border-slate-900 pb-2 mb-2"><i class="fas fa-clock mr-1"></i> {{ $tt('Temps dossier') }}</h2>
                                                <div>
                                                    <div class="text-right flex items-center justify-between gap-2">
                                                        <div class="flex items-center gap-1">
                                                            <select v-model="newTemps" class="text-xs bg-white border-0 p-1 rounded-sm focus:outline-none">
                                                                <option v-for="(temps, tempsIndex) in trancheTemps" :key="'tranche-temps-' + tempsIndex" :value="tempsIndex">{{ temps }}</option>
                                                            </select>
                                                            <button @click="addTemps" class="bg-orange-500 hover:bg-orange-600 duration-200 text-white rounded-sm px-1.5 py-1" style="font-size: 10px;"><i class="fas fa-plus"></i></button>
                                                        </div>
                                                        <div class="flex items-center gap-3">
                                                            <div>
                                                                <div class="font-light text-xs">{{ $tt('Travaillé') }}</div>
                                                                <div class="font-bold text-sm">{{ totalTemps.worked }}</div>
                                                            </div>
                                                            <div>
                                                                <div class="font-light text-xs">{{ $tt('Facturé') }}</div>
                                                                <div class="font-bold text-sm">{{ totalTemps.invoice }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="border-t mt-2 pt-2 border-slate-400">
                                                        <div class="max-h-[300px] overflow-y-auto">
                                                            <div @click="displayHistoryTemps = !displayHistoryTemps" class="flex items-center justify-between text-xs cursor-pointer">
                                                                <div>{{ $tt('Historique des temps') }}</div>
                                                                <template v-if="!displayHistoryTemps"><i class="fas fa-chevron-right"></i></template>
                                                                <template v-if="displayHistoryTemps"><i class="fas fa-chevron-down"></i></template>
                                                            </div>
                                                            <div class="mt-2" v-if="displayHistoryTemps">
                                                                <div v-for="(tempsList, tempsListIndex) in tempsLists" :key="'temps-' + tempsListIndex" class="mb-2 text-sm flex justify-between items-center">
                                                                    <div class="flex items-center gap-1">
                                                                        <input @input="updateTemps(tempsList)" v-model="tempsList.facturable" type="checkbox" :id="'temps-invoice-' + tempsListIndex" />
                                                                        <div>
                                                                            <div class="flex items-center gap-2">
                                                                                <label :for="'temps-invoice-' + tempsListIndex" class="text-xs">{{ tempsList.technicien.nom }}</label>
                                                                                <div class="text-xs font-light">{{ tempsList.date | momentDateTime }}</div>
                                                                            </div>
                                                                            <div class="text-xs font-light">{{ tempsList.data }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="font-semibold text-xs">{{trancheTemps[tempsList.temps]}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div class="flex items-center gap-2 justify-between mb-1">
                                                    <label class="text-xs font-bold">{{ $tt('Travaux effectués') }}</label>
                                                    <button @click="getMessages(); $modal.show('modal-default-messages')" class="px-3 py-1.5 text-xs bg-slate-200 hover:bg-slate-300 duration-200 rounded"><i class="fas fa-list"></i> {{ $tt('Liste des travaux') }}</button>
                                                </div>
                                                <input-rule @input="updateTravaux" v-model="data.commentaire_tech" type="richtext" :options="{rows: 3}" :label-inline="false" custom="rounded-sm text-xs" />
                                                <input-rule v-if="data.iad" type="pcheck" :label="$tt('Fact. Report')" :label-inline="true" class="my-3" custom="text-xs"/>
                                            </div>
                                        </div>

                                        <div>
                                            <div class="border rounded">
                                                <div v-if="tarifs && tarifs.details && !tarifs.details.hasTarif" class="p-2 pb-0">
                                                    <div class="bg-red-200 text-red-800 p-4 rounded">
                                                        <h2 class="font-bold text-sm"><i class="fas fa-exclamation-triangle mr-1"></i> {{ $tt('Aucun tarif trouvé') }}</h2>
                                                        <p class="text-sm text-xs">{{$tt('Veuillez configurer des tarifs pour cette catégorie de produit, liés à ce client')}}</p>
                                                    </div>
                                                </div>
                                                <div v-if="true">
                                                    <table v-if="tarifs" class="mt-2 w-full text-xs">
                                                        <thead>
                                                            <tr>
                                                                <th class="px-2 py-1"></th>
                                                                <th class="px-2 py-1 text-right">{{ $tt('Marque') }}</th>
                                                                <th class="px-2 py-1 text-right">{{ $tt('D. Ordre') }}</th>
                                                                <th class="px-2 py-1 text-right">{{ $tt('Client final') }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td class="px-2 py-1">{{ $tt('Déplacement') }}</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].deplacement}} €</td>
                                                                <td class="px-2 py-1 text-right">0.00 €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].deplacement}} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="px-2 py-1">{{ $tt('Port') }}.</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].port}} €</td>
                                                                <td class="px-2 py-1 text-right">0.00 €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].port}} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="px-2 py-1">{{ $tt('MO') }}</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].mo}} €</td>
                                                                <td class="px-2 py-1 text-right">0.00 €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].mo}} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="px-2 py-1">{{ $tt('Pièce(s)') }}</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].pieces}} €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[2].pieces}} €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].pieces}} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="px-2 py-1">{{ $tt('Frais Adm') }}.</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].frais_gestion}} €</td>
                                                                <td class="px-2 py-1 text-right">0.00 €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].frais_gestion}} €</td>
                                                            </tr>
                                                            <tr v-if="data.iad">
                                                                <td class="px-2 py-1">{{ $tt('IAD') }}</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].iad}} €</td>
                                                                <td class="px-2 py-1 text-right">0.00 €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].iad}} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="px-2 py-1"><div class="flex items-center"><input-rule v-model="data.pret" type="checkbox" :label-inline="false" @input="editPret" /> {{ $tt('Prêt') }}</div></td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].pret}} €</td>
                                                                <td class="px-2 py-1 text-right">0.00 €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].pret}} €</td>
                                                            </tr>
                                                            <tr class="font-black border-gray-700">
                                                                <td class="px-2 py-1">{{ $tt('HT') }}</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].total_ht}} €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[2].total_ht}} €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].total_ht}} €</td>
                                                            </tr>
                                                            <tr class="font-black border-y-2 border-gray-700 bg-slate-200 text-blue-800">
                                                                <td class="px-2 py-1">{{ $tt('Total TTC') }}</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[1].total_ttc}} €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs[2].total_ttc}} €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].total_ttc}} €</td>
                                                            </tr>
                                                            <tr class="font-black border-b-2 border-gray-700">
                                                                <td class="px-2 py-1">{{ $tt('Montant TTC autorisé') }}</td>
                                                                <td class="px-2 py-1 text-right"></td>
                                                                <td class="px-2 py-1 text-right">0.00 €</td>
                                                                <td class="px-2 py-1 text-right">{{tarifs['-1'].montant_min}} €</td>
                                                            </tr>
                                                            <tr v-if="tarifs.details.ecosystem > 0" class="font-black border-b-2 border-green-700 bg-green-200 text-green-800">
                                                                <td class="px-2 py-1">{{$tt('Ecosystème')}}</td>
                                                                <td class="px-2 py-1 text-right"></td>
                                                                <td class="px-2 py-1 text-right"></td>
                                                                <td class="px-2 py-1 text-right">{{ '-' + tarifs.details.ecosystem + ' €' }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div v-if="data.iad" class="px-4 my-4">
                                                        <input-rule type="pcheck" :label="$tt('Nécessite un nouveau passage chez le client')" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-right border-t-2 pt-3 mt-4">
                                        <button @click="$modal.hide('modal-tech-devis')" class="px-4 py-2 duration-200 rounded text-sm bg-slate-200 hover:bg-slate-300 duration-200 mr-2">
                                            <i class="fas fa-times mr-1"></i> {{ $tt('Fermer la fenêtre') }}
                                        </button>
                                        <button :disabled="loadingMakeAvoir || disabledGenerateDevis" @click="makeAvoir" class="px-4 py-2 duration-200 rounded text-sm" :class="canEditDevis && !disabledGenerateDevis ? 'bg-green-500 hover:bg-green-600 text-white' : 'bg-slate-200 text-slate-600 cursor-not-allowed'">
                                            <spinner v-if="loadingMakeAvoir" :size="10" />
                                            <template v-if="!loadingMakeAvoir">
                                                <i v-if="canEditDevis && !disabledGenerateDevis" class="fas fa-check mr-1"></i>
                                                <i v-if="!canEditDevis || disabledGenerateDevis" class="fas fa-ban mr-1"></i>
                                                {{ $tt("Générer le devis") }}
                                            </template>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-devis-codification" width="80%" height="auto" :scrollable="true">
            <div>
                <div class="flex items-center justify-between bg-orange-100 px-4 py-1">
                    <div class="text-xs font-light"><i class="fas fa-file-lines mr-1"></i> {{ $tt('Codification EICTA - IRIS') }}</div>
                    <button @click="$modal.hide('modal-devis-codification')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
            </div>
            <div v-if="data" class="p-4 min-h-[600px]">
                <h2 class="font-bold">{{ $tt('Codification des travaux effectués') }}</h2>
                <div class="grid grid-cols-3 gap-4 bg-slate-100 rounded p-4 mt-4">
                    <div>
                        <input-rule @input="updateCode('code_defaut')" v-model="data.code_defaut" type="select" :options="{values: codifications[4], label: 'fr', key: 'code'}" :label="$tt('Code défaut')" :label-inline="false" />
                    </div>
                    <div>
                        <input-rule @input="updateCode('code_condition')" v-model="data.code_condition" type="select" :options="{values: codifications[1], label: 'fr', key: 'code'}" :label="$tt('Condition')" :label-inline="false" />
                    </div>
                    <div>
                        <input-rule @input="updateCode('code_symptome')" v-model="data.code_symptome" type="select" :options="{values: codifications[2], label: 'fr', key: 'code'}" :label="$tt('Code panne')" :label-inline="false" />
                    </div>
                    <div>
                        <input-rule @input="updateCode('code_reparation')" v-model="data.code_reparation" type="select" :options="{values: codifications[5], label: 'fr', key: 'code'}" :label="$tt('Solution')" :label-inline="false" />
                    </div>
                    <div>
                        <input-rule @input="updateCode('code_section')" v-model="data.code_section" type="select" :options="{values: codifications[3], label: 'fr', key: 'code'}" :label="$tt('Secteur')" :label-inline="false" />
                    </div>
                    <div>
                        <div class="grid grid-cols-3 gap-2">
                            <input-rule v-model="data.rep_pla" type="text" :label="$tt('Platine')" @input="updateCode('rep_pla')" :label-inline="false" />
                            <input-rule v-model="data.rep_el" type="text" :label="$tt('Elément')" @input="updateCode('rep_el')" :label-inline="false" />
                            <input-rule v-model="data.rep_firm" type="text" :label="$tt('Firmware')" @input="updateCode('rep_firm')" :label-inline="false" />
                        </div>
                    </div>
                </div>
                <div class="bg-slate-100 rounded p-4 mt-4">
                    <h2 class="text-sm font-bold border-b pb-2 mb-2 border-slate-400">{{ $tt('Détail des travaux effectués') }}</h2>
                    <div class="text-sm" v-html="data.commentaire_tech || $tt('Aucune information')"></div>
                </div>
            </div>
        </modal>

        <modal name="modal-denonce-garantie" width="50%" height="auto" :scrollable="true" :click-to-close="false">
            <div>
                <div class="flex items-center justify-between bg-slate-100 px-4 py-1">
                    <div class="text-xs font-light"><i class="fas fa-file-lines mr-1"></i> {{ $tt('Dénoncer la garantie') }}</div>
                    <button @click="$modal.hide('modal-denonce-garantie')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
            </div>
            <div class="p-4">
                <input-rule type="richtext" />
                <div class="text-center mt-4">
                    <button class="px-4 py-2 rounded bg-green-500 hover:bg-green-600 duration-200 text-white text-sm"><i class="fas fa-check mr-1"></i> {{ $tt('Confirmer') }}</button>
                </div>
            </div>
        </modal>

        <!-- Modal confirm delete -->
        <modal name="modal-confirm-delete-line" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedLineDelete">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        {{$tt('Confirmer la suppression')}}
                    </h2>
                    <button @click="$modal.hide('modal-confirm-delete-line')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div>{{ $tt('Souhaitez-vous supprimer cette ligne') }} ?</div>
                    <div class="bg-slate-100 p-4 rounded mt-4 text-sm">
                        <div>{{$tt('Référence')}}: <b>{{selectedLineDelete.reference}}</b></div>
                        <div>{{$tt('Désignation')}}: <b>{{selectedLineDelete.designation}}</b></div>
                        <!-- <div class="border-b pb-1 mb-1">{{$tt('Entite')}}: <b>{{ selectedLine.entite_key }}</b></div>
                        <div class="border-b pb-1 mb-1">{{$tt('Catégorie')}}: <b>{{ selectedLine.categorie_key }}</b></div>
                        <div>{{$tt('Zone')}}: <b>{{ selectedLine.zone_intervention_key }}</b></div> -->
                    </div>
                    <div class="flex gap-2 items-center mt-4">
                        <button @click="$modal.hide('modal-confirm-delete-line')" class="px-4 py-2 rounded text-sm bg-slate-200 hover:bg-slate-300 duration-200">Annuler <i class="fas fa-times ml-1"></i></button>
                        <button :disabled="loadingDelete" @click="deleteLine" class="px-4 py-2 rounded text-sm bg-red-500 hover:bg-red-600 duration-200 text-white">
                            <spinner :size="10" v-if="loadingDelete" />
                            <template v-if="!loadingDelete">
                                {{$tt('Supprimer')}} <i class="fas fa-trash ml-1"></i>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <!-- Modal confirm delete -->
        <modal name="modal-default-messages" height="auto" width="60%" :scrollable="true">
            <div class="p-5">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        {{$tt('Liste des travaux')}}
                    </h2>
                    <button @click="$modal.hide('modal-default-messages')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="grid grid-cols-2 gap-10">
                        <div>
                            <input-rule v-model="defaultNewMessageInp" type="richtext" :options="{rows: 3}" :label="$tt('Enregistrer un nouveau message')" :label-inline="false" custom="rounded-sm text-xs" />
                            <button :disabled="loadingDefaultNewMessage" @click="addNewDefaultMessage" class="px-4 py-2 text-xs bg-green-500 hover:bg-green-600 duration-200 text-white rounded mt-2">
                                <spinner :size="10" v-if="loadingDefaultNewMessage" />
                                <template v-if="!loadingDefaultNewMessage">
                                    <i class="fas fa-plus mr-1"></i> {{ $tt('Ajouter') }}
                                </template>
                            </button>
                        </div>
                        <div>
                            <label class="font-bold text-xs">{{ $tt('Messages enregistrés') }}</label>
                            <div @click="selectDefaultMessage(msg)" class="border mb-2 rounded hover:bg-green-100 duration-200 cursor-pointer p-2 flex items-center gap-2" v-for="(msg, msgIndex) in defaultMessages" :key="'default-msg-' + msgIndex">
                                <div class="w-[90%] text-sm" v-html="msg.message"></div>
                                <button @click.stop="deleteDefaultMessage(msg)" class="w-[10%] text-right text-sm text-red-500"><i class="fas fa-trash"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal-stock-piece
        v-if="idDossier && data"
        :id-dossier="idDossier"
        @added="getDevisLines(); getTarifAmount()"
        :garantie="data.garantie"
        />
    </div>
</template>

<script>
import moment from 'moment'
import ModalStockPiece from './ModalStockPiece.vue'

export default {
    components: {
        ModalStockPiece,
    },
    props: {
        idDossier: {
            required: true
        },
    },
    watch: {
        idDossier: {
            deep: true,
            handler() {
                this.init()
            }
        },
        iadForm: {
            deep: true,
            handler() {
                this.updateIadForm()
            }
        },
    },
    data() {
        return {
            data: null,

            newTemps: 900,
            tempsLists: [],
            displayHistoryTemps: false,

            iadForm: {},
            iadTimer: null,

            codifications: [],
            timerSendCodification: null,

            timerTravaux: null,

            tarifs: null,

            devisLines: [],
            devisLinesDefault:[],
            timerEditQtt: null,
            selectedLineDelete: null,
            loadingDelete: false,
            disabledGenerateDevis: false,
            coeffStaMin: 0,

            loadingGetData: false,

            loadingMakeAvoir: false,

            defaultMessages: [],
            defaultNewMessageInp: null,
            loadingDefaultNewMessage: false,
        }
    },
    computed: {
        trancheTemps() {
            return {
                900: "00:15", 1800: "00:30", 2700: "00:45",
                3600: "01:00", 4500: "01:15", 5400: "01:30", 6300: "01:45",
                7200: "02:00", 8100: "02:15", 9000: "02:30", 9900: "02:45",
                10800: "03:00", 11700: "03:15", 12600: "03:30", 13500: "03:45",
                14400: "04:00", 15300: "04:15", 16200: "04:30", 17100: "04:45",
                18000: "05:00", 18900: "05:15", 19800: "05:30", 20700: "05:45",
                21600: "06:00", 22500: "06:15", 23400: "06:30", 24300: "06:45",
                25200: "07:00", 26100: "07:15", 27000: "07:30", 27900: "07:45",
                28800: "08:00", 29700: "08:15", 30600: "08:30", 31500: "08:45",
                32400: "09:00", 33300: "09:15", 34200: "09:30", 35100: "09:45",
                36000: "10:00", 36900: "10:15", 37800: "10:30", 38700: "10:45",
                39600: "11:00", 40500: "11:15", 41400: "11:30", 42300: "11:45",
                43200: "12:00", 44100: "12:15", 45000: "12:30", 45900: "12:45",
                46800: "13:00", 47700: "13:15", 48600: "13:30", 49500: "13:45",
                50400: "14:00", 51300: "14:15", 52200: "14:30", 53100: "14:45",
                54000: "15:00", 54900: "15:15", 55800: "15:30", 56700: "15:45",
                57600: "16:00", 58500: "16:15", 59400: "16:30", 60300: "16:45",
                61200: "17:00", 62100: "17:15", 63000: "17:30", 63900: "17:45",
                64800: "18:00", 65700: "18:15", 66600: "18:30", 67500: "18:45",
                68400: "19:00", 69300: "19:15", 70200: "19:30", 71100: "19:45",
                72000: "20:00", 72900: "20:15", 73800: "20:30", 74700: "20:45",
                75600: "21:00", 76500: "21:15", 77400: "21:30", 78300: "21:45",
                79200: "22:00", 80100: "22:15", 81000: "22:30", 81900: "22:45",
                82800: "23:00", 83700: "23:15", 84600: "23:30", 85500: "23:45"
            }
        },

        trancheRemises() {
            return [
                0.00, 5.00, 10.00, 15.00, 20.00, 25.00, 30.00, 35.00, 40.00, 45.00,
                50.00, 55.00, 60.00, 65.00, 70.00, 75.00, 80.00, 85.00, 90.00, 95.00,
                100.00
            ]
        },

        totalTemps() {
            let tempsWorked = this.tempsLists.map((item) => item.temps).reduce((partialSum, a) => partialSum + a, 0)
            let tempsInvoice = this.tempsLists.filter((item) => item.facturable == 1).map((item) => item.temps).reduce((partialSum, a) => partialSum + a, 0)
            return {
                worked: moment.utc(tempsWorked * 1000).format('HH:mm').replace(':', 'h'),
                invoice: moment.utc(tempsInvoice * 1000).format('HH:mm').replace(':', 'h')
            }
        },

        isGarantie() {
            return this.data && !(!this.data.garantie || this.data.garantie == 'Hors garantie')
        },

        issues() {
            return [
                {'label': 'Stock - Neuf', key: '8'},
                {'label': 'Stock - Occas.', key: '11'},
                {'label': 'Commande', key: '3'},
                {'label': 'Hors stock', key: '-1'}
            ]
        },

        facturables() {
            return [
                {key: -1, label: 'Client final'},
                {key: 1, label: 'Marque'},
                {key: 2, label: "Donneur d'ordre"},
                {key: 4, label: 'Interne'},
            ]
        },

        bgColorFacturables() {
            return {
                '-1': 'bg-lime-200 text-white',
                1: 'bg-yellow-200 text-white',
                2: 'bg-purple-200 text-white',
                4: 'bg-red-200 text-white',
            }
        },

        canEditDevis() {
            if (this.tarifs && this.tarifs.details.hasTarif) {
                return parseFloat(this.tarifs['-1'].total_ttc) >= parseFloat(this.tarifs['-1'].montant_min)
            }
            return false
        }
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },

        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        async init() {
            this.loadingGetData = true
            await this.getDossier()
            await this.getCodifications()
            await this.getDevisLines()
            this.loadingGetData = false
            await this.getTarifAmount()
        },

        async getDossier() {
            let response = await this.$request.post('technicien.get-dossier-devis', {
                id_dossier: this.idDossier
            })
            this.data = response.data.posts.post
            this.tempsLists = this.data.temps_reparations
            if (this.data.last_intervention) {
                this.iadForm = {
                    id: this.data.last_intervention.id,
                    kilometrage_facture: this.data.last_intervention.kilometrage_facture,
                    date_inter: this.data.last_intervention.date_inter,
                    heure_debut: this.data.last_intervention.heure_debut,
                    heure_fin: this.data.last_intervention.heure_fin
                }
            }
        },

        async getTemps() {
            let response = await this.$request.post('technicien.temps.list-dossier', {
                id_dossier: this.data.id_dossiersav
            })
            this.tempsLists = response.data.posts.post
        },

        async addTemps() {
            let response = await this.$request.post('technicien.temps.add', {
                temps: this.newTemps,
                id_dossier: this.data.id_dossiersav
            })
            if (response.data.posts.post?.id) {
                this.getTemps()
                this.newTemps = 900
                this.$toast.success(this.$tt('Temps ajouté'))
                this.getTarifAmount()
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
        },

        async updateTemps(temps) {
            await this.$request.post('technicien.temps.edit', {
                line_id: temps.id,
                facturable: temps.facturable ? 0 : 1
            })
        },

        async updateIadForm() {
            clearTimeout(this.iadTimer)
            this.iadTimer = setTimeout(async () => {
                let response = await this.$request.post('technicien.iad.edit', {
                    intervention_id: this.iadForm.id,
                    kilometrage_facture: this.iadForm.kilometrage_facture
                })
                if (response.data.posts.post != 1) {
                    this.$toast.error(this.$tt('Une erreur est survenue'))
                }
            }, 600)
        },

        async getCodifications() {
            let response = await this.$request.post('technicien.codification.get', {
                id_dossier: this.idDossier
            })
            this.codifications = response.data.posts.post
        },

        async updateCode(keyname) {
            clearTimeout(this.timerSendCodification)
            this.timerSendCodification = setTimeout(async () => {
                await this.$request.post('technicien.codification.update', {
                    id_dossier: this.idDossier,
                    type_code: keyname,
                    code: this.data[keyname] ? this.data[keyname] : ''
                })
            }, 500)
        },

        async updateTravaux() {
            clearTimeout(this.timerTravaux)
            this.timerTravaux = setTimeout(async () => {
                await this.$request.post('technicien.travaux.edit', {
                    id_dossier: this.idDossier,
                    commentaire_tech: this.data.commentaire_tech ? this.data.commentaire_tech : ''
                })
            }, 800)
        },

        async getTarifAmount() {
            let response = await this.$request.post('technicien.tarif.get', {
                id_dossier: this.idDossier
            })
            this.tarifs = response.data.posts.post
        },

        async getDevisLines(reset = true) {
            if (reset) {
                this.devisLines = []
            }
            let response = await this.$request.post('technicien.get-devis-lines', {
                id_dossier: this.idDossier
            })
            
            this.devisLines = response.data.posts.post.lines
            this.devisLinesDefault = JSON.parse(JSON.stringify(this.devisLines))
            this.coeffStaMin = response.data.posts.post.coeff_min
        },

        async updateRemise(line, value) {
            let defaultLine = this.devisLinesDefault.find((item) => item.id == line.id)
            
            if (defaultLine && defaultLine.remise != value) {
                defaultLine.remise = value
                let response = await this.$request.post('technicien.edit-remise-piece', {
                    id_piece: line.id,
                    remise: value
                })
                line.total_ht = response.data.posts.post.total_ht
                line.prix_vente_remise = response.data.posts.post.prix_vente_remise
                this.getTarifAmount()
            }
        },

        async updateQtt(line, value) {
            let defaultLine = this.devisLinesDefault.find((item) => item.id == line.id)
            
            if (defaultLine && defaultLine.qty != value) {
                try {
                    clearTimeout(this.timerEditQtt)
                    this.timerEditQtt = setTimeout(async () => {
                        let response = await this.$request.post('technicien.edit-qtt-piece', {
                            id_piece: line.id,
                            qtt: value,
                            reference: line.reference
                        })
                        defaultLine.qty = value
                        line.total_ht = response.data.posts.post
                        this.getTarifAmount()
                    }, 600)
                } catch (error) {
                    console.log(error);
                    
                }
            }
        },

        async updateFacturable(line, value, lineIndex) {
            let defaultLine = this.devisLinesDefault.find((item) => item.id == line.id)
            
            if (defaultLine && defaultLine.facturable != value) {
                defaultLine.remise = value
                let response = await this.$request.post('technicien.edit-facturable-piece', {
                    id_piece: line.id,
                    facturable: value
                })
                this.devisLines[lineIndex] = response.data.posts.post
                defaultLine.facturable = value
                this.getTarifAmount()
            }
        },

        async deleteLine() {
            this.loadingDelete = true
            let response = await this.$request.post('technicien.delete-piece', {
                id_piece: this.selectedLineDelete.id,
                id_dossier: this.idDossier
            })
            this.loadingDelete = false
            if (response.data.posts.post == 1) {
                this.$modal.hide('modal-confirm-delete-line')
                this.getDevisLines()
                this.getTarifAmount()
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
        },

        async makeAvoir() {            
            if (!this.canEditDevis) {
                this.$toast.error(this.$tt('Total TTC inférieur au montant autorisé'))
                return
            }
            if (this.devisLines.find((item) => item.edit == true)) {
                this.$toast.error(this.$tt("Une ligne de pièce n'est pas sauvegardée"))
                return
            }
            this.loadingMakeAvoir = true
            let response = await this.$request.post('technicien.build-devis-pdf', {
                id_dossier: this.idDossier
            })
            this.loadingMakeAvoir = false
            if (response.data.posts.post) {
                this.$toast.success(this.$tt('Devis envoyé au client'))
            }
            this.disabledGenerateDevis = true
            this.getDossier()
            this.$emit('devis-sended')
        },

        async editPret() {
            await this.$request.post('technicien.edit-pret', {
                id_dossier: this.idDossier,
                pret: this.data.pret ? 1 : 0
            })
            this.getTarifAmount()
        },

        async getMessages() {
            let response = await this.$request.post('technicien.get-default-messages')
            this.defaultMessages = response.data.posts.post
        },

        selectDefaultMessage(msg) {
            this.data.commentaire_tech = msg.message
            this.$modal.hide('modal-default-messages')
        },

        async addNewDefaultMessage() {
            this.loadingDefaultNewMessage = true
            let response = await this.$request.post('technicien.add-default-message', {
                message: this.defaultNewMessageInp
            })
            this.selectDefaultMessage(response.data.posts.post)
            this.defaultNewMessageInp = null
            this.loadingDefaultNewMessage = false
        },

        async deleteDefaultMessage(msg) {
            await this.$request.post('technicien.delete-default-message', {
                message_id: msg.id
            })
            this.getMessages()
        },

        calculEditAmount(line) {
            line.prix_vente_remise = line.prix_vente - ((line.remise * line.prix_vente) / 100)
            line.total_ht = line.prix_vente_remise * line.qty
        },

        async saveEditPiece(line) {
            if (!line.reference || !line.designation || !line.prix_vente || !line.prix_vente_remise || !line.qty || !line.total_ht || !line.facturable) {
                this.$toast.error(this.$tt('Merci de saisir tout les champs'))
                return
            }
            if (line.qty <= 0) {
                this.$toast.error(this.$tt('La quantité doit être supérieur à 0'))
                return
            }
            let endData = line
            endData.id_dossier = this.idDossier
            await this.$request.post('technicien.edit-piece-devis', endData)
            line.edit = false
            this.getTarifAmount()
            this.getDevisLines(false)
        },

        coeffState(piece) {
            if (piece.facturable != -1) {
                return 'bg-green-500'
            }
            
            let prixMinRequired = piece.prix_ha_net * this.coeffStaMin
            
            if (piece.prix_vente_remise < piece.prix_ha_net) {
                return 'bg-red-500'
            }
            if (piece.prix_vente_remise <= prixMinRequired) {
                return 'bg-yellow-500'
            }
            if (piece.prix_vente_remise > prixMinRequired) {
                return 'bg-green-500'
            }
        }
    },
    mounted () {
        this.init()
    },
}
</script>

<style lang="scss" scoped>

</style>