<template>
    <div>
        <input-rule :disabled="enseigne?.nom" v-model="form.nom" type="text" class="text-left mb-4" :label="$tt('Nom de l\'enseigne')" id="shop-name" :label-inline="false" />
        <input-rule v-model="form.address" type="address2" :options="{defaultValue: enseigne.adresse, country: country}" class="text-left mb-4" custom="bg-gray-100" :label="$tt('Adresse')" id="shop-address" :label-inline="false" />

        <input-rule v-model="form.type_societe" type="text" :label="$tt('Forme juridique')" :label-inline="false" id="shop-societe" class="text-left mb-4" custom="bg-gray-100" />

        <input-rule
        id="select-grossiste"
        class="mb-4"
        v-model="selectedCentrale"
        :label-inline="false"
        :label="$tt('Avec quelle enseigne travaillez-vous ?')"
        type="api-select"
        @inp="getInpCentrale"
        :options="{
            api: {action: 'inscription.search-centrale', apiName: 'api2'},
            label: 'centrale_ha',
            init: false,
            min: 3,
            uppercase: true
        }"
        />

        <button @click="endForm" class="text-sm flex items-center font-light bg-emerald-600 text-white px-4 py-2 border-emerald-600 rounded-lg">
            {{ $tt('Valider') }} <i class="fas fa-check ml-1"></i>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        enseigne: {},
        country: {}
    },

    data() {
        return {
            form: {},
            defaultAddress: null,

            selectedCentrale: null,
            centraleInp: null,
        }
    },

    methods: {
        async endForm() {
            let endObj = {
                nom: this.form.nom,
                type_societe: this.form.type_societe,
                centrales: this.selectedCentrale ? [{id: this.selectedCentrale.id, nom: this.selectedCentrale.centrale_ha}] : [{id: null, nom: this.centraleInp}]
            }
            endObj = {...endObj, ...this.form.address}

            if (!this.$validation.check([
                {value: endObj.nom, rules: 'required', id: 'shop-name'},
                {value: endObj.type_societe, rules: 'required', id: 'shop-societe'},
                {value: this.form.address, rules: 'required', id: 'shop-address'},
                {value: endObj.cp, rules: 'required', id: 'shop-address'},
                {value: endObj.ville, rules: 'required', id: 'shop-address'},
                {value: endObj.pays, rules: 'required', id: 'shop-address'},
            ])) {return}

            this.$emit('end', endObj)
        },

        getInpCentrale(value) {
            this.centraleInp = value
        }
    },
    mounted () {
        if (this.enseigne) {
            this.form.nom = this.enseigne.nom || ''
            this.form.type_societe = this.enseigne.type_societe || ''
            this.defaultAddress = this.enseigne.adresse.adresse1 ? (this.enseigne.adresse.adresse1 + ' ' + (this.enseigne.adresse.adresse2 ? this.enseigne.adresse.adresse2 : '') + this.enseigne.adresse.cp + ' ' + this.enseigne.adresse.ville) : ''
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
