<template>
    <div>
        <modal name="modal-confirm-delete" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedLine" style="z-index: 999 !important;">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                        <h2 class="font-bold">
                            Confirmer la suppression
                        </h2>
                        <button @click="$modal.hide('modal-confirm-resend'); selectedLine = null">
                            <i class="fas fa-times text-red-500"></i>
                        </button>
                    </div>
                    <div class="p-4 mb-4 text-sm text-orange-800 rounded-lg bg-orange-50" role="alert">
                        <p>Êtes-vous sûr de vouloir supprimer la simulation de <b>{{ selectedLine.email }}</b> ?</p>
                    </div>
                    <button :disabled="waitingDelete" @click="deleteSimulation(selectedLine)" class="bg-red-600 hover:bg-red-700 duration-200 cursor-pointer text-white px-4 py-2 rounded text-sm">
                        <template v-if="!waitingDelete">
                            Confirmer <i class="fas fa-trash ml-1"></i>
                        </template>
                        <template v-if="waitingDelete">
                            <spinner :size="10" />
                        </template>
                    </button>
            </div>
        </modal>

        <app-template>
            <div class="bg-white p-4 text-sm rounded border shadow-sm mb-18">
                <spinner v-if="loading" />
                <template v-if="!loading">
                    <h2 class="text-2xl font-base pt-2 pb-6 mb-6 border-b">Demande de tarifications depuis le site vitrine</h2>
                    <div v-for="(item, itemIndex) in items" :key="'item-' + itemIndex" class="border-b py-4">
                        <div class="grid grid-cols-7 gap-4">
                            <div class="flex items-center">
                                <div class="font-base text-xl font-bold">{{ item.total }} €</div>
                            </div>
                            <div class="col-span-2">
                                <div v-for="(conf, confIndex) in item.conf" :key="'conf-' + confIndex">
                                    {{ conf.label + ': ' + ((conf.actif || conf.type == 'international') ? conf.total : 0) }} €
                                    <span class="text-xs font-light bg-orange-100 rounded px-2 py-0.5 ml-2 mr-0.5" v-if="conf.type == 'international'">Mise en prod</span>
                                    <span class="text-xs font-light bg-slate-100 rounded px-2 py-0.5 ml-2 mr-0.5" v-if="conf.type == 'connections'">{{ conf.nbConnexions }} connexions</span>
                                    <span class="text-xs font-light bg-slate-100 rounded px-2 py-0.5 ml-2 mr-0.5" v-if="conf.type == 'users'">{{ conf.nbLicence }} utilisateurs</span>
                                    <span class="text-xs font-light bg-slate-100 rounded px-2 py-0.5 ml-2 mr-0.5" v-if="conf.type == 'international' && conf.nbPays > 0">{{ conf.nbPays }} pays</span>
                                </div>
                            </div>
                            <div class="flex items-center gap-2">
                                <i class="fas fa-user mr-2"></i>
                                <div>
                                    <div>Email:</div>
                                    <div class="font-semibold">{{ item.email }}</div>
                                </div>
                            </div>
                            <div class="flex items-center gap-2">
                                <i class="fas fa-key mr-2"></i>
                                <div>
                                    <div>Token:</div>
                                    <div class="font-semibold">{{ item.token }}</div>
                                </div>
                            </div>
                            <div class="flex items-center gap-2">
                                <i class="fas fa-calendar mr-2"></i>
                                <div>
                                    <div>Date création:</div>
                                    <div class="font-semibold">{{ item.updated_at | momentDateTime }}</div>
                                </div>
                            </div>
                            <div class="flex items-center justify-center">
                                <button @click="displayModalConfirmDelete(item)" class="mr-1 text-xs bg-red-500 hover:bg-red-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </app-template>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    data() {
        return {
            items: [],
            loading: false,

            selectedLine: null,
            waitingDelete: false
        }
    },

    filters: {
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },

    methods: {
        async getSimulations() {
            this.loading = true
            let response = await this.$request.post('vitrine.devis.get-simulations')
            this.items = response.data.posts.post.map((item) => ({
                ...item,
                conf: item.conf ? JSON.parse(item.conf) : null,
                total: item.conf ? JSON.parse(item.conf).map((item) => {return item.total}).reduce((a, b) => Number(a) + Number(b), 0) : 0
            }))
            this.loading = false
        },

        displayModalConfirmDelete(item) {
            this.selectedLine = item
            this.$modal.show('modal-confirm-delete')
        },

        async deleteSimulation(item) {
            this.waitingDelete = true
            this.$request.post('vitrine.devis.delete-simulation', {
                line_id: item.id
            })
            this.getSimulations()
            this.$modal.hide('modal-confirm-delete')
            this.waitingDelete = false
        }
    },

    mounted () {
        this.getSimulations()
    },
}
</script>

<style lang="scss" scoped>

</style>