var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal',{attrs:{"name":'modal-add-liaison-' + _vm._uid,"height":"350px","scrollable":true}},[_c('div',{staticClass:"p-10"},[_c('div',{staticClass:"flex items-center justify-between mb-4 pb-4 border-b text-2xl"},[_c('h2',{staticClass:"font-bold"},[_vm._v(" Ajouter une liaison ")]),_c('button',{on:{"click":function($event){return _vm.$modal.hide('modal-add-liaison-' + _vm._uid)}}},[_c('i',{staticClass:"fas fa-times text-red-500"})])]),_c('input-rule',{attrs:{"id":"select-enseigne","label-inline":false,"placeholder":"Centrale Achat","type":"api-select","options":{
                api: {action: 'inscription.search-centrale', apiName: 'api2'},
                label: 'centrale_ha',
                init: false,
                min: 3,
                uppercase: true
            }},model:{value:(_vm.newLiaison),callback:function ($$v) {_vm.newLiaison=$$v},expression:"newLiaison"}}),_c('button',{staticClass:"mt-2 bg-green-600 hover:bg-green-700 duration-200 cursor-pointer text-white px-4 py-2 rounded text-sm",attrs:{"disabled":_vm.loaderLiaison},on:{"click":function($event){return _vm.makeLiaison(_vm.newLiaison)}}},[(!_vm.loaderLiaison)?[_vm._v(" Valider "),_c('i',{staticClass:"fas fa-check ml-1"})]:_vm._e(),(_vm.loaderLiaison)?[_c('spinner',{attrs:{"size":10}})]:_vm._e()],2)],1)]),(_vm.loadingGetData)?_c('div',[_c('spinner',{attrs:{"size":10}})],1):_vm._e(),(!_vm.loadingGetData)?_c('div',[_vm._l((_vm.validations),function(liaison,liaisonIndex){return _c('div',{key:'liaison-' + liaisonIndex,staticClass:"mt-2 bg-white p-2 rounded border md:flex gap-2 items-center justify-between"},[(liaison.id)?_c('div',{staticClass:"text-blue-500 hover:underline cursor-pointer text-sm",on:{"click":function($event){_vm.selectedLine = liaison; _vm.$modal.show('modal-display-centrale')}}},[_vm._v(_vm._s(liaison.nom))]):_c('div',{staticClass:"text-sm"},[_vm._v(_vm._s(liaison.nom))]),_c('div',{staticClass:"grow border-b border-dashed border-slate-300 my-2 md:my-0"}),(!liaison.id && !liaison.liaison_id)?_c('div',[_c('input-rule',{attrs:{"id":"select-enseigne","label-inline":false,"placeholder":"Enseigne","type":"api-select","options":{
                    api: {action: 'inscription.search-centrale', apiName: 'api2'},
                    label: 'centrale_ha',
                    init: false,
                    min: 3,
                    uppercase: true
                }},model:{value:(_vm.selectedLiaison),callback:function ($$v) {_vm.selectedLiaison=$$v},expression:"selectedLiaison"}})],1):_vm._e(),_c('div',{staticClass:"flex gap-1"},[(!liaison.liaison_id && !liaison.cancelled)?[_c('button',{staticClass:"bg-green-400 duration-200 hover:bg-green-500 px-2 py-1 rounded text-sm text-white",on:{"click":function($event){return _vm.makeLiaison(liaison)}}},[_c('i',{staticClass:"fas fa-check"})]),_c('button',{staticClass:"bg-red-400 duration-200 hover:bg-red-500 px-3 rounded text-sm text-white",on:{"click":function($event){return _vm.cancelLiaison(liaison)}}},[_c('i',{staticClass:"fas fa-times"})])]:_vm._e(),(liaison.liaison_id)?_c('div',{staticClass:"bg-green-200 text-xs font-light px-4 py-1 rounded"},[(liaison.found && liaison.found == 1)?[_vm._v("Déjà existante")]:[_vm._v("Validée")]],2):_vm._e(),(liaison.cancelled)?_c('div',{staticClass:"bg-red-200 text-xs font-light px-4 py-1 rounded"},[_vm._v(" Refusée ")]):_vm._e()],2)])}),_c('div',{staticClass:"text-right mt-4 mb-2"},[_c('button',{staticClass:"px-4 py-2 bg-slate-500 hover:bg-slate-600 duration-200 text-xs rounded text-white",on:{"click":function($event){return _vm.$modal.show('modal-add-liaison-' + _vm._uid)}}},[_c('i',{staticClass:"fas fa-plus mr-0.5"}),_vm._v(" Ajouter une liaison")])]),_c('admin-client-liaisons-table',{staticClass:"mt-4",attrs:{"type":"fournisseur","connected":1,"entite-id":_vm.id}})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }