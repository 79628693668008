<template>
    <div>
        <modal name="modal-refuse-connexion" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedConnexion">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-xl">
                    <h2 class="font-bold">{{ selectedConnexion.centrale.centrale_ha }}</h2>
                    <button @click="$modal.hide('modal-refuse-connexion')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <input-rule v-model="motifRejet" type="textarea" label="Motif de rejet" :label-inline="false" id="motif-rejet" />
                    <button @click="refuseConnexion" :disabled="selectedConnexion.loading" class="px-4 py-2 bg-red-500 hover:bg-red-600 duration-200 rounded mt-4 text-white text-sm">
                        <spinner v-if="selectedConnexion.loading" :size="10" />
                        <template v-if="!selectedConnexion.loading">
                            Refuser <i class="fas fa-check ml-1"></i>
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <app-template>
            <h1 class="text-2xl font-bold py-4 mb-2">Connexion en attente de validation</h1>

            <div class="bg-white rounded-xl border shadow-sm p-4">
                <div class="p-4" v-if="loading">
                    <spinner :size="10" />
                </div>
                <template v-if="!loading">
                    <table class="w-full text-sm text-left rtl:text-right border">
                        <thead class="text-xs uppercase bg-slate-100">
                            <tr>
                                <th scope="col" class="px-4 py-3">Date</th>
                                <th scope="col" class="px-4 py-3">Fournisseur</th>
                                <th scope="col" class="px-4 py-3">Centrale Achat</th>
                                <th scope="col" class="px-4 py-3">Dossiers estimation</th>
                                <th scope="col" class="px-4 py-3">Dossiers max/mois</th>
                                <th scope="col" class="px-4 py-3">Coût par mois</th>
                                <th scope="col" class="px-4 py-3">Frais prod</th>
                                <th scope="col" class="px-4 py-3">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(connexion, connexionIndex) in connexions" :key="'connexion-' + connexionIndex" class="bg-white border-b duration-200">
                                <td class="px-4 py-2">
                                    <div class="text-slate-900">{{ connexion.demande_le | momentDateTime }}</div>
                                </td>
                                <td class="px-4 py-2">
                                    <div class="font-bold text-slate-900">{{ connexion.grossiste.nom }}</div>
                                </td>
                                <td class="px-4 py-2">
                                    <div class="font-bold text-slate-900">{{ connexion.centrale.centrale_ha }}</div>
                                </td>
                                <td class="px-4 py-2">
                                    <div class="w-[80px]"><input-rule type="text" v-model="connexion.nb_dossier_estimation" :label-inline="false" /></div>
                                </td>
                                <td class="px-4 py-2">
                                    <div class="w-[80px]"><input-rule type="text" v-model="connexion.nb_dossier" :label-inline="false" /></div>
                                </td>
                                <td class="px-4 py-2">
                                    <div class="w-[80px]"><input-rule type="price" v-model="connexion.prix" :label-inline="false" /></div>
                                </td>
                                <td class="px-4 py-2">
                                    <div class="w-[100px]"><input-rule type="price" v-model="connexion.frais_prod" :label-inline="false" /></div>
                                </td>
                                <td class="px-4 py-2 gap-2 flex">
                                    <spinner v-if="connexion.loading" :size="10" />
                                    <template v-if="!connexion.loading">
                                        <button @click="validConnexion(connexion)" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 text-white rounded text-xs"><i class="fas fa-check mr-0.5"></i> Valider</button>
                                        <button @click="selectedConnexion = connexion; $modal.show('modal-refuse-connexion')" class="px-4 py-2 bg-red-500 hover:bg-red-600 duration-200 text-white rounded text-xs"><i class="fas fa-times mr-0.5"></i> Refuser</button>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </div>
        </app-template>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    data() {
        return {
            loading: false,
            connexions: [],
            selectedConnexion: null,
        }
    },
    filters: {
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        async getConnexions() {
            this.loading = true
            let response = await this.$request.post('admin.get-waiting-connexion')
            this.connexions = response.data.posts.post.map((item) => ({...item, loading: false}))
            this.loading = false
        },

        async validConnexion(connexion) {
            let data = connexion
            data.connexion_id = connexion.id
            data.nb_dossier_estimation = connexion.nb_dossier_estimation ? connexion.nb_dossier_estimation : 0
            delete connexion.id
            connexion.loading = true
            let response = await this.$request.post('admin.valid-connexion', data)
            if (response.data.posts.post == 1) {
                await this.getConnexions()
                this.$toast.success('Connexion validée')
            } else {
                this.$toast.error('Une erreur est survenue')
            }
            connexion.loading = false
        },

        async refuseConnexion() {
            if (!this.$validation.check([
                {value: this.motifRejet, rules: 'required', id: 'motif-rejet'},
            ])) {return}

            this.selectedConnexion.loading = true
            let response = await this.$request.post('admin.refuse-connexion', {
                connexion_id: this.selectedConnexion.id,
                motif: this.motifRejet
            })
            if (response.data.posts.post == 1) {
                await this.getConnexions()
                this.$modal.hide('modal-refuse-connexion')
                this.$toast.success('Connexion refusée')
                this.selectedConnexion = null
                this.motifRejet = null
            } else {
                this.$toast.error('Une erreur est survenue')
            }
            this.selected.loading = false
        }
    },
    mounted () {
        this.getConnexions();
    },
}
</script>

<style lang="scss" scoped>

</style>