<template>
    <div>
        <input-rule :disabled="enseigne?.nom" v-model="form.nom" type="text" class="text-left mb-4" :label="$tt('Nom de l\'enseigne')" id="shop-name" :label-inline="false" />
        <input-rule v-model="form.address" type="address2" :options="{defaultValue: enseigne.adresse, country: country}" class="text-left mb-4" custom="bg-gray-100" :label="$tt('Adresse')" id="shop-address" :label-inline="false" />
        <input-rule v-model="form.auto_entrepreneur" :options="{values: [{label: 'Oui', value: 1}, {label: 'Non', value: 0}], key: 'value', label: 'label'}" type="select" :label="$tt('Êtes-vous auto-entrepreneur ?')" class="mb-4" :label-inline="false" />
        <div class="bg-slate-100 p-4 rounded-lg mb-4">
            <div class="font-bold mb-2">{{ $tt('Quels types de réparation faîtes-vous ?') }}</div>
            <div class="grid grid-cols-3">
                <div v-for="(activite, activiteIndex) in activites" :key="'activite-' + activiteIndex" class="flex items-center gap-4 mb-4">
                    <input-rule v-model="form.activites[activite.id]" type="pcheck" :label-inline="false" />
                    <div :class="form.activites[activite.id] ? '' : 'opacity-60 text-slate-700'">
                        <div class="font-bold text-sm">{{ $tt(activite.libelle) }}</div>
                        <div class="font-light text-xs" v-if="activite.description">{{ $tt(activite.description) }}</div>
                    </div>
                </div>
            </div>
        </div>

        <button @click="endForm" class="text-sm flex items-center font-light bg-emerald-600 text-white px-4 py-2 border-emerald-600 rounded-lg">
            {{ $tt('Valider') }} <i class="fas fa-check ml-1"></i>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        enseigne: {},
        country: {}
    },

    data() {
        return {
            form: {
                nom: null,
                address: null,
                auto_entrepreneur: 0,
                activites: {}
            },
            activites: [],
            defaultAddress: null,

            selectedCentrale: null,
        }
    },

    methods: {
        async endForm() {
            let endObj = {
                nom: this.form.nom,
                activites: this.form.activites,
            }
            endObj = {...endObj, ...this.form.address}
 
            if (!this.$validation.check([
                {value: endObj.nom, rules: 'required', id: 'shop-name'},
                {value: this.form.address, rules: 'required', id: 'shop-address'},
                {value: endObj.cp, rules: 'required', id: 'shop-address'},
                {value: endObj.ville, rules: 'required', id: 'shop-address'},
                {value: endObj.pays, rules: 'required', id: 'shop-address'},
            ])) {return}

            let hasActivites = false
            for (let index in this.form.activites) {
                if (this.form.activites[index]) {
                    hasActivites = true
                }
            }
            if (!hasActivites) {
                this.$toast.error(this.$tt('Vous devez saisir au moins une activité'))
                return
            }

            this.$emit('end', endObj)
        },

        async getActivites() {
            let response = await this.$request.post('reaprateur.list-activites')
            this.activites = response.data.posts.post
        }
    },
    mounted () {
        this.getActivites()
        if (this.enseigne) {
            this.form.nom = this.enseigne.nom || ''
            this.defaultAddress = this.enseigne.adresse.adresse1 ? (this.enseigne.adresse.adresse1 + ' ' + (this.enseigne.adresse.adresse2 ? this.enseigne.adresse.adresse2 : '') + this.enseigne.adresse.cp + ' ' + this.enseigne.adresse.ville) : ''
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
