<template>
    <div>
        <app-template>
            <template v-slot:submenu>
                <div class="p-5">
                    <div class="mb-4">
                        <h2 class="font-bold text-lg mb-0.5">{{ client.nom }}</h2>
                        <span class="bg-blue-200 rounded px-3 py-1 text-xs font-light">Centrale d'achat</span>
                    </div>
                    <div v-if="client.type_entite.id == 2" @click="changeSection('magasins')" :class="selectedSection.name == 'magasins' ? 'bg-slate-100 rounded' : 'hover:bg-slate-100 duration-200 cursor-pointer'" class="text-sm flex mb-1 items-center gap-2 p-3 font-light">
                        <i class="fa-solid fa-shop"></i> Liste des magasins
                    </div>
                    <div @click="changeSection('dossiers')" :class="selectedSection.name == 'dossiers' ? 'bg-slate-100 rounded' : 'hover:bg-slate-100 duration-200 cursor-pointer'" class="text-sm flex mb-1 items-center gap-2 p-3 font-light">
                        <i class="fa-solid fa-folder"></i> Dossiers
                    </div>
                    <div @click="changeSection('utilisateurs')" :class="selectedSection.name == 'utilisateurs' ? 'bg-slate-100 rounded' : 'hover:bg-slate-100 duration-200 cursor-pointer'" class="text-sm flex mb-1 items-center gap-2 p-3 font-light">
                        <i class="fa-solid fa-users"></i> Utilisateurs
                    </div>
                    <div @click="changeSection('facturation')" :class="selectedSection.name == 'facturation' ? 'bg-slate-100 rounded' : 'hover:bg-slate-100 duration-200 cursor-pointer'" class="text-sm flex mb-1 items-center gap-2 p-3 font-light">
                        <i class="fa-solid fa-euro"></i> Facturation
                    </div>
                </div>
            </template>
            <template v-if="selectedSection">
                <component :is="selectedSection.component" :sign-id="$route.params.id" />
            </template>
        </app-template>
    </div>
</template>

<script>
import ClientMagasins from './Components/ClientMagasins.vue'
import ClientDossiers from './Components/ClientDossiers.vue'
import ClientUsers from './Components/ClientUsers.vue'
import ClientFactures from './Components/ClientFactures.vue'

export default {
    components: {
        ClientMagasins,
        ClientDossiers,
        ClientUsers,
        ClientFactures
    },
    data() {
        return {
            client: null,

            currentComponent: null,
            selectedSection: null,
            sections: [
                {name: 'magasins', component: ClientMagasins},
                {name: 'dossiers', component: ClientDossiers},
                {name: 'utilisateurs', component: ClientUsers},
                {name: 'facturation', component: ClientFactures},
            ]
        }
    },
    methods: {
        async getClient() {
            let response = await this.$request.post('client.view', {
                line_id: this.$route.params.id
            })
            this.client = response.data.posts.post
        },

        changeSection(name) {
            let section = this.sections.find((section) => section.name == name)
            if (section) {
                this.selectedSection = section
            }
        }
    },
    async mounted () {
        await this.getClient()
        this.changeSection('magasins')
    },
}
</script>

<style lang="scss" scoped>

</style>