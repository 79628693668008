<template>
    <div>
        <div class="bg-white p-3 rounded-xl border shadow mt-2">
            <div v-if="loadingRequest" class="p-2">
                <spinner :size="10" />
            </div>
            <div v-if="!loadingRequest">
                <div class="font-light text-slate-600 text-xs mt-1" v-if="items.length <= 0">Aucun</div>
                <div v-for="(item, itemIndex) in items" :key="'export-' + itemIndex">
                    <slot name="item" :item="item"></slot>
                </div>
            </div>

            <div v-if="request.prev_page_url || request.next_page_url" class="flex items-center justify-center gap-1 text-xs fontext-slate-400 my-2">
                <button class="bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer p-1 px-2 rounded" v-if="request.prev_page_url" @click="changePage('prev')">
                    <i class="fas fa-chevron-left"></i> Précédent
                </button>
                <button class="bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer p-1 px-2 rounded" v-if="request.next_page_url" @click="changePage('next')">
                    Suivant <i class="fas fa-chevron-right"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import apiService from '@/services/api.service'

export default {
    props: {
        dataSrc: {required: true},
        operation: {required: true}
    },

    watch: {
        dataSrc(value) {
            this.request = value
        },

        request(value) {
            this.items = value.data
        }
    },

    data() {
        return {
            request: [],
            items: [],
            loadingRequest: false
        }
    },

    methods: {
        async changePage(direction) {
            let url = null
            if (direction == 'next' && this.request.next_page_url) {
                url = this.request.next_page_url 
            } else if (direction == 'prev' && this.request.prev_page_url) {
                url = this.request.prev_page_url 
            }
            if (url) {
                this.loadingRequest = true
                let response = await apiService.post(this.operation, {}, 'api2', url)
                this.request = response.data.posts.post
                this.loadingRequest = false
            }
        },
    },

    mounted () {
        if (this.dataSrc) {
            this.request = this.dataSrc
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
