<template>
    <div>
        <modal name="modal-delete-invitation" height="auto" :scrollable="true">
            <div class="p-10">
                <template v-if="selectedLine">
                    <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                        <h2 class="font-bold">
                            Annuler un avoir
                        </h2>
                        <button @click="$modal.hide('modal-cancel-avoir'); motifCancel = null">
                            <i class="fas fa-times text-red-500"></i>
                        </button>
                    </div>
                    <div class="p-4 mb-4 text-sm text-orange-800 rounded-lg bg-orange-50" role="alert">
                        <p>Êtes-vous sûr de vouloir supprimer l'invitation envoyé à <b>{{ selectedLine.email }}</b> ?</p>
                    </div>
                    <button :disabled="loaderModal" @click="deleteInvitation" class="bg-red-600 text-white px-4 py-2 rounded mt-2">
                        <template v-if="!loaderModal">
                            Confirmer <i class="fas fa-check ml-1"></i>
                        </template>
                        <template v-if="loaderModal">
                            <spinner :size="10" />
                        </template>
                    </button>
                </template>
            </div>
        </modal>

        <app-template>
            <div class="p-4 rounded shadow bg-white">
                <spinner v-if="loading" />
                <template v-if="!loading">
                    <data-table
                    :filters="[
                        {label: 'Email', key: 'email', type: 'text'},
                    ]"
                    :columns="[
                        {label: 'Date', key: 'created_at', type: 'datetime'},
                        {label: 'Email', key: 'email', type: 'text'},
                        {label: 'Invité par', key: 'nom_client', type: 'text'},
                    ]"
                    :lines="invitations"
                    >
                        <template v-slot:actions='{line}'>
                            <div class="flex items-center">
                                <button @click="selectedLine = line; $modal.show('modal-delete-invitation')" class="mr-1 text-xs bg-red-500 hover:bg-red-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </template>
                    </data-table>
                </template>
            </div>
        </app-template>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                invitations: [],
                selectedLine: null,
                loading: false,
                loaderModal: false,
            }
        },
        methods: {
            async getInvitations() {
                this.loading = true
                let response = await this.$request.post('liaison.get-all-invitations')
                this.invitations = response.data.posts.post
                this.loading = false
            },
            async deleteInvitation() {
                this.loaderModal = true
                await this.$request.post('liaison.delete-invitation-history', {
                    invitation_id: this.selectedLine.id
                })
                this.loaderModal = false
                this.$modal.hide('modal-delete-invitation')
                this.getInvitations()
            }
        },
        mounted () {
            this.getInvitations();
        },
    }
</script>

<style lang="scss" scoped>

</style>