function get(google, key) {
    if (!google) {
        return null
    }

    let findAddress = (keyword, type = 'long_name') => {
        let component = google.address_components.find((address) => address.types.includes(keyword))
        return component?.[type]
    }

    if (key == 'adresse1') {
        return findAddress('street_number') ? (findAddress('street_number') + ' ' + findAddress('route')) : google.address_components[0].long_name
    }
    if (key == 'adresse2') {
        return ''
    }
    if (key == 'cp') {
        return findAddress('postal_code')
    }
    if (key == 'ville') {
        return findAddress('locality')
    }
    if (key == 'pays') {
        return findAddress('country', 'short_name')
    }
    if (key == 'longitude') {
        return google.geometry.location.lng()
    }
    if (key == 'latitude') {
        return google.geometry.location.lat()
    }
}

function all(google) {
    if (!google) {
        return {}
    }
    return {
        adresse1: get(google, 'adresse1'),
        adresse2: get(google, 'adresse2'),
        cp: get(google, 'cp'),
        ville: get(google, 'ville'),
        pays: get(google, 'pays'),
        longitude: get(google, 'longitude'),
        latitude: get(google, 'latitude')
    }
}

function fullAddress(address) {
    return address.adresse1 ? (address.adresse1 + ' ' + (address.adresse2 ? address.adresse2 : '') + address.cp + ' ' + address.ville) : ''
}

export default {
    get,
    all,
    fullAddress
}
