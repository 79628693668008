<template>
    <div>
        <modal name="modal-view-magasin" height="auto" width="80%" :scrollable="true">
            <div v-if="selectedMagasin" class="p-5" style="z-index: 999 !important;">
                <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
                    <ul class="flex flex-wrap -mb-px">
                        <li @click="selectedModalTab = 'magasin'" class="mr-2">
                            <span class="inline-block p-4 rounded-t-lg border-b-2 cursor-pointer" :class="selectedModalTab == 'magasin' ? 'text-blue-600 border-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'">Magasin</span>
                        </li>
                        <li @click="selectedModalTab = 'dossiers'" class="mr-2">
                            <span class="inline-block p-4 rounded-t-lg border-b-2 cursor-pointer" :class="selectedModalTab == 'dossiers' ? 'text-blue-600 border-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'">Dossiers</span>
                        </li>
                    </ul>
                </div>

                <div class="mt-4">
                    <magasin-view v-if="selectedModalTab == 'magasin'" :id="selectedMagasin.id" :edit="true" @update="updatedMagasin" />
                    <dossier-list
                    class="max-h-[300px] overflow-y-auto"
                    v-if="selectedModalTab == 'dossiers'"
                    :mag-id="selectedMagasin.id"
                    :display-callback="(folder) => {selectedFolder = folder; $modal.show('modal-view-folder')}"
                    :edit="true"
                    @update="updatedMagasin"
                    />
                </div>
            </div>
        </modal>

        <modal name="modal-view-folder" height="auto" width="90%" :scrollable="true">
            <div v-if="selectedFolder" class="p-10" style="z-index: 999 !important;">
                <dossier-view :id="selectedFolder.id" :edit="true" />
            </div>
        </modal>

        <div class="bg-white p-4 rounded border border-slate-100">
            <data-table
            :filters-inline="false"
            :filters="[
                {key: 'magasin', label: 'Magasin', type: 'text', value: null},
                {key: 'cp', label: 'Code postal', type: 'text', value: null},
                {key: 'ville', label: 'Ville', type: 'text', value: null},
            ]"
            :columns="[
                {label: 'Magasin', key: 'magasin', type: 'text'},
                {label: 'Ville', key: 'ville', type: 'text'},
                {label: 'Code postal', key: 'cp', type: 'text'},
                {label: 'Erreur', slot: 'error'},
            ]"
            :lines="magasins"
            >
                <template v-slot:error='{line}'>
                    <div v-if="line.error" class="bg-orange-400 text-xs font-semibold rounded px-2 py-0.5">{{ line.error }}</div>
                </template>
                <template v-slot:actions='{line}'>
                    <button @click="viewMagasin(line)" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                        <i class="fas fa-eye"></i>
                    </button>
                </template>
            </data-table>
        </div>
    </div>
</template>

<script>
import MagasinView from '../../Magasin/MagasinView.vue'
import DossierList from '../../Dossier/DossierList.vue'
import DossierView from '../../Dossier/DossierView.vue'

export default {
    props: {
        signId: {required: true},
    },
    components: {
        MagasinView,
        DossierList,
        DossierView,
    },
    data() {
        return {
            magasins: null,
            selectedMagasin: null,
            selectedModalTab: 'magasin',
            selectedFolder: null,
        }
    },
    methods: {
        async getMagasins() {
            let response = await this.$request.post('client.magasins', {
                sign_id: this.signId
            })
            this.magasins = response.data.posts.post
            this.magasins.forEach((magasin) => {
                this.magasins.forEach((magCheck) => {
                    if (magCheck.id != magasin.id) {
                        if (magCheck.magasin.toLowerCase() == magasin.magasin.toLowerCase() && magCheck.cp == magasin.cp) {
                            magasin.error = 'Doublons'
                        }
                    }
                })
            })
        },

        viewMagasin(magasin) {
            this.magDossiers = []
            this.selectedModalTab = 'magasin'
            this.selectedMagasin = magasin
            this.$modal.show('modal-view-magasin')
        },

        async updatedMagasin() {
            await this.getMagasins()
            this.$modal.hide('modal-view-magasin')
        },
    },
    mounted () {
        this.getMagasins();
    },
}
</script>

<style lang="scss" scoped>

</style>