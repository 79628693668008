<template>
    <div>
        <!-- Modals -->
        <!-- Modal edit/add -->
        <modal :name="'modal-edit-add-' + id" height="auto" :width="modalWidth" :scrollable="true">
            <div class="p-10">
                <slot
                name="modal-edit-add"
                :sendForm="sendEditAdd"
                :modalForm="modalForm"
                :loadingModal="loadingModal"
                :line="selectedLine"
                :onEdit="onEdit"
                :modalId="id"
                ></slot>
            </div>
        </modal>

        <!-- Modal view -->
        <modal :name="'modal-view-' + id" height="auto" :width="modalWidth" :scrollable="true">
            <div class="p-10">
                <slot
                v-if="selectedLine"
                name="modal-view"
                :line="selectedLine"
                ></slot>
            </div>
        </modal>

        <!-- Modal delete -->
        <modal :name="'modal-confirm-delete-' + id" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedLine">
                <h1 class="text-xl font-bold">Supprimer {{ label.singular }}</h1>
                <div class="bg-slate-100 border rounded p-4 my-4">{{ selectedLine[refKeyword] }}</div>
                <spinner :size="10" v-if="loadingModal" />
                <template v-if="!loadingModal">
                    <button @click="$modal.hide('modal-confirm-delete-' + id)" class="mr-1 text-sm bg-slate-200 hover:bg-slate-300 duration-200 cursor-pointer font-light px-5 py-2.5 rounded" type="submit">
                        <i class="fas fa-times mr-0.5"></i> Annuler
                    </button>
                    <button @click="deleteLine" class="mr-1 text-sm bg-red-500 hover:bg-red-600 duration-200 cursor-pointer font-light px-5 py-2.5 rounded text-white" type="submit">
                        <i class="fas fa-trash mr-0.5"></i> Supprimer
                    </button>
                </template>
            </div>
        </modal>

        <!-- Header -->
        <div class="flex justify-between items-center mb-5">
            <h1 class="font-bold text-xl">Gestion {{ label.plural }}</h1>
            <button v-if="canAdd" @click="displayModalEditAdd()" class="mr-1 text-xs bg-green-500 hover:bg-green-600 duration-200 cursor-pointer font-light px-3 py-2.5 rounded text-white" type="submit">
                <i class="fas fa-plus mr-0.5"></i> Ajouter {{ label.singular }}
            </button>
        </div>

        <!-- Data table -->
        <spinner v-if="loadingGetData" />
        <template v-if="!loadingGetData">
            <div v-if="datas.length <= 0">
                Aucune donnée
            </div>
            <template v-if="datas.length > 0">
                <data-table
                :filters="filters"
                :columns="table"
                :lines="datas"
                >
                    <template v-slot:actions='{line}'>
                        <div class="flex">
                            <slot name="buttons" :line="line"></slot>
                            <button v-if="canView" @click="displayModalView(line)" class="mr-1 text-xs bg-orange-500 hover:bg-orange-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white">
                                <i class="fas fa-eye"></i>
                            </button>
                            <button v-if="canEdit" @click="displayModalEditAdd(line)" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white">
                                <i class="fas fa-pen"></i>
                            </button>
                            <button v-if="canDelete" @click="displayModalConfirmDelete(line)" class="mr-1 text-xs bg-red-500 hover:bg-red-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white">
                                <i class="fas fa-trash"></i>
                            </button>
                        </div>
                    </template>
                </data-table>
            </template>
        </template>
    </div>
</template>

<script>
import apiService from '@/services/api.service'

export default {
    props: {
        id: {required: true},
        label: {required: true},
        routes: {required: true},
        table: {required: true},
        filters: {},
        refKeyword: {},
        defaultFormObj: {},
        modalWidth: {},
        canAdd: {default: true},
        canView: {default: true},
        canEdit: {default: true},
        canDelete: {default: true},
    },
    data() {
        return {
            datas: [],

            modalForm: {},
            selectedLine: null,
            onEdit: false,

            loadingGetData: false,
            loadingModal: false
        }
    },

    methods: {
        async getData() {
            this.loadingGetData = true
            try {
                let response = await apiService.post(this.routes.list.key, this.routes.list.request || {}, this.routes.list.api)
                this.datas = response.data.posts.post
            } catch (error) {
                this.$toast.error('Une erreur est survenue')
            }
            this.loadingGetData = false
        },

        displayModalEditAdd(line) {
            this.selectedLine = line
            if (line) {
                this.onEdit = true
                this.modalForm = {...line}
            } else {
                this.onEdit = false
                this.modalForm = this.defaultFormObj ? this.defaultFormObj : {}
            }
            this.$modal.show('modal-edit-add-' + this.id)
        },

        displayModalView(line) {
            this.selectedLine = line
            this.$modal.show('modal-view-' + this.id)
        },

        async sendEditAdd() {
            this.loadingModal = true
            let endObj = {
                line_id: this.selectedLine && this.selectedLine.id ? this.selectedLine.id : 0,
            }

            for (const [key, value] of Object.entries(this.routes.editAdd.form)) {
                if (this.modalForm[value] === null || this.modalForm[value] === undefined) {
                    endObj[key] = this.modalForm[value]
                } else {
                    endObj[key] = this.modalForm[value]
                }
            }

            let response = await apiService.post(this.routes.editAdd.operation, endObj, this.routes.editAdd.api)
            if (response.data.posts.post) {
                await this.getData()
                this.$modal.hide('modal-edit-add-' + this.id)
                this.modalForm = {}
                this.selectedLine = null
            } else {
                this.$toast.error('Une erreur est survenue')
            }
            this.loadingModal = false
        },

        displayModalConfirmDelete(permission) {
            this.selectedLine = permission
            this.$modal.show('modal-confirm-delete-' + this.id)
        },

        async deleteLine() {
            this.loadingModal = true
            let endObj = this.routes.delete.request || {}
            endObj[this.routes.delete.keyname || 'line_id'] = this.selectedLine && this.selectedLine.id ? this.selectedLine.id : 0
            let response = await apiService.post(this.routes.delete.key, endObj, this.routes.delete.api)
            if (response.data.posts.post) {
                await this.getData()
                this.$modal.hide('modal-confirm-delete-' + this.id)
                this.selectedLine = null
            } else {
                this.$toast.error('Une erreur est survenue')
            }
            this.loadingModal = false
        }
    },

    mounted () {
        this.getData()

        this.$eventHub.$on('refresh-crud', (componentId) => {
            if (componentId == this.id) {
                this.getData()
            }
        })
    }
}
</script>

<style lang="scss" scoped>

</style>
