<template>
    <div>
        <intro-tour :steps="tourSteps" />

        <app-template>
            <div class="hole"></div>
            <div class="bg-white p-7 rounded-xl border shadow-sm mb-16">
                <div class="p-4" v-if="!dataIsInit">
                    <spinner :size="10" />
                </div>
                <template v-if="dataIsInit">
                    <template v-if="step <= 2">
                        <div class="flex items-center gap-8 sticky top-0 py-5 bg-white z-20">
                            <vue-ellipse-progress :progress="(step / 2) * 100" :size="50" :thickness="2">
                                <div class="font-bold">{{ step }} / 2</div>
                            </vue-ellipse-progress>
                            <div class="font-semibold text-2xl">{{ $tt("Mettons votre espace en place !")}}</div>
                        </div>

                        <div class="grid grid-cols-3 gap-4 mt-4">
                            <div class="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8">
                                <h5 class="mb-4 text-xl font-medium text-gray-800 uppercase">Gratuite</h5>
                                <span class="bg-slate-100 p-2 rounded text-sm text-slate-600"><i class="fas fa-circle-check mr-1 text-slate-400"></i> Offre actuelle</span>
                                <ul role="list" class="space-y-5 my-7">
                                    <li class="flex items-center">
                                        <span class="mr-2">-</span>
                                        <span class="text-base font-normal leading-tight text-gray-500">{{$tt("Traiter les demandes des enseignes clientes de Districloud")}}</span>
                                    </li>
                                    <li class="flex">
                                        <span class="mr-2">-</span>
                                        <span class="text-base font-normal leading-tight text-gray-500">{{$tt("Accès aux modules complémentaires")}}</span>
                                    </li>
                                    <li class="flex">
                                        <span class="mr-2">-</span>
                                        <span class="text-base font-normal leading-tight text-gray-500">{{$tt("Support gratuit")}}</span>
                                    </li>
                                </ul>
                            </div>

                            <div class="w-full p-4 bg-white border-2 border-yellow-500 bg-yellow-50 rounded-lg shadow sm:p-8 col-span-2">
                                <h5 class="mb-4 text-xl font-bold text-yellow-600 uppercase"><i class="fas fa-star mr-2"></i> Premium</h5>
                                <div class="mt-8 mb-10">
                                    <span class="font-bold p-4 rounded bg-yellow-600 bg-opacity-20 text-sm">+ Toute l'offre gratuite</span>
                                </div>
                                <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-8 mt-6">
                                    <div>
                                        <div class="flex items-center">
                                            <svg class="flex-shrink-0 w-4 h-4 text-yellow-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                            </svg>
                                            <div class="w-[250px] text-base font-semibold leading-tight text-gray-500 ms-3">{{$tt("Gérer votre catalogue produit")}}</div>
                                        </div>
                                        <ul class="ml-4 mt-2 list-disc text-sm font-light opacity-50">
                                            <li>Imports de produit par fichier</li>
                                            <li>Imports de produit par API</li>
                                            <li>Imports de produit via connexion ERP</li>
                                            <li>Codification des pannes</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div class="flex items-center">
                                            <svg class="flex-shrink-0 w-4 h-4 text-yellow-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                            </svg>
                                            <div class="w-[250px] text-base font-semibold leading-tight text-gray-500 ms-3">{{$tt("Paramèterez vos procédures")}}</div>
                                        </div>
                                        <ul class="ml-4 mt-2 list-disc text-sm font-light opacity-50">
                                            <li>Gestion des procédures multi centrales</li>
                                            <li>Gestion de tout vos types de garanties</li>
                                            <li>Gestion des procédures par famille, catégorie, produit</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div class="flex items-center">
                                            <svg class="flex-shrink-0 w-4 h-4 text-yellow-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                            </svg>
                                            <div class="w-[250px] text-base font-semibold leading-tight text-gray-500 ms-3">{{$tt("Centralisez toutes vos demandes SAV")}}</div>
                                        </div>
                                        <ul class="ml-4 mt-2 list-disc text-sm font-light opacity-50">
                                            <li>Centralisation de tout vos dossiers SAV dans un espace</li>
                                            <li>Suivre les délais de réparation</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div class="flex items-center">
                                            <svg class="flex-shrink-0 w-4 h-4 text-yellow-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                            </svg>
                                            <div class="w-[250px] text-base font-semibold leading-tight text-gray-500 ms-3">{{$tt("Connectez vos transporteurs")}}</div>
                                        </div>
                                        <ul class="ml-4 mt-2 list-disc text-sm font-light opacity-50">
                                            <li>Contrat privilégié Chronopost</li>
                                            <li>Gestion de vos contrats transporteurs</li>
                                            <li>Recevoir / Envoyer des produits</li>
                                            <li>Suivi logistique</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div class="flex items-center">
                                            <svg class="flex-shrink-0 w-4 h-4 text-yellow-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                            </svg>
                                            <div class="w-[250px] text-base font-semibold leading-tight text-gray-500 ms-3">{{$tt("Gestion multi-utilisateurs")}}</div>
                                        </div>
                                        <ul class="ml-4 mt-2 list-disc text-sm font-light opacity-50">
                                            <li>Gestion de votre espace par plusieurs utilisateurs</li>
                                            <li>Gestion des permissions utilisateurs</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div class="flex items-center">
                                            <svg class="flex-shrink-0 w-4 h-4 text-yellow-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                            </svg>
                                            <div class="w-[250px] text-base font-semibold leading-tight text-gray-500 ms-3">{{$tt("Connexion API (business central, Navision,SAP, Agora...)")}}</div>
                                        </div>
                                        <ul class="ml-4 mt-2 list-disc text-sm font-light opacity-50">
                                            <li></li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div class="flex items-center">
                                            <svg class="flex-shrink-0 w-4 h-4 text-yellow-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                            </svg>
                                            <div class="w-[250px] text-base font-semibold leading-tight text-gray-500 ms-3">{{$tt("Relance automatique")}}</div>
                                        </div>
                                        <ul class="ml-4 mt-2 list-disc text-sm font-light opacity-50">
                                            <li></li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div class="flex items-center">
                                            <svg class="flex-shrink-0 w-4 h-4 text-yellow-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                            </svg>
                                            <div class="w-[250px] text-base font-semibold leading-tight text-gray-500 ms-3">{{$tt("Gestion des données")}}</div>
                                        </div>
                                        <ul class="ml-4 mt-2 list-disc text-sm font-light opacity-50">
                                            <li>Import / Export des données</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div class="flex items-center">
                                            <svg class="flex-shrink-0 w-4 h-4 text-yellow-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                            </svg>
                                            <div class="w-[250px] text-base font-semibold leading-tight text-gray-500 ms-3">{{$tt("Accompagnement privilégié")}}</div>
                                        </div>
                                        <ul class="ml-4 mt-2 list-disc text-sm font-light opacity-50">
                                            <li>Formations</li>
                                            <li>Support email / téléphone</li>
                                            <li>Suivi de votre espace</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-10">
                            <div class="border rounded-xl p-10 border-slate-400 mb-4">
                                <div @click="step = 1" class="flex items-center gap-4">
                                    <div class="border-2 w-[25px] h-[25px] rounded-full text-center" :class="step > 1 ? 'bg-green-500 border-green-500 text-white' : 'border-slate-400'">
                                        <div class="-mt-0.5" v-if="step > 1"><i class="fas fa-check text-xs"></i></div>
                                    </div>
                                    <div>
                                        <h1 class="text-xl font-bold">{{ $tt("Informations de votre société") }}</h1>
                                    </div>
                                </div>
                                <div class="border-t mt-6 pt-6 border-slate-400" v-if="step == 1">
                                    <fournisseur-view
                                    :id="idEntite"
                                    :edit="true"
                                    :admin="false"
                                    :btn-label="$tt('Valider') + '<i class=\'fas fa-check ml-1\'></i>'"
                                    @submit="() => {fournisseurIsValid = true; step = 2}"
                                    />
                                </div>
                            </div>
                            <div class="border rounded-xl p-10 border-slate-400 mb-4">
                                <div @click="step = (fournisseurIsValid ? 2 : step)" class="flex items-center gap-4" :class="!fournisseurIsValid ? 'cursor-not-allowed' : ''">
                                    <div class="border-2 w-[25px] h-[25px] rounded-full text-center" :class="step > 2 ? 'bg-green-500 border-green-500 text-white' : 'border-slate-400'">
                                        <div class="-mt-0.5" v-if="step > 2"><i class="fas fa-check text-xs"></i></div>
                                    </div>
                                    <div>
                                        <h1 class="text-xl font-bold">{{ $tt("Connexion avec vos distributeurs / centrales d'achat") }}</h1>
                                    </div>
                                </div>
                                <div class="border-t mt-6 pt-6 border-slate-400" v-if="step == 2">
                                    <welcome-connections @end="endSettings" />
                                </div>
                            </div>
                            <!-- <div class="border rounded-xl p-10 border-slate-400 mb-4">
                                <div @click="step = 3" class="flex items-center gap-4">
                                    <div class="border-2 w-[25px] h-[25px] rounded-full text-center" :class="step > 3 ? 'bg-green-500 border-green-500 text-white' : 'border-slate-400'">
                                        <div class="-mt-0.5" v-if="step > 3"><i class="fas fa-check text-xs"></i></div>
                                    </div>
                                    <div>
                                        <h1 class="text-xl font-bold">Gestion de votre catalogue produits</h1>
                                    </div>
                                </div>
                                <div class="border-t mt-6 pt-6 border-slate-400" v-if="step == 3">
                                    <import-product-file :with-proc="false" @submit="() => {step = 4}" />
                                </div>
                            </div> -->
                            <!-- <div class="border rounded-xl p-10 border-slate-400 mb-4">
                                <div @click="step = 4" class="flex items-center gap-4">
                                    <div class="border-2 w-[25px] h-[25px] rounded-full text-center" :class="step > 4 ? 'bg-green-500 border-green-500 text-white' : 'border-slate-400'">
                                        <div class="-mt-0.5" v-if="step > 4"><i class="fas fa-check text-xs"></i></div>
                                    </div>
                                    <div>
                                        <h1 class="text-xl font-bold">Gestion de vos catégories</h1>
                                    </div>
                                </div>
                                <div class="border-t mt-6 pt-6 border-slate-400" v-if="step == 4">
                                    <div class="grid grid-cols-6 gap-10">
                                        <div>
                                            <div class="bg-slate-100 rounded-xl p-6">
                                                <div class="text-lg font-light">Une fois les procédures paramétrées aux catégories, vous pouvez passer à l'étape suivante</div>
                                                <button @click="step = 5" class="px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white duration-200 mt-6" id="btn-next-step-5"><i class="fas fa-check mr-1"></i> Etape suivante</button>
                                            </div>
                                        </div>
                                        <div class="col-span-5">
                                            <categorie-form />
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="border rounded-xl p-10 border-slate-400 mb-4">
                                <div @click="step = 5" class="flex items-center gap-4">
                                    <div class="border-2 w-[25px] h-[25px] rounded-full text-center" :class="step > 5 ? 'bg-green-500 border-green-500 text-white' : 'border-slate-400'">
                                        <div class="-mt-0.5" v-if="step > 5"><i class="fas fa-check text-xs"></i></div>
                                    </div>
                                    <div>
                                        <h1 class="text-xl font-bold">Options supplémentaires</h1>
                                    </div>
                                </div>
                                <div class="border-t mt-6 pt-6 border-slate-400" v-if="step == 5">
                                    <admin-client-price-options />
                                    <button @click="step = 6" class="px-4 py-2 mt-10 bg-green-500 hover:bg-green-600 duration-200 text-white rounded">Continuer <i class="fas fa-check ml-1"></i></button>
                                </div>
                            </div> -->
                            <!-- <div class="border rounded-xl p-10 border-slate-400 mb-4">
                                <div @click="step = (fournisseurIsValid ? 3 : step)" class="flex items-center gap-4" :class="!fournisseurIsValid ? 'cursor-not-allowed' : ''">
                                    <div class="border-2 w-[25px] h-[25px] rounded-full text-center" :class="step > 3 ? 'bg-green-500 border-green-500 text-white' : 'border-slate-400'">
                                        <div class="-mt-0.5" v-if="step > 3"><i class="fas fa-check text-xs"></i></div>
                                    </div>
                                    <div>
                                        <h1 class="text-xl font-bold">{{ $tt("Tarifs catalogue") }}</h1>
                                    </div>
                                </div>
                                <div class="border-t mt-6 pt-6 border-slate-400" v-if="step == 3">
                                    <payment-recap @iban-added="endSettings" />
                                </div>
                            </div> -->
                        </div>
                    </template>
                    <template v-if="step == 3">
                        <div class="py-10">
                            <h1 class="text-xl text-center font-light">{{ $tt("L'équipe de Districloud") }}</h1>
                            <h1 class="text-3xl text-center font-bold mt-2">{{ $tt("Vous souhaite la bienvenue !") }}</h1>
                        </div>
                    </template>
                </template>
            </div>
        </app-template>
    </div>
</template>

<script>
import { VueEllipseProgress } from "vue-ellipse-progress";
import JSConfetti from 'js-confetti'

import IntroTour from '../../../components/IntroTour.vue';

import FournisseurView from './../../Admin/Fournisseur/FournisseurView.vue'
// import AdminClientLiaisonsCatalogue from '../Components/AdminClientLiaisonsCatalogue.vue'
// import ImportProductFile from '../../Appareils/Components/ImportProductFile.vue';
// import CategorieForm from '../../Categories/Components/CategorieForm.vue';
// import AdminClientPriceOptions from './../Components/AdminClientPriceOptions.vue';
// import PaymentRecap from './PaymentRecap.vue';
import WelcomeConnections from './WelcomeConnections.vue';

export default {
    components: {
        VueEllipseProgress,
        IntroTour,

        FournisseurView,
        // AdminClientLiaisonsCatalogue,
        // ImportProductFile,
        // CategorieForm,
        // AdminClientPriceOptions,
        // PaymentRecap,
        WelcomeConnections
    },
    data() {
        return {
            step: 1,
            tourSteps: [],
            dataIsInit: false,
            fournisseurIsValid: false,
        }
    },
    watch: {
        // step(value) {
        //     if (value == 2) {
        //         this.$eventHub.$on('catalogue-loaded', () => {
        //             setTimeout(() => {
        //                 this.tourSteps = [
        //                     {el: '#btn-catalogue-0', message: "Vous pouvez vous connecter aux centrales d'achat"},
        //                     {el: '#btn-invite', message: "Si une centrale n'est pas présente dans la liste, vous pouvez l'inviter"},
        //                     {el: '#btn-next-step-3', message: "Une fois vos connexions terminées, vous pouvez continuer en cliquant sur ce bouton"},
        //                 ]
        //             }, 500)
        //         })
        //     }
        //     if (value == 4) {
        //         this.$eventHub.$on('categ-form-loaded', () => {
        //             setTimeout(() => {
        //                 this.tourSteps = [
        //                     {el: '#categ-list', message: "Retrouvez la liste de vos catégories"},
        //                     {el: '#btn-proc-categ', message: "Avec ce bouton, vous pouvez ajouter ou modifier les procédures par défaut liées à votre catégorie"},
        //                     {el: '#categ-app-list', message: "Vous pouvez retrouver la liste de tout les appareils dans la catégorie séléctionnée"},
        //                     {el: '#btn-next-step-5', message: "Une fois vos procédures paramétrées, vous pouvez continuer en cliquant sur ce bouton"},
        //                 ]
        //             }, 500)
        //         })
        //     }
        // }
    },
    computed: {
        idEntite() {
            return this.$request.user().account.id_entite
        }
    },
    methods: {
        async initClient() {
            this.$request.post('admin.init-client')
        },

        async getFournisseur() {
            this.dataIsInit = false
            let response = await this.$request.post('grossiste.view', {
                line_id: this.idEntite
            })
            let fournisseur = response.data.posts.post
            let requiredValues = ['nom', 'adresse1', 'numintra', 'siret', 'contact_princ', 'tel', 'mail']
            let fournisseurIsValid = true
            requiredValues.forEach((requiredValue) => {
                if (!fournisseur[requiredValue]) {
                    fournisseurIsValid = false
                }
            })
            this.fournisseurIsValid = fournisseurIsValid
            if (fournisseurIsValid) {
                this.step = 2
            }
            this.dataIsInit = true
        },

        async endSettings() {
            let response = await this.$request.post('welcome.waiting-premium')
            if (response.data.posts.post == 1) {
                this.step = 3
                const jsConfetti = new JSConfetti()
                jsConfetti.addConfetti({
                    confettiColors: [
                        '#ffd154', '#32d943', '#32d9d9', '#781aa1', '#d45050'
                    ],
                    confettiRadius: 4,
                    confettiNumber: 1400,
                })
    
                window.parent.postMessage({from: 'settings', type: 'end-premium', meta: null}, "*");
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
        }
    },
    mounted () {
        this.initClient()
        this.getFournisseur()
    },
}
</script>