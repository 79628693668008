<template>
    <div>
        <modal name="modal-restriction" height="auto" :scrollable="true">
            <div class="p-10" style="z-index: 999 !important;">
                <h2 class="text-xl font-bold mb-4 pb-2 border-b">Restriction</h2>
                <input-rule type="date" label="Fin de licence" :label-inline="false" />
                <button class="bg-orange-200 text-sm px-4 py-2 rounded mt-2"><i class="fas fa-lock mr-1"></i> Restreindre</button>
            </div>
        </modal>

        <div class="bg-white p-4 rounded border border-slate-100">
            <!-- <div class="grid grid-cols-2 gap-4">
                <div class="bg-slate-100 p-4 rounded">
                    <h1 class="text-xl font-bold">Annuel</h1>
                    <div class="flex gap-2 mt-2">
                        <button class="bg-blue-500 text-white rounded px-3 py-1 text-xs font-light">Payé</button>
                        <button class="bg-blue-200 hover:bg-blue-300 duration-200 cursor-pointer rounded px-3 py-1 text-xs font-light">Retard</button>
                        <button class="bg-blue-200 hover:bg-blue-300 duration-200 cursor-pointer rounded px-3 py-1 text-xs font-light">Dossiers</button>
                    </div>
                    <div v-if="displayChart">
                        <apexchart width="100%" type="bar" :options="options" :series="series"></apexchart>
                    </div>
                </div>
                <div>
                    <div class="bg-slate-100 p-4 rounded">
                        <h1 class="text-xl font-bold">Période en cours</h1>
                        <h3 class="font-light text-sm">Octobre 2023</h3>
                        <div class="border-t pt-3 mt-3 grid grid-cols-3">
                            <div class="border-r text-center">
                                <div class="text-xl font-bold">45</div>
                                <div class="text-sm font-light">Dossiers</div>
                            </div>
                            <div class="border-r text-center">
                                <div class="text-xl font-bold">80€</div>
                                <div class="text-sm font-light">A payer</div>
                            </div>
                            <div class="text-center text-red-500">
                                <div class="text-xl font-bold">175€</div>
                                <div class="text-sm font-light">Retard</div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-slate-100 p-4 rounded mt-4">
                        <h1 class="text-xl font-bold border-b mb-2 pb-2">Licence</h1>
                        <div class="flex items-end">
                            <div class="mt-1 text-xl mr-2 font-semibold"></div>
                        </div>
                    </div>
                    <div class="bg-slate-100 p-4 rounded mt-4">
                        <h1 class="text-xl font-bold border-b mb-2 pb-2">Restriction</h1>
                        <button @click="$modal.show('modal-restriction')" class="px-4 py-2 bg-orange-200 hover:bg-orange-300 duration-200 cursor-pointer rounded mt-1 text-sm">
                            <i class="fas fa-lock mr-1"></i> Passer le compte en mode restreint
                        </button>
                    </div>
                </div>
            </div> -->

            <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mb-4">
                <ul class="flex flex-wrap -mb-px">
                    <li @click="selectedTab = 'dashboard'" class="mr-2">
                        <span class="inline-block p-4 rounded-t-lg border-b-2 cursor-pointer" :class="selectedTab == 'dashboard' ? 'text-blue-600 border-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'">Dashboard</span>
                    </li>
                    <li @click="selectedTab = 'config'" class="mr-2">
                        <span class="inline-block p-4 rounded-t-lg border-b-2 cursor-pointer" :class="selectedTab == 'config' ? 'text-blue-600 border-blue-600' : 'border-transparent hover:text-gray-600 hover:border-gray-300'">Abonnement</span>
                    </li>
                </ul>
            </div>

            <template v-if="selectedTab == 'dashboard'">
                <div class="mb-5">
                    <div class="text-lg font-light">A payer: </div>
                    <div class="text-2xl font-bold">25€</div>
                </div>
                <div class="mb-5">
                    <div class="text-lg font-light">Prochaine facture: </div>
                    <div class="text-2xl font-bold">...</div>
                </div>
            </template>

            <template v-if="selectedTab == 'config'">
                <client-factures-conf :sign-id="signId" />
            </template>
        </div>
    </div>
</template>

<script>
import ClientFacturesConf from './ClientFacturesConf.vue'

export default {
    props: {
        signId: {required: true},
    },
    components: {
        ClientFacturesConf
    },
    data() {
        return {
            selectedTab: 'dashboard',
            displayChart: false,
            options: {
                chart: {
                    id: 'vuechart'
                },
                xaxis: {
                    categories: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
                }
            },
            series: [{
                name: 'resume',
                data: [30, 40, 45, 50, 49, 60, 70, 91, 73, 27]
            }],
        }
    },
    mounted () {
        this.$nextTick(() => {
            setTimeout(() => {
                this.displayChart = true
            }, 200)
        })
    },
}
</script>

<style lang="scss" scoped>

</style>