<template>
    <div>
        <!-- Plaque signalétique -->
        <div v-if="urls && folder.serial_tag_required" class="mb-4 border-b pb-4">
            <div class="flex items-center mb-2">
                <span class="hidden">{{urls.serial_tag}}</span>
                <spinner v-if="urls.serial_tag && urls.serial_tag == 'upload'" :size="8" />
                <div class="text-green font-bold text-green-500" v-if="urls.serial_tag && urls.serial_tag != 'upload'">
                    <i class="fas fa-check-circle"></i>
                </div>
                <div class="text-green font-bold text-red-500" v-if="!urls.serial_tag">
                    <i class="fas fa-times"></i>
                </div>
                <label for="serial_tag" class="ml-2 block text-sm font-light" :class="!urls.serial_tag ? 'text-red-500 font-bold' : ''">Plaque signalétique</label>
            </div>
            <a target="_blank" v-if="urls.serial_tag && urls.serial_tag != 'upload'" :href="urls.serial_tag" class="p-2 px-3 text-xs font-light bg-green-100 hover:bg-green-200 duration-200 rounded-xl mr-1">
                Voir
            </a>
            <button @click="$refs.serialTag.click()" class="p-2 px-5 text-xs font-light bg-gray-300 hover:bg-gray-400 duration-200 rounded-xl">
                <span v-if="!urls.serial_tag || urls.serial_tag == 'upload'" class="">Ajouter</span>
                <span v-if="urls.serial_tag && urls.serial_tag != 'upload'" class="">Modifier</span>
            </button>
            <input @change="uploadSerialTag" type="file" ref="serialTag" style="display: none" id="serial_tag" class="bg-gray-50 text-sm rounded-lg block w-full p-2.5 focus:outline-none border border-gray-300 text-gray-900">
        </div>

        <template v-if="urls">
            <!-- Invoice -->
            <div v-if="!invoiceUrlIsBroken" class="flex items-center mb-2">
                <span class="hidden">{{urls.invoice}}</span>
                <template v-if="folder.can_edit_invoice">
                    <spinner v-if="urls.invoice && urls.invoice == 'upload'" :size="8" />
                    <div class="text-green font-bold text-green-500" v-if="urls.invoice && urls.invoice != 'upload'">
                        <i class="fas fa-check-circle"></i>
                    </div>
                    <div class="text-green font-bold text-red-500" v-if="!urls.invoice">
                        <i class="fas fa-times"></i>
                    </div>
                    <label for="invoice" class="ml-2 block text-sm font-light">Facture</label>
                </template>
                <template v-else>
                    <a :href="folder.uploaded_invoice" target="_blank" class="bg-green-100 hover:bg-green-200 duration-200 p-4 rounded-xl py-3 font-light text-xs"><i class="fas fa-link mr-0.5"></i> Consulter la facture</a>
                </template>
            </div>
            <template v-if="!invoiceUrlIsBroken && folder.can_edit_invoice">
                <a target="_blank" v-if="urls.invoice && urls.invoice != 'upload'" :href="urls.invoice" class="p-2 px-3 text-xs font-light bg-green-100 hover:bg-green-200 duration-200 rounded-xl mr-1">
                    Voir
                </a>
                <button @click="$refs.fileFacture.click()" class="p-2 px-5 text-xs font-light bg-gray-300 hover:bg-gray-400 duration-200 rounded-xl">
                    <span v-if="!urls.invoice || urls.invoice == 'upload'" class="">Ajouter</span>
                    <span v-if="urls.invoice && urls.invoice != 'upload'" class="">Modifier</span>
                </button>
            </template>
            <template v-if="invoiceUrlIsBroken">
                <div class="bg-red-100 text-red-600 rounded p-4 font-semibold text-sm">
                    <div>L'url de la facture n'est pas valide. <br />Merci de l'ajouter au dossier</div>
                    <input-rule class="mt-1" @input="addInvoice" v-model="newInvoice" type="file" />
                </div>
            </template>
            <input @change="uploadInvoice" ref="fileFacture" type="file" id="invoice" style="display: none" class="bg-gray-50 text-sm rounded-lg block w-full p-2.5 focus:outline-none border border-gray-300 text-gray-900">

            <!-- Bon de dépose -->
            <!-- <div class="mb-4 border-b pb-4">
                <div class="flex items-center mb-2">
                    <span class="hidden">{{urls.claim_request}}</span>
                    <spinner v-if="urls.claim_request && urls.claim_request == 'upload'" :size="8" />
                    <div class="text-green font-bold text-green-500" v-if="urls.claim_request && urls.claim_request != 'upload'">
                        <i class="fas fa-check-circle"></i>
                    </div>
                    <div class="text-green font-bold text-red-500" v-if="!urls.claim_request">
                        <i class="fas fa-times"></i>
                    </div>
                    <label for="claim_request" class="ml-2 block text-sm font-light">Bon de dépose</label>
                </div>
                <a target="_blank" v-if="urls.claim_request && urls.claim_request != 'upload'" :href="urls.claim_request" class="p-2 px-3 text-xs font-light bg-green-100 hover:bg-green-200 duration-200 rounded-xl mr-1">
                    Voir
                </a>
                <button @click="$refs.claimRequest.click()" class="p-2 px-5 text-xs font-light bg-gray-300 hover:bg-gray-400 duration-200 rounded-xl">
                    <span v-if="!urls.claim_request || urls.claim_request == 'upload'" class="">Ajouter</span>
                    <span v-if="urls.claim_request && urls.claim_request != 'upload'" class="">Modifier</span>
                </button>
                <input @change="uploadClaimRequest" type="file" ref="claimRequest" style="display: none" id="claim_request" class="bg-gray-50 text-sm rounded-lg block w-full p-2.5 focus:outline-none border border-gray-300 text-gray-900">
            </div> -->

            <!-- Photo du produit -->
            <!-- <div class="mb-4 border-b pb-4">
                <div class="flex items-center mb-2">
                    <span class="hidden">{{urls.device_picture}}</span>
                    <spinner v-if="urls.device_picture && urls.device_picture == 'upload'" :size="8" />
                    <div class="text-green font-bold text-green-500" v-if="urls.device_picture && urls.device_picture != 'upload'">
                        <i class="fas fa-check-circle"></i>
                    </div>
                    <div class="text-green font-bold text-red-500" v-if="!urls.device_picture">
                        <i class="fas fa-times"></i>
                    </div>
                    <label for="device_picture" class="ml-2 block text-sm font-light">Photo produit</label>
                </div>
                <a target="_blank" v-if="urls.device_picture && urls.device_picture != 'upload'" :href="urls.device_picture" class="p-2 px-3 text-xs font-light bg-green-100 hover:bg-green-200 duration-200 rounded-xl mr-1">
                    Voir
                </a>
                <button @click="$refs.devicePicture.click()" class="p-2 px-5 text-xs font-light bg-gray-300 hover:bg-gray-400 duration-200 rounded-xl">
                    <span v-if="!urls.device_picture || urls.device_picture == 'upload'" class="">Ajouter</span>
                    <span v-if="urls.device_picture && urls.device_picture != 'upload'" class="">Modifier</span>
                </button>
                <input @change="uploadDevicePicture" type="file" ref="devicePicture" style="display: none" id="claim_request" class="bg-gray-50 text-sm rounded-lg block w-full p-2.5 focus:outline-none border border-gray-300 text-gray-900">
            </div> -->
        </template>
    </div>
</template>

<script>
import apiService from '@/services/api.service'
import apiUploadService from '@/services/apiUpload.service'

export default {
    props: {
        folder: {
            required: true
        }
    },

    watch: {
        folder: {
            deep: true,
            handler() {
                this.initData()
            }
        }
    },

    data() {
        return {
            invoice: null,
            urls: null,

            invoiceUrlIsBroken: false,
            newInvoice: null,
        }
    },

    methods: {
        async initData() {
            this.urls = {}
            this.urls.invoice = this.folder ? this.folder.uploaded_invoice : null
            this.urls.serial_tag = this.folder.uploaded_serial_tag
            let response = await this.$request.post('tool.check-url', {
                url: this.folder.uploaded_invoice
            })
            this.invoiceUrlIsBroken = response.data.posts.post == 1 ? false : true
            // this.urls.claim_request = this.folder.uploaded_claim_request
            // this.urls.device_picture = this.folder.uploaded_device_picture
            // console.log(this.urls);
        },

        async uploadFile(file, type) {
            this.urls[type] = 'upload'
            let refreshUrls = JSON.parse(JSON.stringify(this.urls))
            this.urls = null
            this.$nextTick(() => {
                this.urls = refreshUrls
            })

            let responseFile = await apiUploadService.post({
                files: file,
            })

            await apiService.post('ecosysteme.add_file', {
                id_case: this.folder.folder.id_dossiersav,
                url_token: this.folder.url_token,
                file: responseFile.data.files[0].url,
                type: type
            })

            this.urls[type] = responseFile.data.files[0].url
            this.urls = null
            this.$nextTick(() => {
                this.urls = refreshUrls
            })
        },

        uploadInvoice(event) {
            let fileType = event.target.files[0].type.split('/')[1]
            if (fileType != 'pdf') {
                this.$toast.error('Format accepté: pdf')
                return
            }
            this.uploadFile(event.target.files[0], 'invoice')
        },

        async addInvoice() {
            if (this.newInvoice) {
                let response = await this.$request.post('ecosysteme.upload-new-invoice', {
                    file_url: this.newInvoice.url,
                    waiting_folder_id: this.folder.id
                })
                if (response.data.posts.post == 1) {
                    this.invoiceUrlIsBroken = false
                    this.$toast.success('Facture ajoutée')
                }
            }
        },

        uploadSerialTag(event) {
            let fileType = event.target.files[0].type.split('/')[1]
            if (fileType != 'jpeg' && fileType != 'jpg' && fileType != 'png') {
                this.$toast.error('Format accepté: jpeg, jpg ou png')
                return
            }
            this.uploadFile(event.target.files[0], 'serial_tag')
        },

        // uploadClaimRequest(event) {
        //     this.uploadFile(event.target.files[0], 'claim_request')
        // },

        // uploadDevicePicture(event) {
        //     this.uploadFile(event.target.files[0], 'device_picture')
        // }
    },

    mounted() {
        this.initData()
    }
}
</script>

<style lang="scss" scoped>

</style>
