<template>
    <div>
        <div class="">
            <div v-if="loadingRequest" class="p-2">
                <spinner :size="10" />
            </div>
            <div v-if="!loadingRequest">
                <div class="font-light text-slate-600 text-xs mt-1" v-if="items.length <= 0">Aucun</div>
                <div v-for="(item, itemIndex) in items" :key="'export-' + itemIndex">
                    <slot name="item" :item="item"></slot>
                </div>
            </div>

            <div v-if="request.prev_page_url || request.next_page_url" class="flex items-center justify-center gap-1 text-xs fontext-slate-400 my-2">
                <button class="bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer p-1 px-2 rounded" v-if="request.prev_page_url" @click="changePage('prev')">
                    <i class="fas fa-chevron-left"></i> Précédent
                </button>
                <button class="bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer p-1 px-2 rounded" v-if="request.next_page_url" @click="changePage('next')">
                    Suivant <i class="fas fa-chevron-right"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import apiService from '@/services/api.service'

export default {
    props: {
        config: {required: true},
        id: {},
    },

    watch: {
        config: {
            deep: false,
            handler: function(value, oldValue) {
                if (JSON.stringify(value) != JSON.stringify(oldValue)) {
                    this.changePage()
                }
            }
        },
        request(value) {
            this.items = JSON.parse(JSON.stringify(value.data))
        }
    },

    data() {
        return {
            request: [],
            items: [],
            loadingRequest: false,
            lastUrl: null,
        }
    },

    methods: {
        async changePage(direction = null) {
            if (direction == 'next' && this.request.next_page_url) {
                this.lastUrl = this.request.next_page_url 
            } else if (direction == 'prev' && this.request.prev_page_url) {
                this.lastUrl = this.request.prev_page_url 
            }
            this.loadingRequest = true
            let response = await apiService.post(this.config.name, (this.config.form || {}), 'api2', (this.lastUrl || null))
            this.request = response.data.posts.post
            this.loadingRequest = false
        },
    },

    mounted () {
        this.changePage()
        this.$eventHub.$on('refresh-' + this.id, () => {
            this.changePage()
        })
    },
}
</script>

<style lang="scss" scoped>

</style>
