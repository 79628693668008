<template>
    <div>
        <app-template>
            <div class="flex gap-1 justify-between">
                <div class="flex gap-1">
                    <div @click="selectTab('encours_atelier')" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'encours_atelier' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-home mr-1"></i> {{$tt('Encours atelier')}}</div>
                    <div @click="selectTab('encours_iad')" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'encours_iad' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-person-walking-arrow-right mr-1"></i> {{$tt('Encours extérieur')}}</div>
                    <div @click="selectTab('devis_attente')" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'devis_attente' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-clock-rotate-left mr-1"></i> {{$tt('Devis en attente')}}</div>
                </div>

                <div class="flex gap-1">
                    <div @click="selectTab('dossier_iad_admin')" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'dossier_iad_admin' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-user-tie mr-1"></i> {{$tt('Dossier IAD non MAJ')}}</div>
                    <div @click="selectTab('dossier_atelier_admin')" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'dossier_atelier_admin' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-user-tie mr-1"></i> {{$tt('Dossier atelier non MAJ')}}</div>
                    <!-- <div @click="selectedTab = 'encours_iad'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'encours_iad' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-home mr-1"></i> {{$tt('Encours extérieur')}}</div> -->
                    <!-- <div @click="selectedTab = 'devis_attente'" class="border border-b-0 cursor-pointer duration-200 p-4 text-sm rounded-t-lg" :class="selectedTab == 'devis_attente' ? 'bg-white text-slate-900 font-bold' : 'bg-slate-50 hover:bg-white text-slate-700'"><i class="fas fa-home mr-1"></i> {{$tt('Devis en attente')}}</div> -->
                </div>
            </div>
            <div class="bg-white p-2 rounded rounded-tl-none border shadow-sm mb-16">
                <template v-if="displayContent">
                    <div v-if="selectedTab == 'encours_atelier'">
                        <factu-atelier :admin="0" />
                    </div>
                    <div v-if="selectedTab == 'dossier_atelier_admin'">
                        <factu-atelier :admin="1" />
                    </div>
                    <div v-if="selectedTab == 'encours_iad'">
                        <factu-iad :admin="0" />
                    </div>
                    <div v-if="selectedTab == 'dossier_iad_admin'">
                        <factu-iad :admin="1" />
                    </div>
                    <div v-if="selectedTab == 'devis_attente'">
                        <factu-devis-attente />
                    </div>
                </template>
            </div>
        </app-template>
    </div>
</template>

<script>
import FactuAtelier from './Components/FactuAtelier.vue'
import FactuIad from './Components/FactuIad.vue'
import FactuDevisAttente from './Components/FactuDevisAttente.vue'

export default {
    components: {
        FactuAtelier,
        FactuIad,
        FactuDevisAttente
    },
    data() {
        return {
            selectedTab: 'encours_atelier',
            displayContent: true
        }
    },
    methods: {
        selectTab(name) {
            this.selectedTab = name
            this.displayContent = false
            this.$nextTick(() => {
                this.displayContent = true
            })
        }
    },
}
</script>

<style lang="scss" scoped>

</style>